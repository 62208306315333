import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import dateFormatter from "@shared/helpers/dateHelper";
import { useLocaleDate } from "@shared/hooks/useLocaleDate";

import Avatar from "@components/atoms/Avatar";
import ConversationMessage from "@components/molecules/ConversationMessage";

import FileAttachmentList from "../FileAttachmentList/FileAttachmentList";
import "./RequestConversationBox.scss";

const RequestConversationBox = props => {
  const { query, responses, handleFileDownload, viewingUserId, t } = props;
  const { locale, options } = useLocaleDate();
  const renderSource = ({ path, pageName, revision, uploadedDate }) => (
    <>
      <>{`${pageName} | ${path?.join(" | ")}`}</>
      <p>{`Report v${revision}, ${dateFormatter(
        uploadedDate,
        locale,
        options.longFormat
      )}`}</p>
    </>
  );

  useEffect(() => {
    // Scroll to the bottom of the conversation container by default
    const conversationContainer = document.getElementById(
      "request-conversation__content"
    );
    conversationContainer?.scroll?.({
      top: conversationContainer.scrollHeight
    });
  }, [responses]);

  return (
    <>
      <div className="request-conversation__header">
        <div className="request-conversation__header-avatar">
          <Avatar user={query.requestedBy} width="2rem" height="2rem" />
        </div>
        <div className="request-conversation__header-details">
          <div className="request-conversation__header-initiator">
            <div className="request-conversation__header-user">
              {query.requestedBy.name}
            </div>
            <div className="request-conversation__header-date">
              {dateFormatter(query?.createdAt, locale, options.longFormat)}
            </div>
          </div>

          <div className="request-conversation__header-summary">
            {query.description}
          </div>

          <div className="request-conversation__header-query">
            {query.source && renderSource(query.source)}
            {query.query?.split("\n").map((string, i) => {
              return <p key={i}>{string || <br />}</p>;
            })}
          </div>

          <div className="requestion-conversation__header-attachments">
            <FileAttachmentList
              columnMode="flex"
              attachments={query.attachments}
              onFileDownloadClicked={handleFileDownload}
            />
          </div>
        </div>
      </div>
      <div
        id="request-conversation__content"
        className="request-conversation__content"
      >
        {responses?.length > 0 ? (
          <div className="request-conversation__content-message">
            {responses.map(response => {
              return (
                <ConversationMessage
                  key={response.id}
                  align={viewingUserId === response.userId ? "right" : "left"}
                  attachments={response.attachments}
                  user={{
                    ...response.user,
                    name:
                      response.user.name ||
                      `${response.user.firstname} ${response.user.lastname}`
                  }}
                  message={response.response}
                  updatedAt={response.updatedAt}
                  onFileDownloadClicked={handleFileDownload}
                />
              );
            })}
          </div>
        ) : (
          <div className="request-conversation__content--no-responses">
            <span>
              {t(
                "requests:requests.ui.requestConversationBox.requestHasNoResponses",
                { requestName: props.title }
              )}
            </span>
          </div>
        )}
      </div>
    </>
  );
};

RequestConversationBox.defaultProps = {};

RequestConversationBox.propTypes = {
  title: PropTypes.string,
  query: PropTypes.shape({
    requestedBy: PropTypes.shape({
      name: PropTypes.string,
      avatar: PropTypes.shape({
        image: PropTypes.string
      })
    }),
    createdAt: PropTypes.string,
    description: PropTypes.string,
    query: PropTypes.string,
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired,
  responses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      userId: PropTypes.number.isRequired,
      response: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
      user: PropTypes.shape({
        firstname: PropTypes.string,
        lastname: PropTypes.string
      }),
      attachments: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          status: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired
        })
      )
    })
  ).isRequired,
  handleFileDownload: PropTypes.func.isRequired,
  viewingUserId: PropTypes.number.isRequired
};
export default withTranslation()(RequestConversationBox);
