import { clientProjectsUtil } from "@shared/hooks";

import { manageClientEngagementProjectsConstants } from "../constants";
import {
  manageClientEngagementProjectsService,
  projectListService
} from "../services";

export const manageClientEngagementProjectsActions = {
  getClientEngagementProjects,
  getProjectManagers,
  getOpenQueries,
  getOpenRisks,
  addClientEngagementProject,
  updateProject,
  getMyProjects,
  clearMyProjects,
  clearError,
  clearAddClientEngagementProjectFlag,
  clearUpdateClientEngagementProjectFlag,
  clearOpenItems,
  setError,
  reset,
  getProjectsByUser,
  getEngagementByProjectId
};

function getOpenRisks(project) {
  return dispatch => {
    dispatch(request());

    manageClientEngagementProjectsService.getOpenRisks(project).then(
      openRisks => {
        dispatch(success(openRisks));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_PROJECTS_OPEN_RISKS_REQUEST
    };
  }
  function success(openRisks) {
    return {
      type: manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_PROJECTS_OPEN_RISKS_SUCCESS,
      openRisks
    };
  }
  function failure(error) {
    return {
      type: manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_PROJECTS_OPEN_RISKS_FAILURE,
      error
    };
  }
}

function getOpenQueries(project) {
  return dispatch => {
    dispatch(request());

    manageClientEngagementProjectsService.getOpenQueries(project).then(
      openQueries => {
        dispatch(success(openQueries));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_PROJECTS_OPEN_QUERIES_REQUEST
    };
  }
  function success(openQueries) {
    return {
      type: manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_PROJECTS_OPEN_QUERIES_SUCCESS,
      openQueries
    };
  }
  function failure(error) {
    return {
      type: manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_PROJECTS_OPEN_QUERIES_FAILURE,
      error
    };
  }
}

function updateProject(project) {
  return dispatch => {
    dispatch(request());

    manageClientEngagementProjectsService.updateProject(project).then(
      updatedProject => {
        dispatch(clientProjectsUtil.invalidateTags(["ClientProjects"]));
        dispatch(success(updatedProject));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageClientEngagementProjectsConstants.UPDATE_PROJECT_REQUEST
    };
  }
  function success(updatedProject) {
    return {
      type: manageClientEngagementProjectsConstants.UPDATE_PROJECT_SUCCESS,
      updatedProject
    };
  }
  function failure(error) {
    return {
      type: manageClientEngagementProjectsConstants.UPDATE_PROJECT_FAILURE,
      error
    };
  }
}

function getMyProjects(withDetails) {
  return dispatch => {
    dispatch(request());

    manageClientEngagementProjectsService.getMyProjects(withDetails).then(
      projects => {
        dispatch(success(projects));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageClientEngagementProjectsConstants.GET_MY_PROJECTS_REQUEST
    };
  }
  function success(projects) {
    return {
      type: manageClientEngagementProjectsConstants.GET_MY_PROJECTS_SUCCESS,
      projects
    };
  }
  function failure(error) {
    return {
      type: manageClientEngagementProjectsConstants.GET_MY_PROJECTS_FAILURE,
      error
    };
  }
}

function getProjectManagers(hostUser) {
  return dispatch => {
    dispatch(request());

    manageClientEngagementProjectsService.getProjecttManagers(hostUser).then(
      users => {
        dispatch(success(users));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageClientEngagementProjectsConstants.GET_PROJECT_MANAGERS_REQUEST
    };
  }
  function success(users) {
    return {
      type: manageClientEngagementProjectsConstants.GET_PROJECT_MANAGERS_SUCCESS,
      users
    };
  }
  function failure(error) {
    return {
      type: manageClientEngagementProjectsConstants.GET_PROJECT_MANAGERS_FAILURE,
      error
    };
  }
}

function getClientEngagementProjects(engagement) {
  return dispatch => {
    dispatch(request());

    manageClientEngagementProjectsService
      .getClientEngagementProjects(engagement)
      .then(
        ({ projects }) => {
          dispatch(success(projects));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return {
      type: manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_PROJECTS_REQUEST
    };
  }
  function success(projects) {
    return {
      type: manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_PROJECTS_SUCCESS,
      projects
    };
  }
  function failure(error) {
    return {
      type: manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_PROJECTS_FAILURE,
      error
    };
  }
}

function getProjectsByUser() {
  return dispatch => {
    dispatch(request());

    projectListService.getProjectsByUser().then(
      projects => {
        dispatch(success(projects));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_PROJECTS_REQUEST
    };
  }
  function success(projects) {
    return {
      type: manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_PROJECTS_SUCCESS,
      projects
    };
  }
  function failure(error) {
    return {
      type: manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_PROJECTS_FAILURE,
      error
    };
  }
}

function addClientEngagementProject(project, fillUsers) {
  return dispatch => {
    dispatch(request());

    manageClientEngagementProjectsService
      .addClientEngagementProject(project, fillUsers)
      .then(
        project => {
          dispatch(success(project));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return {
      type: manageClientEngagementProjectsConstants.ADD_CLIENT_ENGAGEMENT_PROJECT_REQUEST
    };
  }
  function success(project) {
    return {
      type: manageClientEngagementProjectsConstants.ADD_CLIENT_ENGAGEMENT_PROJECT_SUCCESS,
      project
    };
  }
  function failure(error) {
    return {
      type: manageClientEngagementProjectsConstants.ADD_CLIENT_ENGAGEMENT_PROJECT_FAILURE,
      error
    };
  }
}

function getEngagementByProjectId(projectId) {
  return dispatch => {
    dispatch(request());

    manageClientEngagementProjectsService.getByProjectId(projectId).then(
      engagement => {
        dispatch(success(engagement));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_BY_PROJECT_REQUEST
    };
  }
  function success(engagement) {
    return {
      type: manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_BY_PROJECT_SUCCESS,
      engagement
    };
  }
  function failure(error) {
    return {
      type: manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_BY_PROJECT_FAILURE,
      error
    };
  }
}

function clearError() {
  return { type: manageClientEngagementProjectsConstants.CLEAR_ERROR };
}

function clearMyProjects() {
  return { type: manageClientEngagementProjectsConstants.CLEAR_MY_PROJECTS };
}

function setError(error) {
  return { type: manageClientEngagementProjectsConstants.SET_ERROR, error };
}

function clearAddClientEngagementProjectFlag() {
  return {
    type: manageClientEngagementProjectsConstants.CLEAR_ADD_CLIENT_ENGAGEMENT_PROJECT_FLAG
  };
}

function clearUpdateClientEngagementProjectFlag() {
  return {
    type: manageClientEngagementProjectsConstants.CLEAR_UPDATE_CLIENT_ENGAGEMENT_PROJECT_FLAG
  };
}

function clearOpenItems() {
  return {
    type: manageClientEngagementProjectsConstants.CLEAR_OPEN_ITEMS
  };
}

function reset() {
  return { type: manageClientEngagementProjectsConstants.RESET };
}
