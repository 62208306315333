import React from "react";
import { useCallback, useEffect } from "react";

import PropTypes from "prop-types";

import { useGetClientDocuments } from "@shared/hooks";
import {
  useGetClientDocumentsQuery,
  useUpdateClientDocumentPropertiesMutation
} from "@shared/services/documentService";

import DocumentPropertiesModalForm from "@components/molecules/DocumentPropertiesModalForm";

function UpdateClientDocumentPropertiesModal(props) {
  const { clientId, document, onUpdated, onCancel } = props;
  const { refetch: refetchDocuments } = useGetClientDocumentsQuery(
    { clientId },
    { skip: !clientId }
  );
  const [
    updateClientDocumentProperties,
    {
      isLoading: isUpdatingDocument,
      error: updateError,
      isSuccess: updatedSuccessfully
    }
  ] = useUpdateClientDocumentPropertiesMutation();

  const handleUpdate = useCallback(
    ({ name: updatedName }) => {
      if (isUpdatingDocument) return;
      if (updatedName !== document.name) {
        updateClientDocumentProperties({
          clientId,
          document: { ...document, name: updatedName }
        });
      } else {
        onCancel();
      }
    },
    [
      clientId,
      document,
      isUpdatingDocument,
      onCancel,
      updateClientDocumentProperties
    ]
  );

  useEffect(() => {
    if (updatedSuccessfully) {
      refetchDocuments();
      onUpdated();
    }
  }, [onUpdated, refetchDocuments, updatedSuccessfully]);

  return (
    <DocumentPropertiesModalForm
      document={document}
      onSubmit={handleUpdate}
      onCancel={onCancel}
      error={updateError}
    />
  );
}

UpdateClientDocumentPropertiesModal.propTypes = {
  clientId: PropTypes.string.isRequired,
  document: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  onUpdated: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default UpdateClientDocumentPropertiesModal;
