import { t } from "i18next";
import { keyBy, partition } from "lodash";

import { utilities } from "@shared/helpers";

import websheetWizardUtilities from "../websheetWizardUtilities";

// traverses the transforms to go from a final index back to an original index
const transformerToOriginal = transforms => {
  const traverseOrdering = [...transforms].reverse();
  return key => outputIndex => {
    return traverseOrdering.reduce((acc, transform) => {
      if (acc === undefined || acc === null) {
        return null;
      }
      if (!transform?.[key]) {
        return acc;
      }
      return transform[key][acc];
    }, outputIndex);
  };
};

const transformerToOriginalRowIndex = transforms => {
  return transformerToOriginal(transforms)("rows");
};

const splitColumnByDelimiter = (websheet, params) => {
  const { sheetName, splitColumns } = params;

  const clone = structuredClone(websheet);
  const sheetIndex = clone.findIndex(sheet => sheet.sheet === sheetName);
  const sheetData = clone[sheetIndex];

  const { sheetData: updatedSheet, transforms } = splitColumns
    .filter(sc => sc.sheetName === sheetName)
    .reduce(
      (acc, { columnIndex, delimiter }) => {
        const { sheetData: updatedSheet, transforms } =
          websheetWizardUtilities.splitColumnByDelimiter(
            acc.sheetData,
            columnIndex,
            delimiter,
            {
              includeReverseTransforms: true
            }
          );
        return {
          sheetData: updatedSheet,
          transforms: [...acc.transforms, transforms]
        };
      },
      {
        sheetData,
        transforms: []
      }
    );

  clone[sheetIndex] = updatedSheet;

  return {
    websheet: clone,
    transforms: {
      ...transforms,
      source: "splitColumnByDelimiter"
    }
  };
};

function getHeadersWithData({
  columnSelection,
  headerSuggestionsByName,
  templateHeaders
}) {
  const usedColumnIndexes = [];
  const headersWithData = columnSelection.map(
    ({ isAdjusted, columnIndex, name, isBlankSelection }) => {
      const finalColumnIndex = isAdjusted
        ? columnIndex
        : headerSuggestionsByName[name]?.columnIndex ?? columnIndex;

      if (!isBlankSelection && usedColumnIndexes.includes(finalColumnIndex)) {
        throw utilities.translatableError(
          "common:ui.websheet.actions.cleaningWizard.step.replay.error.nonUniqueColumns"
        );
      } else if (!isBlankSelection) {
        usedColumnIndexes.push(finalColumnIndex);
      }

      return {
        columnIndex: finalColumnIndex,
        name,
        isBlankSelection
      };
    }
  );

  if (headersWithData.length !== templateHeaders.length) {
    throw utilities.translatableError(
      "common:ui.websheet.actions.cleaningWizard.step.replay.error.missingColumns"
    );
  }
  return headersWithData;
}

const assignTemplateHeaders = (inputWebsheet, params) => {
  const {
    sheetName,
    sheetIndex,
    templateHeaders,
    columnSelection,
    splitColumns,
    isAdjusted,
    disallowedSheets = []
  } = params;

  const suggestion = websheetWizardUtilities.suggestSheetAndHeadersForWebsheet(
    inputWebsheet,
    templateHeaders,
    websheetWizardUtilities.MAX_ROWS_TO_SEARCH,
    disallowedSheets
  );
  if (!suggestion && !isAdjusted) {
    throw utilities.translatableError(
      "common:ui.websheet.actions.cleaningWizard.step.replay.error.incompatibleConfiguration"
    );
  }

  const correctedSheetName = (() => {
    if (!isAdjusted) {
      return suggestion.sheetName;
    }

    const sheet = websheetWizardUtilities.getSheetByNameOrIndex(inputWebsheet, {
      sheetName,
      sheetIndex
    });

    // user should not be able to select a sheet which was previously disallowed
    if (disallowedSheets.includes(sheet.sheet)) {
      throw utilities.translatableError(
        "common:ui.websheet.actions.cleaningWizard.step.replay.error.incompatibleConfiguration"
      );
    }

    return sheet.sheet;
  })();

  const correctedSheetIndex = inputWebsheet.findIndex(
    s => s.sheet === correctedSheetName
  );

  const { websheet } = splitColumnByDelimiter(inputWebsheet, {
    sheetName: correctedSheetName,
    splitColumns
  });

  const adjustedHeaderSuggestions =
    isAdjusted && !suggestion
      ? []
      : suggestion.headers.map(header => {
          const offsetAmount = splitColumns.filter(
            ({ columnIndex }) => columnIndex < header.columnIndex
          ).length;
          return {
            ...header,
            columnIndex: header.columnIndex + offsetAmount
          };
        });

  const headerSuggestionsByName = keyBy(
    adjustedHeaderSuggestions,
    s => s.templateHeader.name
  );

  const headersWithData = getHeadersWithData({
    columnSelection,
    headerSuggestionsByName,
    templateHeaders
  });

  const columnsToSelect = headersWithData
    .sort((a, b) => a.columnIndex - b.columnIndex)
    .map(({ columnIndex }) => columnIndex);

  const [blankSelectionHeaders, selectedHeaders] = partition(
    headersWithData,
    "isBlankSelection"
  );

  const filteredData = websheetWizardUtilities.dataSubsetBySheetAndColumns(
    websheet,
    correctedSheetName,
    columnsToSelect
  );

  //adds template header to top of the row
  const { sheetData: sheetWithHeaders, transforms } =
    websheetWizardUtilities.insertRowsToSheet(
      filteredData,
      0,
      {
        data: [selectedHeaders.map(h => h.name)],
        formats: websheetWizardUtilities.emptyFormatsArray(selectedHeaders),
        dataValidations: {
          formattedValidations:
            websheetWizardUtilities.emptyFormatsArray(selectedHeaders)
        }
      },
      { includeReverseTransforms: true }
    );

  blankSelectionHeaders.forEach(header => {
    header.columnIndex = undefined;
  });

  const sheetWithHeadersAndBlankColumns =
    websheetWizardUtilities.insertBlankColumnsToSheet(sheetWithHeaders, {
      blankSelectionHeaders
    });

  return {
    baseWebsheet: websheet,
    websheet: [sheetWithHeadersAndBlankColumns],
    transforms: {
      ...transforms,
      source: "assignTemplateHeaders"
    },
    columnHeaders: headersWithData,
    sheetName: correctedSheetName,
    sheetIndex: correctedSheetIndex
  };
};

const firstRowSelection = (websheet, params) => {
  const { sheetName, templateHeaders, firstRow, isAdjusted } = params;

  const OFFSET_FOR_HEADER_ROW = 1;
  const rowIndex = (() => {
    if (isAdjusted) {
      return firstRow.rowIndex;
    }

    const sheetData = websheetWizardUtilities.getSheetByNameOrIndex(websheet, {
      sheetName
    });
    const suggestion = websheetWizardUtilities.suggestRowHeader({
      sheetData,
      templateHeaders,
      rowOffset: OFFSET_FOR_HEADER_ROW,
      maxRowsToSearch: websheetWizardUtilities.MAX_ROWS_TO_SEARCH
    });
    return suggestion.index;
  })();

  const rowsToRemove = Array(rowIndex)
    .fill(0)
    .map((_, idx) => idx + OFFSET_FOR_HEADER_ROW);

  const { websheet: updatedWebsheet, transforms } =
    websheetWizardUtilities.removeRowsFromSheet(websheet, {
      sheetName,
      rowsToDelete: rowsToRemove,
      includeReverseTransforms: true
    });

  return {
    websheet: updatedWebsheet,
    transforms: {
      ...transforms,
      source: "firstRowSelection"
    }
  };
};

const unnecessaryRowsSelection = (websheet, params) => {
  const { sheetName, selectedRows = [], unselectedRows = [] } = params;

  const sheetData = websheetWizardUtilities.getSheetByNameOrIndex(websheet, {
    sheetName
  });

  const inclusionList = selectedRows.map(sr => sr.rowData);
  const exclusionList = unselectedRows.map(sr => sr.rowData);
  const rowsWithIndex = websheetWizardUtilities.suggestUnnecessaryRows({
    sheetData,
    inclusionList,
    exclusionList
  });
  const rowsToRemove = rowsWithIndex.map(({ rowIndex }) => rowIndex);

  const { websheet: updatedWebsheet, transforms } =
    websheetWizardUtilities.removeRowsFromSheet(websheet, {
      sheetName,
      rowsToDelete: rowsToRemove,
      includeReverseTransforms: true
    });

  return {
    websheet: updatedWebsheet,
    transforms: {
      ...transforms,
      source: "unnecessaryRowsSelection"
    }
  };
};

const aggregate = (websheet, params) => {
  const { commands, templateHeaders } = params;

  const sheetOutputs = commands.map(command => {
    return replaySavedCleanOnWebsheet(websheet, command, templateHeaders);
  });

  const result = sheetOutputs.reduce((acc, sheetOutput) => {
    const {
      websheet: websheetToDisplay,
      finalWebsheet,
      sheetToPreserve,
      debug
    } = sheetOutput;

    const matchingSheet = websheetWizardUtilities.getSheetByNameOrIndex(
      websheetToDisplay,
      sheetToPreserve
    );

    const updatedWebsheet = acc.websheet ?? structuredClone(websheetToDisplay);
    const idx = updatedWebsheet.find(s => s.sheet === matchingSheet.sheet);
    if (idx === -1) {
      throw utilities.translatableError(
        "common:ui.websheet.actions.cleaningWizard.step.replay.error.incompatibleConfiguration"
      );
    }
    updatedWebsheet[idx] = matchingSheet;

    const updatedFinalWebsheet = (() => {
      if (!acc.finalWebsheet) {
        return structuredClone(finalWebsheet);
      }

      const existingFinalWebsheet = acc.finalWebsheet;

      const newSheetData =
        websheetWizardUtilities.alignColumnsAndAppendRowsToSheet(
          existingFinalWebsheet[0],
          finalWebsheet[0],
          { removeIncomingHeaders: true }
        );
      return [newSheetData];
    })();

    return {
      isFinalResult: true,
      websheet: updatedWebsheet,
      finalWebsheet: updatedFinalWebsheet,
      sheetsToPreserve: [...(acc.sheetsToPreserve ?? []), sheetToPreserve],
      debug: [...(acc.debug ?? []), debug]
    };
  }, {});

  return result;
};

const getCommand = commandAction => {
  const commandMap = {
    assignTemplateHeaders,
    firstRowSelection,
    unnecessaryRowsSelection,
    aggregate
  };
  return commandMap[commandAction];
};

const convertCleaningTemplateToReplayCommands = cleaningTemplate => {
  const commands = [];

  if (cleaningTemplate.aggregateSheets) {
    const aggregatedCommands = cleaningTemplate.aggregateSheets.map(
      savedState => convertCleaningTemplateToReplayCommands(savedState)
    );

    commands.push({
      action: "aggregate",
      params: {
        commands: aggregatedCommands
      }
    });

    return {
      commands
    };
  }

  const { appliedTab } = structuredClone(cleaningTemplate);
  const sheetRef = {
    sheetName: appliedTab.name,
    sheetIndex: appliedTab.index
  };

  if (cleaningTemplate.columnsSelection) {
    const isAdjusted = cleaningTemplate.columnsSelection.some(
      cs => cs.isAdjusted
    );
    commands.push({
      action: "assignTemplateHeaders",
      params: {
        ...sheetRef,
        columnSelection: cleaningTemplate.columnsSelection,
        splitColumns: cleaningTemplate.splitColumns ?? [],
        disallowedSheets: cleaningTemplate.disallowedSheets ?? [],
        isAdjusted
      }
    });
  }

  if (cleaningTemplate.firstRowSelection) {
    commands.push({
      action: "firstRowSelection",
      params: {
        ...sheetRef,
        sheetIndex: 0,
        ...cleaningTemplate.firstRowSelection
      }
    });
  }

  if (cleaningTemplate.unnecessaryRowsSelection) {
    commands.push({
      action: "unnecessaryRowsSelection",
      params: {
        ...sheetRef,
        sheetIndex: 0,
        ...cleaningTemplate.unnecessaryRowsSelection
      }
    });
  }

  return {
    commands
  };
};

/**
 * @param websheet
 * @param {Object} replayCommands
 * @param {*} replayCommands.commands
 * @param {Array<{name: string, required: boolean, type: string}>} templateHeaders List of TemplateHeader definitions
 * @returns {Object} result
 * @property {Object} result.websheet
 * @property {Object} result.sheetName sheetName the rows and columns are applicable to
 * @property {Object} result.rowsToRemove indexes of rows to remove
 * @property {Object} result.columnHeaders column header mappings
 */
const replaySavedCleanOnWebsheet = (
  websheet,
  replayCommands,
  templateHeaders
) => {
  const { commands } = replayCommands;
  const transformedWebsheet = commands.reduce(
    (state, cmd) => {
      const command = getCommand(cmd.action);
      const result = command(state.websheet, {
        ...cmd.params,
        templateHeaders
      });
      return {
        ...state,
        ...result,
        websheet: result.websheet,
        transforms: [...state.transforms, result.transforms],
        baseWebsheet: state.baseWebsheet ?? result.baseWebsheet
      };
    },
    { websheet, transforms: [], baseWebsheet: null }
  );

  // The command outputted something which can be returned immediately so we do that
  if (transformedWebsheet.isFinalResult) {
    return transformedWebsheet;
  }

  // The websheet we return/display for review might not be exact inputted one. This because
  // destructive operations such as column splits were done so that is the returned websheet
  const websheetToDisplay = transformedWebsheet.baseWebsheet ?? websheet;
  const matchingSheet = websheetWizardUtilities.getSheetByNameOrIndex(
    websheetToDisplay,
    {
      sheetName: transformedWebsheet.sheetName,
      sheetIndex: transformedWebsheet.sheetIndex
    }
  );

  const transforms = transformedWebsheet.transforms;
  const finalWebsheet = transformedWebsheet.websheet;
  const sheetData = finalWebsheet[0];

  const rowTransformer = transformerToOriginalRowIndex(transforms);
  const rowsPreserved = new Set(
    sheetData.data.map((_, rowIdx) => rowTransformer(rowIdx))
  );

  const rowsToRemove = matchingSheet.data
    .map((_, rowIdx) => rowIdx)
    .filter(rowIdx => !rowsPreserved.has(rowIdx))
    .map(rowIdx => rowIdx + 1); // cos we will be adding the headers

  const sheetToPreserve = {
    sheetName: transformedWebsheet.sheetName,
    sheetIndex: transformedWebsheet.sheetIndex,
    columnHeaders: transformedWebsheet.columnHeaders,
    rowsToRemove
  };

  return {
    websheet: websheetToDisplay, // websheet to show user in preview
    finalWebsheet,
    sheetsToPreserve: [sheetToPreserve],
    sheetToPreserve, // used internally with aggregate
    debug: transformedWebsheet
  };
};

const addHeadersToSheet = (websheet, sheetName, columnHeaders) => {
  const sheetToAddHeadersTo = websheetWizardUtilities.getSheetByNameOrIndex(
    websheet,
    {
      sheetName
    }
  );

  const selectedHeaders =
    columnHeaders.filter(th => !th.isBlankSelection) ?? [];
  const blankSelectionHeaders =
    columnHeaders.filter(th => th.isBlankSelection) ?? [];

  const sheetWithHeaders = websheetWizardUtilities.insertRowsToSheet(
    sheetToAddHeadersTo,
    0,
    {
      data: [
        sheetToAddHeadersTo.columns.map(
          (_, colIdx) =>
            selectedHeaders.find(h => h.columnIndex === colIdx)?.name ?? ""
        )
      ],
      formats: websheetWizardUtilities.emptyFormatsArray(
        sheetToAddHeadersTo.columns
      ),
      dataValidations: {
        formattedValidations: websheetWizardUtilities.emptyFormatsArray(
          sheetToAddHeadersTo.columns
        )
      }
    }
  );

  const sheetWithHeadersAndBlankColumns =
    websheetWizardUtilities.insertBlankColumnsToSheet(sheetWithHeaders, {
      blankSelectionHeaders
    });

  const sheetIndex = websheet.findIndex(s => s.sheet === sheetName);
  websheet[sheetIndex] = sheetWithHeadersAndBlankColumns;
};

export default {
  execute: replaySavedCleanOnWebsheet,
  convertCleaningTemplateToReplayCommands,
  addHeadersToSheet,
  forTest: {
    transformerToOriginalRowIndex,
    splitColumnByDelimiter
  }
};
