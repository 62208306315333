import React from "react";

import { Trans, withTranslation } from "react-i18next";

import ErrorBox from "@shared-components/errorBox/ErrorBox";

import Button from "@components/atoms/Button";

import "./MilestoneCommentTemplate.scss";

const MilestoneCommentTemplate = props => {
  const {
    viewMode,
    mode,
    comment,
    updateComment,
    saveComment,
    handleChange,
    onClose,
    hasError,
    isEditable,
    t
  } = props;
  return (
    <div className="milestone-comment">
      {viewMode === mode.edit && (
        <>
          {hasError && (
            <ErrorBox
              type="component"
              message="There was an error occurred when saving your comment, please try again."
            ></ErrorBox>
          )}
          <textarea
            className="form-control milestone-comment__textarea"
            value={comment?.comment}
            onChange={handleChange}
            data-testid="milestone-comment-textarea"
            maxLength="4096"
          ></textarea>
          <div className="milestone-comment__actions">
            <Button
              type="secondary"
              label={t(
                "common:ui.projectSummary.milestoneComment.actions.close"
              )}
              onClick={onClose}
            />
            <Button
              type="primary"
              label={t(
                "common:ui.projectSummary.milestoneComment.actions.save"
              )}
              onClick={saveComment}
            />
          </div>
        </>
      )}
      {viewMode === mode.view && (
        <>
          <div className="milestone-comment__content">
            <p className="text-content comment">{comment?.comment}</p>
          </div>
          <div className="milestone-comment__actions">
            <Button
              type="secondary"
              label={t(
                "common:ui.projectSummary.milestoneComment.actions.close"
              )}
              onClick={onClose}
            />
            {isEditable && (
              <Button
                type="primary"
                label={t(
                  "common:ui.projectSummary.milestoneComment.actions.update"
                )}
                onClick={updateComment}
              />
            )}
          </div>
        </>
      )}
      {viewMode === mode.new && (
        <>
          <div className="milestone-comment__content">
            <p className="text-content hint">
              <Trans
                i18nKey="common:ui.projectSummary.milestoneComment.hint"
                context={isEditable ? "editable" : ""}
                components={{
                  click: <a onClick={updateComment} />
                }}
              />
            </p>
          </div>
          <div
            className="milestone-comment__actions"
            data-testid="milestone-comment-actions"
          >
            <Button
              type="secondary"
              label={t(
                "common:ui.projectSummary.milestoneComment.actions.close"
              )}
              onClick={onClose}
            />
            {isEditable && (
              <Button
                type="primary"
                label={t(
                  "common:ui.projectSummary.milestoneComment.actions.add"
                )}
                onClick={updateComment}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default withTranslation()(MilestoneCommentTemplate);
