import React, { Suspense, useContext, useMemo } from "react";

import UIConfigContext from "@app/helpers/UIConfigContext";

const DataTableDropdownMultiselect = props => {
  const uiConfig = useContext(UIConfigContext);

  const ThemedDataTableDropdownMultiselect = useMemo(() => {
    switch (uiConfig?.theme?.designSystem) {
      case "ot":
        return React.lazy(() => import("./OTDataTableDropdownMultiselect"));
      case "tds":
      default:
        return React.lazy(() => import("./TDSDataTableDropdownMultiselect"));
    }
  }, [uiConfig?.theme?.designSystem]);

  return (
    <>
      <Suspense fallback={<div></div>}>
        <ThemedDataTableDropdownMultiselect
          {...props}
          theme={uiConfig?.theme?.designSystem}
        ></ThemedDataTableDropdownMultiselect>
      </Suspense>
    </>
  );
};

export default DataTableDropdownMultiselect;
