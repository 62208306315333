import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

import profileLogo from "@shared/assets/images/defaultUserAvatar.png";
import { useSupportedMimes } from "@shared/hooks";

import Popup from "@shared-components/popup/Popup";

import Avatar from "@components/atoms/Avatar";
import ImageEditor from "@components/molecules/ImageEditor";

import "./userProfileImage.scss";

const UserProfileImage = props => {
  const [profileImageVisibility, setProfileImageVisibility] = useState(false);
  const { supportedMimes, getSupportedMimesForImage } = useSupportedMimes();
  const { watch } = useFormContext();

  const firstname = watch("firstname", "");
  const lastname = watch("lastname", "");

  useEffect(() => {
    getSupportedMimesForImage();
  }, [getSupportedMimesForImage]);

  const handlePopupOutsideClick = () => {
    setProfileImageVisibility(false);
  };

  const handleProfileImageSetClick = base64Image => {
    props.profileImageSetClick(base64Image);
    setProfileImageVisibility(false);
  };

  const handleProfileImageClick = () => {
    setProfileImageVisibility(true);
  };

  const handleProfileImageRemoveClick = () => {
    props.profileImageSetClick(null);
  };

  return (
    <>
      <div className="profile-image">
        <Avatar
          size={"large"}
          user={{
            name: props.name,
            firstname,
            lastname,
            avatar: props.avatar
          }}
        />
        <div className="profile-image__config">
          <span
            className="config-label config-label--change"
            onClick={handleProfileImageClick}
          >
            change
          </span>
          {props.avatar ? (
            <span
              className="config-label config-label--remove"
              onClick={handleProfileImageRemoveClick}
            >
              Remove
            </span>
          ) : (
            ""
          )}
        </div>
      </div>

      <Popup
        visibility={profileImageVisibility}
        handleOutsideClick={false}
        width="60rem"
      >
        <ImageEditor
          image={props.avatar?.image || profileLogo}
          supportedMimes={supportedMimes}
          onSet={handleProfileImageSetClick}
          onCancel={handlePopupOutsideClick}
        />
      </Popup>
    </>
  );
};

UserProfileImage.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    avatar: PropTypes.shape({
      image: PropTypes.string
    })
  }),
  profileImageSetClick: PropTypes.func
};

export default UserProfileImage;
