import React, { createRef, useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { manageProjectActions } from "../../actions";
import TextArea from "../UI/textArea/TextArea";
import Button from "../button/Button";
import DropdownOptions from "../dropdown/DropdownOptions";
import DropdownWithInput from "../dropdown/DropdownWithInput";
import Input from "../input/Input";
import ToCard from "../toCard/ToCard";
import "./messageComposer.scss";

const MessageComposer = props => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState({
    message: props.message || "",
    subject: props.subject || "",
    toList: props.toList || [],
    projectId: props.project ? props.project.id : null
  });
  const [send, setSend] = useState(false);
  const [projects, setProjects] = useState([]);
  const [projectSelection, setProjectSelection] = useState("");
  const [membersOfProject, setMembersOfProject] = useState([]);
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [textBodyFocus, setTextBodyFocus] = useState(false);
  const [assignUser, setAssignUser] = useState({
    name: ""
  });
  const [initialRender, setInitialRender] = useState(true);
  const firstInputRef = createRef();
  const subjectRef = createRef();

  const handleUserNameChange = value => {
    setAssignUser({ ...assignUser, name: value });
  };

  const memberUniquenessKeyFunction = member => {
    return `${member.id}-${member.email}`;
  };

  const handleKeyPress = event => {
    if (event.key === "Enter" && !textBodyFocus && readyToSubmit) {
      handleSend();
    }
    if (event.key === "Escape") {
      handleCancel();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToSubmit, textBodyFocus]);

  useEffect(() => {
    if (props.projects && props.projects.length) {
      setProjects(props.projects);
    }
  }, [props.projects]);

  useEffect(() => {
    if (initialRender && !!firstInputRef.current) {
      firstInputRef.current.focus();
      setInitialRender(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstInputRef.current]);

  useEffect(() => {
    setReadyToSubmit(
      message.toList.length && message.subject && message.projectId
    );
  }, [message.toList.length, message.subject, message.projectId]);

  useEffect(() => {
    if (props.projects && props.projects.length) {
      const updateMembers = membersOfProject.filter(
        member =>
          !message.toList.some(
            user => user.id === member.id && user.email === member.email
          )
      );

      setProjectMembers(updateMembers);
    } else {
      const updateMembers = allProjectMembers.filter(
        member =>
          !message.toList.some(
            user => user.id === member.id && user.email === member.email
          )
      );

      setProjectMembers(updateMembers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message.toList]);

  const members = props.members
    ? props.members.hostUsers.concat(props.members.clientUsers)
    : [];
  const allProjectMembers =
    props.contacts && props.contacts.length
      ? props.contacts.sort(
          (a, b) => a.firstname && a.firstname.localeCompare(b.firstname)
        )
      : members && members.length
      ? members.sort(
          (a, b) => a.firstname && a.firstname.localeCompare(b.firstname)
        )
      : [];
  const [projectMembers, setProjectMembers] = useState(
    !projects.length ? allProjectMembers : []
  );

  const handleSubjectInput = event => {
    event.stopPropagation();
    setMessage({ ...message, subject: event.target.value });
  };

  const handleMessageInput = value => {
    setMessage({ ...message, message: value });
  };

  const handleCancel = () => {
    props.onCancel();
  };

  const handleTextBodyFocus = () => {
    setTextBodyFocus(true);
  };
  const handleTextBodyBlur = () => {
    setTextBodyFocus(false);
  };

  const handleSend = () => {
    setSend(true);
    dispatch(manageProjectActions.sendProjectMessage(message));
    props.onSend();
  };

  const handleUserRemoveFromToList = removeUser => {
    const newToList = message.toList.filter(
      user => !(user.id === removeUser.id && user.email === removeUser.email)
    );
    setMessage({ ...message, toList: newToList });
  };

  const handleUserSelection = newUser => {
    if (
      !message.toList.some(
        user => user.id === newUser.id && user.email === newUser.email
      )
    ) {
      const userList = [...message.toList, newUser];
      setMessage({ ...message, toList: userList });
    }
    subjectRef.current.focus();
  };

  const handleProjectSelection = project => {
    const projMembers = allProjectMembers.filter(mem =>
      mem.projects.some(pro => pro.id === project.id)
    );
    setMembersOfProject(projMembers);

    const updateMembers = projMembers.filter(
      member =>
        !message.toList.some(
          user => user.id === member.id && user.email === member.email
        )
    );

    setMessage({ ...message, projectId: project.id, toList: props.toList });
    setProjectMembers(updateMembers);
    setProjectSelection(project.name);
  };

  const getProjectSelectionTemplate = () => {
    if (projects.length === 1) {
      if (!message.projectId) {
        const projMembers = allProjectMembers.filter(mem =>
          mem.projects.some(pro => pro.id === projects[0].id)
        );
        setMembersOfProject(projMembers);

        const updateMembers = projMembers.filter(
          member =>
            !message.toList.some(
              user => user.id === member.id && user.email === member.email
            )
        );
        setProjectMembers(updateMembers);
        setMessage({ ...message, projectId: projects[0].id });
      }

      return (
        <>
          <div className="message-composer__project-selection">
            Project<span>*</span>:
          </div>
          <div className="message-composer__project-box">
            <div className="message-composer__project-name">
              {projects[0].name}
            </div>
          </div>
        </>
      );
    } else if (projects.length) {
      return (
        <>
          <div className="message-composer__project-selection">
            Project<span>*</span>:
          </div>
          <div className="message-composer__project-selection-dropdown">
            <DropdownOptions
              options={projects}
              handleSelection={handleProjectSelection}
              error={send && !projectSelection}
              value={projectSelection}
            />
          </div>
        </>
      );
    } else {
      return "";
    }
  };

  return (
    <div>
      <div className="message-composer__header--popup">
        <h2 className="message-composer__header--popup-heading">
          Notifications and Messages
        </h2>
      </div>

      <div className="message-composer__container">
        {getProjectSelectionTemplate()}
        <div className="message-composer__label">Select Users*:</div>

        <DropdownWithInput
          ref={firstInputRef}
          type="text"
          multiSelect
          placeholder={"Please enter recipient name"}
          error={false}
          handleChange={handleUserNameChange}
          handleSelection={handleUserSelection}
          handleRemoveFromMultiSelection={handleUserRemoveFromToList}
          value={assignUser.name}
          values={message.toList}
          list={projectMembers}
          listItemKeyFunction={memberUniquenessKeyFunction}
          maxLength={64}
        />

        <div className="message-composer__label">
          Subject<span>*</span>:
        </div>
        <Input
          ref={subjectRef}
          handleChange={handleSubjectInput}
          type={"text"}
          value={message.subject}
          error={!!(send && !message.subject)}
          placeholder={"Please enter subject"}
          maxLength={128}
        />
        <div className="message-composer__label">Body:</div>
        <TextArea
          handleChange={handleMessageInput}
          type="text"
          initialValue={message.message}
          maxLength={8192}
          onFocus={handleTextBodyFocus}
          onBlur={handleTextBodyBlur}
          placeholder={"Please enter your message"}
        />
        <div className="message-composer__buttons">
          <div className="message-composer__buttons-cancel">
            <Button
              type="plain-primary"
              name="Cancel"
              handleClick={handleCancel}
            />
          </div>
          <div className="message-composer__buttons-send">
            <Button
              name="Send"
              handleClick={handleSend}
              type={readyToSubmit ? "primary" : "disabled"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageComposer;
