import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  pageFilters: {},
  reviewFilters: {},
  columnFilters: {},
  hiddenCharts: {},
  drilldownFilters: {}
};

const initialStateJSONEditor = {};

export const interactiveReportJsonEditorSlice = createSlice({
  name: "interactiveReportJsonEditor",
  initialState: initialStateJSONEditor,
  reducers: {
    editJSON: (state, action) => {
      const projectId = action?.payload?.projectId;
      if (!projectId) {
        return;
      }

      state[projectId] = {
        ...state[projectId],
        jsonContent: action.payload?.jsonContent
      };
    },
    isEditing: (state, action) => {
      state[action.payload?.projectId].isEditing = action.payload.isEditing;
    },
    isGenerated: (state, action) => {
      state[action.payload?.projectId].isGenerated = action.payload.isGenerated;
    }
  }
});

export const interactiveReportSlice = createSlice({
  name: "interactiveReport",
  initialState,
  reducers: {
    savePageFilter: (state, action) => {
      if (
        action.payload?.pageId === undefined ||
        action.payload?.projectId === undefined
      )
        return;
      state.pageFilters[
        `${action.payload?.projectId}-${action.payload?.pageId}`
      ] = action.payload?.filter;
    },
    saveDrilldownFilter: (state, action) => {
      if (
        action.payload?.pageId === undefined ||
        action.payload?.projectId === undefined ||
        action.payload?.selectedRowId === undefined
      )
        return;
      state.drilldownFilters[
        `${action.payload?.projectId}-${action.payload?.pageId}-${action.payload?.selectedRowId}`
      ] = action.payload?.filter;
    },
    toggleReviewFilter: (state, action) => {
      if (
        action.payload?.pageId === undefined ||
        action.payload?.projectId === undefined ||
        action.payload?.rowId === undefined
      ) {
        return;
      }
      const reviewKey = `${action.payload?.projectId}-${action.payload?.pageId}`;
      const reviewValue = state.reviewFilters[reviewKey] ?? [];
      const rowId = action.payload?.rowId;

      const index = reviewValue.indexOf(rowId);
      if (reviewValue.indexOf(rowId) > -1) {
        reviewValue.splice(index, 1);
      } else {
        reviewValue.push(rowId);
      }
      state.reviewFilters[reviewKey] = reviewValue;
    },
    toggleColumnFilter: (state, action) => {
      if (
        action.payload?.pageId === undefined ||
        action.payload?.projectId === undefined
      ) {
        return;
      }
      const columnKey = `${action.payload?.projectId}-${action.payload?.pageId}`;
      const selectedColumns = action.payload?.columns;
      state.columnFilters[columnKey] = structuredClone(selectedColumns);
    },
    resetPageFilter: (state, action) => {
      delete state.pageFilters[
        `${action.payload?.projectId}-${action.payload?.pageId}`
      ];
    },
    resetPageAndReviewFilters: state => {
      state.pageFilters = {};
      state.reviewFilters = {};
    },
    resetColumnFilters: state => {
      state.columnFilters = {};
    },
    resetCharts: state => {
      state.hiddenCharts = {};
    },
    toggleChartHidden: (state, action) => {
      const { pageId, chartId } = action.payload;
      const { hiddenCharts } = state;
      hiddenCharts[pageId] = {
        ...hiddenCharts[pageId],
        [chartId]: !hiddenCharts[pageId]?.[chartId]
      };
    },
    checkFilter: (state, action) => {
      const projectId = action.payload?.project?.id;
      if (!projectId) {
        return;
      }
      for (const filterKey of Object.keys(initialState)) {
        // This code is filtering out all filters that are not for the current project
        state[filterKey] = Object.entries(state[filterKey])
          .filter(([k]) => k.startsWith(`${projectId}-`))
          .reduce((acc, [k, v]) => {
            acc[k] = v;
            return acc;
          }, {});
      }
    }
  }
});

export const {
  checkFilter,
  savePageFilter,
  resetPageFilter,
  resetPageAndReviewFilters,
  resetColumnFilters,
  toggleReviewFilter,
  toggleColumnFilter,
  toggleChartHidden,
  resetCharts,
  saveDrilldownFilter,
  editJSON,
  isEditing,
  isGenerated
} = {
  ...interactiveReportSlice.actions,
  ...interactiveReportJsonEditorSlice.actions
};

export const interactiveReportSliceReducer = interactiveReportSlice.reducer;
export const interactiveReportJsonEditorSliceReducer =
  interactiveReportJsonEditorSlice.reducer;