import { useRef, useState } from "react";
import { useCallback } from "react";

export const useSearchableDropdown = props => {
  const { dropdownItems } = props;
  const [searchItems, setSearchItems] = useState("");
  const inputRef = useRef(null);
  const focusInput = () => inputRef?.current?.focus();

  const getDropdownItems = useCallback(() => {
    const itemToSearch = searchItems.toLowerCase();
    if (searchItems === "") {
      return dropdownItems;
    }
    return (
      dropdownItems
        .filter(item =>
          item?.name?.toLowerCase().includes(searchItems.toLowerCase())
        )
        .sort(
          (a, b) =>
            a.name.toLowerCase().indexOf(itemToSearch) -
            b.name.toLowerCase().indexOf(itemToSearch)
        ) ?? []
    );
  }, [dropdownItems, searchItems]);

  const handleClearFilter = () => {
    setSearchItems("");
  };

  return {
    inputRef,
    focusInput,
    searchItems,
    setSearchItems,
    getDropdownItems,
    handleClearFilter
  };
};
