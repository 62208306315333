import React from "react";

import PropTypes from "prop-types";

import { getHeightOfElement } from "@app/helpers/componentHelpers";

import Title from "@components/atoms/Title/Title";

import "./SidePanelContentTemplate.scss";

const SidePanelContentTemplate = ({
  title,
  titleStyle,
  subtitle,
  filters,
  content,
  onClose,
  sidePanelContentClassName,
  adjustment
}) => {
  return (
    <div
      className={`side-panel-template ${sidePanelContentClassName}`}
      style={{
        maxHeight: `calc(100vh - ${
          getHeightOfElement("page-template__header") || 135
        }px - ${adjustment})`
      }}
    >
      {onClose && (
        <div className="side-panel-template__close" onClick={onClose}>
          <i className="material-icons">close</i>
        </div>
      )}
      {titleStyle === "styled" ? (
        <Title title={title} />
      ) : (
        <div className={"side-panel-template__title"}>{title}</div>
      )}
      {subtitle && (
        <div className={"side-panel-template__subtitle"}>{subtitle}</div>
      )}
      {filters && (
        <div className={"side-panel-template__filter"}>{filters}</div>
      )}
      <div className={"side-panel-template__content"}>{content ?? ""}</div>
    </div>
  );
};

SidePanelContentTemplate.defaultProps = {
  titleStyle: "text",
  onClose: null,
  adjustment: "5rem",
};

SidePanelContentTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  filters: PropTypes.node,
  content: PropTypes.node,
  titleStyle: PropTypes.oneOf(["text", "styled"]),
  onClose: PropTypes.func,
  sidePanelContentClassName: PropTypes.string
};

export default SidePanelContentTemplate;
