import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageProjectActions } from "@shared/actions";
import { systemConstants } from "@shared/constants";

export function useSupportedDocumentMimes() {
  const [supportedDocumentMimes, setSupportedDocumentMimes] = useState(
    systemConstants.mimes.document
  );
  const manageProject = useSelector(state => state.manageProject);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(manageProjectActions.getSupportedMimesForDocuments());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSupportedDocumentMimes(manageProject.supportedDocumentMimes);
  }, [manageProject.supportedDocumentMimes]);

  return { supportedDocumentMimes };
}
