import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { routeConstants } from "@constants";

import { systemConstants } from "@shared/constants";
import { useAuthUser, useFeatures } from "@shared/hooks";
import { useGetClientEngagementsQuery } from "@shared/services/engagementsService";
import { useGetFeaturesQuery } from "@shared/services/featuresService";

import FormPageTemplate from "@components/templates/FormPageTemplate/FormPageTemplate";

import AddEngagementProjectForm from "./AddEngagementProjectForm/AddEngagementProjectForm";

const AddEngagementProject = () => {
  const { t } = useTranslation();
  const { engagementId } = useParams();
  const { user } = useAuthUser();
  const { data: engagement, isLoading: loadingEngagement } =
    useGetClientEngagementsQuery(
      { engagementId, hostId: user?.hostId },
      { skip: !engagementId || !user?.hostId }
    );
  const navigate = useNavigate();
  const { isLoading: loadingFeatures } = useGetFeaturesQuery();
  const { isEnabled: isFeatureEnabled, isLoading: isFeaturesLoading } =
    useFeatures();

  useEffect(() => {
    const isEngagementsEnabled = isFeatureEnabled(
      systemConstants.features.engagements
    );
    if (!isFeaturesLoading && !isEngagementsEnabled) {
      navigate(routeConstants.notFound);
    }
  }, [isFeatureEnabled, isFeaturesLoading, navigate]);

  return (
    <FormPageTemplate
      title={t("common:ui.projects.title_add")}
      breadcrumbs={[
        {
          linkTo: routeConstants.manageClientEngagements,
          label: t("common:ui.engagement.navigation.title")
        },
        {
          linkTo: {
            pathname: `${routeConstants.engagements}/${engagement?.[0]?.id}`
          },
          label: engagement?.[0]?.name
        }
      ]}
      isLoading={loadingEngagement || loadingFeatures}
      formElement={<AddEngagementProjectForm engagement={engagement?.[0]} />}
    />
  );
};

export default AddEngagementProject;
