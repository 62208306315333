import React, { useState } from "react";

import PropTypes from "prop-types";
import _DatePicker from "react-date-picker";
import { useTranslation } from "react-i18next";

import dateFormatter from "@shared/helpers/dateHelper";
import { utilities } from "@shared/helpers/utilities";
import { useLocaleDate } from "@shared/hooks/useLocaleDate";
import { useUpdateQuery } from "@shared/hooks/useUpdateQuery";

import { getActionIndicator } from "@app/helpers";
import { formatDayOfWeek, isDateBefore } from "@app/helpers/date";

import Avatar from "@components/atoms/Avatar";
import BrandButton from "@components/atoms/Button/BrandButton";
import Icon from "@components/atoms/Icon";
import Pill from "@components/atoms/Pill";
import BoxTemplate from "@components/templates/BoxTemplate";

import AvatarList from "../AvatarList/AvatarList";
import "./RequestDetailsBox.scss";

const RequestDetailsBox = props => {
  const { pillConfig, requestQuery, queryConfig } = props;
  const { t } = useTranslation();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const { updateQuery } = useUpdateQuery();
  const { locale, options } = useLocaleDate();
  const actionIndicator = getActionIndicator(props.requestQuery);
  const colorStyle = actionIndicator.colorScheme;
  const statusText = t(
    `requests:requests.configured.status.${requestQuery.status.toUpperCase()}.label`,
    { context: requestQuery.queryType }
  );
  const showStatus = pillConfig?.actionItemStatus?.shape === "rectangle";
  const isReminderDateUpcoming = !isDateBefore(
    requestQuery.reminderDate,
    new Date().toString()
  );
  const renderTags = () => {
    const sortedTags = utilities.sortBy("name")(
      structuredClone(requestQuery.tags)
    );
    return sortedTags.map(tag => {
      return (
        <Pill
          key={tag.id}
          size="medium"
          label={tag.name}
          flexMode={"block"}
          colorScheme={"outline-grey"}
          shape={pillConfig?.tag.shape}
        />
      );
    });
  };

  const handleEditReminderDate = () => setIsCalendarOpen(!isCalendarOpen);

  const handleResetReminderDate = () =>
    updateQuery({ ...requestQuery, reminderDate: null });

  const handleDateChange = date => {
    const formattedDate = utilities.formatDateOnly(date);
    updateQuery({
      ...requestQuery,
      reminderDate: formattedDate
    });
  };

  const renderStatus = () => {
    return (
      <Pill
        size="medium"
        label={statusText}
        flexMode={"flex"}
        colorScheme={colorStyle}
        borderColorScheme={"transparent"}
      />
    );
  };

  const renderCopiedTo = () => {
    const copiedToField = queryConfig?.fields?.find(f => f.key === "copiedTo");
    if (!copiedToField) {
      return <></>;
    }

    const hasCopiedToUsers = requestQuery.copiedTo?.length;

    return (
      <div className="request-details-box__property">
        <div className="request-details-box__property-field">
          {t("requests:requests.configured.fields.copiedTo.pastTenseLabel", {
            context: requestQuery.queryType
          })}
          :
        </div>
        <div className="request-details-box__property-value">
          <div className="request-details-box__property-value-copyTo request-details-box--hover-reveal">
            {hasCopiedToUsers ? (
              <div className="request-details-box__user">
                <AvatarList
                  size={"small"}
                  users={requestQuery.copiedTo}
                  numOfUsers={3}
                />
              </div>
            ) : (
              ""
            )}
            <div
              className={`request-details-box__action ${
                hasCopiedToUsers
                  ? "request-details-box--hover-reveal__target"
                  : ""
              }`}
            >
              <BrandButton
                iconName="edit"
                type="text"
                size="minimal"
                onClick={props.onClickEditCopiedTo}
                {...(hasCopiedToUsers
                  ? {
                      iconSize: "medium"
                    }
                  : {
                      label: t("common:ui.forms.edit.label")
                    })}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <BoxTemplate boxClassName="request-details-box" title={props.title}>
      <div className="request-details-box__property-list">
        <div className="request-details-box__property">
          <div className="request-details-box__property-field">
            {t("requests:requests.refLabel")}:
          </div>
          <div className="request-details-box__property-value" role="reference">
            {requestQuery.refNo}
          </div>
        </div>
        {!props.hideRequiredByField && requestQuery.requiredBy && (
          <div className="request-details-box__property">
            <div className="request-details-box__property-field">
              {t("requests:requests.configured.fields.requiredBy.label", {
                context: requestQuery.queryType
              })}
              :
            </div>
            <div className="request-details-box__property-value">
              {dateFormatter(
                requestQuery?.requiredBy,
                locale,
                options.longFormat
              )}
            </div>
          </div>
        )}
        {props.showReminderDate && (
          <div className="request-details-box__property">
            <div className="request-details-box__property-field">
              {t("requests:requests.configured.fields.reminderDate.label", {
                context: requestQuery.queryType
              })}
              :
            </div>
            <div className="request-details-box__property-value">
              <div className="request-details-box__reminder-date">
                {dateFormatter(
                  requestQuery.reminderDate,
                  locale,
                  options?.longFormat
                ) ?? "N/A"}
              </div>
              <div className="request-details-box__reminder-date--actions">
                {isReminderDateUpcoming && (
                  <Icon
                    name="edit"
                    fillStyle="filled"
                    size="small"
                    onClick={handleEditReminderDate}
                  />
                )}
                {isReminderDateUpcoming && requestQuery.reminderDate && (
                  <Icon
                    name="delete"
                    fillStyle="filled"
                    designStyle="material-icons"
                    size="small"
                    onClick={handleResetReminderDate}
                  />
                )}
              </div>
              {/* There is a bug in react-date-picker library, if format="dd-MMMM-yy" then month section will become a <select> element */}
              {/* Solution: hide value in datePicker and use div above to display it */}
              <_DatePicker
                value={utilities.safeDate(requestQuery.reminderDate)}
                onChange={date => handleDateChange(date)}
                format="dd-MMMM-yy"
                formatShortWeekday={(_, date) => formatDayOfWeek(date)}
                isOpen={isCalendarOpen}
                clearIcon={null}
                nextLabel={
                  <Icon name="navigate_next" fillStyle="filled" size="small" />
                }
                prevLabel={
                  <Icon
                    name="navigate_before"
                    fillStyle="filled"
                    size="small"
                  />
                }
                dayPlaceholder={null}
                monthPlaceholder={null}
                yearPlaceholder={null}
                calendarIcon={null}
                onCalendarClose={() => {
                  setIsCalendarOpen(false);
                }}
              />
            </div>
          </div>
        )}
        {showStatus && (
          <div className="request-details-box__property">
            <div className="request-details-box__property-field">
              {t("requests:requests.statusLabel")}:
            </div>
            <div
              className="request-details-box__property-value"
              data-testid="test-request-detail-status"
            >
              {statusText}
            </div>
          </div>
        )}
        {requestQuery.assignedTo && (
          <div className="request-details-box__property">
            <div className="request-details-box__property-field">
              {t(
                "requests:requests.configured.fields.assignedTo.pastTenseLabel",
                {
                  context: requestQuery.queryType
                }
              )}
              :
            </div>
            <div className="request-details-box__property-value">
              <div className="request-details-box__property-value-assignedTo">
                <div className="request-details-box__user">
                  <Avatar
                    user={requestQuery.assignedTo}
                    width="2rem"
                    height="2rem"
                  ></Avatar>
                  <span data-testid={"request-details-box__asignedToName"}>
                    {requestQuery.assignedTo.name}
                  </span>
                </div>
                {!showStatus && (
                  <div className="request-details-box__status">
                    {renderStatus()}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {renderCopiedTo()}
        {props.milestoneContent && (
          <div className="request-details-box__property">
            <div className="request-details-box__property-field">
              {t("requests:requests.configured.fields.milestone.label")}:
            </div>
            <div className="request-details-box__property-value">
              {t(props.milestoneContent)}
            </div>
          </div>
        )}
        <div className="request-details-box__property">
          <div className="request-details-box__property-field">
            {t("requests:requests.configured.fields.tags.label")}:
          </div>
          <div className="request-details-box__property-value">
            <div className="request-details-box__tags">{renderTags()}</div>
            {props.canEditLabels && (
              <span
                className={`${
                  requestQuery?.tags?.length
                    ? "request-details-box__edit-labels-btn"
                    : "request-details-box__edit-labels-btn--no-tags"
                }`}
              >
                <BrandButton
                  iconName="edit"
                  label={t("common:ui.forms.edit.label")}
                  type="text"
                  onClick={props.onClickEditLabels}
                  {...{
                    size: requestQuery?.tags?.length > 0 ? null : "minimal"
                  }}
                />
              </span>
            )}
          </div>
        </div>
      </div>
    </BoxTemplate>
  );
};

RequestDetailsBox.defaultProps = {};

RequestDetailsBox.propTypes = {
  title: PropTypes.string.isRequired,
  showReminderDate: PropTypes.bool,
  hideRequiredByField: PropTypes.bool,
  pillConfig: PropTypes.object,
  milestoneContent: PropTypes.string,
  requestQuery: PropTypes.shape({
    requiredBy: PropTypes.string,
    assignedTo: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      avatar: PropTypes.shape({
        image: PropTypes.string
      })
    }),
    status: PropTypes.string.isRequired,
    description: PropTypes.string,
    query: PropTypes.string,
    refNo: PropTypes.number,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  queryConfig: PropTypes.any.isRequired,
  canEditLabels: PropTypes.bool,
  onClickEditLabels: PropTypes.func,
  onClickEditCopiedTo: PropTypes.func
};
export default RequestDetailsBox;
