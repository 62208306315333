import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageProjectActions } from "@shared/actions";

export function useFetchMilestones() {
  const manageProject = useSelector(state => state.manageProject);
  const [milestones, setMilestones] = useState([]);
  const [isMilestonesLoading, setIsMilestonesLoading] = useState(false);
  const [error, setError] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    setMilestones(structuredClone(manageProject.milestones));
  }, [manageProject.milestones]);

  useEffect(() => {
    setIsMilestonesLoading(manageProject.milestonesLoading);
  }, [manageProject.milestonesLoading]);

  useEffect(() => {
    setError(manageProject.error);
  }, [manageProject.error]);

  const fetchMilestones = useCallback(
    project => {
      if (!manageProject.milestonesLoading) {
        dispatch(manageProjectActions.getProjectMilestones(project));
      }
    },
    [dispatch, manageProject.milestonesLoading]
  );

  return {
    milestones,
    fetchMilestones,
    isMilestonesLoading,
    error
  };
}
