import React from "react";

import PropTypes from "prop-types";

import "./CircleIndicator.scss";

const StatusIndicator = props => {
  return (
    <i
      className={`material-icons circle-indicator circle-indicator--color-${props.colorScheme}`}
    >
      lens
    </i>
  );
};

StatusIndicator.defaultProps = {
  colorScheme: "dark"
};

StatusIndicator.propTypes = {
  colorScheme: PropTypes.oneOf(["dark", "success", "error", "warning"])
    .isRequired
};

export default StatusIndicator;
