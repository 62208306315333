import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { useSendExternalRequestMutation } from "@shared/hooks";
import { useUrlHash } from "@shared/hooks/useUrlHash";

import { paginationHelper } from "@app/helpers/pagination";
import { smartFormActions, smartFormStructure } from "@app/helpers/smartForm";
import { conditionalLogicStrategy } from "@app/helpers/smartForm/evaluationStrategies/conditionalLogic/conditionalLogicStrategy";
import { simpleParentStrategy } from "@app/helpers/smartForm/evaluationStrategies/simpleParent/simpleParentStrategy";

import InfoMessageBox from "@components/molecules/InfoMessageBox";
import Pagination from "@components/molecules/Pagination/Pagination";
import { processedQuestionPropType } from "@components/molecules/SmartFormTable/SmartFormTable";

import "./SmartForm.scss";
import SmartFormCategory from "./SmartFormCategory";

const SmartForm = ({
  queryId,
  isPending,
  isClosed,
  conditionals: conditionalLogicConfig,
  questions,
  answers,
  relevantEntitiesById,
  handlers,
  disableAnswerSubmissions,
  isPendingFlagEnabled,
  refetchQuery
}) => {
  const { t } = useTranslation();
  const { urlHashDetail } = useUrlHash();
  const location = useLocation();
  const [currentCategory, setCurrentCategory] = useState(undefined);
  const [sendExternalRequest] = useSendExternalRequestMutation();
  const lastCheckedCurrentCategoryDataRef = useRef(null);
  const urlRef = useRef(location.pathname + location.search + location.hash);
  const shouldSendToExternalRef = useRef(false);
  const currentCategoryRef = useRef();

  const highlightedQuestionId = useMemo(() => {
    const questionId = urlHashDetail.get("questionId");
    return isNaN(+questionId) ? null : +questionId;
  }, [urlHashDetail]);

  const smartFormData = useMemo(() => {
    if (answers === undefined) {
      return {};
    }

    const strategy = (function getStrategy() {
      if (conditionalLogicConfig) {
        return conditionalLogicStrategy(
          conditionalLogicConfig ?? [],
          questions,
          relevantEntitiesById,
          answers
        );
      }
      return simpleParentStrategy();
    })();

    return smartFormStructure.getRelevantSmartFormData(questions, answers, {
      defaultSubCategoryName: t(
        "requests:requests.ui.smartForm.subcategory.label"
      ),
      relevantEntitiesById,
      highlightedQuestionId,
      strategy
    });
  }, [
    answers,
    questions,
    t,
    relevantEntitiesById,
    highlightedQuestionId,
    conditionalLogicConfig
  ]);

  const categories = Object.keys(smartFormData);
  const isLastPage = categories[categories.length - 1] === currentCategory;

  const formMethods = useForm({
    reValidateMode: "onSubmit"
  });

  useEffect(() => {
    if (
      isEmpty(smartFormData[currentCategory]) ||
      !lastCheckedCurrentCategoryDataRef.current ||
      JSON.stringify(lastCheckedCurrentCategoryDataRef.current) ===
        JSON.stringify(smartFormData[currentCategory])
    ) {
      shouldSendToExternalRef.current = false;
      return;
    }
    const allQuestions = Object.values(
      smartFormData[currentCategory].subcategories
    ).flat();
    // do not need to check isVisible cuz all questions in smartFormData are actually visible
    const candidateQuestions = allQuestions.filter(q => q.isMandatory);
    shouldSendToExternalRef.current =
      smartFormStructure.checkQuestionsCompletedByOTUser({
        questions: candidateQuestions,
        answers,
        entities: relevantEntitiesById
      });
  }, [answers, currentCategory, relevantEntitiesById, smartFormData]);

  // load current category data in ref when smartFormData is ready
  useEffect(() => {
    if (!isEmpty(smartFormData) && !lastCheckedCurrentCategoryDataRef.current) {
      lastCheckedCurrentCategoryDataRef.current =
        smartFormData[currentCategory];
    }
  }, [currentCategory, smartFormData]);

  // send external request when navigate to other pages
  useEffect(
    () => () => {
      if (lastCheckedCurrentCategoryDataRef.current === null) {
        return;
      }
      if (shouldSendToExternalRef.current) {
        shouldSendToExternalRef.current = false;
        sendExternalRequest({
          queryId,
          currentCategory: currentCategoryRef.current
        });
      }
    },
    // this useEffect should only run on unmount, so we don't want to add any other dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // send external request when navigate to other categories in the smartForm
  useEffect(() => {
    if (
      urlRef.current ===
      location.pathname + location.search + location.hash
    ) {
      return;
    }
    lastCheckedCurrentCategoryDataRef.current = null;
    urlRef.current = location.pathname + location.search + location.hash;
    if (shouldSendToExternalRef.current) {
      shouldSendToExternalRef.current = false;
      sendExternalRequest({ queryId, currentCategory })
        .unwrap()
        .then(() => {
          refetchQuery(queryId);
        });
    }
  }, [currentCategory, location, queryId, refetchQuery, sendExternalRequest]);

  useEffect(() => {
    if (!isEmpty(smartFormData) && currentCategory === undefined) {
      const pageNumber = urlHashDetail.get("page") ?? 1;
      const newCurrentCategory = Object.keys(smartFormData).find(
        key => smartFormData[key].order === +pageNumber - 1
      );
      setCurrentCategory(newCurrentCategory);
      currentCategoryRef.current = newCurrentCategory;
    }
  }, [currentCategory, smartFormData, urlHashDetail]);

  const pageWithHighlightedQuestion = useMemo(() => {
    if (!highlightedQuestionId) {
      return null;
    }
    let page = null;
    Object.entries(smartFormData).forEach(([_, categoryData]) => {
      if (categoryData.questionHighlighted) {
        page = categoryData.order + 1;
      }
    });
    return page;
  }, [highlightedQuestionId, smartFormData]);

  useEffect(() => {
    if (highlightedQuestionId) {
      setTimeout(() => {
        const questionElement = document.getElementById(
          `smart-form-question-${highlightedQuestionId}`
        );
        questionElement?.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
      }, 1000);
    }
  }, [highlightedQuestionId]);

  const pageInfo = useMemo(() => {
    const data = {};
    Object.entries(smartFormData).forEach(([categoryName, categoryData]) => {
      data[`${categoryData.order + 1}`] = {
        name: categoryName,
        completeCount: categoryData.completeCount,
        totalCount: categoryData.totalCount
      };
    });
    return data;
  }, [smartFormData]);

  const totalPages = useMemo(() => Object.keys(pageInfo).length, [pageInfo]);

  const handleChangeCategory = pageNum => {
    const newCurrentCategory = pageInfo[pageNum]
      ? pageInfo[pageNum]?.name
      : currentCategory;
    setCurrentCategory(newCurrentCategory);
    currentCategoryRef.current = newCurrentCategory;
  };

  const updateAnswer = useCallback(
    (questionId, answer) => {
      handlers[smartFormActions.SAVE_ANSWERS]({ questionId, answer });
    },
    [handlers]
  );

  const renderInfoMessage = useCallback(() => {
    if (
      !isLastPage ||
      !isPendingFlagEnabled ||
      isPending === undefined ||
      isClosed
    ) {
      return null;
    }
    if (isPending) {
      return (
        <InfoMessageBox
          iconProps={{
            name: "autorenew",
            fillStyle: "outlined",
            colorStyle: "accent"
          }}
          message={t("requests:requests.ui.smartForm.infoMessage.pending.on")}
        />
      );
    }
    return (
      <InfoMessageBox
        iconProps={{
          name: "check_circle",
          fillStyle: "filled",
          colorStyle: "success"
        }}
        message={t("requests:requests.ui.smartForm.infoMessage.pending.off")}
      />
    );
  }, [isLastPage, isPending, isPendingFlagEnabled, isClosed, t]);

  const categoryHandlers = useMemo(
    () => ({
      ...handlers,
      [smartFormActions.SUPPLY_ANSWER]: updateAnswer
    }),
    [handlers, updateAnswer]
  );

  return (
    <div className="smart-form" data-testid={"testSmartForm"}>
      {!isEmpty(smartFormData) && currentCategory !== undefined && (
        <>
          <div className="smart-form__categories">
            <FormProvider {...formMethods}>
              <SmartFormCategory
                key={currentCategory}
                dataTestId={"testSmartFormCategory"}
                categoryName={currentCategory}
                queryId={queryId}
                categoryData={smartFormData[currentCategory]}
                handlers={categoryHandlers}
                relevantEntitiesById={relevantEntitiesById}
                disableAnswerSubmissions={disableAnswerSubmissions}
                alwaysShowEntitiesIcon={!!conditionalLogicConfig}
              />
            </FormProvider>
          </div>
          {renderInfoMessage()}
          <Pagination
            totalPages={totalPages}
            handlePageChange={handleChangeCategory}
            pageInfo={pageInfo}
            type={paginationHelper.paginationType.completionStatus}
            startingPage={pageWithHighlightedQuestion}
          />
        </>
      )}
    </div>
  );
};

SmartForm.defaultProps = {
  relevantEntitiesById: {}
};

SmartForm.propTypes = {
  queryId: PropTypes.number,
  queryStatus: PropTypes.string,
  isPending: PropTypes.bool,
  isPendingFlagEnabled: PropTypes.bool,
  smartFormData: PropTypes.objectOf(
    PropTypes.objectOf(PropTypes.arrayOf(processedQuestionPropType))
  ),
  handlers: PropTypes.shape({
    openCommentModal: PropTypes.func,
    openEntitiesModal: PropTypes.func,
    saveAnswers: PropTypes.func
  }),
  relevantEntitiesById: PropTypes.any,
  disableAnswerSubmissions: PropTypes.bool
};

export default SmartForm;
