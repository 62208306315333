import React from "react";

import PropTypes from "prop-types";

import Icon from "@components/atoms/Icon";

const WebsheetColumn = ({ onClick, file, action }) => {
  const label = file.name ?? "";
  return (
    <div className="smart-form-modal__table__content">
      <div className="document-cell websheet-cell" onClick={onClick}>
        <Icon
          name="view_module"
          designStyle="material-icons"
          fillStyle="outlined"
          className="document-cell__icon"
        />
        <div className="websheet-cell__label">{label}</div>
        <div className="websheet-cell__actions">
          <Icon
            name={"open_in_new"}
            designStyle="material-icons"
            fillStyle={"outlined"}
            className="document-state-cell websheet-cell__open_websheet"
            size={"medium"}
          />
        </div>
      </div>
      {action && action(file)}
    </div>
  );
};

WebsheetColumn.propTypes = {
  onClick: PropTypes.func,
  file: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  action: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
};

export default WebsheetColumn;
