import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { withTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants/systemConstants";
import {
  useActionItemFilters,
  useAuthUser,
  useDidMount,
  useFeatures,
  useGetProjectMembers,
  useProjectQueries,
  useRequestPageNavigator,
  useUIConfig,
  useUpdateQuery
} from "@shared/hooks";
import { useGetActionItemTypesQuery } from "@shared/services/actionItemTypesService";

import { expectedActionIndicators, expectedQueryTypes } from "@app/helpers";
import { getActionItemTypeConfiguration } from "@app/helpers/actionItems";

import BrandButton from "@components/atoms/Button/BrandButton";
import ActionItemsFilterLegend from "@components/molecules/ActionItemsFilterLegend";
import { actionItemsFilters } from "@components/molecules/ActionItemsFilterLegend/ActionItemsFilterLegend";
import ProjectAccessModal from "@components/molecules/ProjectAccessModal";
import ActionItemsTable from "@components/organisms/ActionItemsTable";
import AddOrCopyProjectActionItems from "@components/organisms/AddOrCopyProjectActionItems";
import UpdateActionForm from "@components/organisms/UpdateActionForm";
import PageTemplate from "@components/templates/PageTemplate/PageTemplate";

import WorkpaperAudits from "../WorkpaperAudits/WorkpaperAudits";

const UserFilterTypes = {
  None: 1,
  MyActions: 2,
  ClientTeamActions: 3,
  HostTeamActions: 4
};

const statusTypes = systemConstants.project.queries.status;
const filterTypes = systemConstants.dashboard.actionItemsFilterType;

const ActionItems = props => {
  const { t } = props;
  const { navigateToRequestPage } = useRequestPageNavigator();

  const { user } = useAuthUser();
  const [project, setProject] = useState(props.project);
  const { isProjectMember, members, membersLoading } = useGetProjectMembers(
    props.project
  );
  const [historyId, setHistoryId] = useState(null);
  const { updateQuery } = useUpdateQuery();
  const { didMountRef, startLoading } = useDidMount();
  const isShowAskHelp = useRef(false);
  const [showCreateActionForm, setShowCreateActionForm] = useState(false);
  const [showUpdateActionForm, setShowUpdateActionForm] = useState(false);
  const [showProjectAccessModal, setShowProjectAccessModal] = useState(false);
  const [filterUsers, setFilterUsers] = useState([]);
  const [sortedColumn, setSortedColumn] = useState([]);
  const [erroMessage, setErrorMessage] = useState("");
  const [selectedQuery, setSelectedQuery] = useState(null);
  const {
    onChangeRequestFilter,
    onChangeAssignedToUsers,
    onChangeUserFilterType,
    onChangeTableFilters,
    resetActionitemTableFilter,
    requestFilter,
    userFilterType,
    assignedToUsers,
    tableFilters
  } = useActionItemFilters();
  const [actionItemsFilter, setActionItemsFilter] = useState({
    status: null,
    dueDate: null
  });
  const actionIndicators = expectedActionIndicators();
  const [currentUserFilter, setCurrentUserFilter] = useState(1);
  const engagementTypeId = project?.engagement?.engagementTypeId;
  const { data: actionItemTypes } = useGetActionItemTypesQuery(
    {
      engagementTypeId,
      projectId: project?.id
    },
    { skip: engagementTypeId === undefined }
  );

  const { queryTypes, allQueryTypeKeys } = useMemo(
    () => expectedQueryTypes(actionItemTypes, t),
    [actionItemTypes, t]
  );

  const { queries, isLoading, error } = useProjectQueries(
    project,
    allQueryTypeKeys
  );
  const { uiConfig } = useUIConfig();
  const [pillsConfig, setPillsConfig] = useState({
    filter: {
      shape: "rectangle",
      activeType: "outline"
    },
    status: {
      shape: "rectangle",
      activeType: "outline"
    }
  });
  const [filterConfig, setFilterConfig] = useState({
    status: {
      enabled: true,
      filters: ["open", "close"]
    },
    dueDate: {
      enabled: true,
      filters: ["upcoming", "overdue"]
    }
  });
  const [indicatorUiConfig, setIndicatorUiConfig] = useState();
  const { isEnabled } = useFeatures();

  const getHostTeam = useCallback(
    members => [...(members?.hostUsers ?? [])],
    []
  );
  const getClientTeam = useCallback(
    members => [...(members?.clientUsers ?? [])],
    []
  );

  const getMyUser = useCallback(() => {
    const { isMemberOfUserGroup, ...others } = user;
    return [others];
  }, [user]);

  const applyFilterType = useCallback(
    filters => {
      const updatedFilter = structuredClone(actionItemsFilter);
      resetActionitemTableFilter();
      const userTypes = user?.isHostUser
        ? UserFilterTypes.HostTeamActions
        : UserFilterTypes.ClientTeamActions;
      const filteredUsers = user?.isHostUser
        ? getHostTeam(filters.members)
        : getClientTeam(filters.members);
      switch (filters.type) {
        case filterTypes.myPastDueItems:
          onChangeAssignedToUsers(getMyUser());
          onChangeRequestFilter({
            ...updatedFilter,
            dueDate: actionIndicators.overdue.key
          });
          onChangeUserFilterType(UserFilterTypes.MyActions);
          break;
        case filterTypes.myTeamsPastDueItems:
          onChangeAssignedToUsers(filteredUsers);
          onChangeRequestFilter({
            ...updatedFilter,
            dueDate: actionIndicators.overdue.key
          });
          onChangeUserFilterType(userTypes);
          break;
        case filterTypes.myUpcomingItems:
          onChangeAssignedToUsers(getMyUser());
          onChangeRequestFilter({
            ...updatedFilter,
            dueDate: actionIndicators.upcoming.key
          });
          onChangeUserFilterType(UserFilterTypes.MyActions);
          break;
        case filterTypes.myTeamsUpcomingItems:
          onChangeAssignedToUsers(filteredUsers);
          onChangeRequestFilter({
            ...updatedFilter,
            dueDate: actionIndicators.upcoming.key
          });
          onChangeUserFilterType(userTypes);
          break;
        case filterTypes.myActiveItems:
          onChangeAssignedToUsers(getMyUser());
          onChangeRequestFilter({
            status: null,
            dueDate: null
          });
          onChangeUserFilterType(UserFilterTypes.MyActions);
          break;
        case filterTypes.myTeamActiveItems:
          onChangeAssignedToUsers(filteredUsers);
          onChangeRequestFilter({
            status: null,
            dueDate: null
          });
          onChangeUserFilterType(userTypes);
          break;
        case filterTypes.myOpenItems:
          onChangeAssignedToUsers(getMyUser());
          onChangeRequestFilter({
            status: uiConfig.indicators.open.key,
            dueDate: null
          });
          onChangeUserFilterType(UserFilterTypes.MyActions);
          break;
        case filterTypes.myRespondedItems:
          onChangeAssignedToUsers(getMyUser());
          onChangeRequestFilter({
            status: uiConfig.indicators.responded.key,
            dueDate: null
          });
          onChangeUserFilterType(UserFilterTypes.MyActions);
          break;
        case filterTypes.myTeamOpenItems:
          onChangeAssignedToUsers(filteredUsers);
          onChangeRequestFilter({
            status: uiConfig.indicators.open.key,
            dueDate: null
          });
          onChangeUserFilterType(userTypes);
          break;
        case filterTypes.myTeamRespondedItems:
          onChangeAssignedToUsers(filteredUsers);
          onChangeRequestFilter({
            status: uiConfig.indicators.responded.key,
            dueDate: null
          });
          onChangeUserFilterType(userTypes);
          break;
        case filterTypes.myClosedItems:
          onChangeAssignedToUsers(getMyUser());
          onChangeRequestFilter({
            status: uiConfig.indicators.closed.key,
            dueDate: null
          });
          onChangeUserFilterType(UserFilterTypes.MyActions);
          break;
        case filterTypes.myTeamClosedItems:
          onChangeAssignedToUsers(filteredUsers);
          onChangeRequestFilter({
            status: uiConfig.indicators.closed.key,
            dueDate: null
          });
          onChangeUserFilterType(userTypes);
          break;
        default:
          setCurrentUserFilter(UserFilterTypes.None);
      }
    },
    [
      actionIndicators.overdue.key,
      actionIndicators.upcoming.key,
      getClientTeam,
      getHostTeam,
      getMyUser,
      uiConfig.indicators.closed.key,
      uiConfig.indicators.open.key,
      uiConfig.indicators.responded.key,
      user,
      actionItemsFilter,
      onChangeAssignedToUsers,
      onChangeRequestFilter,
      onChangeUserFilterType,
      resetActionitemTableFilter
    ]
  );

  useEffect(() => {
    if (uiConfig?.pills) {
      setPillsConfig(uiConfig.pills);
    }
  }, [uiConfig?.pills]);

  useEffect(() => {
    if (uiConfig?.indicators) {
      setIndicatorUiConfig(uiConfig.indicators);
    }
  }, [uiConfig?.indicators]);

  useEffect(() => {
    if (uiConfig?.actionItemFilters) {
      setFilterConfig(uiConfig.actionItemFilters);
    }
  }, [uiConfig?.actionItemFilters]);

  const enableIndicatorIcon = useMemo(
    () => isEnabled(systemConstants.features.indicatorIcon),
    [isEnabled]
  );
  const enableIndicatorPill = useMemo(
    () => isEnabled(systemConstants.features.indicatorPill),
    [isEnabled]
  );

  useEffect(() => {
    if (props.project) {
      setProject(props.project);
    }
  }, [props.project]);

  useEffect(() => {
    if (!didMountRef.current && !isLoading) {
      isShowAskHelp.current = true;
    }
  }, [didMountRef, isLoading]);

  useEffect(() => {
    if (props.filters?.type) {
      applyFilterType(props.filters);
    }
  }, [applyFilterType, props.filters, members]);

  useEffect(() => {
    if (requestFilter) {
      setActionItemsFilter(structuredClone(requestFilter));
    }
  }, [requestFilter]);

  useEffect(() => {
    if (userFilterType) {
      setCurrentUserFilter(structuredClone(userFilterType));
    }
  }, [userFilterType]);

  useEffect(() => {
    if (assignedToUsers) {
      setFilterUsers(assignedToUsers);
    }
  }, [assignedToUsers]);

  useLayoutEffect(() => {
    startLoading(isLoading);
  }, [isLoading, startLoading]);

  const getQueryListFile = useCallback(
    attachments => {
      return Object.values(attachments || []).map(file => ({
        filePathId: file.filePathId,
        name: file.name,
        projectId: props.project?.id,
        isDeleted: false,
        isNew: false
      }));
    },
    [props.project?.id]
  );

  const projectError = msg => {
    setErrorMessage(msg);
    setShowProjectAccessModal(true);
  };

  const handleAddQuery = useCallback(() => {
    isProjectMember(
      user,
      t(
        "requests:requests.ui.requestListPage.accessRequiredActionCreateRequests"
      ),
      () => setShowCreateActionForm(true),
      projectError
    );
  }, [isProjectMember, t, user]);

  const handleUpdateQuery = useCallback(
    query => {
      const filteredQuery = queryTypes.filter(q => q.key === query.queryType);
      const queryType = filteredQuery.length > 0 ? filteredQuery[0].type : null;
      isProjectMember(
        user,
        t(
          "requests:requests.ui.requestListPage.accessRequiredActionEditRequest"
        ),
        () => {
          if (queryType === systemConstants.actionItemTypes.conversation) {
            navigateToRequestPage(query.id, query.projectId, queryType);
          } else {
            setShowUpdateActionForm(true);
            setSelectedQuery(query);
          }
        },
        projectError
      );
    },
    [queryTypes, isProjectMember, user, t, navigateToRequestPage]
  );

  const isWebsheetActionItemType = useCallback(
    query => {
      const actionItemConfig = getActionItemTypeConfiguration(
        query.queryType,
        actionItemTypes
      );
      return (
        actionItemConfig?.type === systemConstants.actionItemTypes.websheet
      );
    },
    [actionItemTypes]
  );

  const handleCloseQuery = useCallback(
    query => {
      isProjectMember(
        user,
        t(
          "requests:requests.ui.requestListPage.accessRequiredActionCloseRequest"
        ),
        () => {
          const files = isWebsheetActionItemType(query)
            ? getQueryListFile(query.attachments)
            : [];
          updateQuery({ ...query, files, status: statusTypes.closed });
        },
        projectError
      );
    },
    [
      isProjectMember,
      user,
      t,
      isWebsheetActionItemType,
      getQueryListFile,
      updateQuery
    ]
  );

  const handleReopenQuery = useCallback(
    query => {
      isProjectMember(
        user,
        t(
          "requests:requests.ui.requestListPage.accessRequiredActionReopenRequest"
        ),
        () => {
          const files = isWebsheetActionItemType(query)
            ? getQueryListFile(query.attachments)
            : [];
          updateQuery({ ...query, files, status: statusTypes.open });
        },
        projectError
      );
    },
    [
      isProjectMember,
      user,
      t,
      isWebsheetActionItemType,
      getQueryListFile,
      updateQuery
    ]
  );

  const handleSelectQuery = useCallback(
    query => {
      const actionItemConfig = getActionItemTypeConfiguration(
        query.queryType,
        actionItemTypes
      );
      navigateToRequestPage(query.id, query.projectId, actionItemConfig?.type, {
        websheetOpensNewWindow: true
      });
    },
    [actionItemTypes, navigateToRequestPage]
  );

  const handleHistoryQuery = useCallback(query => setHistoryId(query.id), []);

  const errorMessage = () => {
    return error;
  };

  const helpMessage = () => {
    return !queries.length && isShowAskHelp.current
      ? t("requests:requests.ui.requestListPage.noRequestsPresent")
      : null;
  };

  const headingActions = () => {
    return (
      <div>
        <BrandButton
          type="primary"
          onClick={handleAddQuery}
          iconName="add"
          label={t(
            "requests:requests.ui.requestListPage.buttonActionCreateRequest"
          )}
          disabled={
            !user || (user.isTeamMember && (membersLoading || !members))
          }
        />
      </div>
    );
  };

  const onChangeStatusFilter = useCallback(
    indicator => {
      const updatedFilter = structuredClone(actionItemsFilter);
      if (indicator.key == actionItemsFilter.status) {
        updatedFilter.status = null;
      } else {
        updatedFilter.status = indicator.key;
      }
      onChangeRequestFilter(updatedFilter);
    },
    [actionItemsFilter, onChangeRequestFilter]
  );

  const onChangeLabelsFilter = useCallback(
    labels => onChangeTableFilters(labels, "labels"),
    [onChangeTableFilters]
  );

  const onChangeQueryTypeFilter = useCallback(
    queryType => onChangeTableFilters(queryType, "queryType"),
    [onChangeTableFilters]
  );

  const onChangeCreatedByFilter = useCallback(
    createdBy => onChangeTableFilters(createdBy, "createdBy"),
    [onChangeTableFilters]
  );

  const onChangeDueDateFilter = useCallback(
    indicator => {
      const updatedFilter = structuredClone(actionItemsFilter);
      if (indicator.key == actionItemsFilter.dueDate) {
        updatedFilter.dueDate = null;
      } else {
        updatedFilter.dueDate = indicator.key;
      }
      onChangeRequestFilter(updatedFilter);
    },
    [actionItemsFilter, onChangeRequestFilter]
  );

  const onClickMyActionsFilter = useCallback(() => {
    const isSelected = currentUserFilter === UserFilterTypes.MyActions;
    onChangeUserFilterType(
      isSelected ? UserFilterTypes.None : UserFilterTypes.MyActions
    );
    onChangeAssignedToUsers(isSelected ? [] : getMyUser());
  }, [
    currentUserFilter,
    getMyUser,
    onChangeUserFilterType,
    onChangeAssignedToUsers
  ]);

  const onClickClientTeamActionsFilter = useCallback(() => {
    const isSelected = currentUserFilter === UserFilterTypes.ClientTeamActions;
    onChangeUserFilterType(
      isSelected ? UserFilterTypes.None : UserFilterTypes.ClientTeamActions
    );
    onChangeAssignedToUsers(isSelected ? [] : getClientTeam(members));
  }, [
    currentUserFilter,
    getClientTeam,
    members,
    onChangeUserFilterType,
    onChangeAssignedToUsers
  ]);

  const onClickHostTeamActionsFilter = useCallback(() => {
    const isSelected = currentUserFilter === UserFilterTypes.HostTeamActions;
    onChangeUserFilterType(
      isSelected ? UserFilterTypes.None : UserFilterTypes.HostTeamActions
    );
    onChangeAssignedToUsers(isSelected ? [] : getHostTeam(members));
  }, [
    currentUserFilter,
    getHostTeam,
    members,
    onChangeUserFilterType,
    onChangeAssignedToUsers
  ]);

  const filterValueEquals = (filterVal, memberList) => {
    if (filterVal.length !== memberList.length) {
      return false;
    }
    filterVal.sort();
    const m = memberList.map(member => member.name).sort();
    return filterVal.every((val, index) => val === m[index]);
  };

  const userIsOnlyUserInTeam = useCallback(
    teamType => {
      if (teamType === "host") {
        return (
          members.hostUsers.length === 1 && members.hostUsers[0].id === user.id
        );
      } else if (teamType === "client") {
        return (
          members.clientUsers.length === 1 &&
          members.clientUsers[0].id === user.id
        );
      }
    },
    [members.hostUsers, members.clientUsers, user.id]
  );

  // This is to prevent the table header rerender after selecting assigned to/ user filter
  const currentUserFilterRef = useRef(currentUserFilter);

  useEffect(() => {
    currentUserFilterRef.current = currentUserFilter;
  }, [currentUserFilter]);

  const onAssignToFilterValueChanged = useCallback(
    selectedUsers => {
      const filterValue = selectedUsers.map(u => u.value);
      onChangeAssignedToUsers(selectedUsers);

      if (filterValueEquals(filterValue, [user])) {
        if (
          (userIsOnlyUserInTeam("client") &&
            currentUserFilterRef.current ===
              UserFilterTypes.ClientTeamActions) ||
          (userIsOnlyUserInTeam("host") &&
            currentUserFilterRef.current === UserFilterTypes.HostTeamActions)
        ) {
          return;
        }

        if (currentUserFilterRef.current !== UserFilterTypes.MyActions) {
          onChangeUserFilterType(UserFilterTypes.MyActions);
        }
      } else if (filterValueEquals(filterValue, members.hostUsers)) {
        if (
          userIsOnlyUserInTeam("host") &&
          currentUserFilterRef.current === UserFilterTypes.MyActions
        ) {
          return;
        }

        if (currentUserFilterRef.current !== UserFilterTypes.HostTeamActions) {
          onChangeUserFilterType(UserFilterTypes.HostTeamActions);
        }
      } else if (filterValueEquals(filterValue, members.clientUsers)) {
        if (
          userIsOnlyUserInTeam("client") &&
          currentUserFilterRef.current === UserFilterTypes.MyActions
        ) {
          return;
        }
        if (
          currentUserFilterRef.current !== UserFilterTypes.ClientTeamActions
        ) {
          onChangeUserFilterType(UserFilterTypes.ClientTeamActions);
        }
      } else {
        if (currentUserFilterRef.current !== UserFilterTypes.None) {
          onChangeUserFilterType(UserFilterTypes.None);
        }
      }
    },
    [
      members.clientUsers,
      members.hostUsers,
      onChangeAssignedToUsers,
      onChangeUserFilterType,
      user,
      userIsOnlyUserInTeam
    ]
  );

  const primaryContent = () => {
    if (queries.length) {
      return (
        <ActionItemsTable
          queries={queries}
          queryTypes={queryTypes}
          actionItemTypes={actionItemTypes}
          onClickQuery={handleSelectQuery}
          onClickEdit={handleUpdateQuery}
          onClickClose={handleCloseQuery}
          onClickReopen={handleReopenQuery}
          onClickHistory={handleHistoryQuery}
          onAssignToFilterValueChanged={onAssignToFilterValueChanged}
          onQueryTypeFilterChanged={onChangeQueryTypeFilter}
          onCreatedByFilterChanged={onChangeCreatedByFilter}
          onSortedColumnChanged={setSortedColumn}
          onLabelFiltersChanged={onChangeLabelsFilter}
          filterLabel={tableFilters.labels}
          filterUsers={filterUsers}
          filterCreatedBy={tableFilters.createdBy}
          filterQueryType={tableFilters.queryType}
          filter={actionItemsFilter}
          sortedColumn={sortedColumn}
          user={user}
          engagementTypeId={engagementTypeId}
          enableIndicatorIcon={enableIndicatorIcon}
          enableIndicatorPill={enableIndicatorPill}
          indicatorUiConfig={indicatorUiConfig}
          hideRequiredByField={
            project?.configuration?.requests?.hideRequiredByField ?? false
          }
          project={project}
        ></ActionItemsTable>
      );
    }
  };

  const secondaryContent = () => {
    if (!queries.length && isShowAskHelp.current) {
      return null;
    }

    if (queries.length) {
      return (
        <ActionItemsFilterLegend
          handlers={{
            [actionItemsFilters.STATUS]: onChangeStatusFilter,
            [actionItemsFilters.DUE_DATE]: onChangeDueDateFilter,
            [actionItemsFilters.MY_ACTIONS]: onClickMyActionsFilter,
            [actionItemsFilters.CLIENT_TEAM_ACTIONS]:
              onClickClientTeamActionsFilter,
            [actionItemsFilters.HOST_TEAM_ACTIONS]: onClickHostTeamActionsFilter
          }}
          enabled={{
            [actionItemsFilters.MY_ACTIONS]:
              currentUserFilter === UserFilterTypes.MyActions,
            [actionItemsFilters.CLIENT_TEAM_ACTIONS]:
              currentUserFilter === UserFilterTypes.ClientTeamActions,
            [actionItemsFilters.HOST_TEAM_ACTIONS]:
              currentUserFilter === UserFilterTypes.HostTeamActions
          }}
          labels={{
            [actionItemsFilters.MY_ACTIONS]:
              t("requests:requests.myActionsFilterLabel") ?? "",
            [actionItemsFilters.CLIENT_TEAM_ACTIONS]:
              t("requests:requests.clientTeamActionsFilterLabel") ?? "",
            [actionItemsFilters.HOST_TEAM_ACTIONS]:
              t("requests:requests.hostTeamActionsFilterLabel") ?? ""
          }}
          pillsConfig={pillsConfig}
          indicatorUiConfig={indicatorUiConfig}
          filterConfig={filterConfig}
          status={actionItemsFilter}
          enableIndicatorIcon={enableIndicatorIcon}
          enableIndicatorPill={enableIndicatorPill}
        />
      );
    }
  };

  const onAddActionSubmitted = useCallback(() => {
    setShowCreateActionForm(false);
  }, []);

  const onAddActionCancelled = useCallback(() => {
    setShowCreateActionForm(false);
  }, []);

  const onUpdateActionCancelled = useCallback(() => {
    setShowUpdateActionForm(false);
  }, []);

  const onUpdateActionSubmitted = useCallback(() => {
    setShowUpdateActionForm(false);
  }, []);

  return (
    <>
      <PageTemplate
        header={{
          title: t("requests:requests.ui.requestListPage.pageTitle"),
          actions: headingActions()
        }}
        body={{
          primary: primaryContent(),
          secondary: secondaryContent(),
          secondaryMaxWidth: "20vw"
        }}
        other={{
          loading: isLoading
            ? t("requests:requests.ui.requestListPage.loadingRequest")
            : null,
          error: errorMessage(),
          help: helpMessage(),
          smallPageSize: 1800,
          project
        }}
      />
      <ProjectAccessModal
        visibility={showProjectAccessModal}
        message={erroMessage}
        handleClose={() => {
          setShowProjectAccessModal(false);
          setErrorMessage("");
        }}
      />
      <WorkpaperAudits
        queryId={historyId}
        handleClose={() => {
          setHistoryId(null);
        }}
      />
      {showCreateActionForm && (
        <AddOrCopyProjectActionItems
          project={project}
          actionItemTypes={actionItemTypes}
          onCancel={onAddActionCancelled}
          onSubmitted={onAddActionSubmitted}
          isSlidingForm={true}
          modalClassName={"add-request-sideModal"}
          enableWebSheet={true}
        />
      )}
      {showUpdateActionForm && (
        <UpdateActionForm
          project={project}
          query={selectedQuery}
          queryTypes={queryTypes}
          actionItemTypes={actionItemTypes}
          onCancel={onUpdateActionCancelled}
          onSubmitted={onUpdateActionSubmitted}
        />
      )}
    </>
  );
};

export default withTranslation()(ActionItems);
