import { useCallback, useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageExcelDocumentActions } from "@shared/actions/manageExcelDocumentActions";

export function useWebsheetFileLocking() {
  // @ts-ignore
  const manageExcelDocument = useSelector(state => state.manageExcelDocument);
  const dispatch = useDispatch();

  const localFileLock = useRef(null); // ref used so refreshing does not trigger useEffect loops
  const [fileLock, setFileLock] = useState(null);
  const [fileLockError, setFileLockError] = useState("");

  useEffect(() => {
    const lock = manageExcelDocument.lock.fileLock;
    setFileLock(lock);
    localFileLock.current = lock;
  }, [manageExcelDocument.lock.fileLock]);

  useEffect(() => {
    setFileLockError(manageExcelDocument.lock.error);
  }, [manageExcelDocument.lock.error]);

  const acquireFileLock = useCallback(
    ({ documentId, documentRevisionId }) => {
      dispatch(
        manageExcelDocumentActions.getLockForExcelDocument({
          id: documentId,
          documentRevisionId: documentRevisionId
        })
      );
    },
    [dispatch]
  );

  const refreshFileLock = useCallback(() => {
    if (!localFileLock?.current) {
      return;
    }

    dispatch(
      manageExcelDocumentActions.refreshLockForExcelDocument({
        fileLockId: localFileLock.current.id,
        documentId: localFileLock.current.documentId,
        documentRevisionId: localFileLock.current.documentRevisionId
      })
    );
  }, [dispatch]);

  const releaseFileLock = useCallback(() => {
    if (!localFileLock?.current) {
      return;
    }
    dispatch(
      manageExcelDocumentActions.finishEditSession({
        fileLockId: localFileLock?.current.id,
        documentId: localFileLock?.current.documentId,
        documentRevisionId: localFileLock?.current.documentRevisionId
      })
    );
    localFileLock.current = null;
  }, [dispatch]);

  return {
    acquireFileLock,
    refreshFileLock,
    releaseFileLock,
    fileLock,
    fileLockError
  };
}
