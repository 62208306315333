import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { debounce } from "lodash";
import PropTypes from "prop-types";
import useOnClickOutside from "use-onclickoutside";

import "./DropdownDotMenu.scss";
import FloatingMenu from "./FloatingMenu/FloatingMenu";

const ALIGN_TYPE = {
  LEFT: "left",
  RIGHT: "right"
};

const DropdownDotMenu = props => {
  const { disableFixedPositionCalculation, positionRelative } = props;
  const [hideMenu, setHideMenu] = useState(true);
  const ref = useRef();
  const menuContentWrapperRef = useRef();
  const [menuPopupOffset, setMenuPopupOffset] = useState(null);

  const menuItemClickHandler = menuItem => {
    return event => {
      event.stopPropagation();
      setHideMenu(!hideMenu);
      props.onMenuItemClick(menuItem);
    };
  };

  const handleClick = event => {
    event.stopPropagation();
    setHideMenu(!hideMenu);
  };

  const updateScroll = useCallback(() => {
    if (disableFixedPositionCalculation || hideMenu || !ref?.current) {
      return;
    }

    const dotMenuPos = ref.current?.getBoundingClientRect();
    const relativePos = {};
    const offsetX = (dotMenuPos?.width ?? 0) / 2;
    const offsetY = dotMenuPos?.height ?? 0;
    relativePos.top = (dotMenuPos?.top ?? 0) + offsetY;
    relativePos.left = (dotMenuPos?.left ?? 0) + offsetX;

    const top = relativePos.top;
    const left = relativePos.left;
    setMenuPopupOffset({
      top,
      left
    });
  }, [disableFixedPositionCalculation, hideMenu]);

  const debouncedUpdateScroll = useMemo(
    () => debounce(updateScroll, 50),
    [updateScroll]
  );
  const debouncedUpdateResize = useMemo(
    () => debounce(updateScroll, 300),
    [updateScroll]
  );

  // Stop the invocation of the debounced function
  // after unmounting
  useEffect(() => {
    return () => {
      debouncedUpdateScroll.cancel();
    };
  }, [debouncedUpdateScroll]);

  useEffect(() => {
    if (hideMenu) {
      return;
    }
    updateScroll();
  }, [hideMenu, updateScroll]);

  useEffect(() => {
    window.addEventListener("mousewheel", debouncedUpdateScroll);

    return function () {
      window.removeEventListener("mousewheel", debouncedUpdateScroll);
    };
  }, [debouncedUpdateScroll]);

  useEffect(() => {
    window.addEventListener("resize", debouncedUpdateResize);

    return function () {
      window.removeEventListener("resize", debouncedUpdateResize);
    };
  }, [debouncedUpdateResize]);

  useEffect(() => {
    if (disableFixedPositionCalculation) {
      setMenuPopupOffset(null);
    }
  }, [disableFixedPositionCalculation]);

  // Hide the menu when dot menu is not visible (i.e. when it is scrolled out of view)
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (!entries[0].isIntersecting) {
          // hide the floating menu
          setHideMenu(true);
        }
      },
      {
        root: null,
        threshold: 0.5,
        rootMargin: "0px"
      }
    );

    const current = ref.current;
    observer.observe(current);
    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, []);

  useOnClickOutside(ref, event => {
    event.stopPropagation();
    setHideMenu(true);
  });

  const positionClass = `dot-menu-position--${
    positionRelative ? "relative" : "absolute"
  }`;

  return (
    <>
      <div
        ref={ref}
        className={`${"dot-menu"} ${positionClass} ${props.className}`}
        onClick={handleClick}
      >
        <a className="dot-menu__dots" role="button" data-testid={`test__menu`}>
          <i className="material-icons">more_vert</i>
        </a>
        {!hideMenu && (
          <FloatingMenu
            ref={menuContentWrapperRef}
            align={props.align}
            menuItems={props.menuItems}
            menuItemClickHandler={menuItemClickHandler}
            parentPos={menuPopupOffset}
            disableFixedPositionCalculation={disableFixedPositionCalculation}
          />
        )}
      </div>
    </>
  );
};

DropdownDotMenu.defaultProps = {
  align: ALIGN_TYPE.LEFT,
  disableFixedPositionCalculation: false,
  className: "",
  positionRelative: false
};

DropdownDotMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string, // preferable to use key + label
      key: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  onMenuItemClick: PropTypes.func.isRequired,
  align: PropTypes.oneOf([ALIGN_TYPE.LEFT, ALIGN_TYPE.RIGHT]),
  disableFixedPositionCalculation: PropTypes.bool,
  positionRelative: PropTypes.bool,
  className: PropTypes.string
};

export default DropdownDotMenu;
