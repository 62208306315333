import { systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { handleErrorResponse, handleResponse } from "./";

const baseURL = systemConstants.serverURL;

export const manageClientEngagementProjectsService = {
  getClientEngagementProjects,
  addClientEngagementProject,
  getProjecttManagers,
  getMyProjects,
  updateProject,
  getOpenQueries,
  getOpenRisks,
  getByProjectId
};

function getOpenQueries(project) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    `${baseURL}/api/projects/${project.id}/queries?status=[${systemConstants.project.queries.status.open}]`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getOpenRisks(project) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    `${baseURL}/api/projects/${project.id}/risks?status=[${systemConstants.project.risk.status.raised},${systemConstants.project.risk.status.inProgress}]`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getMyProjects(withDetails) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    `${baseURL}/api/users/me/projects?${new URLSearchParams({ withDetails })}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getClientEngagementProjects(engagement) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    `${baseURL}/api/engagements/${engagement.id}/projects?withDetails=true`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getProjecttManagers(hostUser) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    `${baseURL}/api/hosts/${hostUser.hostId}/project-managers`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function addClientEngagementProject(project, fillUsers) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ project, fillUsers })
  };

  return fetch(
    `${baseURL}/api/engagements/${project.engagement.id}/projects`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function updateProject(project) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(project)
  };

  return fetch(`${baseURL}/api/projects/${project.id}`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getByProjectId(projectId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/engagements/getByProjectId/${projectId}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}
