import React, { useCallback, useEffect, useRef, useState } from "react";

import { useVisualisation } from "@shared/hooks";

import { visualisationConstants } from "@app/constants/visualisationConstants";

import Button from "@components/atoms/Button";
import InlineAlert from "@components/atoms/InlineAlert";
import PopupOverlay from "@components/atoms/PopupOverlay";
import UploadFile from "@components/molecules/UploadFile";
import ModalTemplate from "@components/templates/ModalTemplate";

import "./VisualisationWorkbook.scss";

const VisualisationWorkbook = props => {
  const {
    config,
    user,
    document,
    positionNum,
    onDrop,
    onDelete,
    isUploadDisabled,
    handleUploadPermission
  } = props;
  const {
    visualisationError,
    resetVisualisationError,
    updateVisualisationSuccess
  } = useVisualisation();
  const [showDelete, setShowDelete] = useState(false);
  const [error, setError] = useState({
    display: false,
    message: ""
  });
  const [disableUpload, setDisableUpload] = useState(false);

  useEffect(() => {
    if (visualisationError) {
      if (disableUpload) {
        setDisableUpload(false);
        setError({
          display: true,
          message: visualisationError
        });
      }
      if (showDelete) {
        setError({
          display: false,
          message: visualisationError
        });
      }
    }
    if (updateVisualisationSuccess && showDelete) {
      setShowDelete(false);
    }
  }, [
    visualisationError,
    showDelete,
    updateVisualisationSuccess,
    disableUpload
  ]);

  const handleDrop = useCallback(
    acceptedFile => {
      setDisableUpload(true);
      onDrop(acceptedFile, positionNum);
    },
    [onDrop, positionNum]
  );

  const handleDeleteFile = useCallback(() => {
    document.positionNum = positionNum;
    onDelete(document);
    setDisableUpload(false);
  }, [document, onDelete, positionNum]);

  const handleRejection = useCallback(
    errors => {
      if (errors.length) {
        errors.map(e => {
          if (e.errors[0].code === "file-invalid-type") {
            setError({
              display: true,
              message: config?.formatError
            });
          } else {
            setError({
              display: true,
              message: visualisationConstants.visualisationAmountError
            });
          }
        });
      }
    },
    [config]
  );

  const onClickDelete = () => {
    if (handleUploadPermission("delete this workbook")) {
      setShowDelete(true);
    }
  };

  const handleCancelDelete = () => {
    setShowDelete(false);
    setError({
      display: false,
      message: ""
    });
    resetVisualisationError();
  };

  const handleReupload = () => {
    setError({
      display: false,
      message: ""
    });
    setDisableUpload(false);
  };

  const renderUploadErrorModal = () => {
    return (
      <ModalTemplate
        boxClassName="tag-editor"
        title={"Upload Failure"}
        onClose={handleReupload}
        content={
          <>
            <span>{error.message}</span>
          </>
        }
        footer={
          <>
            <Button
              type={"primary"}
              disabled={false}
              label="OK"
              onClick={handleReupload}
            />
          </>
        }
      />
    );
  };

  const renderDeleteVisualisationModal = () => {
    return (
      <ModalTemplate
        boxClassName="tag-editor"
        title={"Delete File"}
        onClose={handleCancelDelete}
        content={
          <>
            {error.message && (
              <div className="tag-editor__error-box">
                <InlineAlert type="error" message={error.message} />
              </div>
            )}
            <span>Are you sure you want to delete the file?</span>
          </>
        }
        footer={
          <>
            <Button
              type="secondary"
              label="Cancel"
              onClick={handleCancelDelete}
            />
            <Button
              type={"danger"}
              disabled={false}
              label="Delete"
              onClick={handleDeleteFile}
            />
          </>
        }
      />
    );
  };

  const handleClickBox = () => {
    handleUploadPermission("upload a workbook");
  };

  return (
    <div className="workbook">
      <div className="workbook__container">
        {document ? (
          <>
            <div className="workbook__container__label">
              <i className="material-icons-outlined">description</i>
              <span className="workbook-name">{document?.name}</span>
            </div>
            <div className="workbook-icons">
              {user.isHostUser && (
                <i
                  className="material-icons workbook-icons__icon"
                  onClick={onClickDelete}
                >
                  delete
                </i>
              )}
            </div>

            <PopupOverlay
              isVisible={showDelete}
              isModal={true}
              onClose={handleCancelDelete}
              showClose={false}
              width={"60rem"}
            >
              {renderDeleteVisualisationModal()}
            </PopupOverlay>
          </>
        ) : (
          <>
            {user.isHostUser && (
              <>
                <div className="workbook__container__upload">
                  {config?.label}
                </div>
                <UploadFile
                  onDrop={handleDrop}
                  handleDrop={handleDrop}
                  handleRejection={handleRejection}
                  handleClickBox={handleClickBox}
                  supportedDocumentMimes={config.supportedMimes}
                  maxNumberOfFiles={visualisationConstants.maximumFileNum}
                  disabled={disableUpload || isUploadDisabled}
                  supportedDocumentMimesMessage={
                    visualisationConstants.supportedDocumentMimesMessage
                  }
                />
                <PopupOverlay
                  isVisible={error.display}
                  isModal={true}
                  onClose={handleReupload}
                  showClose={false}
                  width={"60rem"}
                >
                  {renderUploadErrorModal()}
                </PopupOverlay>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default VisualisationWorkbook;
