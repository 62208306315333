import React, { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { routeConstants } from "@constants";

import { manageClientsActions } from "@shared/actions";
import { utilities } from "@shared/helpers";
import { useGetClientByIdQuery } from "@shared/hooks";

import DragNDrop from "@shared-components/dragndrop/DragNDrop";
import LogoHolder from "@shared-components/logoHolder/LogoHolder";

import Button from "@components/atoms/Button/BrandButton";
import InlineAlert from "@components/atoms/InlineAlert";
import TextInput from "@components/atoms/TextInput/TextInput";
import MainPageTemplate from "@components/templates/MainPageTemplate";
import PageBodyTemplate from "@components/templates/PageBodyTemplate";

import "./AddOrUpdateClient.scss";

const AddOrUpdateClient = () => {
  const { t } = useTranslation();

  const { clientId } = useParams();
  const { data: clientFromQuery } = useGetClientByIdQuery(clientId, {
    skip: !clientId
  });
  const fileRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authentication = useSelector(state => state.authentication);
  const manageClients = useSelector(state => state.manageClients);
  const [client, setClient] = useState({
    name: "",
    acn: "",
    abn: "",
    logo: null,
    hostId: authentication.user.hostId
  });
  const [isUpdate, setIsUpdate] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [logo, setLogo] = useState(null);
  const [hideLogo, setHideLogo] = useState(true);
  const inputFocusRef = React.createRef();
  const [textBodyFocus, setTextBodyFocus] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (inputFocusRef.current) {
      inputFocusRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFocusRef.current]);

  useEffect(() => {
    if (clientFromQuery) {
      setClient(clientFromQuery);
      setIsUpdate(true);
      if (clientFromQuery.logo?.data) {
        setLogo(clientFromQuery.logo?.data);
        setHideLogo(false);
      } else {
        setLogo(utilities.getDefaultClientLogo());
      }
    }
    return () => {
      dispatch(manageClientsActions.clearUpdateClientFlags());
    };
  }, [clientFromQuery, dispatch]);

  const handleKeyPress = event => {
    if (event.key === "Enter" && !textBodyFocus) {
      handleSubmit();
    }
    if (event.key === "Escape") {
      navigate(routeConstants.manageClients);
    }
  };

  const handleTextBodyFocus = () => {
    setTextBodyFocus(true);
  };
  const handleTextBodyBlur = () => {
    setTextBodyFocus(false);
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textBodyFocus]);

  useEffect(() => {
    if (manageClients.addedClient) {
      dispatch(manageClientsActions.clearAddClientFlags());
      navigate(routeConstants.manageClients);
    }
    // eslint-disable-next-line
  }, [manageClients.addedClient]);

  useEffect(() => {
    if (manageClients.updatedClient) {
      dispatch(manageClientsActions.clearUpdateClientFlags());
      navigate(routeConstants.manageClients);
    }
    // eslint-disable-next-line
  }, [manageClients.updatedClient]);

  useEffect(() => {
    if (manageClients.error) {
      setError(manageClients.error);
    }
    // eslint-disable-next-line
  }, [manageClients.error]);

  const handleClientNameInput = event => {
    setError("");
    const name = event.target.value;
    setClient({ ...client, name });
  };

  const handleClientACNInput = event => {
    setError("");
    const acn = event.target.value;
    setClient({ ...client, acn });
  };

  const handleClientABNInput = event => {
    setError("");
    const abn = event.target.value;
    setClient({ ...client, abn });
  };

  const handleDrop = file => {
    setLogo(file);
    setHideLogo(false);
  };

  const handleSubmit = () => {
    setSubmit(true);
    if (client.name) {
      const clientToBeAdded = {
        ...client,
        name: client.name && client.name.trim(),
        acn: client.acn && client.acn.trim(),
        abn: client.abn && client.abn.trim(),
        logo: logo ? { data: logo } : null
      };

      isUpdate
        ? dispatch(manageClientsActions.updateClient(clientToBeAdded))
        : dispatch(manageClientsActions.addClient(clientToBeAdded));
    } else {
      setError("Please enter client name");
    }
  };

  const handleLogoChangeClick = () => {
    fileRef.current.click();
  };

  const handleRemoveLogoClick = () => {
    setLogo(null);
    setHideLogo(true);
  };

  const onLogoChange = event => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    utilities.getBase64OfFile(file, setLogo);
  };

  return (
    <MainPageTemplate>
      <PageBodyTemplate
        breadcrumbs={[
          {
            linkTo: routeConstants.manageClients,
            label: t("common:ui.manageClients.title")
          }
        ]}
        title={
          isUpdate
            ? t("common:ui.updateClient.title")
            : t("common:ui.addClient.title")
        }
      >
        <div className="add-client__main">
          {error && (
            <div className="add-client__error-box">
              <InlineAlert type="error" message={t(error)} />
            </div>
          )}

          <div className="add-client__container">
            <div className="add-client__input-container">
              <TextInput
                label="Client name"
                required={true}
                maxLength={50}
                value={client.name || ""}
                error={
                  !client.name && submit ? "Please enter client name" : null
                }
                onChange={handleClientNameInput}
                onFocus={handleTextBodyFocus}
                onBlur={handleTextBodyBlur}
              />
            </div>

            <div className="add-client__input-container">
              <TextInput
                label="ACN"
                maxLength={11}
                value={client.acn || ""}
                onChange={handleClientACNInput}
                onFocus={handleTextBodyFocus}
                onBlur={handleTextBodyBlur}
              />
            </div>

            <div className="add-client__input-container">
              <TextInput
                label="ABN"
                maxLength={14}
                value={client.abn || ""}
                onChange={handleClientABNInput}
                onFocus={handleTextBodyFocus}
                onBlur={handleTextBodyBlur}
              />
            </div>

            <div className="add-client__input-container">
              <div className="add-client__logo">
                <label className="add-client__body--label">Client logo</label>
                {hideLogo ? (
                  <DragNDrop
                    handleDrop={handleDrop}
                    message={
                      "Please use a logo with a dimention close to 4 x 3"
                    }
                  />
                ) : (
                  <div>
                    <div className="add-client__file-loader">
                      <input
                        onChange={onLogoChange}
                        ref={fileRef}
                        type="file"
                        accept="image/*"
                      />
                    </div>

                    <LogoHolder
                      file={logo}
                      changable
                      removable
                      handleLogoChangeClick={handleLogoChangeClick}
                      handleRemoveLogoClick={handleRemoveLogoClick}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="add-client__button">
              <Button
                type="primary"
                disabled={!(client.name && client.name.trim())}
                label={
                  isUpdate
                    ? t("common:ui.updateClient.submitLabel")
                    : t("common:ui.addClient.submitLabel")
                }
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </PageBodyTemplate>
    </MainPageTemplate>
  );
};

export default AddOrUpdateClient;
