import React from "react";

import * as _ from "lodash";

// Types of pagination display
const paginationType = {
  none: null,
  completionStatus: "completionStatus"
};

/**
 * @typedef {Object} PageInfoItem
 * @property {string} name - The category name
 * @property {number} completeCount - The number of completed questions
 * @property {number} totalCount - The total number of questions
 */

/**
 * @typedef {Object.<number, PageInfoItem>} PageInfo
 */

/**
 *
 * Gets a list of the visible page numbers around the current page
 * @param {number} currentPage
 * @param {number} totalPages
 * @param {number} maxNumberOfVisiblePages
 * @return {number[]}
 */
const getVisiblePagesAroundCurrent = (
  currentPage,
  totalPages,
  maxNumberOfVisiblePages
) => {
  const firstAndLastPageNumberLength = 2;
  // Ensure odd number
  const maxVisiblePages =
    maxNumberOfVisiblePages % 2 === 0
      ? maxNumberOfVisiblePages + 1
      : maxNumberOfVisiblePages;
  const numbersEitherSide =
    (maxVisiblePages - firstAndLastPageNumberLength - 1) / 2; // numbers either side of current
  // First pages
  if (currentPage <= numbersEitherSide + 1) {
    return _.range(1, maxVisiblePages);
    // Last pages
  } else if (currentPage > totalPages - (numbersEitherSide + 1)) {
    return _.range(totalPages - maxVisiblePages + 2, totalPages + 1);
    // Middle pages
  } else {
    return _.range(
      currentPage - numbersEitherSide,
      currentPage + numbersEitherSide + 1
    );
  }
};

/**
 *
 * Get visible pages (to handle when there is a max number of visible pages)
 * e.g. when there are 30 pages total and max visible is 9
 * @param {number} currentPage
 * @param {number} totalPages
 * @param {number} maxNumberOfVisiblePages
 * @return {number[]}
 */
const getVisiblePages = (currentPage, totalPages, maxNumberOfVisiblePages) => {
  if (!maxNumberOfVisiblePages) {
    return _.range(1, totalPages + 1);
  }

  const firstAndLastPageNumber = [1, totalPages];
  return [
    ...new Set([
      ...firstAndLastPageNumber,
      ...getVisiblePagesAroundCurrent(
        currentPage,
        totalPages,
        maxNumberOfVisiblePages
      )
    ])
  ].sort((a, b) => a - b);
};

/**
 *
 * Checks if page is complete
 * @param {PageInfoItem} information
 */
const isPageComplete = information =>
  !!information &&
  !!information.totalCount &&
  information.completeCount === information.totalCount;

/**
 *
 * Get a list of the completed page numbers
 * @param {PageInfo} pageInfo
 */
const getCompletedPages = pageInfo =>
  Object.entries(pageInfo)
    .filter(([key, information]) => isPageComplete(information))
    .map(([key]) => +key);

/**
 *
 *
 * @param {PageInfo} pageInfo
 * @param {string} type
 * @param {number} totalPages
 * @return {string}
 */
const getPaginationMessage = (t, pageInfo, type, totalPages) => {
  if (type === paginationType.completionStatus && totalPages) {
    const completedPages = getCompletedPages(pageInfo);
    if (!completedPages.length) {
      return "";
    }
    return t("common:ui.pagination.sectionsCompleted", {
      complete: completedPages.length,
      total: totalPages
    });
  }

  return "";
};

/**
 *
 * Get page number icon name if needed
 * @param {PageInfoItem} information
 * @param {string} type
 * @return {string | null}
 */
const getPageIconName = (information, type) => {
  if (type === paginationType.completionStatus) {
    return isPageComplete(information) ? "check_circle" : null;
  }
  return null;
};

/**
 *
 * Get page number hover text if needed
 * @param {PageInfoItem} information
 * @param {string} type
 * @return {string[]}
 */
const getPageHoverText = (t, information, type) => {
  const lines = information?.name ? [information?.name] : [];
  if (type === paginationType.completionStatus) {
    if (!information?.totalCount) {
      return lines;
    }
    const complete = t("ui.pagination.complete", {
      complete: information.completeCount || 0,
      total: information.totalCount || 0
    });
    return [...lines, complete];
  }

  return lines;
};

export const paginationHelper = {
  paginationType,
  getPaginationMessage,
  getPageIconName,
  getPageHoverText,
  getVisiblePages,
  forTest: {
    getVisiblePagesAroundCurrent,
    isPageComplete,
    getCompletedPages
  }
};
