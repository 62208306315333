import React, { useCallback, useEffect, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation, withTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants/systemConstants";

import TextTruncate from "@shared-components/UI/textTruncate/TextTruncate";

import { useDataTable } from "@app/hooks";

import DataTable from "@components/molecules/DataTable";

import "./ClientEngagementsTable.scss";

const statusesToIndicatorColorScheme = {
  [systemConstants.engagement.status.active]: "warning",
  [systemConstants.engagement.status.inactive]: "dark",
  [systemConstants.engagement.status.completed]: "success"
};

const tableHeaderKeys = {
  name: "common:ui.engagement.name.label",
  client: "common:ui.engagement.client.label",
  engagementManager: "common:ui.engagement.engagementManager.label",
  totalProjects: "common:ui.engagement.totalProjects.label"
};

const ClientEngagementsTable = props => {
  const { clientEngagements, isEngagementManagerFieldEnabled } = props;
  const { t } = useTranslation();
  const [rowDisplay, setRowDisplay] = useState({ rowId: -1, display: false });
  const {
    findItem,
    createColumnForStatus,
    createColumn,
    createColumnForSingleAction,
    createColumnForDropdownMenu
  } = useDataTable(clientEngagements);

  const actions = {
    updateEngagement: {
      key: "updateEngagement",
      label: "common:ui.engagement.menu.updateEngagement"
    },
    viewDetails: { key: "viewDetails", label: "common:viewDetails" }
  };

  const handleAction = React.useCallback(
    (action, clientEngagementId) => {
      const clientEngagement = findItem(clientEngagementId);
      setRowDisplay({ rowId: -1, display: false });
      if (action.key === actions.updateEngagement.key) {
        props.onUpdateEngagement(clientEngagement);
      } else if (action.key === actions.viewDetails.key) {
        props.onClickEngagement(clientEngagement);
      }
    },
    [findItem, actions.updateEngagement, actions.viewDetails, props]
  );

  const singleActionHandler = React.useCallback(
    action => {
      return ({ cell }) => {
        const clientId = cell.row.original.id;
        handleAction(action, clientId);
      };
    },
    [handleAction]
  );

  const dropdownMenuActionHandler = React.useCallback(
    ({ menuItem, cell }) => {
      const clientId = cell.row.original.id;
      const action = { key: menuItem.key };
      handleAction(action, clientId);
    },
    [handleAction]
  );

  const cellVisibilityHandler = useCallback(
    rowId => {
      return rowDisplay?.rowId === rowId && rowDisplay?.display;
    },
    [rowDisplay]
  );

  const columns = React.useMemo(() => {
    const truncateTextCell = ({ value }) => (
      <TextTruncate text={value} inline={true} />
    );

    return [
      createColumnForStatus({ accessor: "statusIndicatorColorScheme" }),
      createColumnForSingleAction({
        Header: t(tableHeaderKeys.name),
        accessor: "name",
        onClickHandler: singleActionHandler(actions.viewDetails),
        width: 250,
        Cell: truncateTextCell
      }),
      createColumn({
        Header: t(tableHeaderKeys.client),
        accessor: "client",
        width: 200,
        isHidden: props.viewFor === "client",
        Cell: truncateTextCell
      }),
      createColumn({
        Header: t(tableHeaderKeys.totalProjects),
        accessor: "numTotalProject",
        width: 100,
        isHidden: props.viewFor === "host"
      }),
      createColumn({
        Header: t(tableHeaderKeys.engagementManager),
        accessor: "engagementManager",
        width: 200,
        isHidden: !isEngagementManagerFieldEnabled,
        Cell: truncateTextCell
      }),
      createColumnForSingleAction({
        accessor: "action",
        width: 150,
        onClickHandler: singleActionHandler(actions.viewDetails)
      }),
      createColumnForDropdownMenu({
        accessor: "actionsMenu",
        isHidden: props.userRole !== systemConstants.roles.engagementManager,
        onClickHandler: dropdownMenuActionHandler,
        displayCell: cellVisibilityHandler
      })
    ];
  }, [
    t,
    createColumnForStatus,
    createColumnForSingleAction,
    singleActionHandler,
    actions.viewDetails,
    createColumn,
    props.viewFor,
    props.userRole,
    createColumnForDropdownMenu,
    dropdownMenuActionHandler,
    cellVisibilityHandler,
    isEngagementManagerFieldEnabled
  ]);

  const data = React.useMemo(() => {
    const result = clientEngagements.map(item => {
      const statusIndicatorColorScheme =
        statusesToIndicatorColorScheme[item.status] || "dark";

      return {
        id: item.id,
        statusIndicatorColorScheme,
        name: item.name,
        client: item.client.name,
        engagementManager: item.engagementManager?.name,
        numTotalProject: item.projects?.length,
        action: t(actions.viewDetails.label),
        actionsMenu: [
          {
            key: actions.updateEngagement.key,
            label: t(actions.updateEngagement.label)
          }
        ]
      };
    });
    return result;
  }, [
    actions.updateEngagement.key,
    actions.updateEngagement.label,
    actions.viewDetails.label,
    clientEngagements,
    t
  ]);

  return (
    <>
      <DataTable
        className="client-engagements-table"
        columns={columns}
        data={data}
        onMouseEnterRow={rowId => setRowDisplay({ rowId, display: true })}
        onMouseLeaveRow={rowId => setRowDisplay({ rowId, display: false })}
      ></DataTable>
    </>
  );
};

ClientEngagementsTable.defaultProps = {};

ClientEngagementsTable.propTypes = {
  clientEngagements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      client: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired,
      engagementManager: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      }),
      projects: PropTypes.array
    })
  ),
  isEngagementManagerFieldEnabled: PropTypes.bool.isRequired,
  userRole: PropTypes.oneOf(Object.values(systemConstants.roles)).isRequired,
  viewFor: PropTypes.oneOf(["host", "client"]).isRequired,
  onClickEngagement: PropTypes.func.isRequired,
  onUpdateEngagement: PropTypes.func.isRequired
};

export default ClientEngagementsTable;
