import React from "react";

import PropTypes from "prop-types";

import "./ReviewButton.scss";

const ReviewButton = ({ id, hidden, onClick, hoverText, filled }) => {
  return (
    <>
      {!hidden && (
        <div
          onClick={e => onClick?.(e, id)}
          className={`review-button ${filled ? "review-button--filled" : ""}`}
          style={filled ? { visibility: "visible" } : {}}
        >
          <i title={hoverText} className="material-icons">
            {filled ? "star" : "star_outlined"}
          </i>
        </div>
      )}
    </>
  );
};

ReviewButton.propTypes = {
  id: PropTypes.number,
  hoverText: PropTypes.string,
  onClick: PropTypes.func,
  hidden: PropTypes.bool
};

export default ReviewButton;
