import React, { useCallback, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import wizardUtilities from "@shared/helpers/websheetWizard/websheetWizardUtilities";

import { useCallbackRef } from "@app/hooks/useCallbackRef";

import BrandButton from "@components/atoms/Button/BrandButton";

import ValidationWizard from "./ValidationWizard";

export function useValidationWizard({ validations }) {
  const { ref, callbackRef } = useCallbackRef();
  const { t } = useTranslation();
  const [validationErrors, setValidationErrors] = useState(null);

  const runValidation = useCallback(
    websheet => {
      if (!validations?.length) {
        return true;
      }

      const result = wizardUtilities.validateWebsheet(websheet, validations);
      if (!result.isValid) {
        setValidationErrors(result);
      } else {
        setValidationErrors(null);
      }
      return result.isValid;
    },
    [validations]
  );

  const shouldEnterStep = useCallback(
    websheetData => {
      const isValid = runValidation(websheetData.websheet);
      return !isValid;
    },
    [runValidation]
  );

  const postStep = useCallback((websheetData, context) => {
    // Just update the existing aggregated data because the user may have made changes
    context.aggregatedData.current = {
      websheet: websheetData.websheet,
      savedState: websheetData.savedState
    };

    return websheetData;
  }, []);

  const getWizard = useCallback(
    commonWizardProps => {
      return <ValidationWizard {...commonWizardProps} ref={callbackRef} />;
    },
    [callbackRef]
  );

  const stepProperties = useMemo(() => {
    return {
      isMandatory: false
    };
  }, []);

  const canSubmit = useCallback(() => {
    return ref?.canSubmit();
  }, [ref]);

  const getDataForSubmission = useCallback(() => {
    return ref?.getDataForSubmission();
  }, [ref]);

  const reRunValidation = useCallback(() => {
    const { websheet: websheetData } = getDataForSubmission();
    const result = runValidation(websheetData);
    return result;
  }, [getDataForSubmission, runValidation]);

  const getStepDescription = useCallback(() => {
    const validationErrorMessagesOrSuccessfulMessage = () => {
      const validationResultsWithErrors =
        validationErrors?.validations?.filter(v => !v.isValid && v.errors) ??
        [];

      if (validationResultsWithErrors.length) {
        const validationErrorMessage = validationResult => (
          <>
            {validationResult.errors.map(({ i18n, context }) => {
              return (
                <p key={`${validationResult.config.check}_${i18n}`}>
                  {t(i18n, context)}
                </p>
              );
            })}
          </>
        );

        return (
          <>
            {validationResultsWithErrors.map(validationResult => (
              <React.Fragment key={validationResult.config.check}>
                {validationErrorMessage(validationResult)}
              </React.Fragment>
            ))}
          </>
        );
      }

      return (
        <p>
          {t("common:ui.websheet.actions.cleaningWizard.validationError.fixed")}
        </p>
      );
    };

    return (
      <>
        {validationErrorMessagesOrSuccessfulMessage()}
        <BrandButton
          type="text-accent"
          size="minimal"
          iconName="autorenew"
          label={t(
            "common:ui.websheet.actions.cleaningWizard.validationError.reRunValidation"
          )}
          onClick={reRunValidation}
        />
      </>
    );
  }, [reRunValidation, t, validationErrors]);

  return {
    getWizard,
    getStepDescription,
    stepProperties,
    shouldEnterStep,
    canSubmit,
    getDataForSubmission,
    postStep
  };
}
