import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import ModalForm from "@components/molecules/ModalForm";

import "./SendToHost.scss";

const SendToHost = props => {
  const { t } = useTranslation();
  const { handleSubmit, lockSubmit, handleCancel, error } = props;

  return (
    <>
      <ModalForm
        boxClassName="ot-reassign-query"
        title={t(
          "requests:requests.configured.menu.actions.SEND_TO_HOST.label"
        )}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        disabled={lockSubmit}
        submitLabel={t(
          `${
            lockSubmit
              ? "requests:requests.ui.smartform.modal.submitButton.finalised.label"
              : "common:ui.forms.submit.label"
          }`
        )}
        cancelLabel={t("common:ui.forms.cancel.label")}
        errorMessage={error || null}
      >
        <div>{`${t("requests:requests.ui.smartForm.sendToHost.message")}`}</div>
      </ModalForm>
    </>
  );
};

SendToHost.defaultProps = {
  lockSubmit: false
};

SendToHost.propTypes = {
  host: PropTypes.any.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  lockSubmit: PropTypes.bool,
  error: PropTypes.string
};

export default SendToHost;
