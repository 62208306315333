import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { manageHostUsersActions } from "@shared/actions";
import { systemConstants } from "@shared/constants";
import { utilities } from "@shared/helpers";
import { useUpdateHostUserStatus } from "@shared/hooks";

import Button from "@components/atoms/Button/BrandButton";
import DropdownPagination from "@components/molecules/DropdownPagination";
import SearchTextInput from "@components/organisms/SearchTextInput";
import UsersDataTable from "@components/organisms/UserDataTable";
import PageTemplate from "@components/templates/PageTemplate";

import "./ManageTeamUsers.scss";

const routes = {
  addUser: "/admin/add-user"
};

const ManageTeamUsers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authentication = useSelector(state => state.authentication);
  const manageHostUsers = useSelector(state => state.manageHostUsers);
  const [filter, setFilter] = useState({ string: "", users: [] });
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({
    countPerPage: systemConstants.pagination.itemCountPerPage,
    pageCount: 1,
    currentPage: "Page 1",
    currentPageIndex: 0,
    pages: []
  });
  const { activateHostUser, deactivateHostUser } = useUpdateHostUserStatus();
  const { isKeywordInclude } = utilities;

  useEffect(() => {
    dispatch(manageHostUsersActions.getHostUsers(authentication.user));
    return () => dispatch(manageHostUsersActions.reset());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (manageHostUsers.users.length) {
      setFilter({ ...filter, users: manageHostUsers.users });
    }
    // eslint-disable-next-line
  }, [manageHostUsers.users]);

  useEffect(() => {
    if (filter.string) {
      utilities.createPagination({
        list: filter.users,
        pagination,
        setPagination
      });
    } else {
      utilities.createPagination({
        list: manageHostUsers.users,
        pagination,
        setPagination
      });
    }
    // eslint-disable-next-line
  }, [filter.users]);

  useEffect(() => {
    if (pagination.pages.length) {
      setUsers(pagination.pages[pagination.currentPageIndex].data);
    } else {
      setUsers([]);
    }
  }, [pagination]);

  const handleAddUser = () => {
    navigate(routes.addUser);
  };

  const handleUpdateUser = hostUser =>
    navigate(`/admin/users/${hostUser.id}/edit`);

  const handleFilterChange = searchInput => {
    if (!searchInput) {
      setFilter({ ...filter, string: "", users: manageHostUsers.users });
    } else {
      const filteredUsers = manageHostUsers.users.filter(
        user =>
          isKeywordInclude(user.name, searchInput) ||
          (user.email && isKeywordInclude(user.email, searchInput))
      );
      setFilter({
        ...filter,
        string: searchInput,
        users: filteredUsers
      });
    }
  };

  const handlePageSelection = pageName => {
    const index = pagination.pages.findIndex(page => page.name === pageName);
    setPagination({
      ...pagination,
      currentPage: pageName,
      currentPageIndex: index
    });
  };

  const handlePageCountIncrement = () => {
    const index = pagination.currentPageIndex;
    if (index < pagination.pages.length - 1) {
      setPagination({
        ...pagination,
        currentPage: `Page ${index + 2}`,
        currentPageIndex: index + 1
      });
    }
  };

  const handlePageCountDecrement = () => {
    const index = pagination.currentPageIndex;

    if (index > 0) {
      setPagination({
        ...pagination,
        currentPage: `Page ${index}`,
        currentPageIndex: index - 1
      });
    }
  };

  const headerActions = () => {
    return (
      <Button
        type="primary"
        label={t("common:ui.manageUsers.addLabel")}
        iconName="add"
        onClick={handleAddUser}
      />
    );
  };

  const primaryContent = () => {
    if (!users.length) {
      return <></>;
    }
    return (
      <>
        <div className="team-user__table">
          <UsersDataTable
            users={users}
            onUpdateUser={handleUpdateUser}
            onActivateUser={activateHostUser}
            onDeactivateUser={deactivateHostUser}
          />
        </div>
        <DropdownPagination
          handlePageSelection={handlePageSelection}
          handlePageCountIncrement={handlePageCountIncrement}
          handlePageCountDecrement={handlePageCountDecrement}
          pages={pagination.pages.map(page => ({ name: page.name }))}
          currentPage={pagination.currentPage}
        />
      </>
    );
  };

  return (
    <PageTemplate
      header={{
        title: "Manage Users",
        actions: headerActions()
      }}
      body={{
        header: (
          <SearchTextInput
            label={t("common:ui.manageUsers.searchLabel")}
            handleChange={handleFilterChange}
          />
        ),
        primary: primaryContent()
      }}
      other={{
        error: manageHostUsers.error
      }}
    />
  );
};

export default ManageTeamUsers;
