import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { routeConstants } from "@constants";

import { manageClientTagsActions } from "@shared/actions";

import Popup from "@shared-components/popup/Popup";

import BrandButton from "@components/atoms/Button/BrandButton";
import DropdownDotMenu from "@components/atoms/DropdownDotMenu";
import InlineAlert from "@components/atoms/InlineAlert";
import ModalTemplate from "@components/templates/ModalTemplate";

import "./ClientTagsDataTable.scss";

const tableConstants = {
  tags: "Tags",
  documents: "Documents",
  queries: "Requests"
};

const dropdownActions = {
  deleteTag: {
    key: "deleteTag",
    label: "common:ui.manageClientTags.menu.deleteTag"
  },
  updateTag: {
    key: "updateTag",
    label: "common:ui.manageClientTags.menu.updateTag"
  }
};

const ClientTagsDataTable = props => {
  const { t } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteTagShow, setDeleteTagShow] = useState(false);
  const [tag, setTag] = useState();
  const [errorDeleteShow, setErrorDeleteShow] = useState(false);
  const [warningDeleteShow, setWarningDeleteShow] = useState(false);
  const manageClientTags = useSelector(state => state.manageClientTags);
  const [error, setError] = useState("");

  useEffect(() => {
    if (manageClientTags.error) {
      setError(manageClientTags.error);
      setErrorDeleteShow(true);
    }
  }, [manageClientTags.error]);

  const handleMenuItemClick = (actionKey, data) => {
    if (actionKey === dropdownActions.deleteTag.key) {
      if (data.documents !== 0 || data.queries !== 0) {
        setWarningDeleteShow(true);
      } else {
        setDeleteTagShow(true);
        setTag(data);
      }
    } else if (actionKey === dropdownActions.updateTag.key) {
      navigate(routeConstants.updateClientTag, {
        state: {
          client: props.client,
          tag: data
        }
      });
    }
  };

  const handleDeleteClick = () => {
    if (tag) {
      setDeleteTagShow(false);
      if (!tag.isVirtual) {
        const tagToBeDeleted = {
          id: tag.id,
          clientId: props.client.id,
          name: tag.name
        };
        dispatch(
          manageClientTagsActions.deleteClientTag(props.client, tagToBeDeleted)
        );
      }
    }
  };
  const handleCancelDelete = () => {
    setDeleteTagShow(false);
  };

  const handleWarningDelete = () => {
    setWarningDeleteShow(false);
  };

  const handleErrorDelete = () => {
    setErrorDeleteShow(false);
  };

  const handleKeyPress = event => {
    if (event.key === "Escape") {
      if (warningDeleteShow) {
        setWarningDeleteShow(false);
      }
      if (errorDeleteShow) {
        setErrorDeleteShow(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  });

  const getRow = rowData => {
    return (
      <div key={rowData.id} className="client-tags-table__data-row">
        <span className="client-tags-table__data-cell client-tags-table__data-cell--name">
          {rowData.name}
        </span>
        <span className="client-tags-table__data-cell client-tags-table__data-cell--documents">
          {rowData.documents}
        </span>
        <span className="client-tags-table__data-cell client-tags-table__data-cell--queries">
          {rowData.queries}
        </span>
        <span className="client-tags-table__data-cell client-tags-table__data-cell--menu">
          <DropdownDotMenu
            menuItems={[
              {
                key: dropdownActions.deleteTag.key,
                label: t(dropdownActions.deleteTag.label)
              },
              {
                key: dropdownActions.updateTag.key,
                label: t(dropdownActions.updateTag.label)
              }
            ]}
            onMenuItemClick={menuItem =>
              handleMenuItemClick(menuItem.key, rowData)
            }
          />
        </span>
      </div>
    );
  };

  return (
    <>
      <div className="client-tags-table">
        <div className="client-tags-table__header">
          <span className="client-tags-table__header-label">
            {tableConstants.tags}
          </span>
          <span className="client-tags-table__header-label">
            {tableConstants.documents}
          </span>
          <span className="client-tags-table__header-label">
            {tableConstants.queries}
          </span>
          <span className="client-tags-table__header-label"></span>
        </div>

        <div className="client-tags-table__body">
          {props.data.map(rowData => getRow(rowData))}
        </div>
      </div>
      <Popup
        visibility={deleteTagShow}
        handleOutsideClick={false}
        width="60rem"
      >
        <ModalTemplate
          boxClassName="tag-deletion"
          title={t("common:ui.manageClientTags.deleteTagTitle")}
          content={t("common:ui.manageClientTags.deleteTagConfirmation")}
          footer={
            <>
              <BrandButton
                type="secondary"
                label="Cancel"
                onClick={handleCancelDelete}
              />
              <BrandButton
                type="danger"
                label="Yes"
                onClick={handleDeleteClick}
              />
            </>
          }
        />
      </Popup>
      <Popup visibility={warningDeleteShow} type={"component"} width="60rem">
        <ModalTemplate
          boxClassName="tag-deletion"
          title={t("common:ui.manageClientTags.deleteTagTitle")}
          content={
            <InlineAlert
              type="error"
              message={t("common:ui.manageClientTags.deleteTagHasAssociations")}
            />
          }
          footer={
            <>
              <BrandButton
                type="primary"
                label="Close"
                onClick={handleWarningDelete}
              />
            </>
          }
          onClose={handleWarningDelete}
        />
      </Popup>
      {error && (
        <Popup visibility={errorDeleteShow} type={"component"} width="60rem">
          <ModalTemplate
            boxClassName="tag-deletion"
            title={t("common:ui.manageClientTags.deleteTagTitle")}
            content={<InlineAlert type="error" message={error} />}
            footer={
              <>
                <BrandButton
                  type="primary"
                  label="Close"
                  onClick={handleErrorDelete}
                />
              </>
            }
            onClose={handleErrorDelete}
          />
        </Popup>
      )}
    </>
  );
};

ClientTagsDataTable.defaultProps = {};

ClientTagsDataTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      documents: PropTypes.number.isRequired,
      queries: PropTypes.number.isRequired
    })
  ).isRequired,
  client: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
};

export default withTranslation()(ClientTagsDataTable);
