import { systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { handleErrorResponse, handleResponse } from "./";

const baseURL = systemConstants.serverURL;

export const manageProjectDocumentsService = {
  getProjectFoldersAndDocuments,
  createProjectFolder,
  updateProjectDocumentProperties,
  uploadProjectDocument,
  deleteProjectDocument,
  uploadNewProjectDocument,
  getProjectDocumentRevisions,
  moveProjectDocument,
  restoreProjectDocumentRevision,
  getProjectDocumentsBySearchString,
  getArchivedProjectDocuments,
  updateProjectDocumentCurrentRevision,
  getAllTags,
  createProjectTag,
  createProjectTags,
  updateProjectTag,
  deleteProjectTag,
  restoreProjectDocument,
  getProjectDocumentsByTag,
  signProjectDocument
};

function getProjectDocumentsByTag({ projectId, tagId }) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/projects/${projectId}/documents?tagId=${tagId}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getAllTags(project) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/projects/${project.id}/documents/allTags`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function createProjectTag(project, tag) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      name: tag.name,
      projectId: project.id
    })
  };
  return fetch(`${baseURL}/api/projects/${project.id}/tags`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function createProjectTags(project, tags) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(tags)
  };

  return fetch(`${baseURL}/api/projects/${project.id}/tags`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function updateProjectTag(project, tag) {
  const tagToBeUpdated = {
    name: tag.name,
    id: tag.id,
    projectId: project.id
  };
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(tagToBeUpdated)
  };
  return fetch(
    `${baseURL}/api/projects/${project.id}/tags/${tagToBeUpdated.id}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function deleteProjectTag(project, tag, force) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    body: JSON.stringify(tag)
  };
  return fetch(
    `${baseURL}/api/projects/${project.id}/tags/${tag.id}${
      force ? "/force" : ""
    }`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getProjectDocumentRevisions(document) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/documents/${document.id}/documentRevisions`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getProjectDocumentsBySearchString(project, search) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/projects/${project.id}/documents/search?string=${search}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function updateProjectDocumentCurrentRevision(revision) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(revision)
  };
  return fetch(
    `${baseURL}/api/documentRevisions/${revision.id}/current`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getArchivedProjectDocuments(project) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/projects/${project.id}/documents/status?filter=${systemConstants.project.document.status.archived}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function restoreProjectDocumentRevision(revision) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(revision)
  };
  return fetch(
    `${baseURL}/api/documentRevisions/${revision.id}/restore`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getProjectFoldersAndDocuments(project, folderType = null) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/projects/${project.id}/documents/foldersAndDocuments?folderType=${folderType}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function createProjectFolder(folder) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(folder)
  };
  return fetch(
    `${baseURL}/api/projects/${folder.projectId}/folders`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function updateProjectDocumentProperties(document) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(document)
  };
  return fetch(`${baseURL}/api/documents/${document.id}`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function moveProjectDocument(document) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(document)
  };
  return fetch(`${baseURL}/api/documents/${document.id}`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function restoreProjectDocument(document) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(document)
  };
  return fetch(
    `${baseURL}/api/documents/${document.id}/restoreDocument`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function uploadProjectDocument(document) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(document)
  };
  return fetch(`${baseURL}/api/documents/${document.id}/upload`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function deleteProjectDocument(document) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader()
  };
  return fetch(`${baseURL}/api/documents/${document.id}`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function uploadNewProjectDocument(document) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(document)
  };
  return fetch(`${baseURL}/api/documents`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function signProjectDocument(documentIds, projectId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ documentIds })
  };
  return fetch(
    `${baseURL}/api/projects/${projectId}/finalPackage/signoff`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}
