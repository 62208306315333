import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";
import prepareHeaders from "./rtkUtilities";

export const userService = createApi({
  reducerPath: "userServiceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  tagTypes: ["User"],
  endpoints: builder => ({
    getUserById: builder.query({
      query: userId => `/api/users/${userId}`,
      providesTags: ["User"]
    }),
    addClientUser: builder.mutation({
      query: ({ user }) => ({
        url: `/api/clients/${user.clientId}/users`,
        method: "POST",
        body: user
      }),
      invalidatesTags: ["User"]
    }),
    updateClientUser: builder.mutation({
      query: ({ client, user }) => ({
        url: `/api/clients/${client.id}/users/${user.id}`,
        method: "PUT",
        body: user
      }),
      invalidatesTags: ["User"]
    }),
    addHostUser: builder.mutation({
      query: ({ user }) => ({
        url: `/api/hosts/${user.hostId}/users`,
        method: "POST",
        body: user
      }),
      invalidatesTags: ["User"]
    }),
    updateHostUser: builder.mutation({
      query: ({ user, userProfile }) => ({
        url: `/api/hosts/${user.hostId}/users/${user.id}`,
        method: "PUT",
        body: userProfile
      }),
      invalidatesTags: ["User"]
    })
  })
});

export const {
  useGetUserByIdQuery,
  useAddClientUserMutation,
  useAddHostUserMutation,
  useUpdateClientUserMutation,
  useUpdateHostUserMutation
} = userService;
