import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState
} from "react";

import { withTranslation } from "react-i18next";
import * as yup from "yup";

import {
  useAuthUser,
  useGetClientsWithEngagementsAndProjects
} from "@shared/hooks";

import Form from "@components/atoms/Form";
import InlineAlert from "@components/atoms/InlineAlert";
import LoaderSpinner from "@components/atoms/LoaderSpinner/LoaderSpinner";
import FileDownloader from "@components/organisms/FileDownloader/FileDownloader";
import MainPageTemplate from "@components/templates/MainPageTemplate";
import PageBodyTemplate from "@components/templates/PageBodyTemplate";

import "./DataExtraction.scss";

const DataExtraction = props => {
  const { t } = props;
  const [errorMsg, setErrorMsg] = useState();
  const { user: authUser } = useAuthUser();
  const [downloadParams, setDownloadParams] = useState(null);
  const [isLoaded, nowLoaded] = useReducer(() => true, false);
  const [isDownloading, setIsDownloading] = useState(false);

  const {
    clientsWithEngagementsAndProjects: clientEngagementProjects,
    fetchAllClientsWithEngagementsAndProjects
  } = useGetClientsWithEngagementsAndProjects();

  const projectList = useMemo(() => {
    return clientEngagementProjects.map(p => ({
      name: p.name,
      value: p.id
    }));
  }, [clientEngagementProjects]);

  useEffect(() => {
    if (!isLoaded && authUser) {
      fetchAllClientsWithEngagementsAndProjects({
        user: authUser,
        includeAllStatuses: true
      });
      nowLoaded();
    }
  }, [isLoaded, fetchAllClientsWithEngagementsAndProjects, authUser]);

  const errorHandler = useCallback(message => {
    setDownloadParams(null);
    setErrorMsg(message);
    setIsDownloading(false);
  }, []);

  const downloadingHandler = () => {
    setErrorMsg(null);
    setIsDownloading(false);
  };

  const handleSubmit = useCallback(
    ({ project: { value: projectId } }) => {
      if (isDownloading) {
        return;
      }

      setErrorMsg(null);
      setIsDownloading(true);
      setDownloadParams({
        apiUrl: "audit/download",
        data: {
          projectIds: [projectId]
        },
        sequenceId: new Date().getTime()
      });
    },
    [isDownloading]
  );

  const yupSchema = yup.object({
    project: yup.object().required(`Please select a project`)
  });

  const downloadButtonLabel = useMemo(() => {
    if (isDownloading) {
      return (
        <>
          <LoaderSpinner diameter="25"></LoaderSpinner>
          <div>Downloading Data</div>
        </>
      );
    } else {
      return "Download Data";
    }
  }, [isDownloading]);

  return (
    <MainPageTemplate>
      <PageBodyTemplate title={t("ui.sideNavigation.admin.dataExtraction")}>
        <div className="data-extraction">
          {errorMsg && (
            <div className="data-extraction__error-box">
              <InlineAlert type="warning" message={errorMsg} />
            </div>
          )}
          <Form
            handleSubmit={handleSubmit}
            submitLabel={downloadButtonLabel}
            yupSchema={yupSchema}
          >
            <Form.Dropdown
              name="project"
              label="Select a project to download data"
              items={projectList}
              required
            />
          </Form>
          {downloadParams && (
            <FileDownloader
              downloadParams={downloadParams}
              onError={errorHandler}
              onDownloading={downloadingHandler}
            ></FileDownloader>
          )}
        </div>
      </PageBodyTemplate>
    </MainPageTemplate>
  );
};

export default withTranslation()(DataExtraction);
