import React, { useEffect, useState } from "react";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { manageClientEngagementProjectsActions } from "@shared/actions";
import { systemConstants } from "@shared/constants";
import { utilities } from "@shared/helpers";

import Button from "@shared-components/button/Button";
import ErrorBox from "@shared-components/errorBox/ErrorBox";
import ProjectTimeline from "@shared-components/projectTimeline/ProjectTimeline";
import SearchBox from "@shared-components/searchBox/SearchBox";
import ClientEngagementProjectDataTable from "@shared-components/tableData/clientEngagementProjectDataTable/ClientEngagementProjectDataTable";

import DropdownPagination from "@components/molecules/DropdownPagination";
import MainPageTemplate from "@components/templates/MainPageTemplate";

import "./ProjectList.scss";

const ProjectList = props => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { manageClientEngagementProjects, authentication } = useSelector(
    state => state
  );
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({ string: "", projects: [] });
  const [projects, setProjects] = useState([]);
  const [pagination, setPagination] = useState({
    countPerPage: systemConstants.pagination.itemCountPerPage,
    pageCount: 1,
    currentPage: "Page 1",
    currentPageIndex: 0,
    pages: []
  });
  const { t } = useTranslation();
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    dispatch(manageClientEngagementProjectsActions.getProjectsByUser());
    return () => dispatch(manageClientEngagementProjectsActions.reset());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (manageClientEngagementProjects.projects.length) {
      setFilter({
        ...filter,
        projects: structuredClone(manageClientEngagementProjects.projects).sort(
          (a, b) => {
            let result = 1;
            if (a.status === b.status) {
              if (a.upcomingMilestone && b.upcomingMilestone) {
                if (a.upcomingMilestone.hours > b.upcomingMilestone.hours) {
                  result = 1;
                } else {
                  result = -1;
                }
              } else if (!b.upcomingMilestone) {
                result = -1;
              } else {
                result = 0;
              }
            } else {
              if (a.status === systemConstants.project.status.behind) {
                result = -1;
              } else if (
                a.status === systemConstants.project.status.onTrack &&
                (b.status === systemConstants.project.status.upcoming ||
                  b.status === systemConstants.project.status.completed ||
                  b.status === systemConstants.project.status.archived)
              ) {
                result = -1;
              } else if (
                a.status === systemConstants.project.status.upcoming &&
                (b.status === systemConstants.project.status.completed ||
                  b.status === systemConstants.project.status.archived)
              ) {
                result = -1;
              } else if (
                a.status === systemConstants.project.status.completed &&
                b.status === systemConstants.project.status.archived
              ) {
                result = -1;
              }
            }
            return result;
          }
        )
      });
    }
    // eslint-disable-next-line
  }, [manageClientEngagementProjects.projects]);

  useEffect(() => {
    if (filter.string) {
      utilities.createPagination({
        list: filter.projects,
        pagination,
        setPagination
      });
    } else {
      utilities.createPagination({
        list: structuredClone(manageClientEngagementProjects.projects),
        pagination,
        setPagination
      });
    }
    // eslint-disable-next-line
  }, [filter.projects]);

  useEffect(() => {
    if (pagination.pages.length) {
      setProjects(pagination.pages[pagination.currentPageIndex].data);
    } else {
      setProjects([]);
    }
  }, [pagination]);

  const handleFilterChange = event => {
    if (event.target.value) {
      setFilter({ ...filter, string: event.target.value });
    } else {
      setFilter({
        ...filter,
        string: "",
        projects: structuredClone(manageClientEngagementProjects.projects)
      });
    }
  };

  const handleProjectFilter = () => {
    if (filter.string) {
      const filteredProjects = structuredClone(
        manageClientEngagementProjects.projects
      ).filter(project =>
        project.name.toUpperCase().includes(filter.string.toUpperCase())
      );
      setFilter({ ...filter, projects: filteredProjects });
    } else {
      setFilter({
        ...filter,
        projects: structuredClone(manageClientEngagementProjects.projects)
      });
    }
  };

  const handlePageSelection = pageName => {
    utilities.handlePageSelection(pageName, pagination, setPagination);
  };

  const handlePageCountIncrement = () => {
    utilities.handlePageCountIncrement(pagination, setPagination);
  };

  const handlePageCountDecrement = () => {
    utilities.handlePageCountDecrement(pagination, setPagination);
  };

  return (
    <MainPageTemplate>
      <div className="client-engagement-projects__container">
        <div className="client-engagement-projects__header">
          <div className="client-engagement-projects__title">
            <h1 className="client-engagement-projects__title--text">
              Projects
            </h1>
          </div>
        </div>

        {manageClientEngagementProjects.error && (
          <div className="client-user-manager__error-box">
            <ErrorBox message={manageClientEngagementProjects.error} />
          </div>
        )}

        <div className="client-engagement-projects__search">
          <div className="client-engagement-projects__search--box">
            <SearchBox
              label={t("common:ui.engagement.searchProjectLabel")}
              handleChange={handleFilterChange}
              onPressEnter={handleProjectFilter}
            />
          </div>
          <Button
            type="plain"
            name={
              windowWidth > systemConstants.mediaBreakpoints.tabPort
                ? "Filter Projects"
                : "Filter"
            }
            icon="filter_list"
            handleClick={handleProjectFilter}
          />
        </div>

        {projects.length ? (
          <>
            {windowWidth > systemConstants.mediaBreakpoints.tabPort ? (
              <>
                <h3>Project Timeline</h3>
                <div className="client-engagement-projects__project-timeline-box">
                  <ProjectTimeline
                    projects={projects}
                    engagement={null}
                    defaultTimeStart={moment
                      .min(projects.map(project => moment(project.startDate)))
                      .add(-1, "day")}
                    defaultTimeEnd={moment
                      .max(
                        projects.map(project =>
                          moment(project.plannedCompletionDate)
                        )
                      )
                      .add(1, "day")}
                  />
                </div>
                <h3>Projects</h3>
              </>
            ) : (
              ""
            )}
            <div className="client-engagement-projects__table">
              <ClientEngagementProjectDataTable
                engagement={null}
                data={projects}
              />
            </div>
            <DropdownPagination
              handlePageSelection={handlePageSelection}
              handlePageCountIncrement={handlePageCountIncrement}
              handlePageCountDecrement={handlePageCountDecrement}
              pages={pagination.pages.map(page => ({ name: page.name }))}
              currentPage={pagination.currentPage}
            />{" "}
          </>
        ) : (
          ""
        )}
      </div>
    </MainPageTemplate>
  );
};

export default ProjectList;
