import { useCallback } from "react";

import { useUIConfig } from "@shared/hooks";

import { routeConstants } from "@app/constants";

export function useDefaultLandingPageLink() {
  const { uiConfig } = useUIConfig();
  const getDefaultLandingPageLink = useCallback(
    user => {
      if (user?.isClientAdmin || user?.isClientUser) {
        return uiConfig?.sideNavigation?.enabled &&
          uiConfig?.multipleProjectSelection?.enabled
          ? routeConstants.clientDashboard
          : routeConstants.clientUserLandingPage;
      } else if (user?.isHostAdmin || user?.isHostUser) {
        return uiConfig?.sideNavigation?.enabled
          ? routeConstants.clientDashboard
          : routeConstants.hostLandingPage;
      }
      return "";
    },
    [
      uiConfig?.multipleProjectSelection?.enabled,
      uiConfig?.sideNavigation?.enabled
    ]
  );
  return { getDefaultLandingPageLink };
}
