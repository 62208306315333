import React, { createContext, useContext, useReducer } from "react";

const initialState = {
  insightsCardId: null,
  rowId: null,
  columnId: null,
  data: null
};

const drilldownStateActions = {
  SET_DRILLDOWN_SELECTION: "SET_DRILLDOWN_SELECTION",
  CLEAR_DRILLDOWN_SELECTION: "CLEAR_DRILLDOWN_SELECTION"
};

function reducer(state, action) {
  switch (action.type) {
    case drilldownStateActions.SET_DRILLDOWN_SELECTION:
      return { ...state, ...action.payload };
    case drilldownStateActions.CLEAR_DRILLDOWN_SELECTION:
      return { ...initialState };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

function InsightsDrilldownStateProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DrilldownStateContext.Provider value={{ state, dispatch }}>
      {children}
    </DrilldownStateContext.Provider>
  );
}

function useDrilldownState() {
  const context = useContext(DrilldownStateContext);
  if (context === undefined) {
    throw new Error(
      "useDrilldownState must be used within a DrilldownStateProvider"
    );
  }
  return context;
}

const DrilldownStateContext = createContext(null);

export {
  InsightsDrilldownStateProvider,
  useDrilldownState,
  drilldownStateActions
};
