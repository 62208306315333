import {
  manageClientEngagementProjectsConstants,
  systemConstants
} from "../constants";

const initialState = {
  loading: false,
  projects: [],
  projectManagers: [],
  updatedProject: false,
  error: "",
  addedProject: false,
  myProjects: [],
  openItems: { openQueries: null, openRisks: null },
  loadingOpenQueries: false,
  loadingOpenRisks: false,
  engagement: null,
  loadingMyProjects: false
};

export function manageClientEngagementProjects(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_PROJECTS_OPEN_QUERIES_REQUEST:
      return {
        ...state,
        loadingOpenQueries: true
      };
    case manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_PROJECTS_OPEN_QUERIES_SUCCESS:
      return {
        ...state,
        loadingOpenQueries: false,
        openItems: action.openQueries && {
          ...state.openItems,
          openQueries: action.openQueries
        },
        error: ""
      };
    case manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_PROJECTS_OPEN_QUERIES_FAILURE:
      return {
        ...state,
        loadingOpenQueries: false,
        openItems: {
          ...state.openItems,
          openQueries: null
        },
        error: action.error.key ?? action.error.message
      };
    case manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_PROJECTS_OPEN_RISKS_REQUEST:
      return {
        ...state,
        loadingOpenRisks: true
      };
    case manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_PROJECTS_OPEN_RISKS_SUCCESS:
      return {
        ...state,
        loadingOpenRisks: false,
        openItems: action.openRisks && {
          ...state.openItems,
          openRisks: action.openRisks
        },
        error: ""
      };
    case manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_PROJECTS_OPEN_RISKS_FAILURE:
      return {
        ...state,
        loadingOpenRisks: false,
        openItems: {
          ...state.openItems,
          openRisks: null
        },
        error: action.error.key ?? action.error.message
      };
    case manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: action.projects,
        error: ""
      };
    case manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        projects: [],
        error: action.error.key ?? action.error.message
      };
    case manageClientEngagementProjectsConstants.GET_MY_PROJECTS_REQUEST:
      return {
        ...state,
        loadingMyProjects: true
      };
    case manageClientEngagementProjectsConstants.GET_MY_PROJECTS_SUCCESS:
      return {
        ...state,
        loadingMyProjects: false,
        myProjects: action.projects,
        error: ""
      };
    case manageClientEngagementProjectsConstants.GET_MY_PROJECTS_FAILURE:
      return {
        ...state,

        loadingMyProjects: false,

        myProjects: [],

        error: action.error.key ?? action.error.message
      };
    case manageClientEngagementProjectsConstants.UPDATE_PROJECT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageClientEngagementProjectsConstants.UPDATE_PROJECT_SUCCESS:
      const updatedProjects = state.projects.map(project => {
        if (project.id === action.updatedProject.id) {
          project.status =
            action.updatedProject.status ===
            systemConstants.project.status.completed
              ? action.updatedProject.status
              : project.status;
          project.startDate = action.updatedProject.startDate;
          project.plannedCompletionDate =
            action.updatedProject.plannedCompletionDate;
        }
        return project;
      });
      return {
        ...state,
        loading: false,
        projects: updatedProjects,
        updatedProject: action.updatedProject,
        error: ""
      };
    case manageClientEngagementProjectsConstants.UPDATE_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        projects: [],
        updatedProject: false,
        error: action.error.key ?? action.error.message
      };
    case manageClientEngagementProjectsConstants.ADD_CLIENT_ENGAGEMENT_PROJECT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageClientEngagementProjectsConstants.ADD_CLIENT_ENGAGEMENT_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        addedProject: true,
        error: ""
      };
    case manageClientEngagementProjectsConstants.ADD_CLIENT_ENGAGEMENT_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        addedProject: false,
        error: action.error.key ?? action.error.message
      };
    case manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_BY_PROJECT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_BY_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        engagement: action.engagement,
        error: ""
      };
    case manageClientEngagementProjectsConstants.GET_CLIENT_ENGAGEMENT_BY_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        engagement: null,
        error: action.error.key ?? action.error.message
      };
    case manageClientEngagementProjectsConstants.GET_PROJECT_MANAGERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageClientEngagementProjectsConstants.GET_PROJECT_MANAGERS_SUCCESS:
      return {
        ...state,
        loading: false,
        projectManagers: action.users,
        error: ""
      };
    case manageClientEngagementProjectsConstants.GET_PROJECT_MANAGERS_FAILURE:
      return {
        ...state,
        loading: false,
        projectManagers: [],
        error: action.error.key ?? action.error.message
      };
    case manageClientEngagementProjectsConstants.CLEAR_ERROR:
      return {
        ...state,
        error: ""
      };
    case manageClientEngagementProjectsConstants.SET_ERROR:
      return {
        ...state,
        error: action.error
      };
    case manageClientEngagementProjectsConstants.CLEAR_ADD_CLIENT_ENGAGEMENT_PROJECT_FLAG:
      return {
        ...state,
        error: "",
        addedProject: false,
        projectManagers: []
      };
    case manageClientEngagementProjectsConstants.CLEAR_UPDATE_CLIENT_ENGAGEMENT_PROJECT_FLAG:
      return {
        ...state,
        error: "",
        updatedProject: false,
        projectManagers: []
      };
    case manageClientEngagementProjectsConstants.CLEAR_MY_PROJECTS:
      return {
        ...state,
        myProjects: []
      };
    case manageClientEngagementProjectsConstants.CLEAR_OPEN_ITEMS:
      return {
        ...state,
        openItems: { openQueries: null, openRisks: null }
      };
    case manageClientEngagementProjectsConstants.RESET:
      return initialState;
    default:
      return state;
  }
}
