import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import ErrorBox from "@shared/components/errorBox/ErrorBox";

import Pill from "@components/atoms/Pill/Pill";
import EditableNewsItem from "@components/molecules/EditableNewsItem/EditableNewsItem";
import Loading from "@components/molecules/Loading";
import DashboardBoxTemplate from "@components/templates/DashboardBoxTemplate/DashboardBoxTemplate";

import "./EditableNews.scss";

const EditableNews = props => {
  const {
    newsItems,
    canBeDisplayed,
    handleMenuActionClick,
    newsConfiguration,
    isLoading,
    error
  } = props;

  const { t } = useTranslation();
  return (
    <DashboardBoxTemplate
      title={t("common:ui.news.edit.label_other")}
      boxClassName="edit-news--box"
    >
      {isLoading ? (
        <Loading message={t("common:loading")} />
      ) : (
        <>
          {error && <ErrorBox message={error} />}
          {newsItems?.map((item, key) => (
            <div
              className={`edit-news--box__content-item${
                canBeDisplayed(item) ? "" : "--unpublished"
              }`}
              key={key}
            >
              <Pill
                label={
                  canBeDisplayed(item)
                    ? t("common:ui.news.published.label")
                    : t("common:ui.news.unpublished.label")
                }
                size="small"
                borderColorScheme="primary"
                colorScheme={canBeDisplayed(item) ? "success" : "warning"}
                flexMode="inline"
                pointer={true}
              />
              <EditableNewsItem
                item={item.fields}
                handleMenuActionClick={handleMenuActionClick}
                menuActions={[
                  {
                    name: t("common:ui.forms.edit.label"),
                    action: "EDIT",
                    newsItem: item
                  },
                  {
                    name: t("common:ui.forms.delete.label"),
                    action: "DELETE",
                    newsItem: item
                  }
                ]}
                newsConfiguration={newsConfiguration}
              ></EditableNewsItem>
            </div>
          ))}
        </>
      )}
    </DashboardBoxTemplate>
  );
};

EditableNews.propTypes = {
  newsItems: PropTypes.arrayOf(PropTypes.object),
  handleMenuActionClick: PropTypes.func,
  newsConfiguration: PropTypes.object,
  isLoading: PropTypes.bool,
  error: PropTypes.any,
  canBeDisplayed: PropTypes.func
};

export default EditableNews;
