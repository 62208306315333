import React from "react";

import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Form from "@components/atoms/Form";
import CheckListItem from "@components/atoms/Form/CheckListItem/CheckListItem";

import "./ProjectDashboardFilter.scss";

function ProjectDashboardFilterForm(props) {
  const { filters, formMethods, showMyProjects } = props;
  const { t } = useTranslation();
  return (
    <FormProvider {...formMethods}>
      {filters.clients?.length ? (
        <Form.Dropdown
          name={"client"}
          label={t("common:ui.dashboard.filters.client.label")}
          items={filters.clients.map(client => ({
            name: client,
            value: client
          }))}
          placeholder={t("common:ui.dashboard.filters.client.placeholder")}
          showClear={true}
        />
      ) : (
        ""
      )}
      {filters.projectYears?.length ? (
        <Form.Dropdown
          name={"projectYear"}
          label={t("common:ui.dashboard.filters.projectYear.label")}
          items={filters.projectYears.map(year => ({
            name: year
              ? String(year)
              : t("common:ui.dashboard.filters.projectYear.noYear.label"),
            value: year ? String(year) : "none"
          }))}
          showClear={true}
          placeholder={t("common:ui.dashboard.filters.projectYear.placeholder")}
        />
      ) : (
        ""
      )}
      {filters.engagementTypes?.length ? (
        <Form.Dropdown
          name={"engagementType"}
          label={t("common:ui.dashboard.filters.engagementType.label")}
          items={filters.engagementTypes.map(type => ({
            name: type,
            value: type
          }))}
          placeholder={t(
            "common:ui.dashboard.filters.engagementType.placeholder"
          )}
          showClear={true}
        />
      ) : (
        ""
      )}
      <div className="project-dashboard-filter--checklist">
        <CheckListItem
          name={"completed"}
          label={t("common:ui.dashboard.filters.completed.label")}
        />
        {showMyProjects ? (
          <CheckListItem
            name={"myProjects"}
            label={t("common:ui.dashboard.filters.myProjects.label")}
            defaultValue={true}
          />
        ) : (
          ""
        )}
      </div>
    </FormProvider>
  );
}

ProjectDashboardFilterForm.defaultProps = {
  filters: {}
};

export default ProjectDashboardFilterForm;
