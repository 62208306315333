import React, { useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import BrandButton from "@components/atoms/Button/BrandButton";
import Icon from "@components/atoms/Icon/Icon";

import "./DeleteConfirm.scss";

const DeleteConfirm = props => {
  const { onDelete } = props;
  const [showConfirm, setShowConfirm] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      {!showConfirm && (
        <Icon
          name={"delete"}
          designStyle="material-symbols"
          fillStyle={"outlined"}
          onClick={() => {
            setShowConfirm(true);
          }}
          className="delete-confirm-icon"
        />
      )}
      {showConfirm && (
        <div className="delete-confirm">
          <div className="delete-confirm--label">
            {t("ui.documents.confirmDelete.label")}
          </div>
          <div className="delete-confirm--buttons">
            <BrandButton
              type="danger"
              label={t("common:ui.forms.delete.label")}
              size="minimal"
              className="delete-confirm--button"
              onClick={onDelete}
            />
            <BrandButton
              type="secondary"
              label={t("common:ui.forms.cancel.label")}
              size="minimal"
              className="delete-confirm--button"
              onClick={() => {
                setShowConfirm(false);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

DeleteConfirm.propTypes = {
  onDelete: PropTypes.func
};

export default DeleteConfirm;
