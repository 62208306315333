import React, { useRef } from "react";

import PropTypes from "prop-types";

import "./Pill.scss";

const Pill = props => {
  const {
    label,
    status,
    flexMode,
    onDelete,
    size,
    activeStyle,
    active,
    colorScheme,
    shape,
    logo,
    fontWeight,
    borderColorScheme,
    pointer,
  } = props;

  const pillLabelRef = useRef();
  const isPillOverflowing =
    pillLabelRef.current?.scrollWidth > pillLabelRef.current?.clientWidth;

  const getFlexClass = () => `ot-pill--${flexMode}`;
  const getDeletableClass = () => (onDelete ? "ot-pill--deletable" : "");
  const getDeleteButton = () => {
    return onDelete ? (
      <button
        className={`ot-pill__close ot-pill__close--${size}`}
        onClick={evt => {
          evt.stopPropagation();
          evt.preventDefault();
          onDelete();
        }}
      >
        <i className="material-icons">close</i>
      </button>
    ) : (
      ""
    );
  };

  const getActiveClass = () => `ot-pill--active--${status || activeStyle}`;

  return (
    <div
      data-testid="test-pill"
      className={`ot-pill
      ${active ? getActiveClass() : ""} 
      ot-pill--${size} ${getDeletableClass()} ot-pill--color-${colorScheme} ${getFlexClass()} ${
        pointer ? "ot-pill--pointer" : ""
      } ot-pill--${shape} ot-pill--border-color-${borderColorScheme} ${
        logo ? "ot-pill--logo" : ""
      }`}
    >
      {logo && <span className="pill-logo">{logo}</span>}
      <span
        className={`pill-label pill-label-${fontWeight}`}
        ref={pillLabelRef}
        title={isPillOverflowing ? label : ""}
      >
        {label}
      </span>
      {getDeleteButton()}
    </div>
  );
};

Pill.defaultProps = {
  size: "small",
  colorScheme: "primary",
  flexMode: "inline",
  pointer: false,
  active: false,
  activeStyle: "filled",
  shape: "rounded"
};

Pill.propTypes = {
  label: PropTypes.string.isRequired,
  fontWeight: PropTypes.oneOf(["bold", "semi-bold", "normal"]),
  size: PropTypes.oneOf(["small", "medium", "large"]).isRequired,
  flexMode: PropTypes.oneOf(["inline", "flex", "block"]),
  onDelete: PropTypes.func,
  shape: PropTypes.oneOf(["rounded", "slightly-rounded", "rectangle"]),
  active: PropTypes.bool,
  activeStyle: PropTypes.oneOf(["outline", "filled"]),
  status: PropTypes.oneOf([
    "open",
    "closed",
    "responded",
    "attachment",
    "note",
    "dashboard"
  ]),
  colorScheme: PropTypes.oneOf([
    "primary",
    "dark",
    "outline",
    "outline-grey",
    "success",
    "error",
    "warning",
    "overdue",
    "accent",
    "grey",
    "light-grey",
    "transparent",
    "secondary"
  ]),
  borderColorScheme: PropTypes.oneOf([
    "primary",
    "dark",
    "grey",
    "outline",
    "outline-grey",
    "success",
    "error",
    "warning",
    "transparent",
    "secondary"
  ]),
  pointer: PropTypes.bool
};

export default Pill;
