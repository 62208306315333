import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useOnClickOutside from "use-onclickoutside";

import UIConfigContext from "@app/helpers/UIConfigContext";
import { getStyleForDropdown } from "@app/helpers/componentHelpers";

import DropdownSearchBar from "@components/molecules/DropdownSearchBar/DropdownSearchBar";

import { useSearchableDropdown } from "../DropdownSearchable/useSearchableDropdown";
import Icon from "../Icon/Icon";
import "./DropdownInput.scss";
import "./OTDropdownInput.scss";

const DropdownInput = React.forwardRef((props, fwdRef) => {
  const {
    sortComparator,
    showSearchFilter,
    showLabel,
    isSideBySide,
    placeholder
  } = props;
  const { t } = useTranslation();
  const uiConfig = useContext(UIConfigContext);
  const [expanded, setExpanded] = useState(false);
  const [focus, setFocus] = useState(false);
  const [items, setItems] = useState([]);
  const [error, setError] = useState("");
  const dropdownRef = useRef();
  const labelRef = useRef();
  const [dropdownStyle, setDropdownStyle] = useState(null);
  const {
    inputRef,
    focusInput,
    searchItems,
    setSearchItems,
    handleClearFilter,
    getDropdownItems
  } = useSearchableDropdown({
    dropdownItems: items
  });

  const resetDropdownStyle = useCallback(() => {
    const style = getStyleForDropdown(
      dropdownRef?.current,
      props.parentId,
      isSideBySide
    );

    //Dropdown menu to close when >50% of the field is above the parent container
    const height = dropdownRef?.current?.getBoundingClientRect()?.height || 0;
    if (style.top && parseInt(style.top) < -(height / 2)) {
      setExpanded(false);
    }
    const labelHeight = labelRef?.current?.clientHeight ?? 0;
    setDropdownStyle({
      ...style,
      paddingTop: `${height - labelHeight}px`
    });
  }, [dropdownRef, props.parentId, isSideBySide]);

  useEffect(resetDropdownStyle, [resetDropdownStyle, expanded]);

  useEffect(() => {
    const getScrollElement = () => {
      if (props.scrollWithParent) {
        return document.getElementById(props.parentId);
      }

      return document.querySelector(".ot-form__content");
    };

    const scrollElement = getScrollElement();
    if (!scrollElement) {
      return () => {};
    }

    if (expanded) {
      scrollElement.addEventListener("scroll", resetDropdownStyle);
    } else {
      scrollElement.removeEventListener("scroll", resetDropdownStyle);
    }

    return () => {
      scrollElement.removeEventListener("scroll", resetDropdownStyle);
    };
  }, [expanded, props.parentId, props.scrollWithParent, resetDropdownStyle]);

  useOnClickOutside(dropdownRef, () => {
    setExpanded(false);
  });

  useEffect(() => {
    if (props.items) {
      const itemsWithValue = [...props.items];
      itemsWithValue.forEach(item => {
        if (!item.value) item.value = item.name;
      });
      const defaultSortFn = (a, b) =>
        a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
      itemsWithValue.sort(sortComparator ?? defaultSortFn);
      setItems(itemsWithValue);
    }
  }, [props.items, sortComparator]);

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  useEffect(() => {
    if (expanded) {
      focusInput();
    } else {
      setSearchItems("");
    }
  }, [focusInput, expanded, setSearchItems]);

  const getError = () => {
    if (error && error.length > 0 && !expanded) {
      return (
        <div className="ot-dropdown-input__errmsg">
          <i className="ot-dropdown-input__errmsg-icon material-icons">error</i>
          {error}
        </div>
      );
    }
    return <></>;
  };

  const getErrorClass = () => (error && error.length > 0 ? "error" : "");

  const getRequiredClass = () =>
    props.required && !isSideBySide ? "asterisk" : "";

  const getFocusClass = (value, focus) => (value?.name || focus ? "focus" : "");

  const getDisabledClass = () => (props.disabled ? "disabled" : "");

  const getShowClass = () => (expanded ? "reveal" : "");

  const getLabelClass = () => (showLabel && !!props.label ? "" : "no-label");

  const handleItemClicked = (event, selectedItem) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    setFocus(true);
    setExpanded(false);
    if (props.onChange) {
      props.onChange(selectedItem);
    }
  };

  const getThemeClass = () => {
    return `ot-dropdown-input--theme-${uiConfig?.theme?.designSystem ?? "tds"}`;
  };

  const getSideBySideLabelClass = () =>
    isSideBySide ? "side-by-side-label" : "";

  const classNames = list => list?.filter(c => c).join(" ");

  const getDropdownValue = () => {
    const defaultValue = props.value?.name || "";
    return props.transformSelected?.(props.value) ?? defaultValue;
  };

  return (
    <div
      className={classNames([
        "ot-dropdown-input",
        getShowClass(),
        getThemeClass()
      ])}
      ref={dropdownRef}
    >
      <a
        className={classNames([
          "dropdown-toggle",
          getErrorClass(),
          getDisabledClass(),
          getSideBySideLabelClass()
        ])}
        aria-expanded={`${expanded}`}
        aria-haspopup="true"
        data-toggle="dropdown"
        role="button"
        onClick={e => {
          e.stopPropagation();
          setExpanded(!expanded);
        }}
      >
        {showLabel && !!props.label && (
          <label
            className={classNames([
              "ot-dropdown-input__label",
              getRequiredClass(),
              getFocusClass(props.value, focus),
              getDisabledClass()
            ])}
            ref={labelRef}
          >
            {props.label}
          </label>
        )}
        <div
          className={classNames([
            "ot-dropdown-input__container",
            getDisabledClass(),
            getErrorClass()
          ])}
        >
          <input
            aria-label={props.label}
            data-testid={`test_${props.label?.replace(" ", "-") ?? ""}`}
            className={classNames([
              "ot-dropdown-input__textinput",
              getErrorClass(),
              getDisabledClass()
            ])}
            placeholder={placeholder}
            readOnly={true}
            ref={fwdRef}
            onFocus={event => {
              event.stopPropagation();
              event.target.value ? setFocus(true) : setFocus(false);
              if (props.onFocus) {
                props.onFocus(event);
              }
            }}
            value={getDropdownValue()}
            onBlur={event => {
              event.stopPropagation();
              event.target.value ? setFocus(true) : setFocus(false);
              if (props.onBlur) {
                props.onBlur(event);
              }
            }}
          />
          <div
            className={classNames([
              "ot-dropdown-input__info",
              getDisabledClass()
            ])}
            onClick={e => {
              e.stopPropagation();
              props.onChange();
            }}
          >
            {props.showClear && getDropdownValue() && (
              <div
                className="ot-dropdown-input__clear"
                onClick={event => handleItemClicked(event, null)}
              >
                <Icon
                  size="medium"
                  name="close"
                  className="ot-dropdown-input__clear--cross"
                />
              </div>
            )}
            <i
              className={classNames([
                "material-icons",
                "ot-dropdown-input__expand_less",
                getDisabledClass(),
                expanded ? "reveal" : ""
              ])}
            >
              expand_less
            </i>
            <i
              className={classNames([
                "material-icons",
                "ot-dropdown-input__expand_more",
                getDisabledClass(),
                expanded ? "" : "reveal"
              ])}
            >
              expand_more
            </i>
          </div>
        </div>
      </a>

      {getError()}
      <div
        aria-labelledby="single-select-default"
        className={classNames([
          "ot-dropdown-input__menu",
          getShowClass(),
          getLabelClass()
        ])}
        style={dropdownStyle}
      >
        {showSearchFilter && (
          <DropdownSearchBar
            onChange={event => {
              setSearchItems(event.target.value ?? "");
            }}
            onClear={handleClearFilter}
            ref={inputRef}
            value={searchItems}
            placeholder={placeholder ?? t("common:ui.forms.search.label")}
          />
        )}
        <div className="ot-dropdown-input__item-container">
          {getDropdownItems()?.map((item, index) => {
            return (
              <button
                key={(item.name ?? "") + index}
                onClick={event => {
                  handleItemClicked(event, item);
                }}
                className="ot-dropdown-input__item"
                name={item.name}
                data-value={index}
                value={item.name}
                type="button"
              >
                {item.name}{" "}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
});

DropdownInput.defaultProps = {
  showSearchFilter: true,
  showLabel: true,
  isSideBySide: false,
  showClear: false
};

DropdownInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.shape({ name: PropTypes.string, value: PropTypes.any }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any
    })
  ).isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  transformSelected: PropTypes.func,
  error: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  index: PropTypes.number,
  parentId: PropTypes.string,
  showSearchFilter: PropTypes.bool,
  scrollWithParent: PropTypes.bool,
  showLabel: PropTypes.bool,
  placeholder: PropTypes.string,
  sortComparator: PropTypes.func,
  isSideBySide: PropTypes.bool,
  showClear: PropTypes.bool
};

export default DropdownInput;
