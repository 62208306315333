import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { smartFormResponseType } from "@app/helpers/smartForm";

import BrandButton from "@components/atoms/Button/BrandButton";
import Icon from "@components/atoms/Icon/Icon";

import "./SmartFormEntityProgress.scss";

const SmartFormEntityProgress = ({
  assignedEntities,
  answeredEntities,
  relevantEntitiesById,
  questionRelevantEntities,
  className,
  onClick,
  extraIcon,
  buttonType,
  maxEntities,
  responseType,
  text
}) => {
  const { t } = useTranslation();
  const numAssignedEntities = assignedEntities?.length || 0;
  const numAnsweredEntities = answeredEntities?.length || 0;
  const numTotalEntities =
    questionRelevantEntities && questionRelevantEntities.length
      ? questionRelevantEntities.length
      : Object.keys(relevantEntitiesById)?.length;

  const entityShouldBeAnswered = entityId => {
    if (questionRelevantEntities && questionRelevantEntities.length) {
      return (
        !assignedEntities.includes(entityId) &&
        questionRelevantEntities.includes(entityId)
      );
    } else {
      return !assignedEntities.includes(entityId);
    }
  };

  const missingEntities = [];
  const isWebsheet = smartFormResponseType.WEBSHEET;
  const isDocument = smartFormResponseType.DOCUMENT;
  const incompleteEntities = () => {
    if (responseType === isWebsheet || responseType === isDocument) {
      return assignedEntities
        .filter(e => !answeredEntities.includes(e))
        .map(e => relevantEntitiesById[e]);
    }
    return [];
  };

  Object.entries(relevantEntitiesById).forEach(([id, name]) => {
    if (entityShouldBeAnswered(+id)) {
      missingEntities.push(name);
    }
  });

  const numEntitiesOverMax = entities => {
    return Math.max(0, entities.length - maxEntities);
  };

  const hoverElement = () => {
    const hoverText = (entities, title) => {
      const overMax = numEntitiesOverMax(entities);
      const entitiesToShow = entities.slice(0, maxEntities).join(", ");
      const numOfOtherEntities = entities.length > maxEntities && t("requests:requests.ui.smartForm.numOfOtherEntities", {
        count: overMax
      });

      return (
        entities.length > 0 && (
          <span className="smart-form-entity-progress__missing-entities">
          {title}
          <span className="smart-form-entity-progress__missing-entities__names">
              {entitiesToShow}
              {numOfOtherEntities}
          </span>
        </span>
        )
      );
    };

    if (!missingEntities?.length && !incompleteEntities()?.length) {
      return null;
    }
    if (text) {
      const combinedIncompleteEntities = [
        ...new Set([...missingEntities, ...incompleteEntities()])
      ];

      return <>{hoverText(combinedIncompleteEntities, text)}</>;
    }
    return (
      <>
        {hoverText(
          missingEntities,
          t("requests:requests.ui.smartForm.missingEntities.label")
        )}
        {hoverText(
          incompleteEntities(),
          t("requests:requests.ui.smartForm.incompleteEntities.label")
        )}
      </>
    );
  };

  const buttonLabel = () => {
    const assignedEntities = t(
      "requests:requests.ui.smartForm.entitiesAssigned.label",
      {
        assignedEntities: numAssignedEntities,
        totalEntities: numTotalEntities
      }
    );

    if (responseType === smartFormResponseType.WEBSHEET) {
      const answeredEntities = t(
        "requests:requests.ui.smartForm.answeredEntities.label",
        {
          answeredEntities: numAnsweredEntities,
          totalEntities: numTotalEntities
        }
      );
      return (
        <>
          <div className="smart-form-entity-progress__container__button">
            {assignedEntities}
          </div>
          <div className="smart-form-entity-progress__container__button">
            {answeredEntities}
          </div>
        </>
      );
    }
    return assignedEntities;
  };

  const complete = missingEntities.length === 0;

  return (
    <div className="smart-form-entity-progress__container">
      {extraIcon && (
        <Icon name={extraIcon} fillStyle="outlined" size="medium" />
      )}
      <BrandButton
        className={`smart-form-entity-progress ${className}`}
        type={buttonType}
        iconName={complete ? "check_circle" : "circle"}
        iconSide="left"
        iconOutlined={!complete}
        iconSize="medium"
        size="small"
        label={buttonLabel()}
        onClick={onClick}
        textTransform="none"
        hoverElement={hoverElement()}
      />
    </div>
  );
};

SmartFormEntityProgress.defaultProps = {
  questionRelevantEntities: null,
  className: null,
  text: null,
  onClick: null,
  buttonType: "text",
  maxEntities: 10
};

SmartFormEntityProgress.propTypes = {
  assignedEntities: PropTypes.arrayOf(PropTypes.number).isRequired,
  answeredEntities: PropTypes.arrayOf(PropTypes.number),
  relevantEntitiesById: PropTypes.any.isRequired,
  questionRelevantEntities: PropTypes.arrayOf(PropTypes.number),
  className: PropTypes.string,
  onClick: PropTypes.func,
  buttonType: PropTypes.string,
  buttonIcon: PropTypes.string,
  maxEntities: PropTypes.number,
  extraIcon: PropTypes.string,
  responseType: PropTypes.oneOf([
    "boolean",
    "text",
    "singleChoice",
    "date",
    "number",
    "document",
    "websheet"
  ]),
  text: PropTypes.string
};

export default SmartFormEntityProgress;
