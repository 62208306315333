import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";

export const cleaningTemplateService = createApi({
  reducerPath: "cleaningTemplateApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders: (headers, { getState }) => {
      const token =
        getState().authentication?.token || localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["saveCleaningTemplate", "getCleaningTemplateById"],
  endpoints: builder => ({
    saveCleaningTemplate: builder.mutation({
      query: ({ cleaningTemplate }) => ({
        url: `/api/cleaningTemplate`,
        method: "POST",
        body: cleaningTemplate
      }),
      invalidatesTags: ["saveCleaningTemplate", "getCleaningTemplateById"]
    }),
    getCleaningTemplateById: builder.query({
      query: ({ id }) => ({
        url: `/api/cleaningTemplate/${id}`,
        method: "GET"
      }),
      providesTags: ["getCleaningTemplateById"]
    }),
    getCleaningTemplatesByTemplateHeaders: builder.query({
      query: ({ projectId, templateHeaders }) => {
        const queryParams = new URLSearchParams(
          templateHeaders.map(th => ["templateHeader", th])
        );
        return {
          url: `/api/cleaningTemplate/${projectId}/templateHeaders?${queryParams}`,
          method: "GET"
        };
      },
      invalidatesTags: ["getCleaningTemplatesByTemplateHeaders"]
    })
  })
});

export const {
  useSaveCleaningTemplateMutation,
  useGetCleaningTemplateByIdQuery,
  useGetCleaningTemplatesByTemplateHeadersQuery
} = cleaningTemplateService;
