import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react";

import PropTypes from "prop-types";

import wizardUtilities from "@shared/helpers/websheetWizard/websheetWizardUtilities";

import WizardStepsBar from "@components/molecules/WizardStepsBar/WizardStepsBar";

import "./WebsheetCleaningWizard.scss";
import { useCleanupWizard } from "./steps/CleanupWizard/useCleanupWizard";
import { useColumnSelectionWizard } from "./steps/ColumnSelectionWizard/useColumnSelectionWizard";
import { useReplayWizard } from "./steps/ReplayWizard/useReplayWizard";
import { useFirstDataRowSelectionWizard } from "./steps/RowSelectionWizard/useFirstDataRowSelectionWizard";
import { useUnnecessaryRowSelectionWizard } from "./steps/RowSelectionWizard/useUnnecessaryRowSelectionWizard";
import { useValidationWizard } from "./steps/ValidationWizard/useValidationWizard";

const WebsheetCleaningWizard = React.forwardRef((props, fwdRef) => {
  const {
    loading,
    error,
    data,
    hasErrorInCurrentSheet,
    questionConfig,
    handleCompleteCleaning,
    handleOpenModal,
    handleCloseModal,
    setWebsheetModalRenderer,
    onSheetLoading,
    isReplay,
    savedState,
    onCurrentSheetNameChanged: parentOnCurrentSheetNameChanged,
    onStepBarDisplayed,
    preCleanFormData,
    isNextFileAvailable
  } = props;
  const [currentStepNumber, setCurrentStepNumber] = useState(1);
  const [currentStepData, setCurrentStepData] = useState({
    websheet: null,
    savedState: null
  });
  const [visitedSteps, setVisitedSteps] = useState([]);
  const [defaultSheetName, setDefaultSheetName] = useState("");
  const [processedSheets, setProcessedSheets] = useState([]);
  const [totalNumberOfSheets, setTotalNumberOfSheets] = useState(0);
  // useRef because we don't care about re-rendering when this changes
  const aggregatedData = useRef({
    websheet: null,
    savedState: null
  });

  const templateHeaders = useMemo(
    () => questionConfig?.templateHeaders ?? [],
    [questionConfig?.templateHeaders]
  );

  const validations = useMemo(
    () => questionConfig?.validations ?? [],
    [questionConfig?.validations]
  );

  const onSelectCleanseProcess = useCallback(() => {
    handleOpenModal("cleanseWizardProcess");
  }, [handleOpenModal]);

  // To enable replayWizard for testing, just add it to the list below
  const stepA = useReplayWizard({ templateHeaders, onSelectCleanseProcess });
  const step1 = useColumnSelectionWizard({ templateHeaders });
  const step2 = useFirstDataRowSelectionWizard({ templateHeaders });
  const step3 = useUnnecessaryRowSelectionWizard();
  const step4 = useValidationWizard({ validations });
  const step5 = useCleanupWizard();

  const wizardSteps = useMemo(() => {
    return isReplay
      ? [stepA, step4, step5]
      : [step1, step2, step3, step4, step5];
  }, [isReplay, stepA, step1, step2, step3, step4, step5]);

  const getWizardByStep = useCallback(
    stepNumber => wizardSteps[stepNumber - 1],
    [wizardSteps]
  );
  const currentWizardStep = useMemo(() => {
    return getWizardByStep(currentStepNumber);
  }, [currentStepNumber, getWizardByStep]);

  const visibleSteps = useMemo(() => {
    return wizardSteps
      .map((ws, idx) => ({
        stepNumber: idx + 1,
        description: ws.getStepDescription(totalNumberOfSheets),
        isCountable:
          ws.stepProperties.isMandatory || visitedSteps.includes(idx + 1),
        getActionButtons: ws.getStepButtons
      }))
      .filter(ws => ws.isCountable);
  }, [wizardSteps, visitedSteps, totalNumberOfSheets]);

  const visitStep = stepNumber => {
    setVisitedSteps(curr => {
      const visited = new Set(curr);
      visited.add(stepNumber);
      return [...visited];
    });
  };

  const initDataForFirstStep = useCallback(() => {
    if (currentStepNumber !== 1) {
      return;
    }
    const dataObj = structuredClone(data);
    const websheet = wizardUtilities.prepareDataForCleaning(
      dataObj,
      preCleanFormData
    );

    let newDefaultSheetName = defaultSheetName;
    const sheetNames = websheet.map(item => item.sheet);
    if (!sheetNames.includes(newDefaultSheetName)) {
      newDefaultSheetName = websheet[0]?.sheet;
    }

    setDefaultSheetName(newDefaultSheetName);
    setCurrentStepData({ websheet, savedState });
    setVisitedSteps([]);
    setTotalNumberOfSheets(sheetNames.length);
  }, [currentStepNumber, data, preCleanFormData, defaultSheetName, savedState]);

  useEffect(() => {
    initDataForFirstStep();
    visitStep(currentStepNumber);
  }, [
    data,
    currentStepNumber,
    savedState,
    preCleanFormData,
    defaultSheetName,
    initDataForFirstStep
  ]);

  const getNextStepNumber = useCallback(
    (fromStep, websheetData) => {
      const isolatedData = structuredClone(websheetData);

      let delta = 1;
      const context = { aggregatedData };
      while (
        !getWizardByStep(fromStep + delta).shouldEnterStep(
          isolatedData,
          context
        )
      ) {
        delta++;
      }

      return fromStep + delta;
    },
    [getWizardByStep]
  );

  const onNext = useCallback(
    ({ loop: shouldLoop }) => {
      if (!currentWizardStep) {
        return;
      }

      if (shouldLoop) {
        // goes back to step-1 but since we have aggregatedData and processedSheets, columnSelection will ignore those sheets
        setCurrentStepData(null);
        setCurrentStepNumber(1);
        setVisitedSteps([]);
        visitStep(1);
        return;
      }

      let outputData = currentWizardStep.getDataForSubmission?.();
      const context = { aggregatedData, setProcessedSheets };

      // POST step action from the current step
      outputData =
        currentWizardStep.postStep?.(outputData, context) ?? outputData;

      const nextStep = getNextStepNumber(currentStepNumber, outputData);

      // PRE step action for the next step
      outputData =
        getWizardByStep(nextStep).preStep?.(outputData, context) ?? outputData;

      setCurrentStepData(outputData);
      setCurrentStepNumber(nextStep);
      visitStep(nextStep);
    },
    [currentWizardStep, getNextStepNumber, currentStepNumber, getWizardByStep]
  );

  const canSubmit = useMemo(() => {
    return !loading && currentWizardStep?.canSubmit?.();
  }, [currentWizardStep, loading]);

  const onStartOver = useCallback(() => {
    const dataObj = structuredClone(data.websheet);
    const websheet = wizardUtilities.prepareDataForCleaning(dataObj);
    setCurrentStepNumber(1);
    setCurrentStepData({ websheet, savedState: {} });
    setVisitedSteps([]);
    setProcessedSheets([]);
    aggregatedData.current = {
      websheet: null,
      savedState: null
    };
  }, [data]);

  useEffect(() => {
    setWebsheetModalRenderer(currentWizardStep?.getModal);
  }, [currentWizardStep?.getModal, setWebsheetModalRenderer]);

  useImperativeHandle(
    fwdRef,
    () => ({
      handleUpdate: () => currentWizardStep?.getDataForSubmission?.()
    }),
    [currentWizardStep]
  );

  const onCurrentSheetNameChanged = useCallback(
    sheetName => {
      const hasSheet = data.find(s => s.sheet === sheetName);
      if (hasSheet) {
        parentOnCurrentSheetNameChanged?.(sheetName);
      }
    },
    [parentOnCurrentSheetNameChanged, data]
  );

  const renderCurrentStep = useMemo(() => {
    const commonProps = {
      loading,
      error,
      hasErrorInCurrentSheet,
      data: currentStepData,
      handleOpenModal,
      handleCloseModal,
      onSheetLoading,
      onCurrentSheetNameChanged,
      defaultSheetName,
      disallowedSheets: processedSheets
    };

    return getWizardByStep(currentStepNumber).getWizard(commonProps);
  }, [
    loading,
    error,
    hasErrorInCurrentSheet,
    currentStepData,
    handleOpenModal,
    handleCloseModal,
    onSheetLoading,
    onCurrentSheetNameChanged,
    defaultSheetName,
    getWizardByStep,
    currentStepNumber,
    processedSheets
  ]);

  if (!currentStepData?.websheet?.length) {
    return <></>;
  }

  return (
    <>
      <div>
        <WizardStepsBar
          currentStepNumber={currentStepNumber}
          steps={visibleSteps}
          onNext={onNext}
          onStartOver={onStartOver}
          onComplete={handleCompleteCleaning}
          disableProgression={!canSubmit}
          onSelectCleanseProcess={onSelectCleanseProcess}
          onStepBarDisplayed={onStepBarDisplayed}
          isNextFileAvailable={isNextFileAvailable}
          context={{
            numSheets: totalNumberOfSheets,
            numSheetsProcessed: processedSheets.length,
            isReplay
          }}
        />
        {renderCurrentStep}
      </div>
    </>
  );
});

WebsheetCleaningWizard.propTypes = {
  questionConfig: PropTypes.shape({
    templateHeaders: PropTypes.array
  }).isRequired,
  onSheetLoading: PropTypes.func,
  isReplay: PropTypes.bool,
  defaultSheetName: PropTypes.string,
  loading: PropTypes.bool,
  preCleanFormData: PropTypes.object
};

WebsheetCleaningWizard.displayName = "WebsheetCleaningWizard";

export default WebsheetCleaningWizard;
