import React, { useMemo, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import DocumentsDragNDrop from "@shared-components/dragndrop/DocumentsDragNDrop";

import "./UploadFile.scss";

const UploadFile = props => {
  const { t } = useTranslation();
  const {
    handleDrop,
    supportedDocumentMimes,
    handleClickBox,
    disabled,
    maxNumberOfFiles,
    handleRejection,
    dropMessage,
    openFileSelection,
    hideUpload,
    linkName,
    label
  } = props;

  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const protectedHandleDrop = useMemo(() => {
    if (disabled) {
      return () => { };
    }
    return handleDrop;
  }, [disabled, handleDrop]);
  const maxFiles = useMemo(() => maxNumberOfFiles || 0, [maxNumberOfFiles]);

  const handleDragOver = e => {
    setIsDraggingOver(true);
  };

  const handleDropEvent = e => {
    setIsDraggingOver(false);
  };

  const handleDragEnd = e => {
    if (
      e.target.className?.includes("upload-file__body") ||
      e.target.className?.includes("document-drag-box")
    ) {
      setIsDraggingOver(false);
    }
  };

  const message = dropMessage
    ? `${dropMessage} `
    : t("common:ui.forms.fileUpload.dragDropLabel");

  return (
    <div
      className={`upload-file ${disabled ? "upload-file--disabled" : ""}`}
      onClick={handleClickBox}
    >
      {label && <label className="upload-file__label">{label}</label>}
      {isDraggingOver && (
        <div className="upload-file__dropzone">
          <div className="upload-file__dropzone__text">
            {t("common:ui.forms.fileUpload.dropZoneLabel")}
          </div>
        </div>
      )}

      <div
        className={`upload-file__body`}
        onDragOver={handleDragOver}
        onDrop={handleDropEvent}
        onDragLeave={handleDragEnd}
      >
        <DocumentsDragNDrop
          className={`document-drag-box ${hideUpload ? "document-drag-box--hide-upload" : ""
            }`}
          message={message}
          linkName={linkName ?? t("common:ui.forms.fileUpload.browseLabel")}
          handleDrop={protectedHandleDrop}
          supportedMimes={supportedDocumentMimes}
          maxFiles={maxFiles}
          onDropRejected={handleRejection}
          openFileSelection={openFileSelection}
        />
      </div>
    </div>
  );
};

UploadFile.defaultProps = {
  disabled: false,
  openFileSelection: false,
  hideUpload: false
};

UploadFile.propTypes = {
  handleDrop: PropTypes.func,
  supportedDocumentMimes: PropTypes.array,
  handleClickBox: PropTypes.func,
  disabled: PropTypes.bool,
  maxNumberOfFiles: PropTypes.number,
  handleRejection: PropTypes.func,
  openFileSelection: PropTypes.bool,
  hideUpload: PropTypes.bool,
  dropMessage: PropTypes.string,
  label: PropTypes.string,
  linkName: PropTypes.string
};

export default UploadFile;
