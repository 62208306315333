import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  manageDocumentDownloadsActions,
  manageProjectQueriesActions,
  manageQueryResponsesActions
} from "@shared/actions";
import { smartFormConstants, systemConstants } from "@shared/constants";
import {
  useAuthUser,
  useCurrentProject,
  useGetHostObject,
  useGetProjectById,
  useGetProjectMembers,
  useGetProjectQuery,
  useLazyGetAnswersQuery,
  useRequestPageNavigator,
  useSaveAnswerMutation,
  useSendToHostMutation,
  useToasts,
  useUIConfig,
  useUpdateQuery
} from "@shared/hooks";
import usePubSub from "@shared/hooks/usePubSub";
import { useGetActionItemTypesQuery } from "@shared/services/actionItemTypesService";

import Popup from "@shared-components/popup/Popup";

import { routeConstants } from "@app/constants/routeConstants";
import { getActionItemTypeDisplayName } from "@app/helpers";
import { populateRelevantEntitiesByIdToObject } from "@app/helpers/entity";
import { smartFormActions, smartFormStructure } from "@app/helpers/smartForm";
import { smartFormUpdatedBy } from "@app/helpers/smartForm/smartFormConstants";

import Icon from "@components/atoms/Icon/Icon";
import InfoMessageBox from "@components/molecules/InfoMessageBox";
import ProjectAccessModal from "@components/molecules/ProjectAccessModal";
import RequestDetailsBox from "@components/molecules/RequestDetailsBox";
import RequestEntitiesBox from "@components/molecules/RequestEntitiesBox";
import SmartFormModal from "@components/molecules/SmartFormModal";
import EditActionTags from "@components/organisms/EditActionTags";
import QueryActions, {
  buttonActionConstants
} from "@components/organisms/QueryActions";
import ReassignQuery from "@components/organisms/QueryActions/ReassignQuery";
import SendToHost from "@components/organisms/QueryActions/SendToHost";
import UpdateCopiedTo from "@components/organisms/QueryActions/UpdateCopiedTo";
import SmartForm from "@components/organisms/SmartForm/SmartForm";
import CommentsModal from "@components/organisms/SmartFormCommentsModal";
import PageTemplate from "@components/templates/PageTemplate/PageTemplate";

import "./SmartFormPage.scss";

const statusTypes = systemConstants.project.queries.status;
const smartFormActionItemType = systemConstants.actionItemTypes.smartForm;
const { contentKeys } = smartFormConstants;

const SmartFormPage = props => {
  const { t } = useTranslation();
  const { updatedQuery, updateQuery } = useUpdateQuery();
  const {
    query: fetchedQuery,
    errorFetchingQuery,
    refetchQuery
  } = useGetProjectQuery();
  const location = useLocation();
  const [query, setQuery] = useState(location.state?.query);
  const { user: authUser } = useAuthUser();
  const [errorMessage, setErrorMessage] = useState("");
  // A queue for holding operations that need to be saved to answers
  const [saveQueue, setSaveQueue] = useState([]);

  const [smartFormConfig, setSmartFormConfig] = useState(null);
  const [showReminderDate, setShowReminderDate] = useState(false);
  const [pillConfig, setPillConfig] = useState();

  const dispatch = useDispatch();
  const manageQueryResponses = useSelector(state => state.manageQueryResponses);
  const { navigateToRequestPage } = useRequestPageNavigator();
  const navigate = useNavigate();
  const { projectId, queryId } = useParams();
  const { uiConfig } = useUIConfig();
  const { reloadProjectById, errorFetchingProject } = useGetProjectById();
  const isProjectFetched = useRef(false);
  const { currentProject } = useCurrentProject();
  const [currentProjectId, setCurrentProjectId] = useState();

  const { data: actionItemTypes } = useGetActionItemTypesQuery(
    {
      engagementTypeId: currentProject?.engagement.engagementTypeId,
      projectId: currentProject?.id
    },
    {
      skip: currentProject?.engagement.engagementTypeId === undefined
    }
  );
  const { members } = useGetProjectMembers(currentProject);
  const [getAnswers, { data: savedAnswers }] = useLazyGetAnswersQuery();
  const [saveAnswer, { data: updatedAnswers, error }] = useSaveAnswerMutation();
  const [sendToHost, { data: sendToHostSuccess, error: sendToHostError }] =
    useSendToHostMutation();
  const { showSendToHostSuccess, showSendToHostErrorToast } = useToasts();
  const [answers, setAnswers] = useState(undefined);

  const [modalOpen, setModalOpen] = useState(false);
  const [downloadError, setDownloadError] = useState();
  const [contentKey, setContentKey] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [isLoadingPageFirstTime, setIsLoadingPageFirstTime] = useState(true);
  const { hostObject } = useGetHostObject();
  const [isDisableSubmitAction, setIsDisableSubmitAction] = useState(false);

  const relevantEntitiesById = useMemo(() => {
    return populateRelevantEntitiesByIdToObject(currentProject, query);
  }, [currentProject, query]);

  const [isLockFinalisedBtn, setIsLockFinalisedBtn] = useState(false);
  const refreshAnswers = usePubSub();

  const isPendingFlagEnabled = smartFormConfig?.flags?.some(
    flag => flag.key === "PENDING"
  );

  useEffect(() => {
    if (!projectId && currentProject && !queryId && query) {
      navigate(`/projects/${currentProject.id}/smartforms/${query.id}`, {
        replace: true
      });
    }
  }, [navigate, currentProject, projectId, query, queryId]);

  useEffect(() => {
    refreshAnswers.subscribe(
      systemConstants.project.queries.events.smartform.refreshAnswers
    );
  }, [refreshAnswers]);

  useEffect(() => {
    if (refreshAnswers?.value?.queryId == queryId) {
      getAnswers({ queryId });
    }
  }, [refreshAnswers?.value, getAnswers, queryId]);

  useEffect(() => {
    getAnswers({ queryId });
  }, [queryId, getAnswers]);

  useEffect(() => {
    if (savedAnswers) {
      setAnswers(savedAnswers);
    }
  }, [savedAnswers]);

  useEffect(() => {
    if (updatedAnswers) {
      setAnswers(updatedAnswers);
    }
    if (error) {
      //display error message
    }
  }, [updatedAnswers, error]);

  useEffect(
    () => {
      if (sendToHostSuccess?.success) {
        setModalOpen(false);
        refetchQuery(queryId);
        setIsLockFinalisedBtn(false);
        showSendToHostSuccess();
      }
      if (sendToHostError) {
        setErrorMessage(
          sendToHostError?.data?.key
            ? t(sendToHostError.data.key)
            : sendToHostError?.data?.message
        );
        setIsLockFinalisedBtn(false);
        showSendToHostErrorToast({ host: hostObject.host.name });
      }
    },
    //remove refetchQuery from dependency, it will be run in a loop
    //eslint-disable-next-line
    [sendToHostSuccess, sendToHostError, queryId]
  );

  useEffect(() => {
    if (errorFetchingProject) {
      navigate("not-found", { replace: true });
    }
  }, [errorFetchingProject, navigate]);

  useEffect(() => {
    if (errorFetchingQuery) {
      navigate("not-found", { replace: true });
    }
  }, [errorFetchingQuery, navigate, projectId, currentProject]);

  useEffect(
    () => () => dispatch(manageDocumentDownloadsActions.reset()),
    [dispatch]
  );

  useEffect(() => {
    const currProjectId = projectId || location?.state?.project?.id;
    if (currProjectId) {
      setCurrentProjectId(currProjectId);
      isProjectFetched.current = false;
    }
  }, [projectId, location?.state?.project]);

  useEffect(() => {
    if (
      currentProjectId &&
      !errorFetchingProject &&
      (!currentProject || currentProject.id != currentProjectId) &&
      !isProjectFetched.current
    ) {
      reloadProjectById(currentProjectId);
      isProjectFetched.current = true;
    }
  }, [
    currentProjectId,
    currentProject,
    errorFetchingProject,
    reloadProjectById
  ]);

  useEffect(() => {
    return () => {
      dispatch(manageDocumentDownloadsActions.reset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (uiConfig?.pills) {
      setPillConfig(uiConfig.pills);
    }
  }, [uiConfig?.pills]);

  useEffect(() => {
    if (fetchedQuery && projectId) {
      if (fetchedQuery.projectId != projectId) {
        navigate(routeConstants.login);
      } else {
        setQuery(fetchedQuery);
        setIsLoadingPageFirstTime(false);
      }
    } else if (isLoadingPageFirstTime && queryId) {
      refetchQuery(queryId);
    }
  }, [
    fetchedQuery,
    isLoadingPageFirstTime,
    navigate,
    projectId,
    queryId,
    refetchQuery
  ]);

  useEffect(() => {
    if (query?.id) {
      dispatch(manageQueryResponsesActions.getQueryResponses(query.id));
    }
  }, [dispatch, query?.id, manageQueryResponses.isAdded]);

  useEffect(() => {
    if (updatedQuery && query?.id && updatedQuery.id === query.id) {
      setQuery({ ...updatedQuery });
      dispatch(manageProjectQueriesActions.getMyQueryCount(currentProject));
    }
  }, [updatedQuery, dispatch, currentProject, query?.id]);

  useEffect(() => {
    if (!query?.queryType) {
      return;
    }
    const actionItemTypesMatchingKey = actionItemTypes?.filter(
      actionItemType => actionItemType.configuration.key === query.queryType
    );

    const filteredActionItemTypes = actionItemTypesMatchingKey?.map(q => ({
      ...q.configuration,
      buttons: currentProject?.configuration?.buttons,
      queryName: q.name
    }));

    if (filteredActionItemTypes?.length > 0) {
      setSmartFormConfig(filteredActionItemTypes[0]);
    }
  }, [actionItemTypes, query?.queryType, currentProject]);

  useEffect(() => {
    if (!smartFormConfig?.type) {
      return;
    }
    // The actual config type isn't SmartForm redirect appropriately
    if (smartFormConfig.type !== smartFormActionItemType) {
      navigateToRequestPage(query?.id, query?.projectId, smartFormConfig.type, {
        replace: true
      });
    }
  }, [navigate, navigateToRequestPage, query, smartFormConfig]);

  useEffect(
    () => () => {
      dispatch(manageDocumentDownloadsActions.reset());
    },
    [dispatch]
  );

  useEffect(() => {
    setIsDisableSubmitAction(query?.status === statusTypes.closed);
  }, [query, authUser]);

  // this will get triggered whenever handleSaveAnswer is called
  useEffect(() => {
    if (saveQueue.length === 0) return;

    //get the next operation from the queue
    const nextOp = saveQueue[0];

    // try to save answer here
    saveAnswer({
      queryId: queryId,
      questionId: nextOp.questionId,
      answer: nextOp.answer
    })
      .unwrap()
      .then(() => {
        // if successful, remove the operation from the queue
        setSaveQueue(prevQueue => prevQueue.slice(1));
      })
      .catch(() => {
        // if failed, revert the state back to its previous value for that question,
        setAnswers(prevAnswers => ({
          ...prevAnswers,
          [nextOp.questionId]: nextOp.previousAnswer
        }));
        // remove the operation from the queue, and refetch the answers for that question
        setSaveQueue(prevQueue => prevQueue.slice(1));
        getAnswers({ queryId });
      });
  }, [saveQueue, queryId, saveAnswer, getAnswers]);

  const handleFileDownload = useCallback(
    ({ id: documentId, name: filename, documentRevisionId }) => {
      if (documentRevisionId) {
        dispatch(
          manageDocumentDownloadsActions.downloadDocumentRevision({
            id: documentId,
            name: filename,
            documentRevisionId
          })
        );
      } else if (documentId) {
        dispatch(
          manageDocumentDownloadsActions.downloadCurrentDocument({
            id: documentId,
            name: filename
          })
        );
      }
    },
    [dispatch]
  );

  const handleCloseModal = useCallback((setErrorToEmpty = false) => {
    setModalOpen(false);
    setContentKey("");
    if (setErrorToEmpty) {
      setErrorMessage("");
    }
  }, []);

  const handleModalUpdate = useCallback(() => {
    refetchQuery(query?.id);
    handleCloseModal();
  }, [refetchQuery, query?.id, handleCloseModal]);

  const onQueryReassigned = useCallback(() => {
    if (updatedQuery) {
      setQuery({ ...updatedQuery });
    }
    handleCloseModal();
  }, [handleCloseModal, updatedQuery]);

  const handleOpenModal = useCallback((contentKey, question) => {
    setModalOpen(true);
    setCurrentQuestion(question);
    setContentKey(contentKey);
  }, []);

  const isProjectMember = useCallback(
    (action, cb) => {
      if (
        authUser?.isHostUser &&
        !members.hostUsers?.some(member => member.id === authUser.id)
      ) {
        handleOpenModal("projectAccess");
        setErrorMessage(
          t("common:project.accessRequiredToDoAction", { action })
        );
      } else {
        cb();
      }
    },
    [authUser?.isHostUser, authUser.id, members.hostUsers, handleOpenModal, t]
  );

  const getProjectAccessModal = () => (
    <ProjectAccessModal
      visibility={true}
      handleClose={handleCloseModal}
      message={errorMessage}
    />
  );

  const getReassignModal = () => {
    if (
      authUser?.isHostUser &&
      !members.hostUsers?.some(member => member.id === authUser.id)
    ) {
      return getProjectAccessModal();
    } else {
      return (
        <Popup
          visibility={true}
          handleOutsideClick={handleCloseModal}
          width="50rem"
        >
          <ReassignQuery
            project={currentProject}
            query={query}
            onQueryReassigned={onQueryReassigned}
            onCancel={handleCloseModal}
          />
        </Popup>
      );
    }
  };

  const handleSubmitSmartForm = () => {
    sendToHost({ queryId });
    setIsLockFinalisedBtn(true);
  };

  const getFinaliseModal = () => {
    if (
      authUser?.isHostUser &&
      !members.hostUsers?.some(member => member.id === authUser.id)
    ) {
      return getProjectAccessModal();
    } else {
      return (
        <Popup
          visibility={true}
          handleOutsideClick={handleCloseModal}
          width="50rem"
        >
          <SendToHost
            host={hostObject.host}
            lockSubmit={isLockFinalisedBtn}
            handleSubmit={handleSubmitSmartForm}
            handleCancel={handleCloseModal}
            error={errorMessage}
          />
        </Popup>
      );
    }
  };

  const getEditTagsModal = () => (
    <Popup
      visibility={true}
      handleOutsideClick={handleCloseModal}
      width="50rem"
    >
      <EditActionTags
        project={currentProject}
        query={query}
        onUpdate={handleModalUpdate}
        onCancel={handleCloseModal}
      />
    </Popup>
  );

  const getCopiedToModal = () => (
    <Popup
      visibility={true}
      handleOutsideClick={handleCloseModal}
      width="50rem"
    >
      <UpdateCopiedTo
        project={currentProject}
        query={query}
        queryConfig={smartFormConfig}
        onUpdate={handleModalUpdate}
        onCancel={handleCloseModal}
      />
    </Popup>
  );

  const getEntityPerAnswerModal = () => (
    <SmartFormModal
      projectId={projectId}
      queryId={queryId}
      handleClose={handleCloseModal}
      message={errorMessage}
      question={currentQuestion}
      answer={smartFormStructure.getAnswerValuesForQuestion(
        currentQuestion.questionId,
        answers
      )}
      onAnswerSupplied={saveAnswer}
      relevantEntitiesById={relevantEntitiesById}
      disableAnswerSubmissions={query?.status === statusTypes.closed}
      handleFileDownload={handleFileDownload}
    />
  );
  const getCommentsModal = () => (
    <CommentsModal
      query={query}
      question={currentQuestion}
      onClose={handleCloseModal}
      handleFileDownload={handleFileDownload}
      smartFormConfig={smartFormConfig}
    />
  );

  const getModalContent = () => {
    switch (contentKey) {
      case contentKeys.REASSIGN:
        return getReassignModal();
      case contentKeys.FINALISE:
        return getFinaliseModal();
      case contentKeys.EDIT_TAGS:
        return getEditTagsModal();
      case contentKeys.COPIED_TO:
        return getCopiedToModal();
      case contentKeys.COMMENT:
        return getCommentsModal();
      case "projectAccess":
        return getProjectAccessModal();
      case "answerPerEntity":
      case "websheet":
        return getEntityPerAnswerModal();
      default:
        return;
    }
  };

  const closeRequest = () => {
    isProjectMember(
      t(
        "requests:requests.ui.requestDetailsPage.accessRequiredActionCloseRequest"
      ),
      () => {
        dispatch(manageDocumentDownloadsActions.resetError());
        updateQuery({ ...query, status: statusTypes.closed });
      }
    );
  };

  const reopenRequest = () => {
    dispatch(manageDocumentDownloadsActions.resetError());
    updateQuery({ ...query, status: statusTypes.open });
  };

  const handleSaveAnswers = useCallback(
    ({ questionId, answer }) => {
      isProjectMember(
        t(
          "requests:requests.ui.requestDetailsPage.accessRequiredAnswerSmartForm"
        ),
        () => {
          const previousAnswer = answers[questionId];
          // optimistically set the local state to the new answer & update UI
          setAnswers(prev => ({
            ...prev,
            [questionId]: [{ value: answer, updatedBy: smartFormUpdatedBy.OT }]
          }));

          // add new save operation to the queue
          setSaveQueue(prevQueue => [
            ...prevQueue,
            {
              questionId: questionId,
              answer: { value: answer, updatedBy: smartFormUpdatedBy.OT },
              previousAnswer: previousAnswer
            }
          ]);
        }
      );
    },
    [answers, isProjectMember, t]
  );

  useEffect(() => {
    const reminderDateField = smartFormConfig?.fields?.find(
      f => f.key === "reminderDate"
    );

    setShowReminderDate(
      reminderDateField &&
        !(
          reminderDateField.availableTo &&
          !authUser.isMemberOfUserGroup(reminderDateField.availableTo)
        )
    );
  }, [
    authUser,
    smartFormConfig,
    smartFormConfig?.fields,
    smartFormConfig?.questionList
  ]);

  const headerActions = () => {
    return (
      <div className="smart-form-page__actions">
        <QueryActions
          queryType={query.queryType}
          queryId={query.id}
          queryConfig={smartFormConfig}
          queryStatus={query?.status}
          disableSendTohost={isDisableSubmitAction}
          isUserRequestor={query.isUserOnRequestorTeam}
          requestHandlers={{
            [buttonActionConstants.reassign]: () =>
              handleOpenModal(contentKeys.REASSIGN),
            [buttonActionConstants.closeRequest]: closeRequest,
            [buttonActionConstants.reopen]: reopenRequest,
            [buttonActionConstants.download]: setDownloadError,
            [buttonActionConstants.sendToHost]: () =>
              handleOpenModal(contentKeys.FINALISE),
            [buttonActionConstants.closePage]: () => navigate(-1)
          }}
        ></QueryActions>
      </div>
    );
  };

  const smartFormPageContent = useMemo(
    () =>
      authUser.id && (
        <>
          {query?.status === statusTypes.closed && (
            <InfoMessageBox
              iconProps={{
                name: "check_circle",
                designStyle: "material-icons",
                fillStyle: "outlined"
              }}
              message={t(
                "requests:requests.ui.smartForm.errors.closedSoChangesCannotBeMade"
              )}
              color="smartformcompleted"
            />
          )}
          <SmartForm
            queryId={query?.id}
            isPendingFlagEnabled={isPendingFlagEnabled}
            isPending={query?.pending}
            isClosed={query?.status === statusTypes.closed}
            questions={smartFormConfig?.questionList}
            conditionals={smartFormConfig?.conditionals}
            answers={answers}
            relevantEntitiesById={relevantEntitiesById}
            refetchQuery={refetchQuery}
            handlers={{
              [smartFormActions.OPEN_COMMENT_MODAL]: question => {
                handleOpenModal(contentKeys.COMMENT, question);
              },
              [smartFormActions.OPEN_ENTITIES_MODAL]: question => {
                handleOpenModal("answerPerEntity", question);
              },
              [smartFormActions.FILL_WEBSHEET]: question => {
                handleOpenModal("websheet", question);
              },
              [smartFormActions.SAVE_ANSWERS]: handleSaveAnswers
            }}
            disableAnswerSubmissions={query?.status === statusTypes.closed}
          />
        </>
      ),
    [
      authUser.id,
      query?.status,
      query?.id,
      query?.pending,
      t,
      isPendingFlagEnabled,
      smartFormConfig?.questionList,
      smartFormConfig?.conditionals,
      answers,
      relevantEntitiesById,
      handleSaveAnswers,
      handleOpenModal,
      refetchQuery
    ]
  );

  const milestoneContent = () => {
    if (!currentProject?.configuration?.milestones?.labels?.enabled) {
      return "";
    }
    const projectMilestones = currentProject.milestones;
    return projectMilestones?.find(m => m.id === query?.milestone)?.name ?? "";
  };

  const smartFormRequestDetails = () => {
    return (
      <>
        <RequestDetailsBox
          requestQuery={query}
          queryConfig={smartFormConfig}
          showReminderDate={showReminderDate}
          hideRequiredByField={
            currentProject?.configuration?.requests?.hideRequiredByField ??
            false
          }
          canEditLabels={authUser?.isHostUser}
          onClickEditLabels={() => {
            handleOpenModal(contentKeys.EDIT_TAGS);
          }}
          onClickEditCopiedTo={() => {
            handleOpenModal(contentKeys.COPIED_TO);
          }}
          title={getActionItemTypeDisplayName(
            smartFormConfig.useCustomName,
            smartFormConfig.queryName,
            t("requests:requests.configured.shortName", {
              context: smartFormConfig.key
            })
          )}
          pillConfig={pillConfig}
          milestoneContent={milestoneContent()}
        />
        <RequestEntitiesBox
          title={t(`requests:requests.configured.fields.entities.label`, {
            context: query.queryType
          })}
          boxClassName="smart-form-page__entities"
          projectEntities={currentProject?.entities ?? []}
          entityIdsToDisplay={query?.entities ?? []}
          action={<Icon name="lan" />}
        />
      </>
    );
  };

  return smartFormConfig ? (
    <PageTemplate
      header={{
        title: getActionItemTypeDisplayName(
          smartFormConfig.useCustomName,
          smartFormConfig.queryName,
          t("requests:requests.configured.name", {
            context: smartFormConfig.key
          })
        ),
        actions: headerActions()
      }}
      body={{
        primary: smartFormPageContent,
        secondary: smartFormRequestDetails(),
        secondaryWidth: "20vw"
      }}
      other={{
        smallPageSize: 1800,
        error: downloadError?.message,
        project: currentProject
      }}
      modal={{
        open: modalOpen,
        content: getModalContent()
      }}
    />
  ) : (
    <></>
  );
};

export default SmartFormPage;
