import { useCallback, useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { loggedUser } from "@shared/helpers";
import { useUserProfile } from "@shared/hooks";

export function useAuthUser(props) {
  const authentication = useSelector(state => state.authentication);
  const getUserWithInfo = useCallback(() => {
    if (authentication?.user?.id) {
      return {
        ...authentication.user,
        name: `${authentication.user.firstname} ${authentication.user.lastname}`,
        isHostAdmin: loggedUser.isHostAdmin(authentication.user),
        isTeamMember: loggedUser.isTeamMember(authentication.user),
        isHostUser: loggedUser.doesBelongToHost(authentication.user),
        isClientAdmin: loggedUser.isClientAdmin(authentication.user),
        isClientUser: loggedUser.doesBelongToClient(authentication.user),
        isMemberOfUserGroup: userGroup =>
          loggedUser.doesBelongToUserGroup(userGroup, authentication.user),
        isLoggedIn:
          authentication.loggedIn && authentication.verified ? true : false
      };
    } else {
      return {
        isHostAdmin: false,
        isTeamMember: false,
        isHostUser: false,
        isClientAdmin: false,
        isClientUser: false,
        isMemberOfUserGroup: () => false,
        isLoggedIn: false
      };
    }
  }, [
    authentication?.loggedIn,
    authentication?.user,
    authentication?.verified
  ]);

  const { userProfile, fetchUserProfile } = useUserProfile();
  const [user, setUser] = useState(getUserWithInfo());
  const withProfile = props?.withProfile || false;

  useEffect(() => {
    if (authentication.user && authentication.user.id) {
      setUser(getUserWithInfo);
    }
  }, [
    authentication.user,
    authentication.loggedIn,
    authentication.verified,
    fetchUserProfile,
    userProfile.id,
    getUserWithInfo
  ]);

  useEffect(() => {
    if (user.isLoggedIn && userProfile.id !== user.id && withProfile) {
      fetchUserProfile({ id: user.id });
    }
  }, [fetchUserProfile, user.isLoggedIn, user.id, userProfile.id, withProfile]);

  return { user, userProfile };
}
