import { combineReducers } from "redux";

import { systemConstants } from "@shared/constants/systemConstants";
import actionItemReducer from "@shared/features/actionItem/actionItemSlice";
import {
  interactiveReportJsonEditorSliceReducer,
  interactiveReportSliceReducer
} from "@shared/features/interactiveReport/interactiveReportSlice";
import secondaryNavReducer from "@shared/features/secondaryNav/secondaryNavSlice";
import { actionItemService } from "@shared/services/actionItemService";
import { actionItemTypesService } from "@shared/services/actionItemTypesService";
import { cleaningTemplateService } from "@shared/services/cleaningTemplateService";
import { clientProjectsService } from "@shared/services/clientProjectsService";
import { clientService } from "@shared/services/clientService";
import { clientsMenuService } from "@shared/services/clientsMenuService";
import { documentService } from "@shared/services/documentService";
import { engagementTypeService } from "@shared/services/engagementTypeService";
import { engagementsService } from "@shared/services/engagementsService";
import { featuresService } from "@shared/services/featuresService";
import { footerService } from "@shared/services/footerService";
import { insightsService } from "@shared/services/insightsService";
import { interactiveReportService } from "@shared/services/interactiveReportService";
import { jsonEditorService } from "@shared/services/jsonEditorService";
import { newsService } from "@shared/services/newsService";
import { projectDashboardService } from "@shared/services/projectDashboardService";
import { projectMemberService } from "@shared/services/projectMemberService";
import { projectService } from "@shared/services/projectService";
import { riskService } from "@shared/services/riskService";
import { rolesService } from "@shared/services/roles";
import { smartformService } from "@shared/services/smartformService";
import { userService } from "@shared/services/userService";

import { alert } from "./alertReducer";
import { authentication } from "./authenticationReducer";
import { clientDocuments } from "./clientDocumentsReducer";
import { cms } from "./cmsReducer";
import { externalLinkValidation } from "./externalLinkValidationReducer";
import { finalPackage } from "./finalPackageReducer";
import { manageArticles } from "./manageArticlesReducer";
import { manageAudit } from "./manageAuditReducer";
import { manageClientEngagementProjects } from "./manageClientEngagementProjectsReducer";
import { manageClientEngagements } from "./manageClientEngagementsReducer";
import { manageClientEntities } from "./manageClientEntitiesReducer";
import { manageClientProjects } from "./manageClientProjectsReducer";
import { manageClientTags } from "./manageClientTagsReducer";
import { manageClientUsers } from "./manageClientUsersReducer";
import { manageClients } from "./manageClients";
import { manageDashboard } from "./manageDashboardReducer";
import { manageDocumentDownloads } from "./manageDocumentDownloadsReducer";
import { manageDocumentUploads } from "./manageDocumentUploadsReducer";
import { manageExcelDocument } from "./manageExcelDocumentReducer";
import { manageFinalPackageDocuments } from "./manageFinalPackageDocumentsReducer";
import { manageHostUsers } from "./manageHostUsersReducer";
import { manageInteractiveReport } from "./manageInteractiveReportReducer";
import { manageProfile } from "./manageProfileReducer";
import { manageProjectDocuments } from "./manageProjectDocumentsReducer";
import { manageProjectOCRs } from "./manageProjectOCRReducer";
import { manageProjectQueries } from "./manageProjectQueriesReducer";
import { manageProject } from "./manageProjectReducer";
import { manageQueryResponses } from "./manageQueryResponsesReducer";
import { manageTaskTemplates } from "./manageTaskTemplatesReducer";
import { search } from "./searchReducer";

const allReducers = combineReducers({
  authentication,
  alert,
  clientDocuments,
  search,
  manageClients,
  manageClientTags,
  manageClientUsers,
  manageHostUsers,
  manageClientEngagements,
  manageClientEngagementProjects,
  manageProject,
  manageProjectDocuments,
  manageDocumentDownloads,
  manageExcelDocument,
  manageDashboard,
  manageProjectQueries,
  manageQueryResponses,
  manageProfile,
  manageProjectOCRs,
  manageDocumentUploads,
  externalLinkValidation,
  manageTaskTemplates,
  manageClientEntities,
  manageAudit,
  manageArticles,
  manageClientProjects,
  cms,
  finalPackage,
  manageFinalPackageDocuments,
  manageInteractiveReport,
  interactiveReport: interactiveReportSliceReducer,
  interactiveReportJsonEditor: interactiveReportJsonEditorSliceReducer,
  actionItem: actionItemReducer,
  secondaryNavSlice: secondaryNavReducer,
  [interactiveReportService.reducerPath]: interactiveReportService.reducer,
  [footerService.reducerPath]: footerService.reducer,
  [actionItemService.reducerPath]: actionItemService.reducer,
  [actionItemTypesService.reducerPath]: actionItemTypesService.reducer,
  [smartformService.reducerPath]: smartformService.reducer,
  [featuresService.reducerPath]: featuresService.reducer,
  [projectService.reducerPath]: projectService.reducer,
  [documentService.reducerPath]: documentService.reducer,
  [engagementsService.reducerPath]: engagementsService.reducer,
  [insightsService.reducerPath]: insightsService.reducer,
  [projectDashboardService.reducerPath]: projectDashboardService.reducer,
  [projectMemberService.reducerPath]: projectMemberService.reducer,
  [engagementTypeService.reducerPath]: engagementTypeService.reducer,
  [cleaningTemplateService.reducerPath]: cleaningTemplateService.reducer,
  [clientsMenuService.reducerPath]: clientsMenuService.reducer,
  [clientProjectsService.reducerPath]: clientProjectsService.reducer,
  [clientService.reducerPath]: clientService.reducer,
  [newsService.reducerPath]: newsService.reducer,
  [userService.reducerPath]: userService.reducer,
  [rolesService.reducerPath]: rolesService.reducer,
  [jsonEditorService.reducerPath]: jsonEditorService.reducer,
  [riskService.reducerPath]: riskService.reducer
});

function rootReducer(state, action) {
  if (action.type === systemConstants.logout.resetApp) {
    state = { cms: state.cms };
  }
  return allReducers(state, action);
}

export default rootReducer;
