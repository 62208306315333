import React from "react";

import PropTypes from "prop-types";

import LogoAvatar from "@components/atoms/LogoAvatar";
import Text from "@components/atoms/Text";

import "./FinalPackageTipsTemplate.scss";

export const FinalPackageTipsTemplate = ({ description, logo, list }) => {
  return (
    <div className="final-package-tips-template">
      <span className="final-package-tips-template__logo">
        {logo && <LogoAvatar image={logo} shape={"circle"} />}
      </span>
      <span className="final-package-tips-template__content">
        <Text value={description || ""} fontType="light" />
        <span className="final-package-tips-list">{list}</span>
      </span>
    </div>
  );
};

FinalPackageTipsTemplate.propTypes = {
  description: PropTypes.string,
  logo: PropTypes.string,
  list: PropTypes.node
};

export default FinalPackageTipsTemplate;
