import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";
import prepareHeaders from "./rtkUtilities";

export const clientProjectsService = createApi({
  reducerPath: "clientProjectsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  tagTypes: ["ClientProjectForMenu"],
  endpoints: builder => ({
    getClientProjectsForMenu: builder.query({
      query: clientId => `/api/clients/${clientId}/projects?for=menu`,
      providesTags: ["ClientProjectForMenu"],
      transformResponse: response =>
        response.sort((r, l) =>
          r.name.localeCompare(l.name, "en", { sensitivity: "base" })
        )
    })
  })
});

export const {
  useLazyGetClientProjectsForMenuQuery,
  useGetClientProjectsForMenuQuery,
  util: clientProjectsUtil
} = clientProjectsService;
