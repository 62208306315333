import React, { useCallback } from "react";

import PropTypes from "prop-types";

import { systemConstants } from "@shared/constants/systemConstants";
import {
  useDeleteProjectDocument,
  useDocumentDownloads,
  useProjectDocumentRevisions
} from "@shared/hooks";

import Popup from "@shared-components/popup/Popup";

import { documentActions } from "@app/helpers/documents";

import DeleteDocument from "@components/organisms/DeleteDocument";
import ReviewRevisionHistory from "@components/organisms/DocumentRevisionHistory";
import EditDocumentTags from "@components/organisms/EditDocumentTags";
import UpdateProjectDocumentProperties from "@components/organisms/UpdateProjectDocumentPropertiesModal";
import UploadDocumentRevision from "@components/organisms/UploadDocumentRevision";

const ProjectDocumentsTableActions = props => {
  const {
    action: { action, document, projectFolder },
    onFinished
  } = props;
  const { deleteProjectDocument } = useDeleteProjectDocument();
  const {
    fetchDocumentRevisions,
    updateDocumentRevisionToActive,
    revisions,
    activeRevisionId,
    setActiveRevisionId
  } = useProjectDocumentRevisions(document);
  const { fetchProjectDocument, downloadedDocuments } = useDocumentDownloads();

  const handleActionFinished = useCallback(() => {
    onFinished();
  }, [onFinished]);

  const handleDeleteDocument = useCallback(() => {
    deleteProjectDocument(document);
    handleActionFinished();
  }, [deleteProjectDocument, handleActionFinished, document]);

  const isSmartFormType =
    document?.properties?.actionItemTypeKey ===
    systemConstants.actionItemTypes.smartForm;

  return (
    <>
      <Popup
        visibility={action === documentActions.editTags}
        handleOutsideClick={false}
        width="60rem"
      >
        <EditDocumentTags
          document={document}
          onUpdate={handleActionFinished}
          onCancel={handleActionFinished}
        />
      </Popup>

      <Popup
        visibility={action === documentActions.deleteDocument}
        handleOutsideClick={false}
        width="60rem"
      >
        <DeleteDocument
          document={document}
          handleDelete={handleDeleteDocument}
          onDelete={handleActionFinished}
          onCancel={handleActionFinished}
        />
      </Popup>

      <Popup
        visibility={action === documentActions.reviewRevisionHistory}
        handleOutsideClick={false}
        width="80rem"
      >
        <ReviewRevisionHistory
          document={document}
          revisionOf="projectDocument"
          onCancel={handleActionFinished}
          onSubmit={handleActionFinished}
          fetchDocumentRevisions={fetchDocumentRevisions}
          updateDocumentRevisionToActive={updateDocumentRevisionToActive}
          revisions={revisions}
          activeRevisionId={activeRevisionId}
          setActiveRevisionId={setActiveRevisionId}
          fetchDocument={fetchProjectDocument}
          canSelectActive={!isSmartFormType}
          downloadedDocuments={downloadedDocuments}
        />
      </Popup>

      <Popup
        visibility={action === documentActions.updateDocumentProperties}
        handleOutsideClick={false}
        width="60rem"
      >
        <UpdateProjectDocumentProperties
          document={document}
          onUpdated={handleActionFinished}
          onCancel={handleActionFinished}
        />
      </Popup>

      <Popup
        visibility={action === documentActions.uploadDocument}
        handleOutsideClick={false}
        width="60rem"
      >
        <UploadDocumentRevision
          document={document}
          projectFolder={projectFolder}
          onUpload={handleActionFinished}
          onCancel={handleActionFinished}
        />
      </Popup>
    </>
  );
};

ProjectDocumentsTableActions.defaultProps = {};

ProjectDocumentsTableActions.propTypes = {
  action: PropTypes.shape({
    action: PropTypes.string.isRequired,
    document: PropTypes.shape({
      id: PropTypes.number.isRequired
    }),
    projectFolder: PropTypes.shape({
      id: PropTypes.any
    })
  }),
  onFinished: PropTypes.func.isRequired
};

export default ProjectDocumentsTableActions;
