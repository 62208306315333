import React from "react";
import { useFormContext } from "react-hook-form";

const ConditionalField = (props) => {
  const { fieldName, valueComparer, children } = props;
  
  const { watch } = useFormContext();
  const value = watch(fieldName);

  return valueComparer(value) ? children : null;
};

export default ConditionalField;
