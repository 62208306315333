import React, { useCallback, useEffect, useState } from "react";

import { systemConstants } from "@shared/constants/systemConstants";
import {
  useAuthUser,
  useDeleteFinalPackageTip,
  useGetFinalPackageTips,
  useStaticAssets
} from "@shared/hooks";

import FinalPackageTipsList from "@components/molecules/FinalPackageTipsList/FinalPackageTipsList";
import ProjectAccessModal from "@components/molecules/ProjectAccessModal/ProjectAccessModal";
import EditFinalPackageTipModalForm from "@components/organisms/EditFinalPackageTipModalForm/EditFinalPackageTipModalForm";
import BoxTemplate from "@components/templates/BoxTemplate";
import FinalPackageTipsTemplate from "@components/templates/FinalPackageTipsTemplate";

const { serverURL } = systemConstants;

export const FinalPackageTips = ({ project, isProjectMember }) => {
  const { user } = useAuthUser();
  const { finalPackageTips, fetchFinalPackageTips } = useGetFinalPackageTips();
  const { deleteFinalPackageTip, finalPackageTipDeleted } =
    useDeleteFinalPackageTip();
  const [showProjectAccessModal, setShowProjectAccessModal] = useState(false);
  const [membershipErrorMessage, setMembershipErrorMessage] = useState("");
  const [content, setContent] = useState({});
  const [config, setConfig] = useState({});
  const [selectedEditItem, setSelectedEditItem] = useState(null);
  const [defaultSlotsCount, setDefaultSlotsCount] = useState(0);
  const [itemsList, setItemsList] = useState([]);
  const { getUrl } = useStaticAssets();

  const getItemsList = useCallback((count, currentItems, projectId) => {
    const list = [];
    for (let i = 1; i <= count; i++) {
      const existingItemIndex = currentItems.findIndex(
        item => item.priority === i
      );
      if (existingItemIndex !== -1) {
        list.push(currentItems[existingItemIndex]);
      } else {
        list.push({
          projectId,
          tip: "Coming soon...",
          priority: i,
          key: `newSlot${i}`
        });
      }
    }
    return list;
  }, []);

  useEffect(() => {
    if (project?.content?.finalPackage?.finalPackageTips) {
      setContent({ ...project?.content?.finalPackage?.finalPackageTips });
    }
  }, [project?.content?.finalPackage?.finalPackageTips]);

  useEffect(() => {
    if (project?.id) fetchFinalPackageTips(project?.id);
  }, [fetchFinalPackageTips, project?.id]);

  useEffect(() => {
    if (finalPackageTipDeleted && project?.id) {
      fetchFinalPackageTips(project?.id);
    }
  }, [fetchFinalPackageTips, finalPackageTipDeleted, project?.id]);

  useEffect(() => {
    if (project?.configuration?.finalPackage?.finalPackageTips) {
      setConfig({ ...project?.configuration?.finalPackage?.finalPackageTips });
    }
  }, [project?.configuration?.finalPackage?.finalPackageTips]);

  useEffect(() => {
    if (config?.availableSlotsCount) {
      setDefaultSlotsCount(config?.availableSlotsCount);
    }
  }, [config?.availableSlotsCount]);

  useEffect(() => {
    if (user?.isHostUser) {
      setItemsList(
        getItemsList(defaultSlotsCount, finalPackageTips, project?.id)
      );
    } else {
      finalPackageTips?.length > 0
        ? setItemsList(finalPackageTips.slice(0, defaultSlotsCount))
        : setItemsList([
            {
              projectId: project.id,
              tip: "Coming soon...",
              priority: 1,
              key: `newSlot1`
            }
          ]);
    }
  }, [
    defaultSlotsCount,
    finalPackageTips,
    getItemsList,
    project.id,
    user?.isHostUser
  ]);

  const checkMembership = useCallback(
    msg => cb => {
      isProjectMember(user, msg, cb, errMessage => {
        setMembershipErrorMessage(errMessage);
        setShowProjectAccessModal(true);
      });
    },
    [isProjectMember, user]
  );

  const onEditItem = useCallback(
    item => {
      checkMembership("edit this item")(() => {
        setSelectedEditItem(item);
      });
    },
    [checkMembership]
  );

  const onDeleteItem = useCallback(
    item => {
      checkMembership("delete this item")(() => {
        if (item.id) {
          deleteFinalPackageTip(project?.id, item);
        }
      });
    },
    [deleteFinalPackageTip, project?.id, checkMembership]
  );

  const handleCloseModal = () => {
    setShowProjectAccessModal(false);
    setMembershipErrorMessage("");
  };

  const handleEditClosed = useCallback(() => {
    setSelectedEditItem(null);
  }, []);

  const handleEditSumbit = useCallback(() => {
    setSelectedEditItem(null);
    fetchFinalPackageTips(project?.id);
  }, [fetchFinalPackageTips, project?.id]);

  return (
    <>
      <BoxTemplate
        boxClassName="final-package-tips"
        title={content?.title || ""}
        size="medium"
      >
        <FinalPackageTipsTemplate
          description={content?.description}
          logo={getUrl(content?.logoImage)}
          list={
            <FinalPackageTipsList
              items={itemsList}
              allowEdit={user?.isHostUser}
              onEditItem={onEditItem}
              onDeleteItem={onDeleteItem}
            />
          }
        />
      </BoxTemplate>
      <EditFinalPackageTipModalForm
        visibility={!!selectedEditItem}
        finalPackageTip={selectedEditItem}
        projectId={project?.id}
        handleClose={handleEditClosed}
        handleSubmit={handleEditSumbit}
      />
      <ProjectAccessModal
        visibility={showProjectAccessModal}
        message={membershipErrorMessage}
        handleClose={handleCloseModal}
        handleOutsideClick={handleCloseModal}
      />
    </>
  );
};

export default FinalPackageTips;
