import React, { useCallback, useEffect, useState } from "react";

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { systemConstants } from "@shared/constants";

import Button from "@components/atoms/Button";
import ModalTemplate from "@components/templates/ModalTemplate/ModalTemplate";

import "./imageEditor.scss";

const ImageEditor = props => {
  const initialCropObject = props.cropConfig || {
    x: 0,
    y: 0,
    aspect: 1,
    width: 100,
    height: 100
  };

  const [src, setSrc] = useState(props.image);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState(initialCropObject);
  const [enterKey, setEnterKey] = useState(false);
  const handleCancel = () => {
    props.onCancel();
  };

  const getCroppedImg = useCallback(() => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL("image/jpeg");
    return base64Image;
  }, [image, crop]);

  const handleSet = useCallback(() => {
    props.onSet(getCroppedImg());
  }, [getCroppedImg, props]);

  const handleImageChange = event => {
    if (event.target.files.length) {
      setCrop(initialCropObject);
      setSrc(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleCropChange = croppedObject => {
    if (
      croppedObject.width >= initialCropObject.width &&
      croppedObject.height >= initialCropObject.height
    ) {
      setCrop(croppedObject);
    }
  };

  const getSupportedMimes = () => {
    if (props.supportedMimes) {
      const supportedMimeList = props.supportedMimes.map(
        mime => `image/${mime}`
      );
      return supportedMimeList.join();
    } else {
      const supportedMimeList = systemConstants.mimes.image.map(
        mime => `image/${mime}`
      );
      return supportedMimeList.join();
    }
  };

  const handleKeyPress = event => {
    if (event.key == "Enter") {
      setEnterKey(true);
    }
  };

  useEffect(() => {
    if (enterKey) {
      handleSet();
      setEnterKey(false);
    }
  }, [enterKey, handleSet]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ModalTemplate
        boxClassName="image-editor"
        title={props.title || "Set Profile image"}
        onClose={handleCancel}
        content={
          <>
            <div className="image-editor__holder">
              <div className="image-editor__holder-image">
                <ReactCrop
                  src={src}
                  onImageLoaded={setImage}
                  crop={crop}
                  onChange={croppedObj => handleCropChange(croppedObj)}
                />
              </div>

              <div className="image-editor__holder-controls">
                <div className="image-editor__holder-image-input">
                  <input
                    type="file"
                    accept={getSupportedMimes()}
                    onChange={handleImageChange}
                    onKeyPress={e => {
                      if (e.key === "Enter") e.preventDefault();
                    }}
                  ></input>
                </div>
              </div>
            </div>
          </>
        }
        footer={
          <>
            <Button type="secondary" label="Cancel" onClick={handleCancel} />
            <Button
              type={"primary"}
              disabled={image ? false : true}
              label="Set"
              onClick={handleSet}
            />
          </>
        }
      ></ModalTemplate>
    </>
  );
};

export default ImageEditor;
