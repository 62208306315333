import React, { useEffect, useState } from "react";

import { useAuthUser } from "@shared/hooks";
import { milestoneCommentService } from "@shared/services/milestoneCommentService";

import MilestoneCommentTemplate from "@components/templates/MilestoneCommentTemplate";

const MilestoneComment = props => {
  const mode = {
    edit: "EDIT",
    view: "VIEW",
    new: "NEW"
  };
  const [comment, setComment] = useState({ comment: "" });
  const [viewMode, setViewMode] = useState(mode.view);
  const [hasError, setHasError] = useState(false);
  const { user } = useAuthUser();

  useEffect(() => {
    if (props.milestone && props.milestone.id) {
      milestoneCommentService
        .getMilestoneComment(props.milestone.id)
        .then(response => {
          if (response) {
            setComment(response);
          }
          if (!response || !response.comment) {
            setViewMode(mode.new);
          } else {
            setViewMode(mode.view);
          }
        });
    }
    // eslint-disable-next-line
  }, [props.milestone]);

  const updateComment = () => {
    setViewMode(mode.edit);
  };

  const saveComment = () => {
    milestoneCommentService
      .saveMilestoneComment(props.milestone.id, comment.comment)
      .then(
        result => {
          if (!comment.comment) {
            setViewMode(mode.new);
          } else {
            setViewMode(mode.view);
          }
        },
        error => {
          setHasError(true);
        }
      );
  };

  const handleChange = event => {
    setComment({
      ...comment,
      comment: event.target.value
    });
  };

  return (
    <MilestoneCommentTemplate
      viewMode={viewMode}
      mode={mode}
      comment={comment}
      updateComment={updateComment}
      saveComment={saveComment}
      handleChange={handleChange}
      onClose={props.onClose}
      hasError={hasError}
      isEditable={user.isHostUser}
    />
  );
};

export default MilestoneComment;
