import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants/systemConstants";
import dateFormatter from "@shared/helpers/dateHelper";
import { utilities } from "@shared/helpers/utilities";
import {
  useLazyGetMenusQuery,
  useLocaleDate,
  useWindowSize
} from "@shared/hooks";

import {
  QUERY_TYPE_ICON,
  formatQueryType,
  getActionIndicator,
  getQueryActions,
  isNote
} from "@app/helpers";
import { populateRelevantEntitiesByIdToObject } from "@app/helpers/entity";
import { menuItemAccess } from "@app/helpers/menuItemAccess";
import { getColumnWidth } from "@app/helpers/table";
import { useDataTable } from "@app/hooks";

import BarIndicator from "@components/atoms/BarIndicator";
import DataTableDropdownSelect from "@components/atoms/DataTableDropdownSelect";
import HoverText from "@components/atoms/HoverText";
import Icon from "@components/atoms/Icon";
import PillIndicator from "@components/atoms/PillIndicator";
import ShapeIndicator from "@components/atoms/ShapeIndicator";
import Text from "@components/atoms/Text/Text";
import DataTable from "@components/molecules/DataTable";
import EntitiesListIconCell from "@components/molecules/EntitiesListIconCell";

import "./ActionItemsTable.scss";

const ActionItemsTable = props => {
  const {
    queries,
    queryTypes,
    actionItemTypes,
    filterLabel,
    filterQueryType,
    filterUsers,
    filterCreatedBy,
    filter,
    sortedColumn,
    onClickQuery,
    onClickEdit,
    onClickClose,
    onClickHistory,
    onClickReopen,
    user,
    onAssignToFilterValueChanged,
    onLabelFiltersChanged,
    onQueryTypeFilterChanged,
    onCreatedByFilterChanged,
    onSortedColumnChanged,
    enableIndicatorIcon,
    enableIndicatorPill,
    indicatorUiConfig,
    project,
    hideRequiredByField,
    t
  } = props;

  const {
    findItem,
    createColumn,
    createColumnForFilter,
    createColumnForMultiselectFilter,
    caseInsensitiveSortType,
    caseInsensitiveEmptyLastSortType,
    createColumnForDropdownMenu
  } = useDataTable(queries);

  const {
    locale,
    options: { shortFormat }
  } = useLocaleDate();
  const tableRef = useRef();
  const filterUsersRef = useRef();
  const [getInteractiveReportMenus, interactiveReportMenusData] =
    useLazyGetMenusQuery();
  const { width } = useWindowSize();
  const isSmallScreen = width < 1500;
  const [disableFlagCol, setDisableFlagCol] = useState(false);

  useEffect(() => {
    if (project) {
      getInteractiveReportMenus({ projectId: project.id });
    }
  }, [project, getInteractiveReportMenus]);

  useEffect(() => {
    if (sortedColumn) {
      tableRef.current?.setSortBy(sortedColumn);
    }
  }, [sortedColumn, queries]);

  useEffect(() => {
    const hasFlag = actionItemTypes?.findIndex(
      a => a?.configuration?.flags?.length
    );
    setDisableFlagCol(hasFlag < 0);
  }, [actionItemTypes]);

  // Special filter to handle lists of [Label]
  const LabelColumnFilterApply = (rows, id, tagIdToFilter) => {
    return rows.filter(row => {
      const tagsInRow = row.values[id];
      return tagsInRow.map(t => t.id).includes(+tagIdToFilter);
    });
  };

  const dropdownMenuActionHandler = useCallback(
    ({ menuItem: { action }, cell }) => {
      const actionItemId = cell.row.original.id;
      const item = findItem(actionItemId);
      const actionMap = {
        VIEW: onClickQuery,
        EDIT: onClickEdit,
        CLOSE: onClickClose,
        HISTORY: onClickHistory,
        REOPEN: onClickReopen
      };
      actionMap[action]?.(item);
    },
    [
      findItem,
      onClickQuery,
      onClickEdit,
      onClickReopen,
      onClickClose,
      onClickHistory
    ]
  );

  const userFilterChangeHandler = useCallback(
    users => {
      if (tableRef.current) {
        const newVal = users.map(u => u.value);
        tableRef.current.setFilter("assignedTo", newVal);
        onAssignToFilterValueChanged(users);
      }
    },
    [onAssignToFilterValueChanged]
  );

  // This is to prevent the table header rerender after selecting created by filter
  const createdByFilterRef = useRef();
  useEffect(() => {
    return () => {
      onCreatedByFilterChanged(createdByFilterRef.current);
    };
  }, [onCreatedByFilterChanged]);

  const createdByFilterChangeHandler = useCallback(creators => {
    if (tableRef.current) {
      const newVal = creators.map(u => u.value);
      tableRef.current.setFilter("createdBy", newVal);
      createdByFilterRef.current = creators;
    }
  }, []);

  const labelFilterChangeHandler = useCallback(
    e => {
      if (tableRef.current) {
        tableRef.current.setFilter("tags", e);
        onLabelFiltersChanged(e);
      }
    },
    [onLabelFiltersChanged]
  );

  const tagForLabelDropdownFilter = (preFilteredRows, id) => {
    const tagIdMap = {};
    preFilteredRows.forEach(row => {
      const tags = row.values[id];
      tags.forEach(tag => {
        tagIdMap[tag.id] = tag;
      });
    });
    return Object.values(tagIdMap);
  };

  const queryTypeFilterChangeHandler = useCallback(
    e => {
      if (tableRef.current) {
        tableRef.current.setFilter("queryType", e);
        onQueryTypeFilterChanged(e);
      }
    },
    [onQueryTypeFilterChanged]
  );

  const SelectLabelColumnFilter = useCallback(
    onChangeHandler =>
      ({ column: { filterValue, preFilteredRows, id } }) => {
        const options = tagForLabelDropdownFilter(preFilteredRows, id);
        return (
          <DataTableDropdownSelect
            label={`${t("requests:requests.configured.fields.tags.label")}`}
            items={[
              { label: t("common:allOption"), name: "All", value: undefined },
              ...options.map(o => ({ name: o.name, value: o.id }))
            ]}
            onChange={onChangeHandler}
            value={filterValue}
          />
        );
      },
    [t]
  );

  const handleSortChange = useCallback(
    val => {
      onSortedColumnChanged(val);
    },
    [onSortedColumnChanged]
  );

  const getQueryTypeColumn = useCallback(() => {
    if (!isSmallScreen) {
      return {
        accessor: "queryType",
        className: "query-type",
        filterLabel: `${t("requests:requests.typeLabel")}`,
        allOptionLabel: t("common:allOption"),
        width: 140,
        onChangeHandler: queryTypeFilterChangeHandler,
        Cell: ({ cell }) => (
          <>
            <span className="data-table__data-query-type__text">
              {cell.value}
            </span>
            {cell.row.original.queryTypeIcon && (
              <Icon
                className="data-table__data-query-type__icon"
                name={cell.row.original.queryTypeIcon}
                hoverElement={<>{cell.value}</>}
              />
            )}
          </>
        )
      };
    } else {
      return {
        Header: t("requests:requests.typeLabel"),
        accessor: "queryType",
        className: "query-type",
        width: 60,
        disableFilters: true,
        Cell: ({ cell }) =>
          cell.row.original.queryTypeIcon && (
            <Icon
              className="data-table__data-query-type__icon"
              name={cell.row.original.queryTypeIcon}
              hoverElement={<>{cell.value}</>}
            />
          )
      };
    }
  }, [isSmallScreen, queryTypeFilterChangeHandler, t]);

  const actionsMenuForItem = useCallback(
    item => {
      if (!actionItemTypes) {
        return [];
      }
      const actions = getQueryActions(item.queryType, actionItemTypes);
      const labelForAction = actionKey =>
        t(`requests:requests.configured.menu.actions.${actionKey}.label`, {
          context: item.queryType
        });
      let actionItems = [];
      actions?.forEach(({ action, availableTo, name }) => {
        switch (action) {
          case "VIEW":
            menuItemAccess(availableTo, user, item.isUserOnRequestorTeam) &&
              actionItems.push({ name: labelForAction(action), action });
            break;
          case "EDIT":
            if (item.status !== systemConstants.project.queries.status.closed) {
              menuItemAccess(availableTo, user, item.isUserOnRequestorTeam) &&
                actionItems.push({ name: labelForAction(action), action });
            }
            break;
          case "HISTORY":
          case "REOPEN":
            if (item.status == systemConstants.project.queries.status.closed) {
              menuItemAccess(availableTo, user, item.isUserOnRequestorTeam) &&
                actionItems.push({ name: labelForAction(action), action });
            }
            break;
          case "CLOSE":
            if (
              item.status === systemConstants.project.queries.status.open ||
              item.status === systemConstants.project.queries.status.responded
            ) {
              menuItemAccess(availableTo, user, item.isUserOnRequestorTeam) &&
                actionItems.push({ name: labelForAction(action), action });
            }
            break;
        }
      });
      return actionItems;
    },
    [actionItemTypes, t, user]
  );

  const data = useMemo(() => {
    const formatIndicator = item => {
      const referenceDate = new Date();
      return getActionIndicator(item, referenceDate, indicatorUiConfig);
    };
    const renderFlag = flag => {
      switch (flag) {
        case "REJECTED":
          return (
            <Icon className="flag flag-REJECTED" name={"do_disturb_alt"} />
          );
        case "APPROVED":
          return (
            <Icon
              className="flag flag-APPROVED"
              name={"check_circle_outline"}
            />
          );
      }
    };

    const result = queries.map(item => {
      const label = Object.keys(indicatorUiConfig).find(
        keyName => indicatorUiConfig[keyName].key === item.status.toLowerCase()
      );
      const formattedIndicator = enableIndicatorPill
        ? { ...indicatorUiConfig[label], status: label }
        : formatIndicator(item);
      return {
        indicator: formattedIndicator,
        id: item.id,
        createdAt: item.createdAt,
        requiredBy: item.requiredBy,
        description: item.description,
        entities: populateRelevantEntitiesByIdToObject(project, item),
        tags: utilities.sortBy("name")(structuredClone(item.tags)),
        assignedTo: item.assignedTo?.name ?? "",
        createdBy: `${item.requestedBy?.firstname} ${item.requestedBy?.lastname}`,
        queryTypeIcon:
          QUERY_TYPE_ICON[
            queryTypes?.find(type => type.key === item.queryType)?.type
          ],
        queryType: formatQueryType(item.queryType, queryTypes, t),
        flag: item.flag ? renderFlag(item.flag) : "",
        actionsMenu: actionsMenuForItem(item),
        refNo: item.refNo,
        source: item.source,
        status: item.status
      };
    });
    let filteredResult = result;
    if (filter.dueDate) {
      filteredResult = result.filter(item => {
        return item.indicator.key == filter.dueDate && !isNote(item.status);
      });
    }
    if (filter.status) {
      filteredResult = filteredResult.filter(
        item => item.indicator.status == filter.status
      );
    }
    return filteredResult;
  }, [
    queries,
    filter.dueDate,
    filter.status,
    indicatorUiConfig,
    enableIndicatorPill,
    project,
    queryTypes,
    t,
    actionsMenuForItem
  ]);

  useEffect(() => {
    tableRef.current?.setFilter(
      "assignedTo",
      filterUsers?.map(filterUser => filterUser.name)
    );
    filterUsersRef.current = filterUsers;
  }, [filterUsers, data]);

  useEffect(() => {
    tableRef.current?.setFilter(
      "createdBy",
      filterCreatedBy?.map(creator => creator.name)
    );
  }, [filterCreatedBy, data]);

  useEffect(() => {
    tableRef.current?.setFilter("tags", filterLabel);
  }, [filterLabel, queries, data]);

  useEffect(() => {
    tableRef.current?.setFilter("queryType", filterQueryType);
  }, [filterQueryType, queries, data]);

  const columns = useMemo(() => {
    const formatDate = date => dateFormatter(date, locale, shortFormat) ?? "";

    const renderSourceData = (data, rowId) => {
      if (!data) {
        return <></>;
      }
      return (
        <div className="action-items-table__source-row">
          <div className="action-items-table__source-row--top">
            <Text
              value={data?.path?.[data?.path?.length - 1]}
              fontSize="3"
              fontType="bold"
            />
            <HoverText
              name={`${data?.pageName} | ${data?.path?.join(" | ")}`}
              position={"top"}
              isActive={true}
              maxWidth={900}
              classNameTarget="action-items-table__source-row--hover"
            />
          </div>
          <div>{`Report v${data.revision} - ${dateFormatter(
            data.uploadedDate,
            locale,
            shortFormat
          )}`}</div>
        </div>
      );
    };

    let indicatorClassName = "indicator--bar";
    let indicatorWidth = 20;
    if (enableIndicatorPill) {
      indicatorClassName = "indicator--pill";
      indicatorWidth = getColumnWidth({
        rows: data,
        accessor: "indicator",
        headerText: "",
        spacing: 7
      });
    } else if (enableIndicatorIcon) {
      indicatorClassName = "indicator--icon";
      indicatorWidth = 35;
    }
    return [
      {
        Header: "",
        accessor: "indicator",
        className: indicatorClassName,
        disableFilters: true,
        width: indicatorWidth,
        fixedWidth: true,
        Cell: ({ cell }) => {
          let indicator = null;
          const { shape, key, colorScheme } = cell.value;
          if (enableIndicatorIcon) {
            indicator = <ShapeIndicator shape={shape} color={key} />;
          } else {
            indicator = enableIndicatorPill ? (
              <PillIndicator
                label={t(
                  `requests:requests.configured.status.${key.toUpperCase()}.label`
                )}
                shape={shape}
                color={key}
              />
            ) : (
              <BarIndicator colorScheme={colorScheme} />
            );
          }
          return <div className="indicator-container">{indicator}</div>;
        }
      },
      createColumn({
        Header: t("requests:requests.refLabel"),
        accessor: "refNo",
        className: "ref",
        maxWidth: 50,
        fixedWidth: true,
        disableSortBy: false,
        handleSortChange
      }),
      createColumn({
        Header: t("requests:requests.dateAddedLabel"),
        accessor: "createdAt",
        className: "date-added",
        width: 80,
        disableSortBy: false,
        Cell: ({ cell }) => formatDate(cell.value),
        handleSortChange
      }),
      hideRequiredByField
        ? null
        : createColumn({
            Header: t("requests:requests.dueDateLabel"),
            accessor: "requiredBy",
            className: "date-due",
            width: 90,
            disableSortBy: false,
            Cell: ({ cell }) => formatDate(cell.value),
            sortType: caseInsensitiveEmptyLastSortType,
            handleSortChange
          }),
      createColumn({
        Header: t("requests:requests.sourceLabel"),
        accessor: "source",
        className:
          interactiveReportMenusData?.data?.length > 0 ? "source" : "hidden",
        width: 140,
        disableSortBy: true,
        Cell: ({ cell }) => renderSourceData(cell.value, cell.row.id)
      }),
      createColumn({
        Header: t("requests:requests.descriptionLabel"),
        accessor: "description",
        minWidth: hideRequiredByField ? 260 : 140,
        disableSortBy: false,
        sortType: caseInsensitiveSortType,
        handleSortChange
      }),
      {
        Header: "",
        accessor: "tags",
        className: "tags",
        width: 130,
        disableFilters: false,
        isHidden: isSmallScreen,
        Filter: SelectLabelColumnFilter(labelFilterChangeHandler),
        filter: LabelColumnFilterApply,
        Cell: ({ cell }) => {
          const tags = cell.value;
          return tags.map(t => t.name).join(", ");
        }
      },
      createColumnForMultiselectFilter({
        accessor: "assignedTo",
        className: "assigned-to",
        filterLabel: `${t(
          "requests:requests.configured.fields.assignedTo.pastTenseLabel"
        )}`,
        allOptionLabel: t("common:allOption"),
        filterExtras: filterUsersRef.current
          ? filterUsersRef.current.map(filterUser => filterUser.name)
          : [],
        onChangeHandler: userFilterChangeHandler,
        width: 140
      }),
      createColumnForMultiselectFilter({
        accessor: "createdBy",
        className: "created-by",
        filterLabel: `${t(
          "requests:requests.configured.fields.createdBy.label"
        )}`,
        allOptionLabel: t("common:allOption"),
        filterExtras: filterCreatedBy
          ? filterCreatedBy.map(creator => creator.name)
          : [],
        onChangeHandler: createdByFilterChangeHandler,
        width: 140
      }),
      createColumnForFilter(getQueryTypeColumn()),
      createColumn({
        accessor: "flag",
        maxWidth: 40,
        fixedWidth: true,
        isHidden: disableFlagCol,
        disableSortBy: true
      }),
      createColumn({
        accessor: "entities",
        width: 20,
        fixedWidth: true,
        Cell: ({ cell }) => {
          const relevantEntities = Object.values(cell.value || {});
          return (
            project?.entities?.length > 1 && (
              <EntitiesListIconCell entities={relevantEntities} />
            )
          );
        }
      }),
      createColumnForDropdownMenu({
        accessor: "actionsMenu",
        onClickHandler: dropdownMenuActionHandler,
        className: "visible-on-hover",
        fixedWidth: true,
        width: 45,
        isHidden: false
      })
    ].filter(c => c);
  }, [
    enableIndicatorPill,
    enableIndicatorIcon,
    createColumn,
    t,
    handleSortChange,
    hideRequiredByField,
    caseInsensitiveEmptyLastSortType,
    interactiveReportMenusData?.data?.length,
    caseInsensitiveSortType,
    isSmallScreen,
    SelectLabelColumnFilter,
    labelFilterChangeHandler,
    createColumnForMultiselectFilter,
    userFilterChangeHandler,
    filterCreatedBy,
    createdByFilterChangeHandler,
    createColumnForFilter,
    getQueryTypeColumn,
    disableFlagCol,
    createColumnForDropdownMenu,
    dropdownMenuActionHandler,
    locale,
    shortFormat,
    data,
    project?.entities?.length
  ]);

  const handleRowClick = useCallback(
    queryId => {
      const query = findItem(queryId);
      if (!query) {
        return;
      }

      if (onClickQuery) {
        return onClickQuery(query);
      }
    },
    [findItem, onClickQuery]
  );

  return actionItemTypes ? (
    <>
      <DataTable
        ref={tableRef}
        className="action-items-table"
        columns={columns}
        data={data}
        onRowClick={handleRowClick}
      ></DataTable>
    </>
  ) : (
    ""
  );
};

ActionItemsTable.defaultProps = {
  filter: {
    status: null
  }
};

ActionItemsTable.propTypes = {
  queries: PropTypes.array,
  onQueryClick: PropTypes.func,
  filter: PropTypes.shape({
    status: PropTypes.string
  }),
  filterUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  onAssignToFilterValueChanged: PropTypes.func
};

export default withTranslation()(ActionItemsTable);
