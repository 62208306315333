import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageClientsActions } from "@shared/actions";

export function useGetClientsWithEngagementsAndProjects() {
  const dispatch = useDispatch();
  const [
    clientsWithEngagementsAndProjects,
    setClientsWithEngagementsAndProjects
  ] = useState([]);

  const manageClients = useSelector(state => state.manageClients);

  useEffect(
    () => () => {
      dispatch(manageClientsActions.resetClients());
    },
    [dispatch]
  );

  useEffect(() => {
    const clients = manageClients.clientsWithEngagementsAndProjects;
    setClientsWithEngagementsAndProjects(structuredClone(clients));
  }, [manageClients]);

  const fetchAllClientsWithEngagementsAndProjects = useCallback(
    ({ user, updateUser, includeAllStatuses }) => {
      if (user.hostId) {
        dispatch(
          manageClientsActions.getAllClientsWithEngagementsAndProjects({
            hostId: user.hostId,
            userId: updateUser ? updateUser.id : null,
            includeAllStatuses
          })
        );
      }
    },
    [dispatch]
  );

  return {
    clientsWithEngagementsAndProjects,
    fetchAllClientsWithEngagementsAndProjects
  };
}
