import React, { useEffect, useState } from "react";

import { get } from "lodash";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

import RadioButton from "@components/atoms/RadioButton/RadioButton";

import "./RadioGroup.scss";

const RadioGroup = props => {
  const {
    formState: { errors },
    control,
    getValues,
    setValue
  } = useFormContext();

  const [error, setError] = useState();

  useEffect(() => {
    const currentValue = getValues(props.name);
    if (!currentValue || (currentValue.length === 0 && props.defaultValue)) {
      setValue(props.name, props.defaultValue);
    }
  }, [getValues, props.defaultValue, props.name, setValue]);

  useEffect(() => {
    const error = get(errors, props.name?.split("."))?.message ?? "";
    setError(error);
  }, [errors, props.name]);

  const getErrorMessage = () => {
    if (error?.length > 0) {
      return (
        <div className="radio-group__errmsg">
          <i className="radio-group__errmsg-icon material-icons">error</i>
          <div>{error}</div>
        </div>
      );
    }
  };

  const getRequiredClass = () => (props.required ? "asterisk" : "");

  const getErrorClass = () => (error?.length > 0 ? "error" : "");

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { onChange, onBlur, ref } }) => {
        return (
          <div>
            <div className="radio-group">
              <label className={`radio-group__label ${getRequiredClass()}`}>
                {props.label}
              </label>
              <div className={`radio-group__buttons ${getErrorClass()}`}>
                {props.items.map((item, key) => (
                  <React.Fragment key={key}>
                    <RadioButton
                      disabled={props.disabled}
                      ref={ref}
                      value={item.value}
                      checked={getValues(props.name) === item.value}
                      name={props.name}
                      required={props.required}
                      onChange={onChange}
                      onBlur={onBlur}
                      label={item.label}
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>
            {getErrorMessage()}
          </div>
        );
      }}
    />
  );
};

RadioGroup.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string
    })
  ),
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool
};

export default RadioGroup;
