import React, { useEffect, useRef, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import useOnClickOutside from "use-onclickoutside";

import "./DropdownNav.scss";

const ItemTypes = {
  text: "text",
  link: "link"
};

const DropdownNav = props => {
  const [isMenuActive, setMenuActive] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (props.isNeverActive) {
      return;
    }
    const activeItem = props.menuItems.find(item => {
      return location.pathname === item.path;
    });
    setMenuActive(!!activeItem);
  }, [location, props.menuItems, props.isNeverActive]);
  const [hideMenu, setHideMenu] = useState(true);
  const ref = useRef();
  const handleMenuItemClick = event => {
    setHideMenu(!hideMenu);
    if (props.type === ItemTypes.text) {
      props.handleMenuItemClick(event.target.textContent, props.data);
    }
  };

  const handleClick = () => {
    setHideMenu(!hideMenu);
  };

  useOnClickOutside(ref, () => {
    setHideMenu(true);
  });

  return (
    <div ref={ref} className="dropdown-nav" onClick={handleClick}>
      <a
        className={`dropdown-nav__btn
          ${isMenuActive || props.forceSetActive ? "active" : ""}
          `}
        data-testid="dropdown-nav-label"
      >
        {props.label}
        <i className="material-icons dropdown-nav__icon">keyboard_arrow_down</i>
      </a>
      <div
        className={`dropdown-nav__content  ${
          hideMenu ? "" : " dropdown-nav__content--show"
        }`}
        data-testid="dropdown-nav-content"
      >
        {props.type === ItemTypes.text
          ? props.menuItems.map(item => (
              <span key={item.name} onClick={handleMenuItemClick}>
                {item.name}
              </span>
            ))
          : props.menuItems.map(item => {
              if (props.handleNavigate) {
                return (
                  <button
                    key={item.name}
                    onClick={() => {
                      props.handleNavigate(item.path, { state: props.state });
                    }}
                    data-testid="testNavItem"
                    className={"dropdown-nav__content__link"}
                  >
                    <span title={item.name}>{item.name}</span>
                  </button>
                );
              } else {
                // Keeping Link to handle pages with using old page templates
                return (
                  <Link
                    onClick={handleMenuItemClick}
                    key={item.name}
                    className="dropdown-nav__content__link"
                    to={item.path}
                  >
                    <span title={item.name}>{item.name}</span>
                  </Link>
                );
              }
            })}
      </div>
    </div>
  );
};

export default DropdownNav;
