import { t } from "i18next";

import { routeConstants } from "@app/constants";

export const hostAdminNavs = ({ i18nText }: { i18nText: typeof t }) => {
  return {
    manageClients: {
      name: i18nText("admin:ui.navigation.manageClients.label"),
      path: routeConstants.manageClients,
      icon: "account_box"
    },
    manageUsers: {
      name: i18nText("admin:ui.navigation.manageUsers.label"),
      path: routeConstants.manageUsers,
      icon: "supervised_user_circle"
    },
    manageClientsAndUsers: {
      name: i18nText("admin:ui.navigation.manageClientsAndUsers.label"),
      path: routeConstants.manageUsers,
      icon: "account_box"
    },
    auditReport: {
      name: i18nText("common:ui.sideNavigation.admin.auditReport"),
      path: routeConstants.manageAudits,
      icon: "report"
    },
    globalTags: {
      name: i18nText("common:ui.sideNavigation.admin.globalTags"),
      path: routeConstants.globalTags,
      icon: "sell"
    },
    dataExtraction: {
      name: i18nText("common:ui.sideNavigation.admin.dataExtraction"),
      path: routeConstants.dataExtraction,
      icon: "summarize"
    },
    manageNews: {
      name: i18nText("common:ui.sideNavigation.admin.manageNews"),
      path: routeConstants.manageNews,
      icon: "newspaper"
    }
  };
};

export const clientAdminNavs = ({
  clientId,
  i18nText
}: {
  clientId: string | number;
  i18nText: typeof t;
}) => {
  return {
    manageClientUsers: {
      name: i18nText("admin:ui.navigation.manageClientUsers.label"),
      path: `/admin/clients/${clientId}/users`,
      icon: "supervised_user_circle",
      onClick: undefined
    }
  };
};
