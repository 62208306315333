import * as CSV from "@vanillaes/csv";

/**
 * Convert an array of entity objects to an array of externalId
 * @param {Array<Entity>} entities
 * @returns {number[]} array of externalId
 */
export const convertEntitiesToIds = entities => {
  return entities?.map(({ externalId }) => externalId) ?? [];
};

export const populateRelevantEntitiesByIdToObject = (currentProject, query) => {
  const relevantEntityIds = query?.entities ?? [];
  const relevantById = {};
  currentProject?.entities
    ?.filter(projectEntity =>
      relevantEntityIds.includes(projectEntity.externalId)
    )
    ?.forEach(
      relevantEntity =>
        (relevantById[`${relevantEntity.externalId}`] = relevantEntity.name)
    );
  return relevantById;
};
export const populateRelevantEntitiesByIdToArray = (currentProject, query) => {
  const relevantEntityIds = query?.entities ?? [];
  return currentProject?.entities?.filter(projectEntity =>
    relevantEntityIds.includes(projectEntity.externalId)
  );
};

/**
 * Check that a projection has a set of unique values
 * @param {Object[]} array - an array of object with a property
 * @param {string} property - the name of the property to check for uniqueness
 * @returns {boolean} - are the values unique
 */
const uniqueProjectionValues = (array, property) => {
  const uniqueValuesCount = new Set(array.map(a => a[property])).size;
  return array.length === uniqueValuesCount;
};

/**
 * Check that:
 * * all names and all externalIds are unique.
 * * entities is defined
 * * there are no error on an entity
 * * there at least one entity
 *
 * @param {Array<EntityError|Entity>} entities
 * @param {Function} t - the translation function
 * @returns {?EntityError} error object
 */

export const validateEntities = (entities, t) => {
  if (!entities || entities.length === 0) {
    return {
      error: { message: t("common:ui.projects.entities.error.atLeastOne") }
    };
  }
  if (entities.some(e => e.error)) {
    return {
      error: {
        message: t("common:ui.projects.entities.error.invalid", {
          errors: entities
            .filter(e => e.error)
            .map(({ error: { message } }) => message)
            .join()
        })
      }
    };
  }
  if (!uniqueProjectionValues(entities, "name")) {
    return {
      error: { message: t("common:ui.projects.entities.error.uniqueName") }
    };
  }
  if (!uniqueProjectionValues(entities, "externalId")) {
    return {
      error: { message: t("common:ui.projects.entities.error.uniqueId") }
    };
  }
};

/**
 * @typedef {Object} Entity
 * @property {string} name - The entity name
 * @property {number} externalId - The entity id
 */
/**
 * @typedef {Object} ErrorMessage
 * @property {string} message
 */
/**
 * @typedef {Object} EntityError
 * @property {?ErrorMessage} error
 */

/**
 * will parse a string array into a name and an id, name must be a least 1 char, id needs to be a valid int
 * any extra column is discarded
 * @param {string[]} data - the string array to validate
 * @param {Function} t - the translation function
 * @returns {(EntityError|Entity)} an object with a {error:{message}} if there is an error, or with {name,externalId} if not
 */
const validateEntity = (data, t) => {
  if (!Array.isArray(data)) {
    // this will never happened with CSV parser but is here for exhaustiveness
    return {
      error: { message: t("common:ui.projects.entities.error.emptyLine") }
    };
  }
  if (data.length < 2) {
    return {
      error: {
        message: t("common:ui.projects.entities.error.twoColumns", { data })
      }
    };
  }
  const [name, number] = data;
  const externalId = Number.parseInt(number, 10);
  if (
    externalId.toString() !== number.trim() ||
    !Number.isSafeInteger(externalId)
  ) {
    return {
      error: {
        message: t("common:ui.projects.entities.error.invalidId", {
          data,
          number: number.trim()
        })
      }
    };
  }
  if (typeof name !== "string" || name.trim().length === 0) {
    return {
      error: {
        message: t("common:ui.projects.entities.error.invalidName", {
          data,
          name
        })
      }
    };
  }
  return { name, externalId };
};

/**
 *
 * @param {string} entities
 * @param {Function} t - the translation function
 * @returns {Array.<EntityError|Entity>} - an array of {name, externalId}
 */
export const parseEntities = (entities, t) => {
  if (typeof entities !== "string") {
    return [
      { error: { message: t("common:ui.projects.entities.error.atLeastOne") } }
    ];
  }
  try {
    return CSV.parse(entities.replaceAll("\t", ",")).map(e =>
      validateEntity(e, t)
    );
  } catch (e) {
    return [{ error: { message: e } }];
  }
};
