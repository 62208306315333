import React from "react";

import PropTypes from "prop-types";

import "./Icon.scss";

const Icon = ({
  name,
  designStyle,
  colorStyle,
  fillStyle,
  size,
  className,
  onClick,
  hoverElement
}) => {
  const iconClass = () => {
    if (designStyle === "material-icons") {
      return fillStyle === "filled"
        ? "material-icons"
        : `material-icons-${fillStyle}`;
    }

    return `material-symbols-${fillStyle}`;
  };

  const colorClass = () => {
    switch (colorStyle) {
      case "inherit":
        return "ot-icon--inherit-color";
      case "muted":
        return "ot-icon--muted-color";
      case "accent":
        return "ot-icon--accent-color";
      case "sideNav":
        return "ot-icon--sideNav-color";
      case "sideNav-IR":
        return "ot-icon--sideNav-IR-color";
      case "success":
        return "ot-icon--success-color";
      default:
        return "";
    }
  };

  const getClassName = () =>
    ` ${
      className ?? ""
    } ot-icon ot-icon--${size} ${iconClass()} ${colorClass()} ${
      onClick ? "ot-icon--clickable" : ""
    }`;

  return (
    <span className="ot-icon__container">
      <i onClick={onClick} className={getClassName()}>
        {name}
      </i>
      {hoverElement && <span className="ot-icon__hover">{hoverElement}</span>}
    </span>
  );
};

Icon.defaultProps = {
  designStyle: "material-symbols",
  fillStyle: "outlined",
  size: "normal"
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  designStyle: PropTypes.oneOf(["material-symbols", "material-icons"]),
  fillStyle: PropTypes.oneOf(["outlined", "filled"]),
  colorStyle: PropTypes.oneOf([
    "muted",
    "inherit",
    "accent",
    "sideNav",
    "sideNav-IR"
  ]),
  size: PropTypes.oneOf(["small", "medium", "normal", "large"]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  hoverElement: PropTypes.node
};

export default Icon;
