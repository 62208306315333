import React from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants";
import { useAuthUser } from "@shared/hooks/useAuthUser";

import { menuItemAccess } from "@app/helpers/menuItemAccess";

import BrandButton from "@components/atoms/Button/BrandButton";
import FileLink from "@components/organisms/FileLink";

import "./QueryActions.scss";

export const buttonActionConstants = {
  reassign: "REASSIGN",
  closeRequest: "CLOSE_REQUEST",
  addResponse: "ADD_RESPONSE",
  reopen: "REOPEN",
  closePage: "CLOSE_PAGE",
  download: "DOWNLOAD",
  sendToHost: "SEND_TO_HOST",
  approve: "APPROVE",
  reject: "REJECT"
};

const STATUS = systemConstants.project.queries.status;

const isClosed = s => s === STATUS.closed;

const isOpened = s => s === STATUS.responded || s === STATUS.open;

const isResponded = s => s === STATUS.responded;

const getActionButtons = ({
  props,
  user,
  menuConfig,
  extraButtonConfig = []
}) => {
  const { t } = props;
  const actions = [];
  const buttons = menuConfig.filter(menu => menu.name === "BUTTONS");
  if (buttons.length === 0) {
    return actions;
  }

  const buttonLabelForAction = action => {
    return (
      t(`requests:requests.configured.menu.actions.${action.action}.label`, {
        context: props.queryConfig.key
      }) ?? action.name
    );
  };

  buttons[0].actions.forEach(a => {
    switch (a.action) {
      case buttonActionConstants.addResponse: {
        if (buttonActionConstants.addResponse in props.requestHandlers) {
          menuItemAccess(a.availableTo, user, props.isUserRequestor) &&
            !isClosed(props.queryStatus) &&
            actions.push({
              type: "primary",
              icon: "add",
              name: buttonLabelForAction(a),
              handler: props.requestHandlers[buttonActionConstants.addResponse]
            });
        }
        break;
      }
      case buttonActionConstants.closeRequest: {
        if (buttonActionConstants.closeRequest in props.requestHandlers) {
          menuItemAccess(a.availableTo, user, props.isUserRequestor) &&
            isOpened(props.queryStatus) &&
            actions.push({
              type: "secondary",
              icon: "",
              name: buttonLabelForAction(a),
              handler: props.requestHandlers[buttonActionConstants.closeRequest]
            });
        }
        break;
      }
      case buttonActionConstants.reassign: {
        if (buttonActionConstants.reassign in props.requestHandlers) {
          menuItemAccess(a.availableTo, user, props.isUserRequestor) &&
            isOpened(props.queryStatus) &&
            actions.push({
              type: "secondary",
              icon: "",
              name: buttonLabelForAction(a),
              handler: props.requestHandlers[buttonActionConstants.reassign]
            });
        }
        break;
      }
      case buttonActionConstants.reopen: {
        if (buttonActionConstants.reopen in props.requestHandlers) {
          menuItemAccess(a.availableTo, user, props.isUserRequestor) &&
            isClosed(props.queryStatus) &&
            actions.push({
              type: "primary",
              icon: "",
              name: buttonLabelForAction(a),
              handler: props.requestHandlers[buttonActionConstants.reopen]
            });
        }
        break;
      }
      case buttonActionConstants.download: {
        if (buttonActionConstants.download in props.requestHandlers) {
          menuItemAccess(a.availableTo, user, props.isUserRequestor) &&
            actions.push({
              type: "FileLink",
              name: buttonLabelForAction(a),
              handler: props.requestHandlers[buttonActionConstants.download]
            });
        }
        break;
      }
      case buttonActionConstants.sendToHost: {
        if (buttonActionConstants.sendToHost in props.requestHandlers) {
          menuItemAccess(a.availableTo, user, props.isUserRequestor) &&
            isOpened(props.queryStatus) &&
            !isResponded(props.queryStatus) &&
            !user.isHostUser &&
            actions.push({
              type: "primary",
              icon: "",
              name: buttonLabelForAction(a),
              handler: props.requestHandlers[buttonActionConstants.sendToHost]
            });
        }
        break;
      }
    }
  });

  extraButtonConfig.forEach(b => {
    switch (b.action) {
      case buttonActionConstants.closePage:
        actions.push({
          type: "tertiary",
          icon: "",
          name: t("requests:requests.ui.requestActions.actions.closePageLabel"),
          handler: props.requestHandlers[buttonActionConstants.closePage]
        });
    }
  });
  return actions;
};

const QueryActions = props => {
  let actions = [];
  const { user } = useAuthUser();
  switch (props.queryType) {
    case systemConstants.project.queries.queryTypes.todoRequest:
    case systemConstants.project.queries.queryTypes.queryRequest:
      actions = getActionButtons({
        props,
        user,
        menuConfig: props.queryConfig.menus ?? []
      });
      break;
    default:
      if (
        [
          systemConstants.actionItemTypes.conversation,
          systemConstants.actionItemTypes.smartForm
        ].includes(props.queryConfig.type)
      ) {
        actions = getActionButtons({
          props,
          user,
          menuConfig: props.queryConfig.menus ?? [],
          extraButtonConfig: props.queryConfig.buttons ?? []
        });
      } else {
        actions = [];
      }
  }

  return (
    <div className="ot-query-actions">
      {actions.map((action, index) => {
        if (action.type === "FileLink") {
          return (
            <div key={index} className="ot-query-actions-items">
              <FileLink
                key={index}
                label={action.name}
                apiUrl={`queries/${props.queryId}/answers/download`}
                downloadingLabel={action.name}
                setErrorMessage={action.handler}
              />
            </div>
          );
        } else {
          return (
            <div key={index} className="ot-query-actions-items">
              <BrandButton
                key={index}
                type={action.type}
                iconName={action.icon}
                label={action.name}
                onClick={action.handler}
                disabled={action.disabled}
              />
            </div>
          );
        }
      })}
    </div>
  );
};

QueryActions.defaultProps = {};

QueryActions.propTypes = {
  queryConfig: PropTypes.object.isRequired,
  queryType: PropTypes.string.isRequired,
  queryId: PropTypes.number.isRequired,
  queryStatus: PropTypes.string.isRequired,
  isUserRequestor: PropTypes.bool.isRequired,
  requestHandlers: PropTypes.any.isRequired // Key lookup with button as key
};

export default withTranslation()(QueryActions);
