export const manageDashboardConstants = {
  GET_OPEN_QUERIES_REQUEST: "GET_OPEN_QUERIES_REQUEST",
  GET_OPEN_QUERIES_SUCCESS: "GET_OPEN_QUERIES_SUCCESS",
  GET_OPEN_QUERIES_FAILURE: "GET_OPEN_QUERIES_FAILURE",

  GET_CONTACTS_REQUEST: "GET_CONTACTS_REQUEST",
  GET_CONTACTS_SUCCESS: "GET_CONTACTS_SUCCESS",
  GET_CONTACTS_FAILURE: "GET_CONTACTS_FAILURE",

  GET_RECENT_DOCUMENTS_REQUEST: "GET_RECENT_DOCUMENTS_REQUEST",
  GET_RECENT_DOCUMENTS_SUCCESS: "GET_RECENT_DOCUMENTS_SUCCESS",
  GET_RECENT_DOCUMENTS_FAILURE: "GET_RECENT_DOCUMENTS_FAILURE",

  GET_PROJECTS_REQUEST: "GET_PROJECTS_REQUEST",
  GET_PROJECTS_SUCCESS: "GET_PROJECTS_SUCCESS",
  GET_PROJECTS_FAILURE: "GET_PROJECTS_FAILURE",

  UPDATE_TODO_STATUS_REQUEST: "UPDATE_TODO_STATUS_REQUEST",
  UPDATE_TODO_STATUS_SUCCESS: "UPDATE_TODO_STATUS_SUCCESS",
  UPDATE_TODO_STATUS_FAILURE: "UPDATE_TODO_STATUS_FAILURE",

  CLEAR_ERROR: "CLEAR_ERROR",
  RESET: "RESET_DASHBOARD"
};
