import React from "react";

import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

import { systemConstants } from "@shared/constants/systemConstants";
import dateFormatter from "@shared/helpers/dateHelper";
import { utilities } from "@shared/helpers/utilities";
import { useLocaleDate } from "@shared/hooks";

import TextTruncate from "@shared-components/UI/textTruncate/TextTruncate";

import "./ProjectTableRow.scss";

const ProjectTableRow = props => {
  const {
    row,
    onClickRow,
    showRiskFlag,
    getMenuItems,
    isArchived,
    tableConstants,
    isMobile,
    handleHoverVisibility,
    milestoneName,
    isProjectManagerFieldEnabled
  } = props;
  const { t } = useTranslation();

  const {
    locale,
    options: { shortFormat }
  } = useLocaleDate();

  const getStatusClassName = status => {
    let statusClassName = "table-data table-data--status ";
    if (status === systemConstants.project.status.behind) {
      statusClassName += "table-data--status-behind";
    } else if (status === systemConstants.project.status.completed) {
      statusClassName += "table-data--status-completed";
    } else if (status === systemConstants.project.status.onTrack) {
      statusClassName += "table-data--status-ontrack";
    }
    return statusClassName;
  };

  const renderMessageBoxWithMessage = () => {
    return (
      <>
        <i
          className="material-icons table-data--center-icon-mail-unread"
          data-for={`email-${row.id}`}
          data-tip={""}
        >
          email
        </i>
        <ReactTooltip
          className="table-data--center-icon__tooltip"
          multiline={true}
          place="top"
          type="dark"
          effect="solid"
          id={`email-${row.id}`}
        >
          {row.unreadMessages.length === 1
            ? "1 unread notification"
            : row.unreadMessages.length + " unread notifications"}
        </ReactTooltip>
      </>
    );
  };

  const renderMessageBoxWithNoMessage = () => {
    return (
      <>
        {" "}
        <i
          className="material-icons"
          data-for={`drafts-${row.id}`}
          data-tip={""}
        >
          drafts
        </i>
        <ReactTooltip
          className="table-data--center-icon__tooltip"
          multiline={true}
          place="top"
          type="dark"
          effect="solid"
          id={`drafts-${row.id}`}
        >
          {"0 unread notifications"}
        </ReactTooltip>{" "}
      </>
    );
  };

  const renderDesktopProjectTable = () => {
    const upcomingMilestoneDate = dateFormatter(
      row?.upcomingMilestone?.date,
      locale,
      shortFormat
    );
    return (
      <>
        <td className="table-data table-data--center-icon">
          {row.unreadMessages.length && !isArchived
            ? renderMessageBoxWithMessage()
            : renderMessageBoxWithNoMessage()}
        </td>
        {showRiskFlag && (
          <td
            data-test="risks-row"
            className="table-data table-data--center-icon"
          >
            <>
              <i
                data-for={`risks-${row.id}`}
                data-tip={""}
                className={
                  "material-icons " +
                  ((row.newRisks || row.blockingRisks) && !isArchived
                    ? "table-data--center-icon-alert-active"
                    : "material-icons")
                }
              >
                flag
              </i>
              <ReactTooltip
                className="table-data--center-icon__tooltip"
                multiline={true}
                place="top"
                type="dark"
                effect="solid"
                id={`risks-${row.id}`}
              >
                {row.newRisks === 1
                  ? " 1 open risk"
                  : row.newRisks + " open risks"}
              </ReactTooltip>
            </>
          </td>
        )}
        <td className="table-data table-data--project-name">
          {!isArchived ? (
            <span className="table__links">
              <TextTruncate
                className="table__links--text-truncate"
                text={row.name}
                maxWidth={utilities.calmpViewWidth(
                  25,
                  utilities.convertPxToVW(110),
                  utilities.convertPxToVW(580)
                )}
              />
            </span>
          ) : (
            <TextTruncate
              className="table__links--text-truncate"
              text={row.name}
              maxWidth="30vw"
            />
          )}
        </td>
        <td className={getStatusClassName(row.status)}>
          {t("common:ui.project.status", {
            context: row.status?.replaceAll(" ", "_")
          })}
        </td>
        <td className="table-data table-data--project-milestone">
          {row.upcomingMilestone && (
            <div>
              <span>{milestoneName}</span>
              <div className="table-data--project-milestone-box">
                {t("common:ui.projectTable.milestoneDueText", {
                  context: row.upcomingMilestone.hours < 0 ? "past" : "future",
                  date: upcomingMilestoneDate,
                  count: Math.abs(row.upcomingMilestone.days)
                })}
              </div>
            </div>
          )}
        </td>
        {isProjectManagerFieldEnabled && (
          <td className="table-data table-data--project-manager-name">
            {row.projectManager?.name}
          </td>
        )}
        <td className="table-data table-data--nav">
          {!isArchived ? (
            <span className="table__links">{tableConstants.viewDetails}</span>
          ) : (
            <span>{tableConstants.viewDetails}</span>
          )}
        </td>
        {getMenuItems(row)}
      </>
    );
  };

  const renderMobileProjectTable = () => {
    <>
      <td className="table-data table-data--project-name-logo">
        <img
          className="table-data--project-name-logo__box"
          src="/logo-gray-oneteam.png"
        />
      </td>
      <td className="table-data name">
        {isArchived ? (
          <span className="name__text-disabled">{row.name}</span>
        ) : (
          <span className="name__text" onClick={() => onClickRow(row)}>
            {row.name}
          </span>
        )}
      </td>
      <td className={getStatusClassName(row.status)}>{row.status}</td>
      {getMenuItems(row)}
    </>;
  };

  return (
    <tr
      key={row.id}
      className={
        "projects-table__row" +
        (row && isArchived ? " projects-table__row-disabled" : "")
      }
      onClick={() => !isArchived && onClickRow(row)}
      onMouseEnter={() => {
        handleHoverVisibility({ rowId: row.id, display: true });
      }}
      onMouseLeave={() => {
        handleHoverVisibility({ rowId: row.id, display: false });
      }}
    >
      {isMobile ? renderDesktopProjectTable() : renderMobileProjectTable()}
    </tr>
  );
};

export default ProjectTableRow;
