import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  clientDocumentActions,
  manageDocumentUploadsActions,
  manageProjectDocumentsActions
} from "@shared/actions";
import { systemConstants } from "@shared/constants";

import Button from "@components/atoms/Button/BrandButton";
import InlineAlert from "@components/atoms/InlineAlert";
import UploadFileAttachmentList from "@components/molecules/UploadFileAttachmentList";
import ModalTemplate from "@components/templates/ModalTemplate";

import "./AddClientDocument.scss";

const attachedFileStates = systemConstants.addFiles.attachedFile.state;
const uploadAbortError = "As per user request aborting document(s) upload.";

const AddClientDocument = props => {
  const { t } = props;
  const dispatch = useDispatch();
  const { manageDocumentUploads, manageProject, clientDocuments } = useSelector(
    state => state
  );
  const inputRef = useRef(null);
  const [supportedDocumentMimes, setSupportedDocumentMimes] = useState(
    systemConstants.mimes.document
  );
  // files: what the user has selected to upload
  // documents: clientDocument metadata related to the File
  const [files, setFiles] = useState([...props.droppedFiles]);
  const [documents, setDocuments] = useState([]);
  const [uploadSubmitted, setUploadSubmitted] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setSupportedDocumentMimes(manageProject.supportedDocumentMimes);
  }, [manageProject.supportedDocumentMimes]);

  // the problem is that there is too much manipulation of 'documents' both indirect and directly via setDocuments
  // forEach with mutations, map with mutations...

  const attachmentsObject = useMemo(() => {
    if (!documents?.length) {
      return null;
    }

    const formattedDocuments = {};
    documents.forEach(doc => {
      if (doc.name) {
        const updated = { ...doc };
        if (!uploadSubmitted && doc.error) {
          updated.state = attachedFileStates.uploadFailed;
        }
        if (doc.pathId) {
          updated.state = attachedFileStates.uploaded;
        }
        if (!doc.state) {
          updated.state = attachedFileStates.selected;
        }
        formattedDocuments[doc.name] = updated;
      }
    });
    return formattedDocuments;
  }, [documents, uploadSubmitted]);

  useEffect(() => {
    if (!props.droppedFiles?.length) {
      return;
    }
    const documentList = props.droppedFiles.map(documentObject => {
      return {
        id: documentObject.id || null,
        name: documentObject.name,
        state: attachedFileStates.selected,
        clientId: props.clientId,
        revisionName: ""
      };
    });
    setFiles([...props.droppedFiles]);
    setDocuments([...documentList]);
  }, [props.clientId, props.droppedFiles]);

  useEffect(() => {
    // creates the ClientDocument record (i.e. as each file is successfully uploaded)
    documents.map(documentObject => {
      if (documentObject.pathId && !documentObject.metadataUpdateStated) {
        dispatch(
          clientDocumentActions.uploadClientDocument(props.clientId, {
            ...documentObject,
            documentPathId: documentObject.pathId,
            tags: []
          })
        );
        documentObject.metadataUpdateStated = true;
      }

      if (!documentObject.pathId && documentObject.error === uploadAbortError) {
        documentObject.aborted = true;
      }

      return documentObject;
    });
  }, [dispatch, documents, props.clientId]);

  // sets whether ALL documents have been uploaded or not
  const hasAllDocumentsUploaded = useMemo(() => {
    const newDocs = clientDocuments?.newUploadedDocuments || [];
    const updatedDocs = clientDocuments?.uploadedDocuments || [];
    const combined = [...newDocs, ...updatedDocs];
    const uploadedCompleteSet = new Set(combined.map(d => d.name));

    const result = documents.every(cd => uploadedCompleteSet.has(cd.name));
    return result;
  }, [
    documents,
    clientDocuments.newUploadedDocuments,
    clientDocuments.uploadedDocuments
  ]);

  useEffect(() => {
    if (clientDocuments.error) {
      setError(clientDocuments.error);
    }
  }, [clientDocuments.error]);

  // this is used to update the document object for the progress update. can probably be combined with attachmentObject
  // changes here affect when the clientDocument record is uploaded and the attachmentObject
  useEffect(() => {
    if (
      manageDocumentUploads.uploadingDocuments &&
      manageDocumentUploads.uploadingDocuments.length
    ) {
      const updatedDocuments = documents.map(document => {
        const documentUploaded = manageDocumentUploads.uploadingDocuments.find(
          uploadingDocument =>
            uploadingDocument.clientId === document.clientId &&
            uploadingDocument.name === document.name
        );

        const updated = { ...document };

        if (documentUploaded?.documentPathId) {
          updated.pathId = documentUploaded.documentPathId;
          updated.state = attachedFileStates.attached;
        }

        if (documentUploaded?.error) {
          setError(documentUploaded.error);
          updated.error = documentUploaded.error;
          updated.state = attachedFileStates.selected;
        }

        if (documentUploaded?.uploadProgress) {
          updated.uploadProgress = documentUploaded.uploadProgress;
          updated.state = attachedFileStates.uploading;
        }

        if (documentUploaded?.reqSource) {
          updated.reqSource = documentUploaded.reqSource;
        }
        return updated;
      });
      setDocuments(updatedDocuments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageDocumentUploads.uploadingDocuments]);

  const handleChange = event => {
    event.stopPropagation();
    const addedFiles = [...event.target.files];
    event.target.value = null;
    if (
      addedFiles.length &&
      addedFiles.some(
        document => document.size > systemConstants.project.document.maxSize
      )
    ) {
      setError("The maximum supported file size is 50 GB for documents.");
      return;
    }

    const newFiles = addedFiles.filter(fileObject => {
      let documentNotAdded = true;
      files.forEach(file => {
        if (fileObject.name === file.name) {
          documentNotAdded = false;
        }
      });
      return documentNotAdded;
    });

    setFiles(files.concat(newFiles));
    setDocuments(
      documents.concat(
        newFiles.map(file => {
          return {
            id: file.id || null,
            name: file.name,
            state: attachedFileStates.selected,
            clientId: props.clientId,
            revisionName: ""
          };
        })
      )
    );
    setError(false);
  };

  const handleCancel = useCallback(() => {
    if (!uploadSubmitted || error) {
      dispatch(manageProjectDocumentsActions.resetNewUploadedDocument());
      dispatch(manageProjectDocumentsActions.resetUploadedDocument());
      dispatch(manageDocumentUploadsActions.resetUploadingDocuments());
      dispatch(manageProjectDocumentsActions.clearError());
      props.onUpload();
    }
  }, [dispatch, error, props, uploadSubmitted]);

  const handleUpload = () => {
    if (files.length) {
      setUploadSubmitted(true);
      files.forEach(file => {
        dispatch(
          manageDocumentUploadsActions.uploadDocument({
            file,
            clientId: props.clientId
          })
        );
      });
    }
  };

  const handleReqCancel = useCallback(() => {
    let isReqCancelled = false;
    documents.forEach(docObject => {
      if (docObject && docObject.reqSource && !docObject.pathId) {
        isReqCancelled = true;
        docObject.reqSource.cancel(uploadAbortError);
      }
    });
    if (!isReqCancelled) {
      dispatch(manageProjectDocumentsActions.resetNewUploadedDocument());
      dispatch(manageProjectDocumentsActions.resetUploadedDocument());
      dispatch(manageDocumentUploadsActions.resetUploadingDocuments());
      props.onUpload();
    }
  }, [dispatch, documents, props]);

  const handleDone = useCallback(() => {
    if (hasAllDocumentsUploaded) {
      dispatch(manageProjectDocumentsActions.resetNewUploadedDocument());
      dispatch(manageProjectDocumentsActions.resetUploadedDocument());
      dispatch(manageDocumentUploadsActions.resetUploadingDocuments());
      props.onUpload();
      props.onFileUpload();
    }
  }, [dispatch, props, hasAllDocumentsUploaded]);

  const handleAddFile = () => {
    inputRef.current.click();
  };

  const handleRemove = removedDocumentName => {
    const updatedFiles = files.filter(
      fileObject => fileObject.name !== removedDocumentName
    );
    const updatedDocuments = documents.filter(
      documentObject => documentObject.name !== removedDocumentName
    );
    setFiles(updatedFiles);
    setDocuments(updatedDocuments);
    setError("");
    setUploadSubmitted(false);
  };

  const getUploadCancellationError = () => {
    const uploadCancelledDocuments = documents.filter(
      docObject => docObject.error === uploadAbortError
    );

    const uploadedDocumentsCount =
      documents.length - uploadCancelledDocuments.length;
    return `${uploadedDocumentsCount} document(s) uploaded and ${uploadCancelledDocuments.length} document(s) were cancelled.`;
  };

  const getSupportedMimes = useCallback(() => {
    const supportedMimesList = supportedDocumentMimes.map(mime => `.${mime}`);
    return supportedMimesList.join();
  }, [supportedDocumentMimes]);

  const handleClose = useMemo(() => {
    if (hasAllDocumentsUploaded) {
      return handleDone;
    }

    if (!uploadSubmitted || error) {
      return handleCancel;
    }

    return handleReqCancel;
  }, [
    hasAllDocumentsUploaded,
    uploadSubmitted,
    error,
    handleReqCancel,
    handleDone,
    handleCancel
  ]);

  return (
    <ModalTemplate
      boxClassName="upload-documents"
      title={t("common:ui.documents.fileUpload.label")}
      onClose={handleClose}
      content={
        <>
          {error && (
            <InlineAlert
              type="error"
              message={
                error === uploadAbortError
                  ? getUploadCancellationError()
                  : error
              }
            ></InlineAlert>
          )}
          <div className="hidden">
            <input
              ref={inputRef}
              onChange={handleChange}
              type="file"
              multiple
              accept={getSupportedMimes()}
            />
          </div>

          {documents.length ? (
            <div>
              {error ? (
                ""
              ) : (
                <p>
                  {t(
                    "common:ui.documents.fileUpload.uploadAttachmentFileList",
                    {
                      count: documents.length
                    }
                  )}
                </p>
              )}
              <div className="add-file__container--file-box">
                {attachmentsObject && (
                  <UploadFileAttachmentList
                    attachments={attachmentsObject}
                    onDelete={handleRemove}
                  />
                )}
              </div>
            </div>
          ) : (
            <div>
              <p>{t("common:documents.fileUpload.addDocumentsHintText")}</p>
            </div>
          )}

          {!uploadSubmitted ? (
            <>
              <Button
                type="text-dark"
                label={t("common:documents.fileUpload.addDocuments")}
                iconName="add_circle"
                onClick={handleAddFile}
              />
            </>
          ) : (
            ""
          )}
        </>
      }
      footer={
        <>
          {!uploadSubmitted || error ? (
            <>
              <Button
                type={error ? "primary" : "secondary"}
                disabled={uploadSubmitted && !error}
                label={t("common:documents.fileUpload.buttons", {
                  context:
                    error === uploadAbortError
                      ? "CLOSE"
                      : error
                      ? "DONE"
                      : "CANCEL"
                })}
                onClick={handleCancel}
              />
              {error ? (
                ""
              ) : (
                <Button
                  type="primary"
                  disabled={!files.length || uploadSubmitted || !!error}
                  label={t("common:ui.forms.upload.label")}
                  onClick={handleUpload}
                />
              )}
            </>
          ) : (
            <>
              {hasAllDocumentsUploaded ? (
                <Button
                  type="primary"
                  disabled={!hasAllDocumentsUploaded}
                  label={t("common:documents.fileUpload.buttons", {
                    context: "DONE"
                  })}
                  onClick={handleDone}
                />
              ) : (
                <Button
                  type="secondary"
                  label={t("common:documents.fileUpload.buttons", {
                    context: "CANCEL"
                  })}
                  onClick={handleReqCancel}
                />
              )}
            </>
          )}
        </>
      }
    />
  );
};

export default withTranslation()(AddClientDocument);
