/**
 * @param { Array.<string> | string} availableTo The permission groups an action is available for.
 * @param { object } user The current user.
 * @param { boolean } [isRequestor] Determines if the current user is on the requestor team.
 * @returns { boolean } A flag that determines if the current user has permission for an action.
 */
export const menuItemAccess = (availableTo, user = {}, isRequestor) => {
  const { isHostUser = false, isClientUser = false } = user;
  if (typeof availableTo === "string") {
    return menuItemAccess(
      [availableTo],
      { isHostUser, isClientUser },
      isRequestor
    );
  }
  if (!Array.isArray(availableTo)) {
    return false;
  }
  const isProps = { isHostUser, isClientUser, isRequestor, ALL: true };
  const conditions = [
    { level: "HOST", prop: "isHostUser" },
    { level: "CLIENT", prop: "isClientUser" },
    { level: "REQUESTOR", prop: "isRequestor" },
    { level: "ALL", prop: "ALL" }
  ];
  return conditions
    .map(({ level, prop }) => availableTo.includes(level) && isProps[prop])
    .reduce((acc, b) => acc || b, false);
};
