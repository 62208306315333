import React from "react";

import { getDesiredFontSizeRem } from "@shared/helpers/sideNavNameFontSize";

import { classNames } from "@app/helpers/componentHelpers";

import "./NavHeader.scss";

const getTextDirection = (isExpanded: boolean) =>
  isExpanded ? "nav-header__text" : "nav-header__text--vertical";

interface Props {
  text: string;
  isExpanded: boolean;
  image?: string;
}

function NavHeader(props: Props) {
  const { text, isExpanded, image } = props;
  return (
    <div className="nav-header">
      {isExpanded && image && <img className="nav-header__image" src={image} />}
      <h3
        className={classNames([getTextDirection(isExpanded)])}
        style={{
          fontSize: `${getDesiredFontSizeRem(text)}rem`
        }}
      >
        {text}
      </h3>
    </div>
  );
}

export default NavHeader;
