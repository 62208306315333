import React, { useCallback, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { systemConstants } from "@shared/constants/systemConstants";
import { useAuthUser, useProjectQueries } from "@shared/hooks";
import { useUIConfig } from "@shared/hooks/useUIConfig";
import { useGetActionItemTypesQuery } from "@shared/services/actionItemTypesService";

import { routeConstants } from "@app/constants/routeConstants";
import { expectedQueryTypes, isClosed, isOverdue } from "@app/helpers";
import { isActive } from "@app/helpers/actionItems";

import PieChartTable from "@components/atoms/Chart/PieChartTable/PieChartTable";
import ToggleButtonGroup from "@components/atoms/ToggleButtonGroup/ToggleButtonGroup";
import DashboardBoxTemplate from "@components/templates/DashboardBoxTemplate";

import filterColorVariables from "../../../helpers/filterColorVariables.module.scss";
import legendColorVariable from "../../../helpers/legendColorVariable.module.scss";
import "./ActionItemsBoard.scss";

const ActionItemsBoard = props => {
  const { projectMembers, project, t } = props;
  const navigate = useNavigate();
  const { user: authUser } = useAuthUser();

  const { data: actionItemTypes } = useGetActionItemTypesQuery(
    {
      engagementTypeId: project?.engagement?.engagementTypeId,
      projectId: project?.id
    },
    { skip: project?.engagement?.engagementTypeId === undefined }
  );
  const { allQueryTypeKeys } = useMemo(
    () => expectedQueryTypes(actionItemTypes, t),
    [actionItemTypes, t]
  );
  const { queries } = useProjectQueries(project, allQueryTypeKeys);
  const { uiConfig } = useUIConfig();
  const [teamMembers, setTeamMembers] = useState({});
  const [queryItems, setQueryItems] = useState({
    myOpenRequest: 0,
    myRespondedRequest: 0,
    myUpcomingRequests: 0,
    myOverdueRequests: 0,
    myActiveRequests: 0,
    myResolvedRequests: 0,
    myTeamOpenRequest: 0,
    myTeamRespondedRequest: 0,
    myTeamUpcomingRequests: 0,
    myTeamOverdueRequests: 0,
    myTeamActiveRequests: 0,
    myTeamResolvedRequests: 0
  });
  const [selectedType, setSelectedType] = useState("MYREQUESTS");
  const {
    configuration: {
      requests: { summaryIndicators }
    }
  } = project;

  const toggleTypes = {
    myRequests: "MYREQUESTS",
    teamRequests: "TEAMREQUESTS"
  };
  const toggleButtons = [
    {
      type: toggleTypes.myRequests,
      label: t("requests:requests.ui.requestBoard.myRequestsLabel")
    },
    {
      type: toggleTypes.teamRequests,
      label: t("requests:requests.ui.requestBoard.myTeamRequestsLabel")
    }
  ];

  const isByDate = uiConfig?.actionItemFilters?.dueDate?.enabled;
  const isMyRequests = selectedType === toggleTypes.myRequests;
  const isTeamRequests = selectedType === toggleTypes.teamRequests;
  useEffect(() => {
    if (!queries?.length || !authUser || !Object.values(teamMembers).length) {
      setQueryItems({
        myOpenRequest: 0,
        myRespondedRequest: 0,
        myUpcomingRequests: 0,
        myOverdueRequests: 0,
        myActiveRequests: 0,
        myResolvedRequests: 0,
        myTeamOpenRequest: 0,
        myTeamRespondedRequest: 0,
        myTeamUpcomingRequests: 0,
        myTeamOverdueRequests: 0,
        myTeamActiveRequests: 0,
        myTeamResolvedRequests: 0
      });
      return;
    }
    if (queries.length > 0 && project.id !== queries[0].project.id) {
      // those are not my queries, they have the wrong project id!
      return;
    }
    const myAssigned = queries
      .filter(q => q.assignedTo)
      .filter(q => q.assignedTo.id === authUser.id);
    const teamAssigned = queries
      .filter(q => q.assignedTo)
      .filter(q => teamMembers[q.assignedTo.id]);
    if (isByDate) {
      const now = new Date();
      const myUpcoming = myAssigned.filter(
        q => !isClosed(q) && !isOverdue(q, now)
      );
      const myOverdue = myAssigned.filter(
        q => !isClosed(q) && isOverdue(q, now)
      );
      const teamUpcoming = teamAssigned.filter(
        q => !isClosed(q) && !isOverdue(q, now)
      );
      const teamOverdue = teamAssigned.filter(
        q => !isClosed(q) && isOverdue(q, now)
      );
      setQueryItems({
        myUpcomingRequests: myUpcoming.length,
        myOverdueRequests: myOverdue.length,
        myTeamUpcomingRequests: teamUpcoming.length,
        myTeamOverdueRequests: teamOverdue.length
      });
    } else {
      const myActive = myAssigned.filter(q => isActive(q));
      const myResolved = myAssigned.filter(q => isClosed(q));
      const teamActive = teamAssigned.filter(q => isActive(q));
      const teamResolved = teamAssigned.filter(q => isClosed(q));

      const myOpen = myAssigned.filter(({ status }) => status === "OPEN");
      const myResponded = myAssigned.filter(
        ({ status }) => status === "RESPONDED"
      );

      const teamOpen = teamAssigned.filter(({ status }) => status === "OPEN");
      const teamResponded = teamAssigned.filter(
        ({ status }) => status === "RESPONDED"
      );
      setQueryItems({
        myActiveRequests: myActive.length,
        myOpenRequest: myOpen.length,
        myRespondedRequest: myResponded.length,
        myResolvedRequests: myResolved.length,
        myTeamActiveRequests: teamActive.length,
        myTeamResolvedRequests: teamResolved.length,
        myTeamOpenRequest: teamOpen.length,
        myTeamRespondedRequest: teamResponded.length
      });
    }
  }, [project.id, queries, authUser, teamMembers, isByDate]);

  const data = useMemo(() => {
    const getQueryNumber = (indicator, isMyRequests, isByDate) => {
      const {
        myUpcomingRequests,
        myOverdueRequests,
        myActiveRequests,
        myOpenRequest,
        myRespondedRequest,
        myResolvedRequests,
        myTeamUpcomingRequests,
        myTeamOverdueRequests,
        myTeamActiveRequests,
        myTeamResolvedRequests,
        myTeamOpenRequest,
        myTeamRespondedRequest
      } = queryItems;

      if (isByDate) {
        if (isMyRequests) {
          return indicator === "UPCOMING"
            ? myUpcomingRequests
            : myOverdueRequests;
        }
        return indicator === "UPCOMING"
          ? myTeamUpcomingRequests
          : myTeamOverdueRequests;
      }
      if (isMyRequests) {
        switch (indicator) {
          case "OPEN":
            return myOpenRequest;
          case "RESPONDED":
            return myRespondedRequest;
          case "ACTIVE":
            return myActiveRequests;
          case "RESOLVED":
            return myResolvedRequests;
          default:
            return 0;
        }
      }
      if (isTeamRequests) {
        switch (indicator) {
          case "OPEN":
            return myTeamOpenRequest;
          case "RESPONDED":
            return myTeamRespondedRequest;
          case "ACTIVE":
            return myTeamActiveRequests;
          case "RESOLVED":
            return myTeamResolvedRequests;
          default:
            return 0;
        }
      }
      return 0;
    };
    return {
      keys: ["requests"],
      values: summaryIndicators.map(indicator => {
        return {
          label: t("requests:requests.indicators.label", {
            context: indicator.toLowerCase()
          }),
          requests: getQueryNumber(indicator, isMyRequests, isByDate),
          indicator
        };
      })
    };
  }, [
    isByDate,
    isMyRequests,
    isTeamRequests,
    queryItems,
    summaryIndicators,
    t
  ]);
  const filterTypes = systemConstants.dashboard.actionItemsFilterType;

  useEffect(() => {
    const myTeamMembers = {};
    if (authUser.isClientUser) {
      projectMembers?.clientUsers?.forEach(u => {
        myTeamMembers[u.id] = u;
      });
    } else if (authUser.isHostUser) {
      projectMembers?.hostUsers?.forEach(u => {
        myTeamMembers[u.id] = u;
      });
    }
    setTeamMembers(myTeamMembers);
  }, [projectMembers, authUser]);

  const goToActionItemsPage = (filters = {}) => {
    filters.members = projectMembers;
    navigate(routeConstants.request.requests, {
      state: { project: props.project, filters }
    });
  };

  const handleClickMyUpcomingItems = () => {
    goToActionItemsPage({ type: filterTypes.myUpcomingItems });
  };

  const handleClickMyOverdueItems = () => {
    goToActionItemsPage({ type: filterTypes.myPastDueItems });
  };

  const handleClickTeamUpcomingItems = () => {
    goToActionItemsPage({ type: filterTypes.myTeamsUpcomingItems });
  };

  const handleClickTeamOverdueItems = () => {
    goToActionItemsPage({ type: filterTypes.myTeamsPastDueItems });
  };
  const handleClickMyActiveItems = () => {
    goToActionItemsPage({ type: filterTypes.myActiveItems });
  };

  const handleClickMyRespondedItems = () => {
    goToActionItemsPage({ type: filterTypes.myRespondedItems });
  };

  const handleClickMyOpenItems = () => {
    goToActionItemsPage({ type: filterTypes.myOpenItems });
  };

  const handleClickMyClosedItems = () => {
    goToActionItemsPage({ type: filterTypes.myClosedItems });
  };

  const handleClickMyTeamOpenItems = () => {
    goToActionItemsPage({ type: filterTypes.myTeamOpenItems });
  };
  const handleClickMyTeamRespondedItems = () => {
    goToActionItemsPage({ type: filterTypes.myTeamRespondedItems });
  };

  const handleClickTeamActiveItems = () => {
    goToActionItemsPage({ type: filterTypes.myTeamActiveItems });
  };

  const handleClickTeamClosedItems = () => {
    goToActionItemsPage({ type: filterTypes.myTeamClosedItems });
  };

  const handleLegendClick = e => {
    const indicatorLabel = e.indicator;
    const myDirectMap = {
      OPEN: handleClickMyOpenItems,
      RESPONDED: handleClickMyRespondedItems,
      ACTIVE: handleClickMyActiveItems,
      RESOLVED: handleClickMyClosedItems,
      UPCOMING: handleClickMyUpcomingItems,
      OVERDUE: handleClickMyOverdueItems
    };
    const teamDirectMap = {
      OPEN: handleClickMyTeamOpenItems,
      RESPONDED: handleClickMyTeamRespondedItems,
      RESOLVED: handleClickTeamClosedItems,
      ACTIVE: handleClickTeamActiveItems,
      UPCOMING: handleClickTeamUpcomingItems,
      OVERDUE: handleClickTeamOverdueItems
    };
    if (isMyRequests) {
      myDirectMap[indicatorLabel]();
    } else {
      teamDirectMap[indicatorLabel]();
    }
  };

  const handleToggleClick = useCallback(type => setSelectedType(type), []);

  const renderEmptyPlaceHolder = () => (
    <foreignObject x={70} y={115} className="empty-place-holder">
      <div>{t("requests:requests.ui.requestBoard.emptyPlaceHolder")}</div>
    </foreignObject>
  );

  const getChartColors = filterColorVariables => {
    const chartColors = summaryIndicators.map(
      indicator => filterColorVariables[indicator]
    );
    return {
      chartColors,
      legendTextColor: legendColorVariable["legendTextColor"]
    };
  };

  return (
    <DashboardBoxTemplate
      title={t("requests:requests.ui.requestBoard.title")}
      boxClassName={"action-items-board"}
    >
      <ToggleButtonGroup
        onClick={handleToggleClick}
        toggleButtons={toggleButtons}
      />
      <PieChartTable
        data={data}
        handleLegendClick={handleLegendClick}
        pieRadius={"75%"}
        className="action-items-board__chart"
        isSecondaryStyle={true}
        colors={getChartColors(filterColorVariables)}
        displayZero={true}
        fixedHeight={240}
        chartMargin={{}}
        renderEmptyPlaceHolder={renderEmptyPlaceHolder}
        dataType="number"
      />
    </DashboardBoxTemplate>
  );
};

ActionItemsBoard.defaultProps = {};

ActionItemsBoard.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number
  })
};

export default withTranslation()(ActionItemsBoard);
