import React, { useCallback, useEffect, useState } from "react";

import PropTypes from "prop-types";

import DropdownDotMenu from "@components/atoms/DropdownDotMenu/DropdownDotMenu";
import Text from "@components/atoms/Text";

import "./EditableListItem.scss";

export const EditableListItem = ({
  key,
  text,
  disabledText,
  canEdit,
  onEditClicked,
  onDeleteClicked
}) => {
  const editLabel = "Edit";
  const deleteLabel = "Delete";

  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const itemsList = [];
    if (onEditClicked) itemsList.push({ name: editLabel });
    if (onDeleteClicked) itemsList.push({ name: deleteLabel });
    setMenuItems([...itemsList]);
  }, [onDeleteClicked, onEditClicked]);

  const menuItemClicked = useCallback(
    item => {
      switch (item.name) {
        case editLabel:
          onEditClicked?.();
          break;
        case deleteLabel:
          onDeleteClicked?.();
          break;
        default:
          break;
      }
    },
    [onDeleteClicked, onEditClicked]
  );
  return (
    <>
      <div key={key} className="editable-list-item">
        <Text value={text} disabled={disabledText} fontType="bold" />
        {canEdit && menuItems?.length > 0 && (
          <DropdownDotMenu
            positionRelative={true}
            menuItems={menuItems}
            onMenuItemClick={menuItemClicked}
          />
        )}
      </div>
    </>
  );
};

EditableListItem.propTypes = {
  text: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  canEdit: PropTypes.bool,
  disabledText: PropTypes.bool,
  key: PropTypes.any
};

export default EditableListItem;
