import React, { useCallback, useEffect, useState } from "react";

import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  useAuthUser,
  useEngagementMembers,
  useRemoveEngagementMember
} from "@shared/hooks";

import LoaderSpinner from "@shared-components/loaderSpinner/LoaderSpinner";
import Popup from "@shared-components/popup/Popup";

import { routeConstants } from "@app/constants/routeConstants";

import BrandButton from "@components/atoms/Button/BrandButton";
import DangerModal from "@components/molecules/DangerModal";
import AddEngagementUser from "@components/organisms/AddEngagementUser";
import EngagementMembersDataTable from "@components/organisms/EngagementMembersDataTable";

import "./ManageEngagementUsers.scss";

const ManageEngagementUsers = props => {
  const { engagement, t } = props;
  const { user } = useAuthUser();
  const { engagementMembers: members, getMembers } = useEngagementMembers();
  const { removeMember, removedMember } = useRemoveEngagementMember();
  const [userToRemove, setUserToRemove] = useState(null);
  const history = useNavigate();

  const manageClientEngagements = useSelector(
    state => state.manageClientEngagements
  );

  const [showRemoveUserWarning, setShowRemoveUserWarning] = useState(false);
  const [addUserToEngagementVisibility, setAddUserToEngagementVisibility] =
    useState(false);

  useEffect(() => {
    getMembers(engagement);
  }, [engagement, getMembers]);

  useEffect(() => {
    if (engagement && removedMember) {
      if (user.isTeamMember && userToRemove?.id === user.id) {
        history.push(routeConstants.manageClientEngagements);
      } else {
        getMembers(engagement);
      }
    }
  }, [
    engagement,
    getMembers,
    history,
    removeMember.id,
    removedMember,
    user.id,
    userToRemove?.id,
    user.isTeamMember
  ]);

  const handleAddUser = () => {
    setAddUserToEngagementVisibility(true);
  };

  const handlePopupOutsideClick = () => {
    setAddUserToEngagementVisibility(false);
  };

  const handleRemoveUser = useCallback(() => {
    setShowRemoveUserWarning(false);
    removeMember(engagement, userToRemove);
  }, [engagement, removeMember, userToRemove]);

  const onRemoveUserClicked = useCallback(member => {
    setUserToRemove(member);
    setShowRemoveUserWarning(true);
  }, []);

  const onHideRemoveWarning = useCallback(() => {
    setShowRemoveUserWarning(false);
  }, []);

  return (
    <>
      <div className="engagement-users__container">
        {user.isHostUser && (
          <div className="engagement-users__container--header">
            <BrandButton
              icon="add"
              label={t("common:ui.manageUsers.addLabel")}
              type="primary"
              onClick={handleAddUser}
            />
          </div>
        )}

        {manageClientEngagements.getMembersLoading ? (
          <div className="engagement-users__container--spinner">
            <LoaderSpinner diameter="30px" />
          </div>
        ) : members && members.length ? (
          <>
            <EngagementMembersDataTable
              members={members}
              handleRemoveFromEngagement={onRemoveUserClicked}
            />
          </>
        ) : (
          <div className="engagement-users__container--no-user-message">
            <div className="engagement-users__container--no-user-message-icon">
              <i className="material-icons">people</i>
            </div>
            <div>
              <div className="engagement-users__container--no-user-message-first-message">
                No user has been added.
              </div>
              <div className="engagement-users__container--no-user-message-second-message">
                Click the "Add User" button to get started.
              </div>
            </div>
          </div>
        )}
      </div>

      <Popup
        visibility={showRemoveUserWarning}
        handleOutsideClick={false}
        width="60rem"
      >
        <DangerModal
          title="Remove user from Engagement"
          content={`Removing a user from an Engagement will remove the user from all Projects that are part of the Engagement. Are you sure you want to remove ${userToRemove?.name} from this Engagement?`}
          handleConfirmation={handleRemoveUser}
          onCancel={onHideRemoveWarning}
        />
      </Popup>

      <Popup
        visibility={addUserToEngagementVisibility}
        handleOutsideClick={false}
        width="60rem"
      >
        <AddEngagementUser
          engagement={engagement}
          onAdd={handlePopupOutsideClick}
          onCancel={handlePopupOutsideClick}
        />
      </Popup>
    </>
  );
};

export default withTranslation()(ManageEngagementUsers);
