import React, { useCallback, useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { systemConstants } from "@shared/constants";
import { utilities } from "@shared/helpers";
import {
  projectDashboardServiceUtil,
  useAuthUser,
  useGetFiltersForDashboardQuery,
  useLazyGetProjectsForDashboardQuery
} from "@shared/hooks";
import { useGetFeaturesQuery } from "@shared/services/featuresService";

import ProjectPanelList from "@app/pages/ProjectsDashboard/ProjectPanelList";
import { useAppStore } from "@app/useAppStore";

import ProjectDashboardFilter from "@components/molecules/ProjectDashboardFilter";

import ProjectsDashboardTemplate from "./ProjectsDashboardTemplate";
import ProjectsTable from "./ProjectsTable";

const getScrollPercentage = target => {
  const scrollTop = target.scrollTop;
  const scrollHeight = target.scrollHeight;
  const clientHeight = target.clientHeight;
  return (scrollTop / (scrollHeight - clientHeight)) * 100;
};

const ProjectsDashboard = () => {
  const [projects, setProjects] = useState([]);
  const { user } = useAuthUser();
  const viewType = useAppStore(state => state.projectsDashboard.viewType);
  const setViewType = useAppStore(state => state.setProjectsDashboardViewType);
  const [projectFilterAndPageId, setProjectFilterAndPageId] = useState(null);
  const { data: features } = useGetFeaturesQuery();
  const dispatch = useDispatch();
  const { data: filters } = useGetFiltersForDashboardQuery(null, {
    refetchOnMountOrArgChange: true
  });
  const [
    fetchProjectsForDashboardQuery,
    { isFetching, data: latestFetchedPaginatedProject }
  ] = useLazyGetProjectsForDashboardQuery();

  const fetchProjects = useCallback(
    async ({ params }) => {
      fetchProjectsForDashboardQuery(params, true)
        .unwrap()
        .then(paginatedProjects => {
          if (params.pageId === 1) {
            //for when a filter is applied / on page load
            setProjects(paginatedProjects);
          } else {
            setProjects(prev =>
              utilities.getUniqueArrayOfObjectsById(prev, paginatedProjects)
            );
          }
        });
    },
    [fetchProjectsForDashboardQuery]
  );

  useEffect(() => {
    //invalidate project cache on mount, to ensure latest
    dispatch(projectDashboardServiceUtil.invalidateTags(["ProjectDashboard"]));
  }, [dispatch, fetchProjects]);

  useEffect(() => {
    //fetch projects on filter/page change
    if (projectFilterAndPageId) {
      fetchProjects({ params: projectFilterAndPageId });
    }
  }, [fetchProjects, projectFilterAndPageId]);

  useEffect(() => {
    sessionStorage.removeItem("lastClient");
  }, []);

  const onProjectPanelScroll = useCallback(
    e => {
      e.preventDefault();
      const scrollPercentage = getScrollPercentage(e.target);
      if (
        scrollPercentage > 50 &&
        !isFetching &&
        latestFetchedPaginatedProject.length > 0
      ) {
        setProjectFilterAndPageId(prev => ({
          ...prev,
          pageId: prev.pageId + 1
        }));
      }
    },
    [isFetching, latestFetchedPaginatedProject]
  );
  const handleFilterChange = useCallback(val => {
    //pageId should reset when filter changes
    setProjectFilterAndPageId({ ...val, pageId: 1 });
  }, []);

  return (
    <>
      <ProjectsDashboardTemplate
        filtersView={
          <ProjectDashboardFilter
            filters={filters}
            handleChange={handleFilterChange}
          />
        }
        projectsPanelView={
          <ProjectPanelList
            projects={projects}
            isFetching={isFetching}
            onScrollHandler={onProjectPanelScroll}
          />
        }
        projectsTableView={
          <ProjectsTable
            projects={projects}
            scrollHandler={onProjectPanelScroll}
            isFetching={isFetching}
          />
        }
        viewType={viewType}
        onChangeViewType={setViewType}
        showNoResultsFoundMessage={!projects?.length && !isFetching}
        enableAddProject={
          user?.isHostUser &&
          features &&
          !features[systemConstants.features.engagements]
        }
      />
    </>
  );
};

export default ProjectsDashboard;
