import React, { useCallback, useMemo, useState } from "react";

import PropTypes from "prop-types";

import BrandButton from "@components/atoms/Button/BrandButton";
import LoaderSpinner from "@components/atoms/LoaderSpinner/LoaderSpinner";
import FileDownloader from "@components/organisms/FileDownloader";

const FileLink = props => {
  const { setErrorMessage, apiUrl, label, downloadingLabel } = props;
  const [downloadParams, setDownloadParams] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const downloadingHandler = () => {
    setIsDownloading(false);
  };
  const errorHandler = useCallback(
    message => {
      setDownloadParams(null);
      setErrorMessage({
        type: "error",
        message: message?.toString?.()
      });
      setIsDownloading(false);
    },
    [setErrorMessage]
  );

  const handleDownload = useCallback(() => {
    if (isDownloading) {
      return;
    }
    setErrorMessage();
    setIsDownloading(true);
    setDownloadParams({ apiUrl, sequenceId: Date.now() });
  }, [apiUrl, isDownloading, setErrorMessage]);

  const renderLabel = useMemo(() => {
    if (isDownloading) {
      return (
        <div className="download-documents--container">
          <LoaderSpinner diameter="25"></LoaderSpinner>
          {downloadingLabel}
        </div>
      );
    } else {
      return <>{label}</>;
    }
  }, [downloadingLabel, isDownloading, label]);

  return (
    <>
      <BrandButton
        type="text-accent"
        onClick={handleDownload}
        iconName={!isDownloading ? "download" : ""}
        label={renderLabel}
      />
      {downloadParams && (
        <FileDownloader
          downloadParams={downloadParams}
          onError={errorHandler}
          onDownloading={downloadingHandler}
        ></FileDownloader>
      )}
    </>
  );
};

FileLink.defaultProps = {};

FileLink.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  downloadingLabel: PropTypes.string.isRequired,
  setErrorMessage: PropTypes.func.isRequired
};

export default FileLink;
