import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";

export const actionItemService = createApi({
  reducerPath: "actionItemApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders: (headers, { getState }) => {
      const token =
        getState().authentication?.token || localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: [
    "Query",
    "QueryResponse",
    "QueryLockedUpdateFields",
    "getProjectActionItems",
    "Fields",
    "CopyRequest"
  ],
  endpoints: builder => ({
    getClientActionItems: builder.query({
      query: ({ clientId }) => `/api/clients/${clientId}/queries`,
      providesTags: ["Query"]
    }),
    getProjectActionItems: builder.query({
      query: ({ projectId, copyable = false, targetProjectId }) =>
        `/api/projects/${projectId}/queries?${new URLSearchParams({
          copyable,
          targetProjectId
        })}`,
      providesTags: ["getProjectActionItems"]
    }),
    getActionItem: builder.query({
      query: ({ queryId }) => `/api/queries/${queryId}`,
      providesTags: ["Query"]
    }),
    addActionItem: builder.mutation({
      query: actionItem => ({
        url: `/api/queries`,
        method: "POST",
        body: actionItem
      }),
      invalidatesTags: ["Query"]
    }),
    getLockedUpdateFields: builder.query({
      query: ({ queryId }) => `/api/queries/${queryId}/lockedUpdateFields`,
      providesTags: ["QueryLockedUpdateFields"]
    }),
    getResponses: builder.query({
      query: ({ queryId }) => `/api/queries/${queryId}/queryResponses`,
      providesTags: ["QueryResponse"]
    }),
    getCopyFromFields: builder.query({
      query: ({ queryIds, projectId }) => ({
        url: `/api/projects/${projectId}/queries/requiredFields`,
        params: { queryIds }
      }),
      providesTags: ["Fields"]
    }),
    copyRequest: builder.mutation({
      query: ({ projectId, data }) => ({
        url: `/api/projects/${projectId}/queries/copyFrom`,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["CopyRequest"]
    })
  })
});

export const {
  useAddActionItemMutation,
  useGetProjectActionItemsQuery,
  useGetLockedUpdateFieldsQuery,
  useGetClientActionItemsQuery,
  useLazyGetActionItemQuery,
  useLazyGetResponsesQuery,
  useGetCopyFromFieldsQuery,
  useCopyRequestMutation
} = actionItemService;
