import React, { useRef } from "react";

import PropTypes from "prop-types";

import { systemConstants } from "@shared/constants/systemConstants";

import { formatBusinessNumber, formatMobileNumber } from "@app/helpers/users";

import "./ContactDetails.scss";

const ContactDetails = props => {
  const { user } = props;
  const contactName = useRef(null);
  const contactJobDetail = useRef(null);
  const formattedBusinessNumber = formatBusinessNumber(user);
  const formattedMobileNumber = formatMobileNumber(user);
  const isClientUser = user?.role?.name == systemConstants.roles.clientUser;

  const contactNameOverflow =
    contactName.current?.scrollWidth > contactName.current?.clientWidth;

  const contactJobTitleOverflow =
    contactJobDetail.current?.scrollWidth >
    contactJobDetail.current?.clientWidth;

  return (
    <div className="contact__details__right">
      <span className="contact-name" ref={contactName}>
        {contactNameOverflow ? (
          <abbr title={user.name}>{user.name}</abbr>
        ) : (
          user.name
        )}
      </span>
      <div className="contact-jobDetail">
        {user.jobTitle && (
          <span
            className="contact-jobDetail__label contact-jobDetail__jobTitle"
            ref={contactJobDetail}
          >
            {contactJobTitleOverflow ? (
              <abbr title={contactJobTitleOverflow && user.jobTitle}>
                {user.jobTitle}
              </abbr>
            ) : (
              user.jobTitle
            )}
          </span>
        )}
        {user.jobTitle && isClientUser && (
          <span className="contact-jobDetail__label contact-jobDetail__comma">
            ,
          </span>
        )}
        {isClientUser && (
          <span className="contact-jobDetail__label contact-jobDetail__companyName">
            {props.user.memberOf?.name}
          </span>
        )}
      </div>
      {formattedBusinessNumber && (
        <span data-testid={"test-business-number"} className="contact-label">
          {formattedBusinessNumber}
        </span>
      )}
      {formattedMobileNumber && (
        <span data-testid={"test-mobile-number"} className="contact-label">
          {formattedMobileNumber}
        </span>
      )}
    </div>
  );
};

ContactDetails.propTypes = {
  user: PropTypes.object.isRequired
};

export default ContactDetails;
