import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { manageClientUsersActions } from "@shared/actions";
import { systemConstants } from "@shared/constants";
import { loggedUser } from "@shared/helpers";
import { utilities } from "@shared/helpers";
import {
  useGetClientByIdQuery,
  useUpdateClientUserStatus
} from "@shared/hooks";

import LoaderSpinner from "@shared-components/loaderSpinner/LoaderSpinner";

import { routeConstants } from "@app/constants";

import Button from "@components/atoms/Button/BrandButton";
import InlineAlert from "@components/atoms/InlineAlert";
import DropdownPagination from "@components/molecules/DropdownPagination";
import SearchTextInput from "@components/organisms/SearchTextInput";
import UsersDataTable from "@components/organisms/UserDataTable";
import MainPageTemplate from "@components/templates/MainPageTemplate";
import PageBodyTemplate from "@components/templates/PageBodyTemplate";

import "./ManageClientUsers.scss";

const ManageClientUsers = () => {
  const { t } = useTranslation();
  const authentication = useSelector(state => state.authentication);
  const isClientAdmin = loggedUser.isClientAdmin(authentication.user);
  const manageClientUsers = useSelector(state => state.manageClientUsers);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({
    countPerPage: systemConstants.pagination.itemCountPerPage,
    pageCount: 1,
    currentPage: "Page 1",
    currentPageIndex: 0,
    pages: []
  });
  const { clientId } = useParams();
  const { isKeywordInclude } = utilities;

  const { data: client, isError } = useGetClientByIdQuery(clientId);
  const { activateClientUser, deactivateClientUser } =
    useUpdateClientUserStatus(client);

  const [filter, setFilter] = useState({ string: "", users: [] });

  useEffect(() => {
    if (!clientId || isError) {
      navigate(routeConstants.notFound);
    }
  }, [clientId, isError, navigate]);

  useEffect(() => {
    if (client) dispatch(manageClientUsersActions.getClientUsers(client));
    return () => dispatch(manageClientUsersActions.reset());
    // eslint-disable-next-line
  }, [client]);

  useEffect(() => {
    if (manageClientUsers.users?.length) {
      setFilter({ ...filter, users: manageClientUsers.users });
    }
    // eslint-disable-next-line
  }, [manageClientUsers.users]);

  useEffect(() => {
    if (filter.string) {
      createPagination(filter.users);
    } else {
      createPagination(manageClientUsers.users);
    }
    // eslint-disable-next-line
  }, [filter.users]);

  useEffect(() => {
    if (!filter.string) {
      createPagination(manageClientUsers.users);
    }
    // eslint-disable-next-line
  }, [filter.string]);

  useEffect(() => {
    if (pagination.pages.length) {
      setUsers(pagination.pages[pagination.currentPageIndex].data);
    } else {
      setUsers([]);
    }
  }, [pagination]);

  const createPagination = allUsers => {
    const count = Math.ceil(allUsers.length / pagination.countPerPage);
    const items = [];
    for (let number = 0; number < count; number++) {
      const data = allUsers.slice(
        number * pagination.countPerPage,
        number * pagination.countPerPage + pagination.countPerPage
      );
      items.push({ number: number + 1, name: `Page ${number + 1}`, data });
    }

    setPagination({
      ...pagination,
      currentPage: "Page 1",
      currentPageIndex: 0,
      pageCount: count,
      pages: items
    });
  };

  const handlePageSelection = pageName => {
    const index = pagination.pages.findIndex(page => page.name === pageName);
    setPagination({
      ...pagination,
      currentPage: pageName,
      currentPageIndex: index
    });
  };

  const handleFilterChange = searchInput => {
    if (!searchInput) {
      setFilter({ ...filter, string: "", users: manageClientUsers.users });
    } else {
      const filteredUsers = manageClientUsers.users.filter(
        user =>
          isKeywordInclude(user.name, searchInput) ||
          (user.email && isKeywordInclude(user.email, searchInput))
      );
      setFilter({
        ...filter,
        string: searchInput,
        users: filteredUsers
      });
    }
  };

  const handleAddUser = () => navigate(`/admin/clients/${clientId}/add-user`);

  const handleUpdateUser = user =>
    navigate(`/admin/clients/${clientId}/users/${user.id}/edit`);

  const handlePageCountIncrement = () => {
    const index = pagination.currentPageIndex;
    if (index < pagination.pages.length - 1) {
      setPagination({
        ...pagination,
        currentPage: `Page ${index + 2}`,
        currentPageIndex: index + 1
      });
    }
  };

  const handlePageCountDecrement = () => {
    const index = pagination.currentPageIndex;

    if (index > 0) {
      setPagination({
        ...pagination,
        currentPage: `Page ${index}`,
        currentPageIndex: index - 1
      });
    }
  };

  const headerActions = () => (
    <>
      <Button
        type="primary"
        label={t("common:ui.manageUsers.addLabel")}
        iconName="add"
        onClick={handleAddUser}
      />
    </>
  );

  return (
    <MainPageTemplate>
      <PageBodyTemplate
        breadcrumbs={
          !isClientAdmin
            ? [
                {
                  linkTo: routeConstants.manageClients,
                  label: t("common:ui.manageClients.title")
                }
              ]
            : null
        }
        title={
          isClientAdmin
            ? t("common:ui.manageUsers.title")
            : client &&
              t("common:ui.manageUsers.titleWithClientName", {
                clientName: client.name
              })
        }
        actions={headerActions()}
      >
        <div className="client-user-manager">
          {manageClientUsers.error && (
            <div className="client-user-manager__error-box">
              <InlineAlert type="error" message={t(manageClientUsers.error)} />
            </div>
          )}

          <div className="client-user-manager__search">
            <SearchTextInput
              label={t("common:ui.manageUsers.searchLabel")}
              handleChange={handleFilterChange}
            />
          </div>
          {manageClientUsers.googleAuthLoading ||
          manageClientUsers.getClientUsersLoading ||
          manageClientUsers.getClientGroupsLoading ||
          manageClientUsers.getClientRolesLoading ||
          manageClientUsers.updateClientUserLoading ||
          manageClientUsers.addClientUserLoading ? (
            <div className="client-user-manager__spinner">
              <LoaderSpinner diameter="30px" />
            </div>
          ) : manageClientUsers.users.length !== 0 ? (
            <>
              <div className="client-user-manager__table">
                <UsersDataTable
                  users={users}
                  onUpdateUser={handleUpdateUser}
                  onActivateUser={activateClientUser}
                  onDeactivateUser={deactivateClientUser}
                />
              </div>
              <DropdownPagination
                handlePageSelection={handlePageSelection}
                handlePageCountIncrement={handlePageCountIncrement}
                handlePageCountDecrement={handlePageCountDecrement}
                pages={pagination.pages.map(page => ({ name: page.name }))}
                currentPage={pagination.currentPage}
              />{" "}
            </>
          ) : (
            <div className="client-user-manager__no-user-message">
              <div className="client-user-manager__no-user-message--icon">
                <i className="material-icons">people</i>
              </div>
              <div className="client-user-manager__no-user-message--first-message">
                {t("common:ui.manageUsers.noUsersPresent")}
              </div>
              <div className="client-user-manager__no-user-message--second-message">
                {t("common:ui.manageUsers.noUsersPresentCallToAction")}
              </div>
            </div>
          )}
        </div>
      </PageBodyTemplate>
    </MainPageTemplate>
  );
};

export default ManageClientUsers;
