import { systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { handleErrorResponse, handleResponse } from "./";

const baseURL = systemConstants.serverURL;

export const manageClientUsersService = {
  getClientUsers,
  getClientRoles,
  isGoogleAuthEnabled
};

function isGoogleAuthEnabled(client) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/clients/${client.id}/isFeatureEnabled?name=${systemConstants.features.googleAuthentication}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getClientUsers(client) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`${baseURL}/api/clients/${client.id}/users`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getClientRoles(client) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`${baseURL}/api/clients/${client.id}/roles`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}
