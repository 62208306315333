import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { isUndefined } from "lodash";
import PropTypes from "prop-types";

import { classNames } from "@app/helpers/componentHelpers";

export const defaultProps = {
  items: [],
  isSelectAllDisabled: false
};

export const propTypes = {
  label: PropTypes.string,
  defaultSelectAllLabel: PropTypes.string,
  isSelectAllDisabled: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any
    })
  ).isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  reset: PropTypes.func,
  defaultValue: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any
    })
  ),
  resetInputControl: PropTypes.bool,
  placeholder: PropTypes.string,
  parentId: PropTypes.string
};

export function useMultiselect(props, fwdRef) {
  const {
    onChange: onApply,
    valueComparer,
    defaultSelectAllLabel,
    reset
  } = props;

  const [expanded, setExpanded] = useState(false);
  const [error, setError] = useState("");
  const [focus, setFocus] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [filterPhrase, setFilterPhrase] = useState("");
  const [isSelectAllDisabled, setIsSelectAllDisabled] = useState(false);
  const [checkedOptionValues, setCheckedOptionValues] = useState([]);
  const dropdownRef = useRef();
  const selectedFieldRef = useRef();

  const normaliseItems = itemsToNormalise => {
    return itemsToNormalise
      .map(item => ({
        ...item,
        value: item.value || item.name
      }))
      .filter(item => !isUndefined(item.value));
  };

  const options = useMemo(() => {
    return props.items ? normaliseItems(props.items) : [];
  }, [props.items]);

  useEffect(() => {
    if (!props.value?.length) {
      return;
    }
    const normalised = normaliseItems(props.value).map(i => i.value);
    setCheckedOptionValues(normalised);
  }, [props.value]);

  const itemSortComparator = (a, b) => {
    return a.name.localeCompare(b.name, { sensitivity: "base" });
  };

  const internalValueComparator = useCallback(
    (a, b) => {
      if (valueComparer) {
        return valueComparer(a, b);
      }

      return a === b;
    },
    [valueComparer]
  );

  const itemIsChecked = useCallback(
    item => {
      return checkedOptionValues.some(c =>
        internalValueComparator(c, item.value)
      );
    },
    [checkedOptionValues, internalValueComparator]
  );

  const dropdownItems = useMemo(() => {
    const items = !filterPhrase?.length
      ? options
      : options.filter(item =>
          item.name.toUpperCase().includes(filterPhrase.toUpperCase())
        );
    return items
      .map(item => ({
        ...item,
        isChecked: itemIsChecked(item)
      }))
      .sort(itemSortComparator);
  }, [filterPhrase, options, itemIsChecked]);

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  const updateTextValue = useCallback(() => {
    if (expanded && props.theme === "tds") {
      selectedFieldRef.current.value = "";
      return;
    }
    selectedFieldRef.current.value = options
      .filter(itemIsChecked)
      .sort(itemSortComparator)
      .map(item => item?.shortName || item.name)
      .join(", ");
  }, [expanded, props.theme, options, itemIsChecked]);

  useEffect(() => {
    if (fwdRef && dropdownRef) {
      fwdRef.current = dropdownRef.current;
    }
  }, [fwdRef, dropdownRef]);

  useEffect(() => {
    if (props.disabled && expanded) {
      setExpanded(false);
    }
  }, [props.disabled, expanded]);

  useEffect(() => {
    setIsSelectAllDisabled(
      props.isSelectAllDisabled || filterPhrase.length !== 0
    );
  }, [filterPhrase, props.isSelectAllDisabled]);

  const applySelection = useCallback(selectedItems => {
    setUpdated(false);
    setCheckedOptionValues(current => {
      const newCheckedOptions = [...new Set(selectedItems?.map(i => i?.value))];
      if (
        newCheckedOptions.length === current.length &&
        newCheckedOptions.every(i => current.includes(i.value))
      ) {
        return current;
      }
      return newCheckedOptions;
    });
  }, []);

  const toggleSelectAll = useCallback(
    newSelectAll => {
      if (newSelectAll && options?.length > 0) {
        const newItems = options.map(item => {
          return {
            ...item,
            isChecked: true
          };
        });
        applySelection(newItems);
        setUpdated(true);
        return;
      }

      applySelection([]);
      setUpdated(true);
    },
    [applySelection, options]
  );

  useEffect(() => {
    setSelectAll(checkedOptionValues.length === options.length);
    updateTextValue();
  }, [checkedOptionValues, updateTextValue, options.length, props.value]);

  const handleApply = useCallback(
    (_event, updatedItems) => {
      if (!updated) {
        return;
      }
      const newSelection = (updatedItems ? updatedItems : dropdownItems)
        .filter(item => item.isChecked)
        .map(item => {
          delete item.isChecked;
          return item;
        });
      const newOptions = filterPhrase
        ? checkedOptionValues.map(i => ({
            name: i.name ?? i,
            value: i
          }))
        : newSelection;
      applySelection(newOptions);
      onApply?.(newOptions);
    },
    [
      updated,
      dropdownItems,
      filterPhrase,
      checkedOptionValues,
      applySelection,
      onApply
    ]
  );

  const handleClearAll = useCallback(
    event => {
      toggleSelectAll(false);
      handleApply(event, []);
      reset?.();
    },
    [handleApply, toggleSelectAll, reset]
  );

  const toggleCheckedItem = useCallback(
    item => {
      setCheckedOptionValues(current => {
        const updatedItems = [...current];
        const cIdx = current.findIndex(c =>
          internalValueComparator(c, item.value)
        );
        if (cIdx !== -1) {
          updatedItems.splice(cIdx, 1);
        } else {
          updatedItems.push(item.value);
        }
        return updatedItems;
      });
      setUpdated(true);
    },
    [internalValueComparator]
  );

  const getFocusClass = () => {
    return options?.length > 0 || focus ? " focus" : "";
  };

  const getErrorClass = () => {
    return error ? " error" : "";
  };

  const getRequiredClass = () => {
    return props.required ? " asterisk" : "";
  };

  const getRecommendedClass = () => (props.recommended ? " recommended" : "");

  const getShowClass = () => {
    return expanded ? " reveal" : "";
  };

  const getCounterInputClass = () => {
    return checkedOptionValues && checkedOptionValues.length
      ? "ot-multiselect__textinput--counter"
      : "";
  };

  const getDisabledClass = () => {
    return props.disabled ? " disabled" : "";
  };

  const getDropdownToggleProps = () => {
    return {
      "aria-expanded": `${expanded}`,
      "aria-haspopup": "true",
      "data-toggle": "dropdown",
      "data-testid": "testMultiselect__toggle",
      role: "button",
      className: classNames(["dropdown-toggle", getDisabledClass()]),
      onClick: e => {
        e.stopPropagation();
        if (props.disabled) {
          return;
        }
        if (expanded) {
          handleApply();
        }
        setExpanded(!expanded);
      }
    };
  };

  const getLabelClass = () => {
    return classNames([
      "ot-multiselect__label",
      getRequiredClass(),
      getFocusClass(),
      getDisabledClass(),
      getRecommendedClass()
    ]);
  };

  return {
    dropdownRef,
    setExpanded,
    error,
    expanded,
    options,
    selectAll,
    toggleSelectAll,
    dropdownItems,
    toggleCheckedItem,
    getShowClass,
    getDisabledClass,
    getRequiredClass,
    getFocusClass,
    getCounterInputClass,
    selectedFieldRef,
    setFilterPhrase,
    setFocus,
    checkedOptions: checkedOptionValues,
    handleClearAll,
    handleApply,
    getErrorClass,
    getRecommendedClass,
    filterPhrase,
    defaultSelectAllLabel,
    isSelectAllDisabled,
    getDropdownToggleProps,
    getLabelClass
  };
}
