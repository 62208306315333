import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export const useAppStore = create(
  devtools(
    persist(
      set => ({
        projectsDashboard: {
          viewType: "grid"
        },
        setProjectsDashboardViewType: viewType =>
          set(state => ({
            projectsDashboard: {
              ...state.projectsDashboard,
              viewType
            }
          }))
      }),
      {
        name: "ot-app-store"
      }
    ),
    { name: "ot-app-store", enabled: process.env.NODE_ENV === "development" }
  )
);
