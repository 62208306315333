import React, { useCallback, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { useCurrentProject, useGetProjectByIdQuery } from "@shared/hooks";

import { routeConstants } from "@app/constants/routeConstants";

import FileDownloader from "@components/organisms/FileDownloader/FileDownloader";
import ManageClientProjectDocuments from "@components/organisms/ManageClientProjectDocuments";

import "./ManageDataRepository.scss";

const ManageDataRepository = () => {
  const [downloadParams, setDownloadParams] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [inlineAlertMsg, setInlineAlertMsg] = useState();

  const { currentProject, onChangeCurrentProject } = useCurrentProject();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { data: projectToSelect, error: isInvalidProject } =
    useGetProjectByIdQuery(
      { projectId },
      { skip: !projectId || currentProject?.id === projectId }
    );

  useEffect(() => {
    if (!projectId && currentProject) {
      navigate(
        `/projects/${currentProject.id}${routeConstants.dataRepository}`,
        { replace: true }
      );
    }
  }, [navigate, currentProject, projectId]);

  useEffect(() => {
    if (projectToSelect && currentProject?.id !== projectToSelect.id) {
      onChangeCurrentProject(projectToSelect);
    }
  }, [projectToSelect, currentProject, onChangeCurrentProject]);

  useEffect(() => {
    if (isInvalidProject) {
      navigate(routeConstants.notFound, { replace: true });
    }
  }, [isInvalidProject, navigate]);

  const downloadingHandler = () => {
    setIsDownloading(false);
  };

  const errorHandler = useCallback(message => {
    setDownloadParams(null);
    setInlineAlertMsg({
      type: "error",
      message: message?.toString?.() ?? "An error occurred"
    });
    setIsDownloading(false);
  }, []);

  const handleDownloadAll = useCallback(() => {
    if (isDownloading) {
      return;
    }
    setInlineAlertMsg();
    setIsDownloading(true);
    setDownloadParams({
      apiUrl: `projects/${currentProject.id}/documents/downloadAll`,
      sequenceId: Date.now()
    });
  }, [isDownloading, currentProject]);

  return (
    <>
      {currentProject && (
        <ManageClientProjectDocuments
          error={inlineAlertMsg}
          handleDownloadAll={handleDownloadAll}
          project={currentProject}
          isDownloading={isDownloading}
        />
      )}
      {downloadParams && (
        <FileDownloader
          downloadParams={downloadParams}
          onError={errorHandler}
          onDownloading={downloadingHandler}
        ></FileDownloader>
      )}
    </>
  );
};

export default ManageDataRepository;
