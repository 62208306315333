import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";
import { clientProjectsService } from "./clientProjectsService";
import { clientsMenuService } from "./clientsMenuService";

const onProjectMutated = async ({ dispatch, queryFulfilled }) => {
  try {
    await queryFulfilled;
    dispatch(
      clientProjectsService.util.invalidateTags(["ClientProjectForMenu"])
    );
    dispatch(clientsMenuService.util.invalidateTags(["ClientForMenu"]));
  } catch (e) {}
};

export const projectService = createApi({
  reducerPath: "projectServiceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders: (headers, { getState }) => {
      const token =
        getState().authentication?.token || localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: [
    "Project",
    "DetailedProject",
    "ClientProject",
    "ProjectManagers",
    "ProjectMilestones",
    "ClientProjectsListByEngagement"
  ],
  endpoints: builder => ({
    getClientProjects: builder.query({
      query: ({ clientId, projectId }) =>
        clientId
          ? `/api/clients/${clientId}/projects`
          : `/api/projects/${projectId}/clientProjects`,
      providesTags: result =>
        result
          ? result.map(({ id }) => ({ type: "ClientProject", id }))
          : [{ type: "ClientProject", id: "LIST" }]
    }),
    getClientProjectsWithSameEngagement: builder.query({
      query: ({ projectId }) =>
        `/api/projects/${projectId}/allProjectsWithEngagement`,
      providesTags: ["ClientProjectsListByEngagement"]
    }),
    getMyProjects: builder.query({
      query: ({ withDetails }) =>
        `/api/users/me/projects?${new URLSearchParams({ withDetails })}`,
      providesTags: (result, _, { withDetails }) =>
        result
          ? [
              "Project",
              "DetailedProject",
              { type: "DetailedProject", id: "LIST" },
              { type: "Project", id: "LIST" },
              ...result.data.map(({ id }) => ({
                type: withDetails ? "DetailedProject" : "Project",
                id
              }))
            ]
          : [{ type: "Project", id: "LIST" }]
    }),
    getProjectById: builder.query({
      query: ({ projectId }) => `/api/projects/getById/${projectId}`,
      providesTags: (_, __, { projectId }) => [
        { type: "Project", id: projectId }
      ]
    }),
    getProjectMilestones: builder.query({
      query: ({ projectId, onlyEngagementTypeMilestones }) =>
        onlyEngagementTypeMilestones
          ? `/api/projects/${projectId}/milestones?type=engagementType`
          : `/api/projects/${projectId}/milestones`,
      providesTags: ["ProjectMilestones"]
    }),
    copyProject: builder.mutation({
      query: ({ payload }) => ({
        url: "/api/projects/copyProject",
        method: "POST",
        body: payload
      }),
      invalidatesTags: [
        { type: "Project", id: "LIST" },
        "ClientProjectsListByEngagement"
      ]
    }),
    getProjectManagers: builder.query({
      query: ({ hostId }) => `/api/hosts/${hostId}/project-managers`,
      providesTags: ["ProjectManagers"]
    }),
    addProject: builder.mutation({
      query: project => ({
        url: "/api/projects",
        method: "POST",
        body: { project }
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) =>
        onProjectMutated({ dispatch, queryFulfilled }),
      invalidatesTags: [
        { type: "Project", id: "LIST" },
        { type: "DetailedProject", id: "LIST" },
        "ClientProjectsListByEngagement"
      ]
    }),
    addEngagementProject: builder.mutation({
      query: ({ project, fillUsers }) => ({
        url: `/api/engagements/${project.engagementId}/projects`,
        method: "POST",
        body: { project, fillUsers }
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) =>
        onProjectMutated({ dispatch, queryFulfilled }),
      invalidatesTags: [
        { type: "Project", id: "LIST" },
        { type: "DetailedProject", id: "LIST" },
        "ClientProjectsListByEngagement"
      ]
    }),
    updateProject: builder.mutation({
      query: ({ project }) => ({
        url: `/api/projects/${project.id}`,
        method: "PUT",
        body: project
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) =>
        onProjectMutated({ dispatch, queryFulfilled }),
      invalidatesTags: (_, __, { project }) =>
        project?.id
          ? [
              { type: "DetailedProject", id: project?.id },
              { type: "Project", id: project?.id },
              "ClientProjectsListByEngagement"
            ]
          : []
    })
  })
});

export const {
  useGetProjectMilestonesQuery,
  useGetClientProjectsQuery,
  useGetClientProjectsWithSameEngagementQuery,
  useGetMyProjectsQuery,
  useGetProjectByIdQuery,
  useCopyProjectMutation,
  useAddProjectMutation,
  useAddEngagementProjectMutation,
  useUpdateProjectMutation,
  useGetProjectManagersQuery
} = projectService;
