import React, { useCallback, useRef, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useOnClickOutside from "use-onclickoutside";

import ErrorBox from "@shared/components/errorBox/ErrorBox";

import BrandButton from "@components/atoms/Button/BrandButton";

import "./ModalContentTemplate.scss";

const ModalContentTemplate = ({
  modalClassName,
  header,
  body,
  footer,
  handleOutsideClick,
  onClose,
  leftSidePanel
}) => {
  const { t } = useTranslation();
  const headerRef = React.createRef();
  const modalRef = useRef(null);

  const [showMore, setShowMore] = useState(false);
  const [showLink, setShowLink] = useState(false);

  React.useLayoutEffect(() => {
    if (headerRef.current.clientHeight < headerRef.current.scrollHeight) {
      setShowLink(header.expandable);
    }
  }, [headerRef, header.expandable]);

  const onClickMore = () => {
    if (header.expandable) {
      setShowMore(prev => !prev);
    }
  };

  const onCloseWrapper = useCallback(
    cb => {
      if (cb) {
        cb();
        onClose?.();
      }
    },
    [onClose]
  );

  const onClickOutside = useCallback(() => {
    onCloseWrapper(handleOutsideClick);
  }, [handleOutsideClick, onCloseWrapper]);

  useOnClickOutside(modalRef, onClickOutside);

  const renderLeftSidePanel = () => {
    return (
      <div className="modal-content-template__left-side-panel">
        {leftSidePanel}
      </div>
    )
  }

  const renderConversationHeader = () => {
    return (
      <div className={"modal-content-template__conversation-header"}>
        <div className="modal-content-template__conversation-header__left">
          <div className="modal-content-template__conversation-header__title">{body?.conversationTitle}</div>
          <div className="modal-content-template__conversation-header__entities">
          ABC Pty Ltd
          </div>
        </div>
        <div className="modal-content-template__conversation-header__right">
          {/* <div className="modal-content-template__conversation-header__status">
            <Pill
              label={"Open"}
              size={"medium"}
              colorScheme={"accent"}
              shape={"slightly-rounded"}
            />
          </div>
          <div className="modal-content-template__conversation-header__markAsClose">
            <BrandButton
              iconName={"done"}
              iconSide={"left"}
              type={"text"}
              onClick={() => { }}
              label={"Mark as Close"}
              className={"modal-content-template__conversation-header__markAsClose__button"}
            />
          </div> */}
        </div>
      </div>
    )
  }

  return (
    <div className={`modal-content-template ${modalClassName}`}>
      <div className={"modal-content-template__outer-container"}>
        <div
          className={"modal-content-template__inner-container"}
          id={"modal-content-template-inner-container"}
          ref={modalRef}
        >
          <div
            ref={headerRef}
            className={`modal-content-template__header ${
              header.colouredBg
                ? "modal-content-template__header--color"
                : "modal-content-template__header--no-color "
            } ${
              header.seperator
                ? "modal-content-template__header--seperator"
                : ""
            } 
            ${
              header.expandable
                ? "modal-content-template__header--expandable"
                : ""
            } ${showMore ? "modal-content-template__header--expanded" : ""}`}
          >
            {!header.hideCancel && (
              <div
                className="modal-content-template__header__close"
                onClick={() => {
                  onCloseWrapper(footer.handleCancel);
                }}
              >
                <i className="material-icons">close</i>
              </div>
            )}
            {header.topContent && (
              <div className="modal-content-template__header__top-content">
                {header.topContent}
              </div>
            )}
            <div className={"modal-content-template__header__text"}>
              <>
                {header.defaultTitleStyle === true ? (
                  <h1 className="modal-content-template__header__text__title">
                    {header.title}
                  </h1>
                ) : (
                  <div className="modal-content-template__header__text__title--other-style">
                    {header.title}
                  </div>
                )}
              </>

              {header.subtitle && (
                <div
                  className={"modal-content-template__header__text__subtitle"}
                >
                  {header.subtitle}
                </div>
              )}
            </div>
            {header.content && (
              <div className="modal-content-template__header__content">
                {header.content}
              </div>
            )}
            {showLink && (
              <div className="modal-content-template__header--expandable__expand">
                {!showMore && (
                  <div className="modal-content-template__header--expandable__expand-scrim"></div>
                )}
                <div className="modal-content-template__header--expandable__expand-content">
                  <BrandButton
                    label={
                      showMore
                        ? t("common:ui.modal.header.showLess.label")
                        : t("common:ui.modal.header.showMore.label")
                    }
                    iconName={showMore ? "expand_less" : "expand_more"}
                    iconSide="right"
                    type="text-accent"
                    onClick={onClickMore}
                  />
                </div>
              </div>
            )}
            {header.action && (
              <div className="modal-content-template__header__text__action">
                {header.action}
              </div>
            )}
          </div>
          <div className="modal-content-template__body-container">
            {leftSidePanel && renderLeftSidePanel()}
            {/* Inner added to provide a relative positioning for all dropdowns/multiselects in the modal */}
            <div className="modal-content-template__right-side-panel">
              <div
                className={"modal-content-template__inner"}
                id="modal-content-template-inner"
              >
                {!body.loadingOrNoContentMessage ? (
                  <div
                    className={"modal-content-template__content"}
                    id="modal-content-template-content"
                  >
                    {leftSidePanel && !body?.isNewComment && renderConversationHeader()}
                    {body.message && (
                      <div
                        className={"modal-content-template__content__message"}
                        id="modal-content-template-content-message"
                      >
                        {body.message}
                      </div>
                    )}
                    {body.content ?? ""}
                  </div>
                ) : (
                  <div
                    className={"modal-content-template__content__replacement"}
                    id="modal-content-template-content-replacement"
                  >
                    <span
                      className={
                        "modal-content-template__content__replacement__text"
                      }
                    >
                      {body.loadingOrNoContentMessage}
                    </span>
                  </div>
                )}
                {body.errorMessage && (
                  <div className={"modal-content-template__error"}>
                    <ErrorBox message={body.errorMessage} />
                  </div>
                )}
              </div>
              {!footer.hideFooter && (
                <div
                  className={`modal-content-template__footer ${footer.seperator
                      ? "modal-content-template__footer--seperator"
                      : ""
                    }`}
                >
                  {footer.content && (
                    <div
                      className={"modal-content-template__footer__inner"}
                      id="modal-content-template-footer"
                    >
                      <div className="modal-content-template__footer__content">
                        {footer.content}
                      </div>
                    </div>
                  )}
                  {!footer.buttonOverride ? (
                    <div
                      className={
                        "modal-content-template__footer__buttons modal-content-template__footer__buttons--default"
                      }
                    >
                      <div className="modal-content-template__footer__footnote">
                        {footer.footnote}
                      </div>
                      {!footer.hideCancel && (
                        <BrandButton
                          type="secondary"
                          onClick={() => onCloseWrapper(footer.handleCancel)}
                          className="modal-content-template__footer__buttons--cancel"
                          label={
                            footer.cancelText
                              ? footer.cancelText
                              : t("common:ui.forms.cancel.label")
                          }
                        />
                      )}
                      <BrandButton
                        type="primary"
                        disabled={footer.disableSubmit}
                        onClick={footer.handleSubmit}
                        className="modal-content-template__footer__buttons--submit"
                        label={
                          footer.submitText
                            ? footer.submitText
                            : t("common:ui.forms.submit.label")
                        }
                      />
                    </div>
                  ) : (
                    <div
                      className={
                        "modal-content-template__footer__buttons--override"
                      }
                    >
                      {footer.buttonOverride}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalContentTemplate.defaultProps = {
  header: {
    topContent: null,
    subtitle: null,
    colouredBg: false,
    content: null,
    defaultTitleStyle: true,
    hideCancel: false
  },
  body: {
    content: <></>,
    errorMessage: ""
  },
  footer: {
    submitText: null,
    handleSubmit: null,
    cancelText: null,
    handleCancel: null,
    hideCancel: false,
    buttonOverride: null,
    seperator: false,
    footnote: null,
    disableSubmit: false,
    hideFooter: false
  },
  handleOutsideClick: null
};

ModalContentTemplate.propTypes = {
  modalClassName: PropTypes.string,
  header: PropTypes.shape({
    topContent: PropTypes.node, // Content to display above the heading
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    defaultTitleStyle: PropTypes.bool,
    subtitle: PropTypes.node,
    colouredBg: PropTypes.bool, // If heading has coloured bg $theme-modal-header-bg
    content: PropTypes.node, // Content to display below the heading
    hideCancel: PropTypes.bool, // hides the X close button in the corner
    expandable: PropTypes.bool,
    action: PropTypes.node
  }),
  body: PropTypes.shape({
    message: PropTypes.string,
    content: PropTypes.node,
    errorMessage: PropTypes.string,
    loadingOrNoContentMessage: PropTypes.string,
    conversationTitle: PropTypes.string,
    isNewComment: PropTypes.bool
  }),
  footer: PropTypes.shape({
    content: PropTypes.node, // Content for the footer e.g. Document upload and text box
    submitText: PropTypes.string,
    handleSubmit: PropTypes.func,
    cancelText: PropTypes.string,
    handleCancel: PropTypes.func,
    hideCancel: PropTypes.bool,
    footerOverride: PropTypes.any, // If we want to display something in place of default buttons
    seperator: PropTypes.bool, // To add a 2px seperator between body and footer,
    footnote: PropTypes.node,
    disableSubmit: PropTypes.bool,
    hideFooter: PropTypes.bool
  }),
  leftSidePanel: PropTypes.node,
  handleOutsideClick: PropTypes.func,
  onClose: PropTypes.func
};

export default ModalContentTemplate;
