import { smartFormResponseType } from "./smartFormConstants";

/**
 *
 * Get matching name of specified value from list of options
 * @param {string | number} value
 * @param {Object} options
 */
export const getSingleSelectValueName = (value, options) =>
  options.find(option => option.value === value)?.name || value;

/**
 * get valid smart form number when user is answering, prevent invalid input key and remove spaces as well as commas
 * not only allowed valid number, but also some "ongoing" format, like "-" and "3."
 * for example if a user wants to enter 3.4, they have to enter "3." before "4"
 * @param {string} string
 * @return {string} longest valid string
 */
const getValidTypingSmartFormNumber = string => {
  const pattern = /^-?(\d+(\.\d*)?)?/g;
  const result = string.replaceAll(/[\s|,]+/g, "").match(pattern)?.[0] ?? "";
  return result;
};

/**
 * determine if the submitted value is a valid number
 * @param {string} string
 * @return {boolean}
 */
const isValidSmartFormNumber = string => {
  if (!string) {
    return true;
  }
  const pattern = /^-?\d+(\.\d+)?$/;
  return pattern.test(string);
};

const isNumberInRange = ({ value, min, max }) => {
  if (value === "") {
    return true;
  }

  const parsedNum = Number(value);

  if (isNaN(parsedNum)) {
    return false;
  }

  if (min === undefined && max === undefined) {
    return true;
  }

  if (min !== undefined && max !== undefined) {
    return parsedNum >= min && parsedNum <= max;
  }

  if (min !== undefined) {
    return parsedNum >= min;
  }

  if (max !== undefined) {
    return parsedNum <= max;
  }

  return false;
};

const getEntitiesWithNoAnswer = (
  entities,
  questionAnswer,
  currentIndex = null
) => {
  const data =
    currentIndex !== null
      ? questionAnswer.filter((_, index) => index !== currentIndex)
      : questionAnswer;

  const entitiesWithAnswer = data.flatMap(item =>
    item.entities.map(entity => +entity.value)
  );
  return entities.filter(entity => !entitiesWithAnswer.includes(+entity.value));
};

/**
 * Remove all line breaks in a text
 * @param {string} value
 * @return {string}
 */
const removeLineBreaks = value => value.replace(/(\r\n|\n|\r)/gm, "");

const getResponseTypeIcon = type => {
  switch (type) {
    case smartFormResponseType.NUMBER:
      return "numbers";
    case smartFormResponseType.TEXT:
      return "text_fields";
    case smartFormResponseType.DOCUMENT:
      return "description";
    case smartFormResponseType.WEBSHEET:
      return "view_module";
    case smartFormResponseType.DATE:
      return "date_range";
    case smartFormResponseType.BOOLEAN:
    case smartFormResponseType.SINGLE_CHOICE:
    default:
      return "format_list_bulleted";
  }
};

const getNumberPlaceholder = ({ min, max, t }) => {
  const minText = min
    ? t(
        "requests:requests.ui.smartForm.responseType.number.placeholderWithMin",
        { min }
      )
    : "";
  const maxText = max
    ? t(
        "requests:requests.ui.smartForm.responseType.number.placeholderWithMax",
        { max }
      )
    : "";
  return [minText, maxText].filter(Boolean).join(", ");
};

const getPlaceholder = ({ responseType, min, max, t }) => {
  const defaultPlaceholders = {
    [smartFormResponseType.TEXT]: t(
      "requests:requests.ui.smartForm.responseType.text.placeholder"
    ),
    [smartFormResponseType.NUMBER]: t(
      "requests:requests.ui.smartForm.responseType.number.placeholder"
    )
  };

  if (responseType === smartFormResponseType.NUMBER && (min || max)) {
    defaultPlaceholders[smartFormResponseType.NUMBER] = getNumberPlaceholder({
      min,
      max,
      t
    });
  }

  return defaultPlaceholders[responseType];
};

const formatRadioButtonKey = value => {
  if (value === "false") {
    return false;
  } else if (value === "true") {
    return true;
  }
  return value;
};

/**
 * check the smartform is single entity or not
 * @param {Object} relevantEntitiesById use entity id as the key of the object
 * @return {boolean}
 */
const isSingleEntity = relevantEntitiesById =>
  Object.keys(relevantEntitiesById).length === 1;

export const smartFormHelper = {
  isValidSmartFormNumber,
  formatRadioButtonKey,
  getValidTypingSmartFormNumber,
  getSingleSelectValueName,
  getEntitiesWithNoAnswer,
  getResponseTypeIcon,
  removeLineBreaks,
  isNumberInRange,
  getPlaceholder,
  isSingleEntity
};
