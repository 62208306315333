import React, { useCallback, useEffect } from "react";

import { get } from "lodash";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

import DatePicker from "@components/atoms/DatePicker";

function DateField(props) {
  const {
    formState: { errors },
    getValues,
    setValue,
    control
  } = useFormContext();

  useEffect(() => {
    const currentValue = getValues(props.name);
    if (!currentValue && props.defaultValue) {
      setValue(props.name, props.defaultValue);
    }
  }, [getValues, props.defaultValue, props.name, setValue]);

  const handleChangeDate = useCallback(
    (date, formOnChange) => {
      formOnChange(date);
      if (props.onChangeDate) {
        props.onChangeDate(date);
      }
    },
    [props]
  );

  return (
    <Controller
      control={control}
      name={props.name}
      rules={{ required: props.required }}
      render={({ field: { onChange, onBlur, ref } }) => {
        return (
          <DatePicker
            ref={ref}
            label={props.label}
            value={getValues(props.name) || ""}
            error={get(errors, props.name?.split("."))?.message ?? ""}
            required={props.required}
            disabled={props.disabled}
            minDate={props.minDate}
            maxDate={props.maxDate}
            onChange={t => handleChangeDate(t, onChange)}
            onBlur={onBlur}
          />
        );
      }}
    />
  );
}

DateField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.instanceOf(Date),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  onChangeDate: PropTypes.func
};

export default DateField;
