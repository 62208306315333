import React from "react";

import PropTypes from "prop-types";

import Icon from "../Icon";
import "./DropdownItem.scss";

const DropdownItem = props => {
  const { item, onClickSelection, id, isExpandable, isExpanded } = props;

  const handleClickSelection = e => {
    e.stopPropagation();
    onClickSelection(item);
  };

  return (
    <div
      className={`dropdown-item ${isExpanded ? "dropdown-item--active" : ""}`}
      onClick={handleClickSelection}
      id={id}
    >
      <span className="dropdown-item__label">{item.name}</span>
      {isExpandable && <Icon name="chevron_right" size="medium" />}
    </div>
  );
};

DropdownItem.propTypes = {
  item: PropTypes.object,
  onClickSelection: PropTypes.func,
  id: PropTypes.number,
  isExpandable: PropTypes.bool,
  isExpanded: PropTypes.bool
};

export default DropdownItem;
