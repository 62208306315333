import * as yup from "yup";

import { systemConstants } from "@shared/constants/systemConstants";

const queryTypeConstants = systemConstants.project.queries.queryTypes;

const defaultIndicators = {
  overdue: {
    key: "overdue",
    label: "Past Due Date",
    colorScheme: "error"
  },
  upcoming: {
    key: "upcoming",
    label: "Upcoming",
    colorScheme: "warning"
  },
  responded: {
    key: "responded",
    label: "Responded",
    colorScheme: "success"
  },
  closed: {
    key: "closed",
    label: "Closed",
    colorScheme: "dark"
  }
};

export const QUERY_TYPE_ICON = {
  CONVERSATION: "forum",
  WEBSHEET: "view_module",
  SMARTFORM: "fact_check"
};

export function getActionItemTypeDisplayName(
  useCustomName,
  queryName,
  i18nText
) {
  return useCustomName ? queryName : i18nText;
}

function formatQueryTypeOption(actionItemType, i18nText) {
  const actionItemConfiguration = {
    name: getActionItemTypeDisplayName(
      actionItemType.configuration.useCustomName,
      actionItemType.name,
      i18nText("requests:requests.configured.name", {
        context: actionItemType.configuration.key
      })
    ),
    key: actionItemType.configuration.key,
    id: actionItemType.configuration.key,
    value: actionItemType.configuration.key,
    type: actionItemType.configuration.type,
    fields: actionItemType.configuration.fields
  };

  if (actionItemType.configuration?.useCustomName) {
    actionItemConfiguration.useCustomName =
      actionItemType.configuration.useCustomName;
  }

  return actionItemConfiguration;
}

export const queryTypeDropdownOptions = ({
  actionItemTypes,
  t,
  excludeWebsheets,
  includeCopyOption
}) => {
  const dropdownOptions =
    actionItemTypes
      ?.filter(
        i =>
          !excludeWebsheets ||
          i.configuration.type === systemConstants.actionItemTypes.conversation
      )
      .map(a => formatQueryTypeOption(a, t))
      .map(dd => ({
        ...dd
      })) ?? [];
  if (includeCopyOption) {
    const copyOption = {
      name: t("requests:requests.ui.copyRequestsOptionLabel"),
      key: systemConstants.copyActionItemOption.key,
      id: systemConstants.copyActionItemOption.key,
      value: systemConstants.copyActionItemOption.key,
      type: "",
      fields: []
    };
    dropdownOptions.push(copyOption);
  }
  return dropdownOptions;
};

export function getSelectedQueryType(queryType, actionItemTypes) {
  const selectedQueryType = {
    isQueryList: queryType.key == queryTypeConstants.queryList,
    isConfigType: queryType.key !== queryTypeConstants.queryList,
    key: queryType.key,
    actionItemType: actionItemTypes?.find(
      actionItemType => actionItemType.configuration.key == queryType.key
    )?.configuration?.type
  };
  return selectedQueryType;
}

export function getQueryFields(key, actionItemTypes) {
  const activeItemType = actionItemTypes.find(
    actionItemType => actionItemType.configuration.key == key
  );

  return activeItemType?.configuration?.fields ?? [];
}

export function getActionItemTypeConfiguration(key, actionItemTypes) {
  return actionItemTypes?.find(
    actionItemType => actionItemType.configuration.key === key
  )?.configuration;
}

export function isEditAvailable(query, actionItemTypes) {
  const buttons = getWebsheetButtons(query?.queryType, actionItemTypes);
  const edit = buttons.find(({ action }) => action === "EDIT");
  if (edit) {
    // statuses could be ["STATUS"], "STATUS" or empty
    const statuses = edit.from;
    // TODO: ONE-5149 will fix the root cause in BE, now it is a workaround. Correct code should be if (!statuses || statuses === query.status)
    if (
      !statuses ||
      statuses === query.status ||
      (statuses === systemConstants.project.queries.status.open &&
        query.status === systemConstants.project.queries.status.responded)
    ) {
      return true;
    }
    return (
      Array.isArray(statuses) &&
      statuses.some(status => status === query.status)
    );
  }
}

export function getWebsheetButtons(key, actionItemTypes) {
  const configuration = getActionItemTypeConfiguration(key, actionItemTypes);

  // prefer SMARTFORM_BUTTONS or WEBSHEET_BUTTONS over BUTTONS
  const typeMenu = configuration?.menus?.find(
    menu => menu.name === `${configuration.type}_BUTTONS`
  );
  if (typeMenu) {
    return typeMenu.actions;
  }
  const menu = configuration?.menus?.find(menu => menu.name === "BUTTONS");
  if (menu) {
    return menu.actions;
  }
  return [];
}

export function getQueryTypeDropdownOptionByKey(
  queryTypeKey,
  queryTypes,
  i18nText
) {
  if (!queryTypeKey) {
    return undefined;
  }

  const queryType = queryTypes
    .filter(qt => qt.key === queryTypeKey)
    .map(qt => ({
      name: i18nText("requests:requests.configured.name", { context: qt.key }),
      value: qt
    }));
  if (queryType && queryType.length > 0) {
    return queryType[0];
  }

  return undefined;
}

export function getQueryActions(queryType, actionItemTypes) {
  if (actionItemTypes) {
    const actionItemType = actionItemTypes.find(
      at => at.configuration.key == queryType
    );
    if (actionItemType) {
      return actionItemType.configuration?.menus?.find(
        item => item?.name == systemConstants.project.queries.menu.actionItem
      )?.actions;
    }
  }
}

export function expectedQueryTypes(actionItemTypes, i18nText) {
  const allQueryTypes = (actionItemTypes ?? []).map(a =>
    formatQueryTypeOption(a, i18nText)
  );
  const allQueryTypeKeys = allQueryTypes.map(qt => qt.key);
  return {
    queryTypes: allQueryTypes,
    allQueryTypeKeys
  };
}

export function getQuestionConfig(smartFormConfig, questionId) {
  return (
    smartFormConfig?.questionList?.find(q => q.questionId === +questionId) ?? {}
  );
}

export function formatQueryType(queryType, allQueryTypes, i18nText) {
  const actionItemType = allQueryTypes.find(type => type.key === queryType);

  return getActionItemTypeDisplayName(
    actionItemType.useCustomName,
    actionItemType.name,
    i18nText("requests:requests.configured.name", {
      context: actionItemType?.key
    })
  );
}

export function expectedActionIndicators() {
  return defaultIndicators;
}

export function getActionIndicator(
  { status, requiredBy },
  referenceDate,
  indicatorUiConfig
) {
  const indicators = indicatorUiConfig ?? defaultIndicators;
  const [statusKey] = Object.entries(
    systemConstants.project.queries.status
  ).find(([_, value]) => value === status);
  if (isClosed({ status })) {
    return { ...indicators.closed, status: statusKey };
  }
  if (referenceDate && isOverdue({ requiredBy }, referenceDate)) {
    return { ...indicators.overdue, status: statusKey };
  }
  return { ...indicators.upcoming, status: statusKey };
}

export function isOverdue({ requiredBy }, referenceDate) {
  if (!requiredBy) {
    return false;
  }
  const dueDate = new Date(requiredBy);
  dueDate.setHours(0, 0, 0, 0);
  referenceDate.setHours(0, 0, 0, 0);
  return dueDate < referenceDate;
}

export function isNote(status) {
  return status == systemConstants.project.queries.status.note;
}

export function isClosed({ status }) {
  return status === systemConstants.project.queries.status.closed;
}

export function isActive({ status }) {
  const { open, responded } = systemConstants.project.queries.status;
  return status === open || status === responded;
}

export function validateQuery(query, i18nText, i18nContext) {
  if (query.queryType?.fields) {
    const { validations: fileValidations } = query.queryType.fields.find(
      f => f.type == "file"
    ) ?? { validations: [] };
    const countValidation = fileValidations.find(fv => fv.name == "count");
    if (
      countValidation &&
      (countValidation.value
        ? Object.keys(query.files).length !== countValidation.value
        : Object.keys(query.files).length === 0)
    ) {
      return {
        success: false,
        error: i18nText(
          "requests:requests.configured.fields.files.validation.count.errorMessage",
          { context: i18nContext }
        )
      };
    } else {
      return {
        success: true
      };
    }
  }
  return {
    success: true
  };
}

export function getQueryValidationSchema({
  configFields,
  additionalFields,
  i18nText
}) {
  const messageStrings = {
    requiredError: configFields
      ?.map(({ key }) => {
        switch (key) {
          case "queryType":
            return {
              [key]: i18nText(
                "requests:requests.ui.populateRequestForm.validation.fieldRequired.errorMessage",
                { context: "queryType" }
              )
            };
          case "assignedTo":
            return {
              [key]: i18nText(
                "requests:requests.configured.fields.assignedTo.validation.required.errorMessage"
              )
            };
          case "description":
            return {
              [key]: i18nText(
                "requests:requests.configured.fields.description.validation.required.errorMessage"
              )
            };
          case "query":
            return {
              [key]: i18nText(
                "requests:requests.configured.fields.query.validation.required.errorMessage"
              )
            };
          case "entities":
            return {
              [key]: i18nText(
                "requests:requests.configured.fields.entities.validation.required.errorMessage"
              )
            };
          case "overrideMessage":
            return {
              [key]: i18nText(
                "requests:requests.ui.populateRequestForm.validation.fieldRequired.errorMessage",
                { context: "overrideMessage" }
              )
            };
        }
      })
      .reduce((acc, obj) => ({ ...acc, ...obj }), {})
  };

  function getRequiredErrorMessageString(key) {
    return (
      messageStrings.requiredError?.[key] ??
      i18nText(
        "requests:requests.ui.populateRequestForm.validation.fieldRequired.errorMessage"
      )
    );
  }

  // Schema validates according to the queryType's configuration details
  const schema = {};

  (configFields || []).forEach(f => {
    let base = null;
    switch (f.type) {
      case "user":
        base = yup.object();
        break;
      case "text":
      case "textarea": {
        base = yup.string();
        break;
      }
    }
    if (base) {
      if (f.required) {
        schema[f.key] = base.required(getRequiredErrorMessageString(f.key));
      } else {
        schema[f.key] = base;
      }
    } else if (f.required) {
      schema[f.key] = yup
        .mixed()
        .required(getRequiredErrorMessageString(f.key));
    }
  });

  Object.entries(additionalFields ?? {}).forEach(
    ([key, { type, required }]) => {
      let base;
      switch (type) {
        case "string":
          base = yup.string();
          break;
        case "object":
          base = yup.object();
          break;
      }
      if (base) {
        if (required) {
          schema[key] = base.required(getRequiredErrorMessageString(key));
        } else {
          schema[key] = base;
        }
      } else if (required) {
        schema[key] = yup.mixed().required(getRequiredErrorMessageString(key));
      }
    }
  );

  return yup.object(schema);
}
