import React from "react";

import ErrorBox from "@shared/components/errorBox/ErrorBox";

import "./PageFormTemplate.scss";

const PageFormTemplate = ({ children, errorMessage }) => {
  return (
    <div className="page-form-template">
      {errorMessage && (
        <div className="page-form-template__error-box">
          <ErrorBox message={errorMessage} />
        </div>
      )}
      <div className="page-form-template__container">{children}</div>
    </div>
  );
};

export default PageFormTemplate;
