import React, { useCallback, useEffect, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants/systemConstants";
import { useToasts } from "@shared/hooks/useToasts";

import { handleFreezeScrollPosition } from "@app/helpers/componentHelpers";

import ModalFormWithSteps from "@components/molecules/ModalFormWithSteps/ModalFormWithSteps";
import SelectAddActionItemType from "@components/organisms/SelectAddActionItemType";

import { useAddActionFormStep } from "./useAddActionFormStep";
import { useGetCopySteps } from "./useGetCopySteps";

function AddOrCopyProjectActionItems(props) {
  const {
    project,
    viewScope,
    modalClassName,
    source,
    isSlidingForm,
    disableCopy,
    enableWebSheet,
    onCancel,
    onSubmitted
  } = props;
  const { t } = useTranslation();
  const { showActionItemCreatedToast, showActionItemsCopiedToast } =
    useToasts();
  const [selectedQueryType, setSelectedQueryType] = useState();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [show, setShow] = useState(true);

  const handleSubmitted = useCallback(
    addedActionItem => {
      setShow(false);
      onSubmitted?.(addedActionItem);
      if (
        selectedQueryType?.queryType?.key &&
        selectedQueryType.queryType.key !==
          systemConstants.copyActionItemOption.key
      ) {
        showActionItemCreatedToast({
          project,
          request: { id: addedActionItem?.queryId },
          actionItemType: selectedQueryType?.queryType?.actionItemType
        });
      } else {
        showActionItemsCopiedToast();
      }
    },
    [
      onSubmitted,
      project,
      selectedQueryType?.queryType?.actionItemType,
      selectedQueryType?.queryType?.key,
      showActionItemCreatedToast,
      showActionItemsCopiedToast
    ]
  );

  const handleCancel = useCallback(() => {
    setShow(false);
    handleFreezeScrollPosition(false);
    onCancel();
  }, [onCancel]);

  useEffect(() => {
    setShow(true);
  }, []);

  const addForm = useAddActionFormStep({
    project,
    selectedQueryType,
    source,
    handleSubmitted
  });
  const getAddForm = useCallback(() => [addForm], [addForm]);

  const getFormSteps = () =>
    !disableCopy &&
    selectedQueryType?.queryType?.key ===
      systemConstants.copyActionItemOption.key
      ? getCopyFormSteps()
      : getAddForm();

  const handleQueryTypeChanged = useCallback(queryType => {
    setCurrentStepIndex(0);
    setSelectedQueryType(queryType);
  }, []);

  const onNext = useCallback(() => {
    setCurrentStepIndex(prevState => ++prevState);
  }, []);

  const steps = useGetCopySteps({
    project,
    handleSubmitted,
    onNext
  });
  const getCopyFormSteps = () => steps;

  return (
    <ModalFormWithSteps
      show={show}
      isSlidingForm={isSlidingForm}
      title={t("requests:requests.ui.populateRequestForm.titleCreateRequest", {
        requestLongNameSingular: t("requests:requests.longName")
      })}
      steps={getFormSteps()}
      currentStepIndex={currentStepIndex}
      modalClassName={modalClassName}
      onNext={onNext}
      onCancel={handleCancel}
    >
      {currentStepIndex === 0 && (
        <SelectAddActionItemType
          engagementTypeId={project?.engagement?.engagementTypeId}
          projectId={project?.id}
          onChangeSelectQueryType={handleQueryTypeChanged}
          viewScope={viewScope}
          enableWebSheet={enableWebSheet}
          enableCopyOption={!disableCopy && isSlidingForm} //Copy only supported in sliding form
        />
      )}
    </ModalFormWithSteps>
  );
}

AddOrCopyProjectActionItems.defaultProps = {
  disableCopy: false,
  isSlidingForm: true
};

AddOrCopyProjectActionItems.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    engagement: PropTypes.shape({
      engagementTypeId: PropTypes.number
    })
  }),
  viewScope: PropTypes.oneOf(Object.values(systemConstants.viewScopeType)),
  isSlidingForm: PropTypes.bool,
  enableWebSheet: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func.isRequired
};

export default AddOrCopyProjectActionItems;
