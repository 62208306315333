import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import useOnClickOutside from "use-onclickoutside";

import "./dropdownOptions.scss";

const DropdownOptions = props => {
  const [list, setList] = useState(props.options);
  const [options, setOptions] = useState({ name: props.value });
  const [showOptions, setShowOptions] = useState(false);
  const dropdownOptionRef = useRef(null);

  useEffect(() => {
    setList(props.options);
  }, [props.options]);

  useEffect(() => {
    setOptions({ name: props.value });
  }, [props.value]);

  useEffect(() => {
    if (props.reset) {
      setOptions({ name: "" });
    }
  }, [props.reset]);

  useOnClickOutside(dropdownOptionRef, () => {
    setShowOptions(false);
  });

  const handleSelection = (event, item) => {
    event.stopPropagation();
    setShowOptions(false);
    if (!props.keepInputEmpty) {
      setOptions(item);
    }
    props.handleSelection(item);
  };

  const handleClick = () => {
    if (props.disabled) {
      if (showOptions) setShowOptions(false);
      return;
    }
    setShowOptions(!showOptions);
  };

  const getOptions = item => {
    return (
      <div
        key={`${item.id}${item.name}`}
        className="option-container__options option-container__options--list"
        onClick={event => handleSelection(event, item)}
      >
        <span className="option-container__options--list-text">
          {item.name}
        </span>
      </div>
    );
  };

  return (
    <div ref={dropdownOptionRef} className="option-container">
      <div
        className={
          "option-container__options" +
          (props.error ? " option-container__options__error" : "") +
          (props.disabled || (!list.length && props.disableIfEmpty)
            ? " option-container__options__disable"
            : "") +
          (props.customClass ? ` ${props.customClass}` : "")
        }
        onClick={handleClick}
        tabIndex="0"
      >
        <span className="option-container__options--text">
          {options.name}
          <span className="option-container__options--placeholder">
            {options.name ? "" : props.placeholder}
          </span>
        </span>
        <i className="material-icons option-container__options--icon">
          {showOptions && list.length
            ? "keyboard_arrow_up"
            : "keyboard_arrow_down"}
        </i>
      </div>
      <div
        className={
          "option-container--suggestion" +
          (!showOptions ? " option-container--suggestion--hide" : "")
        }
      >
        {list.map(item => getOptions(item))}
      </div>
    </div>
  );
};

DropdownOptions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.any.isRequired
    })
  ),
  value: PropTypes.string.isRequired
};

export default DropdownOptions;
