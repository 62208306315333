import React, { useCallback } from "react";

import { useNavigate } from "react-router-dom";

/**
 * Hook to go back to the previous page.
 * @param {object} props
 * @param {string} [props.defaultPath="/"] - The default path to go back to.
 * @returns {function} - The function to go back to the previous page.
 */
export const useGoBack = (props = {}) => {
  const navigate = useNavigate();
  const previousPath = JSON.parse(
    sessionStorage.getItem("previousPath") ?? "{}"
  );
  const defaultPrevPath = props?.defaultPath ?? `/`;
  const goBack = useCallback(
    () => navigate(previousPath?.pathname ?? defaultPrevPath),
    [defaultPrevPath, navigate, previousPath?.pathname]
  );
  return goBack;
};
