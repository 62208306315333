import { authConstants } from "../constants";
import { authService, eventsService } from "../services";

export const authActions = {
  login,
  loginWithExternalAuth,
  logout,
  verify,
  clearError,
  forgotPassword,
  resetPassword,
  verifyResetPasswordToken,
  resetForgotPassword,
  resetMFAActivated,
  getQRCode,
  activateMFA,
  disableMFA,
  verifyMFAToken,
  emailMFAVerificationCode,
  resetMFATokenVerification,
  resetEmailSentForMFADisabling,
  resetMFADisabled,
  resetResetPasswortTokenVerification,
  resetExternalLoginFailed,
  getHostWithBrandDetails,
  resetHostWithBrandDetails,
  resetLastKnownPath
};

function getHostWithBrandDetails() {
  return dispatch => {
    dispatch(request());

    authService.getHostWithBrandDetails().then(
      hostWithBrandDetails => {
        dispatch(success(hostWithBrandDetails));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: authConstants.GET_HOST_WITH_BRAND_DETAILS_REQUEST };
  }
  function success(hostWithBrandDetails) {
    return {
      type: authConstants.GET_HOST_WITH_BRAND_DETAILS_SUCCESS,
      hostWithBrandDetails
    };
  }
  function failure(error) {
    return { type: authConstants.GET_HOST_WITH_BRAND_DETAILS_FAILURE, error };
  }
}

function getQRCode(user) {
  return dispatch => {
    dispatch(request());

    authService.getQRCode(user).then(
      qrCode => {
        dispatch(success(qrCode));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: authConstants.GET_QR_CODE_REQUEST };
  }
  function success(qrCode) {
    return { type: authConstants.GET_QR_CODE_SUCCESS, qrCode };
  }
  function failure(error) {
    return { type: authConstants.GET_QR_CODE_FAILURE, error };
  }
}

function disableMFA(user, mfaObject) {
  return dispatch => {
    dispatch(request());

    authService.disableMFA(user, mfaObject).then(
      disabledObject => {
        dispatch(success(disabledObject));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: authConstants.DISABLE_MFA_REQUEST };
  }
  function success(disabledObject) {
    return { type: authConstants.DISABLE_MFA_SUCCESS, disabledObject };
  }
  function failure(error) {
    return { type: authConstants.DISABLE_MFA_FAILURE, error };
  }
}

function emailMFAVerificationCode(user, mfaObject) {
  return dispatch => {
    dispatch(request());

    authService.emailMFAVerificationCode(user, mfaObject).then(
      emailSentObject => {
        dispatch(success(emailSentObject));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: authConstants.EMAIL_MFA_VERIFICATION_TOKEN_REQUEST };
  }
  function success(emailSentObject) {
    return {
      type: authConstants.EMAIL_MFA_VERIFICATION_TOKEN_SUCCESS,
      emailSentObject
    };
  }
  function failure(error) {
    return { type: authConstants.EMAIL_MFA_VERIFICATION_TOKEN_FAILURE, error };
  }
}

function activateMFA(user, mfaObject) {
  return dispatch => {
    dispatch(request());

    authService.activateMFA(user, mfaObject).then(
      activated => {
        dispatch(success(activated));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: authConstants.ACTIVATE_MFA_TOKEN_REQUEST };
  }
  function success(activated) {
    return { type: authConstants.ACTIVATE_MFA_TOKEN_SUCCESS, activated };
  }
  function failure(error) {
    return { type: authConstants.ACTIVATE_MFA_TOKEN_FAILURE, error };
  }
}

function verifyMFAToken(user, token) {
  return dispatch => {
    dispatch(request());

    authService.verifyMFAToken(user, token).then(
      verified => {
        dispatch(success(verified));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: authConstants.VERIFY_MFA_TOKEN_REQUEST };
  }
  function success(verified) {
    return { type: authConstants.VERIFY_MFA_TOKEN_SUCCESS, verified };
  }
  function failure(error) {
    return { type: authConstants.VERIFY_MFA_TOKEN_FAILURE, error };
  }
}

function login(email, password) {
  return dispatch => {
    dispatch(request({ email, password }));

    authService.login(email, password).then(
      ({ user }) => {
        if (user.hostId || user.clientId) {
          dispatch(success(user));
        } else {
          dispatch(failure({ message: "Invalid email or password" }));
        }
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(user) {
    return { type: authConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: authConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: authConstants.LOGIN_FAILURE, error };
  }
}

function loginWithExternalAuth({ authObject }) {
  return dispatch => {
    dispatch(request());

    authService.loginWithExternalAuth({ authObject }).then(
      ({ user }) => {
        if (user.hostId || user.clientId) {
          dispatch(success(user));
        }
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: authConstants.LOGIN_WITH_EXTENAL_AUTH_REQUEST };
  }
  function success(user) {
    return { type: authConstants.LOGIN_WITH_EXTENAL_AUTH_SUCCESS, user };
  }
  function failure(error) {
    return { type: authConstants.LOGIN_WITH_EXTENAL_AUTH_FAILURE, error };
  }
}

function forgotPassword(email) {
  return dispatch => {
    dispatch(request({ email }));

    authService.forgotPassword(email).then(
      responseWithMessage => {
        if (responseWithMessage) {
          dispatch(success(responseWithMessage));
        }
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(user) {
    return { type: authConstants.FORGOT_PASSWORD_REQUEST, user };
  }
  function success(user) {
    return { type: authConstants.FORGOT_PASSWORD_SUCCESS, user };
  }
  function failure(error) {
    return { type: authConstants.FORGOT_PASSWORD_FAILURE, error };
  }
}

function verifyResetPasswordToken({ id, passwordToken }) {
  return dispatch => {
    dispatch(request());
    authService
      .verifyResetPasswordToken({ id, passwordToken })
      .then(res => dispatch(success(res.verified)))
      .catch(error => failure(error));
  };
  function request() {
    return { type: authConstants.VERIFY_RESET_PASSWORD_TOKEN_REQUEST };
  }
  function success(verified) {
    return {
      type: authConstants.VERIFY_RESET_PASSWORD_TOKEN_SUCCESS,
      verified
    };
  }
  function failure(error) {
    return { type: authConstants.VERIFY_RESET_PASSWORD_TOKEN_FAILURE, error };
  }
}

function resetPassword({ id, password, passwordToken }) {
  return dispatch => {
    dispatch(request({ id, password, passwordToken }));

    authService.resetPassword({ id, password, passwordToken }).then(
      responseWithMessage => {
        if (responseWithMessage) {
          dispatch(success(responseWithMessage));
        }
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(user) {
    return { type: authConstants.RESET_PASSWORD_REQUEST, user };
  }
  function success(user) {
    return { type: authConstants.RESET_PASSWORD_SUCCESS, user };
  }
  function failure(error) {
    return { type: authConstants.RESET_PASSWORD_FAILURE, error };
  }
}

function logout() {
  eventsService.track({ name: "LOGOUT" });
  authService.logout();
  return { type: authConstants.LOGOUT };
}

function verify() {
  return dispatch => {
    dispatch(request());

    authService.verify().then(
      res => {
        dispatch(success(res));
      },
      error => {
        dispatch(logout());
      }
    );
  };

  function request() {
    return { type: authConstants.TOKEN_VERIFY_REQUEST };
  }
  function success(res) {
    return { type: authConstants.TOKEN_VERIFY_SUCCESS, res };
  }
}

function clearError() {
  return { type: authConstants.CLEAR_ERROR };
}

function resetForgotPassword() {
  return { type: authConstants.RESET_FORGOT_PASSWORD };
}

function resetMFAActivated() {
  return { type: authConstants.RESET_MFA_ACTIVATED };
}

function resetMFATokenVerification() {
  return { type: authConstants.RESET_MFA_TOKEN_VERIFICATION };
}

function resetMFADisabled() {
  return { type: authConstants.RESET_MFA_DISABLED };
}

function resetEmailSentForMFADisabling() {
  return { type: authConstants.RESET_EMAIL_SENT_FOR_MFA_VERIFICATION_TOKEN };
}

function resetResetPasswortTokenVerification() {
  return { type: authConstants.RESET_RESET_PASSWORD_TOKEN_VERIFICATION };
}

function resetExternalLoginFailed() {
  return { type: authConstants.RESET_EXTERNAL_LOGIN_FAILED };
}

function resetHostWithBrandDetails() {
  return { type: authConstants.RESET_HOST };
}

function resetLastKnownPath() {
  return { type: authConstants.RESET_LAST_KNOWN_PATH };
}
