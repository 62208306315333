import React, { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { routeConstants } from "@constants/routeConstants";

import { systemConstants } from "@shared/constants";
import { utilities } from "@shared/helpers/utilities";
import {
  useAuthUser,
  useGetClientEngagementsQuery,
  useWindowSize
} from "@shared/hooks";
import { useFeatures } from "@shared/hooks";

import ErrorBox from "@shared-components/errorBox/ErrorBox";

import ManageClientEngagementProjects from "@app/pages/ManageClientEngagementProjects";
import ManageEngagementUsers from "@app/pages/ManageEngagementUsers";

import Loading from "@components/molecules/Loading";
import MainPageTemplate from "@components/templates/MainPageTemplate";
import PageBodyTemplate from "@components/templates/PageBodyTemplate";

import "./ManageEngagementDashboard.scss";

const ManageEngagementDashboard = props => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const [mobile, setMobile] = useState(
    windowSize.width <= systemConstants.mediaBreakpoints.tabPort
  );
  const { user } = useAuthUser();
  const { engagementId } = useParams();
  const { data: engagements, error } = useGetClientEngagementsQuery({
    hostId: user.hostId,
    engagementId
  });
  const manageClientEngagements = useSelector(
    state => state.manageClientEngagements
  );
  const manageClientEngagementProjects = useSelector(
    state => state.manageClientEngagementProjects
  );

  const { isEnabled: isFeatureEnabled, isLoading: isFeaturesLoading } =
    useFeatures();

  const engagement = useMemo(() => engagements?.[0], [engagements]);

  useEffect(() => {
    if (error) {
      navigate(routeConstants.notFound);
    }
  }, [error, navigate]);

  const [tabSelection, setTabSelection] = useState([
    {
      i18nKey: "common:ui.engagement.tab.projects",
      key: "Projects",
      isSelected: true
    },
    {
      i18nKey: "common:ui.engagement.tab.users",
      key: "Users",
      isSelected: false
    }
  ]);
  const [maxEngagementNameWidth, setMaxEngagementNameWidth] = useState(
    utilities.calmpViewWidth(60, 1, utilities.convertRemToVW(135))
  );

  useEffect(() => {
    setMobile(windowSize.width <= systemConstants.mediaBreakpoints.tabPort);
    setMaxEngagementNameWidth(
      utilities.calmpViewWidth(60, 1, utilities.convertRemToVW(135))
    );
  }, [windowSize.width]);

  useEffect(() => {
    const isEngagementsEnabled = isFeatureEnabled(
      systemConstants.features.engagements
    );
    if (!isFeaturesLoading && !isEngagementsEnabled) {
      navigate(routeConstants.notFound);
    }
  }, [isFeatureEnabled, isFeaturesLoading, navigate]);

  const handleTabClick = selectedTab => {
    const updatedTabSelection = tabSelection.map(tab => {
      if (tab.key === selectedTab.key) {
        tab.isSelected = true;
      } else {
        tab.isSelected = false;
      }
      return tab;
    });
    setTabSelection(updatedTabSelection);
  };

  const getTabContent = () => {
    if (isFeaturesLoading) {
      return <Loading message={t("common:loading")} />;
    }
    const currentTab = tabSelection.find(tab => tab.isSelected);
    if (currentTab.key === "Projects" && engagement) {
      return <ManageClientEngagementProjects engagement={engagement} />;
    } else if (currentTab.key === "Users" && engagement) {
      return <ManageEngagementUsers engagement={engagement} />;
    }
  };

  return (
    <MainPageTemplate>
      <PageBodyTemplate
        title={t("common:ui.engagement.navigation.title", {
          context: "selected",
          name: engagement?.name
        })}
      >
        <div className="client-engagement-dashboard__container">
          <div className="client-engagement-dashboard__container-top">
            {(manageClientEngagementProjects.error ||
              manageClientEngagements.error) && (
              <div className="client-user-manager__error-box">
                <ErrorBox
                  message={
                    manageClientEngagementProjects.error ||
                    manageClientEngagements.error
                  }
                />
              </div>
            )}

            <div className="client-engagement-dashboard__tabs">
              {tabSelection &&
                tabSelection.map((tab, index) => (
                  <div
                    onClick={() => handleTabClick(tab)}
                    className={
                      "client-engagement-dashboard__tabs-name" +
                      (tab.isSelected
                        ? " client-engagement-dashboard__tabs-name-selected"
                        : "")
                    }
                    key={index}
                  >
                    {mobile && (
                      <i className="material-icons client-engagement-dashboard__nav-menu-item-icon">
                        lens
                      </i>
                    )}
                    <div className="client-engagement-dashboard__tabs-tab-name">
                      {t(tab.i18nKey)}
                    </div>
                  </div>
                ))}
            </div>
          </div>

          {getTabContent()}
        </div>
      </PageBodyTemplate>
    </MainPageTemplate>
  );
};

export default ManageEngagementDashboard;
