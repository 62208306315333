import React from "react";

import websheetUtilities from "@shared/helpers/websheetUtilities";

import Dropdown from "@components/atoms/Dropdown";

import "./WebsheetTabsDropdown.scss";

const WebsheetTabsDropdown = ({
  sheetNames,
  handleClickTab,
  currentSheetName
}) => {
  const getIcon = (name, currentSheetName) => {
    if (currentSheetName === name) {
      return { iconName: "check", hover: false };
    }
    return {};
  };
  const items = sheetNames.map((name, index) => {
    const item = {
      id: name,
      name,
      icon: getIcon(name, currentSheetName)
    };
    if (currentSheetName === name) {
      item.active = true;
    }
    return item;
  });

  return (
    <div className={"websheet-tabs-dropdown"}>
      <Dropdown
        leftIcon="menu"
        handleClickSelection={handleClickTab}
        dropdownItems={items}
        getItemId={websheetUtilities.getWebsheetDropdownItemId}
        position={"top"}
        name={""}
        capacity={Math.min(items.length, 8)}
      />
    </div>
  );
};

export default WebsheetTabsDropdown;
