import React from "react";

import Icon from "@components/atoms/Icon";

import "./DropdownItem.scss";

const DropdownItem = props => {
  const { item, onClickSelection, icon, active, id } = props;

  const handleClickSelection = e => {
    e.stopPropagation();
    onClickSelection(item);
  };
  return (
    <span
      id={id}
      className={`dropdownItem ${active ? "dropdownItem--active" : ""}`}
      onClick={e => handleClickSelection(e)}
    >
      {icon && <Icon name={icon} />}
      <span className={"dropdownItem__label"}>{item.name}</span>
    </span>
  );
};

export default DropdownItem;
