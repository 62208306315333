import { systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { handleErrorResponse } from "./";

const baseURL = systemConstants.serverURL;

export const manageDocumentDownloadsService = {
  getClientDocument,
  getProjectDocument,
  downloadClientDocumentRevision,
  downloadDocumentRevision,
  downloadCurrentDocument,
  downloadBlob
};

function downloadCurrentDocument({ id, name }) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(`${baseURL}/api/documents/${id}/download`, requestOptions)
    .then(async response => {
      if (!response.ok) {
        const text = await response.text();
        const data = text && JSON.parse(text);
        const error = {
          message:
            systemConstants.support.message ||
            "An unexpected error occurred. Please try again, and if the problem persists contact support"
        };

        if (data && response.status !== 500) {
          error.message = data.message;
        }

        return Promise.reject(error);
      }
      return {
        id: id,
        blob: await response.blob()
      };
    })
    .then(document => {
      document.objectURL = URL.createObjectURL(document.blob);
      downloadBlob(name, document.objectURL);
      return document;
    })
    .catch(handleErrorResponse);
}

function downloadDocumentRevision({
  id,
  name,
  documentRevisionId,
  forceLatest,
  downloadName
}) {
  return getProjectDocument({
    id,
    name,
    documentRevisionId,
    forceLatest
  })
    .then(document => {
      downloadBlob(downloadName ?? document.downloadName, document.objectURL);
      return document;
    })
    .catch(handleErrorResponse);
}

function getProjectDocument(document) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  const query = `?${document.forceLatest ? "latestIfAvailable=true" : ""}`;
  return fetch(
    `${baseURL}/api/documents/${document.id}/revision/${
      document.documentRevisionId ?? 0
    }${query}`,
    requestOptions
  )
    .then(async response => {
      if (!response.ok) {
        const text = await response.text();
        const data = text && JSON.parse(text);
        const error = {
          message:
            systemConstants.support.message ||
            "An unexpected error occurred. Please try again, and if the problem persists contact support"
        };

        if (data && response.status !== 500) {
          error.message = data.message;
        }

        return Promise.reject(error);
      }
      const documentProperties = response.url
        .match(/documents\/\d+\/revision\/\d+/)[0]
        .match(/\d+/g);
      return {
        id: +documentProperties[0],
        documentRevisionId: +documentProperties[1],
        blob: await response.blob()
      };
    })
    .then(documentResult => {
      documentResult.objectURL = URL.createObjectURL(documentResult.blob);
      documentResult.downloadName = document.name;
      return documentResult;
    })
    .catch(handleErrorResponse);
}

function downloadClientDocumentRevision({
  id,
  clientId,
  name,
  documentRevisionId
}) {
  return getClientDocument({
    id,
    clientId,
    name,
    documentRevisionId
  })
    .then(document => {
      downloadBlob(document.downloadName, document.objectURL);
      return document;
    })
    .catch(handleErrorResponse);
}

function getClientDocument(document) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/clients/${document.clientId}/documents/${document.id}/revision/${document.documentRevisionId}`,
    requestOptions
  )
    .then(async response => {
      if (!response.ok) {
        const text = await response.text();
        const data = text && JSON.parse(text);
        const error = {
          message:
            systemConstants.support.message ||
            "An unexpected error occurred. Please try again, and if the problem persists contact support"
        };

        if (data && response.status !== 500) {
          error.message = data.message;
        }

        return Promise.reject(error);
      }
      const documentProperties = response.url
        .match(/documents\/\d+\/revision\/\d+/)[0]
        .match(/\d+/g);
      return {
        id: +documentProperties[0],
        documentRevisionId: +documentProperties[1],
        blob: await response.blob()
      };
    })
    .then(documentResult => {
      documentResult.objectURL = URL.createObjectURL(documentResult.blob);
      documentResult.downloadName = document.name;
      return documentResult;
    })
    .catch(handleErrorResponse);
}

function downloadBlob(filename, url) {
  var element = document.createElement("a");
  element.setAttribute("href", url);
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
