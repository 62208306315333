import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import BrandButton from "@components/atoms/Button/BrandButton";
import ModalTemplate from "@components/templates/ModalTemplate/ModalTemplate";

import DocumentRevisionDataTable from "./DocumentRevisionDataTable";
import "./DocumentRevisionHistory.scss";

const revisionOf = {
  clientProject: "projectDocument"
};

const DocumentRevisionHistory = props => {
  const {
    document,
    fetchDocumentRevisions,
    updateDocumentRevisionToActive,
    revisions,
    activeRevisionId,
    setActiveRevisionId,
    fetchDocument,
    downloadedDocuments,
    canSelectActive,
    t
  } = props;
  const downloadRef = useRef(null);
  const [downloadItem, setDownloadItem] = useState(document);
  const [downloadFile, setDownloadFile] = useState(null);
  const [downloadClicked, setDownloadClicked] = useState(false);

  useEffect(() => {
    if (props.revisionOf === revisionOf.clientProject) {
      fetchDocumentRevisions();
    }
  }, [props.revisionOf, fetchDocumentRevisions]);

  const handleRestoreClick = () => {
    if (props.revisionOf !== revisionOf.clientProject) {
      return;
    }
    if (document.documentRevisionId === activeRevisionId) {
      return;
    }

    updateDocumentRevisionToActive();
    props.onSubmit();
  };

  const selectRevision = item => {
    setActiveRevisionId(item.id);
  };

  useEffect(() => {
    if (downloadedDocuments?.length === 0) {
      return;
    }

    downloadedDocuments.forEach(doc => {
      if (
        doc.id === downloadItem.id &&
        doc.documentRevisionId === downloadItem.documentRevisionId
      ) {
        setDownloadFile({
          downloadName: doc.downloadName,
          file: doc.objectURL
        });
      }
    });
  }, [downloadedDocuments, downloadItem]);

  useEffect(() => {
    if (downloadItem && downloadFile?.file && downloadClicked) {
      downloadRef.current.click();
      setDownloadClicked(false);
    }
  }, [downloadItem, downloadFile, downloadClicked]);

  const handleDownload = item => {
    if (props.revisionOf === revisionOf.clientProject) {
      const downloadDoc = {
        ...document,
        documentRevisionId: item.id,
        downloadName: item.name,
        name: item.name,
        file: null
      };
      setDownloadClicked(true);
      setDownloadItem(downloadDoc);
      setDownloadFile(null);
      fetchDocument(downloadDoc);
    }
  };

  const renderFooter = () => {
    if (!canSelectActive) {
      return null;
    }
    return (
      <>
        <BrandButton
          type="secondary"
          label={t("common:ui.forms.cancel.label")}
          onClick={props.onCancel}
        />
        <BrandButton
          type="primary"
          label={t("common:documents.revision.saveActiveRevision")}
          onClick={handleRestoreClick}
        />
      </>
    );
  };

  return (
    <ModalTemplate
      boxClassName="document-revision-history"
      title={t("common:documents.revision.label")}
      onClose={props.onCancel}
      content={
        <>
          <div className="document-revision-history__table">
            <DocumentRevisionDataTable
              documentRevisions={revisions || []}
              activeRevisionId={activeRevisionId}
              handleDownloadDocument={handleDownload}
              handleRevisionSelected={selectRevision}
              canSelectActive={canSelectActive}
            ></DocumentRevisionDataTable>
          </div>

          {downloadFile ? (
            <div>
              <a
                className="hidden"
                ref={downloadRef}
                href={downloadFile.file}
                download={downloadFile.downloadName}
              ></a>
            </div>
          ) : (
            ""
          )}
        </>
      }
      footer={renderFooter()}
    />
  );
};

DocumentRevisionHistory.defaultProps = {};

DocumentRevisionHistory.propTypes = {
  document: PropTypes.any.isRequired,
  revisionOf: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fetchDocumentRevisions: PropTypes.func,
  updateDocumentRevisionToActive: PropTypes.func,
  revisions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      revision: PropTypes.number,
      name: PropTypes.string,
      date: PropTypes.string,
      properties: PropTypes.shape({
        overrideMessage: PropTypes.string
      }),
      uploadedBy: PropTypes.shape({
        name: PropTypes.string
      })
    })
  ),
  activeRevisionId: PropTypes.number,
  setActiveRevisionId: PropTypes.func,
  fetchDocument: PropTypes.func,
  downloadedDocuments: PropTypes.array,
  canSelectActive: PropTypes.bool
};
export default withTranslation()(DocumentRevisionHistory);
