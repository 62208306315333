import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { systemConstants } from "../constants";
import prepareHeaders from "./rtkUtilities";

export const rolesService = createApi({
  reducerPath: "rolesServiceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  tagTypes: ["Roles"],
  endpoints: builder => ({
    getRoles: builder.query({
      query: () => "/api/roles",
      providesTags: ["Roles"]
    })
  })
});

export const { useGetRolesQuery } = rolesService;
