import React, { useCallback } from "react";

import { useFieldArray, useFormContext } from "react-hook-form";

import BrandButton from "@components/atoms/Button/BrandButton";
import Form from "@components/atoms/Form/Form";

const FORM_FIELD_ARRAY_NAME = "conditions";
const FIELD_ARRAY_KEYS = {
  FIELD: "field",
  OPERATOR: "operator",
  VALUE: "value"
};
const InteractiveReportFilterForm = ({
  columnsForFilter,
  operatorsForColumnTypes,
  parentId
}) => {
  const { getValues, resetField, setValue } = useFormContext();

  const {
    fields,
    append,
    remove: removeFilterCondition
  } = useFieldArray({
    name: FORM_FIELD_ARRAY_NAME
  });

  const addFilterCondition = useCallback(() => {
    append({
      field: null,
      operator: null,
      value: null
    });
  }, [append]);

  const getIndexedFieldName = (fieldName, idx) =>
    `${FORM_FIELD_ARRAY_NAME}.${idx}.${fieldName}`;

  const getOperatorListForType = useCallback(
    type =>
      operatorsForColumnTypes[type] ?? operatorsForColumnTypes["other"] ?? [],
    [operatorsForColumnTypes]
  );

  const refreshOperatorOptions = useCallback(
    (selectedField, idx) => {
      const currentOperator = getValues(
        getIndexedFieldName(FIELD_ARRAY_KEYS.OPERATOR, idx)
      );
      const operatorOptions = getOperatorListForType(
        selectedField?.value?.type
      );
      const isOperatorPresent = operatorOptions.some(
        o => o.value === currentOperator?.value
      );
      if (!isOperatorPresent) {
        resetField(getIndexedFieldName(FIELD_ARRAY_KEYS.OPERATOR, idx));
        setValue(getIndexedFieldName(FIELD_ARRAY_KEYS.OPERATOR, idx));
        resetField(getIndexedFieldName(FIELD_ARRAY_KEYS.VALUE, idx));
        setValue(getIndexedFieldName(FIELD_ARRAY_KEYS.VALUE, idx));
      }
      if (operatorOptions.length === 1) {
        setValue(
          getIndexedFieldName(FIELD_ARRAY_KEYS.OPERATOR, idx),
          operatorOptions[0]
        );
      }
    },
    [getOperatorListForType, getValues, resetField, setValue]
  );

  const renderConditionParameterInputFields = useCallback(
    idx => {
      const field = getValues(getIndexedFieldName(FIELD_ARRAY_KEYS.FIELD, idx));
      const isLookup =
        getValues(getIndexedFieldName(FIELD_ARRAY_KEYS.OPERATOR, idx))
          ?.valueType === "lookup";

      const fieldType = field?.value?.type;
      if (!fieldType) {
        return <></>;
      }
      const operatorOptions = getOperatorListForType(fieldType);
      const onlyOneOption = operatorOptions.length === 1;
      const valueLabel = onlyOneOption ? operatorOptions[0].name : "Value";
      const currentField = getValues(
        getIndexedFieldName(FIELD_ARRAY_KEYS.FIELD, idx)
      );
      return (
        <>
          <div
            style={{
              display: onlyOneOption ? "none" : "inline",
              paddingTop: !operatorOptions[0]?.label ? "1.5625rem" : "" //Prevent misalignment when no label
            }}
          >
            <Form.Dropdown
              name={getIndexedFieldName(FIELD_ARRAY_KEYS.OPERATOR, idx)}
              label={operatorOptions[0]?.label ?? ""}
              required={true}
              items={operatorOptions}
              disabled={!fieldType || onlyOneOption}
              index={idx}
              parentId={parentId}
            />
          </div>
          <Form.TextField
            name={getIndexedFieldName(FIELD_ARRAY_KEYS.VALUE, idx)}
            label={valueLabel}
            required={true}
            {...(isLookup
              ? {
                  datalist: {
                    name: `datalist-${idx}`,
                    list: currentField.lookup
                  }
                }
              : {})}
            disabled={!fieldType}
          />
        </>
      );
    },
    [getOperatorListForType, getValues, parentId]
  );

  return (
    <>
      {fields.map((item, idx) => (
        <React.Fragment key={`f-${item.id}`}>
          <div
            className="ot-form__inline-fields filter-modal__form-row"
            role="group"
          >
            <Form.Dropdown
              name={getIndexedFieldName(FIELD_ARRAY_KEYS.FIELD, idx)}
              label="Select field"
              index={idx}
              required={true}
              items={columnsForFilter}
              onChange={selected => refreshOperatorOptions(selected, idx)}
            />

            {renderConditionParameterInputFields(idx)}

            <div className="filter-modal__delete">
              <i
                onClick={() => removeFilterCondition(idx)}
                className="material-icons"
              >
                delete
              </i>
            </div>
          </div>
          {idx < fields.length - 1 && (
            <div className="filter-modal__and">AND</div>
          )}
        </React.Fragment>
      ))}

      <div className="filter-modal__add-more">
        <BrandButton
          type="text"
          onClick={addFilterCondition}
          iconName="add_circle"
          label="Add filter"
        ></BrandButton>
      </div>
    </>
  );
};

InteractiveReportFilterForm.propTypes = {};

export default InteractiveReportFilterForm;
