import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";

import websheetUtilities from "@shared/helpers/websheetUtilities";

import PageTemplate from "@components/templates/PageTemplate/PageTemplate";

import "./WebsheetTemplate.scss";

const WebsheetTemplate = props => {
  const {
    project,
    headerActions,
    error,
    shortDescription,
    handleMouseMove,
    switchToEditModeButton,
    inEditMode,
    saveButton,
    showEditOrSaveButton,
    isLoading,
    modalOpen,
    getModalContent,
    contentDetails,
    isSidePanelOpen,
    sidePanelContent
  } = props;
  const { t } = useTranslation();

  const contentHeader = useMemo(() => {
    return (
      <div
        className="websheet-template__header"
        style={{
          height: `${websheetUtilities.documentHeaderHeightPx}px`
        }}
      >
        {showEditOrSaveButton && (
          <div className="websheet-template__header__left">
            {inEditMode ? saveButton : switchToEditModeButton}
          </div>
        )}
        <div className="websheet-template__header__right">{contentDetails}</div>
      </div>
    );
  }, [
    contentDetails,
    inEditMode,
    saveButton,
    showEditOrSaveButton,
    switchToEditModeButton
  ]);

  const getBodyContent = useMemo(() => {
    return (
      <div
        className="websheet-template__container"
        onMouseMove={inEditMode ? handleMouseMove : null}
      >
        {contentHeader}
        <div className="websheet-template__container-body">
          {error ? (
            <div className="websheet-template__error">{error}</div>
          ) : (
            <></>
          )}
          {props.children}
        </div>
      </div>
    );
  }, [props.children, contentHeader, error, handleMouseMove, inEditMode]);

  return (
    <PageTemplate
      header={{
        title: project?.client?.name,
        subtitle: shortDescription,
        subtitlePosition: "newline",
        sticky: true,
        actions: headerActions
      }}
      body={{ primary: getBodyContent }}
      sidePanel={{
        open: isSidePanelOpen,
        content: sidePanelContent
      }}
      other={{
        loading: isLoading
          ? t("requests:requests.ui.requestListPage.loadingRequest")
          : null,
        isolationMode: true,
        fullScreenContent: true
      }}
      modal={{
        open: modalOpen,
        content: getModalContent()
      }}
    />
  );
};

export default WebsheetTemplate;
