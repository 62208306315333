import React from "react";

import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import { menuConstants } from "@app/constants";
import { classNames } from "@app/helpers/componentHelpers";

import NavMultilevelDropdown from "@components/organisms/NavMultiLevelDropdown/NavMultilevelDropdown";

import DropdownNav from "../DropdownNav";
import SubNav from "../SubNav";
import "./NavItem.scss";

const menuItemTypes = menuConstants.menuItemTypes;

const renderLink = ({
  name,
  link,
  state = {},
  currentPath,
  handleNavigate,
  navigationStyle
}) => {
  const isActive = currentPath === link;
  if (handleNavigate) {
    return (
      <button
        onClick={() => {
          handleNavigate(link, { state });
        }}
        data-testid="testNavItem"
        className={classNames([
          "nav__item",
          navigationStyle,
          isActive ? "active" : ""
        ])}
      >
        <span className="nav-link-name">{name}</span>
      </button>
    );
  } else {
    // Keeping NavLink to handle pages with using old page templates
    return (
      <NavLink
        to={link}
        state={state}
        data-testid="testNavItem"
        className={classNames([
          "nav__item",
          navigationStyle,
          isActive ? "active" : ""
        ])}
        end
      >
        <span className="nav-link-name">{name}</span>
      </NavLink>
    );
  }
};

const renderDropDown = ({
  name,
  items,
  isActive,
  isNeverActive,
  handleNavigate
}) => {
  if (items?.length > 0) {
    return (
      <DropdownNav
        label={name}
        forceSetActive={isActive}
        isNeverActive={isNeverActive}
        menuItems={items}
        handleNavigate={handleNavigate}
      />
    );
  }
  return null;
};

const renderSub = ({
  name,
  items,
  isActive,
  currentPath,
  state = {},
  handleNavigate,
  subNavLeftPosition,
  setSubNavActive,
  navigationStyle
}) => {
  if (items?.length > 0) {
    return (
      <SubNav
        label={name}
        currentPath={currentPath}
        forceSetActive={isActive}
        menuItems={items}
        subNavLeftPosition={subNavLeftPosition}
        setSubNavActive={setSubNavActive}
        state={state}
        handleNavigate={handleNavigate}
        navigationStyle={navigationStyle}
      />
    );
  }
  return null;
};

const renderSearchableDropDown = ({
  name,
  items,
  handleNavigate,
  isActive
}) => {
  return (
    <NavMultilevelDropdown
      name={name}
      dropdownItems={items}
      handleNavigate={handleNavigate}
      isExpandable={false}
      isActive={isActive}
    />
  );
};

const renderNavMultiLevelDropDown = ({
  name,
  items,
  isActive,
  handleNavigate,
  placeholder
}) => {
  return (
    <NavMultilevelDropdown
      name={name}
      dropdownItems={items}
      handleNavigate={handleNavigate}
      isExpandable={true}
      isActive={isActive}
      placeholder={placeholder}
    />
  );
};

const NavItem = ({
  type,
  name,
  link,
  items,
  isActive,
  isNeverActive,
  state,
  subNavLeftPosition,
  setSubNavActive,
  handleNavigate,
  currentPath,
  navigationStyle,
  placeholder
}) => {
  switch (type) {
    case menuItemTypes.NAV_LINK:
      return renderLink({
        name,
        link,
        state,
        currentPath,
        handleNavigate,
        navigationStyle
      });
    case menuItemTypes.NAV_DROPDOWN:
      return renderDropDown({
        name,
        items,
        isActive,
        isNeverActive,
        handleNavigate
      });
    case menuItemTypes.NAV_SEARCHABLE_DROPDOWN:
      return renderSearchableDropDown({
        name,
        items,
        handleNavigate,
        isActive
      });
    case menuItemTypes.NAV_MULTILEVEL_DROPDOWN:
      return renderNavMultiLevelDropDown({
        name,
        items,
        handleNavigate,
        isActive,
        placeholder
      });
    case menuItemTypes.NAV_SUBMENU:
      return renderSub({
        name,
        items,
        isActive,
        currentPath,
        state,
        handleNavigate,
        subNavLeftPosition,
        setSubNavActive,
        navigationStyle
      });
    default:
      return null;
  }
};

NavItem.defaultProps = {
  type: menuItemTypes.NAV_LINK,
  isActive: false,
  subNavLeftPosition: "0px",
  handleNavigate: null
};

NavItem.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(menuItemTypes)),
  link: PropTypes.string,
  items: PropTypes.array,
  isActive: PropTypes.bool,
  state: PropTypes.object,
  subNavLeftPosition: PropTypes.string,
  setSubNavActive: PropTypes.func,
  handleNavigate: PropTypes.func,
  navigationStyle: PropTypes.string
};

export default NavItem;
