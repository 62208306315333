import React, { useMemo } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import BrandButton from "@components/atoms/Button/BrandButton";
import PageTemplate from "@components/templates/PageTemplate";

import "./ManageUserRolesTemplate.scss";

const ManageUserRolesTemplate = props => {
  const { t } = useTranslation();
  const { rolesTable, handleClickAddRole, isLoading } = props;

  const headingActions = () => {
    return (
      <div>
        <BrandButton
          type="primary"
          onClick={handleClickAddRole}
          iconName="add"
          label={t("admin:ui.manageUserRoles.action.addRole")}
        />
      </div>
    );
  };

  const bodyContent = useMemo(() => {
    return <div className="user-role-permissions__body">{rolesTable}</div>;
  }, [rolesTable]);

  return (
    <>
      <PageTemplate
        header={{
          title: t("admin:ui.manageUserRoles.title"),
          actions: headingActions()
        }}
        body={{
          primary: bodyContent
        }}
        other={{
          loading: isLoading ? t("common:loading") : null
        }}
      />
    </>
  );
};

ManageUserRolesTemplate.defaultProps = {};

ManageUserRolesTemplate.propTypes = {
  rolesTable: PropTypes.element,
  handleClickAddRole: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default ManageUserRolesTemplate;
