import React, { useEffect, useState } from "react";

import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { routeConstants } from "@constants";

import { manageHostUsersActions } from "@shared/actions";

import Button from "@components/atoms/Button/BrandButton";
import DropdownInput from "@components/atoms/DropdownInput/DropdownInput";
import InlineAlert from "@components/atoms/InlineAlert/InlineAlert";
import TextInput from "@components/atoms/TextInput/TextInput";
import MainPageTemplate from "@components/templates/MainPageTemplate";
import PageBodyTemplate from "@components/templates/PageBodyTemplate";

import "./AddOrUpdateGlobalTag.scss";

const AddOrUpdateTag = ({ t }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const existingTag = location.state?.tag;
  const manageHostUsers = useSelector(state => state.manageHostUsers);
  const [tag, setTag] = useState(
    existingTag
      ? {
          id: existingTag.tagId,
          name: existingTag.name,
          engagementType: existingTag.engagementType
        }
      : {
          name: "",
          engagementType: { id: null, name: null }
        }
  );
  const [engagementTypes, setEngagementTypes] = useState([]);
  const [submit, setSubmit] = useState(false);
  const inputFocusRef = React.createRef();
  const [error, setError] = useState("");

  useEffect(() => {
    if (inputFocusRef.current) {
      inputFocusRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFocusRef.current]);

  useEffect(() => {
    dispatch(manageHostUsersActions.getEngagementTypes());
    return () => {
      dispatch(manageHostUsersActions.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setEngagementTypes(manageHostUsers.engagementTypes);
  }, [manageHostUsers.engagementTypes]);

  useEffect(() => {
    if (manageHostUsers.addedTag && manageHostUsers.addedTag.id) {
      navigate(routeConstants.globalTags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageHostUsers.addedTag]);

  useEffect(() => {
    if (manageHostUsers.updatedTag && manageHostUsers.updatedTag.id) {
      navigate(routeConstants.globalTags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageHostUsers.updatedTag]);

  const handleKeyPress = event => {
    if (event.key === "Escape") {
      navigate(routeConstants.globalTags);
    }
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag]);

  useEffect(() => {
    if (manageHostUsers.error) {
      setError(manageHostUsers.error);
    }
  }, [manageHostUsers.error]);

  const handleEngagementTypeChange = event => {
    setError("");
    setTag({
      ...tag,
      engagementType: {
        id: event.id,
        name: event.name
      }
    });
  };

  const handleNameChange = event => {
    setError("");
    const name = event.target.value;
    setTag({ ...tag, name });
  };

  const handleKeyDown = event => {
    event.stopPropagation();
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (existingTag) {
      handleUpdateSubmit();
    } else {
      handleAddSubmit();
    }
  };

  const handleUpdateSubmit = () => {
    setSubmit(true);
    if (tag.id && tag.name && tag.engagementType && tag.engagementType.id) {
      const tagToBeUpdated = {
        id: tag.id,
        name: tag.name && tag.name.trim(),
        engagementTypeId: tag.engagementType && tag.engagementType.id
      };
      dispatch(manageHostUsersActions.updateTag(tagToBeUpdated));
    } else if (!tag.name) {
      setError("Please enter tag name");
    }
  };

  const handleAddSubmit = () => {
    setSubmit(true);
    if (tag.name && tag.engagementType && tag.engagementType.id) {
      const tagToBeAdded = {
        name: tag.name && tag.name.trim(),
        engagementTypeId: tag.engagementType && tag.engagementType.id
      };
      dispatch(manageHostUsersActions.createTag(tagToBeAdded));
    } else {
      if (!tag.name) {
        setError("Please enter tag name");
      } else if (!tag.engagementType || !tag.engagementType.id) {
        setError("Please select engagement type");
      }
    }
  };

  return (
    <MainPageTemplate>
      <PageBodyTemplate
        breadcrumbs={[
          {
            linkTo: routeConstants.globalTags,
            label: t("common:ui.globalTags.title")
          }
        ]}
        title={
          existingTag
            ? t("common:ui.updateTag.title")
            : t("common:ui.addTag.title")
        }
      >
        <div className="add-global-tag__main">
          {error && (
            <div className="add-global-tag__error-box">
              <InlineAlert type="error" message={error} />
            </div>
          )}

          <div className="add-global-tag__container">
            <div className="add-global-tag__input-container">
              <TextInput
                label="Tag name"
                required={true}
                value={tag.name || ""}
                onChange={handleNameChange}
                onKeyDown={handleKeyDown}
                maxLength={50}
              />
            </div>

            <div className="add-global-tag-user__option-container">
              {existingTag ? (
                <>
                  <TextInput
                    label="Engagement Type"
                    value={tag.engagementType.updateDisplayName}
                    disabled={true}
                  />
                </>
              ) : (
                <>
                  <DropdownInput
                    label="Engagement Type"
                    required={true}
                    items={engagementTypes}
                    value={tag.engagementType}
                    onChange={handleEngagementTypeChange}
                  />
                </>
              )}
            </div>

            <div className="add-global-tag__button">
              {existingTag ? (
                <>
                  <Link
                    className="btn btn-link add-tag__button--cancel"
                    to={routeConstants.globalTags}
                  >
                    {t("common:ui.updateTag.cancelLabel")}
                  </Link>
                  <Button
                    className="add-global-tag__button--submit"
                    type="primary"
                    disabled={
                      !(
                        tag.name?.trim?.() !== location.state?.tag?.name &&
                        tag.engagementType?.id
                      )
                    }
                    label={t("common:ui.updateTag.submitLabel")}
                    onClick={handleUpdateSubmit}
                  />
                </>
              ) : (
                <>
                  <Link
                    className="btn btn-link add-tag__button--cancel"
                    to={routeConstants.globalTags}
                  >
                    {t("common:ui.addTag.cancelLabel")}
                  </Link>
                  <Button
                    className="add-global-tag__button--submit"
                    type="primary"
                    disabled={
                      !(
                        tag.name &&
                        tag.name.trim() &&
                        tag.engagementType &&
                        tag.engagementType.id
                      )
                    }
                    label={t("common:ui.addTag.submitLabel")}
                    onClick={handleAddSubmit}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </PageBodyTemplate>
    </MainPageTemplate>
  );
};

export default withTranslation()(AddOrUpdateTag);
