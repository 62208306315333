import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { withTranslation } from "react-i18next";

import { useAuthUser } from "@shared/hooks/useAuthUser";
import { useDeleteProjectDocument } from "@shared/hooks/useDeleteProjectDocument";
import { useDocumentDownloads } from "@shared/hooks/useDocumentDownloads";
import { useFinalPackageDocuments } from "@shared/hooks/useFinalPackageDocuments";
import { useProjectDocumentRevisions } from "@shared/hooks/useProjectDocumentRevisions";
import { useSupportedDocumentMimes } from "@shared/hooks/useSupportedDocumentMimes";

import ErrorBox from "@shared-components/errorBox/ErrorBox";
import Popup from "@shared-components/popup/Popup";

import BrandButton from "@components/atoms/Button/BrandButton";
import FileItem from "@components/molecules/FileItem";
import ProjectAccessModal from "@components/molecules/ProjectAccessModal/ProjectAccessModal";
import UploadFile from "@components/molecules/UploadFile/UploadFile";
import DeleteDocument from "@components/organisms/DeleteDocument";
import ReviewRevisionHistory from "@components/organisms/DocumentRevisionHistory";
import BoxTemplate from "@components/templates/BoxTemplate/BoxTemplate";

import AddClientProjectDocument from "../AddClientProjectDocument/AddClientProjectDocument";
import "./FinalPackageDocumentsPanel.scss";

const documentActions = {
  showRevisionHistory: "Show revision history",
  deleteDocument: "Delete document",
  downloadDocument: "Download document"
};

const FinalPackageDocumentsPanel = props => {
  const { project, isProjectMember, t } = props;
  const { user } = useAuthUser();
  const { supportedDocumentMimes } = useSupportedDocumentMimes();
  const [dropboxError, setDropboxError] = useState(null);
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [showUploadModal, setShowUploadDocumentModal] = useState(false);
  const [openFileSelection, setOpenFileSelection] = useState(false);

  const [downloadFile, setDownloadFile] = useState(null);
  const [downloadClicked, setDownloadClicked] = useState(false);
  const downloadRef = useRef(null);

  const [action, setAction] = useState({});
  const { documents, fetchDocuments, isLoading, error, finalPackageFolder } =
    useFinalPackageDocuments(project.id);
  const { forceDeleteProjectDocument } = useDeleteProjectDocument();
  const {
    fetchDocumentRevisions,
    updateDocumentRevisionToActive,
    revisions,
    activeRevisionId,
    updateRevisionSuccess,
    setActiveRevisionId,
    setCurrentDocument
  } = useProjectDocumentRevisions();
  const { fetchProjectDocument, downloadedDocuments } = useDocumentDownloads();
  const [showProjectAccessModal, setShowProjectAccessModal] = useState(false);
  const [membershipErrorMessage, setMembershipErrorMessage] = useState("");

  useEffect(() => {
    if (updateRevisionSuccess) {
      setAction({});
      fetchDocuments();
    }
  }, [updateRevisionSuccess, fetchDocuments]);

  const checkMembership = useCallback(
    msg => cb => {
      isProjectMember(user, msg, cb, errMessage => {
        setMembershipErrorMessage(errMessage);
        setShowProjectAccessModal(true);
      });
    },
    [isProjectMember, user]
  );

  const isTeamMemberOrAbove = useMemo(() => {
    return user?.isTeamMember || user?.isHostAdmin;
  }, [user]);

  const handleDrop = useCallback(docs => {
    setDroppedFiles([...docs]);
    setShowUploadDocumentModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowProjectAccessModal(false);
    setMembershipErrorMessage("");
  }, []);

  const onFileRejected = useCallback(
    filesRejected => {
      if (filesRejected.length) {
        setDropboxError(t("common:ui.upload.error.fileType.static"));
      } else {
        setDropboxError(null);
      }
    },
    [t]
  );

  const handleClickBrowseToUpload = useCallback(() => {
    checkMembership("perform this action")(() => {
      setOpenFileSelection(true);

      // Flag queued to set to false immediately on the next update cycle so we can trigger the opening again.
      // 'lock' is to guard against the slim chance the component has dismounted
      // See DocumentsDragNDrop.jsx for details
      let lock = true;
      setTimeout(() => {
        if (lock) {
          setOpenFileSelection(false);
        }
      }, 0);
      return () => (lock = false);
    });
  }, [checkMembership]);

  const handleActionFinished = useCallback(() => {
    setCurrentDocument(null);
  }, [setCurrentDocument]);

  const handleDeleteDocument = useCallback(
    document => {
      forceDeleteProjectDocument(document);
      handleActionFinished();
    },
    [forceDeleteProjectDocument, handleActionFinished]
  );

  const handleCancelAction = useCallback(() => {
    setAction({});
  }, []);

  useEffect(() => {
    if (
      action.name !== documentActions.downloadDocument ||
      downloadedDocuments?.length === 0
    ) {
      return;
    }

    const docId = action.document.id;
    const document = documents.find(d => d.id === docId);
    const docFile = downloadedDocuments.find(
      doc =>
        doc.id === document.id &&
        doc.documentRevisionId === document.documentRevisionId
    );
    if (docFile) {
      setDownloadFile({
        documentId: document.id,
        downloadName: docFile.downloadName,
        file: docFile.objectURL
      });
    }
  }, [downloadedDocuments, action, documents]);

  useEffect(() => {
    if (downloadFile?.documentId === action?.document?.id && downloadClicked) {
      downloadRef.current.click();
      setDownloadClicked(false);
    }
  }, [downloadFile, action, downloadClicked]);

  const handleProjectDocumentUpload = useCallback(() => {
    setShowUploadDocumentModal(false);
    setOpenFileSelection(false);
    fetchDocuments();
  }, [fetchDocuments]);

  const handleCancelOnAddClientDocument = useCallback(() => {
    setShowUploadDocumentModal(false);
    setOpenFileSelection(false);
  }, []);

  const menuClickHandler = useCallback(
    menuItem => {
      const docId = menuItem.id;
      const document = documents.find(d => d.id === docId);

      if (menuItem.name === documentActions.downloadDocument) {
        setAction({
          document,
          name: menuItem.name
        });
        fetchProjectDocument(document);
        setDownloadClicked(true);
        setDownloadFile(null);
        return;
      }

      checkMembership("perform this action")(() => {
        setAction({
          document,
          name: menuItem.name
        });
        if (menuItem.name === documentActions.showRevisionHistory) {
          setCurrentDocument(document);
          setDownloadClicked(false);
        } else if (menuItem.name === documentActions.deleteDocument) {
          setDownloadClicked(false);
          // UI reacts to the action so no special processing required. just here for completeness
        }
      });
    },
    [documents, setCurrentDocument, fetchProjectDocument, checkMembership]
  );

  const sortedDocuments = useMemo(
    () =>
      [...documents].sort(({ name: a }, { name: b }) =>
        a.localeCompare(b, undefined, { sensitivity: "base" })
      ),
    [documents]
  );

  const fileItemWitActions = doc => (
    <FileItem
      key={doc.id}
      itemName={doc.name}
      menuItems={[
        {
          name: documentActions.showRevisionHistory,
          id: doc.id
        },
        { name: documentActions.deleteDocument, id: doc.id }
      ]}
      quickAccessMenuItems={[
        {
          name: documentActions.downloadDocument,
          id: doc.id,
          iconName: "download"
        }
      ]}
      onMenuItemClick={menuClickHandler}
      menuVisibility={isTeamMemberOrAbove}
    />
  );

  return (
    <>
      <BoxTemplate
        boxClassName="final-package-documents-panel"
        title="Final FBT Package"
        action={
          isTeamMemberOrAbove && (
            <BrandButton
              type="secondary"
              iconName="cloud_upload"
              label="Browse to upload"
              onClick={handleClickBrowseToUpload}
            ></BrandButton>
          )
        }
      >
        {isLoading ? (
          "Loading..."
        ) : error ? (
          <ErrorBox message={error.message} />
        ) : (
          <>
            <div className="final-package-documents-panel__document-list-wrapper">
              <div className="final-package-documents-panel__document-list">
                {sortedDocuments.map(fileItemWitActions)}

                {downloadFile && (
                  <div>
                    <a
                      className="hidden"
                      ref={downloadRef}
                      href={downloadFile.file}
                      download={downloadFile.downloadName}
                    ></a>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {isTeamMemberOrAbove && isProjectMember(user) && (
          <>
            <UploadFile
              handleDrop={handleDrop}
              supportedDocumentMimes={supportedDocumentMimes}
              handleRejection={onFileRejected}
              openFileSelection={openFileSelection}
              hideUpload={true}
            />
          </>
        )}
      </BoxTemplate>

      <ProjectAccessModal
        visibility={showProjectAccessModal}
        message={membershipErrorMessage}
        handleClose={handleCloseModal}
        handleOutsideClick={handleCloseModal}
      />

      {isTeamMemberOrAbove && (
        <>
          {action?.document && (
            <>
              <Popup
                visibility={action.name === documentActions.deleteDocument}
                handleOutsideClick={false}
                width="60rem"
              >
                <DeleteDocument
                  document={action.document}
                  handleDelete={() => handleDeleteDocument(action.document)}
                  onCancel={handleCancelAction}
                />
              </Popup>
              <Popup
                visibility={action.name === documentActions.showRevisionHistory}
                handleOutsideClick={false}
                width="80rem"
              >
                <ReviewRevisionHistory
                  document={action.document}
                  revisionOf="projectDocument"
                  onCancel={handleCancelAction}
                  onSubmit={handleActionFinished}
                  fetchDocumentRevisions={fetchDocumentRevisions}
                  updateDocumentRevisionToActive={
                    updateDocumentRevisionToActive
                  }
                  revisions={revisions}
                  activeRevisionId={activeRevisionId}
                  setActiveRevisionId={setActiveRevisionId}
                  fetchDocument={fetchProjectDocument}
                  downloadedDocuments={downloadedDocuments}
                />
              </Popup>
            </>
          )}
          {finalPackageFolder && (
            <Popup
              visibility={showUploadModal}
              handleOutsideClick={false}
              width="60rem"
            >
              <AddClientProjectDocument
                project={project}
                parentFolderId={finalPackageFolder.id}
                droppedFiles={droppedFiles}
                tags={[]}
                error={dropboxError}
                onFileUpload={handleActionFinished}
                onUpload={handleProjectDocumentUpload}
                onCancel={handleCancelOnAddClientDocument}
              />
            </Popup>
          )}
        </>
      )}
    </>
  );
};

export default withTranslation()(FinalPackageDocumentsPanel);
