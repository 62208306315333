import React, { useCallback, useEffect, useState } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { smartFormHelper, smartFormResponseType } from "@app/helpers/smartForm";

import BrandButton from "@components/atoms/Button/BrandButton";
import DatePicker from "@components/atoms/DatePicker";
import Multiselect from "@components/atoms/Multiselect";
import TextAreaInput from "@components/atoms/TextAreaInput/TextAreaInput";

import SmartFormRadioButtons from "../SmartFormRadioButtons";
import SmartFormSingleChoice from "../SmartFormSingleChoice";
import "./SmartFormModal.scss";

const invalidValues = [undefined, null, ""];

const SmartFormEntitiesFooter = props => {
  const {
    entities,
    addToFormData,
    t,
    responseType,
    entitiesError,
    inputError,
    options,
    isLineBreaksAllowed,
    maxLength,
    setInputError,
    min,
    max
  } = props;

  const [selectedEntities, setSelectedEntities] = useState([]);
  const [value, setValue] = useState("");
  const [inputControl, setResetInputControl] = useState(false);

  const onSelectedEntitiesChanged = entities => {
    setSelectedEntities(entities);
  };

  const handleSend = useCallback(() => {
    addToFormData({
      value,
      entities: selectedEntities
    });
    const isNumberValueValid = smartFormHelper.isNumberInRange({
      value,
      min,
      max
    });

    if (!isNumberValueValid) {
      setResetInputControl(!isNumberValueValid);
    } else {
      setResetInputControl(true);
    }
  }, [addToFormData, selectedEntities, value, min, max]);

  const handleOnChange = (type, updated) => {
    switch (type) {
      case smartFormResponseType.NUMBER:
        setValue(
          smartFormHelper.getValidTypingSmartFormNumber(updated.target.value)
        );
        setInputError("");
        break;
      case smartFormResponseType.TEXT:
        const newValue = isLineBreaksAllowed
          ? updated.target.value
          : smartFormHelper.removeLineBreaks(updated.target.value);
        setValue(newValue);
        break;
      default:
        setValue(updated);
    }
  };

  const resetSelectedEntities = () => {
    setSelectedEntities([]);
  };

  useEffect(() => {
    if (!entitiesError) {
      setValue("");
    }
    return () => {
      setResetInputControl(false);
    };
  }, [entitiesError, inputControl]);
  const isSendButtonDisabled = () => {
    if (entities.length === 0 || invalidValues.includes(value)) {
      return true;
    }
    if (responseType === smartFormResponseType.NUMBER) {
      return !smartFormHelper.isValidSmartFormNumber(value);
    }
  };

  const renderInput = () => {
    switch (responseType) {
      case smartFormResponseType.NUMBER:
      case smartFormResponseType.TEXT:
        return (
          <TextAreaInput
            placeholder={smartFormHelper.getPlaceholder({
              responseType,
              min,
              max,
              t
            })}
            value={value}
            maxLength={maxLength}
            onChange={event => handleOnChange(responseType, event)}
            disabled={entities.length === 0}
            error={inputError}
          />
        );
      case smartFormResponseType.BOOLEAN:
        const booleanOptions = [
          {
            name: `${t(
              "requests:requests.ui.smartForm.responseType.boolean.yes.label"
            )}`,
            value: true
          },
          {
            name: `${t(
              "requests:requests.ui.smartForm.responseType.boolean.no.label"
            )}`,
            value: false
          }
        ];
        return (
          <SmartFormRadioButtons
            options={booleanOptions}
            onChange={(_, value) =>
              handleOnChange(smartFormResponseType.BOOLEAN, value)
            }
            allowUnselect={false}
            value={value}
            questionId={-1}
            disabled={entities.length === 0}
          />
        );
      case smartFormResponseType.DATE:
        return (
          <DatePicker
            value={value ? new Date(value) : null}
            minDate={new Date("1000-01-01")}
            maxDate={new Date("9999-12-31")}
            onChange={value =>
              handleOnChange(smartFormResponseType.DATE, value)
            }
            disabled={entities.length === 0}
          />
        );
      case smartFormResponseType.SINGLE_CHOICE:
        return (
          <SmartFormSingleChoice
            options={options}
            value={value}
            handleOnChange={value => {
              handleOnChange(smartFormResponseType.SINGLE_CHOICE, value);
            }}
            disabled={entities.length === 0}
            questionId={-1}
            entities={entities}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="smart-form-modal--entities">
      <div className="smart-form-modal--entities-multiselect">
        <span className="smart-form-modal--entities-multiselect__relevant-entities">
          {t(
            "requests:requests.ui.smartForm.modal.answerPerEntity.multiSelect.relevantEntities"
          )}
        </span>
        <Multiselect
          defaultSelectAllLabel={t("ui.forms.multiselect.selectAll")}
          placeholder={t(
            "requests:requests.ui.smartForm.modal.multiselect.label"
          )}
          name="text-entities"
          items={entities}
          onChange={onSelectedEntitiesChanged}
          resetInputControl={inputControl}
          disabled={entities.length === 0}
          reset={resetSelectedEntities}
          value={selectedEntities}
          parentId="modal-content-template-footer"
          error={entitiesError}
        />
      </div>
      <div className="smart-form-modal__answer-per-entities">
        <div className="smart-form-modal__answer-per-entities-value">
          {renderInput()}
        </div>
        <BrandButton
          iconName="send"
          onClick={handleSend}
          iconSize="medium"
          className="smart-form-modal__answer-per-entities-button"
          disabled={isSendButtonDisabled()}
        />
      </div>
    </div>
  );
};

SmartFormEntitiesFooter.defaultProps = {
  entities: [],
  isLineBreaksAllowed: true
};

SmartFormEntitiesFooter.propTypes = {
  entities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.any
    })
  ),
  addToFormData: PropTypes.func,
  error: PropTypes.string,
  isLineBreaksAllowed: PropTypes.bool,
  maxLength: PropTypes.number
};

export default withTranslation()(SmartFormEntitiesFooter);
