import React, { useCallback, useMemo, useRef } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants/systemConstants";

import { smartFormHelper } from "@app/helpers/smartForm";
import { useDataTable } from "@app/hooks/useDataTable";

import Icon from "@components/atoms/Icon";
import Multiselect from "@components/atoms/Multiselect";
import ProgressSpinner from "@components/atoms/ProgressSpinner";
import DataTable from "@components/molecules/DataTable";
import DeleteConfirm from "@components/molecules/DeleteConfirm/index";

const addAttachedFileState = systemConstants.addFiles.attachedFile.state;

const SmartFormModalTable = props => {
  const {
    t,
    data,
    header,
    onClickRemove,
    responseColumn,
    handleEntitiesChange,
    entities,
    isColumnHeaderHidden,
    showEntities,
    disableAnswerSubmissions,
    showDeletedDocuments,
    removeDeleted,
    webSheetMark,
    checkAnswerStatus,
    allowSameEntities,
    uploadedFileStates
  } = props;

  const tableRef = useRef();
  const { createColumn } = useDataTable();

  const renderDeleteIcon = useCallback(
    (value, index) => {
      if (disableAnswerSubmissions) {
        return <></>;
      }
      if (showDeletedDocuments) {
        return <DeleteConfirm onDelete={() => removeDeleted(value?.id)} />;
      }

      return (
        <Icon
          name={"delete"}
          designStyle="material-symbols"
          fillStyle={"outlined"}
          className="document-state-cell__icon"
          onClick={() => {
            onClickRemove(value, index);
          }}
        ></Icon>
      );
    },
    [
      disableAnswerSubmissions,
      onClickRemove,
      removeDeleted,
      showDeletedDocuments
    ]
  );

  const renderFileProgressIcon = useCallback(
    ({ value, index }) => {
      if (value !== null && value !== undefined) {
        const state = uploadedFileStates[index]?.state ?? null;
        switch (state) {
          case addAttachedFileState.uploading:
            return (
              <div className="upload-progress-spinner">
                <ProgressSpinner
                  percentage={uploadedFileStates[index].uploadProgress || 0}
                />
              </div>
            );
          case addAttachedFileState.uploaded:
            return (
              <Icon
                name={"done"}
                designStyle="material-symbols"
                fillStyle={"outlined"}
                className="document-state-cell__icon"
              ></Icon>
            );
          default:
            return renderDeleteIcon(value, index);
        }
      }
    },
    [renderDeleteIcon, uploadedFileStates]
  );

  const renderEntities = useCallback(
    row => {
      if (showDeletedDocuments) {
        const entities = row.original.entities.map(e => e.name).join(", ");
        return (
          <div
            title={entities}
            className="smart-form-modal__table--truncated-list"
          >
            {entities}
          </div>
        );
      }
      const status = checkAnswerStatus(row.original.value?.id);

      return (
        <Multiselect
          placeholder={t(
            "requests:requests.ui.smartForm.modal.multiselect.label"
          )}
          defaultSelectAllLabel={t("ui.forms.multiselect.selectAll")}
          name="entities"
          items={
            allowSameEntities
              ? entities
              : smartFormHelper.getEntitiesWithNoAnswer(
                  entities,
                  data,
                  row.index
                )
          }
          onChange={e => {
            handleEntitiesChange(e, row.index);
          }}
          disabled={
            disableAnswerSubmissions ||
            (status.isCompleted && status.isCleaned) ||
            row.original.value.isRestored
          }
          reset={() => {
            handleEntitiesChange([], row.index);
          }}
          value={row.original.entities}
          parentId="modal-content-template-inner-container"
          label={""}
        />
      );
    },
    [
      checkAnswerStatus,
      data,
      disableAnswerSubmissions,
      entities,
      handleEntitiesChange,
      showDeletedDocuments,
      allowSameEntities,
      t
    ]
  );

  const columns = useMemo(() => {
    return [
      webSheetMark &&
        createColumn({
          Header: " ",
          width: 50,
          id: "websheetMark",
          accessor: ({ value }) => value?.id,
          className: "websheet-mark-cell",
          Cell: ({ cell }) => webSheetMark(cell?.value)
        }),
      createColumn({
        Header: header,
        accessor: "value",
        width: showEntities ? 215 : 380,
        id: "value",
        className: "response-cell",
        ...responseColumn
      }),
      showEntities &&
        createColumn({
          Header: t("requests:requests.configured.fields.entities.label"),
          accessor: "entities",
          className: "entities-cell",
          id: "entities",
          width: 380,
          Cell: ({ cell }) => (
            <div className="smart-form-modal__table--entities">
              {renderEntities(cell.row)}
            </div>
          )
        }),
      {
        Header: "",
        accessor: "state",
        className: "document-state-cell",
        id: "state",
        width: showDeletedDocuments ? 120 : 30,
        Cell: ({ cell }) => {
          return (
            <div className="smart-form-modal__table__content-action">
              {renderFileProgressIcon({
                index: cell.row.index,
                value: cell.row.original.value
              })}
            </div>
          );
        }
      }
    ].filter(d => d);
  }, [
    webSheetMark,
    createColumn,
    header,
    showEntities,
    responseColumn,
    t,
    showDeletedDocuments,
    renderEntities,
    renderFileProgressIcon
  ]);

  const getColumnHeaderHiddenClass = () => {
    return isColumnHeaderHidden ? "hidden-column-header" : "";
  };

  const classNames = list => list?.filter(c => c).join(" ");

  return (
    <DataTable
      ref={tableRef}
      id="smart-form-modal-table"
      className={classNames([
        "smart-form-modal__table",
        getColumnHeaderHiddenClass()
      ])}
      columns={columns}
      data={data}
      hideHeaders={false}
      height={300}
    />
  );
};

SmartFormModalTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.object
      ]),
      entities: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.any
        })
      )
    })
  ),
  header: PropTypes.string,
  onClickRemove: PropTypes.func.isRequired,
  entities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  handleEntitiesChange: PropTypes.func,
  responseColumn: PropTypes.shape({
    Cell: PropTypes.func
  }),
  isColumnHeaderHidden: PropTypes.bool,
  disableAnswerSubmissions: PropTypes.bool,
  webSheetMark: PropTypes.func,
  uploadedFileStates: PropTypes.arrayOf(
    PropTypes.shape({
      state: PropTypes.string,
      name: PropTypes.string,
      uploadProgress: PropTypes.number
    })
  )
};

export default withTranslation()(SmartFormModalTable);
