import React from "react";

import PropTypes from "prop-types";

import NavItem from "./NavItem";

const Nav = props => {
  const {
    navItems,
    subNavLeftPosition,
    setSubNavActive,
    handleNavigate,
    currentPath,
    navigationStyle,
    handleSelectFilter
  } = props;
  if (!navItems || navItems.length === 0) {
    return null;
  }
  return (
    <>
      {navItems.map(item => (
        <NavItem
          key={item.name}
          {...item}
          subNavLeftPosition={subNavLeftPosition}
          setSubNavActive={setSubNavActive}
          handleNavigate={handleNavigate}
          currentPath={currentPath}
          navigationStyle={navigationStyle}
          handleSelectFilter={handleSelectFilter}
        />
      ))}
    </>
  );
};

Nav.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      isActive: PropTypes.bool,
      isNeverActive: PropTypes.bool,
      link: PropTypes.string,
      state: PropTypes.object
    })
  ),
  subNavLeftPosition: PropTypes.string,
  setSubNavActive: PropTypes.func,
  handleSelectFilter: PropTypes.func,
  handleNavigate: PropTypes.func,
  currentPath: PropTypes.string,
  groupLabel: PropTypes.string,
  navigationStyle: PropTypes.string
};

export default Nav;
