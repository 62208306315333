import React from "react";

import PropTypes from "prop-types";

import BrandButton from "@components/atoms/Button/BrandButton";
import ModalTemplate from "@components/templates/ModalTemplate";

import "./WarningModal.scss";

const WarningModal = props => {
  const { message, items, title, onClickYes, onClickCancel } = props;
  const renderMessage = () => {
    return (
      <div className="warning-modal__content">
        <span className="warning-modal__message">{message}</span>
        {items.length > 0 && (
          <div className="warning-modal__items">
            {items.map(item => (
              <div key={item.id} className="warning-modal__items-item">
                <i className="material-icons bullet">circle</i>
                <span className="warning-modal__items-item-request">
                  {item.value}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };
  return (
    <ModalTemplate
      boxClassName="warning-modal"
      title={title}
      onClose={onClickCancel}
      content={renderMessage()}
      footer={
        <>
          <BrandButton
            type="secondary"
            label="Cancel"
            onClick={onClickCancel}
          />
          <BrandButton type="primary" label="OK" onClick={onClickYes} />
        </>
      }
    />
  );
};

WarningModal.defaultProps = {
  items: []
};

WarningModal.propTypes = {
  message: PropTypes.string,
  items: PropTypes.array,
  title: PropTypes.string,
  onClickYes: PropTypes.func,
  onClickCancel: PropTypes.func
};

export default WarningModal;
