import React from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import BrandButton from "@components/atoms/Button/BrandButton";
import Icon from "@components/atoms/Icon/Icon";
import Text from "@components/atoms/Text/Text";
import PageTemplate from "@components/templates/PageTemplate/PageTemplate";

import "./NotFound.scss";

const NotFound = () => {
  const { t } = useTranslation();
  const content = {
    errorCode: t("ui.notFoundPage.errorCode"),
    errorDescription: t("ui.notFoundPage.errorDescription"),
    description: t("ui.notFoundPage.description"),
    buttonLabel: t("ui.notFoundPage.buttonLabel")
  };
  const navigate = useNavigate();
  const previousPath = JSON.parse(sessionStorage.getItem("previousPath"));

  const { pathname } =
  previousPath?.baseURL === window.location.hostname
      ? previousPath
      : { pathname: "/" };

  const goBack = () => {
    navigate(pathname);
  };

  const body = () => (
    <section className="notFound__main">
      <Icon
        className="notFound__main__icon"
        name="sentiment_dissatisfied"
        size="large"
      />
      <Text value={content.errorCode} fontSize="11" />
      <Text value={content.errorDescription} fontSize="8" disabled />
      <Text value={content.description} />
      <BrandButton label={content.buttonLabel} size="small" onClick={goBack} />
    </section>
  );

  return (
    <PageTemplate
      header={{ title: content.errorDescription, sticky: true }}
      body={{ primary: body() }}
      skipSavePath={true}
    ></PageTemplate>
  );
};

export default NotFound;
