import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { isNil } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { evaluateCondition } from "@shared/helpers/interactiveReportOperators";

import CommentButton from "@components/atoms/CommentButton/";
import ExpandButton from "@components/atoms/ExpandButton";
import Icon from "@components/atoms/Icon/Icon";
import Pill from "@components/atoms/Pill";
import ReviewButton from "@components/atoms/ReviewButton/ReviewButton";
import DataTable from "@components/molecules/DataTable";

import "./InteractiveReportTable.scss";

function InteractiveReportTable({
  table,
  filter,
  activeRowId,
  reviewRows,
  onDrilldownTableSelected,
  onDrillDownPageSelected,
  onClickCommentToCreate,
  onClickCommentToView,
  onClickReviewRow,
  downloadTable,
  isDrilldown,
  displayChart
}) {
  const { t } = useTranslation();
  const [showReview, setShowReview] = useState(false);
  const [reviewPreview, setReviewPreview] = useState(false);
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [show, setShow] = useState(false);
  const contextRef = useRef();
  const handleClick = useCallback(() => show && setShow(false), [show]);

  const onContextMenu = useCallback(
    event => {
      if (downloadTable) {
        event.preventDefault();
        const rect = contextRef.current.getBoundingClientRect();
        setAnchorPoint({
          x: event.clientX - rect.left,
          y: event.clientY - rect.top
        });
        setShow(true);
      }
    },
    [downloadTable]
  );

  useEffect(() => {
    addEventListener("click", handleClick);
    addEventListener("contextmenu", handleClick);
    return () => {
      removeEventListener("click", handleClick);
      removeEventListener("contextmenu", handleClick);
    };
  }, [handleClick]);

  const getExpandedDrilldownColumn = useCallback(
    (isDrilldownExist, tableRows, tableOptions) => {
      return {
        name: "drilldownIcon",
        hidden: false,
        type: "drilldown",
        className: isDrilldownExist
          ? `-borderless-cell`
          : `-borderless-cell-hidden`,
        fixedWidth: true,
        width: 40,
        freeze: tableOptions.freezeColumns
          ? {
              position: "right",
              isEdge: true
            }
          : null,
        value: (event, id) => {
          const rowId = Math.floor(id);
          const row = tableRows?.find(r => r.id == rowId);
          const drilldown = row?.drilldown;
          event.stopPropagation();
          if (drilldown?.pageId !== undefined) {
            onDrillDownPageSelected({ rowId, drilldown });
          } else if (drilldown?.table) {
            onDrilldownTableSelected({ row, drilldown });
          }
        }
      };
    },
    [onDrillDownPageSelected, onDrilldownTableSelected]
  );

  const renderColumns = useCallback(
    (tableColumns, tableRows, tableOptions) => {
      const commentColumn = {
        name: "commentIcon",
        hidden: false,
        type: "comment",
        className: `-borderless-cell`,
        freeze: tableOptions.freezeColumns
          ? {
              position: "right",
              isEdge: false
            }
          : null,
        fixedWidth: true,
        width: 50,
        value: (event, id) => {
          event.stopPropagation();
          onClickCommentToCreate?.(id, isDrilldown);
        }
      };

      const isDrilldownExist = tableRows?.some(row => row?.drilldown);
      const expandedDrilldownColumn =
        !isDrilldown || isDrilldownExist
          ? getExpandedDrilldownColumn(
              isDrilldownExist,
              tableRows,
              tableOptions
            )
          : null;

      const reviewColumn = {
        name: "reviewIcon",
        hidden: isDrilldown,
        type: "review",
        className: `-borderless-cell`,
        freeze: tableOptions.freezeColumns
          ? {
              position: "left",
              isEdge: !tableOptions?.freezeColumns
            }
          : null,
        fixedWidth: true,
        width: 50,
        value: (e, id) => {
          onClickReviewRow(e, id);
        }
      };

      const normallyVisibleColumns = tableColumns.filter(
        c => !c.hidden || c.hiddenByFilter
      );
      const dataColumns = tableColumns.map((c, index) => {
        // only apply freezing to columns which are normally visible
        const vcIdx = normallyVisibleColumns.findIndex(
          vc => c.name === vc.name
        );
        const shouldFreezeColumn =
          !c.hidden && vcIdx !== -1 && vcIdx < tableOptions?.freezeColumns;
        return {
          ...c,
          freeze: shouldFreezeColumn
            ? {
                position: "left",
                style: c.style
              }
            : null
        };
      });
      // find the last 'left' as we need to mark it as the edge for styling
      if (tableOptions?.freezeColumns) {
        const leftEdgeColumn = dataColumns
          .filter(c => c.freeze?.position === "left")
          ?.pop();
        if (leftEdgeColumn?.freeze) {
          leftEdgeColumn.freeze.isEdge = true;
        }
      }

      const columns = [reviewColumn, ...dataColumns];

      if (expandedDrilldownColumn) {
        columns.push(expandedDrilldownColumn);
      }

      columns.push(commentColumn);
      return columns;
    },
    [
      isDrilldown,
      onClickCommentToCreate,
      onClickReviewRow,
      getExpandedDrilldownColumn
    ]
  );

  const columns = useMemo(() => {
    const MaybeHyperlink = ({ url, label, icon = "info", isMergedCell }) => {
      if (!url?.startsWith("https://") || isMergedCell) {
        return <></>;
      }

      return (
        <a
          className="data-table__data--hyperlink-icon"
          title={label ?? url}
          href={url}
          target="_blank"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {icon && <Icon name={icon} size="small" />}
        </a>
      );
    };

    const formatCell = (type, cell) => {
      const value = cell.mergedCell ? "" : cell.value;
      switch (type) {
        case "number":
          const formattedNumber = Math.abs(value).toLocaleString("en-AU");
          const cellValue =
            cell.value < 0 ? `(${formattedNumber})` : formattedNumber;
          return (
            <>
              <span className="data-table__data--number" title={cellValue}>
                {cellValue}
              </span>
              <MaybeHyperlink
                {...cell.hyperlink}
                isMergedCell={cell.mergedCell}
              />
            </>
          );
        case "tag":
          return value !== "" ? (
            <Pill label={value} colorScheme={"accent"} />
          ) : (
            <></>
          );
        case "comment":
          if (cell?.row?.original?.comment?.statusDisplay) {
            const { icon, state } = cell.row.original.comment.statusDisplay;
            return (
              <div className="data-table__data--comment data-table__data--icon-cell">
                <CommentButton
                  id={cell.row.original.id}
                  title={t(
                    `requests:requests.configured.status.${cell.row.original.comment.status}.label`
                  )}
                  icon={icon}
                  state={state}
                  onClick={(event, id) => {
                    event.stopPropagation();
                    onClickCommentToView?.(id, isDrilldown);
                  }}
                />
              </div>
            );
          }
          return (
            <div
              className="data-table__data--on-hover-cell data-table__data--icon-cell"
              role="cell-comment-icon"
            >
              <CommentButton id={cell.row.original.id} onClick={value} />
            </div>
          );
        case "drilldown":
          if (cell?.row?.original?.drilldown) {
            const drilldown = cell?.row?.original?.drilldown;
            const drilldownStats = drilldown?.drilldownStats;
            const state = ["active", "inactive"].find(s =>
              drilldownStats?.states?.includes(s)
            );

            const isRowActive =
              !isNil(activeRowId) && cell.row.original.id === activeRowId;
            const isButtonActive = isRowActive || state === "active";

            return (
              <div className="data-table__data--drilldown">
                <ExpandButton
                  id={cell.row.original.id}
                  onClick={value}
                  isActive={isButtonActive}
                  type={
                    drilldown?.pageId !== undefined ? "newWindow" : "drilldown"
                  }
                  state={state}
                />
              </div>
            );
          }
          return <></>;
        case "review":
          return (
            <div
              className={`${
                reviewRows?.includes(cell.row.original.id)
                  ? ""
                  : "data-table__data--on-hover-cell"
              } data-table__data--icon-cell`}
              role="cell-review-icon"
            >
              <ReviewButton
                id={cell.row.original.id}
                onClick={value}
                hoverText="Mark for review"
                filled={reviewRows?.includes(cell.row.original.id)}
              />
            </div>
          );

        default:
          return (
            <>
              <span className="data-table__data--string">{value}</span>
              <MaybeHyperlink
                {...cell.hyperlink}
                isMergedCell={cell.mergedCell}
              />
            </>
          );
      }
    };

    if (table?.columns) {
      const headerName = c => {
        if (c.type === "review" && !isDrilldown) {
          return (
            <div
              className="ot-interactive-report-table--review-header data-table__data--icon-cell"
              onMouseEnter={() => setReviewPreview(true)}
              onMouseLeave={() => setReviewPreview(false)}
            >
              <ReviewButton
                onClick={() => setShowReview(prev => !prev)}
                hidden={!reviewRows?.length}
                hoverText="Select Items for Review"
                filled={showReview || reviewPreview}
              />
            </div>
          );
        }
        return ["tag", "comment", "drilldown"].includes(c.type) ? "" : c.name;
      };

      const renderWidth = c => {
        if (table.freezeColumns) {
          if (c.style === "wide") {
            return 300;
          }
          if (c.type === "tag") {
            return 75;
          }
          if (c.width) {
            return c.width;
          }
          return 150;
        }
        if (c.width) {
          return c.width;
        }
        if (c.style === "wide") {
          return 90;
        }
        return c.type === "tag" ? 75 : 50;
      };

      return renderColumns(table.columns, table.rows, {
        freezeColumns: table.freezeColumns
      }).map((c, _index) => {
        return {
          Header: headerName(c),
          hoverText: c.name,
          accessor: c.name,
          isHidden: c.hidden,
          className: c.className,
          fixedWidth: c.fixedWidth ?? c.type === "tag",
          width: renderWidth(c),
          freeze: c.freeze,
          Cell: ({ value, row }) =>
            formatCell(
              c.type,
              value?.content ?? { row, value: c.value, hyperlink: c.hyperlink }
            )
        };
      });
    }
    return [];
  }, [
    table.columns,
    table.rows,
    table.freezeColumns,
    reviewRows,
    t,
    onClickCommentToView,
    isDrilldown,
    activeRowId,
    renderColumns,
    showReview,
    reviewPreview
  ]);

  const isMergedCell = ({
    dataRows,
    rowIndex,
    colIndex,
    cellValue,
    tableDisplayLevels,
    columnDisplayLevels,
    skipMerge
  }) => {
    const colDisplayLevel = columnDisplayLevels[colIndex];
    if (
      rowIndex === 0 ||
      skipMerge ||
      tableDisplayLevels === undefined ||
      tableDisplayLevels < 2 ||
      colDisplayLevel === 0 ||
      colDisplayLevel >= tableDisplayLevels
    )
      return false;

    const prevCellValue = dataRows[rowIndex - 1]?.cells[colIndex].value;
    return cellValue === prevCellValue;
  };

  const data = useMemo(() => {
    const columnNames = table?.columns?.map(c => c.name);
    let displayLevel = 1;
    const columnDisplayLevels = table?.columns?.map(c =>
      c.type !== "string" || c.type.hidden ? 0 : displayLevel++
    );
    const checkRowPredicate = ({ cells }, { field, operator, value }) => {
      const cellIndex = columnNames?.indexOf(field);
      if (cells && cellIndex > -1 && cells[cellIndex].value !== undefined) {
        const cellValue = cells[cellIndex].value;
        return evaluateCondition(cellValue, value, operator);
      }
      return true;
    };
    const applyRowFilters = row => {
      // Comment filter requires us to find matches
      if (
        filter?.comments?.status?.length > 0 &&
        !filter.comments.status?.some(s => {
          return (
            s === row?.comment?.status ||
            row?.drilldown?.drilldownStats?.statuses?.includes(s)
          );
        })
      ) {
        return false;
      }
      // Special case 'no comment' filter -> neither comment on row nor drilldown
      //Filter rows with no comments => status: []
      if (
        filter?.comments?.status?.length === 0 &&
        (row.comment || row?.drilldown?.drilldownStats?.statuses?.length > 0)
      ) {
        return false;
      }
      if (filter?.drilldownFilter) {
        return row?.cells?.some(cell => {
          return cell.value === filter?.drilldownFilter?.label;
        });
      }

      if (
        filter?.comments?.requestKeys &&
        !filter.comments.requestKeys.includes(row?.comment?.queryType) &&
        !filter.comments.requestKeys.some(requestKey =>
          row?.drilldown?.drilldownStats?.requestKeys?.includes(requestKey)
        )
      ) {
        return false;
      }

      //Apply filter conditions
      const filterConditions =
        filter?.conditions?.every(f => checkRowPredicate(row, f)) ?? true;

      if (filterConditions) {
        //reviewFilter
        return !showReview || reviewRows.includes(row.id);
      }

      return false;
    };

    const filteredRows = table?.rows?.filter(applyRowFilters);

    return (
      filteredRows?.map((row, rowIndex) => {
        let skipMergeOnRemainingCells = false;
        const rowData = row.cells
          .map((col, colIndex) => {
            const mergedCell = isMergedCell({
              dataRows: filteredRows,
              rowIndex,
              colIndex,
              cellValue: col.value,
              tableDisplayLevels: table?.displayLevels,
              columnDisplayLevels,
              skipMerge: skipMergeOnRemainingCells
            });
            if (
              !skipMergeOnRemainingCells &&
              columnDisplayLevels[colIndex] !== 0 &&
              !mergedCell
            ) {
              skipMergeOnRemainingCells = true;
            }
            return {
              [columnNames[colIndex]]: {
                value: col.value,
                hyperlink: col.hyperlink,
                mergedCell
              }
            };
          })
          .reduce((result, obj) => {
            result[Object.keys(obj)[0]] = {
              content: Object.values(obj)[0],
              style: row.style
            };
            return result;
          }, {});
        if (row.comment) {
          rowData.comment = row.comment;
        }
        if (row.drilldown) {
          rowData.drilldown = row.drilldown;
        }
        return { id: row.id, style: row.style ?? "", ...rowData };
      }) ?? []
    );
  }, [
    filter,
    table?.columns,
    table?.displayLevels,
    table?.rows,
    showReview,
    reviewRows
  ]);

  const rowClassNames = useMemo(
    () => rowId => {
      const rowStyles = table?.rows
        ?.filter(r => r.id !== undefined && r.id === rowId)
        .map(r => r.style?.join?.(" "));
      if (activeRowId !== undefined && rowId === activeRowId) {
        return [...rowStyles, "active"];
      } else return rowStyles;
    },
    [activeRowId, table?.rows]
  );

  const rowClickHandler = useMemo(() => {
    return {
      isClickable: rowId => {
        const item = table?.rows.find(r => r.id === rowId);
        return item?.drilldown?.pageId !== undefined || item?.drilldown?.table;
      },
      handler: rowId => {
        const row = table?.rows.find(r => r.id === rowId);
        const drilldown = row?.drilldown;
        if (drilldown?.pageId !== undefined) {
          onDrillDownPageSelected?.({ rowId, drilldown });
        } else if (drilldown?.table) {
          onDrilldownTableSelected({ row, drilldown });
        }
      }
    };
  }, [onDrillDownPageSelected, onDrilldownTableSelected, table?.rows]);

  return (
    <div
      ref={contextRef}
      onContextMenu={onContextMenu}
      style={{ position: "relative" }}
    >
      <DataTable
        className="ot-interactive-report-table"
        columns={columns}
        data={data}
        height={displayChart ? 370 : 880}
        layoutMode={table?.freezeColumns ? "freeze" : "flex"}
        rowClassNames={rowClassNames}
        rowClickHandler={rowClickHandler}
      />
      {show ? (
        <ul
          className="ot-interactive-report-table--menu"
          style={{
            top: `${anchorPoint.y}px`,
            left: `${anchorPoint.x}px`
          }}
        >
          <li onClick={downloadTable.onClick}>Download {downloadTable.name}</li>
        </ul>
      ) : (
        <> </>
      )}
    </div>
  );
}

InteractiveReportTable.propTypes = {
  filter: PropTypes.shape({
    conditions: PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.string.isRequired,
        operator: PropTypes.oneOf([
          "gt",
          "gte",
          "lt",
          "lte",
          "eq",
          "neq",
          "contains"
        ]).isRequired,
        value: PropTypes.any.isRequired
      })
    )
  }),
  table: PropTypes.shape({
    displayLevels: PropTypes.number,
    freezeColumns: PropTypes.number,
    columns: PropTypes.array,
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        cells: PropTypes.array,
        drillDown: PropTypes.object,
        style: PropTypes.arrayOf(PropTypes.string)
      })
    )
  }),
  onDrilldownTableSelected: PropTypes.func,
  onDrillDownPageSelected: PropTypes.func,
  downloadTable: PropTypes.shape({
    onClick: PropTypes.func,
    name: PropTypes.string
  }),
  activeRowId: PropTypes.number,
  onClickCommentToCreate: PropTypes.func,
  onClickCommentToView: PropTypes.func
};

export default InteractiveReportTable;
