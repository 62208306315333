import React, { useEffect } from "react";

import { get } from "lodash";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

import DropdownInput from "@components/atoms/DropdownInput";

const Dropdown = props => {
  const {
    getValues,
    setValue,
    formState: { errors },
    clearErrors,
    control
  } = useFormContext();

  useEffect(() => {
    if (props.defaultValue) {
      setValue(props.name, props.defaultValue);
    }
  }, [props.defaultValue, props.name, setValue]);

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { onChange, onBlur, ref } }) => {
        return (
          <DropdownInput
            label={props.label}
            items={props.items}
            ref={ref}
            value={getValues(props.name) || { name: "" }}
            onChange={newVal => {
              if (newVal && newVal.length === 0) {
                newVal = undefined;
              }
              if (newVal) clearErrors(props.name);
              onChange(newVal);
              props?.onChange?.(newVal);
            }}
            onBlur={onBlur}
            disabled={
              props.disabled ||
              (props.disableWhenNoOptions &&
                !props.items?.length &&
                !getValues(props.name))
            }
            required={props.required}
            error={get(errors, props.name?.split("."))?.message ?? ""}
            transformSelected={props.transformSelected}
            index={props.index}
            showSearchFilter={props.showSearchFilter}
            parentId={props.parentId}
            showClear={props.showClear}
            scrollWithParent={props.scrollWithParent}
            showLabel={props.showLabel}
            placeholder={props.placeholder}
            sortComparator={props.sortComparator}
            isSideBySide={props.isSideBySide}
          />
        );
      }}
    />
  );
};

Dropdown.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any
    })
  ).isRequired,
  defaultValue: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any
  }),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  showSearchFilter: PropTypes.bool,
  parentId: PropTypes.string,
  showClear: PropTypes.bool,
  scrollWithParent: PropTypes.bool,
  disableWhenNoOptions: PropTypes.bool,
  showLabel: PropTypes.bool,
  placeholder: PropTypes.string,
  sortComparator: PropTypes.func,
  isSideBySide: PropTypes.bool
};

export default Dropdown;
