/* eslint-disable no-console */
import { useCallback, useState } from "react";

import { authService } from "@shared/services/authService";

let newSocket;

const initWebSocket = async () => {
  const { url } = await authService.getClientAccessToken();
  if (!newSocket) {
    newSocket = new WebSocket(url);
  }
};

export default function usePubSub() {
  const [data, setData] = useState(null);
  const [socket, setSocket] = useState(null);
  const [events, setEvents] = useState([]);

  const onMessage = useCallback(
    event => {
      try {
        const parsed = JSON.parse(event.data);
        if (events.some(e => e === parsed.subject)) {
          setData({ ...parsed.data, timeStamp: event.timeStamp });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [events]
  );

  const startPubSub = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token || socket) {
      return;
    }
    try {
      await initWebSocket();
      setSocket(newSocket);
      newSocket.addEventListener("message", onMessage);
      newSocket.addEventListener("close", async () => {
        newSocket = null;
        await initWebSocket();
      });
    } catch (error) {
      socket?.close();
      console.error(error);
    }
  }, [onMessage, socket]);

  const subscribe = useCallback(
    eventName => {
      if (!socket) {
        startPubSub();
      }
      if (!events.some(e => e === eventName)) {
        setEvents(e => {
          e.push(eventName);
          return e;
        });
      }
    },
    [events, socket, startPubSub]
  );

  const unsubscribe = useCallback(
    eventName => {
      const eventsIndex = events.findIndex(e => e === eventName);
      if (eventsIndex !== -1) {
        setEvents(e => {
          e.splice(eventsIndex, 1);
          return e;
        });
      }
    },
    [events]
  );

  return {
    eventNames: events,
    value: data,
    subscribe,
    unsubscribe
  };
}
