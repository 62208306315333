import React, { useCallback, useEffect, useRef, useState } from "react";

import { useVisualisation } from "@shared/hooks";

import { visualisationConstants } from "@app/constants/visualisationConstants";

import Button from "@components/atoms/Button";
import InlineAlert from "@components/atoms/InlineAlert";
import PopupOverlay from "@components/atoms/PopupOverlay";
import UploadFile from "@components/molecules/UploadFile";
import ModalTemplate from "@components/templates/ModalTemplate";

import VisualisationTitle from "../VisualisationTitle";
import "./VisualisationImage.scss";

const VisualisationImage = props => {
  const {
    config,
    user,
    document,
    onDrop,
    onDelete,
    onEditTitle,
    positionNum,
    isUploadDisabled,
    handleUploadPermission
  } = props;
  const {
    visualisationError,
    resetVisualisationError,
    updateVisualisationSuccess
  } = useVisualisation();
  const [expand, setExpand] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [error, setError] = useState({
    display: false,
    message: ""
  });
  const [disableUpload, setDisableUpload] = useState(false);
  const [updateTitle, setUpdateTitle] = useState();

  useEffect(() => {
    if (document) {
      setUpdateTitle({
        id: document.id,
        name: document.name
      });
    } else if (!document) {
      setUpdateTitle(null);
    }
  }, [document]);

  useEffect(() => {
    if (visualisationError) {
      if (disableUpload) {
        setDisableUpload(false);
        setError({
          display: true,
          message: visualisationError
        });
      }
      if (showDelete) {
        setError({
          display: false,
          message: visualisationError
        });
      }
    }
    if (updateVisualisationSuccess && showDelete) {
      setShowDelete(false);
    }
  }, [
    visualisationError,
    showDelete,
    updateVisualisationSuccess,
    disableUpload
  ]);

  const handleDrop = useCallback(
    acceptedFile => {
      setDisableUpload(true);
      onDrop(acceptedFile, positionNum);
    },
    [onDrop, positionNum]
  );

  const handleDeleteFile = useCallback(() => {
    document.positionNum = positionNum;
    onDelete(document);
    setUpdateTitle(null);
    setDisableUpload(false);
  }, [document, positionNum, onDelete]);

  const handleRejection = useCallback(
    errors => {
      if (errors.length) {
        errors.map(e => {
          if (e.errors[0].code === "file-invalid-type") {
            setError({
              display: true,
              message: config?.formatError
            });
          } else {
            setError({
              display: true,
              message: visualisationConstants.visualisationAmountError
            });
          }
        });
      }
    },
    [config]
  );

  const onClickExpand = () => {
    setExpand(true);
  };

  const handleCloseVisualisation = () => {
    setExpand(false);
  };

  const onClickDelete = () => {
    if (handleUploadPermission("delete this image")) {
      setShowDelete(true);
    }
  };

  const handleCancelDelete = () => {
    setShowDelete(false);
    setError({
      display: false,
      message: ""
    });
    resetVisualisationError();
  };

  const handleReupload = () => {
    setError({
      display: false,
      message: ""
    });
    setDisableUpload(false);
  };

  const handleChangeTitle = name => {
    setUpdateTitle({
      ...updateTitle,
      name
    });
  };

  const handleSubmitTitle = () => {
    onEditTitle({
      documentId: updateTitle.id,
      name: updateTitle.name.trim()
    });
  };

  const handleClickBox = () => {
    handleUploadPermission("upload an image");
  };

  const renderUploadErrorModal = () => {
    return (
      <ModalTemplate
        boxClassName="tag-editor"
        title={"Upload Failure"}
        onClose={handleReupload}
        content={
          <>
            <span>{error.message}</span>
          </>
        }
        footer={
          <>
            <Button
              type={"primary"}
              disabled={false}
              label="OK"
              onClick={handleReupload}
            />
          </>
        }
      />
    );
  };

  const renderDeleteVisualisationModal = () => {
    return (
      <ModalTemplate
        boxClassName="tag-editor"
        title={"Delete File"}
        onClose={handleCancelDelete}
        content={
          <>
            {error.message && (
              <div className="tag-editor__error-box">
                <InlineAlert type="error" message={error.message} />
              </div>
            )}
            <span>Are you sure you want to delete the file?</span>
          </>
        }
        footer={
          <>
            <Button
              type="secondary"
              label="Cancel"
              onClick={handleCancelDelete}
            />
            <Button
              type={"danger"}
              disabled={false}
              label="Delete"
              onClick={handleDeleteFile}
            />
          </>
        }
      />
    );
  };

  return (
    <div className="visualisation-image">
      <VisualisationTitle
        user={user}
        handleChange={handleChangeTitle}
        handleSubmit={handleSubmitTitle}
        existingTitle={document}
        updateTitle={updateTitle}
        isUploadDisabled={isUploadDisabled}
        handleUploadPermission={handleUploadPermission}
      />
      <div className="visualisation-image__container">
        {document?.image ? (
          <>
            <img
              className="data-image"
              src={document?.image}
              alt={document?.name}
              data-testid="test-visualisation-img"
            />
            <div className="visualisation-icons">
              <i
                className="material-icons visualisation-icons__icon"
                onClick={onClickExpand}
              >
                open_in_full
              </i>
              {user.isHostUser && (
                <i
                  className="material-icons visualisation-icons__icon"
                  onClick={onClickDelete}
                >
                  delete
                </i>
              )}
            </div>

            <PopupOverlay
              isVisible={showDelete}
              isModal={true}
              onClose={handleCancelDelete}
              showClose={false}
              width={"60rem"}
            >
              {renderDeleteVisualisationModal()}
            </PopupOverlay>

            <PopupOverlay
              isVisible={expand}
              isModal={false}
              onClose={handleCloseVisualisation}
              showClose={true}
            >
              <div className="visualisation-canvas">
                <img
                  className="visualisation-canvas__image"
                  src={document?.image}
                />
              </div>
            </PopupOverlay>
          </>
        ) : (
          <>
            {user.isHostUser && (
              <div className="visualisation-image__container__upload">
                <span className="upload-label">{config?.label}</span>
                <UploadFile
                  onDrop={handleDrop}
                  handleDrop={handleDrop}
                  handleRejection={handleRejection}
                  handleClickBox={handleClickBox}
                  supportedDocumentMimes={config?.supportedMimes}
                  maxNumberOfFiles={visualisationConstants.maximumFileNum}
                  disabled={disableUpload || isUploadDisabled}
                  dropMessage={
                    visualisationConstants.supportedDocumentMimesMessage
                  }
                />
                <PopupOverlay
                  isVisible={error.display}
                  isModal={true}
                  onClose={handleReupload}
                  showClose={false}
                  width={"60rem"}
                >
                  {renderUploadErrorModal()}
                </PopupOverlay>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default VisualisationImage;
