import React from "react";

import PropTypes from "prop-types";
import { Oval } from "react-loader-spinner";

import "./ProgressSpinner.scss";

function ProgressSpinner(props) {
  return (
    <div className="ot-progress-spinner">
      <div className="ot-progress-spinner__loader">
        <Oval color="#333" height={28} width={28} />
      </div>
      <div className="ot-progress-spinner__percentage">{`${
        props.percentage || 0
      }%`}</div>
    </div>
  );
}

ProgressSpinner.propTypes = {
  percentage: PropTypes.number.isRequired
};

export default ProgressSpinner;
