import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import websheetUtilities from "@shared/helpers/websheetUtilities";

import DropdownDotMenu from "@components/atoms/DropdownDotMenu";
import Dropdown from "@components/atoms/Form/Dropdown/Dropdown";

const WizardWebsheetColumnHeader = ({
  templateHeaders,
  columnIndex,
  formFieldName,
  handleOpenSplitColumnModal,
  disabled
}) => {
  const { t } = useTranslation();

  const handleMenuItemClick = ({ key }) => {
    if (key === "SPLIT_COLUMN") {
      handleOpenSplitColumnModal(columnIndex);
    }
  };

  return (
    <div className="wizard-websheet__column-header">
      <b>{websheetUtilities.convertNumToColumn(columnIndex + 1)}</b>
      <div className="wizard-websheet__column-header__content">
        <Dropdown
          label={formFieldName}
          showLabel={false}
          name={formFieldName}
          items={templateHeaders.map(th => ({
            name: websheetUtilities.getHeaderNameForSelection(th, t),
            value: th.name
          }))}
          showSearchFilter={false}
          parentId="wizard-websheet__table-header"
          showClear={true}
          scrollWithParent={true}
          disabled={disabled}
          disableWhenNoOptions={true}
          placeholder={t(
            "common:ui.websheet.actions.cleaningWizard.step.selectColumns.dropdownPlaceholder"
          )}
        />
      </div>
      <div className="wizard-websheet__column-header__action">
        <DropdownDotMenu
          positionRelative={true}
          menuItems={[
            {
              key: "SPLIT_COLUMN",
              label:
                "common:ui.websheet.actions.cleaningWizard.step.splitColumn.dropdownLabel"
            }
          ]}
          onMenuItemClick={handleMenuItemClick}
          className="wizard-websheet__column-header__action"
        />
      </div>
    </div>
  );
};

WizardWebsheetColumnHeader.propTypes = {
  templateHeaders: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  columnIndex: PropTypes.number.isRequired,
  formFieldName: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default WizardWebsheetColumnHeader;
