import { menuConstants, routeConstants } from "@app/constants";

const menuItemNames = menuConstants.menuItemNames;
const menuItemTypes = menuConstants.menuItemTypes;

const isAdminSubPages = () => {
  const pathname = window.location.pathname;
  return Object.values(routeConstants.admin).some(
    adminPath => adminPath === pathname
  );
};

const isEngagementSubPages = () => {
  const pathname = window.location.pathname;
  return Object.values(routeConstants.engagement).some(
    engagementPath => engagementPath === pathname
  );
};

const isActionItemsSubPages = () => {
  const pathname = window.location.pathname;
  return Object.values(routeConstants.request).some(
    requestPath => requestPath === pathname
  );
};

const isPageAtEngagementLevel = pathname =>
  pathname === routeConstants.clientDashboard ||
  pathname === routeConstants.manageClientEngagements ||
  pathname.includes(routeConstants.insightsBoard) ||
  pathname.includes(routeConstants.notFound) ||
  isEngagementSubPages();

const getPermanentFilesNavItem = (clientId, permanentFileEnabled) => {
  if (!(clientId && permanentFileEnabled)) {
    return [];
  }
  return [
    {
      name: menuItemNames.permanentFiles,
      link: `/clients/${clientId}/permanent-files`,
      state: { clientId },
      type: menuItemTypes.NAV_LINK
    }
  ];
};
const showProjectTabs = sideBarEnabled => {
  return (
    !sideBarEnabled ||
    (sideBarEnabled &&
      !(
        isPageAtEngagementLevel(window.location.pathname) ||
        window.location.pathname === routeConstants.permanentFiles ||
        window.location.pathname === routeConstants.profile ||
        isAdminSubPages()
      ))
  );
};

const getClientDashboardNavItem = (sideBarEnabled, dashboardLabel) => {
  return sideBarEnabled && isPageAtEngagementLevel(window.location.pathname)
    ? [
        {
          name: dashboardLabel,
          path: routeConstants.clientDashboard,
          type: menuItemTypes.NAV_LINK,
          link: routeConstants.clientDashboard,
          isActive: window.location.pathname === routeConstants.clientDashboard
        }
      ]
    : [];
};

const getOtherProjects = (sideBarEnabled, project, projects, i18nText) => {
  const otherProjects = projects?.filter(({ id }) => id != project?.id);
  if (!project || sideBarEnabled || !otherProjects?.length) {
    return [];
  }
  return [
    {
      name: i18nText("ui.projects.others.label"),
      type: menuItemTypes.NAV_DROPDOWN,
      isNeverActive: true,
      items: otherProjects.map(p => ({
        name: p.name,
        path: `/projects/${p.id}`
      }))
    }
  ];
};

const getProjectNavItems = (
  project,
  interactiveReportMenuItems,
  projectRisksEnabled,
  sideBarEnabled,
  i18nText,
  finalPackageShareStatus = false
) => {
  if (!(project && showProjectTabs(sideBarEnabled))) {
    return [];
  }
  const items = [
    {
      name: project.name,
      link: `/projects/${project.id}`,
      state: { project },
      type: menuItemTypes.NAV_LINK
    },
    ...interactiveReportMenuItems,
    ...getRisksNavItem(project, i18nText, projectRisksEnabled),
    {
      name: i18nText("requests:requests.ui.navigation.title"),
      isActive: isActionItemsSubPages(),
      link: `/projects/${project.id}/queries`,
      state: { project },
      type: menuItemTypes.NAV_LINK
    },
    {
      name: i18nText("common:ui.documents.navigation.title"),
      link: `/projects/${project.id}${routeConstants.dataRepository}`,
      state: { project },
      type: menuItemTypes.NAV_LINK
    }
  ];
  const finalPackageItem = getFinalPackageNavItem(
    project,
    finalPackageShareStatus
  );
  if (finalPackageItem) {
    items.push(finalPackageItem);
  }
  return items;
};
const getFinalPackageNavItem = (project, finalPackageShareStatus) => {
  if (
    !(
      finalPackageShareStatus &&
      project?.configuration?.features?.finalPackage?.enabled
    )
  ) {
    return null;
  }
  return {
    name: project.configuration.finalPackage.name,
    link: `/projects/${project.id}${routeConstants.finalPackage}`,
    state: { project },
    type: menuItemTypes.NAV_LINK
  };
};

const getRisksNavItem = (project, i18nText, projectRisksEnabled = true) => {
  if (!(project && projectRisksEnabled)) {
    return [];
  }
  return [
    {
      name: `${i18nText("risks:risks.ui.navigation.title")}`,
      link: routeConstants.project.risks,
      state: { project },
      type: menuItemTypes.NAV_LINK
    }
  ];
};

const getEngagementsNavItem = (
  engagementsEnabled,
  sideBarEnabled,
  i18nText
) => {
  if (
    !engagementsEnabled ||
    (sideBarEnabled && !isPageAtEngagementLevel(window.location.pathname))
  ) {
    return [];
  }

  return [
    {
      name: `${i18nText("common:ui.engagement.navigation.title")}`,
      isActive: isEngagementSubPages(),
      link: routeConstants.manageClientEngagements,
      type: menuItemTypes.NAV_LINK
    }
  ];
};

const getInsightsNavItem = (sideBarEnabled, i18nText, insightsEnabled) => {
  const existCondition =
    !sideBarEnabled || isPageAtEngagementLevel(window.location.pathname);
  if (!(insightsEnabled && existCondition)) {
    return [];
  }
  return [
    {
      name: `${i18nText("common:insights.navigation.title")}`,
      type: menuItemTypes.NAV_LINK,
      isActive: window.location.pathname.includes(routeConstants.insightsBoard),
      link: routeConstants.insightsBoard
    }
  ];
};

const getInteractiveReport = (
  currentProject,
  interactiveReportEnabled,
  sideBarEnabled,
  i18nText
) => {
  if (
    currentProject &&
    interactiveReportEnabled &&
    showProjectTabs(sideBarEnabled)
  ) {
    return [
      {
        name: i18nText(
          "interactiveReport:interactiveReport.ui.navigation.uploadTitle"
        ),
        link: `/projects/${currentProject.id}/upload-interactive-report`,
        state: { project: currentProject },
        type: menuItemTypes.NAV_LINK
      }
    ];
  } else {
    return [];
  }
};
const getHostAdminNav = ({
  clientId,
  currentProject,
  projects,
  interactiveReportMenuItems,
  i18nText,
  globalTagEnabled,
  projectRisksEnabled,
  permanentFileEnabled,
  dataExtractionEnabled,
  manageNewsEnabled,
  sideBarEnabled,
  dashboardLabel,
  interactiveReportEnabled,
  insightsEnabled,
  engagementsEnabled
}) => {
  const clientDashboardNav = getClientDashboardNavItem(
    sideBarEnabled,
    dashboardLabel
  );
  const permanentFilesNav = getPermanentFilesNavItem(
    clientId,
    permanentFileEnabled && !sideBarEnabled
  );
  const projectNavItems = getProjectNavItems(
    currentProject,
    interactiveReportMenuItems,
    projectRisksEnabled,
    sideBarEnabled,
    i18nText,
    true
  );
  const engagementsNav = getEngagementsNavItem(
    engagementsEnabled,
    sideBarEnabled,
    i18nText
  );
  const insightsNav = getInsightsNavItem(
    sideBarEnabled,
    i18nText,
    insightsEnabled
  );
  const otherProjectsNav = getOtherProjects(
    sideBarEnabled,
    currentProject,
    projects,
    i18nText
  );
  const interactiveReportNav = getInteractiveReport(
    currentProject,
    interactiveReportEnabled,
    sideBarEnabled,
    i18nText
  );
  const navItems = [
    ...clientDashboardNav,
    ...engagementsNav,
    ...insightsNav,
    ...projectNavItems,
    ...permanentFilesNav,
    ...otherProjectsNav,
    ...interactiveReportNav
  ];

  if (!sideBarEnabled) {
    const items = [
      {
        name: i18nText("common:ui.sideNavigation.admin.manageClients"),
        path: routeConstants.manageClients
      },
      {
        name: i18nText("common:ui.sideNavigation.admin.manageTeams"),
        path: routeConstants.manageUsers
      },
      {
        name: i18nText("common:ui.sideNavigation.admin.auditReport"),
        path: routeConstants.manageAudits
      }
    ];
    if (manageNewsEnabled) {
      items.push({
        name: i18nText("common:ui.sideNavigation.admin.manageNews"),
        path: routeConstants.manageNews
      });
    }
    if (dataExtractionEnabled) {
      items.push({
        name: i18nText("common:ui.sideNavigation.admin.dataExtraction"),
        path: routeConstants.dataExtraction
      });
    }
    if (globalTagEnabled) {
      items.push({
        name: i18nText("common:ui.sideNavigation.admin.globalTags"),
        path: routeConstants.globalTags
      });
    }

    const adminNav = {
      name: menuItemNames.admin,
      link: "",
      type: menuItemTypes.NAV_DROPDOWN,
      isActive: isAdminSubPages(),
      items
    };
    navItems.push(adminNav);
  }
  return navItems;
};

const getHostUserNav = ({
  clientId,
  globalTagEnabled,
  currentProject,
  projects,
  interactiveReportMenuItems,
  i18nText,
  projectRisksEnabled,
  permanentFileEnabled,
  manageNewsEnabled,
  interactiveReportEnabled,
  sideBarEnabled,
  dashboardLabel,
  engagementsEnabled
}) => {
  const clientDashboardNav = getClientDashboardNavItem(
    sideBarEnabled,
    dashboardLabel
  );
  const permanentFilesNav = getPermanentFilesNavItem(
    clientId,
    permanentFileEnabled && !sideBarEnabled
  );
  const projectNavItems = getProjectNavItems(
    currentProject,
    interactiveReportMenuItems,
    projectRisksEnabled,
    sideBarEnabled,
    i18nText,
    true
  );
  const engagementsNav = getEngagementsNavItem(
    engagementsEnabled,
    sideBarEnabled,
    i18nText
  );
  const otherProjectsNav = getOtherProjects(
    sideBarEnabled,
    currentProject,
    projects,
    i18nText
  );
  const interactiveReportNav = getInteractiveReport(
    currentProject,
    interactiveReportEnabled,
    sideBarEnabled,
    i18nText
  );
  const navItems = [
    ...clientDashboardNav,
    ...engagementsNav,
    ...projectNavItems,
    ...permanentFilesNav,
    ...otherProjectsNav,
    ...interactiveReportNav
  ];

  if (!sideBarEnabled) {
    const adminNav = {
      name: menuItemNames.admin,
      link: null,
      type: menuItemTypes.NAV_DROPDOWN,
      isActive: isAdminSubPages(),
      items: globalTagEnabled
        ? [
            {
              name: i18nText("common:ui.sideNavigation.admin.manageClients"),
              path: routeConstants.manageClients
            },
            {
              name: i18nText("common:ui.sideNavigation.admin.globalTags"),
              path: routeConstants.globalTags
            }
          ]
        : [
            {
              name: i18nText("common:ui.sideNavigation.admin.manageClients"),
              path: routeConstants.manageClients
            }
          ]
    };
    if (manageNewsEnabled) {
      adminNav.items.push({
        name: i18nText("common:ui.sideNavigation.admin.manageNews"),
        path: routeConstants.manageNews
      });
    }
    navItems.push(adminNav);
  }
  return navItems;
};
const getClientUserNav = ({
  clientId,
  currentProject,
  projects,
  interactiveReportMenuItems,
  sideBarEnabled,
  projectRisksEnabled,
  permanentFileEnabled,
  finalPackageShareStatus,
  i18nText
}) => {
  if (
    sideBarEnabled &&
    (window.location.pathname === routeConstants.clientDashboard ||
      window.location.pathname === routeConstants.permanentFiles ||
      window.location.pathname === routeConstants.profile ||
      isAdminSubPages())
  ) {
    return [];
  }

  let navItems = [];
  if (currentProject) {
    const projectNavItems = getProjectNavItems(
      currentProject,
      interactiveReportMenuItems,
      projectRisksEnabled,
      sideBarEnabled,
      i18nText,
      finalPackageShareStatus
    );
    const clientNavItems = getPermanentFilesNavItem(
      clientId,
      permanentFileEnabled && !sideBarEnabled
    );
    const otherProjectsNav = getOtherProjects(
      sideBarEnabled,
      currentProject,
      projects,
      i18nText
    );
    navItems = [...projectNavItems, ...clientNavItems, ...otherProjectsNav];
  }
  return navItems;
};

const getClientAdminNav = ({
  clientId,
  currentProject,
  projects,
  interactiveReportMenuItems,
  sideBarEnabled,
  projectRisksEnabled,
  permanentFileEnabled,
  finalPackageShareStatus,
  i18nText
}) => {
  if (
    sideBarEnabled &&
    (window.location.pathname === routeConstants.clientDashboard ||
      window.location.pathname === routeConstants.permanentFiles ||
      window.location.pathname === routeConstants.profile ||
      isAdminSubPages())
  ) {
    return [];
  }

  let navItems = getClientUserNav({
    clientId,
    currentProject,
    projects,
    interactiveReportMenuItems,
    sideBarEnabled,
    projectRisksEnabled,
    permanentFileEnabled,
    finalPackageShareStatus,
    i18nText
  });
  if (!sideBarEnabled) {
    const adminNav = {
      name: menuItemNames.admin,
      type: menuItemTypes.NAV_DROPDOWN,
      isActive: isAdminSubPages(),
      items: [
        {
          name: i18nText("common:ui.sideNavigation.admin.manageTeams"),
          path: routeConstants.manageClientUsers
        }
      ],
      state: { client: { id: clientId } }
    };
    navItems.push(adminNav);
  }
  return navItems;
};

const safeLink = (projectId, pageId) =>
  projectId && pageId ? `/projects/${projectId}/pages/${pageId}` : "";

const mapIRMenus = project => menu => ({
  name: menu.name,
  type:
    menu.pages?.length === 1
      ? menuItemTypes.NAV_LINK
      : menuItemTypes.NAV_SUBMENU,
  link:
    menu.pages?.length === 1 ? safeLink(project?.id, menu.pages[0]?.id) : "",
  isActive: false,
  items:
    menu.pages?.map(page => ({
      name: page?.pageName,
      path: safeLink(project?.id, page?.id)
    })) ?? [],
  state: { project }
});

const getErrorPageNav = ({
  user,
  sideBarEnabled,
  i18nText,
  dashboardLabel
}) => {
  let engagementNavItem = [];
  if (user.isHostAdmin || user.isHostUser) {
    engagementNavItem = getEngagementsNavItem(sideBarEnabled, i18nText);
  }
  const clientDashboardNav = getClientDashboardNavItem(
    sideBarEnabled,
    dashboardLabel
  );
  return [...clientDashboardNav, ...engagementNavItem];
};

const getOT1MenuItems = ({
  user,
  clientId,
  currentProject,
  projects,
  interactiveReportMenusData,
  i18nText,
  globalTagEnabled,
  dataExtractionEnabled,
  engagementsEnabled,
  interactiveReportEnabled,
  manageNewsEnabled,
  sideBarEnabled,
  permanentFileEnabled,
  projectRisksEnabled,
  finalPackageShareStatus,
  dashboardLabel,
  insightsEnabled
}) => {
  if (!user.isLoggedIn) {
    return [];
  }
  const interactiveReportMenuItems =
    interactiveReportMenusData?.data?.map(mapIRMenus(currentProject)) ?? [];

  if (window.location.pathname === routeConstants.notFound) {
    return getErrorPageNav({
      user,
      sideBarEnabled,
      i18nText,
      dashboardLabel
    });
  }

  if (user.isHostAdmin) {
    return getHostAdminNav({
      clientId,
      currentProject,
      projects,
      interactiveReportMenuItems,
      i18nText,
      globalTagEnabled,
      projectRisksEnabled,
      permanentFileEnabled,
      dataExtractionEnabled,
      interactiveReportEnabled,
      manageNewsEnabled,
      sideBarEnabled,
      dashboardLabel,
      insightsEnabled,
      engagementsEnabled
    });
  }
  if (user.isHostUser) {
    return getHostUserNav({
      clientId,
      currentProject,
      projects,
      interactiveReportMenuItems,
      i18nText,
      globalTagEnabled,
      projectRisksEnabled,
      permanentFileEnabled,
      manageNewsEnabled,
      interactiveReportEnabled,
      sideBarEnabled,
      dashboardLabel,
      engagementsEnabled
    });
  }
  if (user.isClientAdmin) {
    return getClientAdminNav({
      clientId: user.clientId,
      currentProject,
      projects,
      interactiveReportMenuItems,
      i18nText,
      projectRisksEnabled,
      permanentFileEnabled,
      sideBarEnabled,
      finalPackageShareStatus
    });
  }
  if (user.isClientUser) {
    return getClientUserNav({
      clientId: user.clientId,
      currentProject,
      projects,
      interactiveReportMenuItems,
      i18nText,
      projectRisksEnabled,
      permanentFileEnabled,
      sideBarEnabled,
      finalPackageShareStatus
    });
  }
  return [];
};

const getOT1ProfileMenuItems = ({ handleLogout, i18nText }) => [
  {
    name: i18nText("common:ui.sideNavigation.manageProfile"),
    path: routeConstants.profile,
    icon: "account_circle"
  },
  {
    name: i18nText("common:ui.sideNavigation.logout"),
    path: routeConstants.logout,
    icon: "exit_to_app",
    onClick: handleLogout
  }
];

export const ot1MenuItems = {
  getOT1MenuItems,
  getOT1ProfileMenuItems
};
