import { manageClientUsersConstants } from "../constants";
import { manageClientUsersService } from "../services";
import { manageClientGroupsService } from "../services";

export const manageClientUsersActions = {
  getClientUsers,
  getClientGroups,
  getClientRoles,
  clearError,
  clearAddClientUserFlag,
  clearUpdateClientUserFlag,
  reset,
  isGoogleAuthEnabled,
  resetGoogleAuthFeature
};

function isGoogleAuthEnabled(client) {
  return dispatch => {
    dispatch(request());

    manageClientUsersService.isGoogleAuthEnabled(client).then(
      resObject => {
        dispatch(success(resObject));
      },
      () => {
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: manageClientUsersConstants.GOOGLE_AUTH_FEATURE_REQUEST };
  }
  function success(resObject) {
    return {
      type: manageClientUsersConstants.GOOGLE_AUTH_FEATURE_SUCCESS,
      resObject
    };
  }
  function failure() {
    return { type: manageClientUsersConstants.GOOGLE_AUTH_FEATURE_FAILURE };
  }
}

function getClientUsers(client) {
  return dispatch => {
    dispatch(request());

    manageClientUsersService.getClientUsers(client).then(
      users => {
        dispatch(success(users));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: manageClientUsersConstants.GET_CLIENT_USERS_REQUEST };
  }
  function success(users) {
    return { type: manageClientUsersConstants.GET_CLIENT_USERS_SUCCESS, users };
  }
  function failure(error) {
    return { type: manageClientUsersConstants.GET_CLIENT_USERS_FAILURE, error };
  }
}

function getClientGroups(client) {
  return dispatch => {
    dispatch(request());

    manageClientGroupsService.getClientGroups(client).then(
      groups => {
        dispatch(success(groups));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: manageClientUsersConstants.GET_CLIENT_GROUPS_REQUEST };
  }
  function success(groups) {
    return {
      type: manageClientUsersConstants.GET_CLIENT_GROUPS_SUCCESS,
      groups
    };
  }
  function failure(error) {
    return {
      type: manageClientUsersConstants.GET_CLIENT_GROUPS_FAILURE,
      error
    };
  }
}

function getClientRoles(client) {
  return dispatch => {
    dispatch(request());

    manageClientUsersService.getClientRoles(client).then(
      roles => {
        dispatch(success(roles));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: manageClientUsersConstants.GET_CLIENT_ROLES_REQUEST };
  }
  function success(roles) {
    return {
      type: manageClientUsersConstants.GET_CLIENT_ROLES_SUCCESS,
      roles
    };
  }
  function failure(error) {
    return {
      type: manageClientUsersConstants.GET_CLIENT_ROLES_FAILURE,
      error
    };
  }
}

function clearError() {
  return { type: manageClientUsersConstants.CLEAR_ERROR };
}

function clearAddClientUserFlag() {
  return { type: manageClientUsersConstants.CLEAR_ADD_CLIENT_USER_FLAG };
}

function clearUpdateClientUserFlag() {
  return { type: manageClientUsersConstants.CLEAR_UPDATE_CLIENT_USER_FLAG };
}

function resetGoogleAuthFeature() {
  return { type: manageClientUsersConstants.RESET_GOOGLE_AUTH_FEATURE };
}

function reset() {
  return { type: manageClientUsersConstants.RESET };
}
