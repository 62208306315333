import { useCallback, useState } from "react";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import { useUIConfig } from "./useUIConfig";

export function useHandlePDF() {
  const [PDFIsLoading, setPDFIsLoading] = useState(false);
  const { uiConfig } = useUIConfig();
  const generatePDF = useCallback(
    ({
      element,
      width,
      height,
      html2canvasOptions,
      jsPDFOptions,
      fileName
    }) => {
      html2canvas(element, html2canvasOptions).then(canvas => {
        const imgData = canvas.toDataURL("image/webp");
        const pdfDOC = new jsPDF(jsPDFOptions);
        pdfDOC.addImage(imgData, "WEBP", 0, 0, width, height);
        pdfDOC.save(fileName);
        setPDFIsLoading(false);
      });
    },
    []
  );

  const generatePDFForAnalyticsDashboard = useCallback(
    fileName => {
      setPDFIsLoading(true);
      const printLogo = uiConfig?.pdf?.printLogo !== false;
      const element = document.getElementById("page-template__body__content");
      // set width in fixed full screen value to avoid being affected by current window width
      const width = 1900;
      // get actual height of the element
      element.style.height = "auto";
      const height = element.clientHeight;
      // width of extra right padding
      const widthOffset = 60;
      // height of part of footer
      const heightOffset = printLogo ? 55 : -40;
      const html2canvasOptions = {
        scale: 1.5,
        width,
        windowWidth: width,
        windowHeight: height,
        useCORS: true,
        // used to modify the contents that will be rendered without affecting the original source document
        onclone: clonedDoc => {
          clonedDoc.getElementById(
            "page-template__body__content"
          ).style.backgroundColor = "white";
          clonedDoc.getElementById(
            "page-template__body__content__inner"
          ).style.paddingBottom = "0";
          if (printLogo) {
            clonedDoc.getElementsByClassName(
              "footer"
            )[0].style.backgroundColor = "white";
          }
        }
      };
      const jsPDFOptions = {
        unit: "px",
        orientation: width > height ? "landscape" : "portrait",
        // cut out redundant margin
        format: [width - widthOffset, height - heightOffset],
        hotfixes: ["px_scaling"]
      };
      // chart layout is computed by JS functions based on window size, put them in onclone is not working
      // expand all charts to the full screen size
      [].forEach.call(
        document.getElementsByClassName("analytics-dashboard__grid"),
        element => {
          if (!element.querySelector("#interactive-report-chart")) {
            return;
          }
          // the page layout has 3 grids in a row, and the number of grids occupied by each panel is defined by gridArea
          const {
            style: { gridArea }
          } = element;
          const totalWidth = 1654;
          const grids = gridArea.match(/\d/g);
          // calculate how wide the chart should be expanded as in full screen
          const gridNumber = grids[3] - grids[1];
          // add grid gap width for long panel
          const elementWidth = `${
            (gridNumber / 3) * totalWidth + (gridNumber - 1) * 40
          }px`;
          element.style.width = elementWidth;
        }
      );
      // recharts library functions need some time to redraw the chart layout
      setTimeout(() => {
        generatePDF({
          element,
          width,
          height,
          html2canvasOptions,
          jsPDFOptions,
          fileName
        });
        element.style.height = "";
        [].forEach.call(
          document.getElementsByClassName("analytics-dashboard__grid"),
          element => {
            if (!element.querySelector("#interactive-report-chart")) {
              return;
            }
            element.style.width = "";
          }
        );
      }, 800);
    },
    [generatePDF, uiConfig?.pdf?.printLogo]
  );

  return {
    PDFIsLoading,
    generatePDFForAnalyticsDashboard
  };
}
