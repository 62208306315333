import { useCallback, useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { authActions, resetActions } from "@shared/actions";
import { useDocumentUploadInProgress } from "@shared/hooks";

import { routeConstants } from "@app/constants";

export function useLogout() {
  const authentication = useSelector(state => state.authentication);
  const { documentUploadInProgress } = useDocumentUploadInProgress();
  const interval = useRef(false);
  const [triggerLogout, setTriggerLogout] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const maxTimeKey = "AutoLogoutMaxTime";

  const cancelInterval = useCallback(() => {
    if (interval.current) {
      window.clearInterval(interval.current);
      interval.current = undefined;
    }
  }, [interval]);

  const logout = useCallback(() => {
    cancelInterval();
    dispatch(authActions.logout());
    dispatch(resetActions.reset());
  }, [dispatch, cancelInterval]);

  useEffect(() => {
    if (triggerLogout && !documentUploadInProgress) {
      sessionStorage.removeItem("lastKnownPath");
      logout();
      navigate(routeConstants.login, {
        state: {}
      });
    }
  }, [triggerLogout, documentUploadInProgress, logout, navigate]);

  useEffect(() => {
    const autoLogoutIdleTimer = authentication.user.autoLogoutIdleTimer;
    if (!autoLogoutIdleTimer?.enabled) {
      return;
    }
    const newMaxTime = () => Date.now() + autoLogoutIdleTimer.value * 60 * 1000;
    const updateMaxTime = () => localStorage.setItem(maxTimeKey, newMaxTime());

    const checkMaxTime = () => {
      if (documentUploadInProgress) {
        updateMaxTime();
        return;
      }
      const now = Date.now();
      const maxTime = localStorage.getItem(maxTimeKey);
      if (maxTime && maxTime < now) {
        setTriggerLogout(true);
      }
    };

    cancelInterval();
    updateMaxTime();
    interval.current = setInterval(checkMaxTime, 10 * 1000);
    document.onmousemove = updateMaxTime;
    document.onmousedown = updateMaxTime;
    document.ontouchstart = updateMaxTime;
    document.onclick = updateMaxTime;
    document.onkeydown = updateMaxTime;
    return () => {
      cancelInterval();
      document.onmousemove = undefined;
      document.onmousedown = undefined;
      document.ontouchstart = undefined;
      document.onclick = undefined;
      document.onkeydown = undefined;
      localStorage.removeItem(maxTimeKey);
    };
  }, [
    authentication.user.autoLogoutIdleTimer,
    documentUploadInProgress,
    cancelInterval
  ]);

  return { logout };
}
