import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";
import prepareHeaders from "./rtkUtilities";

export const engagementsService = createApi({
  reducerPath: "engagementsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  tagTypes: ["clientEngagements", "engagements"],
  endpoints: builder => ({
    getClientEngagements: builder.query({
      query: ({ hostId, clientId, engagementId }) => {
        const queryParams = {};
        if (clientId) {
          queryParams.clientId = clientId;
        }
        if (engagementId) {
          queryParams.engagementId = engagementId;
        }
        return `/api/hosts/${hostId}/clients-engagements?${new URLSearchParams(
          queryParams
        ).toString()}`;
      },
      providesTags: ["clientEngagements"]
    }),
    getEngagementById: builder.query({
      query: engagementId => `/api/engagements/${engagementId}`,
      providesTags: ["engagements"]
    }),
    addEngagement: builder.mutation({
      query: engagement => ({
        url: `/api/clients/${engagement.client.id}/engagements`,
        method: "POST",
        body: engagement
      }),
      invalidatesTags: ["clientEngagements", "engagements"]
    }),
    updateEngagement: builder.mutation({
      query: engagement => ({
        url: `/api/clients/${engagement.clientId}/engagements/${engagement.id}`,
        method: "PUT",
        body: engagement
      }),
      invalidatesTags: ["clientEngagements", "engagements"]
    })
  })
});

export const {
  useGetClientEngagementsQuery,
  useGetEngagementByIdQuery,
  useAddEngagementMutation,
  useUpdateEngagementMutation
} = engagementsService;
