import React from "react";

import PropTypes from "prop-types";

import { classNames } from "@app/helpers/componentHelpers";

import "./Text.scss";

export const Text = props => {
  const { value, disabled, fontType, textDecoration, colorStyle, fontSize } =
    props;

  const getTextStyle = () => {
    let styleClasses = "";
    if (disabled) {
      styleClasses += " ot-text--disabled";
    }

    if (fontType) {
      switch (fontType) {
        case "light":
          styleClasses += " ot-text--font-light";
          break;
        case "medium":
          styleClasses += " ot-text--font-medium";
          break;
        case "semi-bold":
          styleClasses += " ot-text--font-semi-bold";
          break;
        case "bold":
          styleClasses += " ot-text--font-bold";
          break;
        default:
          break;
      }
    }
    return styleClasses;
  };

  const getColorStyle = () => {
    switch (colorStyle) {
      case "primary":
        return " ot-text--color-primary";
      case "secondary":
        return " ot-text--color-secondary";
      case "accent":
        return " ot-text--color-accent";
      case "inherit":
        return " ot-text--color-inherit";
      case "tertiary":
        return " ot-text--color-tertiary";
      default:
        return "";
    }
  };

  const getTextDecoration = () => {
    if (textDecoration === "line-through") {
      return " ot-text--decoration-line-through";
    } else if (textDecoration === "underline") {
      return " ot-text--decoration-underline";
    }
    return "";
  };

  const getFontSize = () => {
    if (fontSize) {
      return ` ot-text--font-size-${fontSize}`;
    }
    return "";
  };

  const getClassNames = () =>
    classNames([
      "ot-text",
      getTextStyle(),
      getColorStyle(),
      getTextDecoration(),
      getFontSize()
    ]);

  return <span className={getClassNames()}>{value}</span>;
};

Text.defaultProps = {
  disabled: false,
  fontType: "regular",
  colorStyle: "primary"
};

Text.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  fontType: PropTypes.oneOf([
    "light",
    "regular",
    "medium",
    "semi-bold",
    "bold"
  ]),
  colorStyle: PropTypes.oneOf([
    "primary",
    "secondary",
    "accent",
    "inherit",
    "tertiary"
  ]),
  fontSize: PropTypes.oneOf([
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11"
  ]),
  textDecoration: PropTypes.oneOf(["line-through", "underline", ""])
};

export default Text;
