import { manageClientEntitiesConstants } from "../constants";

const initialState = {
  getEntitiesLoading: false,
  loading: false,
  entities: [],
  nonEntities: [],
  error: "",
  errorWhileAddingClientEntity: "",
  addedEntity: false,
  removedEntity: false
};

export function manageClientEntities(state = initialState, action = {}) {
  switch (action.type) {
    case manageClientEntitiesConstants.GET_CLIENT_ENTITIES_REQUEST:
      return {
        ...state,
        loading: true,
        getEntitiesLoading: true
      };
    case manageClientEntitiesConstants.GET_CLIENT_ENTITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        getEntitiesLoading: false,
        entities: action.entities,
        error: ""
      };
    case manageClientEntitiesConstants.GET_CLIENT_ENTITIES_FAILURE:
      return {
        ...state,
        loading: false,
        getEntitiesLoading: false,
        entities: [],
        error: action.error.key ?? action.error.message
      };
    case manageClientEntitiesConstants.GET_CLIENT_NON_ENTITIES_REQUEST:
    case manageClientEntitiesConstants.ADD_CLIENT_ENTITY_REQUEST:
    case manageClientEntitiesConstants.REMOVE_CLIENT_ENTITY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageClientEntitiesConstants.GET_CLIENT_NON_ENTITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        nonEntities: action.nonEntities,
        errorWhileAddingClientEntity: ""
      };
    case manageClientEntitiesConstants.GET_CLIENT_NON_ENTITIES_FAILURE:
      return {
        ...state,
        loading: false,
        nonEntities: [],
        errorWhileAddingClientEntity: action.error.key ?? action.error.message
      };

    case manageClientEntitiesConstants.ADD_CLIENT_ENTITY_SUCCESS:
      return {
        ...state,
        loading: false,
        addedEntity: true,
        errorWhileAddingClientEntity: ""
      };
    case manageClientEntitiesConstants.ADD_CLIENT_ENTITY_FAILURE:
      return {
        ...state,
        loading: false,
        addedEntity: false,
        errorWhileAddingClientEntity: action.error.key ?? action.error.message
      };

    case manageClientEntitiesConstants.REMOVE_CLIENT_ENTITY_SUCCESS:
      return {
        ...state,
        loading: false,
        removedEntity: true,
        error: ""
      };
    case manageClientEntitiesConstants.REMOVE_CLIENT_ENTITY_FAILURE:
      return {
        ...state,
        loading: false,
        removedEntity: false,
        error: action.error.key ?? action.error.message
      };
    case manageClientEntitiesConstants.RESET_ADDED_ENTITY:
      return {
        ...state,
        error: "",
        addedEntity: false
      };
    case manageClientEntitiesConstants.RESET_REMOVED_ENTITY:
      return {
        ...state,
        error: "",
        removedEntity: false
      };
    case manageClientEntitiesConstants.RESET_CLIENT_ENTITIES:
      return {
        ...state,
        entities: []
      };
    case manageClientEntitiesConstants.RESET_CLIENT_NON_ENTITIES:
      return {
        ...state,
        nonEntities: []
      };
    case manageClientEntitiesConstants.RESET_ERROR_WHILE_ADDING_CLIENT_ENTITY:
      return {
        ...state,
        errorWhileAddingClientEntity: ""
      };
    case manageClientEntitiesConstants.CLEAR_ERROR:
      return {
        ...state,
        error: ""
      };
    case manageClientEntitiesConstants.RESET:
      return initialState;
    default:
      return state;
  }
}
