import React from "react";

import PropTypes from "prop-types";

import "./BarIndicator.scss";

const BarIndicator = props => {
  return (
    <div
      className={`bar-indicator bar-indicator--color-${props.colorScheme}`}
    ></div>
  );
};

BarIndicator.defaultProps = {
  colorScheme: "dark"
};

BarIndicator.propTypes = {
  colorScheme: PropTypes.oneOf(["dark", "success", "error", "warning"])
};

export default BarIndicator;
