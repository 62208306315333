import { useCallback, useState } from "react";

import { useDispatch } from "react-redux";

import { clientDocumentActions } from "@shared/actions";
import { systemConstants } from "@shared/constants";

const documentStatus = systemConstants.project.document.status;

export function useUpdateClientDocument(clientId, document) {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);

  const updateDocumentProperties = useCallback(
    ({ name, status }) => {
      if (!name) {
        setError(true);
        return false;
      }

      setError(false);
      document.name = name;
      document.status =
        status === documentStatus.none
          ? systemConstants.document_status_uploaded
          : status;
      dispatch(
        clientDocumentActions.updateClientDocumentProperties(clientId, document)
      );
      return true;
    },
    [dispatch, document, clientId]
  );

  return {
    error,
    updateDocumentProperties
  };
}
