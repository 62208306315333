import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { routeConstants } from "@constants";

import { useFeatures } from "@shared/hooks";

import { manageClientEngagementProjectsActions } from "../../../actions";
import { systemConstants } from "../../../constants";
import { loggedUser } from "../../../helpers";
import { utilities } from "../../../helpers/utilities";
import TextTruncate from "../../UI/textTruncate/TextTruncate";
import DropdownMenu from "../../dropdown/DropdownMenu";
import Popup from "../../popup/Popup";
import WarningMessage from "../../warningMessage/WarningMessage";
import "./clientEngagementProjectDataTable.scss";

const tableConstants = {
  mail: "Mail",
  notes: "Notes",
  name: "Name",
  status: "Status",
  upcomingMilestone: "Upcoming Milestone",
  projectManager: "Project Manager",
  viewDetails: "View Details"
};

const menuItems = [
  { id: 1, name: "Update Project" },
  { id: 2, name: "Archive Project" }
];

const ClientEngagementProjectDataTable = props => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { authentication, manageClientEngagementProjects } = useSelector(
    state => state
  );
  const dispatch = useDispatch();
  const engagement = props.engagement;
  const mounted = useRef();
  const navigate = useNavigate();
  const [myProjects, setMyProjects] = useState([]);
  const [showRiskFlag, setShowRiskFlag] = useState(false);
  const [
    projectDeactiveWarningVisibility,
    setProjectDeactiveWarningVisibility
  ] = useState(false);
  const [inactivateProject, setInactivateProject] = useState(null);
  const [openItems, setOpenItems] = useState({ openQueries: 0, openRisks: 0 });
  const { isEnabled } = useFeatures();
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    dispatch(
      manageClientEngagementProjectsActions.getMyProjects(authentication.user)
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      manageClientEngagementProjects.myProjects &&
      manageClientEngagementProjects.myProjects.length
    ) {
      setMyProjects([...manageClientEngagementProjects.myProjects]);
    }
    // eslint-disable-next-line
  }, [manageClientEngagementProjects.myProjects]);

  useEffect(() => {
    setShowRiskFlag(isEnabled(systemConstants.features.projectRisks));
  }, [isEnabled]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      // do componentDidUpate logic
      ReactTooltip.rebuild();
    }
  });

  useEffect(() => {
    if (
      manageClientEngagementProjects.openItems.openQueries &&
      manageClientEngagementProjects.openItems.openRisks
    ) {
      setOpenItems({
        ...openItems,
        openQueries:
          manageClientEngagementProjects.openItems.openQueries.length,
        openRisks: manageClientEngagementProjects.openItems.openRisks.length
      });

      setProjectDeactiveWarningVisibility(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageClientEngagementProjects.openItems]);

  const initializeDeactivatingProperties = () => {
    dispatch(manageClientEngagementProjectsActions.clearOpenItems());
    setInactivateProject(null);
    setTimeout(() => {
      setOpenItems({
        ...openItems,
        openQueries: 0,
        openRisks: 0
      });
    }, systemConstants.popup.popupCloseTimeoutForTest);
  };
  const handleMenuItemClick = (event, item) => {
    if (event === "Update Project") {
      onUpdateProject(item);
    } else if (event === "Archive Project") {
      setInactivateProject(item);
      dispatch(manageClientEngagementProjectsActions.getOpenQueries(item));
      dispatch(manageClientEngagementProjectsActions.getOpenRisks(item));
    }
  };

  const handlePopupOutsideClick = () => {
    setProjectDeactiveWarningVisibility(false);
    initializeDeactivatingProperties();
  };

  const handleOkClick = () => {
    dispatch(
      manageClientEngagementProjectsActions.updateProject({
        ...inactivateProject,
        status: systemConstants.project.status.archived
      })
    );
    setProjectDeactiveWarningVisibility(false);
    initializeDeactivatingProperties();
  };

  const onUpdateProject = project => {
    navigate(routeConstants.updateClientEngagementProject, {
      state: {
        engagement,
        project: {
          id: project.id,
          name: project.name,
          manager: { ...project.projectManager },
          startDate: project.startDate,
          plannedCompletionDate: project.plannedCompletionDate,
          milestones: project.milestones
        }
      }
    });
  };

  const getProjectObject = row => {
    return {
      id: row.id,
      name: row.name,
      status: row.status,
      startDate: row.startDate,
      daysBetweenStartDateAndNow: row.daysBetweenStartDateAndNow,
      daysBetweenEndDateAndNow: row.daysBetweenEndDateAndNow,
      daysBetweenStartDateAndEndDate: row.daysBetweenStartDateAndEndDate,
      plannedCompletionDate: row.plannedCompletionDate,
      actualCompletionDate: row.actualCompletionDate,
      projectManagerId: row.projectManagerId,
      projectManager: row.projectManager,
      milestones: row.milestones,
      upcomingMilestone: row.upcomingMilestone,
      unreadMessages: row.unreadMessages,
      engagementId: row.engagementId
    };
  };

  const handleProjectRowClick = project => {
    navigate(routeConstants.project.dashboard, {
      state: {
        project: getProjectObject(project)
      }
    });
  };

  const getTemplateForMenu = row => {
    if (
      row.status !== systemConstants.project.status.archived &&
      (loggedUser.isHostAdmin(authentication.user) ||
        (loggedUser.doesBelongToHost(authentication.user) &&
          myProjects.some(projectObject => projectObject.id === row.id)))
    ) {
      return (
        <td className="client-engagement-project-table__data client-engagement-project-table__data--menu">
          <DropdownMenu
            label={<i className="material-icons table__dots">more_vert</i>}
            menuItems={menuItems}
            handleMenuItemClick={handleMenuItemClick}
            type="text"
            data={row}
          />
        </td>
      );
    } else if (loggedUser.doesBelongToClient(authentication.user)) {
      return null;
    } else {
      return (
        <td className="client-engagement-project-table__data--menu-empty"></td>
      );
    }
  };

  const getInactivateWarningMessage = () => {
    return (
      <div className="client-engagement-project-table__inactivate-warning">
        {!(openItems.openRisks === 0 && openItems.openQueries === 0) ? (
          <>
            <span className="client-engagement-project-table__inactivate-warning-message">
              Assignees will be notified to let them know that no further action
              is required for the following items. Click OK if you wish to
              proceed
            </span>
            <div className="client-engagement-project-table__inactivate-warning-void-items">
              <div className="client-engagement-project-table__inactivate-warning-void-items-item">
                <i className="material-icons client-engagement-project-table__inactivate-warning-void-items-bullet">
                  circle
                </i>
                <span className="client-engagement-project-table__inactivate-warning-void-items-item-request">{`${openItems.openQueries} open request(s)`}</span>
              </div>
              <div className="client-engagement-project-table__inactivate-warning-void-items-item">
                <i className="material-icons client-engagement-project-table__inactivate-warning-void-items-bullet">
                  circle
                </i>
                <span className="client-engagement-project-table__inactivate-warning-void-items-item-risk">{`${openItems.openRisks} open risk(s)`}</span>
              </div>
            </div>
          </>
        ) : (
          <span className="client-engagement-project-table__inactivate-warning-message">
            Are you sure you wish to archive this project?
          </span>
        )}
      </div>
    );
  };

  const getRow = row => {
    return windowWidth > systemConstants.mediaBreakpoints.tabPort ? (
      <tr
        key={row.id}
        className={
          "client-engagement-project-table__data--row " +
          (row && row.status === systemConstants.project.status.archived
            ? "client-engagement-project-table__data--row-disabled"
            : "")
        }
        onClick={() =>
          row.status !== systemConstants.project.status.archived &&
          handleProjectRowClick(row)
        }
      >
        <td className="client-engagement-project-table__data client-engagement-project-table__data--center-icon">
          {row.unreadMessages.length &&
          row.status !== systemConstants.project.status.archived ? (
            <>
              <i
                className="material-icons client-engagement-project-table__data--center-icon-mail-unread"
                data-for={`email-${row.id}`}
                data-tip={""}
              >
                email
              </i>
              <ReactTooltip
                className="client-engagement-project-table__data--center-icon__tooltip"
                multiline={true}
                place="top"
                type="dark"
                effect="solid"
                id={`email-${row.id}`}
              >
                {row.unreadMessages.length === 1
                  ? "1 unread notification"
                  : row.unreadMessages.length + " unread notifications"}
              </ReactTooltip>
            </>
          ) : (
            <>
              {" "}
              <i
                className="material-icons"
                data-for={`drafts-${row.id}`}
                data-tip={""}
              >
                drafts
              </i>
              <ReactTooltip
                className="client-engagement-project-table__data--center-icon__tooltip"
                multiline={true}
                place="top"
                type="dark"
                effect="solid"
                id={`drafts-${row.id}`}
              >
                {"0 unread notifications"}
              </ReactTooltip>{" "}
            </>
          )}
        </td>
        {showRiskFlag && (
          <td
            data-test="risks-row"
            className="client-engagement-project-table__data client-engagement-project-table__data--center-icon"
          >
            <>
              <i
                data-for={`risks-${row.id}`}
                data-tip={""}
                className={
                  "material-icons " +
                  ((row.newRisks || row.blockingRisks) &&
                  row.status !== systemConstants.project.status.archived
                    ? "client-engagement-project-table__data--center-icon-alert-active"
                    : "material-icons")
                }
              >
                flag
              </i>
              <ReactTooltip
                className="client-engagement-project-table__data--center-icon__tooltip"
                multiline={true}
                place="top"
                type="dark"
                effect="solid"
                id={`risks-${row.id}`}
              >
                {row.newRisks === 1
                  ? " 1 open risk"
                  : row.newRisks + " open risks"}
              </ReactTooltip>
            </>
          </td>
        )}
        <td className="client-engagement-project-table__data client-engagement-project-table__data--project-name">
          {row.status !== systemConstants.project.status.archived ? (
            <Link
              to={routeConstants.project.dashboard}
              state={{ project: getProjectObject(row) }}
              className="table__links"
            >
              <TextTruncate
                className="table__links--text-truncate"
                text={row.name}
                maxWidth={utilities.calmpViewWidth(
                  25,
                  utilities.convertPxToVW(110),
                  utilities.convertPxToVW(580)
                )}
              />
            </Link>
          ) : (
            <TextTruncate
              className="table__links--text-truncate"
              text={row.name}
              maxWidth="30vw"
            />
          )}
        </td>
        <td
          className={
            "client-engagement-project-table__data client-engagement-project-table__data--status " +
            (row.status === systemConstants.project.status.behind
              ? "client-engagement-project-table__data--status-behind"
              : "") +
            (row.status === systemConstants.project.status.completed
              ? "client-engagement-project-table__data--status-completed"
              : "") +
            (row.status === systemConstants.project.status.onTrack
              ? "client-engagement-project-table__data--status-ontrack"
              : "")
          }
        >
          {row.status}
        </td>
        <td className="client-engagement-project-table__data client-engagement-project-table__data--project-milestone">
          {row.upcomingMilestone && (
            <div>
              <span>{row.upcomingMilestone.name}</span>
              <div className="client-engagement-project-table__data--project-milestone-box">
                {row.upcomingMilestone.hours < 0
                  ? `${row.upcomingMilestone.in} ago, on ${row.upcomingMilestone.date}`
                  : `In ${row.upcomingMilestone.in}, on ${row.upcomingMilestone.date}`}
              </div>
            </div>
          )}
        </td>
        <td className="client-engagement-project-table__data client-engagement-project-table__data--project-manager-name">
          {row.projectManager.name}
        </td>
        <td className="client-engagement-project-table__data client-engagement-project-table__data--nav">
          {row.status !== systemConstants.project.status.archived ? (
            <Link
              to={routeConstants.project.dashboard}
              state={{ project: getProjectObject(row) }}
              className="table__links"
            >
              {tableConstants.viewDetails}
            </Link>
          ) : (
            <span>{tableConstants.viewDetails}</span>
          )}
        </td>
        {row.status !== systemConstants.project.status.archived &&
        (loggedUser.isHostAdmin(authentication.user) ||
          (loggedUser.doesBelongToHost(authentication.user) &&
            myProjects.some(projectObject => projectObject.id === row.id))) ? (
          <td className="client-engagement-project-table__data client-engagement-project-table__data--menu">
            <DropdownMenu
              label={<i className="material-icons table__dots">more_vert</i>}
              menuItems={menuItems}
              handleMenuItemClick={handleMenuItemClick}
              type="text"
              data={row}
            />
          </td>
        ) : (
          <td className="client-engagement-project-table__data--menu-empty"></td>
        )}
      </tr>
    ) : (
      <tr
        key={row.id}
        className={
          "client-engagement-project-table__data--row" +
          (row && row.status === systemConstants.project.status.archived
            ? " client-engagement-project-table__data--row-disabled"
            : "")
        }
        onClick={() =>
          row.status !== systemConstants.project.status.archived &&
          handleProjectRowClick(row)
        }
      >
        <td className="client-engagement-project-table__data--project-name-logo-box">
          <img
            className="client-engagement-project-table__data--project-name-logo"
            src="/logo-gray-oneteam.png"
          />
        </td>
        <td className="client-engagement-project-table__data client-engagement-project-table__data--project-name">
          {row.status === systemConstants.project.status.archived ? (
            <span className="client-engagement-project-table__data--project-name-text-disabled">
              {row.name}
            </span>
          ) : (
            <span
              className="client-engagement-project-table__data--project-name-text"
              onClick={() => handleProjectRowClick(row)}
            >
              {row.name}
            </span>
          )}
        </td>
        <td
          className={
            "client-engagement-project-table__data client-engagement-project-table__data--status " +
            (row.status === systemConstants.project.status.behind
              ? "client-engagement-project-table__data--status-behind"
              : "") +
            (row.status === systemConstants.project.status.completed
              ? "client-engagement-project-table__data--status-completed"
              : "") +
            (row.status === systemConstants.project.status.onTrack
              ? "client-engagement-project-table__data--status-ontrack"
              : "")
          }
        >
          {row.status}
        </td>

        {getTemplateForMenu(row)}
      </tr>
    );
  };
  return (
    <>
      <table className="table">
        <tbody className="table__body">
          {windowWidth > systemConstants.mediaBreakpoints.tabPort ? (
            <tr className="table__row">
              <th className="table__head">{tableConstants.mail}</th>
              {showRiskFlag && (
                <th data-test="risks-column-header" className="table__head">
                  Risks
                </th>
              )}
              <th className="table__head">{tableConstants.name}</th>
              <th className="table__head">{tableConstants.status}</th>
              <th className="table__head">
                {tableConstants.upcomingMilestone}
              </th>
              <th className="table__head">{tableConstants.projectManager}</th>
              <th className="table__head"></th>
            </tr>
          ) : (
            <tr className="table__row">
              <th className="table__head"></th>
              <th className="table__head">{tableConstants.name}</th>
              <th className="table__head">{tableConstants.status}</th>
              {loggedUser.doesBelongToHost(authentication.user) ? (
                <th className="table__head"></th>
              ) : null}
            </tr>
          )}

          {props.data.map(row => getRow(row))}
        </tbody>
      </table>
      <Popup
        visibility={projectDeactiveWarningVisibility}
        handleOutsideClick={false}
        width="50rem"
      >
        <WarningMessage
          title={"Archive project"}
          message={getInactivateWarningMessage()}
          onYes={handleOkClick}
          onCancel={handlePopupOutsideClick}
          confirmationName={"OK"}
        />
      </Popup>
    </>
  );
};

ClientEngagementProjectDataTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      milestones: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          status: PropTypes.string.isRequired,
          date: PropTypes.string.isRequired
        })
      ).isRequired,
      projectManager: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired
    })
  )
};

export default ClientEngagementProjectDataTable;
