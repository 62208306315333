import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import HoverText from "@components/atoms/HoverText";
import Icon from "@components/atoms/Icon";

import "./EntitiesListIconCell.scss";

const EntitiesListIconCell = props => {
  const { entities } = props;
  const { t } = useTranslation();
  const hoverElement = (
    <span className="relevant-entities">
      {`${t("requests:requests.ui.smartForm.relevantEntities.label")}:`}
      <span className="relevant-entities__names">{entities.join(", ")}</span>
    </span>
  );
  return entities.length ? (
    <div className="entities-list-icon-cell" role="cell-relevant-entities-icon">
      <Icon name="lan" size="medium" />
      <HoverText
        isActive={true}
        name={hoverElement}
        maxWidth={250}
        position={"right"}
      />
    </div>
  ) : null;
};

EntitiesListIconCell.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.string)
};

export default EntitiesListIconCell;
