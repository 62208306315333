import React, { memo } from "react";
import PropTypes from "prop-types";

import { HotTable } from "@handsontable/react";
import "handsontable/dist/handsontable.full.css";
import { registerAllModules } from "handsontable/registry";

import LoaderSpinner from "@components/atoms/LoaderSpinner";

import "./TableContent.scss";

registerAllModules();

/**
 *
 * @param {object} options
 * @param {boolean} options.loading
 * @param {boolean} options.error
 * @param {object} options.settings
 * @param {object} options.hotTableComponent
 * @returns
 */
const TableContent = ({ loading, error, settings, hotTableComponent }) => {
  if (loading) {
    return (
      <div className="table-content__spinner">
        <LoaderSpinner diameter="30px" />
      </div>
    );
  }
  if (error) {
    return null;
  }

  return (
    <div id="handsontable-id" className="hot table-content__table">
      {settings && (
        <HotTable ref={hotTableComponent} settings={settings} comments={true} />
      )}
    </div>
  );
};

TableContent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  settings: PropTypes.object,
  hotTableComponent: PropTypes.object
};

export default memo(TableContent);
