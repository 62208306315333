import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";

export const actionItemTypesService = createApi({
  reducerPath: "actionItemTypesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders: (headers, { getState }) => {
      const token =
        getState().authentication?.token || localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["ActionItemType", "CreatableActionItemType"],
  endpoints: builder => ({
    getCreatableActionItemTypes: builder.query({
      query: ({ engagementTypeId, viewScope, projectId }) =>
        engagementTypeId
          ? `/api/engagementTypes/${engagementTypeId}/projects/${projectId}/actionItemTypes/creatable?from=${
              viewScope ?? ""
            }`
          : null,
      providesTags: ["CreatableActionItemType"]
    }),
    getActionItemTypes: builder.query({
      query: ({ engagementTypeId, projectId }) =>
        `/api/engagementTypes/${engagementTypeId}/projects/${projectId}/actionItemTypes`,
      providesTags: ["ActionItemType"]
    })
  })
});

export const {
  useGetActionItemTypesQuery,
  useGetCreatableActionItemTypesQuery
} = actionItemTypesService;
