import React, { useEffect, useState } from "react";

import jwtDecode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { routeConstants } from "@constants";

import { useStaticAssets } from "@shared/hooks";

import { authActions } from "../../../actions";
import { systemConstants } from "../../../constants";
import ErrorBox from "../../errorBox/ErrorBox";
import AuthContainer from "../authContainer/AuthContainer";
import "./resetPassword.scss";

const { serverURL } = systemConstants;

const ResetPassword = props => {
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const authentication = useSelector(state => state.authentication);
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const token = query.get("token") || null;
  const userId = token && jwtDecode(token) ? jwtDecode(token).id : null;
  const [hostObject, sethostObject] = useState(null);
  const [passwordInput, setPasswordInput] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setError] = useState({});
  const [formState, setFormState] = useState({});
  const { getUrl } = useStaticAssets();

  useEffect(() => {
    dispatch(
      authActions.verifyResetPasswordToken({ id: userId, passwordToken: token })
    );
    if (!authentication.host) {
      dispatch(authActions.getHostWithBrandDetails());
    }

    return () => dispatch(authActions.resetResetPasswortTokenVerification());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sethostObject(authentication.host);

    // eslint-disable-next-line
  }, [authentication.host]);

  useEffect(() => {
    if (!authentication.verificationDone) return;
    setSubmitSuccess(null);
    if (authentication.resetPassWord) {
      setSubmitSuccess(true);
    }
    if (authentication.error) {
      setSubmitSuccess(false);
      setError({
        ...errors,
        form: {
          type: "submitError",
          message:
            "There was a problem when resetting your password, please try again later."
        }
      });
      dispatch(authActions.clearError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authentication, authentication.verificationDone]);

  useEffect(() => {
    if (!passwordInput) {
      setFormState({
        ...formState,
        isValid: false
      });
      return;
    }

    const result = validatePassword(passwordInput);
    setError({
      ...errors,
      password: {
        types: result
      }
    });

    const hasNoValidationErrors = Object.values(result).every(r => r === false);
    setFormState({
      ...formState,
      isValid: hasNoValidationErrors
    });
    // setError
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordInput]);

  const clearError = errorScopes => {
    errorScopes.forEach(err => {
      setError({
        ...errors,
        [err]: null
      });
    });
  };

  const validatePassword = candidate => {
    // each entry will be TRUE if validation is violated
    const passwordValidationResult = {};

    const criteria = {
      maxLength: 30,
      minLength: 8,
      required: true,
      validate: {
        hasNumber: value => !!value.match(/[0-9]+/),
        hasSpecialChars: value =>
          !!value.match(
            // eslint-disable-next-line no-useless-escape
            /[\!\@\#\$\%\^\&\*\_\+\-\?\.\,\>\<\"\(\)\=\'\\\|\[\]]+/
          ),
        hasUpperLetter: value => !!value.match(/[A-Z]+/),
        hasLowerLetter: value => !!value.match(/[a-z]+/)
      }
    };

    passwordValidationResult["maxLength"] = !(
      candidate.length <= criteria.maxLength
    );
    passwordValidationResult["minLength"] = !(
      candidate.length >= criteria.minLength
    );

    for (const [rule, ruleCheckFn] of Object.entries(criteria.validate)) {
      const ruleCheckResult = ruleCheckFn(candidate);
      passwordValidationResult[rule] = !ruleCheckResult;
    }

    passwordValidationResult["required"] = !candidate;

    return passwordValidationResult;
  };

  const handlePasswordChange = event => {
    clearError(["form"]);
    setPasswordInput(event.target.value);
  };

  const handleConfirmPasswordChange = event => {
    clearError(["form"]);
    setConfirmPassword(event.target.value);
  };

  const onSubmit = event => {
    event.preventDefault();
    setFormState({
      ...formState,
      isSubmitted: false
    });
    if (passwordInput !== confirmPassword) {
      setError({
        ...errors,
        form: {
          type: "mismatchPassword",
          message: "The passwords you entered do not match."
        }
      });
      return;
    }

    if (userId && passwordInput && token) {
      dispatch(
        authActions.resetPassword({
          id: userId,
          password: passwordInput,
          passwordToken: token
        })
      );
      setFormState({
        ...formState,
        isSubmitted: true
      });
    }
  };

  const isDisabled = () => {
    return !formState.isValid || !token || !userId;
  };

  const getIconTemplate = errorTypes => {
    const hasError = !!errorTypes.find(type => errors?.password?.types[type]);
    return (
      <>
        {!!passwordInput ? (
          <i
            data-testid="password-validation-icon"
            className={`material-icons ${
              hasError ? "text-error" : "text-success"
            }`}
          >
            {hasError ? "close" : "done"}
          </i>
        ) : (
          <i
            data-testid="password-validation-icon"
            className="material-icons reset-password__icon--init"
          >
            fiber_manual_record
          </i>
        )}
      </>
    );
  };

  const getFormTitle = () => {
    return submitSuccess !== true &&
      !authentication.resetPasswordTokenVerified ? (
      <>
        <span className="auth-container__host-header-name">
          Link has expired
        </span>
      </>
    ) : (
      <>
        <span className="auth-container__host-header-name">Reset password</span>
      </>
    );
  };
  const getFormSubmittedMessage = () => {
    return (
      <div
        data-testid="reset-password-success"
        className="auth-container__success"
      >
        <i className="material-icons auth-container__success-icon">lock</i>
        <p>
          Your password has been reset successfully. Please click the link below
          to login again.
        </p>
        <Link to={routeConstants.login} className="auth-container__link">
          <i className="material-icons">keyboard_arrow_left</i> Back to login
        </Link>
      </div>
    );
  };

  const getResetPasswordForm = () => {
    return (
      <>
        <form className="reset-password-form" onSubmit={onSubmit}>
          {(!token || !userId) && (
            <ErrorBox
              data-testid="reset-password-error-token"
              type="component"
              message={"Invalid token to reset password."}
            ></ErrorBox>
          )}
          {errors.form && (
            <ErrorBox
              type="component"
              data-testid="reset-password-form-error"
              message={errors.form.message}
            ></ErrorBox>
          )}
          {errors.form && <div data-testid="ajt">JSON.stringify(errors)</div>}
          <div className="form-group">
            <label htmlFor="password" className="form-label">
              New Password
            </label>
            <input
              type="password"
              className={`form-control ${errors.password ? "is-invalid" : ""} `}
              name="password"
              data-testid="reset-password-password"
              placeholder="New Password"
              autoComplete="off"
              required
              autoFocus={true}
              onChange={handlePasswordChange}
              value={passwordInput}
            />
            <div className="reset-password-validation">
              <p>Must include:</p>
              <ul>
                <li>
                  {getIconTemplate(["minLength", "maxLength"])}8 to 30
                  characters
                </li>
                <li>{getIconTemplate(["hasNumber"])}1 number</li>
                <li>
                  {getIconTemplate(["hasLowerLetter"])}1 lower case letter
                </li>
                <li>
                  {getIconTemplate(["hasUpperLetter"])}1 upper case letter
                </li>
                <li>
                  {getIconTemplate(["hasSpecialChars"])}1 special character{" "}
                  {"( ! @ # $ % ^ & * _ + - ? ( ) . , > < \" ' \\ | = [ ] )"}
                </li>
              </ul>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="confirmPassword" className="form-label">
              Confirm Password
            </label>
            <input
              type="password"
              className="form-control"
              name="confirmPassword"
              data-testid="reset-password-confirm-password"
              placeholder="Confirm Password"
              autoComplete="off"
              onChange={handleConfirmPasswordChange}
              required
              value={confirmPassword}
            />
          </div>

          <div className="auth-container__actions reset-password__actions">
            <Link
              to={routeConstants.login}
              className="auth-container__link pull-left"
            >
              <i className="material-icons">keyboard_arrow_left</i> Back to
              login
            </Link>
            <button
              className="btn primary"
              type="submit"
              data-testid="reset-password-submit"
              disabled={isDisabled()}
            >
              Update Password
            </button>
          </div>
        </form>
      </>
    );
  };

  const getLinkExpiredMessage = () => {
    return (
      <>
        <div className="auth-container__warning reset-form__link-expired-message">
          <i className="material-icons auth-container__warning-icon">warning</i>
          <p>
            The reset password request has expired. A request can only be used
            once and it is valid for 24 hours. Please click the following link
            to request a new reset password email.
          </p>
          <Link
            to={routeConstants.forgotPassword}
            className="auth-container__link"
          >
            Resend reset password email
          </Link>
        </div>
      </>
    );
  };

  return (
    authentication.verificationDone && (
      <AuthContainer>
        <div className="auth-container__host-header">
          {getFormTitle()}
          {hostObject && (
            <img
              src={getUrl("authLogo.png")}
              alt="Logo"
              className="auth-container__host-header-logo"
            />
          )}
        </div>
        {formState.isSubmitted && submitSuccess === true
          ? getFormSubmittedMessage()
          : authentication.resetPasswordTokenVerified
          ? getResetPasswordForm()
          : getLinkExpiredMessage()}
      </AuthContainer>
    )
  );
};

export default ResetPassword;
