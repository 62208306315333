import React, { useCallback, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import CheckBox from "@components/atoms/CheckBox/CheckBox";
import Form from "@components/atoms/Form/";
import Icon from "@components/atoms/Icon/Icon";

import "./MilestoneField.scss";

const MilestoneField = props => {
  const {
    id,
    isDeleteVisible,
    isItemCompleted,
    index,
    defaultName,
    minDate,
    maxDate,
    register,
    handleDelete,
    labelConfigEnabled,
    labelConfigItems
  } = props;

  const { t } = useTranslation();
  const [showDefault, setShowDefault] = useState(true);

  const onChange = useCallback(val => {
    //default behaviour for forms when value is cleared is to set to default value, this overrides that
    if (val === undefined) {
      setShowDefault(false);
    }
  }, []);

  const noSortingComparator = () => 0;
  const labelFields = useMemo(() => {
    if (labelConfigEnabled) {
      return (
        <Form.Dropdown
          label={t("ui.milestone.form.name.label")}
          placeholder={t("ui.milestone.form.name.placeholder")}
          items={labelConfigItems}
          required={true}
          name={`milestoneForm.${index}.name`}
          showClear={true}
          showSearchFilter={false}
          disabled={isItemCompleted}
          onChange={onChange}
          defaultValue={showDefault ? defaultName : null}
          sortComparator={noSortingComparator}
        />
      );
    }
    return (
      <Form.TextField
        label={t("ui.milestone.form.name.label")}
        name={`milestoneForm.${index}.name`}
        placeholder={t("ui.milestone.form.name.placeholder")}
        required={true}
        disabled={isItemCompleted}
      />
    );
  }, [
    defaultName,
    index,
    isItemCompleted,
    labelConfigEnabled,
    labelConfigItems,
    onChange,
    showDefault,
    t
  ]);

  return (
    <div className="milestone-field" key={id}>
      {labelFields}
      <Form.DateField
        label={t("ui.milestone.form.date.label")}
        name={`milestoneForm.${index}.date`}
        required={true}
        disabled={isItemCompleted}
        {...(minDate ? { minDate } : {})}
        {...(maxDate ? { maxDate } : {})}
      />
      <div className="milestone-field--select">
        <CheckBox formProps={register(`milestoneForm.${index}.completed`)} />
      </div>
      <div
        style={{
          visibility: isDeleteVisible ? "visible" : "hidden"
        }}
        className="milestone-field--delete"
      >
        <Icon
          name="delete"
          designStyle="material-icons"
          fillStyle="filled"
          {...(isDeleteVisible ? { onClick: () => handleDelete(index) } : {})}
        />
      </div>
    </div>
  );
};

export default MilestoneField;
