import React from "react";

import "./logoHolder.scss";

const type = {
  simple: "simple",
  complex: "complex"
};

const LogoHolder = props => {
  const handleChangeClick = () => {
    props.handleLogoChangeClick && props.handleLogoChangeClick();
  };

  const handleRemoveClick = () => {
    props.handleRemoveLogoClick && props.handleRemoveLogoClick();
  };

  return (
    <div className="logo__container">
      <div className="logo__image-box">
        {props.file && (
          <img src={props.file} alt="Logo" className="logo__image" />
        )}
        {props.type === type.complex && (
          <div className="logo__container--details">
            <div className="logo__text logo__text--name">
              {props.clientName}
            </div>
          </div>
        )}
      </div>

      <div className="logo__action">
        {props.changable && (
          <span onClick={handleChangeClick} className="logo__action--text">
            {props.file ? "Change" : "Add"}
          </span>
        )}
        {props.removable && (
          <span onClick={handleRemoveClick} className="logo__action--text">
            Remove
          </span>
        )}
      </div>
    </div>
  );
};

export default LogoHolder;
