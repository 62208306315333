import { manageTaskTemplatesConstants } from "../constants";

const initialState = {
  loading: false,
  taskTemplates: [],
  error: ""
};

export function manageTaskTemplates(state = initialState, action = {}) {
  switch (action.type) {
    case manageTaskTemplatesConstants.GET_TASK_TEMPLATES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageTaskTemplatesConstants.GET_TASK_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        taskTemplates: action.taskTemplates || [],
        error: ""
      };
    case manageTaskTemplatesConstants.GET_TASK_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        taskTemplates: [],
        error: action.error.key ?? action.error.message
      };
    case manageTaskTemplatesConstants.RESET_TASK_TEMPLATES:
      return {
        ...state,
        loading: false,
        taskTemplates: [],
        error: ""
      };
    default:
      return state;
  }
}
