import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { routeConstants } from "@constants";

import { manageHostUsersActions } from "@shared/actions";

import Popup from "@shared-components/popup/Popup";

import BrandButton from "@components/atoms/Button/BrandButton";
import DropdownDotMenu from "@components/atoms/DropdownDotMenu";
import InlineAlert from "@components/atoms/InlineAlert";
import ModalTemplate from "@components/templates/ModalTemplate";

import "./GlobalTagsDataTable.scss";

const tableConstants = {
  engagementType: "Engagement Type",
  tags: "Tags",
  documents: "Documents",
  queries: "Requests"
};

const dropdownActionKeys = {
  deleteTag: {
    key: "deleteTag",
    label: "common:ui.globalTags.menu.deleteTag"
  },
  updateTag: {
    key: "updateTag",
    label: "common:ui.globalTags.menu.updateTag"
  }
};

const GlobalTagsDataTable = props => {
  const { t } = props;
  const dispatch = useDispatch();
  const [deleteTagShow, setDeleteTagShow] = useState(false);

  const [tag, setTag] = useState();
  const [errorDeleteShow, setErrorDeleteShow] = useState(false);
  const [warningDeleteShow, setWarningDeleteShow] = useState(false);
  const manageHostUsers = useSelector(state => state.manageHostUsers);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (manageHostUsers.error) {
      setError(manageHostUsers.error);
      setErrorDeleteShow(true);
    }
  }, [manageHostUsers.error]);

  const handleMenuClick = (actionKey, data) => {
    if (dropdownActionKeys.deleteTag.key === actionKey) {
      if (data.documentsCount !== 0 || data.queriesCount !== 0) {
        setWarningDeleteShow(true);
      } else {
        setDeleteTagShow(true);
        setTag(data);
      }
    } else if (dropdownActionKeys.updateTag.key === actionKey) {
      handleUpdateTag(data);
    }
  };

  const handleUpdateTag = data => {
    navigate(routeConstants.addTag, {
      state: { tag: data }
    });
  };

  const handleDeleteClick = () => {
    if (tag) {
      setDeleteTagShow(false);
      const tagId = tag.tagId;
      if (!tag.isVirtual) {
        const tagToBeDeleted = {
          tagId: tagId,
          tagName: tag.name
        };
        dispatch(
          manageHostUsersActions.deleteGlobalTag(
            tag.engagementType.id,
            tagToBeDeleted
          )
        );
      }
    }
  };

  const handleCancelDelete = () => {
    setDeleteTagShow(false);
  };

  const handleWarningDelete = () => {
    setWarningDeleteShow(false);
  };

  const handleErrorDelete = () => {
    setErrorDeleteShow(false);
    manageHostUsers.error = "";
  };

  const isFirstTagRowInEngagement = row => {
    if (row.engagementType.name !== "") {
      return "global-tags-row--group-start";
    }
    return "";
  };

  const handleKeyPress = event => {
    if (event.key === "Escape") {
      if (warningDeleteShow) {
        setWarningDeleteShow(false);
      }
      if (errorDeleteShow) {
        setErrorDeleteShow(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  });

  const getRow = row => {
    const key = row.isVirtual
      ? `Engagement_${row.engagementType.id}`
      : `Engagement_${row.engagementType.id}_Tag_${row.tagId}`;
    return (
      <div
        key={key}
        className={`global-tags-row ${isFirstTagRowInEngagement(row)}`}
      >
        <div className="global-tags-row__field global-tags-row__field--engagement-type">
          {row.engagementType.name}
        </div>
        <div className="global-tags-row__field">
          {row.name ? <div> {row.name} </div> : <></>}
        </div>
        <div className="global-tags-row__field">{row.documentsCount}</div>
        <div className="global-tags-row__field">{row.queriesCount}</div>
        <div className="global-tags-row__field global-tags-row__field--menu">
          {row.isVirtual ? (
            <></>
          ) : (
            <>
              <DropdownDotMenu
                menuItems={[
                  {
                    key: dropdownActionKeys.deleteTag.key,
                    label: t(dropdownActionKeys.deleteTag.label)
                  },
                  {
                    key: dropdownActionKeys.updateTag.key,
                    label: t(dropdownActionKeys.updateTag.label)
                  }
                ]}
                onMenuItemClick={menuItem => handleMenuClick(menuItem.key, row)}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="global-tags-table">
        <div className="global-tags-table__header">
          <div className="global-tags-table__header-label">
            {tableConstants.engagementType}
          </div>
          <div className="global-tags-table__header-label">
            {tableConstants.tags}
          </div>
          <div className="global-tags-table__header-label">
            {tableConstants.documents}
          </div>
          <div className="global-tags-table__header-label">
            {tableConstants.queries}
          </div>
        </div>
        <div className="global-tags-table__body">
          {props.data.map(row => getRow(row))}
        </div>
      </div>
      <Popup
        visibility={deleteTagShow}
        handleOutsideClick={false}
        width="60rem"
      >
        <ModalTemplate
          boxClassName="tag-deletion"
          title={t("common:ui.globalTags.deleteTagTitle")}
          content={t("common:ui.globalTags.deleteTagConfirmation")}
          footer={
            <>
              <BrandButton
                type="secondary"
                label="Cancel"
                onClick={handleCancelDelete}
              />
              <BrandButton
                type="danger"
                label="Yes"
                onClick={handleDeleteClick}
              />
            </>
          }
          onClose={handleCancelDelete}
        />
      </Popup>
      <Popup visibility={warningDeleteShow} type={"component"} width="60rem">
        <ModalTemplate
          boxClassName="tag-deletion"
          title={t("common:ui.globalTags.deleteTagTitle")}
          content={
            <InlineAlert
              type="error"
              message={t("common:ui.globalTags.deleteTagHasAssociations")}
            />
          }
          footer={
            <>
              <BrandButton
                type="primary"
                label="Close"
                onClick={handleWarningDelete}
              />
            </>
          }
          onClose={handleWarningDelete}
        />
      </Popup>
      {error && (
        <Popup visibility={errorDeleteShow} type={"component"} width="60rem">
          <ModalTemplate
            boxClassName="tag-deletion"
            title={t("common:ui.globalTags.deleteTagTitle")}
            content={<InlineAlert type="error" message={t(error)} />}
            footer={
              <>
                <BrandButton
                  type="primary"
                  label="Close"
                  onClick={handleErrorDelete}
                />
              </>
            }
            onClose={handleErrorDelete}
          />
        </Popup>
      )}
    </>
  );
};

GlobalTagsDataTable.defaultProps = {};

GlobalTagsDataTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      tagId: PropTypes.number.isRequired,
      isVirtual: PropTypes.bool,
      engagementType: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      }),
      name: PropTypes.string.isRequired,
      documentsCount: PropTypes.number.isRequired,
      queriesCount: PropTypes.number.isRequired
    })
  )
};

export default withTranslation()(GlobalTagsDataTable);
