import React, { useCallback, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants/systemConstants";
import { useFeatures } from "@shared/hooks";

import TextTruncate from "@shared-components/UI/textTruncate/TextTruncate";

import { useDataTable } from "@app/hooks";

import DataTable from "@components/molecules/DataTable";

const statusesToIndicatorColorScheme = {
  [systemConstants.client.status.active]: "success",
  [systemConstants.client.status.inactive]: "dark"
};

const actions = {
  manageEntities: {
    key: "manageEntities",
    label: "common:ui.manageClients.menu.manageEntities"
  },
  updateClient: {
    key: "updateClient",
    label: "common:ui.manageClients.menu.updateClient"
  },
  manageClientTags: {
    key: "manageClientTags",
    label: "common:ui.manageClients.menu.manageClientTags"
  },
  manageUsers: {
    key: "manageUsers",
    label: "common:ui.manageClients.menu.manageUsers"
  }
};

const tableConstants = {
  clientName: "Client Name",
  acn: "ACN",
  abn: "ABN",
  usersCount: "Users"
};

const ClientDataTable = props => {
  const {
    clients,
    userRole,
    onHandleManageEntities,
    onHandleUpdateClient,
    onHandleManageClientTags,
    onHandleManageClientUsers,
    t
  } = props;

  const {
    findItem,
    createColumnForStatus,
    createColumn,
    createColumnForSingleAction,
    createColumnForDropdownMenu
  } = useDataTable(clients);
  const [rowDisplay, setRowDisplay] = useState({ rowId: -1, display: false });
  const { isEnabled } = useFeatures();

  const clientTagEnabled = useMemo(
    () => isEnabled(systemConstants.features.clientTags),
    [isEnabled]
  );

  const handleAction = useCallback(
    (actionKey, clientId) => {
      const client = findItem(clientId);
      setRowDisplay({ rowId: -1, display: false });
      if (actionKey === actions.manageEntities.key) {
        onHandleManageEntities(client);
      } else if (actionKey === actions.updateClient.key) {
        onHandleUpdateClient(client);
      } else if (actionKey === actions.manageClientTags.key) {
        onHandleManageClientTags(client);
      } else if (actionKey === actions.manageUsers.key) {
        onHandleManageClientUsers(client);
      }
    },
    [
      findItem,
      onHandleManageEntities,
      onHandleUpdateClient,
      onHandleManageClientTags,
      onHandleManageClientUsers
    ]
  );

  const singleActionHandler = useCallback(
    actionKey =>
      ({ cell }) => {
        const clientId = cell.row.original.id;
        handleAction(actionKey, clientId);
      },
    [handleAction]
  );

  const dropdownMenuActionHandler = useCallback(
    ({ menuItem, cell }) => {
      const clientId = cell.row.original.id;
      const actionKey = menuItem.key;
      handleAction(actionKey, clientId);
    },
    [handleAction]
  );

  const cellVisibilityHandler = useCallback(
    rowId => {
      return rowDisplay?.rowId === rowId && rowDisplay?.display;
    },
    [rowDisplay]
  );

  const columns = useMemo(
    () => [
      createColumnForStatus({ accessor: "statusIndicatorColorScheme" }),
      createColumn({
        Header: tableConstants.clientName,
        accessor: "name",
        width: 250,
        Cell: ({ value }) => <TextTruncate text={value} inline={true} />
      }),
      createColumn({ Header: tableConstants.acn, accessor: "acn", width: 125 }),
      createColumn({ Header: tableConstants.abn, accessor: "abn", width: 125 }),
      createColumn({
        Header: tableConstants.usersCount,
        accessor: "usersCount",
        className: "users-count",
        width: 125
      }),
      createColumnForSingleAction({
        accessor: "action",
        className: "manage-users",
        width: 150,
        fixedWidth: true,
        onClickHandler: singleActionHandler(actions.manageUsers.key)
      }),
      createColumnForDropdownMenu({
        accessor: "actionsMenu",
        onClickHandler: dropdownMenuActionHandler,
        displayCell: cellVisibilityHandler
      })
    ],
    [
      singleActionHandler,
      dropdownMenuActionHandler,
      createColumnForStatus,
      createColumn,
      createColumnForSingleAction,
      createColumnForDropdownMenu,
      cellVisibilityHandler
    ]
  );

  const data = useMemo(
    () =>
      clients.map(item => {
        const statusIndicatorColorScheme =
          statusesToIndicatorColorScheme[item.status] || "dark";

        const getActionsMenu = () => {
          if (userRole === systemConstants.roles.engagementManager) {
            return clientTagEnabled
              ? [
                  {
                    key: actions.updateClient.key,
                    label: t(actions.updateClient.label)
                  },
                  {
                    key: actions.manageClientTags.key,
                    label: t(actions.manageClientTags.label)
                  }
                ]
              : [
                  {
                    key: actions.updateClient.key,
                    label: t(actions.updateClient.label)
                  }
                ];
          }
          if (
            userRole === systemConstants.roles.teamMember &&
            clientTagEnabled
          ) {
            return [
              {
                key: actions.manageClientTags.key,
                label: t(actions.manageClientTags.label)
              }
            ];
          }
          return [];
        };

        return {
          id: item.id,
          statusIndicatorColorScheme,
          name: item.name,
          acn: item.acn || "-",
          abn: item.abn || "-",
          usersCount: item.usersCount,
          action: t(actions.manageUsers.label),
          actionsMenu: getActionsMenu()
        };
      }),
    [clients, userRole, clientTagEnabled, t]
  );

  return (
    <DataTable
      className="clients-table"
      columns={columns}
      data={data}
      onMouseEnterRow={rowId => setRowDisplay({ rowId, display: true })}
      onMouseLeaveRow={rowId => setRowDisplay({ rowId, display: false })}
    ></DataTable>
  );
};

ClientDataTable.defaultProps = {};

ClientDataTable.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      abn: PropTypes.string,
      acn: PropTypes.string,
      hostId: PropTypes.number,
      id: PropTypes.number,
      logo: PropTypes.shape({
        data: PropTypes.string
      }),
      name: PropTypes.string,
      status: PropTypes.string,
      usersCount: PropTypes.number
    })
  ).isRequired,
  userRole: PropTypes.oneOf(Object.values(systemConstants.roles)).isRequired,
  onHandleManageEntities: PropTypes.func,
  onHandleUpdateClient: PropTypes.func,
  onHandleManageClientTags: PropTypes.func,
  onHandleManageClientUsers: PropTypes.func
};

export default withTranslation()(ClientDataTable);
