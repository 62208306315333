import React, { useEffect, useRef } from "react";

import PropTypes from "prop-types";

import "./DashboardBoxTemplate.scss";

const DashboardBoxTemplate = props => {
  const { title, subtext, action, boxClassName, getHeaderHeight } = props;
  const headerRef = useRef(null);

  useEffect(() => {
    if (getHeaderHeight) {
      const headerHeight = headerRef.current?.offsetHeight;
      getHeaderHeight(headerHeight);
    }
  }, [getHeaderHeight]);

  return (
    <div className={`dashboard-card-template ${boxClassName}`}>
      <div className="dashboard-card-template__header" ref={headerRef}>
        <div className="dashboard-card-template__heading">
          <h4 className="dashboard-card-template__title">{title}</h4>
          <div className="dashboard-card-template__subtext">{subtext}</div>
        </div>
        <div className="dashboard-card-template__action">{action}</div>
      </div>
      <div
        className={`dashboard-card-template__content ${boxClassName}__content`}
      >
        {props.children}
      </div>
    </div>
  );
};

DashboardBoxTemplate.defaultProps = {};

DashboardBoxTemplate.propTypes = {
  boxClassName: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtext: PropTypes.string,
  action: PropTypes.node,
  children: PropTypes.node,
  getHeaderHeight: PropTypes.func
};

export default DashboardBoxTemplate;
