import React, { useCallback, useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import ModalContentTemplate from "@components/templates/ModalContentTemplate/ModalContentTemplate";

export function useGuardedNavigate() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [unsaved, setUnsaved] = useState(false);
  const [unsavedErrorModalOpen, setUnsavedErrorModalOpen] = useState(false);
  const [tryingToNavigateTo, setTryingToNavigateTo] = useState();

  const handleNavigate = useCallback(
    (path, { state = location?.state, force = false } = {}) => {
      if (!force && unsaved) {
        handleOpenUnsavedPopup(path);
      } else {
        navigate(path, { state });
      }
    },
    [location?.state, navigate, unsaved]
  );

  const handleOpenUnsavedPopup = path => {
    setUnsavedErrorModalOpen(true);
    setTryingToNavigateTo(path);
  };

  const handleContinueUnsavedPopup = () => {
    setUnsavedErrorModalOpen(false);
    handleNavigate(tryingToNavigateTo, { force: true });
    setTryingToNavigateTo(null);
  };

  const getUnsavedPopup = () => {
    if (!unsavedErrorModalOpen) {
      return <></>;
    }
    return (
      <ModalContentTemplate
        header={{
          title: t("common:ui.unsavedWarning.title")
        }}
        body={{
          content: <>{t("common:ui.unsavedWarning.message")}</>
        }}
        footer={{
          submitText: t("ui.unsavedWarning.discard"),
          cancelText: t("ui.unsavedWarning.back"),
          handleCancel: () => setUnsavedErrorModalOpen(false),
          handleSubmit: handleContinueUnsavedPopup
        }}
      />
    );
  };

  return {
    unsaved,
    setUnsaved,
    getUnsavedPopup,
    handleNavigate
  };
}
