import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants/systemConstants";

import { expectedActionIndicators } from "@app/helpers";

import Pill from "@components/atoms/Pill/Pill";
import ShapeIndicator from "@components/atoms/ShapeIndicator";
import BoxTemplate from "@components/templates/BoxTemplate";

import "./ActionItemsFilterLegend.scss";

export const actionItemsFilters = {
  STATUS: "status",
  DUE_DATE: "dueDate",
  MY_ACTIONS: "myActions",
  CLIENT_TEAM_ACTIONS: "clientTeamActions",
  HOST_TEAM_ACTIONS: "hostTeamActions"
};

const ActionItemsFilterLegend = props => {
  const { t } = useTranslation();
  const {
    handlers,
    enabled,
    labels,
    pillsConfig,
    indicatorUiConfig,
    filterConfig,
    status
  } = props;

  const indicatorSize = window.innerWidth > 1200 ? "large" : "medium";
  const selectedStatus = props.status.status;
  const selectedDueDate = props.status.dueDate;
  const actionIndicators = indicatorUiConfig || expectedActionIndicators();
  const statusIndicators = filterConfig?.status.filters.map(
    f => actionIndicators[f]
  );
  const dueDateIndicators = filterConfig?.dueDate.filters.map(
    f => actionIndicators[f]
  );

  const filterPillsProps = {
    shape: pillsConfig?.actionItemFilter?.shape,
    activeStyle: pillsConfig?.actionItemFilter?.activeStyle
  };

  const statusPillsProps = {
    shape: pillsConfig?.actionItemStatus?.shape,
    activeStyle: pillsConfig?.actionItemStatus?.activeStyle
  };

  const onClickIndicatorHandler = (event, indicator) => {
    event.stopPropagation();
    handlers[actionItemsFilters.STATUS](indicator);
  };

  const onClickDueDateHandler = (event, indicator) => {
    event.stopPropagation();
    handlers[actionItemsFilters.DUE_DATE](indicator);
  };

  const getFilterButtonBorderColor = enabled => {
    return enabled ? "secondary" : "outline";
  };

  const getFilterButtonBgColor = enabled => {
    const color =
      filterPillsProps.activeStyle == "filled" ? "secondary" : "primary";
    return enabled ? color : "transparent";
  };

  const getHostTeamFilterButton = () => (
    <span
      className={"filter-legend__indicators"}
      onClick={handlers[actionItemsFilters.HOST_TEAM_ACTIONS]}
    >
      <Pill
        label={labels[actionItemsFilters.HOST_TEAM_ACTIONS]}
        size={indicatorSize}
        borderColorScheme={getFilterButtonBorderColor(
          enabled[actionItemsFilters.HOST_TEAM_ACTIONS]
        )}
        colorScheme={getFilterButtonBgColor(
          enabled[actionItemsFilters.HOST_TEAM_ACTIONS]
        )}
        flexMode={"flex"}
        pointer={true}
        shape={filterPillsProps.shape}
        active={enabled[actionItemsFilters.HOST_TEAM_ACTIONS]}
        activeStyle={filterPillsProps.activeStyle}
      />
    </span>
  );

  const getMyActionsFilterButton = () => (
    <span
      className={"filter-legend__indicators"}
      onClick={handlers[actionItemsFilters.MY_ACTIONS]}
    >
      <Pill
        label={labels[actionItemsFilters.MY_ACTIONS]}
        size={indicatorSize}
        borderColorScheme={getFilterButtonBorderColor(
          enabled[actionItemsFilters.MY_ACTIONS]
        )}
        colorScheme={getFilterButtonBgColor(
          enabled[actionItemsFilters.MY_ACTIONS]
        )}
        flexMode={"flex"}
        pointer={true}
        shape={filterPillsProps.shape}
        active={enabled[actionItemsFilters.MY_ACTIONS]}
        activeStyle={filterPillsProps.activeStyle}
      />
    </span>
  );

  const getClientTeamFilterButton = () => (
    <span
      className={"filter-legend__indicators"}
      onClick={handlers[actionItemsFilters.CLIENT_TEAM_ACTIONS]}
    >
      <Pill
        label={labels[actionItemsFilters.CLIENT_TEAM_ACTIONS]}
        size={indicatorSize}
        borderColorScheme={getFilterButtonBorderColor(
          enabled[actionItemsFilters.CLIENT_TEAM_ACTIONS]
        )}
        colorScheme={getFilterButtonBgColor(
          enabled[actionItemsFilters.CLIENT_TEAM_ACTIONS]
        )}
        flexMode={"flex"}
        pointer={true}
        shape={filterPillsProps.shape}
        active={enabled[actionItemsFilters.CLIENT_TEAM_ACTIONS]}
        activeStyle={filterPillsProps.activeStyle}
      />
    </span>
  );

  const getStatusBorderColor = indicator => {
    const isActive = indicator.key == selectedStatus;
    if (statusPillsProps.activeStyle == systemConstants.pill.type.outline) {
      return isActive ? indicator.colorScheme : "outline";
    } else if (
      statusPillsProps.activeStyle == systemConstants.pill.type.filled
    ) {
      return indicator.colorScheme;
    }
  };

  const getColorScheme = (indicator, selectedFilter) => {
    return indicator.key == selectedFilter &&
      statusPillsProps.activeStyle === systemConstants.pill.type.filled
      ? indicator.colorScheme
      : "outline";
  };

  const getPillsLogo = indicator => {
    if (indicator.shape) {
      return <ShapeIndicator shape={indicator.shape} color={indicator.key} />;
    }
    return null;
  };

  return (
    <BoxTemplate>
      <div className="filter-legend">
        {(labels[actionItemsFilters.MY_ACTIONS] ||
          labels[actionItemsFilters.CLIENT_TEAM_ACTIONS] ||
          labels[actionItemsFilters.HOST_TEAM_ACTIONS]) && (
          <div className="filter-legend__filter">
            <div className="filter-legend__title">
              {t("requests:requests.ui.requestFilterLegend.filterTitle")}
            </div>
            <div className="filter-legend__indicators">
              {labels[actionItemsFilters.MY_ACTIONS] &&
                getMyActionsFilterButton()}
              {labels[actionItemsFilters.CLIENT_TEAM_ACTIONS] &&
                getClientTeamFilterButton()}
              {labels[actionItemsFilters.HOST_TEAM_ACTIONS] &&
                getHostTeamFilterButton()}
            </div>
          </div>
        )}
        {filterConfig.status?.enabled && (
          <div className="filter-legend__legend">
            <div className="filter-legend__title">
              {" "}
              {t("requests:requests.ui.requestFilterLegend.statusTitle")}
            </div>
            <div className="filter-legend__indicators">
              {statusIndicators.map(indicator => (
                <div
                  className="filter-legend__indicator"
                  key={indicator.key}
                  onClick={event => onClickIndicatorHandler(event, indicator)}
                >
                  <Pill
                    label={t("requests:requests.indicators.label", {
                      context: indicator?.key
                    })}
                    size={indicatorSize}
                    borderColorScheme={getStatusBorderColor(indicator)}
                    colorScheme={getColorScheme(indicator, selectedStatus)}
                    flexMode={"flex"}
                    pointer={true}
                    shape={statusPillsProps.shape}
                    active={indicator.key == selectedStatus}
                    activeStyle={statusPillsProps.activeStyle}
                    logo={getPillsLogo(indicator)}
                    status={status.status}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        {filterConfig.dueDate?.enabled && (
          <div className="filter-legend__legend">
            <div className="filter-legend__title">Due Date</div>
            <div className="filter-legend__indicators">
              {dueDateIndicators.map(indicator => (
                <div
                  className="filter-legend__indicator"
                  key={indicator.key}
                  onClick={event => onClickDueDateHandler(event, indicator)}
                >
                  <Pill
                    label={indicator.label}
                    size={indicatorSize}
                    borderColorScheme={getStatusBorderColor(indicator)}
                    colorScheme={getColorScheme(indicator, selectedDueDate)}
                    flexMode={"flex"}
                    pointer={true}
                    shape={statusPillsProps.shape}
                    active={indicator.key == selectedDueDate}
                    activeStyle={statusPillsProps.activeStyle}
                    logo={getPillsLogo(indicator)}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </BoxTemplate>
  );
};

ActionItemsFilterLegend.propTypes = {
  handlers: PropTypes.shape({
    status: PropTypes.func,
    dueDate: PropTypes.func,
    myActions: PropTypes.func,
    clientTeamActions: PropTypes.func,
    hostTeamActions: PropTypes.func
  }).isRequired,
  enabled: PropTypes.shape({
    myActions: PropTypes.bool.isRequired,
    clientTeamActions: PropTypes.bool.isRequired,
    hostTeamActions: PropTypes.bool.isRequired
  }).isRequired,
  labels: PropTypes.shape({
    myActions: PropTypes.string,
    clientTeamActions: PropTypes.string,
    hostTeamActions: PropTypes.string
  }).isRequired
};

export default ActionItemsFilterLegend;
