import React from "react";

import PropTypes from "prop-types";

import LoaderSpinner from "@components/atoms/LoaderSpinner";

import "./InfiniteScrollFooter.scss";

function InfiniteScrollFooter(props) {
  const { state } = props;
  return (
    <div>
      {state === "loading" && (
        <div className="ot-infinite-scroll-footer__loading">
          <LoaderSpinner />
        </div>
      )}
    </div>
  );
}
InfiniteScrollFooter.defaultProps = {
  state: "loading"
};

InfiniteScrollFooter.propTypes = {
  state: PropTypes.oneOf(["loading"])
};

export default InfiniteScrollFooter;
