import React from "react";

import { Navigate } from "react-router-dom";

const ExternalLinkWithObject = props => {
  const query = new URLSearchParams(location.search);
  const objectEncoded = query.get("object") || null;

  if (objectEncoded) {
    sessionStorage.setItem("externalObject", objectEncoded);
  }
  return <Navigate to="/" />;
};

export default ExternalLinkWithObject;
