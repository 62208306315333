import React, { useState } from "react";

import { routeConstants } from "@constants";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import "./Logout.scss";

const Logout = props => {
  const { handleLogout } = props;
  const [showLogoutLabel, setShowLogoutLabel] = useState(false);

  return (
    <NavLink
      to={routeConstants.login}
      className="logout"
      data-testid="testLogout"
      onClick={handleLogout}
      onMouseEnter={() => {
        setShowLogoutLabel(true);
      }}
      onMouseLeave={() => {
        setShowLogoutLabel(false);
      }}
    >
      <i className="material-icons logout__icon">exit_to_app</i>
      <span
        className={
          "logout__icon-on-hover" +
          (showLogoutLabel ? "" : " logout__icon-on-hover-hidden")
        }
      >
        Logout
      </span>
    </NavLink>
  );
};

Logout.propTypes = {
  handleLogout: PropTypes.func.isRequired
};

export default Logout;
