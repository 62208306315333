import React from "react";

import PropTypes from "prop-types";

import "./ToastIcon.scss";

function ToastIcon(props) {
  const { type } = props;
  const getIcon = () => {
    switch (type) {
      case "success":
        return (
          <i className="material-icons ot-toast-icon--success">{"check"}</i>
        );
      case "error":
        return <i className="material-icons ot-toast-icon--error"></i>;
      case "warning":
        return (
          <i className="material-icons ot-toast-icon--warning">
            {"priority_high"}
          </i>
        );
      case "info":
        return <i className="material-icons ot-toast-icon--info"></i>;
      default:
        return <></>;
    }
  };
  return <div className="ot-toast-icon">{getIcon()}</div>;
}

ToastIcon.propTypes = {
  type: PropTypes.oneOf(["success", "error", "warning", "info"])
};

export default ToastIcon;
