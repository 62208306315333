import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants";
import { useAuthUser, useFeatures } from "@shared/hooks";

import { clientAdminNavs, hostAdminNavs } from "@app/constants";

interface NavItem {
  name: string;
  path: string;
  icon: string;
  state?: {};
  onClick?: () => void;
}
export function useAdminNavItems() {
  const { t } = useTranslation();
  const { user } = useAuthUser({ withProfile: false });
  const { isEnabled } = useFeatures();

  const hostAdminUserNav = hostAdminNavs({ i18nText: t });

  const adminNavItems = useMemo(() => {
    let navItems: NavItem[] = [];
    const dataExtractionEnabled = isEnabled(
      systemConstants.features.dataExtraction
    );
    const manageNewsEnabled = isEnabled(systemConstants.features.manageNews);
    const globalTagsEnabled = isEnabled(systemConstants.features.globalTags);

    if (user.isHostAdmin) {
      navItems = navItems.concat([hostAdminUserNav.auditReport]);
      if (manageNewsEnabled) {
        navItems.push(hostAdminUserNav.manageNews);
      }
      if (globalTagsEnabled) {
        navItems.push(hostAdminUserNav.globalTags);
      }
      if (dataExtractionEnabled) {
        navItems.push(hostAdminUserNav.dataExtraction);
      }
    }
    if (user.isTeamMember) {
      navItems.push(hostAdminUserNav.manageClients);
      if (manageNewsEnabled) {
        navItems.push(hostAdminUserNav.manageNews);
      }
      if (globalTagsEnabled) {
        navItems.push(hostAdminUserNav.globalTags);
      }
    }
    if (user.isClientAdmin) {
      const clientAdminUserNav = clientAdminNavs({
        clientId: user.clientId,
        i18nText: t
      });
      navItems.push(clientAdminUserNav.manageClientUsers);
    }
    return navItems;
  }, [hostAdminUserNav, isEnabled, t, user]);

  const adminNavItemsByNavigationStyle = useMemo(() => {
    if (!user.isHostAdmin) {
      return {
        OT1: adminNavItems,
        OT2: adminNavItems
      };
    }

    return {
      OT1: [
        hostAdminUserNav.manageClients,
        hostAdminUserNav.manageUsers,
        ...adminNavItems
      ],
      OT2: [hostAdminUserNav.manageClientsAndUsers, ...adminNavItems]
    };
  }, [
    adminNavItems,
    hostAdminUserNav.manageClients,
    hostAdminUserNav.manageClientsAndUsers,
    hostAdminUserNav.manageUsers,
    user.isHostAdmin
  ]);

  return {
    adminNavItemsByNavigationStyle
  };
}
