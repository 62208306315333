import React, { useCallback, useRef } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import dateFormatter from "@shared/helpers/dateHelper";
import { useLocaleDate } from "@shared/hooks/useLocaleDate";

import { routeConstants } from "@app/constants/routeConstants";

import DropdownDotMenu from "@components/atoms/DropdownDotMenu/DropdownDotMenu";
import Pill from "@components/atoms/Pill/Pill";
import Text from "@components/atoms/Text/Text";
import MilestoneProgressBar from "@components/molecules/MilestoneProgressBar";

import "./ProjectPanel.scss";

const allMenuItems = [
  {
    name: "common:ui.project.title_update",
    action: "UPDATE"
  },
  {
    name: "common:ui.project.title_copy",
    action: "COPY"
  }
];

const ProjectPanel = props => {
  const {
    id,
    projectName,
    clientName,
    engagementType,
    year,
    yearEnabled,
    colorStatus,
    currentMilestone,
    lastMilestone,
    totalMilestones,
    completedMilestone,
    user
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const projectPanelTitleRef = useRef();
  const projectPanelProjectNameRef = useRef();

  function isElementOverflowing(ref) {
    return ref.current?.scrollWidth > ref.current?.clientWidth;
  }
  const { locale, options } = useLocaleDate();

  const onPanelClickHandler = useCallback(() => {
    navigate(`${routeConstants.projects}/${id}`);
  }, [id, navigate]);

  const onMenuItemClick = useCallback(
    item => {
      switch (item.action) {
        case "UPDATE":
          navigate(`${routeConstants.projects}/${id}/edit`);
          break;
        case "COPY":
          navigate(`${routeConstants.projects}/${id}/copy`);
          break;
      }
    },
    [id, navigate]
  );

  const title = user.isHostUser ? clientName : projectName;
  const isMilestonesCompleted = completedMilestone === totalMilestones;

  const nextMilestoneDateText = isMilestonesCompleted
    ? t("common:ui.dashboard.panel.milestones.complete")
    : t("common:ui.dashboard.panel.milestones.nextMilestoneDate", {
        nextMilestoneDate: dateFormatter(
          currentMilestone.date,
          locale,
          options.shortFormat
        )
      });

  return (
    <div
      className="ot-projects-dashboard-project-panel"
      onClick={onPanelClickHandler}
    >
      <div className="ot-projects-dashboard-project-panel__top">
        <div
          className="ot-projects-dashboard-project-panel__top-title"
          ref={projectPanelTitleRef}
          title={isElementOverflowing(projectPanelTitleRef) ? title : ""}
        >
          <Text
            colorStyle="inherit"
            fontSize="7"
            fontType="semi-bold"
            value={title}
          />
          {user.isHostUser && (
            <DropdownDotMenu
              menuItems={allMenuItems}
              onMenuItemClick={onMenuItemClick}
              disableFixedPositionCalculation={true}
            />
          )}
        </div>
        <div
          className="ot-projects-dashboard-project-panel__top-project-name"
          ref={projectPanelProjectNameRef}
          title={
            isElementOverflowing(projectPanelProjectNameRef) ? projectName : ""
          }
        >
          {user.isHostUser && (
            <Text
              value={projectName}
              fontType="regular"
              fontSize="5"
              colorStyle="inherit"
            />
          )}
        </div>
        <div className="ot-projects-dashboard-project-panel__pill">
          <span className="ot-projects-dashboard-project-panel__pill--engagement-type">
            <Pill
              size="small"
              active
              shape="slightly-rounded"
              activeStyle="filled"
              flexMode="inline"
              status="dashboard"
              colorScheme="light-grey"
              fontWeight="semi-bold"
              label={engagementType}
            />
          </span>
          {yearEnabled && year && (
            <Pill
              size="small"
              active
              shape="slightly-rounded"
              activeStyle="filled"
              flexMode="inline"
              status="dashboard"
              colorScheme="light-grey"
              fontWeight="semi-bold"
              label={year}
            />
          )}
        </div>
      </div>

      <div className="ot-projects-dashboard-project-panel__progress-bar">
        <MilestoneProgressBar
          colorStatus={colorStatus}
          totalMilestones={totalMilestones}
          completedMilestone={completedMilestone}
          milestoneName={
            isMilestonesCompleted ? lastMilestone.name : currentMilestone.name
          }
        />
      </div>
      <div className="ot-projects-dashboard-project-panel__bottom">
        <span className="ot-projects-dashboard-project-panel__bottom-icon material-symbols-outlined">
          calendar_today
        </span>
        <Text
          colorStyle="inherit"
          fontType="medium"
          fontSize="3"
          value={nextMilestoneDateText}
        />
      </div>
    </div>
  );
};

ProjectPanel.propTypes = {
  projectName: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
  engagementType: PropTypes.string.isRequired,
  projectYear: PropTypes.string,
  colorStatus: PropTypes.oneOf(["behind", "inprogress", "complete"]).isRequired,
  totalMilestones: PropTypes.number.isRequired,
  completedMilestone: PropTypes.number.isRequired,
  currentMilestone: PropTypes.shape({
    name: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired
  }),
  lastMilestone: PropTypes.shape({
    name: PropTypes.string,
    date: PropTypes.string
  }).isRequired,
  user: PropTypes.shape({
    isHostUser: PropTypes.bool.isRequired,
    isTeamMember: PropTypes.bool.isRequired
  }).isRequired
};

export default ProjectPanel;
