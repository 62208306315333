import React from "react";

import Icon from "../Icon/Icon";
import "./SecondaryNavSectionTitle.scss";

const SecondaryNavSectionTitle = props => {
  const { item, isExpanded, width } = props;
  return (
    <div
      className="secondary-nav-section-title"
      key={item.name}
      style={{ width: width }}
      title={item.name}
    >
      <div className="secondary-nav-section-title__icon-container">
        <Icon name={item.icon.name} />
      </div>
      {isExpanded && (
        <div className="secondary-nav-section-title__name-container">
          <span className="secondary-nav-section-title__name">{item.name}</span>
        </div>
      )}
    </div>
  );
};

export default SecondaryNavSectionTitle;
