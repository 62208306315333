import React, { useCallback, useMemo } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { routeConstants } from "@constants";

import dateFormatter from "@shared/helpers/dateHelper";
import { useAuthUser } from "@shared/hooks/useAuthUser";
import { useLocaleDate } from "@shared/hooks/useLocaleDate";

import { useDataTable } from "@app/hooks";

import DataTable from "@components/molecules/DataTable";
import InfiniteScrollFooter from "@components/molecules/InfiniteScrollFooter";

import "./ProjectsTable.scss";

function ProjectsTable(props) {
  const { projects, scrollHandler, isFetching } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { createColumn, createColumnForDropdownMenu } = useDataTable(projects);
  const { locale, options } = useLocaleDate();
  const { user } = useAuthUser();
  const onRowClick = useCallback(
    (_, row) => {
      navigate(`${routeConstants.projects}/${row.projectId}`);
    },
    [navigate]
  );
  const rowClickHandler = useMemo(
    () => ({ isClickable: () => true, handler: onRowClick }),
    [onRowClick]
  );

  const yearEnabled = useMemo(() => {
    return projects.some(project => project.yearEnabled);
  }, [projects]);

  const getMenuItems = useCallback(
    projectId => {
      if (!user?.isHostUser) {
        return [];
      }

      return [
        {
          label: t("common:ui.project.title_update"),
          key: "updateProject",
          projectId: projectId
        },
        {
          label: t("common:ui.project.title_copy"),
          key: "copyProject",
          projectId: projectId
        }
      ];
    },
    [t, user?.isHostUser]
  );

  const menuItemClickHandler = useCallback(
    ({ menuItem }) => {
      switch (menuItem.key) {
        case "updateProject":
          navigate(`${routeConstants.projects}/${menuItem.projectId}/edit`);
          break;
        case "copyProject":
          navigate(`${routeConstants.projects}/${menuItem.projectId}/copy`);
          break;
        default:
          break;
      }
    },
    [navigate]
  );

  const data = useMemo(
    () =>
      projects.map(project => ({
        projectId: project.id,
        client: project.clientName,
        engagementType: project.engagementType,
        projectName: project.name,
        projectYear: project.year,
        nextMilestone: dateFormatter(
          project.currentMilestone?.date,
          locale,
          options.shortFormat
        ),
        actions: getMenuItems(project.id)
      })),
    [getMenuItems, locale, options.shortFormat, projects]
  );

  const columns = useMemo(
    () => [
      createColumn({
        Header: t("common:ui.projectTable.client"),
        accessor: "client",
        className: "client",
        maxWidth: 25,
        isHidden: !user?.isHostUser
      }),
      createColumn({
        Header: t("common:ui.projectTable.engagementType"),
        accessor: "engagementType",
        className: "engagementType",
        maxWidth: 25
      }),
      createColumn({
        Header: t("common:ui.projectTable.project"),
        accessor: "projectName",
        className: "projectName"
      }),
      createColumn({
        Header: t("common:ui.projectTable.projectYear"),
        accessor: "projectYear",
        className: "projectYear",
        maxWidth: 15,
        isHidden: !yearEnabled
      }),
      createColumn({
        Header: t("common:ui.projectTable.nextMilestone"),
        accessor: "nextMilestone",
        className: "nextMilestone",
        maxWidth: 15
      }),
      createColumnForDropdownMenu({
        Header: "",
        accessor: "actions",
        className: "actions",
        isHidden: !user?.isHostUser,
        onClickHandler: menuItemClickHandler
      })
    ],
    [
      createColumn,
      createColumnForDropdownMenu,
      menuItemClickHandler,
      t,
      user?.isHostUser,
      yearEnabled
    ]
  );

  return (
    <div className="ot-dashboard-projects-table" onScroll={scrollHandler}>
      <DataTable
        data={data}
        columns={columns}
        rowClickHandler={rowClickHandler}
      ></DataTable>
      {isFetching && <InfiniteScrollFooter state="loading" />}
    </div>
  );
}

ProjectsTable.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      clientName: PropTypes.string,
      engagementType: PropTypes.string,
      name: PropTypes.string,
      year: PropTypes.number,
      currentMilestone: PropTypes.shape({
        date: PropTypes.string
      })
    })
  ),
  scrollHandler: PropTypes.func,
  isFetching: PropTypes.bool
};

export default ProjectsTable;
