import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { classNames } from "@app/helpers/componentHelpers";

import Icon from "@components/atoms/Icon";

import "./DropdownSearchBar.scss";

/**
 * @type {React.FC<Object>}
 */
const DropdownSearchBar = React.forwardRef((props, fwdRef) => {
  const { value, onChange, onClear, forMenu, placeholder } = props;
  const { t } = useTranslation();

  return (
    <div
      className={classNames([
        "dropdown-search-bar",
        `${forMenu ? "dropdown-search-bar__for-menu" : ""}`
      ])}
    >
      <Icon name="search" className={"dropdown-search-bar--icon"} />
      <input
        data-testid="testMultiselect__search"
        className={`dropdown-search-bar--input ${
          forMenu && "dropdown-search-bar--input__for-menu"
        }`}
        disabled={props.disabled}
        placeholder={placeholder ?? t("common:ui.forms.search.label")}
        value={value}
        onChange={onChange}
        ref={fwdRef}
      />
      {value && (
        <Icon
          name="close"
          className={"dropdown-search-bar--clear"}
          onClick={onClear}
          size="medium"
        />
      )}
    </div>
  );
});

DropdownSearchBar.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  forMenu: PropTypes.bool
};

DropdownSearchBar.displayName = "DropdownSearchBar";

export default DropdownSearchBar;
