import React from "react";

import "./successBox.scss";

const SuccessBox = props => {
  return (
    <div
      className={`success-box ${
        props.type === "component" ? "component-success" : "page-success"
      }`}
    >
      <i className="material-icons success-box--icon">check_circle</i>
      <span className="success-box--text">{props.message}</span>
    </div>
  );
};

export default SuccessBox;
