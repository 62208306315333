import React, { useState } from "react";

import PropTypes from "prop-types";

import HoverText from "@components/atoms/HoverText";

import Cleaned from "./Cleaned.svg?react";
import Complete from "./Complete.svg?react";
import "./WebsheetStatusIcon.scss";

const WebSheetStatusIcon = ({ color = "success", status }) => {
  const [isActive, setIsActive] = useState(false);
  const displayIcons = () => {
    switch (status?.mark) {
      case "cleaned":
        return <Cleaned />;
      case "complete":
        return <Complete />;
      default:
        return <></>;
    }
  };

  return (
    <div
      className={`websheet-status-icon websheet-status-icon--${color}`}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      {displayIcons()}
      {status && (
        <HoverText
          isActive={isActive}
          classNameTarget={`websheet-status-icon--${color}`}
          name={status?.text}
          maxWidth={250}
        />
      )}
    </div>
  );
};

WebSheetStatusIcon.defaultProps = {
  color: "success"
};

WebSheetStatusIcon.propTypes = {
  color: PropTypes.oneOf(["success"]),
  status: PropTypes.shape({
    mark: PropTypes.string,
    text: PropTypes.string
  })
};

export default WebSheetStatusIcon;
