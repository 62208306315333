import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";
import * as yup from "yup";

import ErrorBox from "@shared/components/errorBox/ErrorBox";
import Popup from "@shared/components/popup/Popup";

import Form from "@components/atoms/Form";
import Text from "@components/atoms/Text/Text";
import ModalForm from "@components/molecules/ModalForm";

const SaveCleaningTemplateModal = ({
  handleSubmit,
  handleClose,
  visibility,
  errorMessage,
  continueToNextFile
}) => {
  const { t } = useTranslation();
  const yupSchema = useMemo(() => {
    const yupShape = {
      name: yup.string().required(
        t("common:ui.websheet.actions.saveCleaningTemplate.popup.name.error", {
          context: "empty"
        })
      )
    };
    return yup.object().shape(yupShape);
  }, [t]);
  const context = useMemo(() => {
    return continueToNextFile ? { context: "nextFile" } : undefined;
  }, [continueToNextFile]);

  return (
    <Popup visibility={visibility} width="50rem">
      <ModalForm
        boxClassName=""
        title={t("common:ui.websheet.actions.cleaningWizard.saveTemplate")}
        handleCancel={() => handleClose(continueToNextFile)}
        handleSubmit={formData => {
          handleSubmit(formData, continueToNextFile);
        }}
        yupSchema={yupSchema}
        submitLabel={t(
          "common:ui.websheet.actions.saveCleaningTemplate.popup.submitLabel",
          context
        )}
        cancelLabel={t(
          "common:ui.websheet.actions.saveCleaningTemplate.popup.cancelLabel",
          context
        )}
      >
        <Text
          value={t(
            "common:ui.websheet.actions.saveCleaningTemplate.popup.text",
            context
          )}
        />
        <Form.TextField
          name="name"
          required={true}
          label={t(
            "common:ui.websheet.actions.saveCleaningTemplate.popup.textArea.label"
          )}
          placeholder={t(
            "common:ui.websheet.actions.saveCleaningTemplate.popup.textArea.placeholder"
          )}
        />
        {errorMessage && <ErrorBox message={errorMessage} />}
      </ModalForm>
    </Popup>
  );
};
export default SaveCleaningTemplateModal;
