import { systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { handleErrorResponse, handleResponse } from "./";

const baseURL = systemConstants.serverURL;

export const milestoneCommentService = {
  getMilestoneComment,
  saveMilestoneComment
};

function getMilestoneComment(milestoneId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    `${baseURL}/api/milestones/${milestoneId}/comment`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function saveMilestoneComment(milestoneId, comment) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ comment: comment })
  };

  return fetch(
    `${baseURL}/api/milestones/${milestoneId}/comment`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}
