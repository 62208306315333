import React from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { documentActions } from "@app/helpers/documents";

import BrandButton from "@components/atoms/Button/BrandButton";
import ModalTemplate from "@components/templates/ModalTemplate";

const DeleteDocument = props => {
  const { document, t } = props;

  const handleDeleteClick = () => {
    if (props.handleDelete) {
      props.handleDelete(document);
    }
  };

  return (
    <ModalTemplate
      boxClassName="document-deletion"
      title={t("common:documents.documentActions", {
        context: documentActions.deleteDocument
      })}
      content={t("common:documents.deleteDocument.warningMessage")}
      footer={
        <>
          <BrandButton
            type="danger"
            label={t("common:documents.deleteDocument.yes")}
            onClick={handleDeleteClick}
          />
          <BrandButton
            type="secondary"
            label={t("common:documents.deleteDocument.no")}
            onClick={props.onCancel}
          />
        </>
      }
      onClose={props.onCancel}
    />
  );
};

DeleteDocument.defaultProps = {};

DeleteDocument.propTypes = {
  document: PropTypes.any,
  onCancel: PropTypes.func,
  handleDelete: PropTypes.func
};

export default withTranslation()(DeleteDocument);
