import React from "react";

import PropTypes from "prop-types";

import BrandButton from "@components/atoms/Button/BrandButton";
import ModalTemplate from "@components/templates/ModalTemplate";

const DangerModal = props => {
  const handleConfirmClick = () => props.handleConfirmation?.();

  return (
    <ModalTemplate
      boxClassName="danger-modal"
      title={props.title || "Warning"}
      content={props.content || "Are you sure you want to do this?"}
      footer={
        <>
          <BrandButton type="danger" label="Yes" onClick={handleConfirmClick} />
          <BrandButton type="secondary" label="No" onClick={props.onCancel} />
        </>
      }
      onClose={props.onCancel}
    />
  );
};

DangerModal.defaultProps = {};

DangerModal.propTypes = {
  onCancel: PropTypes.func,
  handleConfirmation: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.node
};

export default DangerModal;
