import React from "react";

import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
  ReactPlugin
} from "@microsoft/applicationinsights-react-js";
import { Provider } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { systemConstants } from "@shared/constants";
import { store } from "@shared/helpers/store";
import { useAuthUser } from "@shared/hooks/useAuthUser";

import ExternalLinkWithObject from "@shared-components/pages/externalLinkWithObject/ExternalLinkWithObject";
import ForgotPassword from "@shared-components/pages/forgotPassword/ForgotPassword";
import ResetPassword from "@shared-components/pages/resetPassword/ResetPassword";
import SignUpPage from "@shared-components/pages/signUpPage/SignUpPage";

import ThemedStylesheet from "@app/components/organisms/ThemedStylesheet";
import { routeConstants } from "@app/constants";
import { useDefaultLandingPageLink } from "@app/hooks/useDefaultLandingPageLink";
import AccountNotFound from "@app/pages/AccountNotFound";
import AddClientUser from "@app/pages/AddClientUser";
import AddHostUser from "@app/pages/AddHostUser";
import AddOrUpdateArticle from "@app/pages/AddOrUpdateArticle";
import AddOrUpdateClient from "@app/pages/AddOrUpdateClient";
import AddOrUpdateClientEngagementProject from "@app/pages/AddOrUpdateClientEngagementProject";
import AddOrUpdateClientTag from "@app/pages/AddOrUpdateClientTag";
import AddOrUpdateGlobalTag from "@app/pages/AddOrUpdateGlobalTag";
import AddProject from "@app/pages/AddProject";
import DataExtraction from "@app/pages/DataExtraction";
import EditDocument from "@app/pages/EditDocument";
import FinalPackage from "@app/pages/FinalPackage";
import GlobalTags from "@app/pages/GlobalTags";
import InteractiveReport from "@app/pages/InteractiveReport";
import LandingPage from "@app/pages/LandingPage";
import LoginPage from "@app/pages/LoginPage";
import LogoutPage from "@app/pages/LogoutPage";
import ManageActionItems from "@app/pages/ManageActionItems";
import ManageAudits from "@app/pages/ManageAudits";
import ManageClientEngagements from "@app/pages/ManageClientEngagements";
import ManageClientTags from "@app/pages/ManageClientTags";
import ManageClientUsers from "@app/pages/ManageClientUsers";
import ManageClients from "@app/pages/ManageClients";
import ManageDataRepository from "@app/pages/ManageDataRepository";
import ManageEngagementDashboard from "@app/pages/ManageEngagementDashboard";
import ManageInsightsBoard from "@app/pages/ManageInsightsBoard";
import ManageNews from "@app/pages/ManageNews";
import ManageTeamUsers from "@app/pages/ManageTeamUsers";
import NotFound from "@app/pages/NotFound";
import PermanentFiles from "@app/pages/PermanentFiles";
import Profile from "@app/pages/Profile";
import ProjectList from "@app/pages/ProjectList";
import ProjectRisks from "@app/pages/ProjectRisks";
import ProjectSummary from "@app/pages/ProjectSummary";
import ProjectsDashboard from "@app/pages/ProjectsDashboard";
import RequestDetails from "@app/pages/RequestDetails";
import SmartForm from "@app/pages/SmartFormPage";
import UpdateClientUser from "@app/pages/UpdateClientUser";
import UpdateHostUser from "@app/pages/UpdateHostUser";
import UploadInteractiveReport from "@app/pages/UploadInteractiveReport";

import UIConfigProvider from "@components/organisms/UIConfigProvider";

import "./App.scss";
import { AppInsights } from "./AppInsights";
import AddEngagementProject from "./pages/AddEngagementProject";
import AiReporting from "./pages/AiReporting";
import CopyClientEngagementProject from "./pages/CopyClientEngagementProject";
import InsightsBoard from "./pages/InsightsBoard";
import ManageUserRoles from "./pages/ManageUserRoles";
import SomethingWentWrong from "./pages/SomethingWentWrong";
import UpdateProject from "./pages/UpdateProject";

const pathFragments = {
  engagement: "/engagements/:engagementId",
  project: "/projects/:projectId",
  client: "/clients/:clientId",
  user: "/users/:userId",
  query: "/queries/:queryId",
  websheet: "/websheets/:queryId",
  document: "/documents/:documentId",
  smartForm: "/smartforms/:queryId",
  smartFormWebsheet: "/websheets/:websheetId",
  page: "/pages/:pageId",
  insightsBoard: `${routeConstants.insightsBoard}/:insightsBoardId`,
  uploadInteractiveReport: "/upload-interactive-report",
  aiReport: "/ai-reporting",
  risks: "/risks",
  admin: "/admin"
};

const hostAdminRoles = [systemConstants.roles.engagementManager];

const hostRoles = [
  systemConstants.roles.engagementManager,
  systemConstants.roles.teamMember
];

const clientAdminRoles = [
  systemConstants.roles.clientAdmin,
  systemConstants.roles.engagementManager,
  systemConstants.roles.teamMember
];

const anyRole = [
  systemConstants.roles.engagementManager,
  systemConstants.roles.teamMember,
  systemConstants.roles.clientAdmin,
  systemConstants.roles.clientUser
];

const authenticatedPaths = [
  {
    path: routeConstants.clientDashboard,
    roles: anyRole,
    Page: ProjectsDashboard
  },
  {
    path: routeConstants.manageAudits,
    roles: hostAdminRoles,
    Page: ManageAudits
  },
  {
    path: routeConstants.dataExtraction,
    roles: hostAdminRoles,
    Page: DataExtraction
  },
  {
    path: routeConstants.addProject,
    roles: hostRoles,
    Page: AddProject
  },
  {
    path: routeConstants.editDashboardArticle,
    roles: hostRoles,
    Page: AddOrUpdateArticle
  },
  {
    path: routeConstants.addDashboardArticle,
    roles: hostRoles,
    Page: AddOrUpdateArticle
  },
  {
    path: routeConstants.updateClientEngagementProject,
    roles: hostRoles,
    Page: AddOrUpdateClientEngagementProject
  },
  {
    path: routeConstants.manageClientEngagements,
    roles: hostRoles,
    Page: ManageClientEngagements
  },
  { path: routeConstants.manageNews, roles: hostRoles, Page: ManageNews },
  { path: routeConstants.globalTags, roles: hostRoles, Page: GlobalTags },
  {
    path: routeConstants.manageClientTags,
    roles: hostRoles,
    Page: ManageClientTags
  },
  {
    path: routeConstants.admin.manageUserRoles,
    roles: hostAdminRoles,
    Page: ManageUserRoles
  },
  {
    path: routeConstants.addClientTag,
    roles: hostRoles,
    Page: AddOrUpdateClientTag
  },
  {
    path: routeConstants.updateClientTag,
    roles: hostRoles,
    Page: AddOrUpdateClientTag
  },
  {
    path: routeConstants.addTag,
    roles: hostRoles,
    Page: AddOrUpdateGlobalTag
  },
  { path: routeConstants.projects, roles: hostRoles, Page: ProjectList },
  // any roles
  { path: routeConstants.project.summary, Page: ProjectSummary },
  { path: routeConstants.profile, Page: Profile },
  {
    path: routeConstants.engagement.dashboard,
    Page: ManageEngagementDashboard
  },
  { path: routeConstants.request.requests, Page: ManageActionItems },
  { path: routeConstants.request.queryDetails, Page: RequestDetails },
  { path: routeConstants.dataRepository, Page: ManageDataRepository },
  { path: routeConstants.permanentFiles, Page: PermanentFiles },
  { path: routeConstants.dashboard, Page: LandingPage },
  { path: routeConstants.editDocument, Page: EditDocument },
  { path: routeConstants.insightsBoard, Page: ManageInsightsBoard },
  // deep links
  {
    path: pathFragments.client,
    Page: ManageClientEngagements
  },
  {
    path: routeConstants.manageClients,
    Page: ManageClients
  },
  {
    path: routeConstants.manageUsers,
    Page: ManageTeamUsers
  },
  {
    path: routeConstants.updateHostUser,
    roles: hostAdminRoles,
    Page: UpdateHostUser
  },
  {
    path: pathFragments.engagement,
    Page: ManageEngagementDashboard
  },
  {
    path: `${pathFragments.engagement}/add-project`,
    Page: AddEngagementProject
  },
  { path: pathFragments.project, Page: ProjectSummary },
  {
    path: `${pathFragments.project}${routeConstants.dataRepository}`,
    Page: ManageDataRepository
  },
  {
    path: `${pathFragments.project}${routeConstants.finalPackage}`,
    Page: FinalPackage
  },
  { path: `${pathFragments.project}/queries`, Page: ManageActionItems },
  {
    path: `${pathFragments.project}${pathFragments.websheet}`,
    Page: EditDocument
  },
  {
    path: `${pathFragments.project}${pathFragments.document}`,
    Page: EditDocument
  },
  {
    path: `${pathFragments.project}${pathFragments.query}`,
    Page: RequestDetails
  },
  {
    path: `${pathFragments.project}${pathFragments.page}`,
    Page: InteractiveReport
  },
  {
    path: `${pathFragments.project}${pathFragments.uploadInteractiveReport}`,
    Page: UploadInteractiveReport
  },
  {
    path: `${pathFragments.project}${pathFragments.aiReport}`,
    Page: AiReporting
  },
  {
    path: `${pathFragments.project}${pathFragments.smartForm}`,
    Page: SmartForm
  },
  {
    path: `${pathFragments.project}${pathFragments.smartForm}${pathFragments.smartFormWebsheet}`,
    Page: EditDocument
  },
  {
    path: `${pathFragments.project}${pathFragments.risks}`,
    Page: ProjectRisks
  },
  {
    path: `${pathFragments.client}${routeConstants.permanentFiles}`,
    Page: PermanentFiles
  },
  {
    path: routeConstants.addClient,
    roles: hostAdminRoles,
    Page: AddOrUpdateClient
  },
  {
    path: `${pathFragments.admin}${pathFragments.client}/edit`,
    roles: hostAdminRoles,
    Page: AddOrUpdateClient
  },
  {
    path: `${pathFragments.admin}/add-user`,
    Page: AddHostUser
  },
  {
    path: `${pathFragments.admin}${pathFragments.user}/edit`,
    Page: UpdateHostUser
  },
  {
    path: `${pathFragments.admin}${pathFragments.client}/users`,
    Page: ManageClientUsers
  },
  {
    path: `${pathFragments.admin}${pathFragments.client}/add-user`,
    Page: AddClientUser
  },
  {
    path: `${pathFragments.admin}${pathFragments.client}${pathFragments.user}/edit`,
    Page: UpdateClientUser
  },
  {
    path: `${pathFragments.project}/edit`,
    Page: UpdateProject
  },
  {
    path: `${pathFragments.project}/copy`,
    Page: CopyClientEngagementProject
  },
  {
    path: pathFragments.insightsBoard,
    Page: InsightsBoard
  },
  {
    path: "*",
    Page: NotFound
  }
];

function RequireAuth({ children, roles }) {
  const { user } = useAuthUser();
  const { getDefaultLandingPageLink } = useDefaultLandingPageLink();
  const isAuthenticated = user.isLoggedIn;
  const isAuthorised = !roles || user.isMemberOfUserGroup(roles);
  if (!isAuthenticated) {
    return document.location.pathname === routeConstants.notFound ? (
      <Navigate to={routeConstants.login} />
    ) : (
      <Navigate
        to={routeConstants.login}
        state={{
          target: `${document.location.pathname}${document.location.search}${document.location.hash}`
        }}
      />
    );
  }
  if (isAuthenticated && !isAuthorised) {
    return <Navigate to={getDefaultLandingPageLink(user)} />;
  }
  return children;
}

function authenticatedRoute({ path, roles, Page }, key) {
  return (
    <Route
      key={key}
      path={path}
      element={
        <RequireAuth roles={roles}>
          <Page />
        </RequireAuth>
      }
    />
  );
}

function App() {
  const reactPlugin = new ReactPlugin();
  const errorMsg = () => <SomethingWentWrong />;

  AppInsights({ reactPlugin }).catch(() => {});

  return (
    <>
      <React.StrictMode>
        <Provider store={store}>
          <AppInsightsErrorBoundary
            appInsights={reactPlugin}
            onError={errorMsg}
          >
            <AppInsightsContext.Provider value={reactPlugin}>
              <UIConfigProvider>
                <ThemedStylesheet />
                <div data-test="test-app" className="oneteam-app">
                  <div className="oneteam-app-body">
                    <Routes>
                      {authenticatedPaths.map(authenticatedRoute)}
                      {/* Public Routes */}
                      <Route path="/login" element={<LoginPage />} />
                      <Route path="/logout" element={<LogoutPage />} />
                      <Route
                        path="/accountNotFound"
                        element={<AccountNotFound />}
                      />
                      <Route
                        path="/forgotPassword"
                        exact
                        element={<ForgotPassword />}
                      />
                      <Route path="/signup" element={<SignUpPage />} />
                      <Route
                        path="/resetPassword"
                        element={<ResetPassword />}
                      />
                      <Route
                        path="/external"
                        element={<ExternalLinkWithObject />}
                      />
                      <Route path="*" element={<LoginPage />} />
                    </Routes>
                  </div>
                  <ToastContainer
                    position="bottom-right"
                    hideProgressBar={true}
                    transition={Slide}
                    limit={5}
                  />
                </div>
              </UIConfigProvider>
            </AppInsightsContext.Provider>
          </AppInsightsErrorBoundary>
        </Provider>
      </React.StrictMode>
    </>
  );
}

export default App;
