import React from "react";

import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import "./HamburgerMenuItem.scss";

const HamburgerMenuItem = props => {
  const {
    itemIcon,
    itemName,
    itemPath,
    onClickItem,
    onClose,
    itemState,
    type
  } = props;

  const onMenuItemClick = () => {
    if (onClickItem) {
      onClickItem();
    }
    onClose();
  };

  const renderActionMenuItem = () => {
    return (
      <div className="hamburgerMenu-item" onClick={onMenuItemClick}>
        <span className={`hamburgerMenu-item__name`}>{itemName}</span>
      </div>
    );
  };

  const renderNavLinkMenuItem = () => {
    return (
      <NavLink
        className="hamburgerMenu-item"
        data-testid="hamburgerMenu-item"
        to={itemPath}
        state={itemState || null}
        onClick={onMenuItemClick}
      >
        <div className="hamburgerMenu-item__icon">
          <i className="material-icons item-icon">{itemIcon}</i>
        </div>
        <span className="hamburgerMenu-item__name">{itemName}</span>
      </NavLink>
    );
  };

  const renderMenuItem = () => {
    switch (type) {
      case "action":
        return renderActionMenuItem();
      case "link":
        return renderNavLinkMenuItem();
      default:
        return;
    }
  };
  return <>{renderMenuItem()}</>;
};

HamburgerMenuItem.defaultProps = {
  type: "link"
};

HamburgerMenuItem.propTypes = {
  itemIcon: PropTypes.string,
  itemName: PropTypes.string,
  itemPath: PropTypes.string,
  onClickItem: PropTypes.func,
  onClose: PropTypes.func,
  itemState: PropTypes.object,
  type: PropTypes.oneOf(["link", "action"])
};

export default HamburgerMenuItem;
