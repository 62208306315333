import React, { useCallback, useEffect, useState } from "react";

import { withTranslation } from "react-i18next";
import * as yup from "yup";

import { useAddProjectTags, useGetCopyProjectTagsList } from "@shared/hooks";

import ErrorBox from "@shared-components/errorBox/ErrorBox";

import Button from "@components/atoms/Button";
import Form from "@components/atoms/Form/Form";
import CopyProjectTagsFormFields from "@components/molecules/CopyProjectTagsFormFields";

import ModalForm from "../../molecules/ModalForm/ModalForm";

function AddOrCopyProjectTags(props) {
  const { onCancel, onSubmitted, t } = props;
  const { projects, fetchProjectsWithTags } = useGetCopyProjectTagsList();
  const [copyTags, setCopyTags] = useState(false);
  const toggleCopyTagsClicked = () => setCopyTags(!copyTags);
  const [copyProjects, setCopyProjects] = useState([]);
  const {
    addProjectTag,
    addProjectTags,
    addingProjectTags,
    errorAddingProjectTag,
    projectTagsAdded,
    resetAddProjectAdds
  } = useAddProjectTags();

  const yupSchema = !copyTags
    ? yup.object({
        name: yup
          .string()
          .required(t("common:ui.addOrCopyProjectTags.validation.add"))
      })
    : yup.object({
        tags: yup
          .mixed()
          .required(t("common:ui.addOrCopyProjectTags.validation.copy"))
      });

  useEffect(() => {
    if (props.engagementId) {
      fetchProjectsWithTags(props.engagementId);
    }
  }, [fetchProjectsWithTags, props.engagementId]);

  useEffect(() => {
    if (projects && props.existingTags?.length > 0) {
      const projectsWithoutExistingTags = projects
        .filter(p => p.id !== props.projectId)
        .map(p => ({
          ...p,
          tags: p.tags.filter(
            t =>
              !props.existingTags.some(
                et =>
                  et.name.trim().toLowerCase() === t.name.trim().toLowerCase()
              )
          )
        }))
        .filter(p => p.tags.length > 0);
      setCopyProjects(projectsWithoutExistingTags);
    } else {
      setCopyProjects(projects ?? []);
    }
  }, [projects, props.existingTags, props.projectId]);

  const getTitle = () =>
    t(`common:ui.addOrCopyProjectTags.title.${copyTags ? "copy" : "add"}`);
  const getCopyButtonLabel = () =>
    t(`common:ui.addOrCopyProjectTags.button.${copyTags ? "add" : "copy"}`);

  const handleCancel = useCallback(() => {
    onCancel?.();
    resetAddProjectAdds();
  }, [onCancel, resetAddProjectAdds]);

  const handleSubmit = useCallback(
    data => {
      if (addingProjectTags) return;
      if (!copyTags) {
        addProjectTag({
          projectId: props.projectId,
          tag: {
            projectId: props.projectId,
            name: data.name
          }
        });
      } else {
        addProjectTags({
          projectId: props.projectId,
          tags: data.tags.map(t => ({
            name: t.value.name,
            projectId: props.projectId
          }))
        });
      }
      resetAddProjectAdds();
    },
    [
      addProjectTag,
      addProjectTags,
      addingProjectTags,
      copyTags,
      props.projectId,
      resetAddProjectAdds
    ]
  );

  useEffect(() => {
    if (!addingProjectTags && projectTagsAdded) {
      onSubmitted?.();
    }
  }, [addingProjectTags, onSubmitted, projectTagsAdded]);

  return (
    <ModalForm
      title={getTitle()}
      submitLabel={t("common:ui.forms.add.label")}
      boxClassName="ot-add-or-copy-project-tags__form"
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      yupSchema={yupSchema}
    >
      {!!errorAddingProjectTag && <ErrorBox message={errorAddingProjectTag} />}
      {copyTags && copyProjects?.length ? (
        <CopyProjectTagsFormFields projects={copyProjects} />
      ) : (
        <Form.TextField
          label={t("common:ui.addOrCopyProjectTags.textField.label")}
          name="name"
          required={true}
        />
      )}
      {!!copyProjects?.length && (
        <Button
          type="text"
          label={getCopyButtonLabel()}
          onClick={toggleCopyTagsClicked}
        />
      )}
    </ModalForm>
  );
}

export default withTranslation()(AddOrCopyProjectTags);
