import React, { useMemo } from "react";

import { systemConstants } from "@shared/constants";
import { milestoneUtilities } from "@shared/helpers/milestoneUtilities";
import { useGetFeaturesQuery } from "@shared/services/featuresService";

export const useProjectFormConfig = ({ engagementTypeConfig, t }) => {
  const { data: features } = useGetFeaturesQuery();

  const projectYearEnabled = useMemo(
    () => !!engagementTypeConfig?.project?.yearField?.enabled,
    [engagementTypeConfig?.project?.yearField?.enabled]
  );

  const entitiesEnabled = useMemo(
    () => !!engagementTypeConfig?.entities?.enabled,
    [engagementTypeConfig?.entities?.enabled]
  );

  const projectManagerEnabled = useMemo(
    () => !!features?.[systemConstants.features.projectManagerField],
    [features]
  );

  const startDateLabel = useMemo(
    () => milestoneUtilities.renderStartLabel(engagementTypeConfig, t) ?? "",
    [engagementTypeConfig, t]
  );

  const endDateLabel = useMemo(
    () => milestoneUtilities.renderEndLabel(engagementTypeConfig, t) ?? "",
    [engagementTypeConfig, t]
  );

  return {
    entitiesEnabled,
    projectManagerEnabled,
    projectYearEnabled,
    startDateLabel,
    endDateLabel
  };
};
