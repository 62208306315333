import React, { useCallback, useEffect, useMemo } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { useAuthUser } from "@shared/hooks/useAuthUser";
import { useGetProjectMembers } from "@shared/hooks/useGetProjectMembers";
import { useUpdateQuery } from "@shared/hooks/useUpdateQuery";

import { getQueryValidationSchema } from "@app/helpers/actionItems";

import Form from "@components/atoms/Form/Form";
import ModalForm from "@components/molecules/ModalForm";

const UpdateCopiedTo = ({
  t,
  query,
  queryConfig,
  project,
  document,
  onCancel,
  onUpdate
}) => {
  const { updatedQuery, updateQueryError, updateQuery, isUpdating } =
    useUpdateQuery();
  const { members } = useGetProjectMembers(project);
  const { user } = useAuthUser();

  const files = useMemo(
    () => [
      {
        isDeleted: false,
        isNew: false,
        name: document?.name,
        projectId: query?.projectId
      }
    ],
    [document?.name, query]
  );

  const membersForDropdown = useMemo(() => {
    if (!members) {
      return [];
    }

    const allUsers = (members.clientUsers || [])
      .concat(members.hostUsers || [])
      .filter(member => member.id !== user.id)
      .map(member => ({ name: member.name, value: member }));
    return allUsers;
  }, [members, user.id]);

  useEffect(() => {
    if (updatedQuery?.id) {
      onUpdate(updatedQuery);
    }
  }, [isUpdating, onUpdate, updatedQuery]);

  const handleSubmit = useCallback(
    data => {
      updateQuery({
        ...query,
        files,
        copiedTo: data.copiedTo?.map(({ value: user }) => user) ?? []
      });
    },
    [files, query, updateQuery]
  );

  const copiedToField = useMemo(() => {
    return queryConfig?.fields?.find(f => f.key === "copiedTo");
  }, [queryConfig]);

  const initialCopiedTo = useMemo(() => {
    const copyTo = query?.[copiedToField?.key];
    if (!copyTo?.length) {
      return [];
    }
    return copyTo.map(u => ({ id: u.id, name: u.name, value: u }));
  }, [query, copiedToField]);

  const yupSchema = useMemo(() => {
    return getQueryValidationSchema({
      configFields: copiedToField ? [copiedToField] : [],
      i18nText: t
    });
  }, [copiedToField, t]);

  return (
    <>
      <ModalForm
        boxClassName="ot-reassign-query"
        title={t("requests:requests.ui.editCopiedTo.title")}
        handleCancel={onCancel}
        handleSubmit={handleSubmit}
        submitLabel={t("common:ui.forms.update.label")}
        cancelLabel={t("common:ui.addTag.cancelLabel")}
        yupSchema={yupSchema}
        defaultValues={{
          [copiedToField?.key]: initialCopiedTo
        }}
        errorMessage={updateQueryError || null}
      >
        {copiedToField && (
          <Form.Multiselect
            key={copiedToField.key}
            name={copiedToField.key}
            label={t(`requests:requests.configured.fields.copiedTo.label`, {
              context: queryConfig.key
            })}
            required={copiedToField.required}
            items={membersForDropdown}
            defaultValueComparer={(a, b) => a.id === b.id}
          />
        )}
      </ModalForm>
    </>
  );
};

UpdateCopiedTo.defaultProps = {};

UpdateCopiedTo.propTypes = {
  query: PropTypes.any.isRequired,
  project: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  document: PropTypes.any, // this is currently required for Websheet Requests
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
};

export default withTranslation()(UpdateCopiedTo);
