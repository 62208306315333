import { manageProfileConstants, systemConstants } from "../constants";

const initialState = {
  loading: false,
  loaded: false,
  error: "",
  user: {},
  requireLogout: false,
  supportedMimes: systemConstants.mimes.image
};

export function manageProfile(state = initialState, action = {}) {
  switch (action.type) {
    case manageProfileConstants.GET_SUPPORTED_MIMES_FOR_IMAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageProfileConstants.GET_SUPPORTED_MIMES_FOR_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        supportedMimes: action.mimesList
      };
    case manageProfileConstants.GET_SUPPORTED_MIMES_FOR_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        supportedMimes: systemConstants.mimes.image,
        error: action.error.key ?? action.error.message
      };
    case manageProfileConstants.GET_USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case manageProfileConstants.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        user: action.user
      };
    case manageProfileConstants.GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.key ?? action.error.message
      };
    case manageProfileConstants.UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: ""
      };
    case manageProfileConstants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        requireLogout: action.requireLogout ?? false,
        loaded: true,
        loading: false,
        user: {
          ...state.user,
          properties: {
            ...(state.user.properties ?? {}),
            ...(action.properties ?? {})
          }
        }
      };
    case manageProfileConstants.UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.key ?? action.error.message
      };
    case manageProfileConstants.CLEAR_ERROR:
      return {
        ...state,
        error: ""
      };
    case manageProfileConstants.RESET:
      return initialState;
    default:
      return state;
  }
}
