import React, { Suspense, useContext, useMemo } from "react";

import UIConfigContext from "@app/helpers/UIConfigContext";

const DataTableDropdownSelect = props => {
  const uiConfig = useContext(UIConfigContext);

  const ThemedDataTableDropdownSelect = useMemo(() => {
    switch (uiConfig?.theme?.designSystem) {
      case "ot":
        return React.lazy(() => import("./OTDataTableDropdownSelect"));
      case "tds":
      default:
        return React.lazy(() => import("./TDSDataTableDropdownSelect"));
    }
  }, [uiConfig?.theme?.designSystem]);

  return (
    <>
      <Suspense fallback={<div></div>}>
        <ThemedDataTableDropdownSelect
          {...props}
          theme={uiConfig?.theme?.designSystem}
        ></ThemedDataTableDropdownSelect>
      </Suspense>
    </>
  );
};

export default DataTableDropdownSelect;
