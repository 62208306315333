import { clientsMenuUtil } from "@shared/services/clientsMenuService";

import { manageClientsConstants } from "../constants";
import { manageClientsService } from "../services";

export const manageClientsActions = {
  getClient,
  getAllClientsWithEngagementsAndProjects,
  getClients,
  getClientsFromCRM,
  addClient,
  updateClient,
  clearError,
  clearGetClientFlags,
  clearAddClientFlags,
  clearUpdateClientFlags,
  resetClients
};

function getClient(user) {
  return dispatch => {
    dispatch(request());

    manageClientsService.getClient(user).then(
      client => {
        dispatch(success(client));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: manageClientsConstants.GET_CLIENT_REQUEST };
  }

  function success(client) {
    return { type: manageClientsConstants.GET_CLIENT_SUCCESS, client };
  }

  function failure(error) {
    return { type: manageClientsConstants.GET_CLIENT_FAILURE, error };
  }
}

function getClients({ hostId }) {
  return dispatch => {
    dispatch(request());

    manageClientsService.getClients({ hostId }).then(
      clients => {
        dispatch(success(clients));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: manageClientsConstants.GET_CLIENTS_REQUEST };
  }
  function success(clients) {
    return { type: manageClientsConstants.GET_CLIENTS_SUCCESS, clients };
  }
  function failure(error) {
    return { type: manageClientsConstants.GET_CLIENTS_FAILURE, error };
  }
}

function getAllClientsWithEngagementsAndProjects({
  hostId,
  userId,
  includeAllStatuses
}) {
  return dispatch => {
    dispatch(request());
    manageClientsService
      .getAllClientsWithEngagementsAndProjects({
        hostId,
        userId,
        includeAllStatuses
      })
      .then(
        clients => {
          dispatch(success(clients));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return {
      type: manageClientsConstants.GET_CLIENTS_WITH_ENGAGEMENTS_AND_PROJECTS_REQUEST
    };
  }
  function success(clients) {
    return {
      type: manageClientsConstants.GET_CLIENTS_WITH_ENGAGEMENTS_AND_PROJECTS_SUCCESS,
      clients
    };
  }
  function failure(error) {
    return {
      type: manageClientsConstants.GET_CLIENTS_WITH_ENGAGEMENTS_AND_PROJECTS_FAILURE,
      error
    };
  }
}

function getClientsFromCRM() {
  return dispatch => {
    dispatch(request());

    manageClientsService.getClientsFromCRM().then(
      clients => {
        dispatch(success(clients));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: manageClientsConstants.GET_CLIENTS_FROM_CRM_REQUEST };
  }
  function success(clients) {
    return {
      type: manageClientsConstants.GET_CLIENTS_FROM_CRM_SUCCESS,
      clients
    };
  }
  function failure(error) {
    return { type: manageClientsConstants.GET_CLIENTS_FROM_CRM_FAILURE, error };
  }
}

function addClient(client) {
  return dispatch => {
    dispatch(request());

    manageClientsService.addClient(client).then(
      client => {
        dispatch(success(client));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: manageClientsConstants.ADD_CLIENT_REQUEST };
  }
  function success(client) {
    return { type: manageClientsConstants.ADD_CLIENT_SUCCESS, client };
  }
  function failure(error) {
    return { type: manageClientsConstants.ADD_CLIENT_FAILURE, error };
  }
}

function updateClient(client) {
  return dispatch => {
    dispatch(request());

    manageClientsService.updateClient(client).then(
      () => {
        dispatch(clientsMenuUtil.invalidateTags(["clientsMenu"]));
        dispatch(success());
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: manageClientsConstants.UPDATE_CLIENT_REQUEST };
  }
  function success() {
    return { type: manageClientsConstants.UPDATE_CLIENT_SUCCESS };
  }
  function failure(error) {
    return { type: manageClientsConstants.UPDATE_CLIENT_FAILURE, error };
  }
}

function clearError() {
  return { type: manageClientsConstants.CLEAR_ERROR };
}

function clearGetClientFlags() {
  return { type: manageClientsConstants.CLEAR_GET_CLIENT_FLAGS };
}

function clearAddClientFlags() {
  return { type: manageClientsConstants.CLEAR_ADD_CLIENT_FLAGS };
}

function resetClients() {
  return { type: manageClientsConstants.RESET_CLIENTS };
}

function clearUpdateClientFlags() {
  return { type: manageClientsConstants.CLEAR_UPDATE_CLIENT_FLAGS };
}
