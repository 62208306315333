import React, { lazy } from "react";
import PropTypes from "prop-types";
import UploadFile from "@components/molecules/UploadFile";

const JsonEditor = lazy(() => import('@components/molecules/JsonEditor'));

const UploadInteractiveBox = ({
  handleDrop,
  setError,
  openFileSelection,
}) => {
  const handleRejection = errors => {
    errors.forEach(e => {
      if (e.errors[0].code === "file-invalid-type") {
        setError({
          message:
            "interactiveReport:interactiveReport.ui.validation.invalidFileFormatError"
        });
      } else if (e.errors[0].code === "too-many-files") {
        setError({
          message:
            "interactiveReport:interactiveReport.ui.validation.multipleFileError"
        });
      }
    });
  };

  return (
    <UploadFile
      handleDrop={handleDrop}
      handleRejection={handleRejection}
      supportedDocumentMimes={["json"]}
      maxNumberOfFiles={1}
      openFileSelection={openFileSelection}
    />
  );
};

UploadInteractiveBox.propTypes = {
  handleDrop: PropTypes.func,
  setError: PropTypes.func,
  openFileSelection: PropTypes.func,
};

export default UploadInteractiveBox;
