import React from "react";

import PropTypes from "prop-types";

const FormTemplate = props => {
  return (
    <form
      autoComplete={props.autoComplete}
      className={`ot-form ${props.customFormClassName}`}
      onSubmit={props.onSubmit}
    >
      <div className="ot-form__fields">{props.children}</div>
      {props.actionButtons}
    </form>
  );
};

FormTemplate.propTypes = {
  autoComplete: PropTypes.string,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
  actionButtons: PropTypes.any,
  customFormClassName: PropTypes.string
};

export default FormTemplate;
