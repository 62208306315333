import { useCallback } from "react";

import { useDispatch } from "react-redux";

import { clientDocumentActions } from "@shared/actions";
import { systemConstants } from "@shared/constants";

const STATUS = systemConstants.project.document.status;

export function useDeleteClientDocument(clientId, document) {
  const dispatch = useDispatch();

  const deleteDocument = useCallback(() => {
    if (document.status === STATUS.archived) {
      dispatch(clientDocumentActions.deleteClientDocument(clientId, document));
    } else {
      const doc = {
        ...document,
        status: STATUS.archived
      };
      dispatch(
        clientDocumentActions.updateClientDocumentProperties(clientId, doc)
      );
    }
  }, [dispatch, clientId, document]);

  const restoreDocument = useCallback(() => {
    dispatch(clientDocumentActions.restoreClientDocument(clientId, document));
  }, [clientId, dispatch, document]);

  return {
    deleteDocument,
    restoreDocument
  };
}
