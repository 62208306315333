import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { manageClientEngagementProjectsActions } from "@shared/actions";

export function useUpdateClientEngagementProject() {
  const dispatch = useDispatch();

  const updateProject = project => {
    dispatch(manageClientEngagementProjectsActions.updateProject(project));
  };

  return { updateProject };
}
