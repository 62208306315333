import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import * as _ from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { useUrlHash } from "@shared/hooks/useUrlHash";

import { paginationHelper } from "@app/helpers/pagination";

import BrandButton from "@components/atoms/Button/BrandButton";

import "./Pagination.scss";
import PaginationNumber from "./PaginationNumber";

const defaultStartingPage = 1;
const pageKey = "page";

const Pagination = ({
  totalPages,
  handlePageChange,
  pageInfo,
  type = paginationHelper.paginationType.none,
  sticky = true,
  startingPage = null,
  maxNumberOfVisiblePages = 9
}) => {
  const isMounted = useRef(false);
  const { urlHashDetail, updateUrlHash } = useUrlHash();

  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(defaultStartingPage);
  const pages = useMemo(() => _.range(1, totalPages + 1), [totalPages]);

  const handlePageUpdate = useCallback(
    page => {
      updateUrlHash(pageKey, page);
    },
    [updateUrlHash]
  );

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const page = urlHashDetail.get("page");
    if (isMounted.current) {
      const updatedPage =
        +page && !isNaN(+page) && pageInfo[+page] ? +page : defaultStartingPage;
      setCurrentPage(updatedPage);
      handlePageChange?.(updatedPage);
    }
    // Working to find a better solution for ignoring deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlHashDetail.get("page")]);

  useEffect(() => {
    if (isMounted.current) {
      if (startingPage) {
        handlePageUpdate(startingPage);
      }
    }
    // Working to find a better solution for ignoring deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startingPage]);

  const visiblePages = useMemo(
    () =>
      paginationHelper.getVisiblePages(
        currentPage,
        totalPages,
        maxNumberOfVisiblePages
      ),
    [currentPage, maxNumberOfVisiblePages, totalPages]
  );

  const progressMessage = useMemo(
    () => paginationHelper.getPaginationMessage(t, pageInfo, type, totalPages),
    [pageInfo, t, totalPages, type]
  );

  const renderPageNumbers = useMemo(() => {
    let previousPage = 0;
    return (
      <div key="pagination-number-list" className="pagination__numbers">
        {pages
          ?.filter(pageNum => visiblePages.includes(pageNum))
          ?.map(page => {
            const breaker = page - 1 !== previousPage ? <div>...</div> : null;
            previousPage = page;
            return (
              <React.Fragment key={`pagination-number-${page}`}>
                {breaker}
                <PaginationNumber
                  t={t}
                  page={page}
                  isActive={page === currentPage}
                  information={pageInfo?.[page]}
                  handlePageUpdate={handlePageUpdate}
                  type={type}
                />
              </React.Fragment>
            );
          })}
      </div>
    );
  }, [currentPage, handlePageUpdate, pageInfo, pages, t, type, visiblePages]);

  return (
    <div
      key="pagination"
      className={`pagination${sticky ? " pagination--sticky" : ""}`}
    >
      <div key="pagination-context" className="pagination__content">
        <div key="pagination-prev" className="pagination__prev">
          {currentPage > 1 && (
            <BrandButton
              type="text"
              onClick={() => handlePageUpdate(currentPage - 1)}
              iconName="chevron_left"
              label={t("common:ui.pagination.previous")}
            />
          )}
        </div>
        {renderPageNumbers}
        <div key="pagination-next" className="pagination__next">
          {currentPage < totalPages && (
            <BrandButton
              type="text"
              className="pagination__next"
              onClick={() => handlePageUpdate(currentPage + 1)}
              iconName="chevron_right"
              label={t("common:ui.pagination.next")}
              iconSide={"right"}
            />
          )}
        </div>
      </div>
      {progressMessage && (
        <div key="pagination-summary" className="pagination__summary">
          {progressMessage}
        </div>
      )}
    </div>
  );
};

Pagination.defaultProps = {
  type: null,
  sticky: true,
  startingPage: null,
  maxNumberOfVisiblePages: 9
};

Pagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  pageInfo: PropTypes.shape({
    name: PropTypes.string,
    completeCount: PropTypes.number,
    totalCount: PropTypes.number
  }).isRequired,
  type: PropTypes.string,
  sticky: PropTypes.bool,
  startingPage: PropTypes.number,
  maxNumberOfVisiblePages: PropTypes.number
};

export default Pagination;
