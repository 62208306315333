import React, { useEffect, useMemo, useRef, useState } from "react";

import PropTypes from "prop-types";
import useOnClickOutside from "use-onclickoutside";

import { useLazyGetClientProjectsForMenuQuery } from "@shared/services/clientProjectsService";

import { classNames, getScrollPosition } from "@app/helpers/componentHelpers";

import Icon from "../../atoms/Icon";
import DropdownItemList from "../../molecules/DropdownItemList/DropdownItemList";
import "./NavMultilevelDropdown.scss";

const NavMultilevelDropdown = props => {
  const { name, dropdownItems, isActive, handleNavigate, isExpandable } = props;
  const [showDropdownMenu, setShowDropdownMenu] = useState(false); // Toggle top level
  const [expandedItemId, setExpandedItemId] = useState(null); // Toggle secondary level
  const [getClientProjects, { data: clientProjects, isFetching }] =
    useLazyGetClientProjectsForMenuQuery();
  const secondaryListOpen = useMemo(() => {
    return clientProjects?.length > 0 && expandedItemId;
  }, [clientProjects, expandedItemId]);

  const handleClickSecondaryItem = itemId => {
    handleNavigate(`/projects/${itemId}`);
    setShowDropdownMenu(false);
  };

  const buttonRef = useRef(null);
  const navItemRef = useRef(null);

  useOnClickOutside(navItemRef, e => {
    //we need both navItemRef and buttonRef to check if the click is outside the dropdown
    //because there is no common parent that is a rectangle
    if (!buttonRef.current?.contains(e.target)) {
      setShowDropdownMenu(false);
    }
  });

  const toggle = () => {
    setShowDropdownMenu(prevState => !prevState);
  };

  const handleClickPrimaryItem = itemId => {
    setExpandedItemId(prevItemId => {
      if (prevItemId !== itemId) {
        // when the item is getting opened, we want to call the API to get the projects
        getClientProjects(itemId, true);
        return itemId;
      }
      return null;
    });
  };

  const handlePrimaryListScroll = () => {
    setExpandedItemId(null);
  };

  const renderSecondaryList = () => {
    return (
      <DropdownItemList
        isLoading={isFetching}
        dropdownItems={clientProjects?.map(item => {
          return {
            ...item,
            onClick: () => {
              handleClickSecondaryItem(item.id);
            }
          };
        })}
        style={{ marginTop }}
        isExpandable={false}
      />
    );
  };

  useEffect(() => {
    if (!showDropdownMenu) {
      setExpandedItemId(null);
    }
  }, [showDropdownMenu]);

  const renderClassName = useMemo(
    () =>
      `nav-multilevel-dropdown__items ${
        showDropdownMenu ? "nav-multilevel-dropdown__items-show" : ""
      }`,
    [showDropdownMenu]
  );

  const marginTop = useMemo(() => {
    const element = document.getElementById(expandedItemId);
    const position = getScrollPosition(
      element,
      "nav-multilevel-dropdown__inner"
    );
    return position.top;
  }, [expandedItemId]);

  return (
    <div
      className={classNames([
        "nav-multilevel-dropdown",
        isActive ? "active" : ""
      ])}
      onClick={toggle}
      ref={buttonRef}
    >
      <button className="nav-multilevel-dropdown__button">
        <span className="nav-multilevel-dropdown__label">{name}</span>
        <Icon name="expand_more" className="material-icons" size="normal" />
      </button>
      <div
        className={renderClassName}
        id="nav-multilevel-dropdown__inner"
        onScroll={handlePrimaryListScroll}
        ref={navItemRef}
      >
        {/* Primary Item List */}
        <DropdownItemList
          dropdownItems={dropdownItems.map(item => {
            return {
              ...item,
              isExpanded: item.id === expandedItemId,
              onClick: () => {
                if (isExpandable) {
                  handleClickPrimaryItem(item.id);
                } else {
                  handleClickSecondaryItem(item.id);
                }
              }
            };
          })}
          onScroll={handlePrimaryListScroll}
          search
          isExpandable={isExpandable}
          setExpandedItemId={setExpandedItemId}
          placeholder={props.placeholder}
        />
        {/* Secondary Item List */}
        {secondaryListOpen && renderSecondaryList()}
      </div>
    </div>
  );
};

NavMultilevelDropdown.propTypes = {
  name: PropTypes.string,
  dropdownItems: PropTypes.array,
  isActive: PropTypes.bool,
  handleNavigate: PropTypes.func,
  isExpandable: PropTypes.bool,
  placeholder: PropTypes.string
};

export default NavMultilevelDropdown;
