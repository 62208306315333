import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { utilities } from "@shared/helpers/utilities";
import { useGuardedNavigate } from "@shared/hooks/useGuardedNavigate";
import { useSecondaryNav } from "@shared/hooks/useSecondaryNav";
import { useUIConfig } from "@shared/hooks/useUIConfig";
import { useUserProfile } from "@shared/hooks/useUserProfile";
import { useWindowSize } from "@shared/hooks/useWindowSize";

import ErrorBox from "@shared-components/errorBox/ErrorBox";

import { classNames as classNamesFunc } from "@app/helpers/componentHelpers";
import { useLastKnownPath } from "@app/hooks/useLastKnowPath";

import Title from "@components/atoms/Title/Title";
import AskHelp from "@components/molecules/AskHelp/AskHelp";
import Loading from "@components/molecules/Loading/Loading";
import ApplicationNotification from "@components/organisms/ApplicationNotification";
import Footer from "@components/organisms/Footer";
import Heading from "@components/organisms/Heading/Heading";
import SideMenu from "@components/organisms/SideMenu/SideMenu";

import "./PageTemplate.scss";
import variables from "./exportables.module.scss";
import {
  defaultProps,
  getBodyContentStyle,
  getBodySecondaryStyle,
  getCurrentContentWidth,
  propTypes
} from "./pageTemplateHelper";

/**
 *
 * @param   {Object}  props
 *
 * @param   {Object}  props.header
 * @param   {string}  props.header.title Heading text for the page
 * @param   {JSX.Element|string}  [props.header.subtitle] Subeading text for the page
 * @param   {"inline"|"newline"} [props.header.subtitlePosition] Position of subtitle relative to title
 * @param   {boolean} [props.header.sticky] If heading is sticky on scroll
 * @param   {JSX.Element} [props.header.icon] Icon to display in heading
 * @param   {JSX.Element} [props.header.actions] Heading actions (displays at top right of heading)
 * @param   {JSX.Element} [props.header.content] Heading content (displays in container under heading)
 * @param   {Object[]}  [props.header.breadcrumbs]
 * @param   {string}  [props.header.tagline]
 *
 * @param   {Object}  props.body
 * @param   {JSX.Element} props.body.header Items to appear on top of the primary/secondary body
 * @param   {JSX.Element} props.body.primary Items to appear in primary body
 * @param   {JSX.Element} [props.body.secondary] Items to appear in secondary body
 * @param   {string} [props.body.secondaryWidth] Width of secondary - default to not specified
 * @param   {string} [props.body.secondaryMaxWidth] Max width of secondary - default to not specified
 * @param   {string} [props.body.secondaryMinWidth] Min width of secondary - default to not specified
 * @param   {boolean} [props.body.secondaryOnRight] If body secondary appears on right - default false
 *
 * @param   {Object}  props.other
 * @param   {string} [props.other.error] If content has an error (displays at top of body)
 * @param   {string} [props.other.loading] If content is loading (replaces body with loading message until other.loading is null)
 * @param   {string} [props.other.help] If content has no data
 * @param   {number}  [props.other.smallPageSize] Size which the secondary body element moves from left/right to top of page (default 1400)
 * @param   {boolean}  [props.other.isolationMode] If page is isolated from navigation
 * @param   {boolean}  [props.other.hideFooter] If footer is hidden
 * @param   {boolean}  [props.other.fullScreenContent] If content takes up all available space
 * @param   {Object}  [props.other.project] If there is a project in the context of this page
 * @param   {Object}  [props.other.client] If there is a client in the context of this page
 * @param   {Object}  [props.other.secondaryNavDisabled] If secondary Nav is disabled for the page
 *
 * @param   {Object}  props.classNames
 * @param   {string} [props.classNames.page] Class for page template
 * @param   {string} [props.classNames.banner] Class for banner if any
 *
 * @param   {Object}  props.sidePanel
 * @param   {boolean} props.sidePanel.open If side panel is open
 * @param   {JSX.Element} props.sidePanel.content Items to appear in side panel - recommended to pass in <SidePanelContentTemplate />
 * @param   {string} [props.sidePanel.width] Width of side panel for e.g. "35vw" (optional) - default set in theme variables
 *
 * @param   {Object}  props.modal
 * @param   {boolean} props.modal.open If modal is open
 * @param   {JSX.Element} props.modal.content Items to appear in modal - recommended to use <ModalContentTemplate />
 * @param   {boolean} props.skipSavePath If true, the last known path will not be saved
 * @param   {React.UIEventHandler} [props.onPageScroll] Callback function to be called when page is scrolled
 *
 * @component
 * @example
 * const project = ...;
 * return (
 *   <PageTemplate
 *      header={{ title: 'Report', sticky: true}}
 *      body={{ primary: (<Button/>) }}
 *    />
 * )
 */

// Updated page template to be used for all
const PageTemplate = ({
  header,
  body,
  other,
  classNames,
  sidePanel,
  modal,
  skipSavePath,
  onPageScroll
}) => {
  useLastKnownPath(skipSavePath);
  const { i18n } = useTranslation();
  const location = useLocation();
  const windowSize = useWindowSize();
  const { userProfile } = useUserProfile();
  const { uiConfig } = useUIConfig();
  const { setUnsaved, getUnsavedPopup, handleNavigate } = useGuardedNavigate();
  const {
    width: secondaryNavWidth,
    isExpanded: isSecondaryNavExpanded,
    enabled: secondaryNavEnabled
  } = useSecondaryNav();
  const [subNavActive, setSubNavActive] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [currentContentWidth, setCurrentContentWidth] = useState(
    getCurrentContentWidth(secondaryNavEnabled, secondaryNavWidth)
  );
  const [currentSidePanelWidth, setCurrentSidePanelWidth] = useState(`0px`);

  const [pageContentReplacement, setPageContentReplacement] = useState(null);

  const bodyContentStyle = useMemo(() => {
    return getBodyContentStyle(
      currentContentWidth,
      secondaryNavWidth,
      uiConfig?.theme?.navigationStyle === "OT2"
        ? variables.pageHeaderHeight
        : variables.pageHeaderHeightOT1,
      variables.navigationBarHeight,
      subNavActive,
      other.secondaryNavDisabled,
      other.isolationMode
    );
    // Below line is to ensure the body content style updates with subNavActive update and header.title update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentContentWidth,
    secondaryNavWidth,
    header.title,
    subNavActive,
    other.isolationMode
  ]);

  const bodySecondaryStyle = useMemo(() => {
    return getBodySecondaryStyle(
      windowSize.width,
      other.smallPageSize,
      body.secondaryWidth,
      body.secondaryMaxWidth,
      body.secondaryMinWidth,
      variables.pageHeaderHeight,
      variables.navigationBarHeight,
      variables.bodyPadding
    );
  }, [
    windowSize.width,
    other.smallPageSize,
    body.secondaryWidth,
    body.secondaryMaxWidth,
    body.secondaryMinWidth
  ]);

  if (!sidePanel.width) {
    sidePanel.width = variables.sidePanelWidth;
  }

  const handleClearNotificationMessage = useCallback(() => {
    utilities.clearExternalLinkObjectFromSessionLastKnownPath();
  }, []);
  useEffect(() => {
    setUnsaved(other.unsaved || false);
  }, [other.unsaved, setUnsaved]);

  const updateWidths = useCallback(() => {
    if (other.isolationMode) {
      if (sidePanel.open) {
        setCurrentContentWidth("65vw");
        setCurrentSidePanelWidth("35vw");
      } else {
        setCurrentContentWidth("100vw");
        setCurrentSidePanelWidth(`0vw`);
      }
    } else {
      if (!isSecondaryNavExpanded && !sidePanel.open) {
        setCurrentContentWidth(
          getCurrentContentWidth(secondaryNavEnabled, variables.sideNavWidth)
        );
        setCurrentSidePanelWidth(`0vw`);
      } else if (isSecondaryNavExpanded && !sidePanel.open) {
        setCurrentContentWidth(
          getCurrentContentWidth(
            secondaryNavEnabled,
            variables.sideNavWidthExpanded
          )
        );
        setCurrentSidePanelWidth(`0vw`);
      } else if (!isSecondaryNavExpanded && sidePanel.open) {
        setCurrentContentWidth(
          getCurrentContentWidth(
            secondaryNavEnabled,
            `${sidePanel.width} - ${variables.sideNavWidth} / 2`
          )
        );
        setCurrentSidePanelWidth(
          `calc(${sidePanel.width} - ${
            secondaryNavEnabled ? variables.sideNavWidth : 0
          } / 2)`
        );
      } else if (isSecondaryNavExpanded && sidePanel.open) {
        setCurrentContentWidth(
          getCurrentContentWidth(
            secondaryNavEnabled,
            `${sidePanel.width} - (${variables.sideNavWidthExpanded} / 2)`
          )
        );
        setCurrentSidePanelWidth(
          `calc(${sidePanel.width} - (${variables.sideNavWidthExpanded} / 2))`
        );
      }
    }
  }, [
    other.isolationMode,
    isSecondaryNavExpanded,
    sidePanel.open,
    sidePanel.width,
    secondaryNavEnabled
  ]);

  // This prevents body from scrolling when using page template as scrolling is handled in either body or side panel
  useEffect(() => {
    const overflow = document.body.style.overflow;
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = overflow;
    };
  }, []);

  useEffect(() => {
    if (other.loading) {
      setPageContentReplacement(<Loading message={other.loading} />);
    } else if (other.help) {
      setPageContentReplacement(<AskHelp message={other.help} />);
    } else {
      setPageContentReplacement(null);
    }
  }, [other.loading, other.help]);

  useEffect(() => {
    updateWidths();
  }, [updateWidths, isSecondaryNavExpanded, sidePanel.open]);

  useEffect(() => {
    const languagePreference =
      userProfile?.properties?.languagePreferences?.UILanguage;
    if (languagePreference && i18n.language !== languagePreference) {
      i18n.changeLanguage(languagePreference);
    }
  }, [userProfile, i18n, i18n.language]);

  useEffect(() => {
    if (location?.state?.externalLinkObject?.message) {
      setNotificationMessage(location.state.externalLinkObject.message);
    }
  }, [location?.state]);

  const returnStringIfTrue = (boolean, string) => (boolean ? string : "");

  return (
    // Page Template
    <>
      <div
        id="page-template"
        data-testid="test-page-template"
        className={`${returnStringIfTrue(
          classNames?.page,
          classNames?.page
        )} page-template ${modal.open ? "page-template--modal-open" : ""}`}
      >
        {/* Header and Top Navigation */}
        {/* .main-page__header added for background image to apply */}
        <div
          id="page-template__header"
          className={`page-template__header main-page__header main-page__header${returnStringIfTrue(
            other.isolationMode,
            "--isolation"
          )}`}
        >
          <Heading
            subNavLeftPosition={secondaryNavWidth}
            setSubNavActive={setSubNavActive}
            isolationMode={other.isolationMode}
            handleNavigate={handleNavigate}
          />
        </div>
        {/* Body */}
        <div className={`page-template__body`}>
          {/* Side Nav */}
          {secondaryNavEnabled &&
            !other.isolationMode &&
            !other.secondaryNavDisabled && (
              <div
                className={classNamesFunc([
                  "page-template__body__side-nav",
                  uiConfig.theme.navigationStyle
                ])}
                style={{ width: secondaryNavWidth }}
              >
                <SideMenu
                  handleNavigate={handleNavigate}
                  project={other.project}
                  client={other.client}
                  width={secondaryNavWidth}
                />
              </div>
            )}
          {/* Content */}
          <div
            id="page-template__body__content"
            className={`page-template__body__content ${returnStringIfTrue(
              other.isolationMode,
              "page-template__body__content--isolation"
            )}`}
            style={bodyContentStyle}
            onScroll={onPageScroll}
          >
            {/* Heading */}
            <div
              id={returnStringIfTrue(
                header.sticky,
                "page-template__sticky-heading"
              )}
              data-html2canvas-ignore
              className={`${returnStringIfTrue(
                classNames?.banner,
                classNames?.banner
              )} page-template__body__content__heading${returnStringIfTrue(
                header.sticky,
                "--sticky"
              )}`}
            >
              {/* Breadcrumbs */}
              {header.breadcrumbs && (
                <div className="page-template__body__content__heading__breadcrumbs">
                  {header.breadcrumbs.map((crumb, index) => (
                    <span key={index}>
                      {index > 0 ? " > " : ""}
                      <Link to={crumb.linkTo} state={crumb.linkTo?.state}>
                        {crumb.label}
                      </Link>
                    </span>
                  ))}
                </div>
              )}
              {/* Tagline */}
              {header.tagline && (
                <div
                  className="page-template__body__content__heading__tagline"
                  role="header.tagline"
                >
                  {header.tagline}
                </div>
              )}
              {/* Top of heading */}
              <Title
                title={header.title}
                subtitle={header.subtitle}
                subtitlePosition={header.subtitlePosition}
                actions={header.actions}
                icon={header.icon}
              />
              {header.content && (
                <div className="page-template__body__content__heading__content">
                  {header.content}
                </div>
              )}
            </div>
            {/* Error message */}
            {other.error && (
              <div className={`page-template__body__content__error-message`}>
                <ErrorBox type="component" message={other.error} />
              </div>
            )}
            {body.header && (
              <div className="page-template__body__content--body-header">
                {body.header}
              </div>
            )}
            {/* Inner */}
            <div
              id="page-template__body__content__inner"
              className={`page-template__body__content__inner${returnStringIfTrue(
                (windowSize.width || window.innerWidth) <= other.smallPageSize,
                "--small-screen"
              )} ${returnStringIfTrue(
                body.secondaryOnRight,
                "page-template__body__content__inner--secondary-on-right"
              )} ${returnStringIfTrue(
                other.fullScreenContent,
                "page-template__body__content__inner--full-screen"
              )}`}
            >
              {pageContentReplacement !== null ? (
                <>{pageContentReplacement}</>
              ) : (
                <>
                  {/* Secondary content */}
                  {body.secondary && (
                    <div
                      className={`page-template__body__content__inner__secondary`}
                      style={bodySecondaryStyle}
                    >
                      <div
                        className={`page-template__body__content__inner__secondary__content`}
                      >
                        {body.secondary}
                      </div>
                    </div>
                  )}
                  {/* Divider if needed */}
                  {body.secondary && (
                    <div
                      className={`page-template__body__content__inner__divider`}
                    ></div>
                  )}
                  {/* Primary content */}
                  <div
                    className={`page-template__body__content__inner__primary`}
                  >
                    {body.primary}
                  </div>
                </>
              )}
            </div>
            {/* Footer */}
            {!other.fullScreenContent && !other.hideFooter && (
              <div className={`page-template__body__content__footer`}>
                <Footer />
              </div>
            )}
          </div>
          {/* Side panel */}
          <div
            className={`page-template__body__side-panel${returnStringIfTrue(
              sidePanel.open,
              "--expanded"
            )}`}
            style={{
              width: currentSidePanelWidth ? currentSidePanelWidth : "auto",
              paddingTop: subNavActive ? variables.navigationBarHeight : 0
            }}
          >
            {sidePanel.open && sidePanel.content}
          </div>
          {/* Notification */}
          <ApplicationNotification
            type="error"
            title="Error"
            message={notificationMessage}
            clearMessage={handleClearNotificationMessage}
          />
          {/* Modal */}
          {modal.open && (
            <div className="page-template__body__modal">{modal.content}</div>
          )}
          {getUnsavedPopup()}
        </div>
      </div>
    </>
  );
};

PageTemplate.defaultProps = defaultProps;
PageTemplate.propTypes = propTypes;

export default PageTemplate;
