import React, { useCallback, useEffect } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import ErrorBox from "@shared/components/errorBox/ErrorBox";
import { useAuthUser } from "@shared/hooks";
import {
  useAddEngagementProjectMutation,
  useGetProjectManagersQuery
} from "@shared/services";

import { getErrorMessage } from "@app/helpers/error";
import {
  getAddProjectFormFieldsSchema,
  getProjectObjectFromProjectFormData
} from "@app/helpers/projectForm";
import { useGoBack } from "@app/hooks";
import { useProjectFormConfig } from "@app/hooks/useProjectFormConfig";

import Form from "@components/atoms/Form/Form";
import ProjectForm from "@components/molecules/ProjectForm/ProjectForm";

const AddEngagementProjectForm = props => {
  const { t } = useTranslation();
  const { user } = useAuthUser();
  const navigate = useNavigate();
  const { engagement } = props;
  const goBack = useGoBack({ defaultPath: `/engagements/${engagement?.id}` });
  const {
    entitiesEnabled,
    projectManagerEnabled,
    projectYearEnabled,
    startDateLabel,
    endDateLabel
  } = useProjectFormConfig({
    engagementTypeConfig: engagement?.engagementType?.configuration,
    t
  });
  const { data: projectManagers } = useGetProjectManagersQuery(
    { hostId: user?.hostId },
    { skip: !projectManagerEnabled || !user?.hostId }
  );
  const [
    addEngagementProject,
    { isLoading: isSubmitting, data: addedProject, isSuccess, isError, error }
  ] = useAddEngagementProjectMutation();

  const yupSchema = yup.object(getAddProjectFormFieldsSchema(t));

  const handleSubmit = useCallback(
    data => {
      const project = {
        engagementId: engagement?.id,
        ...getProjectObjectFromProjectFormData(
          data,
          {
            entitiesEnabled,
            projectManagerEnabled,
            projectYearEnabled
          },
          t
        )
      };
      addEngagementProject({ project, fillUsers: data.copyEngagementUsers });
    },
    [
      addEngagementProject,
      engagement?.id,
      entitiesEnabled,
      projectManagerEnabled,
      projectYearEnabled,
      t
    ]
  );

  const handleCancel = () => {
    goBack({ defaultPath: `/engagements/${engagement?.id}` });
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(`/projects/${addedProject.id}`);
    }
  }, [isSuccess, addedProject, navigate, engagement]);

  return (
    <Form
      yupSchema={yupSchema}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      disabled={isSubmitting || isSuccess}
    >
      {isError && <ErrorBox message={getErrorMessage(error, t)} />}
      <ProjectForm
        enableEntities={entitiesEnabled}
        enableProjectManager={projectManagerEnabled}
        enableProjectYear={projectYearEnabled}
        enableCopyEngagementUsers
        startDateLabel={startDateLabel}
        endDateLabel={endDateLabel}
        projectManagers={projectManagers}
      />
    </Form>
  );
};

AddEngagementProjectForm.propTypes = {
  engagement: PropTypes.shape({
    id: PropTypes.number.isRequired,
    engagementType: PropTypes.shape({
      id: PropTypes.number.isRequired,
      configuration: PropTypes.object.isRequired
    }).isRequired
  })
};

export default AddEngagementProjectForm;
