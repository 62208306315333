import { systemConstants } from "../constants";

function isHostAdmin(user) {
  return user.role.name === systemConstants.roles.engagementManager;
}

function isClientAdmin(user) {
  return user.role.name === systemConstants.roles.clientAdmin;
}

function isClientUser(user) {
  return user.role.name === systemConstants.roles.clientUser;
}

function isAdmin(user) {
  return (
    user.role.name === systemConstants.roles.clientAdmin ||
    user.role.name === systemConstants.roles.engagementManager
  );
}
function isTeamMember(user) {
  return user.role.name === systemConstants.roles.teamMember;
}

function doesBelongToHost(user) {
  return user.hostId ? true : false;
}

function doesBelongToClient(user) {
  return user.hostId ? false : true;
}

function doesBelongToUserGroup(userGroup, user) {
  const isGroupMember = group => {
    switch (group?.toLowerCase()) {
      case "all":
        return true;
      case "client":
        return doesBelongToClient(user);
      case "host":
        return doesBelongToHost(user);
      case "team member":
        return isTeamMember(user);
      case "engagement manager":
        return isHostAdmin(user);
      case "client user":
        return isClientUser(user);
      case "client admin":
        return isClientAdmin(user);
      default:
        return false;
    }
  };
  if (Array.isArray(userGroup)) {
    return userGroup.some(g => isGroupMember(g));
  } else {
    return isGroupMember(userGroup);
  }
}

export const loggedUser = {
  isHostAdmin,
  isTeamMember,
  isClientAdmin,
  isAdmin,
  doesBelongToHost,
  doesBelongToClient,
  doesBelongToUserGroup
};
