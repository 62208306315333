import React, { useMemo } from "react";

import PropTypes from "prop-types";

import { useSecondaryNav } from "@shared/hooks/useSecondaryNav";

import Icon from "@components/atoms/Icon";

import "./SecondaryNavItem.scss";
import DashboardGrid from "./dashboardGrid.svg?react";

const defaultIconProps = {
  name: "stop",
  designStyle: "material-symbols",
  fillStyle: "filled",
  size: "normal",
  colorStyle: "sideNav"
};
const defaultIcon = (
  <Icon
    {...defaultIconProps}
    designStyle="material-icons"
    colorStyle="sideNav-IR"
  />
);
const SecondaryNavItem = props => {
  const { isExpanded, item, onClickNavigate, isMenuItemExpanded } = props;
  const { setMenuItemExpanded } = useSecondaryNav();
  const icon = useMemo(() => {
    if (!item?.icon && !item?.name) {
      return defaultIcon;
    }
    if (item?.icon?.custom) {
      return (
        <div className="secondary-nav-item__icon-container">
          <DashboardGrid style={{ width: "20px" }} />
        </div>
      );
    }
    return (
      <div className="secondary-nav-item__icon-container">
        <Icon {...defaultIconProps} {...(item?.icon || {})} />
      </div>
    );
  }, [item?.icon, item?.name]);
  const clickHandler = () => {
    if (item.id) {
      setMenuItemExpanded({ id: item.id, isExpanded: !isMenuItemExpanded });
    }
  };
  const renderSubItems = () => {
    if (item?.items && isMenuItemExpanded)
      return (
        <>
          {item.items.map(item => (
            <SecondaryNavItem
              key={item.name}
              isExpanded={isExpanded}
              item={item}
              isSubItem={true}
              onClickNavigate={() => onClickNavigate(item.link, item.state)}
            />
          ))}
        </>
      );
  };
  const getDisabledClass = () => (props.disabled ? "disabled" : "");
  const classNames = list => list?.filter(c => c).join(" ");
  return (
    <>
      <div
        className="secondary-nav__item"
        onClick={() => onClickNavigate(item.link, item.state)}
        title={item?.name}
      >
        <div
          className={classNames([
            "secondary-nav-item",
            item?.isActive && "secondary-nav-item--active",
            isExpanded && "secondary-nav-item--expand"
          ])}
          onClick={clickHandler}
        >
          {icon}
          {item?.name && isExpanded && (
            <span
              className={classNames([
                "secondary-nav-item__name",
                item?.isActive && "secondary-nav-item__name--active"
              ])}
            >
              {item?.name}
            </span>
          )}
          {isExpanded && item?.items?.length > 0 && (
            <i
              className={classNames([
                "material-icons",
                "secondary-nav-item__expand",
                getDisabledClass(),
                "reveal"
              ])}
            >
              {isMenuItemExpanded ? "expand_less" : "expand_more"}
            </i>
          )}
        </div>
      </div>
      {renderSubItems()}
    </>
  );
};

SecondaryNavItem.defaultProps = {
  isActive: false,
  isSubItem: false
};

SecondaryNavItem.propTypes = {
  isExpanded: PropTypes.bool,
  isMenuItemExpanded: PropTypes.bool,
  isActive: PropTypes.bool,
  name: PropTypes.string,
  showToolTip: PropTypes.bool,
  item: PropTypes.object,
  onClickNavigate: PropTypes.func,
  isSubItem: PropTypes.bool
};

export default SecondaryNavItem;
