import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { routeConstants } from "@constants";

import { loggedUser } from "@shared/helpers";
import { useGetClientByIdQuery } from "@shared/hooks";
import { useClientUserProfile } from "@shared/hooks/useClientUserProfile";
import { useAddClientUserMutation } from "@shared/services";

import ErrorBox from "@shared-components/errorBox/ErrorBox";

import UserForm from "@components/organisms/UserForm/UserForm";
import MainPageTemplate from "@components/templates/MainPageTemplate";
import PageBodyTemplate from "@components/templates/PageBodyTemplate";

import "./AddClientUser.scss";

const AddClientUser = () => {
  const { t } = useTranslation();
  const { clientId } = useParams();
  const { data: client, isError } = useGetClientByIdQuery(clientId);
  const [addClientUser, { isSuccess: isClientUserAdded }] =
    useAddClientUserMutation();

  const navigate = useNavigate();
  const authentication = useSelector(state => state.authentication);
  const isClientAdmin = loggedUser.isClientAdmin(authentication.user);
  const { roles, clientUserFields, projectsList, error } = useClientUserProfile(
    {
      client
    }
  );

  useEffect(() => {
    if (isClientUserAdded) {
      navigate(`/admin/clients/${clientId}/users`);
    }
  }, [clientId, isClientUserAdded, navigate]);

  const breadcrumbs = () => {
    const result = [];
    if (!isClientAdmin) {
      result.push({
        linkTo: routeConstants.manageClients,
        label: t("common:ui.manageClients.title")
      });
    }
    result.push({
      linkTo: `/admin/clients/${clientId}/users`,
      label: isClientAdmin
        ? t("common:ui.manageUsers.title")
        : t("common:ui.manageUsers.titleWithClientName", {
            clientName: client?.name
          })
    });

    return result;
  };
  const addConfigKey = key =>
    clientUserFields?.find(field => field.key === key) ?? {};

  const addRole = () => {
    if (roles?.length > 1) {
      return {
        key: "role",
        type: "dropdown",
        required: true,
        items: roles
      };
    }
    return {};
  };

  useEffect(() => {
    if (!clientId || isError) {
      navigate(routeConstants.notFound);
    }
  }, [clientId, isError, navigate]);

  const handleSubmit = user => {
    if (!user.roleId) {
      user.roleId = roles?.[0]?.id;
    }
    user.clientId = client.id;
    addClientUser({ user });
  };
  const formConfig = [
    {
      key: "firstname",
      type: "text",
      required: true
    },
    {
      key: "lastname",
      type: "text",
      required: true
    },
    {
      key: "email",
      type: "text",
      required: true
    },
    addConfigKey("jobTitle"),
    addRole(),
    {
      key: "projects",
      type: "multiselect",
      required: false,
      items: projectsList,
      defaultValue: [],
      placeholder: t("common:user.configured.fields.projects.recommended")
    },
    addConfigKey("MOBILE_NUMBER"),
    addConfigKey("BUSINESS_NUMBER"),
    {
      key: "NOTIFICATION",
      type: "radio",
      defaultValue: "digest",
      items: ["digest", "each"],
      path: "properties.notification.email"
    }
  ];

  return (
    <MainPageTemplate>
      <PageBodyTemplate
        breadcrumbs={breadcrumbs()}
        title={
          isClientAdmin
            ? t("common:ui.manageUsers.addTitle_team")
            : t("common:ui.manageUsers.addTitle_client")
        }
      >
        {error && <ErrorBox message={error} />}
        <UserForm
          formConfig={formConfig}
          handleSubmit={handleSubmit}
          submitLabel={t("common:ui.manageUsers.addSubmit")}
        />
      </PageBodyTemplate>
    </MainPageTemplate>
  );
};

export default AddClientUser;
