import React, { useEffect, useRef } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { useWindowSize } from "@shared/hooks/useWindowSize";

import Button from "@components/atoms/Button";
import MilestoneComment from "@components/organisms/MilestoneComment";

import "./MilestoneGraphTemplate.scss";

const MilestoneGraphTemplate = props => {
  const {
    showUpdateButton,
    project,
    milestones,
    selectedMilestone,
    getMilestoneIcons,
    getMilestoneWidth,
    getMilestoneLines,
    onClick,
    onClear,
    t
  } = props;
  const milestoneRef = useRef();
  const windowSize = useWindowSize();
  useEffect(() => {
    if (milestoneRef.current?.clientWidth) {
      getMilestoneWidth(milestoneRef.current.clientWidth);
    }
  }, [getMilestoneWidth, windowSize]);

  const getTemplateForDesktop = () => {
    return (
      <>
        <div
          className={`project-milestone__graph ${
            selectedMilestone ? "has-selected-milestone" : ""
          }`}
          data-testid="milestone-graph"
          ref={milestoneRef}
        >
          <div className="project-milestone__goal-box">
            <div className="project-milestone__goal">
              {milestones
                .slice(0, -1) // don't need the last one as that's the goal post
                .map((milestone, index) => getMilestoneLines(milestone, index))}
              {milestones.map((milestone, index) =>
                getMilestoneIcons(milestone, index)
              )}
            </div>
          </div>
        </div>

        {selectedMilestone && (
          <div
            data-testid="project-milestone-comment"
            className="project-milestone__comment"
          >
            <MilestoneComment
              milestone={selectedMilestone}
              onClose={onClear}
            ></MilestoneComment>
          </div>
        )}
      </>
    );
  };

  return (
    <div className={`project-milestone`}>
      {project && (
        <>
          <div className="project-milestone__header">
            <span className="milestones-name" data-testid="test-milestone-name">
              {t("common:ui.projectSummary.milestoneTitle", {
                projectName: project.name,
                interpolation: { escapeValue: false }
              })}
            </span>

            {showUpdateButton && (
              <div className="update-button">
                <Button
                  type="primary"
                  label={t(
                    `common:ui.projectSummary.milestone.actions.${
                      milestones.length ? "update" : "add"
                    }`
                  )}
                  onClick={onClick}
                />
              </div>
            )}
          </div>
          {getTemplateForDesktop()}
        </>
      )}
    </div>
  );
};

MilestoneGraphTemplate.propTypes = {
  project: PropTypes.shape({
    name: PropTypes.string.isRequired,
    status: PropTypes.string,
    milestoneStatus: PropTypes.string,
    milestones: PropTypes.array,
    engagementId: PropTypes.number,
    startDate: PropTypes.string,
    plannedCompletionDate: PropTypes.string
  }),
  getMilestoneIcons: PropTypes.func.isRequired,
  getMilestoneLines: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onClear: PropTypes.func.isRequired
};

export default withTranslation()(MilestoneGraphTemplate);
