import React from "react";

import { useTranslation } from "react-i18next";

import "./errorBox.scss";

const ErrorBox = props => {
  const { t } = useTranslation();
  return (
    <div
      className={`error-box ${
        props.type === "component" ? "component-error" : "page-error"
      }`}
    >
      <i className="material-icons error-box--icon">warning</i>
      <span className="error-box--text">{t(props.message)}</span>
      {props.customMessage}
    </div>
  );
};

export default ErrorBox;
