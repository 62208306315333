import React, { useCallback, useEffect, useMemo } from "react";

import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { routeConstants } from "@constants/routeConstants";

import { systemConstants } from "@shared/constants";
import { useAuthUser } from "@shared/hooks";

import ClickableMessage from "@components/atoms/ClickableMessage/ClickableMessage";
import Form from "@components/atoms/Form";

const ProjectUserForm = props => {
  const { projectMembers, users, entities, entitiesEnabled, editMode } = props;
  const { user: authUser } = useAuthUser();
  const { t } = useTranslation();
  const { watch, resetField } = useFormContext();
  const navigate = useNavigate();

  const watchFormValues = watch();

  useEffect(() => {
    if (watchFormValues.memberOf) {
      resetField("member");
      resetField("entities");
    }
  }, [resetField, watchFormValues.memberOf]);

  const memberOptions = useMemo(() => {
    const memberOptionsFilter = member => {
      if (authUser.role.name === systemConstants.roles.teamMember) {
        return member.role.name !== systemConstants.roles.engagementManager;
      }
      return true;
    };

    const hostOrClientUsers =
      watchFormValues.memberOf?.users?.filter(memberOptionsFilter) ?? [];

    return structuredClone(hostOrClientUsers);
  }, [authUser.role.name, watchFormValues.memberOf?.users]);

  const memberOfOptions = useMemo(
    () =>
      Object.values(users)
        .filter(o => o)
        .map(optionObject => ({
          isClient: optionObject.isClient ?? false,
          users: optionObject.users,
          name: optionObject.name
        })),
    [users]
  );

  const entitiesOptions = useMemo(
    () =>
      entities?.map(e => ({
        name: e.name,
        value: e.externalId
      })) ?? [],
    [entities]
  );

  const handleNoClientUserCallToAction = useCallback(() => {
    if (!users.client) {
      navigate("/not-found");
    }
    navigate(routeConstants.manageClientUsers, {
      state: {
        id: users.client.id,
        name: users.client.name
      }
    });
  }, [navigate, users.client]);

  const membersField = useMemo(() => {
    if (memberOptions?.length) {
      return (
        <Form.Dropdown
          name="member"
          label={t("common:ui.projectUserForm.fields.member.label")}
          items={memberOptions}
          required={true}
          disabled={editMode}
        />
      );
    }
    //check to ensure hostUsers isn't the field that is empty
    if (projectMembers.hostUsers?.length) {
      return (
        <ClickableMessage
          onClick={handleNoClientUserCallToAction}
          i18nKey={"common:ui.projectUserForm.noUser"}
        />
      );
    }
    return <></>;
  }, [
    editMode,
    handleNoClientUserCallToAction,
    memberOptions,
    projectMembers.hostUsers?.length,
    t
  ]);

  const showEntitiesField = useMemo(
    () =>
      entitiesEnabled && entitiesOptions?.length > 1 && memberOptions.length,
    [entitiesEnabled, entitiesOptions?.length, memberOptions.length]
  );

  return (
    <>
      {authUser.isHostUser && (
        <Form.Dropdown
          name="memberOf"
          label={t("common:ui.projectUserForm.fields.memberOf.label")}
          items={memberOfOptions}
          required={true}
          disabled={editMode}
        />
      )}
      {membersField}
      {showEntitiesField ? (
        <Form.ConditionalField
          fieldName="memberOf"
          valueComparer={data => data?.isClient}
        >
          <Form.Multiselect
            name="entities"
            label={t("stringFormat.capitalize", {
              key: "common:entities.displayName",
              count: 2
            })}
            items={entitiesOptions}
          />
        </Form.ConditionalField>
      ) : (
        <></>
      )}
    </>
  );
};

ProjectUserForm.defaultProps = {
  editMode: false,
  users: {},
  projectMembers: {}
};

ProjectUserForm.propTypes = {
  users: PropTypes.shape({
    host: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      users: PropTypes.array
    }),
    client: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      users: PropTypes.array
    })
  }),
  projectMembers: PropTypes.shape({
    hostUsers: PropTypes.array,
    clientUsers: PropTypes.array
  }),
  entities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      externalId: PropTypes.number
    })
  ),
  entitiesEnabled: PropTypes.bool,
  editMode: PropTypes.bool
};

export default ProjectUserForm;
