import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import {
  Bar,
  Cell,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Surface,
  Symbols,
  Text,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

import { chartConstants } from "@app/constants";
import {
  DataFormater,
  filterData,
  formatHoverText,
  getBarAndWaterfallChartFactors
} from "@app/helpers/chart";

import "./WaterfallChartTable.scss";

function CustomizedTick(params) {
  const {
    x,
    y,
    payload: { value },
    visibleTicksCount,
    dependencies: { isDrilldown, tickWidth, chartWidth, className, axisColors }
  } = params;
  const minWidth = 59;
  const getLabelWidth = () => {
    if (!isDrilldown) {
      return tickWidth - 8;
    }
    if (visibleTicksCount === 1) {
      return chartWidth / 1.5;
    }
    return chartWidth / visibleTicksCount / 1.2;
  };
  return (
    tickWidth > minWidth && (
      <foreignObject
        width={getLabelWidth()}
        x={x - 0.5 * getLabelWidth()}
        y={y}
        style={{ color: axisColors.stroke }}
        className={`bar-chart__tick ${className}`}
      >
        {value}
      </foreignObject>
    )
  );
}

const CustomTooltip = (props) => {
  const { active, payload, dataType } = props;
  if (!active || !payload?.length) {
    return null;
  }
  // the first and last columns only consist of one rect,
  // while other columns consists of one transparent rect and one visible rect.
  const renderPayloads = payload.slice(1);
  const {
    payload: { label }
  } = renderPayloads[0];
  return (
    <div className="custom-tooltip">
      <p className="label">{label}</p>
      {renderPayloads.map((payload, index) => {
        const value = formatHoverText(payload.value, dataType);
        return (
          <p key={index} className="label">{`${payload.name} : ${value}`}</p>
        );
      })}
    </div>
  );
};

const WaterfallChartTable = props => {
  const {
    data,
    colors,
    className,
    axis,
    displayZero,
    fixedHeight,
    isDrilldown,
    dataType
  } = props;
  const [tickWidth, setTickWidth] = useState(80);
  const [barSize, setBarSize] = useState(99);
  const [interval, setInterval] = useState(0);
  const [chartWidth, setChartWidth] = useState(0);
  const { chartColors, axisColors } = colors;
  const waterfallChartRef = useRef();
  const barNums = axis.values.length;
  const axisHeight = 40;
  const waterfallDataKey = data.keys[0];
  const lineDataKey = data.keys[1];

  useEffect(() => {
    if (!chartWidth) {
      return;
    }
    const { tickWidth, interval, barSize } = getBarAndWaterfallChartFactors({
      chartWidth,
      barNums,
      isDrilldown
    });
    setBarSize(barSize);
    setTickWidth(tickWidth);
    setInterval(interval);
  }, [barNums, chartWidth, interval, isDrilldown, tickWidth]);

  useEffect(() => {
    const resize = new ResizeObserver(entries => {
      const width = entries[0].contentRect.width;
      if (width) {
        setChartWidth(width);
      }
    });
    const waterFallChartElement = waterfallChartRef?.current;
    if (waterFallChartElement) {
      resize.observe(waterFallChartElement);
    }
    return () => {
      if (waterFallChartElement) {
        resize.unobserve(waterFallChartElement);
      }
    };
  }, []);

  const getBars = () => (
    <>
      <Bar
        key={`${waterfallDataKey}_base`}
        dataKey={`${waterfallDataKey}_base`}
        barSize={barSize}
        stackId={waterfallDataKey}
        fill="transparent"
      />
      <Bar
        key={waterfallDataKey}
        dataKey={waterfallDataKey}
        barSize={barSize}
        stackId={waterfallDataKey}
      >
        {data.values.map((value, index) => {
          let fillColor = null;
          if (index === 0 || index === data.values.length - 1) {
            fillColor = chartColors[2];
          } else if (value[waterfallDataKey] > 0) {
            fillColor = chartColors[0];
          } else {
            fillColor = chartColors[1];
          }
          return (
            <Cell
              fill={fillColor}
              dataKey={waterfallDataKey}
              key={`cell-${index}`}
            />
          );
        })}
      </Bar>
      {lineDataKey && (
        <Line
          dataKey={lineDataKey}
          isAnimationActive={false}
          stroke={chartColors[3]}
        />
      )}
    </>
  );

  const renderWaterfallLegend = () => {
    const legends = [
      {
        value: `Increase`,
        color: chartColors[0],
        type: "square"
      },
      {
        value: `Decrease`,
        color: chartColors[1],
        type: "square"
      },
      {
        value: `Opening and Total`,
        color: chartColors[2],
        type: "square"
      }
    ];
    lineDataKey &&
      legends.push({
        value: lineDataKey,
        color: chartColors[3],
        type: "square"
      });
    return (
      <div>
        {legends.map((legend, index) => (
          <span key={`${legend.value}-${index}`} className="waterfall-chart__legend">
            <Surface width={10} height={10} viewBox="0 0 10 10">
              <Symbols
                cx={5}
                cy={5}
                type={legend.type}
                size={50}
                fill={legend.color}
              />
            </Surface>
            <Text style={{ color: colors.legendTextColor }}>
              {legend.value}
            </Text>
          </span>
        ))}
      </div>
    );
  };

  return (
    <div ref={waterfallChartRef} className="waterfall-chart">
      <ResponsiveContainer
        width="99%"
        height={fixedHeight ?? chartConstants.defaultHeight}
        className={className}
      >
        <ComposedChart
          data={filterData(data.values, { displayZero })}
          margin={{
            top: 10,
            right: 20,
            left: 0,
            bottom: 20
          }}
        >
          <ReferenceLine
            y={0}
            stroke={axisColors.stroke}
            strokeDasharray="3 3"
          />
          <XAxis
            dataKey="label"
            stroke={axisColors.stroke}
            interval={interval}
            tick={
              <CustomizedTick
                dependencies={{
                  isDrilldown,
                  tickWidth,
                  chartWidth,
                  className,
                  axisColors
                }}
              />
            }
            height={axisHeight + 40}
            tickMargin={5}
          ></XAxis>

          <YAxis
            stroke={axisColors.stroke}
            tickFormatter={DataFormater}
            tickCount={7}
          />
          <Tooltip
            cursor={{ fill: axisColors.hoverOverlay }}
            content={<CustomTooltip dataType={dataType}/>}
          />
          <Legend
            verticalAlign="top"
            height={25}
            content={renderWaterfallLegend()}
          />
          {getBars()}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

WaterfallChartTable.defaultProps = {
  displayZero: true,
  dataType: "currency"
};

WaterfallChartTable.propTypes = {
  axis: PropTypes.shape({
    label: PropTypes.string,
    values: PropTypes.array
  }),
  colors: PropTypes.shape({
    chartColors: PropTypes.arrayOf(PropTypes.string),
    axisColors: PropTypes.shape({
      stroke: PropTypes.string,
      label: PropTypes.string
    })
  }),
  data: PropTypes.shape({
    keys: PropTypes.arrayOf(PropTypes.string),
    values: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired
        // and some dynamic properties according to the value in "keys"
        // [key]:  PropTypes.number,
        // [`${key}_base`]: PropTypes.number
      })
    )
  }),
  isDrilldown: PropTypes.bool,
  dataType: PropTypes.oneOf(["currency", "number", "percentage"]),
  className: PropTypes.string,
  fixedHeight: PropTypes.number,
  displayZero: PropTypes.bool
};

export default WaterfallChartTable;
