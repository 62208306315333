import React, { useMemo } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { dateAndTimeFormatter } from "@shared/helpers/dateHelper";
import { useGetHostObject, useLocaleDate } from "@shared/hooks";

import { useDataTable } from "@app/hooks/useDataTable";

import DataTable from "@components/molecules/DataTable";

const RolesTable = props => {
  const { t } = useTranslation();
  const { hostObject } = useGetHostObject();
  const { data } = props;
  const { createColumn, caseInsensitiveSortType } = useDataTable(data);
  const {
    locale,
    options: { shortFormat }
  } = useLocaleDate();

  const columns = useMemo(() => {
    const timeZone = hostObject?.host.properties.timezone;
    const formatDateTime = date =>
      dateAndTimeFormatter(date, locale, shortFormat, timeZone) ?? "";

    return [
      createColumn({
        Header: t("admin:ui.manageUserRoles.table.roleName.label"),
        accessor: "name",
        freeze: {
          position: "left",
          isEdge: false
        },
        disableSortBy: false,
        sortType: caseInsensitiveSortType
      }),
      createColumn({
        Header: t("admin:ui.manageUserRoles.table.type.label"),
        accessor: "type",
        disableSortBy: false,
        sortType: caseInsensitiveSortType
      }),
      createColumn({
        Header: t("admin:ui.manageUserRoles.table.createdBy.label"),
        accessor: "createdBy.name",
        disableSortBy: false,
        sortType: caseInsensitiveSortType
      }),
      createColumn({
        Header: t("admin:ui.manageUserRoles.table.lastUpdated.label"),
        accessor: "lastUpdated",
        Cell: ({ value }) => formatDateTime(value),
        disableSortBy: false
      })
    ];
  }, [
    caseInsensitiveSortType,
    createColumn,
    hostObject?.host.properties.timezone,
    locale,
    shortFormat,
    t
  ]);

  return <DataTable columns={columns} data={data} />;
};

RolesTable.defaultProps = {
  data: []
};

RolesTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      createdBy: PropTypes.object.isRequired,
      lastUpdated: PropTypes.string.isRequired
    })
  )
};

export default RolesTable;
