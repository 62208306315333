import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";

import websheetUtilities from "@shared/helpers/websheetUtilities";

import WizardWebsheet from "../../WizardWebsheet";

const ValidationWizard = React.forwardRef((props, fwdRef) => {
  const {
    data: { websheet: data, savedState },
    onSheetLoading
  } = props;
  const hotTableComponent = useRef(null);
  const [currentSheetName, setCurrentSheetName] = useState();
  const [localData, setLocalData] = useState(null);

  useEffect(() => {
    const newLocalData = structuredClone(data);
    setLocalData(newLocalData);
  }, [data]);

  const setHotTableComponent = useCallback(node => {
    hotTableComponent.current = node;
  }, []);

  const afterGetColHeader = (_, TH) => {
    TH.className += "wizard-websheet__col-header";
  };

  const afterGetRowHeader = (row, TH) => {
    TH.className += "wizard-websheet__row-header";
  };

  const getDataForSubmission = useCallback(() => {
    if (!hotTableComponent?.current?.hotInstance) {
      return {};
    }
    const hotData = hotTableComponent.current.hotInstance.getData();
    const colHeaders = hotTableComponent.current.hotInstance.getColHeader();

    // NB: current assumption is that there is only one sheet so this logic is fine
    // Inserting col header to first row of data
    const updatedData = data.map(sheetData => ({
      ...sheetData,
      data: [colHeaders].concat(websheetUtilities.unPopulateRows(hotData))
    }));

    setLocalData(updatedData);
    return {
      websheet: updatedData,
      savedState
    };
  }, [data, savedState]);

  useImperativeHandle(
    fwdRef,
    () => ({
      getDataForSubmission,
      canSubmit: () => true
    }),
    [getDataForSubmission]
  );

  return (
    <WizardWebsheet
      currentSheetName={localData?.[0]?.sheet}
      data={localData}
      firstRowHeader={true}
      editable={true}
      hotTableComponent={setHotTableComponent}
      onCurrentSheetNameChanged={setCurrentSheetName}
      afterGetRowHeader={afterGetRowHeader}
      rowHeaders={true}
      afterGetColHeader={afterGetColHeader}
      onSheetLoading={onSheetLoading}
    />
  );
});

ValidationWizard.displayName = "ValidationWizard";

export default ValidationWizard;
