import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { routeConstants } from "@constants/routeConstants";

import Popup from "@shared/components/popup/Popup";
import { systemConstants } from "@shared/constants/systemConstants";
import { useCurrentProject, useGetProjectMembers } from "@shared/hooks";
import { useGetProjectByIdQuery } from "@shared/services";
import { useGetFeaturesQuery } from "@shared/services/featuresService";
import { useGetProjectRisksQuery } from "@shared/services/riskService";

import AddOrUpdateRisk from "@app/components/organisms/addOrUpdateRisk/AddOrUpdateRisk";
import RiskCard from "@app/components/organisms/riskCard/RiskCard";

import Button from "@components/atoms/Button/BrandButton";
import MainPageTemplate from "@components/templates/MainPageTemplate";
import PageBodyTemplate from "@components/templates/PageBodyTemplate";

import "./ProjectRisks.scss";

const ProjectRisks = () => {
  const { t } = useTranslation();
  const { currentProject: project, onChangeCurrentProject } =
    useCurrentProject();
  const { projectId } = useParams();
  const { data: risks } = useGetProjectRisksQuery(
    { projectId },
    {
      skip: !projectId || project?.id === projectId
    }
  );
  const { members } = useGetProjectMembers(project);
  const navigate = useNavigate();
  const [addRiskVisibility, setAddRiskVisibility] = useState(false);
  const { data: features } = useGetFeaturesQuery();
  const { data: projectToSelect, error: isInvalidProject } =
    useGetProjectByIdQuery(
      { projectId },
      { skip: !projectId || project?.id === projectId }
    );

  useEffect(() => {
    if (projectToSelect && project?.id !== projectToSelect.id) {
      onChangeCurrentProject(projectToSelect);
    }
  }, [projectToSelect, project, onChangeCurrentProject]);

  // this is to check the risk feature is enabled or if the project is invalid
  useEffect(() => {
    if (
      isInvalidProject ||
      (features && !features[systemConstants.features.projectRisks])
    ) {
      navigate(routeConstants.notFound, { replace: true });
    }
  }, [features, navigate, isInvalidProject]);

  const handleAddRisk = () => {
    setAddRiskVisibility(true);
  };

  const handlePopupCancelClick = () => {
    setAddRiskVisibility(false);
  };

  const handlePopupAddClick = () => {
    setAddRiskVisibility(false);
  };

  const headingActions = () => (
    <Button
      type={"primary"}
      iconName="add"
      label={t("risks:ui.actions.createRisk.label")}
      onClick={handleAddRisk}
    />
  );

  return (
    <MainPageTemplate project={project}>
      <PageBodyTemplate
        title={t("risks:risks.ui.navigation.title")}
        actions={headingActions()}
      >
        <div className="project-risk__main">
          {risks?.length ? (
            <div className="project-risk__container">
              {risks?.map(risk => (
                <RiskCard key={risk.id} data={risk} project={project} />
              ))}
            </div>
          ) : (
            <div className="project-risk__no-risks">
              {t("risks:risks.ui.projectRisks.noRiskPresent.message")}
            </div>
          )}
        </div>

        <Popup
          visibility={addRiskVisibility}
          handleOutsideClick={handlePopupAddClick}
          width="55rem"
        >
          <AddOrUpdateRisk
            project={project}
            members={members}
            onAdd={handlePopupAddClick}
            onCancel={handlePopupCancelClick}
          />
        </Popup>
      </PageBodyTemplate>
    </MainPageTemplate>
  );
};

export default ProjectRisks;
