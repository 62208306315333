import React from "react";

import { Oval } from "react-loader-spinner";

import variables from "@shared/scss/exportables.module.scss";

const LoaderSpinner = props => {
  return (
    <div className="loader-container">
      <Oval
        color={variables.colorPrimary}
        secondaryColor={variables.backgroundOverlayColor}
        height={props.diameter}
        width={props.diameter}
      />
    </div>
  );
};

export default LoaderSpinner;
