import React, { useCallback, useEffect, useState } from "react";

import PropTypes from "prop-types";

import { handleFreezeScrollPosition } from "@app/helpers/componentHelpers";

import Form from "@components/atoms/Form";
import ModalContentTemplate from "@components/templates/ModalContentTemplate";
import ModalTemplate from "@components/templates/ModalTemplate";
import SlidingModalTemplate from "@components/templates/SlidingModalTemplate";

import "./ModalForm.scss";

const ModalForm = props => {
  const [footerRef, setFooterRef] = useState(null);
  const measuredFooterRef = useCallback(node => {
    setFooterRef(node);
  }, []);
  const {
    boxClassName,
    title,
    slidingForm,
    modalContentForm,
    modalContentProps,
    actionButtonsPortal: ignore,
    errorMessage,
    isLoading,
    ...formProps
  } = props;

  useEffect(() => {
    handleFreezeScrollPosition(true);
    return () => handleFreezeScrollPosition(false);
  }, []);

  const handleClose = () => {
    handleFreezeScrollPosition(false);
    props.handleCancel?.();
  };

  const renderBody = () => {
    if (slidingForm) {
      return (
        <SlidingModalTemplate
          title={props.title}
          show={props.show}
          onClose={handleClose}
          isLoading={isLoading}
          content={<div className="ot-form__content">{props.children}</div>}
          footer={<div ref={measuredFooterRef}></div>}
        />
      );
    } else if (modalContentForm) {
      return (
        <ModalContentTemplate {...modalContentProps} onClose={handleClose} />
      );
    } else {
      return (
        <ModalTemplate
          boxClassName={props.boxClassName}
          errorMessage={errorMessage}
          title={props.title}
          onClose={handleClose}
          isLoading={isLoading}
          content={
            <div className="ot-form__content" id="modal-form">
              {props.children}
            </div>
          }
          footer={
            <div className="modal-form__footer" ref={measuredFooterRef}></div>
          }
        />
      );
    }
  };

  return (
    <>
      <Form {...formProps} actionButtonsPortal={footerRef}>
        {renderBody()}
      </Form>
    </>
  );
};

ModalForm.defaultProps = {};

ModalForm.propTypes = {
  ...Form.propTypes,
  handleSubmit: PropTypes.func,
  boxClassName: PropTypes.string,
  show: PropTypes.bool,
  slidingForm: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node
};

export default ModalForm;
