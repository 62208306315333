import React from "react";

import { useTranslation } from "react-i18next";

import { useGetEngagementTypesQuery } from "@shared/services";
import { useGetFeaturesQuery } from "@shared/services/featuresService";

import FormPageTemplate from "@components/templates/FormPageTemplate";

import AddClientProjectForm from "./AddClientProjectForm/AddClientProjectForm";

const AddProject = () => {
  const { t } = useTranslation();
  const { isLoading: loadingEngagementTypes } = useGetEngagementTypesQuery();
  const { isLoading: loadingFeatures } = useGetFeaturesQuery();

  return (
    <FormPageTemplate
      title={t("common:ui.projects.title_add")}
      formElement={<AddClientProjectForm />}
      isLoading={loadingFeatures || loadingEngagementTypes}
    />
  );
};

AddProject.propTypes = {};

export default AddProject;
