import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

import CheckBox from "@components/atoms/CheckBox/CheckBox";

import "./CheckListItem.scss";

const CheckListItem = props => {
  const { control, getValues, setValue } = useFormContext();

  useEffect(() => {
    const currentValue = getValues(props.name);
    if (currentValue === undefined && props.defaultValue) {
      setValue(props.name, props.defaultValue);
    }
  }, [getValues, props.defaultValue, props.name, setValue]);

  return (
    <Controller
      control={control}
      name={props.name}
      shouldUnregister={props.shouldUnregister}
      render={({ field: { onChange } }) => {
        return (
          <CheckBox
            disabled={props.disabled}
            value={getValues(props.name)}
            onChange={onChange}
            label={props.label}
            labelPosition={props.labelPosition}
            styleVariant="primary"
            textWeight="normal"
            className="check-list--item"
          />
        );
      }}
    />
  );
};

CheckListItem.defaultProps = {
  shouldUnregister: false
};

CheckListItem.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.bool,
  disabled: PropTypes.bool,
  shouldUnregister: PropTypes.bool,
  labelPosition: PropTypes.oneOf(["left", "right"])
};

export default CheckListItem;
