import { useCallback, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageHostUsersActions } from "@shared/actions";
import { useUpdateHostUserMutation } from "@shared/services";

const status = {
  active: "ACTIVE",
  inactive: "INACTIVE"
};

export function useUpdateHostUserStatus() {
  const dispatch = useDispatch();
  const authentication = useSelector(state => state.authentication);
  const [updateHostUser, { isSuccess: updatedUser }] =
    useUpdateHostUserMutation();

  useEffect(() => {
    if (updatedUser) {
      dispatch(manageHostUsersActions.clearUpdateHostUserFlag());
      dispatch(manageHostUsersActions.getHostUsers(authentication.user));
    }
  }, [dispatch, authentication.user, updatedUser]);

  const deactivateHostUser = useCallback(
    ({ id: hostUserId }) => {
      const userUpdate = {
        id: hostUserId,
        status: status.inactive,
        hostId: authentication.user.hostId
      };
      updateHostUser({ user: userUpdate, userProfile: userUpdate });
    },
    [authentication.user.hostId, updateHostUser]
  );

  const activateHostUser = useCallback(
    ({ id: hostUserId }) => {
      const userUpdate = {
        id: hostUserId,
        status: status.active,
        hostId: authentication.user.hostId
      };
      updateHostUser({ user: userUpdate, userProfile: userUpdate });
    },
    [authentication.user.hostId, updateHostUser]
  );

  return {
    activateHostUser,
    deactivateHostUser
  };
}
