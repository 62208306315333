import { manageAuditConstants } from "../constants";

const initialState = {
  loading: false,
  records: [],
  error: ""
};

export function manageAudit(state = initialState, action = {}) {
  switch (action.type) {
    case manageAuditConstants.GET_AUDIT_RECORDS_BY_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageAuditConstants.GET_AUDIT_RECORDS_BY_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.records,
        error: ""
      };
    case manageAuditConstants.GET_AUDIT_RECORDS_BY_USER_FAILURE:
      return {
        ...state,
        loading: false,
        records: [],
        error: action.error.key ?? action.error.message
      };
    case manageAuditConstants.SET_ERROR:
      return {
        ...state,
        error: action.error
      };
    case manageAuditConstants.RESET_AUDIT_RECORDS:
      return {
        ...state,
        records: []
      };
    case manageAuditConstants.RESET:
      return initialState;
    default:
      return state;
  }
}
