import React, { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { routeConstants } from "@constants";

import { systemConstants } from "@shared/constants";
import { useFeatures } from "@shared/hooks";
import { useGetProjectByIdQuery } from "@shared/services/projectService";

import Loading from "@components/molecules/Loading";
import CopyProjectForm from "@components/organisms/CopyProjectForm";
import MainPageTemplate from "@components/templates/MainPageTemplate";
import PageBodyTemplate from "@components/templates/PageBodyTemplate";

const CopyClientEngagementProject = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { projectId } = useParams();
  const {
    data: currentProject,
    isLoading,
    isFetching: isFetchingProject,
    error: errorFetchingProject
  } = useGetProjectByIdQuery(
    { projectId },
    { skip: !projectId, refetchOnMountOrArgChange: true }
  );

  const { isEnabled: isFeatureEnabled, isLoading: isFeaturesLoading } =
    useFeatures();

  useEffect(() => {
    if (!projectId) {
      navigate(routeConstants.login);
    }
  }, [navigate, projectId]);

  useEffect(() => {
    if (errorFetchingProject) {
      navigate(routeConstants.notFound, { replace: true });
    }
  }, [errorFetchingProject, navigate]);

  const breadcrumbs = useMemo(() => {
    if (
      isFeaturesLoading ||
      !isFeatureEnabled(systemConstants.features.engagements)
    ) {
      return [];
    }
    return [
      {
        linkTo: routeConstants.manageClientEngagements,
        label: t("common:ui.engagement.navigation.title")
      },
      {
        linkTo: {
          pathname: `${routeConstants.engagements}/${currentProject?.engagement.id}`
        },
        label: currentProject?.engagement?.name
      }
    ];
  }, [currentProject?.engagement, isFeatureEnabled, isFeaturesLoading, t]);

  return (
    <MainPageTemplate project={currentProject}>
      <PageBodyTemplate
        breadcrumbs={breadcrumbs}
        title={t("common:ui.project.title_copy")}
      >
        {isLoading || isFetchingProject || errorFetchingProject ? (
          <Loading />
        ) : (
          <CopyProjectForm
            project={currentProject}
            engagement={{
              ...currentProject?.engagement,
              engagementType: {
                id: currentProject?.engagement?.engagementTypeId,
                configuration: currentProject?.configuration
              }
            }}
          />
        )}
      </PageBodyTemplate>
    </MainPageTemplate>
  );
};

export default CopyClientEngagementProject;
