import { systemConstants } from "@shared/constants";

// convenience types so its more obvious what the value is
type ThemeCode = number;
type integer = number;

/**
 * Converts argb hex to rgba
 * @param hex 4 of 8 digit hex value
 * @returns rgba string
 */
export const argbToRgb = (hex: string): string => {
  const [a, r, g, b] = (() => {
    if (hex.length === 4) {
      return [
        "0x" + hex[0] + hex[0],
        "0x" + hex[1] + hex[1],
        "0x" + hex[2] + hex[2],
        "0x" + hex[3] + hex[3]
      ];
    }

    if (hex.length === 8) {
      return [
        "0x" + hex[0] + hex[1],
        "0x" + hex[2] + hex[3],
        "0x" + hex[4] + hex[5],
        "0x" + hex[6] + hex[7]
      ];
    }

    return [1, 0, 0, 0];
  })();

  const alpha = +(+a / 255).toFixed(3);
  return `rgba(${+r},${+g},${+b},${+alpha})`;
};

const themeColors: { [key: ThemeCode]: [integer, integer, integer] } = {
  0: [255, 255, 255],
  1: [0, 0, 0],
  2: [231, 230, 230],
  3: [68, 84, 106],
  4: [68, 114, 196],
  5: [237, 125, 49],
  6: [165, 165, 165],
  7: [255, 192, 0],
  8: [91, 155, 213],
  9: [112, 173, 71],
  10: [0, 102, 204]
};
/**
 * Converts excel theme and tint to rgb string
 * @param theme 0-10
 * @param tint -1.0 to 1.0
 * @returns rgb string
 */
export const themeTintToRgb = (theme: ThemeCode, tint?: number): string => {
  const rgb = themeColors[theme];

  if (tint === undefined) {
    const [r, g, b] = rgb;
    return `rgba(${r},${g},${b})`;
  }

  if (Math.sign(tint) === 1) {
    const tintValue = (value: number, tintAmount: number) =>
      Math.round(value + (255 - value) * tintAmount);

    const [r, g, b] = rgb.map(v => tintValue(v, tint));
    return `rgba(${r},${g},${b})`;
  }

  if (Math.sign(tint) === -1) {
    const tintValue = (value: number, tintAmount: number) =>
      Math.round(value * (1 - tintAmount));

    const absTint = Math.abs(tint);
    const [r, g, b] = rgb.map(v => tintValue(v, absTint));
    return `rgba(${r},${g},${b})`;
  }

  const [r, g, b] = rgb;
  return `rgba(${r},${g},${b})`;
};

interface Color {
  argb?: string;
  theme?: ThemeCode;
  tint?: number;
}
/**
 * Convenience helper to convert color to rgb string
 * @param color color object
 * @returns rgb string or undefined if color is undefined
 */
const colorToRgb = (color: Color | undefined): string | undefined => {
  if (!color) {
    return undefined;
  }

  if (color.argb) {
    return argbToRgb(color.argb);
  }

  if (color.theme != undefined) {
    return themeTintToRgb(color.theme, color.tint);
  }

  return undefined;
};

type BorderSide = "bottom" | "top" | "right" | "left";
type BorderAlignmentColor =
  | "borderBottomColor"
  | "borderTopColor"
  | "borderRightColor"
  | "borderLeftColor";
const getBorderAlignmentColor = (
  border: BorderSide
): BorderAlignmentColor | undefined => {
  if (border === "bottom") {
    return "borderBottomColor";
  }
  if (border === "top") {
    return "borderTopColor";
  }
  if (border === "right") {
    return "borderRightColor";
  }
  if (border === "left") {
    return "borderLeftColor";
  }
};

interface BorderStyle {
  color?: Color;
}
type Border = {
  [key in BorderSide]?: BorderStyle;
};
export const formatBorder = (border: Border, TD: any) => {
  Object.keys(border).forEach(b => {
    const side: BorderSide = b as BorderSide;
    const alignment = getBorderAlignmentColor(side);
    if (!alignment) {
      return;
    }

    const borderStyle = border[side] as BorderStyle;
    if (!borderStyle.color) {
      return;
    }

    const rgbString = colorToRgb(borderStyle.color);
    if (rgbString) {
      TD.style[alignment] = rgbString;
    }
  });
};

interface FontStyle {
  color?: Color;
  bold?: boolean;
  italic?: boolean;
  size?: integer;
  name?: string;
  underline?: boolean;
}
const PT_TO_PIXEL =
  systemConstants.project.document.editExcelDocumentPage.ptToPixel;
export const formatFont = (font: FontStyle, TD: any) => {
  const rgbString = colorToRgb(font.color);
  if (rgbString) {
    TD.style.color = rgbString;
  }

  TD.style.fontWeight = font.bold ? "bold" : "";
  TD.style.fontStyle = font.italic ? "italic" : "normal";

  if (font.size) {
    TD.style.fontSize = `${font.size / PT_TO_PIXEL}px`;
  }

  if (font.name) {
    TD.style.fontFamily = font.name;
  }

  TD.style.textDecoration = font.underline ? "underline" : "";
};

interface FillStyle {
  bgColor?: Color;
  fgColor?: Color;
}
export const formatFill = (fill: FillStyle, TD: any) => {
  const fillColor = fill.fgColor ?? fill.bgColor;
  const rgbString = colorToRgb(fillColor);
  if (rgbString) {
    TD.style.backgroundColor = rgbString;
  }
};
