import React, { useCallback, useEffect, useState } from "react";

import { withTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants";
import { utilities } from "@shared/helpers";
import { useAuthUser, useUpdateMilestones } from "@shared/hooks";


import MilestoneIcon from "@components/molecules/MilestoneIcon";
import MilestoneGraphTemplate from "@components/templates/MilestoneGraphTemplate";

const MilestoneGraph = props => {
  const {
    updateMilestone,
    project,
    milestones,
    i18n,
    handleClickUpdateMilestone,
    isReloadMilestones
  } = props;
  const { user } = useAuthUser();
  const [selectedMilestone, setSelectedMilestone] = useState();
  const showUpdateButton = user.isHostAdmin || user.isHostUser ? true : false;
  const { isUpdatingProjectMilestones } = useUpdateMilestones();
  const [isShowAllMilestones, setIsShowAllMilestones] = useState(false);
  const [filteredMilestones, setFilteredMilestones] = useState(milestones);

  useEffect(() => {
    if (!isUpdatingProjectMilestones && isReloadMilestones?.current) {
      updateMilestone();
      isReloadMilestones.current = false;
    }
  }, [isReloadMilestones, isUpdatingProjectMilestones, updateMilestone]);

  useEffect(() => {
    setFilteredMilestones(utilities.filterExcessiveMilestones(milestones));
  }, [milestones]);

  const getMilestoneWidth = useCallback(
    width => {
      if (filteredMilestones.length <= 2) {
        setIsShowAllMilestones(true);
      } else {
        setIsShowAllMilestones(width / filteredMilestones.length >= 210);
      }
    },
    [filteredMilestones]
  );

  const getTimelineLocation = index => {
    const ratio = 100 / (filteredMilestones.length - 1);
    if (index == 0) {
      return 1;
    } else {
      return Math.round(index * ratio);
    }
  };

  const clickMilestone = milestone => {
    if (milestone.projectCompletion) return;
    setSelectedMilestone(milestone);
  };
  const clearSelectedMilestone = () => {
    setSelectedMilestone();
  };

  const getCellStyle = index => {
    const timelineLocation = getTimelineLocation(index);
    return {
      gridColumnStart: timelineLocation,
      gridColumnEnd: timelineLocation + 1,
      gridRowStart: 1,
      gridRowEnd: 4
    };
  };

  const getIconType = milestone => {
    if (
      milestone.status == systemConstants.project.milestone.status.completed
    ) {
      return "completed";
    } else if (
      milestone.status == systemConstants.project.milestone.status.inProgress
    ) {
      return "in-progress";
    } else {
      return "non-complete";
    }
  };

  const renderMilestoneIcons = (milestone, index) => {
    const showDate =
      milestone.status == systemConstants.project.milestone.status.inProgress ||
      isShowAllMilestones;
    const showLabel =
      isShowAllMilestones ||
      milestone.status == systemConstants.project.milestone.status.inProgress;
    const style = getCellStyle(index);
    const iconType = getIconType(milestone);
    const totalNumMilestones = filteredMilestones.length;
    return (
      <MilestoneIcon
        key={`mi-${milestone.id || milestone.name}`}
        type={iconType}
        isShowDate={showDate}
        isShowLabel={showLabel}
        style={style}
        onClickMilestone={clickMilestone}
        milestoneName={utilities.getProjectMilestoneName(milestone, i18n)}
        milestone={milestone}
        milestoneIndex={index}
        totalNumMilestones={totalNumMilestones}
      />
    );
  };

  const renderMilestoneLines = (milestone, index) => {
    const timelineLocation = getTimelineLocation(index);
    const nextTimelineLocation = getTimelineLocation(index + 1);
    const style = {
      gridColumnStart: timelineLocation,
      gridColumnEnd: nextTimelineLocation
    };
    const iconType = getIconType(milestone);
    return (
      <div
        key={milestone.id || milestone.name}
        className={`project-milestone__goal-item-line project-milestone__goal-item-line-${iconType}`}
        style={style}
      ></div>
    );
  };

  return (
    <MilestoneGraphTemplate
      project={project}
      showUpdateButton={showUpdateButton}
      milestones={filteredMilestones}
      getMilestoneWidth={getMilestoneWidth}
      getMilestoneIcons={renderMilestoneIcons}
      getMilestoneLines={renderMilestoneLines}
      selectedMilestone={selectedMilestone}
      onClick={handleClickUpdateMilestone}
      onClear={clearSelectedMilestone}
    />
  );
};

export default withTranslation()(MilestoneGraph);
