import React, { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import Popup from "@shared/components/popup/Popup";

import Button from "@components/atoms/Button";
import AddInsightsBoard from "@components/organisms/AddInsightsBoard/AddInsightsBoard";
import InsightsBoardTable from "@components/organisms/InsightsBoardTable";

import PageTemplate from "../PageTemplate/PageTemplate";

const ManageInsightsBoardTemplate = props => {
  const { insightsBoards, error } = props;
  const { t } = useTranslation();
  const [showCreateInsightBoardModal, setShowCreateInsightBoardModal] =
    useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState("");

  const insightsBoardTable = useMemo(() => {
    return <InsightsBoardTable insightsBoards={insightsBoards} />;
  }, [insightsBoards]);

  const pageActions = useMemo(
    () => (
      <Button
        label={t("common:insights.board.create.label")}
        type="primary"
        onClick={() => setShowCreateInsightBoardModal(true)}
      />
    ),
    [t]
  );

  const handleSubmit = async insightsBoard => {
    const error = await props.handleSubmit(insightsBoard);
    if (!error) {
      handleClose();
    } else {
      setFormErrorMessage(error);
    }
  };

  const handleClose = () => {
    setShowCreateInsightBoardModal(false);
    setFormErrorMessage("");
  };

  return (
    <>
      <PageTemplate
        header={{
          title: t("common:insights.board.page.title_other"),
          actions: pageActions
        }}
        body={{
          primary: insightsBoardTable
        }}
        other={{
          error
        }}
      />
      <Popup visibility={showCreateInsightBoardModal} width="60rem">
        <AddInsightsBoard
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
          errorMessage={formErrorMessage}
        />
      </Popup>
    </>
  );
};

export default ManageInsightsBoardTemplate;
