import React, { useEffect, useState } from "react";

import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { manageClientsActions } from "@shared/actions";
import { systemConstants } from "@shared/constants";
import { utilities } from "@shared/helpers";

import { routeConstants } from "@app/constants";

import Button from "@components/atoms/Button/BrandButton";
import InlineAlert from "@components/atoms/InlineAlert";
import DropdownPagination from "@components/molecules/DropdownPagination";
import ClientsTable from "@components/organisms/ClientDataTable";
import SearchTextInput from "@components/organisms/SearchTextInput";
import MainPageTemplate from "@components/templates/MainPageTemplate";
import PageBodyTemplate from "@components/templates/PageBodyTemplate";

import "./ManageClients.scss";

const ManageClients = ({ t }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authentication = useSelector(state => state.authentication);
  const manageClients = useSelector(state => state.manageClients);
  const [clients, setClients] = useState([]);
  const [pagination, setPagination] = useState({
    countPerPage: systemConstants.pagination.itemCountPerPage,
    pageCount: 1,
    currentPage: "Page 1",
    currentPageIndex: 0,
    pages: []
  });
  const { isKeywordInclude } = utilities;
  const [filter, setFilter] = useState({ string: "", clients: [] });

  useEffect(() => {
    dispatch(manageClientsActions.getClients(authentication.user));
    return () => {
      dispatch(manageClientsActions.resetClients());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFilter({ ...filter, clients: manageClients.clients });
    // eslint-disable-next-line
  }, [manageClients.clients]);

  useEffect(() => {
    if (filter.string) {
      createPagination(filter.clients);
    } else {
      createPagination(manageClients.clients);
    }
    // eslint-disable-next-line
  }, [filter.clients]);

  useEffect(() => {
    if (!filter.string) {
      createPagination(manageClients.clients);
    }
    // eslint-disable-next-line
  }, [filter.string]);

  useEffect(() => {
    if (pagination.pages.length) {
      setClients(pagination.pages[pagination.currentPageIndex].data);
    } else {
      setClients([]);
    }
  }, [pagination]);

  const createPagination = allClients => {
    const count = Math.ceil(allClients.length / pagination.countPerPage);
    const items = [];
    for (let number = 0; number < count; number++) {
      const data = allClients.slice(
        number * pagination.countPerPage,
        number * pagination.countPerPage + pagination.countPerPage
      );
      items.push({ number: number + 1, name: `Page ${number + 1}`, data });
    }

    setPagination({
      ...pagination,
      currentPage: "Page 1",
      currentPageIndex: 0,
      pageCount: count,
      pages: items
    });
  };

  const handlePageSelection = pageName => {
    const index = pagination.pages.findIndex(page => page.name === pageName);
    setPagination({
      ...pagination,
      currentPage: pageName,
      currentPageIndex: index
    });
  };

  const handleFilterChange = searchInput => {
    if (!searchInput) {
      setFilter({
        string: "",
        clients: manageClients.clients
      });
    } else {
      const filteredClients = manageClients.clients.filter(
        client =>
          isKeywordInclude(client.name, searchInput) ||
          (client.code && isKeywordInclude(client.code, searchInput))
      );
      setFilter({
        string: searchInput,
        clients: filteredClients
      });
    }
  };

  const handleAddClient = () => {
    navigate(routeConstants.addClient);
  };

  const handleManageEntities = client => {
    navigate(routeConstants.admin.manageClientEntities, {
      state: client
    });
  };
  const handleUpdateClient = client => {
    navigate(`/admin/clients/${client.id}/edit`);
  };
  const handleManageClientTags = client => {
    navigate(routeConstants.admin.manageClientTags, {
      state: client
    });
  };
  const handleManageClientUsers = client =>
    navigate(`/admin/clients/${client.id}/users`);

  const handlePageCountIncrement = () => {
    const index = pagination.currentPageIndex;
    if (index < pagination.pages.length - 1) {
      setPagination({
        ...pagination,
        currentPage: `Page ${index + 2}`,
        currentPageIndex: index + 1
      });
    }
  };

  const handlePageCountDecrement = () => {
    const index = pagination.currentPageIndex;

    if (index > 0) {
      setPagination({
        ...pagination,
        currentPage: `Page ${index}`,
        currentPageIndex: index - 1
      });
    }
  };

  const headerActions = () => {
    return (
      authentication.user.role.name ===
        systemConstants.roles.engagementManager && (
        <Button
          type="primary"
          label={t("common:ui.manageClients.addClient")}
          iconName="add"
          onClick={handleAddClient}
        />
      )
    );
  };

  return (
    <MainPageTemplate>
      <PageBodyTemplate
        title={t("common:ui.manageClients.title")}
        actions={headerActions()}
      >
        <div className="client-manager">
          {manageClients.error && (
            <div className="client-manager__error-box">
              <InlineAlert type="error" message={t(manageClients.error)} />
            </div>
          )}
          <div className="client-manager__search">
            <SearchTextInput
              label={t("common:ui.manageClients.searchLabel")}
              handleChange={handleFilterChange}
            />
          </div>

          {clients.length ? (
            <>
              <div className="client-manager__table">
                <ClientsTable
                  clients={clients}
                  userRole={authentication.user.role.name}
                  onHandleManageEntities={handleManageEntities}
                  onHandleUpdateClient={handleUpdateClient}
                  onHandleManageClientTags={handleManageClientTags}
                  onHandleManageClientUsers={handleManageClientUsers}
                />
              </div>
              <DropdownPagination
                handlePageSelection={handlePageSelection}
                handlePageCountIncrement={handlePageCountIncrement}
                handlePageCountDecrement={handlePageCountDecrement}
                pages={pagination.pages.map(page => ({ name: page.name }))}
                currentPage={pagination.currentPage}
              />{" "}
            </>
          ) : (
            ""
          )}
        </div>
      </PageBodyTemplate>
    </MainPageTemplate>
  );
};

export default withTranslation()(ManageClients);
