import React, { useCallback } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Button from "@components/atoms/Button";

const AddProjectActionButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate("/add-project");
  }, [navigate]);

  return (
    <Button
      label={t("common:ui.project.title_add")}
      iconSide="left"
      iconName="add"
      iconSize="medium"
      type="primary"
      onClick={handleClick}
    />
  );
};

export default AddProjectActionButton;
