import React, { useCallback, useEffect, useMemo, useState } from "react";

import { isEmpty, set } from "lodash";
import { Trans, useTranslation } from "react-i18next";
import * as yup from "yup";

import { systemConstants } from "@shared/constants";
import { useAddQueryResponse, useSupportedDocumentMimes } from "@shared/hooks";
import { useDocumentCheck } from "@shared/hooks/useDocumentCheck";

import {
  convertEntitiesToIds,
  populateRelevantEntitiesByIdToArray
} from "@app/helpers/entity";

import Form from "@components/atoms/Form";
import InlineAlert from "@components/atoms/InlineAlert";
import ModalForm from "@components/molecules/ModalForm";

import "./AddQueryResponse.scss";

const addFilesState = systemConstants.addFiles.state;
const queryTypes = systemConstants.project.queries.queryTypes;
const responseTypes = systemConstants.project.queries.responses.responseTypes;

const AddQueryResponse = props => {
  const { onSuccess, query, project } = props;
  const { t } = useTranslation();
  const { entities: projectEntities, id: projectId } = project;
  const queryEntities = populateRelevantEntitiesByIdToArray(project, query);
  const { supportedDocumentMimes } = useSupportedDocumentMimes();
  const { check, isCheckLoading, fetchCheck, isActionTypeSmartForm } =
    useDocumentCheck(projectId);
  const { isAdded, queryResponseError, resetQuery, addResponse, clearError } =
    useAddQueryResponse();
  const [errorMsg, setErrorMsg] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState(null);
  const [uploadState, setUploadState] = useState(addFilesState.add);
  const [showEntitiesMultiSelect, setShowEntitiesMultiSelect] = useState(false);
  const [selectedEntities, setSelectedEntities] = useState(queryEntities);
  const [uploadData, setUploadData] = useState({});
  useEffect(() => {
    if (!queryResponseError && isAdded) {
      onSuccess();
      return () => {
        resetQuery();
        setIsSaving(false);
      };
    }
  }, [isAdded, onSuccess, queryResponseError, resetQuery]);

  useEffect(() => {
    if (isSaving && queryResponseError) {
      setIsSaving(false);
      setErrorMsg(queryResponseError);
    }
  }, [isSaving, queryResponseError]);

  const addQueryResponse = useCallback(
    data => {
      const responseData = {
        ...data,
        queryId: props.query.id
      };
      if (
        props.responseType === responseTypes.approve ||
        props.responseType === responseTypes.reject
      )
        responseData.responseType = props.responseType;

      addResponse(responseData);
    },
    [addResponse, props.query.id, props.responseType]
  );

  const handleSelectEntities = e => {
    setSelectedEntities(
      e?.map(({ name, externalId }) => ({
        name,
        externalId
      }))
    );
  };

  const handleFilesChange = files => {
    setShowEntitiesMultiSelect(
      !isEmpty(files) &&
        project.configuration.entities?.enabled &&
        projectEntities?.length > 1
    );
  };

  const canAttachFiles = useMemo(
    () =>
      props.responseType === responseTypes.message &&
      [systemConstants.actionItemTypes.conversation].includes(
        props.queryConfig.type
      ),
    [props.queryConfig.type, props.responseType]
  );

  const uploadFiles = () => {
    setUploadState(addFilesState.upload);
  };

  const revisions = useMemo(() => {
    return check?.filter(({ present }) => present);
  }, [check]);

  const sendResponse = useCallback(
    files => {
      if (isSaving) return;
      setIsSaving(true);
      resetQuery();
      clearError();
      const formattedFiles = files?.map(file => {
        const revision = revisions.find(({ name }) => name === file.name);
        if (!revision) {
          set(
            file,
            ["properties", "entities"],
            convertEntitiesToIds(selectedEntities)
          );
        } else {
          file.properties = revision.properties;
        }
        return file;
      });
      const submitReqData = {
        ...formData,
        files: formattedFiles || [],
        responseType: props.responseType,
        isDraft: false
      };
      addQueryResponse(submitReqData);
    },
    [
      addQueryResponse,
      clearError,
      formData,
      isSaving,
      props.responseType,
      resetQuery,
      revisions,
      selectedEntities
    ]
  );

  const onFileUploadsCompleted = uploadedFiles => {
    setUploadState(addFilesState.finished);
    const newFiles = Object.keys(uploadedFiles).map(key => {
      const file = uploadedFiles[key];
      return {
        filePathId: file.filePathId,
        name: file.name,
        projectId: projectId
      };
    });
    sendResponse(newFiles);
  };

  const onFileUploadsFailed = error => {
    setIsSaving(false);
    setFormData(null);
    setErrorMsg(error);
    setUploadState(addFilesState.finished);
  };

  const requiresOverrideMessage = !!revisions?.length;

  const handleSubmit = data => {
    setUploadData(data);
    if (isCheckLoading) {
      return;
    }
    if (requiresOverrideMessage) {
      setUploadData({});
      setFormData({
        response: data.response,
        overrideMessage: data.overrideMessage
      });
      canAttachFiles ? uploadFiles() : sendResponse();
    } else {
      const files = Object.values(data.files);
      if (files.length && files.length !== check?.length) {
        fetchCheck(files.map(f => f.name));
      } else if (!files.length) {
        setFormData({
          response: data.response
        });
        canAttachFiles ? uploadFiles() : sendResponse();
      }
    }
  };

  useEffect(() => {
    const files = Object.values(uploadData?.files ?? {});
    if (
      files.length &&
      files.length === check?.length &&
      !requiresOverrideMessage
    ) {
      setUploadData({});
      setFormData({
        response: uploadData?.response
      });
      canAttachFiles ? uploadFiles() : sendResponse();
    } else if (requiresOverrideMessage && check.length === revisions.length) {
      setShowEntitiesMultiSelect(false);
    }
  }, [
    canAttachFiles,
    check,
    check?.length,
    requiresOverrideMessage,
    revisions?.length,
    sendResponse,
    uploadData?.files,
    uploadData?.response
  ]);

  const getSubmitButtonLabel = () => {
    switch (props.responseType) {
      case responseTypes.approve:
        return t("common:ui.forms.approve.label");
      case responseTypes.reject:
        return t("common:ui.forms.reject.label");
      default:
        return t("common:ui.forms.save.label");
    }
  };

  const getMessageSubject = () => {
    switch (props.responseType) {
      case responseTypes.message:
        return (
          <>
            <span>
              <Trans
                i18nKey="requests:requests.responses.addResponse.subject"
                values={{ shortDescription: props.query?.description ?? "" }}
                context={
                  props.queryType === queryTypes.approveRequest
                    ? queryTypes.approveRequest
                    : ""
                }
                shouldUnescape={true}
                components={{ bold: <strong /> }}
              />
            </span>
          </>
        );
      default:
        return <></>;
    }
  };

  const getTitle = () => {
    switch (props.responseType) {
      case responseTypes.message:
        return props.queryType === queryTypes.approveRequest
          ? "Add a Message"
          : t("requests:requests.responses.addResponse.title");
      case responseTypes.reject:
        return "Reject Request";
      case responseTypes.approve:
        return "Send Approval";
      default:
        return "";
    }
  };

  const getUploadFileErrorMessage = () => {
    return `${
      revisions.length === check.length
        ? t("common:ui.upload.error.fileName.existed")
        : t("common:ui.upload.error.fileName.partiallyExisted")
    } ${
      project?.configuration?.entities?.enabled
        ? t("common:ui.upload.error.entities.willNotUpdated")
        : ""
    }`;
  };

  const validationSchema = () => {
    if (requiresOverrideMessage) {
      return yup.object().shape({
        response: yup
          .string()
          .required(t("requests:requests.responses.addResponse.messageLabel")),
        overrideMessage: yup
          .string()
          .required(t("requests:requests.responses.addResponse.messageLabel"))
      });
    }

    return yup.object().shape({
      response: yup
        .string()
        .required(t("requests:requests.responses.addResponse.messageLabel"))
    });
  };

  return (
    <ModalForm
      title={getTitle()}
      boxClassName="ot-add-responses"
      handleSubmit={handleSubmit}
      submitLabel={getSubmitButtonLabel()}
      handleCancel={props.onCancel}
      yupSchema={validationSchema()}
    >
      {errorMsg && (
        <div className="ot-add-response__contents-error-box">
          <InlineAlert type="error" message={t(errorMsg)} />
        </div>
      )}
      <div className="ot-add-response__contents-subject">
        {getMessageSubject()}
      </div>
      <div className="ot-form__add-text-document-response">
        <Form.TextArea
          label={t("requests:requests.responses.addResponse.messageLabel")}
          name="response"
          placeholder={t(
            "requests:requests.responses.addResponse.messageLabel"
          )}
          maxLength="8192"
          data-testid="message"
          required={true}
        />
      </div>
      {canAttachFiles && (
        <div className="ot-add-response__contents-attachments">
          <Form.UploadDocuments
            name={"files"}
            supportedDocumentMimes={supportedDocumentMimes}
            projectId={projectId}
            state={uploadState}
            onUploadsComplete={onFileUploadsCompleted}
            onUploadsFailed={onFileUploadsFailed}
            disableFileListActions={requiresOverrideMessage}
            disableUploads={requiresOverrideMessage}
            hideUpload={requiresOverrideMessage}
            onChange={handleFilesChange}
          />
        </div>
      )}
      {showEntitiesMultiSelect && (
        <Form.Multiselect
          key="entities"
          name="entities"
          onChange={handleSelectEntities}
          label={t(`requests:requests.configured.fields.entities.label`)}
          defaultValue={selectedEntities?.map(entity => ({
            ...entity,
            value: entity.name
          }))}
          items={projectEntities}
        />
      )}
      {requiresOverrideMessage && (
        <div className="ot-add-response__contents-version-comment">
          {isActionTypeSmartForm ? (
            <InlineAlert
              type="warning"
              message={t("common:ui.upload.error.fileName.cannotOverwrite")}
            ></InlineAlert>
          ) : (
            <>
              <InlineAlert
                type="warning"
                message={getUploadFileErrorMessage()}
              ></InlineAlert>
              <Form.TextArea
                label={t("common:ui.documents.overrideMessage.label")}
                name="overrideMessage"
                maxLength="255"
                data-testid="overrideMessage"
                required={true}
              />
            </>
          )}
        </div>
      )}
    </ModalForm>
  );
};

export default AddQueryResponse;
