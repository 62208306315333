import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import wizardUtilities from "@shared/helpers/websheetWizard/websheetWizardUtilities";

import WebsheetCleaningWizard from "../../WebsheetCleaningWizard";
import WizardWebsheet from "../../WizardWebsheet";

const PreCleansingWizard = React.forwardRef((props, fwdRef) => {
  const {
    loading,
    error,
    data,
    onSheetLoading,
    onCurrentSheetNameChanged: parentOnCurrentSheetNameChanged,
    defaultSheetName,
    wizardEntities,
    isPreCleanseFinished,
    isPreCleanseEnabled
  } = props;

  const [websheetData, setWebsheetData] = useState(null);
  const hotTableComponent = useRef(null);

  useEffect(() => {
    const dataObj = structuredClone(data);
    const websheet = wizardUtilities.prepareDataForCleaning(dataObj);
    setWebsheetData(websheet);
  }, [data]);

  const setHotTableComponent = useCallback(node => {
    hotTableComponent.current = node;
  }, []);

  const currentSheet = useMemo(
    () => data?.find(sheet => sheet.sheet === defaultSheetName),
    [defaultSheetName, data]
  );

  const colWidths = useMemo(() => {
    return wizardUtilities.getColWidthsForWizard(currentSheet);
  }, [currentSheet]);

  const renderWizardWebsheet = () => {
    if (
      wizardEntities.length > 1 &&
      !isPreCleanseFinished &&
      isPreCleanseEnabled
    ) {
      return (
        <WizardWebsheet
          currentSheetName={defaultSheetName}
          data={websheetData}
          colWidths={colWidths}
          loading={loading}
          error={error}
          rowHeaders={true}
          colHeaders={true}
          onSheetLoading={onSheetLoading}
          hotTableComponent={setHotTableComponent}
          onCurrentSheetNameChanged={parentOnCurrentSheetNameChanged}
        />
      );
    }
    return <WebsheetCleaningWizard {...props} ref={fwdRef} />;
  };

  return renderWizardWebsheet();
});

PreCleansingWizard.displayName = "PreCleansingWizard";

export default PreCleansingWizard;
