import React from "react";

import PropTypes from "prop-types";

import "./textTruncate.scss";

const TextTruncate = props => {
  return (
    <div
      className={"text-truncate " + props.className}
      {...(props.title ? { title: props.title } : {})}
      style={{
        maxWidth: props.maxWidth,
        display: props.inline ? "inline-block" : "block",
        direction: props.direction
      }}
    >
      {props.text}
    </div>
  );
};
TextTruncate.defaultProps = {
  maxWidth: "1000",
  direction: "ltr"
};

TextTruncate.propTypes = {
  text: PropTypes.string.isRequired,
  maxWidth: PropTypes.string,
  className: PropTypes.string,
  inline: PropTypes.bool,
  direction: PropTypes.string,
  title: PropTypes.string
};

export default TextTruncate;
