import React, { useState } from "react";

import Avatar from "@components/atoms/Avatar";
import HamburgerMenu from "@components/atoms/HamburgerMenu";

import "./HeaderMenu.scss";

const HeaderMenu = props => {
  const { menuItems, user, icon, type } = props;
  const [isShowMenu, setIsShowMenu] = useState(false);

  const handleMenuClick = () => {
    setIsShowMenu(true);
  };

  const handleCloseMenu = () => {
    setIsShowMenu(false);
  };

  return (
    <div className="header-menu">
      <div
        className={`hamburger-menu__icon ${
          isShowMenu ? "hamburger-menu__icon--show-menu" : ""
        }`}
        onClick={handleMenuClick}
        data-testid="test-hambuger-menu"
      >
        {user?.name ? (
          <Avatar user={user} size="small" />
        ) : (
          <i className="material-icons-outlined menu-icon">{icon ?? "menu"}</i>
        )}
      </div>
      <HamburgerMenu
        isShowMenu={isShowMenu}
        menuItems={menuItems}
        onClickMenu={handleMenuClick}
        onClose={handleCloseMenu}
        user={user}
        showClose={false}
        type={type}
      />
    </div>
  );
};

export default HeaderMenu;
