import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";

import { routeConstants } from "@constants";

import { systemConstants } from "@shared/constants";
import { utilities } from "@shared/helpers";
import { useAuthUser, useClients, useFeatures } from "@shared/hooks";
import { useGetClientEngagementsQuery } from "@shared/services/engagementsService";

import BrandButton from "@components/atoms/Button/BrandButton";
import InlineAlert from "@components/atoms/InlineAlert/InlineAlert";
import LogoHolder from "@components/atoms/LogoHolder";
import AskHelp from "@components/molecules/AskHelp";
import DropdownPagination from "@components/molecules/DropdownPagination";
import Loading from "@components/molecules/Loading";
import AddEngagementForm from "@components/organisms/AddEngagementForm/AddEngagementForm";
import ClientEngagementsTable from "@components/organisms/ClientEngagementsTable/ClientEngagementsTable";
import SearchTextInput from "@components/organisms/SearchTextInput/SearchTextInput";
import UpdateEngagementForm from "@components/organisms/UpdateEngagementForm/UpdateEngagementForm";
import MainPageTemplate from "@components/templates/MainPageTemplate";
import PageBodyTemplate from "@components/templates/PageBodyTemplate/PageBodyTemplate";

import "./ManageClientEngagements.scss";

const ManageClientEngagements = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const { user } = useAuthUser();
  const { clients } = useClients(user);
  const {
    isFetching: isEngagementsLoading,
    data: engagements,
    error
  } = useGetClientEngagementsQuery({
    hostId: user.hostId,
    clientId
  });

  const [pagination, setPagination] = useState({
    countPerPage: systemConstants.pagination.itemCountPerPage,
    pageCount: 1,
    currentPage: "Page 1",
    currentPageIndex: 0,
    pages: []
  });
  const [clientsEngagements, setClientsEngagements] = useState([]);
  const [showAddEngagementForm, setShowAddEngagementForm] = useState(false);
  const [showUpdateEngagementForm, setShowUpdateEngagementForm] =
    useState(false);
  const [engagementToUpdate, setEngagementToUpdate] = useState(null);
  const {
    isEngagementManagerFieldEnabled,
    isEnabled: isFeatureEnabled,
    isLoading: isFeaturesLoading
  } = useFeatures();
  const { isKeywordInclude } = utilities;

  const sortEngagements = useCallback(engagements => {
    engagements &&
      [...engagements].sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        } else {
          return -1;
        }
      });
    return engagements;
  }, []);

  useEffect(() => {
    if (error && clientId) {
      navigate(routeConstants.notFound);
    }
  }, [clientId, error, navigate]);

  useEffect(() => {
    if (!pagination.pages.length) {
      setClientsEngagements([]);
    } else {
      setClientsEngagements(
        pagination.pages[pagination.currentPageIndex]?.data
      );
    }
  }, [pagination.currentPageIndex, pagination.pages]);

  useEffect(() => {
    if (engagements) {
      utilities.createPagination({
        list: sortEngagements(engagements),
        setPagination
      });
    }
  }, [engagements, sortEngagements]);

  useEffect(() => {
    const isEngagementsEnabled = isFeatureEnabled(
      systemConstants.features.engagements
    );
    if (!isFeaturesLoading && !isEngagementsEnabled) {
      navigate(routeConstants.notFound);
    }
  }, [isFeatureEnabled, isFeaturesLoading, navigate]);

  const openAddEngagementForm = () => {
    setShowAddEngagementForm(true);
  };

  const handleFilterChange = useCallback(
    searchInput => {
      if (!searchInput) {
        utilities.createPagination({
          list: sortEngagements(engagements),
          setPagination
        });
      } else {
        const filteredEngagements = engagements.filter(
          engagement =>
            isKeywordInclude(engagement.name, searchInput) ||
            (user.isHostUser &&
              isKeywordInclude(engagement.client.name, searchInput)) ||
            isKeywordInclude(engagement.engagementManager?.name, searchInput)
        );
        utilities.createPagination({
          list: sortEngagements(filteredEngagements),
          setPagination
        });
      }
    },
    [engagements, isKeywordInclude, sortEngagements, user.isHostUser]
  );

  const onClientClickHandler = client => {
    if (clientId) {
      navigate(routeConstants.hostLandingPage);
    } else {
      navigate(`${routeConstants.clients}/${client.id}`);
    }
  };

  const openUpdateEngagementForm = engagement => {
    setShowUpdateEngagementForm(true);
    setEngagementToUpdate(engagement);
  };

  const visibleClientList = useMemo(
    () =>
      engagements?.reduce((acc, engagement) => {
        if (!acc.some(client => client.id === engagement.client.id)) {
          acc.push(engagement.client);
        }
        return acc;
      }, []) ?? [],
    [engagements]
  );

  const onEngagementSubmitted = useCallback(() => {
    setShowAddEngagementForm(false);
    setShowUpdateEngagementForm(false);
    setEngagementToUpdate(null);
  }, []);

  const validationSchema = useMemo(() => {
    if (isEngagementManagerFieldEnabled) {
      return yup.object({
        name: yup.string().required(t("common:ui.engagement.validation.name")),
        client: yup
          .object()
          .required(t("common:ui.engagement.validation.client")),
        engagementManager: yup
          .object()
          .required(t("common:ui.engagement.validation.manager"))
      });
    }
    return yup.object({
      name: yup.string().required(t("common:ui.engagement.validation.name")),
      client: yup.object().required(t("common:ui.engagement.validation.client"))
    });
  }, [isEngagementManagerFieldEnabled, t]);

  const renderClient = client => (
    <div
      className="client-engagement__logo-box-container--box"
      key={client.id}
      onClick={() => onClientClickHandler(client)}
    >
      <LogoHolder src={client.logo?.data} label={client.name} />
    </div>
  );

  const renderClientsList = () => {
    if (user.isHostUser && clients.length && clientsEngagements.length) {
      return (
        <div className="client-engagement__logo-box-container">
          {visibleClientList.map(renderClient)}
        </div>
      );
    }
    return <></>;
  };

  const headerActions = () => {
    if (!user.isHostAdmin) {
      return;
    }
    return (
      <BrandButton
        type="primary"
        iconName="add"
        onClick={openAddEngagementForm}
        label={t("common:ui.engagement.addLabel")}
      />
    );
  };

  const isLoading = useMemo(
    () => isFeaturesLoading || isEngagementsLoading,
    [isEngagementsLoading, isFeaturesLoading]
  );

  const engagementsTable = useMemo(() => {
    const handlePageSelection = pageName => {
      utilities.handlePageSelection(pageName, pagination, setPagination);
    };

    const handlePageCountIncrement = () => {
      utilities.handlePageCountIncrement(pagination, setPagination);
    };

    const handlePageCountDecrement = () => {
      utilities.handlePageCountDecrement(pagination, setPagination);
    };

    const handleClickEngagement = engagement =>
      navigate(`${routeConstants.engagements}/${engagement.id}`);

    if (isLoading) {
      return <Loading message={t("common:ui.engagement.loadingEngagements")} />;
    }

    if (clientsEngagements?.length) {
      return (
        <>
          <div className="client-engagement__table">
            <ClientEngagementsTable
              clientEngagements={clientsEngagements}
              userRole={user.role.name}
              viewFor={user.isHostUser ? "host" : "client"}
              onClickEngagement={handleClickEngagement}
              onUpdateEngagement={openUpdateEngagementForm}
              isEngagementManagerFieldEnabled={isEngagementManagerFieldEnabled}
            />
          </div>
          <DropdownPagination
            handlePageSelection={handlePageSelection}
            handlePageCountIncrement={handlePageCountIncrement}
            handlePageCountDecrement={handlePageCountDecrement}
            pages={pagination.pages.map(page => ({ name: page.name }))}
            currentPage={pagination.currentPage}
            currentPageIndex={pagination.currentPageIndex}
          />
        </>
      );
    }

    return <AskHelp message={t("common:ui.engagement.noEngagementsAdded")} />;
  }, [
    clientsEngagements,
    isEngagementManagerFieldEnabled,
    isLoading,
    navigate,
    pagination,
    t,
    user.isHostUser,
    user.role.name
  ]);

  return (
    <MainPageTemplate>
      <PageBodyTemplate
        title={t("common:ui.engagement.navigation.title")}
        actions={headerActions()}
      >
        <div className="client-engagement__container">
          {error && error.message !== "Not Found" && (
            <div className="client-engagement__error-box">
              <InlineAlert type="error" message={error.message} />
            </div>
          )}

          <div className="client-engagement__search">
            <SearchTextInput
              label={
                user.isHostUser
                  ? t("common:ui.engagement.searchLabel", { context: "host" })
                  : t("common:ui.engagement.searchLabel")
              }
              handleChange={handleFilterChange}
            />
          </div>

          {renderClientsList()}
          {engagementsTable}
        </div>
        {showAddEngagementForm && (
          <AddEngagementForm
            onCancel={() => {
              setShowAddEngagementForm(false);
            }}
            showForm={showAddEngagementForm}
            onSubmitted={onEngagementSubmitted}
            yupSchema={validationSchema}
            isEngagementManagerFieldEnabled={isEngagementManagerFieldEnabled}
          />
        )}
        {showUpdateEngagementForm && (
          <UpdateEngagementForm
            engagement={engagementToUpdate}
            onCancel={() => {
              setShowUpdateEngagementForm(false);
            }}
            showForm={showUpdateEngagementForm}
            onSubmitted={onEngagementSubmitted}
            yupSchema={validationSchema}
            isEngagementManagerFieldEnabled={isEngagementManagerFieldEnabled}
          />
        )}
      </PageBodyTemplate>
    </MainPageTemplate>
  );
};

export default ManageClientEngagements;
