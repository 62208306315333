import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageHostUsersActions } from "@shared/actions";
import { useGetClientsWithEngagementsAndProjects } from "@shared/hooks";

export function useHostUserProfile(updateUser) {
  const dispatch = useDispatch();

  const authentication = useSelector(state => state.authentication);
  const manageHostUsers = useSelector(state => state.manageHostUsers);
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState();
  const {
    clientsWithEngagementsAndProjects,
    fetchAllClientsWithEngagementsAndProjects
  } = useGetClientsWithEngagementsAndProjects();
  const [projectsList, setProjectsList] = useState();
  const [teamUserFields, setTeamUserFields] = useState([]);

  useEffect(() => {
    dispatch(manageHostUsersActions.getHostRoles(authentication.user));
    return () => dispatch(manageHostUsersActions.clearAddHostUserFlag());
  }, [authentication.user, dispatch]);

  useEffect(() => {
    fetchAllClientsWithEngagementsAndProjects({
      user: authentication.user,
      updateUser
    });
  }, [
    authentication.user,
    updateUser,
    fetchAllClientsWithEngagementsAndProjects
  ]);

  useEffect(() => {
    setRoles(structuredClone(manageHostUsers.roles));
  }, [manageHostUsers.roles]);

  useEffect(() => {
    setError(structuredClone(manageHostUsers.error));
  }, [manageHostUsers.error]);

  useEffect(() => {
    setTeamUserFields(
      authentication.host?.host?.properties?.formsConfig?.teamUserFields ?? []
    );
  }, [authentication.host?.host?.properties?.formsConfig?.teamUserFields]);

  useEffect(() => {
    setProjectsList(clientsWithEngagementsAndProjects);
  }, [clientsWithEngagementsAndProjects]);

  return {
    error,
    projectsList,
    roles,
    teamUserFields
  };
}
