// Note: This file is used to determine the font size for the side nav names (project or client).
// breakpoints.max is the max number of characters for a given font size.
// breakpoints.size is the desired font size in rem.
const breakpoints = [
  {
    max: 40,
    size: 1.25
  },
  {
    max: 50,
    size: 1.1
  },
  {
    max: 80,
    size: 0.8
  },
  {
    max: 120,
    size: 0.7
  },
  {
    max: 255,
    size: 0.6
  }
];
// getDesiredFontSizeRem returns the desired font size in rem for a given name.
// The output value is a number.
export const getDesiredFontSizeRem = name => {
  const length = name?.length || 0;
  const breakpoint = breakpoints.find(bp => length <= bp.max);
  return breakpoint.size;
};
