import { systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { handleErrorResponse, handleResponse } from "./";

const baseURL = systemConstants.serverURL;

export const manageClientGroupsService = {
  getClientGroups
};

function getClientGroups(client) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`${baseURL}/api/clients/${client.id}/groups`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}
