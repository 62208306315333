import React from "react";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useGetProjectByIdQuery } from "@shared/services";
import { useGetFeaturesQuery } from "@shared/services/featuresService";

import FormPageTemplate from "@components/templates/FormPageTemplate/FormPageTemplate";

import UpdateProjectForm from "./UpdateProjectForm/UpdateProjectForm";

const UpdateProject = () => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { data: project, isLoading: loadingProject } = useGetProjectByIdQuery(
    { projectId: projectId },
    { skip: !projectId }
  );
  const { isLoading: loadingFeatures } = useGetFeaturesQuery();

  return (
    <FormPageTemplate
      title={t("common:ui.projects.title_update")}
      formElement={<UpdateProjectForm project={project} />}
      isLoading={loadingProject || loadingFeatures}
      other={{ project }}
    />
  );
};

UpdateProject.propTypes = {};

export default UpdateProject;
