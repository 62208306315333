import { systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { handleErrorResponse, handleResponse } from "./";

const baseURL = systemConstants.serverURL;

export const manageClientEngagementsService = {
  getClients,
  getEngagementManagers,
  getByProjectId,
  getMembersToAdd,
  getMembers,
  addMember,
  removeMember
};

function getMembers(engagement) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/engagements/${engagement.id}/members`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getMembersToAdd(engagement) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/engagements/${engagement.id}/membersToAdd`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getByProjectId(projectId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/engagements/getByProjectId/${projectId}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getClients(hostUser) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    `${baseURL}/api/hosts/${hostUser.hostId}/clients`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getEngagementManagers(user) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    `${baseURL}/api/hosts/${user.hostId}/engagement-managers`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function addMember({ engagement, member }) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(member)
  };

  return fetch(
    `${baseURL}/api/engagements/${engagement.id}/members`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function removeMember({ engagement, member }) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    body: JSON.stringify(member)
  };
  return fetch(
    `${baseURL}/api/engagements/${engagement.id}/members`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}
