import React from "react";

import PropTypes from "prop-types";

import dateFormatter from "@shared/helpers/dateHelper";
import { useLocaleDate } from "@shared/hooks/useLocaleDate";

import Avatar from "@components/atoms/Avatar";

import FileAttachmentList from "../FileAttachmentList/FileAttachmentList";
import "./ConversationMessage.scss";

const ConversationMessage = props => {
  const { locale, options } = useLocaleDate();
  return (
    <>
      <div
        className={`conversation-message conversation-message--${props.align}`}
      >
        <div className="conversation-message__speaker-icon">
          <Avatar user={props.user} width="2rem" height="2rem" />
        </div>
        <div className="conversation-message__contents">
          <div className="conversation-message__contents-name">
            {props.user.name}
          </div>
          <div className="conversation-message__contents-body">
            {props.message && (
              <div className="conversation-message__contents-message">
                {props.message}
              </div>
            )}
            {props.attachments.length > 0 && (
              <>
                <FileAttachmentList
                  attachments={props.attachments}
                  onFileDownloadClicked={props.onFileDownloadClicked}
                />
              </>
            )}
            {props.messageFooter && (
              <div className="conversation-message__contents-message">
                {props.messageFooter}
              </div>
            )}
          </div>
          <div className="conversation-message__contents-date">
            {dateFormatter(props.updatedAt, locale, options.longFormat)}
          </div>
          <div className="conversation-message__contents-bonus"></div>
        </div>
      </div>
    </>
  );
};

ConversationMessage.defaultProps = {
  align: "left"
};

ConversationMessage.propTypes = {
  align: PropTypes.oneOf(["left", "right"]),
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    avatar: PropTypes.shape({
      image: PropTypes.string
    })
  }),
  message: PropTypes.string,
  messageFooter: PropTypes.element,
  updatedAt: PropTypes.string.isRequired,
  onFileDownloadClicked: PropTypes.func.isRequired
};

export default ConversationMessage;
