import React, { useMemo } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import HoverText from "@components/atoms/HoverText";
import Icon from "@components/atoms/Icon";

import "./WebsheetEntityIcon.scss";

const entitiesHelpers = ({
  projectEntities,
  entitiesToDisplayIds,
  maxEntities
}) => {
  const entities = projectEntities
    ?.filter(entity => entitiesToDisplayIds?.includes(entity.externalId))
    ?.map(entity => entity.name);
  if (entities?.length > maxEntities) {
    return `${entities.splice(0, maxEntities).join(", ")}...`;
  }
  return entities?.join(", ");
};

const WebsheetEntityIcon = props => {
  const {
    projectEntities,
    queryEntitiesIds,
    documentEntities,
    websheetType,
    iconClassName,
    maxEntities
  } = props;

  const { t } = useTranslation();

  const entitiesForSmartForm = useMemo(() => {
    if (!documentEntities?.length) {
      return t("requests:requests.ui.smartForm.websheetEntities.noneApplied");
    } else {
      return entitiesHelpers({
        projectEntities,
        entitiesToDisplayIds: documentEntities.map(e => e.externalId),
        maxEntities
      });
    }
  }, [documentEntities, t, projectEntities, maxEntities]);

  const entitiesForDocument = useMemo(() => {
    if (documentEntities?.length === 0 || !documentEntities) {
      return t("requests:requests.ui.smartForm.websheetEntities.noneApplied");
    } else {
      const entitiesForDocument = documentEntities?.map(entity => entity.name);
      if (documentEntities?.length > maxEntities) {
        return `${entitiesForDocument.splice(0, maxEntities).join(", ")}...`;
      }
      return entitiesForDocument.join(", ");
    }
  }, [documentEntities, t, maxEntities]);

  const entitiesForQueries = useMemo(() => {
    if (queryEntitiesIds?.length === 0) {
      return t("requests:requests.ui.smartForm.websheetEntities.noneApplied");
    } else {
      return entitiesHelpers({
        projectEntities,
        entitiesToDisplayIds: queryEntitiesIds,
        maxEntities
      });
    }
  }, [queryEntitiesIds, projectEntities, t, maxEntities]);

  const entitiesToDisplay = useMemo(() => {
    if (websheetType === "smartForm") {
      return entitiesForSmartForm;
    } else if (websheetType === "document") {
      return entitiesForDocument;
    } else if (websheetType === "query") {
      return entitiesForQueries;
    }
  }, [
    websheetType,
    entitiesForSmartForm,
    entitiesForDocument,
    entitiesForQueries
  ]);

  const hoverText = (
    <>
      <span className="websheet-entity-icon__title">
        {t("requests:requests.ui.smartForm.websheetEntities.label")}
      </span>
      {entitiesToDisplay}
    </>
  );

  return (
    <div className="websheet-entity-icon">
      <Icon
        name="lan"
        size="medium"
        fillStyle="filled"
        className={iconClassName}
      />
      <HoverText
        name={hoverText}
        position={"bottom-left"}
        classNameTarget="websheet-entity-icon_hover-text"
        type={"long"}
      />
    </div>
  );
};

WebsheetEntityIcon.defaultProps = {
  maxEntities: 10
};

WebsheetEntityIcon.propTypes = {
  iconClassName: PropTypes.string,
  maxEntities: PropTypes.number,
  projectEntities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      externalId: PropTypes.number.isRequired
    })
  ),
  queryEntitiesIds: PropTypes.arrayOf(PropTypes.number),
  documentEntities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      externalId: PropTypes.number.isRequired
    })
  ),
  websheetType: PropTypes.oneOf(["smartForm", "document", "query"]),
  queryAnswers: PropTypes.object,
  documentId: PropTypes.number
};

export default WebsheetEntityIcon;
