import React from "react";

import PropTypes from "prop-types";

import LoaderSpinner from "../loaderSpinner/LoaderSpinner";
import "./button.scss";

const Button = props => {
  return (
    <div
      onClick={props.type !== "disabled" ? props.handleClick : null}
      className={`application-button ${props.type}`}
    >
      <div className={`application-button__content ${props.customClass}`}>
        <i className="material-icons application-button--icon">{props.icon}</i>
        {props.loading ? (
          <span className="application-button--spinner">
            <LoaderSpinner diameter="18px" />
          </span>
        ) : (
          <></>
        )}
        <div className="application-button--name">{props.name}</div>
      </div>
    </div>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf([
    "disabled",
    "brand",
    "primary",
    "plain",
    "plain-primary",
    "plain-brand",
    "negative",
    "text-primary",
    "text-brand"
  ]).isRequired,
  name: PropTypes.string,
  icon: PropTypes.string,
  handleClick: PropTypes.func
};

export default Button;
