import React from "react";

import PropTypes from "prop-types";

import Icon from "../Icon/Icon";
import "./BrandButton.scss";

const BrandButton = props => {
  const {
    label,
    type,
    onClick,
    disabled,
    enableWrap,
    iconSide,
    size,
    iconSize,
    iconName,
    className,
    id,
    shape
  } = props;
  const renderIcon = (iconName, iconOutlined) => (
    <Icon
      name={iconName}
      designStyle="material-icons"
      fillStyle={iconOutlined ? "outlined" : "filled"}
      className="button__icon"
    ></Icon>
  );

  const getStyle = () => {
    return {
      ...(props.textTransform ? { textTransform: props.textTransform } : {})
    };
  };

  return (
    <button
        className={`${className} button button--${type} button--iconside-${iconSide} ${
          enableWrap ? "button--wrap" : ""
          } button--size-${size} button--iconsize-${iconSize} button--shape-${shape}`}
        onClick={onClick}
        disabled={disabled}
        type={props.submit ? "submit" : "button"}
        style={getStyle()}
        id={id}
      >
        {iconName &&
          props.iconSide === "left" &&
          renderIcon(iconName, props.iconOutlined)}
        <span className="button__label">{label}</span>
        {iconName &&
          props.iconSide === "right" &&
          renderIcon(iconName, props.iconOutlined)}
        {props.hoverElement && (
          <span
            className={`button__hover button__hover--${props.hoverPosition}`}
          >
            {props.hoverElement}
          </span>
        )}
    </button>
  );
};

BrandButton.defaultProps = {
  type: "primary",
  enableWrap: false,
  iconOutlined: false,
  iconSide: "left",
  size: "default",
  iconSize: "default",
  className: "",
  shape: "default"
};

BrandButton.propTypes = {
  type: PropTypes.oneOf([
    "primary",
    "primary-dark",
    "secondary",
    "tertiary",
    "danger",
    "text",
    "text-inherit",
    "text-dark",
    "text-accent"
  ]).isRequired,
  enableWrap: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  iconName: PropTypes.string,
  iconOutlined: PropTypes.bool,
  iconSide: PropTypes.oneOf(["left", "right"]),
  submit: PropTypes.bool,
  label: PropTypes.node,
  size: PropTypes.oneOf(["small", "minimal", "default"]),
  iconSize: PropTypes.oneOf(["default", "medium", "large"]),
  className: PropTypes.string,
  textTransform: PropTypes.string,
  hoverElement: PropTypes.node,
  hoverPosition: PropTypes.oneOf(["left"]),
  id: PropTypes.string,
  shape: PropTypes.oneOf(["rounded", "default"])
};

export default BrandButton;
