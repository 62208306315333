import { getAnswersByEntity } from "../../structure";
import { runWithConditionalForest } from "./conditionalLogic";
import { ConditionalLogicForest } from "./conditionalLogicForest";

/**
 * This strategy is used to determine the visibility of a question based on the conditional logic
 * configuration and the answers provided by the user.
 * @param {Object} conditionalLogicConfig The conditional logic configuration
 * @param {Object[]} questions The list questions
 * @param {Object} entitiesById The entities keyed by their id
 * @param {Object} answers The answers provided by the user
 * @return {Object} Object containing the strategy functions
 */
export function conditionalLogicStrategy(
  conditionalLogicConfig,
  questions,
  entitiesById,
  answers
) {
  const questionStateByEntity = (() => {
    const entities = Object.keys(entitiesById);
    const answersByEntity = getAnswersByEntity(answers, entities);

    const clForest = ConditionalLogicForest({
      config: conditionalLogicConfig,
      questions
    });

    const statesByEntity = entities.map(entityId => {
      const answersForEntity = answersByEntity[entityId];

      const questionState = runWithConditionalForest({
        conditionalLogicForest: clForest,
        answerByQuestionId: answersForEntity
      });

      return {
        entityId,
        answers,
        states: questionState
      };
    });

    return statesByEntity;
  })();

  const getResultingQuestionState = question => {
    const statesByEntity = questionStateByEntity;

    // Merges the states of all entities into one
    const visibleStatesByEntity = statesByEntity.filter(
      ({ states }) => states[question.questionId]?.isVisible
    );

    const visibleEntities = visibleStatesByEntity.map(
      ({ entityId }) => +entityId
    );
    const visibleStates = visibleStatesByEntity
      .map(({ states }) => states[question.questionId])
      .reduce((acc, curr) => {
        const { isVisible, isMandatory } = acc[question.questionId] ?? {};
        return {
          ...acc,
          [question.questionId]: {
            isVisible: isVisible || curr.isVisible,
            isMandatory: isMandatory || curr.isMandatory
          }
        };
      }, {});

    const result = {
      entities: [...new Set([...visibleEntities])],
      states: visibleStates
    };

    return result;
  };

  /**
   * @returns Entities that are relevant to the question based on its answers and conditional logic
   */
  const getQuestionRelevantEntities = question => {
    const { entities } = getResultingQuestionState(question);
    if (!entities?.length) {
      return { relevantEntities: null };
    }
    return { relevantEntities: entities };
  };

  /**
   * Checks if the question is visible based on its configuration or conditional logic
   * This returns the result for the union of all entities
   * @returns TRUE if the question should be visible, FALSE otherwise
   */
  const isQuestionVisible = question => {
    const { states } = getResultingQuestionState(question);

    return question.showByDefault || states[question.questionId]?.isVisible;
  };

  /**
   * Checks if the question is visible for the specific entity based on its configuration or conditional logic.
   * Results returned by this one may be different from isQuestionVisible() as it is a union of ALL visibility states for relevant entities
   * @returns TRUE if the question should be visible, FALSE otherwise
   */
  const isQuestionVisibleForEntity = (question, entityId) => {
    const stateForEntity = questionStateByEntity.find(
      qs => +qs.entityId === +entityId
    );
    return (
      question.showByDefault ||
      !!stateForEntity?.states?.[question.questionId]?.isVisible
    );
  };

  /**
   * Checks if the question is mandatory based on its configuration or conditional logic
   * @returns TRUE if the question should be mandatory, FALSE otherwise
   */
  const isQuestionMandatory = question => {
    const { states } = getResultingQuestionState(question);

    return question.isMandatory || states[question.questionId]?.isMandatory;
  };

  return {
    getQuestionRelevantEntities,
    isQuestionVisible,
    isQuestionMandatory,
    isQuestionVisibleForEntity
  };
}
