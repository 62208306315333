import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useOnClickOutside from "use-onclickoutside";

import {
  manageDocumentDownloadsActions,
  manageProjectActions,
  manageProjectDocumentsActions
} from "@shared/actions";
import LoaderSpinner from "@shared/components/loaderSpinner/LoaderSpinner";
import {
  useAuthUser,
  useDeleteProjectTag,
  useGetProjectTags,
  useRequestPageNavigator,
  useSearchProjectDocuments,
  useUIConfig
} from "@shared/hooks";
import { useModalContent } from "@shared/hooks/useModalContent";

import CreateFolder from "@shared-components/createFolder/CreateFolder";
import DocumentNameChange from "@shared-components/documentNameChange/DocumentNameChange";
import Popup from "@shared-components/popup/Popup";
import WarningMessage from "@shared-components/warningMessage/WarningMessage";

import { routeConstants } from "@app/constants/routeConstants";

import BrandButton from "@components/atoms/Button/BrandButton";
import TextInput from "@components/atoms/TextInput/TextInput";
import IndexList from "@components/molecules/IndexList";
import TagEditor from "@components/molecules/TagEditor";
import AddClientProjectDocument from "@components/organisms/AddClientProjectDocument";
import DocumentsTable from "@components/organisms/DocumentsTable";
import EditSignedLabelConfirmationModal from "@components/organisms/EditSignedLabelConfirmationModal";
import PageTemplate from "@components/templates/PageTemplate/PageTemplate";

import AddOrCopyProjectTags from "../AddOrCopyProjectTags/AddOrCopyProjectTags";
import ProjectDocumentsTableActions from "../DocumentsTable/ProjectDocumentsTableActions";
import UploadFileBox from "../UploadFileBox/UploadFileBox";
import "./ManageClientProjectDocuments.scss";

const menuItems = {
  addDocument: "Add a document",
  moveSelectedDocument: "Move selected document"
};

const menu = [
  { id: 1, name: menuItems.addDocument },
  { id: 2, name: menuItems.moveSelectedDocument, disabled: true }
];

const editTagTypes = {
  add: "ADD",
  edit: "EDIT",
  delete: "DELETE"
};

const ManageClientProjectDocuments = props => {
  const { t } = useTranslation();
  const { navigateToRequestPage } = useRequestPageNavigator();
  const [showEditSignedTagConfirmation, setShowEditSignedTagConfirmation] =
    useState(false);
  const { deleteProjectTag, deleteProjectTagError, resetDeleteProjectTag } =
    useDeleteProjectTag();
  const { projectTags, fetchProjectTags } = useGetProjectTags(
    props.project?.id
  );

  const { project, handleDownloadAll, isDownloading, error } = props;

  const dispatch = useDispatch();
  const { manageProjectDocuments, manageDocumentDownloads } = useSelector(
    state => state
  );

  const [projectFoldersAndDocuments, setProjectFoldersAndDocuments] = useState({
    id: `project_${project.id}`,
    name: project.name,
    type: "project",
    isRoot: true,
    folders: [],
    documents: []
  });
  const [folder, setFolder] = useState({
    id: null,
    folders: [],
    documents: []
  });
  const documentMenuRef = useRef(null);
  const [tag, setTag] = useState(null);
  const [createFolderShow, setCreateFolderShow] = useState(false);
  const [addClientDocumentShow, setAddClientDocumentShow] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [moveDocumentShow, setMoveDocumentShow] = useState(null);
  const [documentNameChangeShow, setDocumentNameChangeShow] = useState(false);
  const [tagEditorShow, setTagEditorShow] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [currentFolder, setCurrentFolder] = useState(
    projectFoldersAndDocuments
  );
  const [showArchivedDocuments, setShowArchivedDocuments] = useState(false);
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [activeDocumentAction, setActiveDocumentAction] = useState(null);
  const { searchDocuments, searchResults } = useSearchProjectDocuments();
  const [tagMessage, setTagMessage] = useState("");
  const [showAddTags, setShowAddTags] = useState(false);
  const [editType, setEditType] = useState(editTagTypes.edit);
  const [handleTagSubmit, setHandleTagSubmit] = useState(() => () => {});
  const { user } = useAuthUser();
  const [isArchivedFolder, setIsArchivedFolder] = useState(false);
  const { uiConfig } = useUIConfig();
  const [tagUiConfig, setTagUiConfig] = useState({
    tag: {
      shape: "rectangle",
      activeStyle: "outline"
    }
  });

  const {
    modalOpen,
    getModalContent,
    registerModal,
    handleOpenModal,
    handleCloseModal
  } = useModalContent();

  useEffect(() => {
    if (uiConfig?.pills?.tag) {
      setTagUiConfig(uiConfig.pills.tag);
    }
  }, [uiConfig?.pills]);

  useEffect(() => {
    if (manageProjectDocuments.selectedProjectDocument) {
      setSelectedDocument(manageProjectDocuments.selectedProjectDocument);
      menu.forEach(option => {
        if (option.name === menuItems.moveSelectedDocument) {
          option.disabled = false;
        }
      });
    } else {
      menu.forEach(option => {
        if (option.name === menuItems.moveSelectedDocument) {
          option.disabled = true;
        }
      });
    }
  }, [manageProjectDocuments.selectedProjectDocument]);

  useEffect(() => {
    dispatch(
      manageProjectDocumentsActions.getProjectFoldersAndDocuments(
        project,
        "project"
      )
    );
    dispatch(
      manageProjectDocumentsActions.getProjectRequestFoldersAndDocuments(
        project
      )
    );
    dispatch(
      manageProjectDocumentsActions.getArchivedProjectDocuments(project)
    );
    return () => {
      dispatch(manageProjectDocumentsActions.reset());
      dispatch(manageDocumentDownloadsActions.reset());
      dispatch(manageProjectDocumentsActions.resetAllTags());
    };
  }, [dispatch, project]);

  useEffect(() => {
    if (manageProjectDocuments.movedDocument) {
      setFolder(prevState => {
        const updatedDocuments = [manageProjectDocuments.movedDocument].concat(
          prevState.documents
        );
        return { ...prevState, documents: updatedDocuments };
      });
      dispatch(manageProjectDocumentsActions.resetMovedDocument());
      dispatch(manageProjectDocumentsActions.resetSelectedProjectDocument());
    }
  }, [dispatch, manageProjectDocuments.movedDocument]);

  useEffect(() => {
    if (manageProjectDocuments.restoredDocument) {
      setFolder(prevState => {
        const updatedDocuments = prevState.documents.map(doc => {
          if (doc.id === manageProjectDocuments.restoredDocument.id) {
            doc = { ...manageProjectDocuments.restoredDocument };
          }
          return doc;
        });
        return { ...prevState, documents: updatedDocuments };
      });
      dispatch(manageProjectDocumentsActions.resetRestoredDocument());
    }
  }, [dispatch, manageProjectDocuments.restoredDocument]);

  useEffect(() => {
    if (manageProjectDocuments.uploadedDocument) {
      setFolder(prevState => {
        const updatedDocuments = prevState.documents.map(doc => {
          if (doc.id === manageProjectDocuments.uploadedDocument.id) {
            doc = { ...manageProjectDocuments.uploadedDocument };
          }
          return doc;
        });
        return { ...prevState, documents: updatedDocuments };
      });
      dispatch(manageProjectDocumentsActions.resetUploadedDocument());
    }
  }, [dispatch, manageProjectDocuments.uploadedDocument]);

  useEffect(() => {
    if (manageProjectDocuments.updatedDocumentWithNewRevsion) {
      setFolder(prevState => {
        const updatedDocuments = prevState.documents.map(doc => {
          if (
            doc.id === manageProjectDocuments.updatedDocumentWithNewRevsion.id
          ) {
            doc = { ...manageProjectDocuments.updatedDocumentWithNewRevsion };
          }
          return doc;
        });
        return { ...prevState, documents: updatedDocuments };
      });
    }
  }, [manageProjectDocuments.updatedDocumentWithNewRevsion]);

  useEffect(() => {
    if (manageProjectDocuments.newUploadedDocument) {
      const newDocument = manageProjectDocuments.newUploadedDocument;
      if (
        folder.id === `project_${newDocument.projectId}` ||
        folder.id === newDocument.projectFolderId
      ) {
        setFolder(prevState => {
          return {
            ...prevState,
            documents: [newDocument].concat(folder.documents)
          };
        });
      }

      dispatch(manageProjectDocumentsActions.resetNewUploadedDocument());
    }
  }, [
    dispatch,
    folder.documents,
    folder.id,
    manageProjectDocuments.newUploadedDocument
  ]);

  useEffect(() => {
    setProjectFoldersAndDocuments(prevState => ({
      ...prevState,
      folders: manageProjectDocuments.foldersAndDocuments.folders,
      documents: manageProjectDocuments.foldersAndDocuments.documents
    }));
    setFolder(prevState => {
      return {
        ...prevState,
        folders:
          prevState.type === "tag"
            ? []
            : manageProjectDocuments.foldersAndDocuments.folders,
        documents:
          prevState.type === "tag"
            ? manageProjectDocuments.taggedDocuments
            : manageProjectDocuments.foldersAndDocuments.documents
      };
    });
  }, [
    manageProjectDocuments.foldersAndDocuments,
    manageProjectDocuments.taggedDocuments
  ]);

  useEffect(() => {
    if (
      (projectFoldersAndDocuments.documents &&
        projectFoldersAndDocuments.documents.length) ||
      (projectFoldersAndDocuments.folders &&
        projectFoldersAndDocuments.folders.length)
    ) {
      setFolder(prevState =>
        prevState?.id ? prevState : projectFoldersAndDocuments
      );
    }
  }, [projectFoldersAndDocuments]);

  useEffect(() => {
    if (manageProjectDocuments.createdFolder) {
      setFolder(prevState => {
        const updatedFolders = prevState.folders
          ? [manageProjectDocuments.createdFolder].concat(prevState.folders)
          : [manageProjectDocuments.createdFolder];
        return { ...prevState, folders: updatedFolders };
      });
      dispatch(manageProjectDocumentsActions.resetCreatedFolder());
    }
  }, [dispatch, manageProjectDocuments.createdFolder]);

  useEffect(() => {
    const isArchived = folder.id === "archivedFolder";
    setIsArchivedFolder(isArchived);
  }, [folder]);

  useOnClickOutside(documentMenuRef, () => {});

  const handlePopupOutsideClick = () => {
    setCreateFolderShow(false);
    setAddClientDocumentShow(false);
    setMoveDocumentShow(false);
    setDocumentNameChangeShow(false);
  };

  const handleCancelOnAddClientDocument = () => {
    setDroppedFiles([]);
    setAddClientDocumentShow(false);
  };

  const viewTagDocuments = useCallback(
    tagId => {
      dispatch(
        manageProjectDocumentsActions.getProjectDocumentsByTag({
          projectId: project.id,
          tagId
        })
      );
    },
    [dispatch, project]
  );

  const viewArchivedDocuments = useCallback(() => {
    setShowArchivedDocuments(true);
    dispatch(
      manageProjectDocumentsActions.getArchivedProjectDocuments(project)
    );
  }, [dispatch, project]);

  const viewRequestDocuments = useCallback(() => {
    dispatch(
      manageProjectDocumentsActions.getProjectFoldersAndDocuments(
        project,
        "request"
      )
    );
  }, [dispatch, project]);

  const viewProjectDocuments = useCallback(() => {
    dispatch(
      manageProjectDocumentsActions.getProjectFoldersAndDocuments(
        project,
        "project"
      )
    );
  }, [dispatch, project]);

  const handleFolderSelection = useCallback(
    selectedFolder => {
      dispatch(manageDocumentDownloadsActions.resetError());
      setFolder(selectedFolder);
      setCurrentFolder(selectedFolder);
      setSearchString("");
      if (selectedFolder.id !== "archivedFolder") {
        setShowArchivedDocuments(false);
      }

      if (selectedFolder.type === "tag") {
        viewTagDocuments(selectedFolder.id);
      } else if (selectedFolder.isRoot) {
        if (selectedFolder.id === "archivedFolder") {
          viewArchivedDocuments();
        } else if (selectedFolder.id === "requestFolder") {
          viewRequestDocuments();
        } else {
          viewProjectDocuments();
        }
      }
    },
    [
      dispatch,
      viewArchivedDocuments,
      viewProjectDocuments,
      viewRequestDocuments,
      viewTagDocuments
    ]
  );

  const handleResetFilters = useCallback(() => {
    handleFolderSelection(projectFoldersAndDocuments);
  }, [handleFolderSelection, projectFoldersAndDocuments]);

  const handleMoveClick = () => {
    if (
      folder.documents.some(
        doc =>
          doc.name === selectedDocument.name &&
          doc.projectFolderId === selectedDocument.projectFolderId
      )
    ) {
      setMoveDocumentShow(false);
      dispatch(manageProjectDocumentsActions.resetSelectedProjectDocument());
    } else if (
      folder.documents.some(doc => doc.name === selectedDocument.name)
    ) {
      setDocumentNameChangeShow(true);
    } else {
      const doc = { ...selectedDocument };
      if (folder.type === "folder") {
        doc.projectFolderId = folder.id;
      } else {
        doc.projectFolderId = null;
      }

      dispatch(manageProjectDocumentsActions.moveProjectDocument(doc));
      setMoveDocumentShow(false);
    }
  };

  const handleDocumentRename = name => {
    const doc = { ...selectedDocument };
    doc.name = name;
    if (folder.type === "folder") {
      doc.projectFolderId = folder.id;
    } else {
      doc.projectFolderId = null;
    }

    dispatch(manageProjectDocumentsActions.moveProjectDocument(doc));
    setMoveDocumentShow(false);
    setDocumentNameChangeShow(false);
  };

  const handleChange = event => {
    event.stopPropagation();
    setSearchString(event.target.value);
  };

  const handleProjectDocumentUpload = () => {
    dispatch(
      manageProjectDocumentsActions.getProjectFoldersAndDocuments(
        project,
        "project"
      )
    );
    dispatch(manageProjectActions.getProjectDocuments(project));
    setDroppedFiles([]);
    setAddClientDocumentShow(false);
  };

  const handleRestoreDocument = useCallback(
    ({ document }) => {
      dispatch(manageProjectDocumentsActions.restoreProjectDocument(document));
    },
    [dispatch]
  );

  const handleOpenDocumentForEdit = useCallback(
    document => {
      //figure out what type of document it is
      //then build the url for it
      //if smartform websheet
      if (document.properties?.actionItemTypeKey === "SMARTFORM") {
        window.open(
          `/projects/${document.projectId}/smartforms/${document.properties.queryId}/websheets/${document.id}`
        );
        return;
      } else if (document.id) {
        window.open(`/projects/${document.projectId}/documents/${document.id}`);
        return;
      }

      //if standalone document
      sessionStorage.setItem(
        "documentEditInfo",
        JSON.stringify({
          item: document,
          projectFolder: folder,
          project,
          title: document.name
        })
      );
      window.open(`${routeConstants.editDocument}`);
      sessionStorage.removeItem("documentEditInfo");
    },
    [folder, project]
  );

  const handleViewInRequest = useCallback(
    document => {
      const questionId = document?.properties?.questionId;
      navigateToRequestPage(
        document.properties?.queryId,
        document.projectId,
        document.properties?.actionItemTypeKey,
        {
          websheetOpensNewWindow: true,
          replace: false,
          hash: `questionId=${questionId}`
        }
      );
    },
    [navigateToRequestPage]
  );

  const handleDownloadDocument = useCallback(
    ({ document, cb }) => {
      dispatch(
        manageDocumentDownloadsActions.downloadDocumentRevision({
          ...document,
          cb
        })
      );
    },
    [dispatch]
  );

  const handleDocumentAction = documentAction => {
    setActiveDocumentAction(documentAction);
  };

  const handleDocumentActionFinished = () => {
    setActiveDocumentAction(null);
    handleCloseModal();
  };

  const hideTagEditor = useCallback(() => {
    setTagMessage("");
    setTagEditorShow(false);
  }, []);

  useEffect(() => {
    if (manageProjectDocuments.deletedDocument) {
      dispatch(manageProjectDocumentsActions.resetDeletedDocument());
      viewArchivedDocuments();
    }
  }, [dispatch, manageProjectDocuments.deletedDocument, viewArchivedDocuments]);

  useEffect(() => {
    if (manageProjectDocuments.revertedDocument) {
      dispatch(manageProjectDocumentsActions.resetRevertedDocument());
      viewArchivedDocuments();
    }
  }, [
    dispatch,
    manageProjectDocuments.revertedDocument,
    viewArchivedDocuments
  ]);

  useEffect(() => {
    if (searchString) {
      searchDocuments(project, searchString);
    }
  }, [project, searchDocuments, searchString]);

  useEffect(() => {
    if (searchString) {
      setFolder({
        id: "search",
        folders: [],
        documents: structuredClone(searchResults).map(document => {
          if (document.projectFolder) {
            document.pathName = `../${document.projectFolder.name}/${document.name}`;
          } else {
            document.pathName = document.name;
          }
          return document;
        })
      });
    } else {
      handleFolderSelection(currentFolder);
    }
  }, [searchString, searchResults, handleFolderSelection, currentFolder]);

  useEffect(() => {
    if (manageProjectDocuments.updatedProjectTag?.id) {
      fetchProjectTags(project.id);
      handleFolderSelection(currentFolder);
      setTagEditorShow(false);
    }
  }, [
    manageProjectDocuments.updatedProjectTag,
    dispatch,
    project,
    currentFolder,
    handleFolderSelection,
    fetchProjectTags
  ]);

  useEffect(() => {
    if (manageProjectDocuments.deletedProjectTag?.id) {
      dispatch(manageProjectDocumentsActions.getAllTags(project));
      handleFolderSelection(currentFolder);
      setTagEditorShow(false);
    }
  }, [
    manageProjectDocuments.deletedProjectTag,
    currentFolder,
    handleFolderSelection,
    dispatch,
    project
  ]);

  useEffect(() => {
    if (deleteProjectTagError) {
      setHandleTagSubmit(() => tag => deleteProjectTag(project, tag, true));
      setTagMessage(deleteProjectTagError);
      setEditType(editTagTypes.delete);
      setTagEditorShow(true);
    }
  }, [deleteProjectTag, deleteProjectTagError, project]);

  useEffect(() => {
    if (manageProjectDocuments.taggedDocuments) {
      setProjectFoldersAndDocuments(prevState => ({
        ...prevState,
        documents: manageProjectDocuments.taggedDocuments
      }));
      setFolder(prevState => ({
        ...prevState,
        documents: manageProjectDocuments.taggedDocuments
      }));
    }
  }, [manageProjectDocuments.taggedDocuments]);

  useEffect(() => {
    if (manageProjectDocuments.updatedDocument) {
      if (currentFolder.type === "tag") {
        viewTagDocuments(currentFolder.id);
      }
      dispatch(
        manageProjectDocumentsActions.getProjectFoldersAndDocuments(
          project,
          "project"
        )
      );
      dispatch(manageProjectDocumentsActions.resetUpdatedDocument());
    }
  }, [
    currentFolder.id,
    currentFolder.type,
    dispatch,
    manageProjectDocuments.updatedDocument,
    viewTagDocuments,
    project
  ]);

  useEffect(() => {
    if (showArchivedDocuments) {
      setFolder({
        id: "archivedFolder",
        folders: [],
        documents: structuredClone(
          manageProjectDocuments.archivedDocuments
        ).map(document => {
          if (document.projectFolder) {
            document.pathName = `../${document.projectFolder.name}/${document.name}`;
          } else {
            document.pathName = document.name;
          }
          return document;
        })
      });
    } else if (currentFolder.type === "tag") {
      viewTagDocuments(currentFolder.id);
    }
  }, [
    currentFolder.id,
    currentFolder.type,
    manageProjectDocuments.archivedDocuments,
    showArchivedDocuments,
    viewTagDocuments
  ]);

  const showTagEditor = useCallback((event, tag) => {
    event.stopPropagation();
    setTag(tag);
    setTagMessage("");
    setTagEditorShow(true);
  }, []);

  const handleTagUpdateSubmit = useCallback(
    tag => {
      dispatch(manageProjectDocumentsActions.updateProjectTag(project, tag));
    },
    [dispatch, project]
  );

  const handleAddTag = useCallback(event => {
    event.preventDefault();
    setShowAddTags(true);
  }, []);

  const onCancelAddTags = useCallback(() => {
    setShowAddTags(false);
  }, []);

  const onAddTagsSubmitted = useCallback(() => {
    setShowAddTags(false);
    fetchProjectTags(project.id);
  }, [fetchProjectTags, project.id]);

  const handleTagDismiss = useCallback(
    event => {
      event?.stopPropagation();
      hideTagEditor();
      resetDeleteProjectTag();
    },
    [hideTagEditor, resetDeleteProjectTag]
  );

  const handleEditTag = useCallback(
    tag => event => {
      event.preventDefault();
      event.stopPropagation();

      const shouldShowUpdateConfirmation = () => {
        const fpEnabled =
          project.configuration?.features?.finalPackage?.enabled;
        if (fpEnabled) {
          const signedPackageConfig =
            project.configuration?.finalPackage?.signedPackage;
          const isSignedLabel = tag.name === signedPackageConfig?.signedLabel;
          const isConfirmationNeeded =
            signedPackageConfig?.modifySignedLabel?.showConfirmationMessage;
          return isSignedLabel && isConfirmationNeeded;
        } else {
          return false;
        }
      };

      if (shouldShowUpdateConfirmation()) {
        setTag(tag);
        setShowEditSignedTagConfirmation(true);
      } else {
        setHandleTagSubmit(() => handleTagUpdateSubmit);
        setEditType(editTagTypes.edit);
        showTagEditor(event, tag);
      }
    },
    [
      handleTagUpdateSubmit,
      project.configuration?.features?.finalPackage?.enabled,
      project.configuration?.finalPackage?.signedPackage,
      showTagEditor
    ]
  );
  const handleDeleteTag = useCallback(
    tag => event => {
      event.preventDefault();
      event.stopPropagation();
      setTag(tag);
      deleteProjectTag(project, tag);
    },
    [deleteProjectTag, project]
  );

  const handleResetTag = useCallback(
    event => {
      handleFolderSelection(projectFoldersAndDocuments);
    },
    [handleFolderSelection, projectFoldersAndDocuments]
  );

  const onUpdateSignoffLabelCancelled = useCallback(() => {
    setShowEditSignedTagConfirmation(false);
  }, []);

  const onUpdateSignoffLabelConfirmed = useCallback(() => {
    setShowEditSignedTagConfirmation(false);
    setHandleTagSubmit(() => handleTagUpdateSubmit);
    setEditType(editTagTypes.edit);
    setTagEditorShow(true);
  }, [handleTagUpdateSubmit]);

  const bodySecondary = useMemo(() => {
    if (!project || !user) {
      return <></>;
    }
    return (
      <>
        <div className="ot-client-project-documents__body--index-body">
          {currentFolder.id === "requestFolder" ||
            currentFolder.id === "archivedFolder" ||
            currentFolder.type === "query" || (
              <UploadFileBox
                title={t("common:ui.documents.fileUpload.label")}
                project={project}
                boxClassName={"upload-file-box"}
                checkIsMember={true}
                tags={user.isHostUser ? projectTags : []}
                registerModal={registerModal}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
              />
            )}
        </div>
        <div className="ot-client-project-documents__body--index-body">
          <IndexList
            foldersAndDocuments={[projectFoldersAndDocuments]}
            selectedFolder={folder}
            tags={projectTags}
            canAddTag={user?.isHostUser}
            handleFolderSelection={handleFolderSelection}
            handleAddTag={handleAddTag}
            handleEditTag={handleEditTag}
            handleDeleteTag={handleDeleteTag}
            handleResetTag={handleResetTag}
            isArchivedFolder={isArchivedFolder}
            tagUiConfig={tagUiConfig}
          />
        </div>
      </>
    );
  }, [
    currentFolder,
    folder,
    handleAddTag,
    handleCloseModal,
    handleDeleteTag,
    handleEditTag,
    handleFolderSelection,
    handleOpenModal,
    handleResetTag,
    isArchivedFolder,
    project,
    projectFoldersAndDocuments,
    projectTags,
    registerModal,
    t,
    tagUiConfig,
    user
  ]);

  const getError = useCallback(() => {
    if (error) {
      return error;
    } else if (
      (!tagEditorShow && manageProjectDocuments.error) ||
      manageDocumentDownloads.error
    ) {
      return manageProjectDocuments.error || manageDocumentDownloads.error;
    } else return "";
  }, [
    error,
    manageDocumentDownloads.error,
    manageProjectDocuments.error,
    tagEditorShow
  ]);

  const bodyPrimary = useMemo(() => {
    if (!project || !user) {
      return <></>;
    }
    return (
      <div className="ot-client-project-documents__body--documents">
        {isArchivedFolder ? (
          <div className="ot-client-project-documents__subheading">
            {t("common:ui.documents.deletedDocuments.label")}
          </div>
        ) : (
          <div className="ot-client-project-documents__search-box">
            <div className="ot-client-project-documents__search-box-input">
              <TextInput
                label={t("common:ui.documents.searchLabel")}
                value={searchString}
                onChange={handleChange}
              />
              <i className="material-icons">search</i>
            </div>
            <BrandButton
              label={t("common:ui.documents.resetButtonLabel")}
              type="text-dark"
              onClick={handleResetFilters}
            />
          </div>
        )}

        <DocumentsTable
          folder={folder}
          project={project}
          handleFolderSelection={handleFolderSelection}
          handleRestoreDocument={handleRestoreDocument}
          handleOpenDocumentForEdit={handleOpenDocumentForEdit}
          handleDownloadDocument={handleDownloadDocument}
          handleEditDocument={handleDocumentAction}
          handleUploadDocumentRevision={handleDocumentAction}
          handleUpdateDocumentProperties={handleDocumentAction}
          handleReviewRevisionHistory={handleDocumentAction}
          handleDeleteDocument={handleDocumentAction}
          handleEditDocumentTags={handleDocumentAction}
          handleReviewReviewHistory={handleDocumentAction}
          handleViewInRequest={handleViewInRequest}
          downloadingErrorDocumentId={manageDocumentDownloads.errorDocumentId}
        />
      </div>
    );
  }, [
    folder,
    handleDownloadDocument,
    handleFolderSelection,
    handleOpenDocumentForEdit,
    handleResetFilters,
    handleRestoreDocument,
    handleViewInRequest,
    isArchivedFolder,
    manageDocumentDownloads.errorDocumentId,
    project,
    searchString,
    t,
    user
  ]);

  const downloadAllLabel = useMemo(() => {
    if (isDownloading) {
      return (
        <div className="download-documents--container">
          <LoaderSpinner diameter="25"></LoaderSpinner>
          <>
            {t("common:ui.documents.downloadAllLabel", {
              context: "downloading"
            })}
          </>
        </div>
      );
    } else {
      return <>{t("common:ui.documents.downloadAllLabel")}</>;
    }
  }, [isDownloading, t]);

  return (
    <>
      <PageTemplate
        header={{
          title: t("ui.documents.navigation.title"),
          actions: (
            <BrandButton
              type="primary"
              onClick={handleDownloadAll}
              iconName={!isDownloading ? "download" : ""}
              label={downloadAllLabel}
            />
          )
        }}
        body={{
          primary: bodyPrimary,
          secondary: bodySecondary,
          secondaryWidth: "40vw"
        }}
        other={{
          smallPageSize: 1600,
          error: getError(),
          project
        }}
        classNames={{
          page: "ot-client-project-documents"
        }}
        modal={{
          open: modalOpen,
          content: getModalContent()
        }}
      />
      {activeDocumentAction?.action && (
        <ProjectDocumentsTableActions
          action={activeDocumentAction}
          onFinished={handleDocumentActionFinished}
        />
      )}
      <Popup visibility={showAddTags} handleOutsideClick={false} width="60rem">
        <AddOrCopyProjectTags
          projectId={project.id}
          existingTags={projectTags}
          engagementId={project.engagementId}
          onCancel={onCancelAddTags}
          onSubmitted={onAddTagsSubmitted}
        />
      </Popup>

      <Popup visibility={tagEditorShow} width="60rem">
        <TagEditor
          existingTag={tag}
          editType={editType}
          handleSubmit={handleTagSubmit}
          handleDismiss={handleTagDismiss}
          message={tagMessage}
        />
      </Popup>
      <Popup
        visibility={createFolderShow}
        handleOutsideClick={false}
        width="60rem"
      >
        <CreateFolder
          project={project}
          parentFolderId={folder.type === "folder" ? folder.id : null}
          onCreate={handlePopupOutsideClick}
          onCancel={handlePopupOutsideClick}
        />
      </Popup>

      <Popup
        visibility={addClientDocumentShow}
        handleOutsideClick={false}
        width="60rem"
      >
        <AddClientProjectDocument
          project={project}
          parentFolderId={folder.type === "folder" ? folder.id : null}
          droppedFiles={droppedFiles}
          tags={user?.isHostUser ? projectTags : []}
          onUpload={handleProjectDocumentUpload}
          onCancel={handleCancelOnAddClientDocument}
        />
      </Popup>

      <Popup
        visibility={moveDocumentShow}
        handleOutsideClick={false}
        width="60rem"
      >
        <WarningMessage
          title={"Move document"}
          message={`Are you sure you want to move document ${
            selectedDocument && selectedDocument.name
          } to this folder?`}
          onYes={handleMoveClick}
          onCancel={handlePopupOutsideClick}
        />
      </Popup>

      <Popup
        visibility={showEditSignedTagConfirmation}
        handleOutsideClick={false}
        width="60rem"
      >
        <EditSignedLabelConfirmationModal
          project={project}
          onSubmit={onUpdateSignoffLabelConfirmed}
          handleDismiss={onUpdateSignoffLabelCancelled}
        />
      </Popup>

      <Popup
        visibility={documentNameChangeShow}
        handleOutsideClick={false}
        width="60rem"
      >
        <DocumentNameChange
          message={`Document with same name already exist in this folder, would you like to rename?`}
          document={selectedDocument}
          onRename={handleDocumentRename}
          onCancel={handlePopupOutsideClick}
        />
      </Popup>
    </>
  );
};

ManageClientProjectDocuments.defaultProps = {};

ManageClientProjectDocuments.propTypes = {
  project: PropTypes.object.isRequired,
  handleDownloadAll: PropTypes.func,
  isDownloading: PropTypes.bool,
  error: PropTypes.string
};

export default ManageClientProjectDocuments;
