import React from "react";

import PropTypes from "prop-types";

import BrandButton from "@components/atoms/Button/BrandButton";

import "./ArrowNavigationButtonGroup.scss";

const ArrowNavigationButtonGroup = ({
  onClickPrevious,
  disablePrevious,
  disableNext,
  onClickNext,
  type = "text"
}) => {
  return (
    <span className="arrow-navigation-button-group">
      <BrandButton
        size="small"
        type={type}
        iconName={"arrow_back"}
        disabled={disablePrevious}
        onClick={onClickPrevious}
      />
      <BrandButton
        size="small"
        type={type}
        iconName={"arrow_forward"}
        disabled={disableNext}
        onClick={onClickNext}
      />
    </span>
  );
};
ArrowNavigationButtonGroup.propTypes = {
  disablePrevious: PropTypes.bool,
  disableNext: PropTypes.bool,
  onClickPrevious: PropTypes.func,
  onClickNext: PropTypes.func,
  type: PropTypes.string
};

export default ArrowNavigationButtonGroup;
