import React, { useCallback, useEffect, useState } from "react";

import * as CSV from "@vanillaes/csv";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { routeConstants } from "@constants";

import {
  manageClientEngagementProjectsActions,
  manageProjectActions
} from "@shared/actions";
import { milestoneUtilities } from "@shared/helpers/milestoneUtilities";
import { utilities } from "@shared/helpers/utilities";
import { useFeatures, useGetProjectByIdQuery } from "@shared/hooks";

import ErrorBox from "@shared-components/errorBox/ErrorBox";

import { parseEntities, validateEntities } from "@app/helpers/entity";
import { getErrorMessage } from "@app/helpers/error";

import Button from "@components/atoms/Button/BrandButton";
import DatePicker from "@components/atoms/DatePicker";
import DropdownInput from "@components/atoms/DropdownInput";
import TextAreaInput from "@components/atoms/TextAreaInput";
import TextInput from "@components/atoms/TextInput";
import UploadFile from "@components/molecules/UploadFile";
import MainPageTemplate from "@components/templates/MainPageTemplate";
import PageBodyTemplate from "@components/templates/PageBodyTemplate";

import "./AddOrUpdateClientEngagementProject.scss";
import ProjectYearField from "./ProjectYearField";

/**
 * Serialize an array of entities to a string
 * @param {Entity[]} entities
 * @returns {string} the string representation
 */
const entitiesToString = entities =>
  CSV.stringify(entities?.map(({ name, externalId }) => [name, externalId]));

const AddOrUpdateClientEngagementProject = ({ t }) => {
  const currentYear = new Date().getFullYear().toString();
  const [submit, setSubmit] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const location = useLocation();
  const engagement = location.state?.engagement;
  const [project, setProject] = useState({
    name: "",
    engagement,
    engagementId: engagement?.id,
    breadcrumbs: [{ label: "" }]
  });
  const [isEntityEnabled, setEntityEnabled] = useState();
  const [isYearFieldEnabled, setYearFieldEnabled] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const authentication = useSelector(state => state.authentication);
  const manageClientEngagementProjects = useSelector(
    state => state.manageClientEngagementProjects
  );
  const manageProject = useSelector(state => state.manageProject);
  const { isProjectManagerFieldEnabled } = useFeatures();
  const [projectManagers, setProjectManagers] = useState([]);
  const [fillUsers, setFillUsers] = useState(true);
  const [error, setError] = useState(false);
  const [customError, setCustomError] = useState(null);
  const inputFocusRef = React.createRef();
  const { refetch: refetchProject } = useGetProjectByIdQuery({ projectId });

  useEffect(() => {
    if (projectId && projectId !== project.id) {
      dispatch(manageProjectActions.getProjectById(projectId));
    } else {
      setEntityEnabled(
        engagement?.engagementType?.configuration?.entities?.enabled
      );
      setYearFieldEnabled(
        engagement?.engagementType?.configuration?.project?.yearField?.enabled
      );
      if (location.state?.project) {
        const p = location.state?.project;
        setProject({
          ...p,
          plannedCompletionDate: utilities.getLastMilestoneDate(p.milestones),
          startDate: utilities.getFirstMilestoneDate(p.milestones),
          engagement,
          engagementId: engagement.id,
          milestones: p.milestones,
          entities: entitiesToString(p.entities ?? []),
          parsedEntities: p.entities ?? [],
          year: p.year,
          update: true
        });
      } else {
        setProject({
          name: "",
          engagement,
          engagementId: engagement?.id,
          manager: { id: null, name: "" },
          startDate: "",
          plannedCompletionDate: "",
          entities: "",
          parsedEntities: [],
          year: currentYear,
          update: false
        });
      }
    }
  }, [
    dispatch,
    location.state?.engagement,
    location.state?.project,
    project.configuration?.entities?.enabled,
    project.id,
    projectId,
    engagement,
    currentYear
  ]);

  useEffect(() => {
    setError(getErrorMessage(manageClientEngagementProjects.error, t));
  }, [manageClientEngagementProjects.error, t]);

  useEffect(() => {
    if (projectId && manageProject.currentProject) {
      const p = manageProject.currentProject;
      const engagement = {
        // put back the config on the engagementType as it's missing from engagement
        ...p.engagement,
        engagementType: { configuration: p.configuration }
      };
      setEntityEnabled(p.configuration?.entities?.enabled);
      setYearFieldEnabled(p.configuration?.project?.yearField?.enabled);

      setProject({
        id: p.id,
        name: p.name,
        engagement,
        engagementId: engagement?.id,
        manager: p.projectManager,
        plannedCompletionDate: utilities.getLastMilestoneDate(p.milestones),
        startDate: utilities.getFirstMilestoneDate(p.milestones),
        entities: entitiesToString(p.entities ?? []),
        parsedEntities: p.entities ?? [],
        year: p.year,
        update: true,
        client: p.client
      });
    }
  }, [manageProject.currentProject, projectId]);

  const clearError = useCallback(() => {
    if (manageClientEngagementProjects.error) {
      dispatch(manageClientEngagementProjectsActions.clearError());
    }
    setError(false);
    setCustomError(null);
  }, [dispatch, manageClientEngagementProjects.error]);

  const validateForm = useCallback(() => {
    if (!project.name) {
      return t("common:ui.projects.name.error");
    } else if (
      isProjectManagerFieldEnabled &&
      (!project.manager || !project.manager.id)
    ) {
      return t("common:ui.projects.projectManager.error");
    } else if (!project.startDate) {
      return t("common:ui.projects.startDate.error");
    } else if (!project.plannedCompletionDate) {
      return t("common:ui.projects.endDate.error.defined");
    } else if (isEntityEnabled) {
      const parsedEntities = parseEntities(project.entities, t);
      const validation = validateEntities(parsedEntities, t);
      if (validation?.error) {
        return validation.error.message;
      }
    }
    return false;
  }, [
    isEntityEnabled,
    project.entities,
    project.manager,
    project.name,
    project.plannedCompletionDate,
    project.startDate,
    isProjectManagerFieldEnabled,
    t
  ]);

  const handleUpdate = useCallback(() => {
    clearError();
    const error = validateForm();
    if (error) {
      setError(error);
    } else {
      if (isProjectManagerFieldEnabled) {
        project.projectManagerId = project.manager.id;
      }
      dispatch(manageClientEngagementProjectsActions.updateProject(project));
      setSubmit(true);
    }
  }, [
    clearError,
    dispatch,
    project,
    validateForm,
    isProjectManagerFieldEnabled
  ]);

  const handleSubmit = useCallback(() => {
    clearError();
    const error = validateForm();
    if (error) {
      setError(error);
    } else if (
      project.startDate &&
      project.plannedCompletionDate &&
      !moment(project.startDate).isSameOrBefore(project.plannedCompletionDate)
    ) {
      dispatch(
        manageClientEngagementProjectsActions.setError(
          t("common:ui.projects.endDate.error.later")
        )
      );
    } else {
      project.projectManagerId = project.manager.id;
      dispatch(
        manageClientEngagementProjectsActions.addClientEngagementProject(
          project,
          fillUsers
        )
      );
      setSubmit(true);
    }
  }, [clearError, dispatch, fillUsers, project, validateForm, t]);

  useEffect(() => {
    inputFocusRef.current?.focus();
  }, [inputFocusRef]);

  useEffect(() => {
    if (
      !submit &&
      project.name?.length > 0 &&
      (!isProjectManagerFieldEnabled || project.manager?.id) &&
      (!isYearFieldEnabled || project.year) &&
      project.startDate &&
      project.plannedCompletionDate &&
      !error &&
      !customError
    ) {
      setEnableSubmit(true);
    } else {
      setEnableSubmit(false);
    }
  }, [
    submit,
    project,
    error,
    customError,
    isProjectManagerFieldEnabled,
    isYearFieldEnabled
  ]);
  useEffect(() => {
    dispatch(
      manageClientEngagementProjectsActions.getProjectManagers(
        authentication.user
      )
    );
    return () => {
      project.update
        ? dispatch(
            manageClientEngagementProjectsActions.clearUpdateClientEngagementProjectFlag()
          )
        : dispatch(
            manageClientEngagementProjectsActions.clearAddClientEngagementProjectFlag()
          );
    };
  }, [authentication.user, dispatch, project.update]);

  useEffect(() => {
    if (manageClientEngagementProjects.error) {
      setSubmit(false);
    }
  }, [manageClientEngagementProjects.error]);

  useEffect(() => {
    setProjectManagers(
      structuredClone(manageClientEngagementProjects.projectManagers).sort(
        (a, b) => a.name.localeCompare(b.name)
      )
    );
  }, [manageClientEngagementProjects.projectManagers]);

  useEffect(() => {
    if (manageClientEngagementProjects.addedProject) {
      navigate(`${routeConstants.engagements}/${project.engagementId}`);
    }
  }, [
    navigate,
    manageClientEngagementProjects.addedProject,
    project.engagementId
  ]);

  useEffect(() => {
    if (
      manageClientEngagementProjects.updatedProject &&
      manageClientEngagementProjects.updatedProject?.status !== 400
    ) {
      refetchProject();
      navigate(`${routeConstants.engagements}/${project.engagementId}`);
    }
  }, [
    navigate,
    manageClientEngagementProjects.updatedProject,
    project.engagementId,
    refetchProject
  ]);

  const handleProjectNameChange = event => {
    setProject(prev => ({ ...prev, name: event.target.value }));
  };

  const handleProjectManagerNameChange = value => {
    clearError();
    setProject(prev => ({
      ...prev,
      manager: {
        ...prev.manager,
        name: value.name,
        id: value.id,
        projectManagerGroupId: null
      }
    }));
  };

  const handleStartDateChange = date => {
    clearError();
    const dt = date ? moment(date).endOf("day").toDate() : "";
    setProject(prev => ({ ...prev, startDate: dt }));
  };

  const handleEndDateChange = date => {
    clearError();
    const dt = date ? moment(date).endOf("day").toDate() : "";
    setProject(prev => ({ ...prev, plannedCompletionDate: dt }));
  };

  const handleEntitiesChange = useCallback(
    event => {
      const entities = event.target.value;
      const parsedEntities = parseEntities(entities, t);
      const validation = validateEntities(parsedEntities, t);
      if (validation?.error) {
        setProject(prev => ({ ...prev, entities }));
        setError(validation.error.message);
      } else {
        clearError();
        setProject(prev => ({ ...prev, entities, parsedEntities }));
      }
    },
    [t, clearError]
  );

  const handleFillUsersClick = event => {
    event.stopPropagation();
    setFillUsers(!fillUsers);
  };

  const handleYearChange = useCallback(
    selectedYear => {
      clearError();
      setProject(prev => ({ ...prev, year: selectedYear }));
    },
    [clearError]
  );

  const handleDrop = useCallback(
    acceptedFiles => {
      acceptedFiles.forEach(acceptedFile => {
        const reader = new FileReader();
        reader.onload = () => {
          const binaryStr = reader.result;
          const removeHeader = () => {
            const formatted = CSV.parse(binaryStr).splice(1);
            return CSV.stringify(formatted);
          };
          const parsedEntities = parseEntities(removeHeader(), t);
          const validation = validateEntities(parsedEntities, t);
          if (validation?.error) {
            setProject(prev => ({ ...prev, entities: removeHeader() }));
            setError(validation.error.message);
          } else {
            clearError();
            setProject(prev => ({
              ...prev,
              entities: removeHeader(),
              parsedEntities
            }));
          }
        };
        reader.readAsText(acceptedFile);
      });
    },
    [clearError, t]
  );

  const handleDropReject = () => {
    setError(t("common:ui.projects.entities.fileUpload.errorMessage"));
  };

  const engagementConfig = project.engagement?.engagementType?.configuration;

  return (
    <MainPageTemplate project={project}>
      <PageBodyTemplate
        breadcrumbs={[
          {
            linkTo: routeConstants.manageClientEngagements,
            label: t("common:ui.engagement.navigation.title")
          },
          {
            linkTo: {
              pathname: `${routeConstants.engagements}/${engagement?.id}`
            },
            label: project.engagement?.name
          }
        ]}
        title={t("common:ui.projects.title", {
          context: project.update ? "update" : "add"
        })}
      >
        <div className="add-client-engagement-project__main">
          {manageClientEngagementProjects.error && (
            <div className="add-client-engagement-project__error-box">
              <ErrorBox message={manageClientEngagementProjects.error} />
            </div>
          )}

          <div className="add-client-engagement-project__container">
            <div className="update-project-form">
              <TextInput
                disabled={false}
                value={project.name}
                label={t("common:ui.projects.name.label")}
                placeholder={t("common:ui.projects.name.placeholder")}
                onChange={handleProjectNameChange}
                maxLength={255}
              />
            </div>
            {isYearFieldEnabled && (
              <div className="update-project-form">
                <ProjectYearField
                  label={t("common:ui.projects.year.label")}
                  defaultValue={project ? project.year : currentYear}
                  onChange={handleYearChange}
                />
              </div>
            )}
            {isProjectManagerFieldEnabled && (
              <div className="update-project-form">
                <DropdownInput
                  items={projectManagers}
                  label={t("common:ui.projects.manager")}
                  onChange={handleProjectManagerNameChange}
                  error={null}
                  value={{ name: project.manager?.name || "" }}
                />
              </div>
            )}
            {!project.update && (
              <>
                <div className="update-project-form">
                  <DatePicker
                    value={project.startDate}
                    onChange={handleStartDateChange}
                    label={milestoneUtilities.renderStartLabel(
                      engagementConfig,
                      t
                    )}
                    maxDate={project.plannedCompletionDate ?? null}
                  />
                </div>
                <div className="update-project-form">
                  <DatePicker
                    value={project.plannedCompletionDate}
                    onChange={handleEndDateChange}
                    label={milestoneUtilities.renderEndLabel(
                      engagementConfig,
                      t
                    )}
                    minDate={project.startDate ?? null}
                  />
                </div>
              </>
            )}
            {isEntityEnabled && (
              <div className="update-project-form">
                <TextAreaInput
                  value={project.entities}
                  onChange={handleEntitiesChange}
                  label={t("common:ui.projects.entities.label")}
                  placeholder={t("common:ui.projects.entities.placeholder")}
                />
              </div>
            )}
            {isEntityEnabled && (
              <div className="update-project-form">
                <UploadFile
                  handleDrop={handleDrop}
                  supportedDocumentMimes={["csv"]}
                  disabled={false}
                  dropMessage={t(
                    "common:ui.projects.entities.fileUpload.dropMessage"
                  )}
                  linkName={t("common:ui.forms.fileUpload.browseLabel")}
                  label={t("common:ui.projects.entities.fileUpload.label")}
                  supportedDocumentMimesMessage={t(
                    "common:ui.projects.entities.fileUpload.errorMessage"
                  )}
                  hideUpload={false}
                  handleRejection={handleDropReject}
                  maxNumberOfFiles={1}
                  maxNumberOfFilesError={t(
                    "common:ui.projects.entities.fileUpload.errorMessage"
                  )}
                />
              </div>
            )}
            {!project.update && (
              <div className="copy-users">
                <label className="copy-users__label">
                  {t("common:ui.projects.copyUsers")}
                </label>
                <div
                  className="copy-users-label__checkbox"
                  onClick={handleFillUsersClick}
                >
                  <i className="material-icons">
                    {fillUsers ? "check_box" : "check_box_outline_blank"}
                  </i>
                </div>
              </div>
            )}

            {error && (
              <div className="add-client-engagement-project__error-box">
                <ErrorBox message={error} customMessage={customError} />
              </div>
            )}

            <div className="add-client-engagement-project__button">
              <Link
                to={`${routeConstants.engagements}/${project.engagementId}`}
                className="btn btn-link"
              >
                {t("ui.forms.cancel.label")}
              </Link>
              <Button
                type="primary"
                disabled={!enableSubmit}
                label={t("common:ui.projects.title", {
                  context: project.update ? "update" : "add"
                })}
                onClick={project.update ? handleUpdate : handleSubmit}
              />
            </div>
          </div>
        </div>
      </PageBodyTemplate>
    </MainPageTemplate>
  );
};

export default withTranslation()(AddOrUpdateClientEngagementProject);
