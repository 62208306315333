import React, { useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";

import Checklist from "@components/atoms/Checklist";

import "./InteractiveReportFilterDropdown.scss";

const InteractiveReportFilterDropdown = props => {
  const { items, onChange } = props;
  const [isColumnFilterVisible, setIsColumnFilterVisible] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);

  useEffect(() => {
    setCheckedItems(items.filter(i => !i.hidden).map(i => i.name));
  }, [items]);

  const dropdownItems = useMemo(() => {
    return items.map((item, index) => {
      const isChecked = checkedItems.includes(item.name);
      return {
        ...item,
        isChecked,
        id: index
      };
    });
  }, [items, checkedItems]);

  const toggleColumnDropdown = () => {
    setIsColumnFilterVisible(prev => !prev);
  };

  const toggleItem = item => {
    const updatedItems = new Set([...checkedItems]);
    const isChecked = updatedItems.has(item.name);
    if (isChecked) {
      updatedItems.delete(item.name);
    } else {
      updatedItems.add(item.name);
    }
    items[item.id].hidden = isChecked;
    items[item.id].hiddenByFilter = isChecked; // used as hint to let others know this is hidden by a filter
    setCheckedItems(_p => [...updatedItems]);
    onChange(items);
  };

  return (
    <div className="ot-interactive-report-dropdown-filter">
      <span
        className="filter-icon material-symbols-outlined"
        onClick={toggleColumnDropdown}
      >
        visibility
      </span>

      {isColumnFilterVisible && dropdownItems.length && (
        <div className="dropdown">
          <Checklist items={dropdownItems} toggleItem={toggleItem} />
        </div>
      )}
    </div>
  );
};

InteractiveReportFilterDropdown.prototypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      hidden: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  onChange: PropTypes.func.isRequired
};

export default InteractiveReportFilterDropdown;
