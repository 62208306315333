import React, { useCallback, useEffect, useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { systemConstants } from "@shared/constants/systemConstants";
import { utilities } from "@shared/helpers/utilities";

import BrandButton from "@components/atoms/Button/BrandButton";
import InlineAlert from "@components/atoms/InlineAlert/InlineAlert";
import ModalForm from "@components/molecules/ModalForm/ModalForm";

import MilestoneField from "./MilestoneField/MilestoneField";
import "./MilestonesForm.scss";

const milestoneConstants = systemConstants.project.milestone;
const MilestonesForm = props => {
  const { milestones, handleCancel, handleSubmit, projectId, milestoneConfig } =
    props;
  const { i18n, t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);

  const getDefaultItem = useCallback(
    milestone => {
      if (milestone.properties?.i18nOptions) {
        return {
          name: t(milestone.name),
          value: milestone.name
        };
      }
      return milestone.name
        ? {
            name: milestone.name
          }
        : null;
    },
    [t]
  );

  const defaultValues = useMemo(() => {
    //name should be translated only if its text-input, else key needs to be preserved
    const getName = milestone => {
      if (!milestoneConfig?.labels?.enabled) {
        return { name: utilities.getProjectMilestoneName(milestone, i18n) };
      }
      return {
        name: getDefaultItem(milestone)
      };
    };

    return {
      milestoneForm: milestones.map(milestone => ({
        ...milestone,
        date: utilities.endOfDay(milestone.date),
        completed: milestone.status === milestoneConstants.status.completed,
        ...getName(milestone)
      }))
    };
  }, [getDefaultItem, i18n, milestoneConfig?.labels?.enabled, milestones]);

  const yupSchema = useMemo(
    () =>
      yup.object({
        milestoneForm: yup
          .array()
          .of(
            yup.object().shape({
              name: yup
                .mixed()
                .transform(value => (value ? value : null))
                .required(t("common:ui.milestone.form.name.error")),
              date: yup
                .date()
                .typeError(t("common:ui.milestone.form.date.error.required")),
              completed: yup
                .boolean()
                .test("is-all-complete", "Error", function (_) {
                  const fields = this.from[1].value.milestoneForm;
                  return (
                    !fields[fields.length - 1].completed ||
                    fields.every(f => f.completed)
                  );
                })
            })
          )
          .test("date-check", "Error", values => {
            const lastDate = values[values.length - 1].date;
            const firstDate = values[0].date;

            for (let i = 0; i < values.length; i++) {
              const currDate = values[i].date;
              if (currDate > lastDate || currDate < firstDate) {
                return false;
              }
            }
            return true;
          })
      }),
    [t]
  );

  const resolver = useCallback(
    async (data, context, options) => {
      const result = await yupResolver(yupSchema)(data, context, options);
      return result;
    },
    [yupSchema]
  );

  const methods = useForm({
    defaultValues,
    mode: "onChange",
    resolver
  });

  const watchFormValues = useWatch({
    name: "milestoneForm",
    control: methods.control
  });

  const { fields, remove, insert } = useFieldArray({
    control: methods.control,
    name: "milestoneForm"
  });

  useEffect(() => {
    //If any field date is smaller than start milestones date, set that to start milestone date
    const startMilestone = watchFormValues.find(
      f => f.type === milestoneConstants.type.start
    );
    if (startMilestone) {
      for (let i = 0; i < watchFormValues.length; i++) {
        if (watchFormValues[i].date < startMilestone?.date) {
          methods.setValue(`milestoneForm.${i}.date`, startMilestone?.date);
        }
        setMinDate(startMilestone.date);
      }
    }

    //If any field date is greater than end milestones date, throw error for that field
    const endMilestone = watchFormValues.find(
      f => f.type === milestoneConstants.type.end
    );
    if (endMilestone) {
      for (let i = 0; i < watchFormValues.length; i++) {
        if (watchFormValues[i].date > endMilestone.date) {
          methods.setError(`milestoneForm.${i}.date`, {
            type: "date-order",
            message: t("common:ui.milestone.form.date.error.lateDate")
          });
        } else {
          methods.clearErrors(`milestoneForm.${i}.date`);
        }
      }
      setMaxDate(endMilestone.date);
    }
  }, [methods, t, watchFormValues]);

  const milestoneItems = useMemo(() => {
    if (!milestoneConfig?.labels?.enabled) {
      return [];
    }
    const milestoneItems = milestoneConfig?.labels?.names?.map(m => ({
      name: t(m.key),
      value: m.key
    }));
    const selectedItems = watchFormValues
      .map(f => f.name?.value)
      .filter(f => f);
    return (
      milestoneItems?.filter(item => !selectedItems.includes(item.value)) ?? []
    );
  }, [milestoneConfig?.labels, t, watchFormValues]);

  const milestoneField = useCallback(
    (item, index) => {
      const isItemCompleted = methods.watch(`milestoneForm.${index}.completed`);
      const isDeleteVisible =
        ![milestoneConstants.type.start, milestoneConstants.type.end].includes(
          item.type
        ) && !isItemCompleted;

      return (
        <MilestoneField
          id={item.id}
          defaultName={item.name}
          isItemCompleted={isItemCompleted}
          isDeleteVisible={isDeleteVisible}
          index={index}
          minDate={item.type === milestoneConstants.type.start ? null : minDate}
          maxDate={item.type === milestoneConstants.type.end ? null : maxDate}
          register={methods.register}
          handleDelete={remove}
          labelConfigEnabled={milestoneConfig?.labels?.enabled}
          labelConfigItems={milestoneItems}
          key={index}
        />
      );
    },
    [
      maxDate,
      methods,
      milestoneConfig?.labels?.enabled,
      milestoneItems,
      minDate,
      remove
    ]
  );

  useEffect(() => {
    if (
      watchFormValues[watchFormValues.length - 1].completed &&
      watchFormValues.some(f => !f.completed)
    ) {
      setErrorMessage(
        "common:ui.milestone.form.date.error.milestoneCompletion"
      );
    } else {
      setErrorMessage("");
    }
  }, [watchFormValues]);

  const newMilestoneData = date => ({
    name: null,
    date,
    type: milestoneConstants.type.standard,
    status: milestoneConstants.status.open,
    projectId,
    completed: false
  });

  const numberOfMilestones = watchFormValues.length;
  const finalMilestoneCompleted =
    watchFormValues[numberOfMilestones - 1]?.completed;

  const handleAddMilestone = () => {
    const index = numberOfMilestones - 1;
    insert(index, newMilestoneData(watchFormValues[index - 1].date), {
      focusName: `milestoneForm.${index}.name`
    });
  };

  const disableAddMilestone = useMemo(() => {
    if (!milestoneConfig?.labels?.enabled) {
      return false;
    }
    const selectedItems = new Set(
      watchFormValues.map(f => f.name?.value).filter(v => v)
    );
    return (
      milestoneConfig.labels.names?.every(v => selectedItems.has(v.key)) ?? true
    );
  }, [milestoneConfig?.labels, watchFormValues]);

  return (
    <FormProvider {...methods}>
      <ModalForm
        title={t("common:ui.milestone.title")}
        handleSubmit={data => handleSubmit(data.milestoneForm)}
        handleCancel={handleCancel}
        boxClassName="milestones-form"
        withoutProvider={true}
        formMethods={methods}
        customResolver={resolver}
      >
        {fields.map(milestoneField)}
        <div className="milestones-form--footer">
          {!finalMilestoneCompleted && (
            <BrandButton
              iconName="add_circle"
              label={t("common:ui.milestone.add")}
              onClick={handleAddMilestone}
              type="text-accent"
              iconSize="medium"
              disabled={disableAddMilestone}
            />
          )}
          {errorMessage && (
            <InlineAlert type="error" message={t(errorMessage)} />
          )}
        </div>
      </ModalForm>
    </FormProvider>
  );
};

MilestonesForm.defaultProps = {
  milestones: []
};

MilestonesForm.propTypes = {
  milestones: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      status: PropTypes.oneOf(Object.values(milestoneConstants.status)),
      completed: PropTypes.bool,
      date: PropTypes.string
    })
  ).isRequired,
  projectId: PropTypes.number,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  milestoneConfig: PropTypes.object
};

export default MilestonesForm;
