import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

function HiddenInput(props) {
  const { name, value } = props;
  const { register, setValue } = useFormContext();

  useEffect(() => {
    setValue(name, value);
  }, [name, setValue, value]);

  return <input {...register(name, { value })} hidden />;
}

HiddenInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any
};

export default HiddenInput;
