import { useCallback } from "react";

import { systemConstants } from "@shared/constants";
import { useGetFeaturesQuery } from "@shared/services/featuresService";

export function useFeatures() {
  const { data: features, isLoading } = useGetFeaturesQuery();

  const isEnabled = useCallback(name => !!features?.[name], [features]);

  const {
    projectManagerField,
    engagementManagerField,
    projectRisks,
    interactiveReport,
    downloadWebsheet
  } = systemConstants.features;

  return {
    isLoading,
    isEnabled,
    isProjectManagerFieldEnabled: isEnabled(projectManagerField),
    isEngagementManagerFieldEnabled: isEnabled(engagementManagerField),
    isRiskFlagEnabled: isEnabled(projectRisks),
    isInteractiveReportEnabled: isEnabled(interactiveReport),
    isDownloadWebsheetEnabled: isEnabled(downloadWebsheet)
  };
}
