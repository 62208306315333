import { useCallback, useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { manageClientUsersActions } from "@shared/actions/manageClientUsersActions";
import { useUpdateClientUserMutation } from "@shared/services";

const status = {
  active: "ACTIVE",
  inactive: "INACTIVE"
};

export function useUpdateClientUserStatus(parentClient) {
  const [client, setClient] = useState(parentClient);
  const dispatch = useDispatch();
  const [updateClientUser, { isSuccess: updatedUser }] =
    useUpdateClientUserMutation();

  useEffect(() => {
    setClient(parentClient);
  }, [parentClient]);

  useEffect(() => {
    if (updatedUser && client) {
      dispatch(manageClientUsersActions.clearUpdateClientUserFlag());
      dispatch(manageClientUsersActions.getClientUsers(client));
    }
  }, [dispatch, updatedUser, client]);

  const activateClientUser = useCallback(
    ({ id: clientUserId }) => {
      const userUpdate = { id: clientUserId, status: status.active };
      updateClientUser({ user: userUpdate, client });
    },
    [updateClientUser, client]
  );

  const deactivateClientUser = useCallback(
    ({ id: clientUserId }) => {
      const userUpdate = { id: clientUserId, status: status.inactive };
      updateClientUser({ user: userUpdate, client });
    },
    [updateClientUser, client]
  );

  return {
    activateClientUser,
    deactivateClientUser
  };
}
