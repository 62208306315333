import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useGetClientProjectsForMenuQuery } from "@shared/hooks";

import { menuConstants, routeConstants } from "@app/constants";

import DropdownInput from "@components/atoms/DropdownInput/DropdownInput";
import Icon from "@components/atoms/Icon";
import NavHeader from "@components/atoms/NavHeader";
import SecondaryNavSectionTitle from "@components/atoms/SecondaryNavSectionTitle/SecondaryNavSectionTitle";
import SecondaryNavItem from "@components/molecules/SecondaryNavItem";

import "./SecondaryNavTemplate.scss";

const formatProjectItems = projects => {
  return (
    projects?.map(project => ({
      name: project.name,
      value: project.id
    })) ?? []
  );
};

const sideNavContext = menuConstants.sideNavContext;
const SecondaryNavTemplate = props => {
  const {
    width,
    onClickArrow,
    sideNavItems,
    onClickNavigate,
    currentSideNavContext,
    project,
    client,
    isExpanded,
    getMenuItemExpanded,
    user
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: projectsForMenu } = useGetClientProjectsForMenuQuery(
    client?.id,
    {
      skip: !user.isHostUser || !client?.id
    }
  );

  const projectName = useMemo(() => {
    if (!project?.name || (user.isHostUser && !isExpanded)) {
      return <></>;
    }
    if (user.isHostUser) {
      const items = formatProjectItems(projectsForMenu);
      return (
        <DropdownInput
          value={{ name: project.name, value: project.id }}
          items={items}
          onChange={val => navigate(`${routeConstants.projects}/${val.value}`)}
          placeholder={t("common:ui.forms.searchProjects.label")}
        />
      );
    }
    return <NavHeader text={project.name} isExpanded={isExpanded} />;
  }, [isExpanded, navigate, project, projectsForMenu, user.isHostUser, t]);

  const clientName = useMemo(() => {
    if (!client || !user?.isHostUser) return <></>;

    return (
      <NavHeader
        text={client?.name}
        isExpanded={isExpanded}
        image={client?.logo?.data}
      />
    );
  }, [client, isExpanded, user?.isHostUser]);

  const sideNavHeader = useMemo(() => {
    return (
      <div className="secondary-nav__top--header">
        {currentSideNavContext === sideNavContext.ADMIN ? (
          <NavHeader text={"Admin"} isExpanded={isExpanded} />
        ) : (
          clientName
        )}
      </div>
    );
  }, [clientName, currentSideNavContext, isExpanded]);

  const renderTop = item => {
    if (item.type === "NAV_SECTION_TITLE") {
      return (
        <SecondaryNavSectionTitle
          key={item.name}
          isExpanded={isExpanded}
          item={item}
          width={width}
        />
      );
    }
    return (
      <SecondaryNavItem
        key={item.name}
        isExpanded={isExpanded}
        isMenuItemExpanded={getMenuItemExpanded(item.id)}
        item={item}
        onClickNavigate={onClickNavigate}
      />
    );
  };

  const renderMiddle = item => {
    return (
      <div key={item.name} className="secondary-nav__settings">
        <SecondaryNavItem
          isExpanded={isExpanded}
          item={item}
          onClickNavigate={onClickNavigate}
        />
      </div>
    );
  };

  const renderBottom = item => {
    return (
      <SecondaryNavItem
        key={item.name}
        isExpanded={isExpanded}
        item={item}
        onClickNavigate={onClickNavigate}
      />
    );
  };

  return (
    <div className="secondary-nav" style={{ width }}>
      <div className="secondary-nav__top" style={{ width }}>
        {sideNavHeader}
        {currentSideNavContext === sideNavContext.PROJECT && projectName}
        <div
          className={`secondary-nav__items-container ${
            !isExpanded ? "secondary-nav__items-container-scrollbar" : ""
          }`}
        >
          {sideNavItems["top"]?.map(renderTop)}
        </div>
        {sideNavItems["middle"]?.map(renderMiddle)}
        <div className="secondary-nav__button">
          <Icon
            name="keyboard_arrow_right"
            onClick={onClickArrow}
            className={`secondary-nav-arrow material-icons secondary-nav-arrow__icon ${
              isExpanded
                ? "secondary-nav-arrow--left"
                : "secondary-nav-arrow--right"
            }`}
          ></Icon>
        </div>
      </div>
      <div className={"secondary-nav__bot"}>
        {sideNavItems["bottom"]?.map(renderBottom)}
      </div>
    </div>
  );
};

export default SecondaryNavTemplate;
