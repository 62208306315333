import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageProfileActions } from "@shared/actions";

export function useSupportedMimes() {
  const dispatch = useDispatch();
  const [supportedMimes, setSupportedMimes] = useState([]);
  const manageProfile = useSelector(state => state.manageProfile);

  useEffect(() => {
    setSupportedMimes(manageProfile.supportedMimes);
  }, [manageProfile.supportedMimes]);

  const getSupportedMimesForImage = useCallback(() => {
    dispatch(manageProfileActions.getSupportedMimesForImage());
  }, [dispatch]);

  return {
    supportedMimes,
    getSupportedMimesForImage
  };
}
