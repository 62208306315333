import React, { useCallback, useEffect } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import ErrorBox from "@shared/components/errorBox/ErrorBox";
import {
  useAuthUser,
  useGetProjectManagersQuery,
  useUpdateProjectMutation
} from "@shared/hooks";

import { getErrorMessage } from "@app/helpers/error";
import {
  getEditProjectFormFieldsSchema,
  getProjectObjectFromProjectFormData
} from "@app/helpers/projectForm";
import { useGoBack } from "@app/hooks";
import { useProjectFormConfig } from "@app/hooks/useProjectFormConfig";

import Form from "@components/atoms/Form/Form";
import ProjectForm from "@components/molecules/ProjectForm/ProjectForm";

function UpdateProjectForm(props) {
  const { project } = props;
  const { user } = useAuthUser();
  const { t } = useTranslation();
  const goBack = useGoBack();
  const { entitiesEnabled, projectManagerEnabled, projectYearEnabled } =
    useProjectFormConfig({
      engagementTypeConfig: project?.configuration,
      t
    });
  const [
    updateProject,
    { isLoading: isSubmitting, error, isError, isSuccess }
  ] = useUpdateProjectMutation();

  const { data: projectManagers } = useGetProjectManagersQuery(
    { hostId: user?.hostId },
    { skip: !projectManagerEnabled || !user?.hostId }
  );

  const yupSchema = yup.object(getEditProjectFormFieldsSchema(t));

  const handleUpdate = useCallback(
    data => {
      const updatedProject = {
        id: project?.id,
        engagementId: project?.engagementId,
        ...getProjectObjectFromProjectFormData(
          data,
          {
            entitiesEnabled,
            projectManagerEnabled,
            projectYearEnabled,
            isUpdate: true
          },
          t
        )
      };
      updateProject({ project: updatedProject });
    },
    [
      entitiesEnabled,
      project,
      projectManagerEnabled,
      projectYearEnabled,
      t,
      updateProject
    ]
  );

  const handleCancel = useCallback(() => {
    goBack();
  }, [goBack]);

  useEffect(() => {
    if (isSuccess) {
      goBack();
    }
  }, [goBack, isSuccess]);

  return project ? (
    <Form
      yupSchema={yupSchema}
      handleSubmit={handleUpdate}
      handleCancel={handleCancel}
      disabled={isSubmitting || isSuccess}
    >
      {isError && <ErrorBox message={getErrorMessage(error, t)} />}
      <ProjectForm
        project={project}
        showUpdateFieldsOnly
        enableProjectManager={projectManagerEnabled}
        enableProjectYear={projectYearEnabled}
        enableEntities={entitiesEnabled}
        projectManagers={projectManagers}
      />
    </Form>
  ) : (
    <></>
  );
}

UpdateProjectForm.propTypes = {
  project: PropTypes.object
};

export default UpdateProjectForm;
