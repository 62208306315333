import React, { useRef } from "react";

import "./searchBox.scss";

const SearchBox = props => {
  const inputRef = useRef(null);
  const handleClick = () => {
    inputRef.current.focus();
  };

  const handleKeyDown = event => {
    event.stopPropagation();
    if (event.key === "Enter" && props.onPressEnter) {
      props.onPressEnter();
    }
  };

  return (
    <div onClick={handleClick} className="search-container">
      <i className="material-icons search-icon">search</i>
      <input
        ref={inputRef}
        type="text"
        value={props.value}
        placeholder={props.placeholder}
        className="search-box"
        onChange={props.handleChange}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

export default SearchBox;
