import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import PropTypes from "prop-types";
import useOnClickOutside from "use-onclickoutside";

import DropdownItem from "@components/atoms/DropdownItem";
import DropdownSearchBar from "@components/molecules/DropdownSearchBar";

import Icon from "../Icon/Icon";
import "./SearchableDropdown.scss";
import { useSearchableDropdown } from "./useSearchableDropdown";

const SearchableDropdown = props => {
  const {
    name,
    dropdownItems,
    handleClickSelection,
    leftIcon,
    disabled = false,
    position = "left",
    navigationStyle
  } = props;
  const dropdownRef = useRef(null);
  const [showItems, setShowItems] = useState(false);
  const {
    inputRef,
    focusInput,
    searchItems,
    setSearchItems,
    handleClearFilter,
    getDropdownItems
  } = useSearchableDropdown({
    dropdownItems
  });
  const toggleDropdown = useCallback(() => {
    setShowItems(prevState => !prevState);
  }, []);

  const onClickSelection = input => {
    setShowItems(false);
    handleClickSelection(input);
  };

  useOnClickOutside(dropdownRef, () => {
    setShowItems(false);
  });

  const renderClassName = useMemo(
    () =>
      `searchable-dropdown__items ${
        showItems ? "searchable-dropdown__items-show" : ""
      } searchable-dropdown__items-${position}`,
    [showItems, position]
  );

  const handleDropdownChange = event => {
    setSearchItems(event.target.value ?? "");
  };

  useEffect(() => {
    showItems ? focusInput() : setSearchItems("");
  }, [focusInput, setSearchItems, showItems]);

  return (
    <div
      className="searchable-dropdown"
      data-testid="test__dropdown"
      ref={dropdownRef}
    >
      <div className={navigationStyle === "OT1" ? "" : "nav__item"}>
        <button
          className={`searchable-dropdown__label button-default`}
          disabled={disabled}
          onClick={toggleDropdown}
        >
          <span className="searchable-dropdown__label-iconLeft">
            {leftIcon}
          </span>
          {name}
          {dropdownItems.length > 1 && (
            <Icon name="arrow_drop_down" className="material-icons" />
          )}
        </button>
      </div>
      <div className={renderClassName}>
        <DropdownSearchBar
          value={searchItems}
          onClear={handleClearFilter}
          onChange={handleDropdownChange}
          ref={inputRef}
        />
        {getDropdownItems()?.map(item => {
          if (item.name !== name) {
            return (
              <DropdownItem
                key={item.id}
                item={item}
                onClickSelection={onClickSelection}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

SearchableDropdown.defaultProps = {
  showDropdowns: false
};

SearchableDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  showDropdowns: PropTypes.bool,
  handleClickSelection: PropTypes.func,
  navigationStyle: PropTypes.string,
  dropdownItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.any.isRequired
    })
  ),
  leftIcon: PropTypes.node,
  position: PropTypes.oneOf(["left", "right"]),
  disabled: PropTypes.bool
};

export default SearchableDropdown;
