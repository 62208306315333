import React from "react";

import PropTypes from "prop-types";

import { classNames } from "@app/helpers/componentHelpers";

import "./Title.scss";

export const Title = props => {
  const { title, subtitle, actions, icon, subtitlePosition } = props;

  const renderTitleAndSubtitle = () => {
    if (subtitle && subtitlePosition === "newline") {
      return (
        <div className="ot-title__wrapper">
          <div>
            {title && <h1 className="ot-title__title">{title}</h1>}
            {icon && <h1 className="ot-title__icon">{icon}</h1>}
          </div>
          {subtitle && <h3 className="ot-title__subtitle">{subtitle}</h3>}
        </div>
      );
    }

    return (
      <>
        {title && <h1 className="ot-title__title">{title}</h1>}
        {icon && <h1 className="ot-title__icon">{icon}</h1>}
        {title && subtitle && <div className="ot-title__divider">{"|"}</div>}
        {subtitle && <h1 className="ot-title__subtitle">{subtitle}</h1>}
      </>
    );
  };

  return (
    <div
      className={classNames([
        "ot-title",
        `ot-title--subtitle-${subtitlePosition}`
      ])}
    >
      {renderTitleAndSubtitle()}
      {actions && <div className={`ot-title__actions`}>{actions}</div>}
      {(title || subtitle) && <div className="ot-title__horizontal-bar"></div>}
    </div>
  );
};

Title.defaultProps = {
  subtitlePosition: "inline"
};

Title.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.node,
  actions: PropTypes.node,
  icon: PropTypes.node,
  subtitlePosition: PropTypes.oneOf(["inline", "newline"])
};

export default Title;
