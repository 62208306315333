import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

import "./textArea.scss";

const TextArea = React.forwardRef((props, ref) => {
  const [text, setText] = useState(props.value || "");
  const [error, setError] = useState(props.error);

  useEffect(() => {
    setText(props.initialValue || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.value) {
      setText(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  const handleChange = event => {
    event.stopPropagation();
    setText(event.target.value);
    props.handleChange(event.target.value);
  };

  const handleOnBlur = () => {
    props.handleChange(text);
    props.onBlur();
  };

  return (
    <div className={"text-area-container" + (error ? "__error" : "")}>
      <textarea
        ref={ref}
        maxLength={props.maxLength || 524288}
        disabled={props.disabled}
        className={"text-area-text"}
        type={props.type}
        onChange={handleChange}
        onFocus={props.onFocus}
        onBlur={handleOnBlur}
        value={text}
        placeholder={props.placeholder}
      />
    </div>
  );
});

TextArea.propTypes = {
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  initialValue: PropTypes.string,
  type: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string
};

export default TextArea;
