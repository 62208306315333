import React, { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";

import Avatar from "@components/atoms/Avatar/Avatar";
import BrandButton from "@components/atoms/Button/BrandButton";
import AvatarList from "@components/molecules/AvatarList/AvatarList";
import WebsheetEntityIcon from "@components/molecules/WebsheetEntityIcon/WebsheetEntityIcon";

import "./WebsheetDetails.scss";

const WebsheetDetails = props => {
  const {
    project,
    query,
    actionItemConfig,
    websheetProperties,
    type,
    handleOpenModal
  } = props;

  const { t } = useTranslation();

  const renderCopiedTo = useMemo(() => {
    const copiedToField = actionItemConfig?.fields?.find(
      f => f.key === "copiedTo"
    );
    if (!copiedToField) {
      return <></>;
    }

    const hasCopiedToUsers = query.copiedTo?.length;

    return (
      <div className="websheet-details__item">
        <div className="websheet-details__label">
          {t("requests:requests.configured.fields.copiedTo.pastTenseLabel", {
            context: query.queryType
          })}
          :
        </div>
        <div className="websheet-details__value websheet-details__hover-reveal">
          {hasCopiedToUsers ? (
            <div>
              <AvatarList
                size={"x-small"}
                users={query.copiedTo}
                numOfUsers={3}
                hoverPosition="bottom"
              />
            </div>
          ) : (
            ""
          )}
          <div
            className={`websheet-details__action ${
              hasCopiedToUsers ? "websheet-details__hover-reveal--target" : ""
            }`}
          >
            <BrandButton
              iconName="edit"
              type="text"
              size="minimal"
              onClick={() => handleOpenModal("copiedTo")}
              {...(hasCopiedToUsers
                ? {
                    iconSize: "medium"
                  }
                : {
                    label: t("common:ui.forms.edit.label")
                  })}
            />
          </div>
        </div>
      </div>
    );
  }, [
    actionItemConfig?.fields,
    handleOpenModal,
    query?.copiedTo,
    query?.queryType,
    t
  ]);

  const renderHeaderIcon = useCallback(() => {
    return (
      <WebsheetEntityIcon
        projectEntities={project?.entities}
        queryEntitiesIds={query?.entities}
        iconClassName="websheet-details__value_icon"
        documentEntities={websheetProperties?.properties?.entities}
        websheetType={type}
      />
    );
  }, [
    project?.entities,
    query?.entities,
    type,
    websheetProperties?.properties?.entities
  ]);

  return (
    <>
      {project?.entities?.length > 1 && (
        <div className="websheet-details__item">
          <div className="websheet-details__label">{`${t(
            "stringFormat.capitalize",
            {
              key: "common:entities.displayName",
              count: 2
            }
          )}:`}</div>
          <div className="websheet-details__value">{renderHeaderIcon()}</div>
        </div>
      )}
      {query && type !== "smartForm" && (
        <>
          {query.refNo ? (
            <div className="websheet-details__item">
              <div className="websheet-details__label">{`${t(
                "requests:requests.refLabel"
              )}: `}</div>
              <div className="websheet-details__value" data-testid="refNo">
                {query.refNo}
              </div>
            </div>
          ) : (
            ""
          )}
          {renderCopiedTo}
          {query.assignedTo ? (
            <div className="websheet-details__item">
              <div className="websheet-details__label">
                {t(
                  "requests:requests.configured.fields.assignedTo.pastTenseLabel",
                  {
                    context: query.queryType
                  }
                )}
                :
              </div>
              <div className="websheet-details__value">
                <Avatar user={query.assignedTo} size={"x-small"} />
              </div>
              <div className="websheet-details__value">
                {query.assignedTo?.name}
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default WebsheetDetails;
