import React, { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";

import websheetUtilities from "@shared/helpers/websheetUtilities";

import BrandButton from "@components/atoms/Button/BrandButton";

const WebsheetTabsContent = props => {
  const { currentSheetName, sheetNames, handleClickTab } = props;
  const { t } = useTranslation();

  const onClick = useCallback(
    (sheet, index) => () => handleClickTab(sheet, index),
    [handleClickTab]
  );

  const getSheetTabs = useMemo(() => {
    return sheetNames.map((sheet, index) => {
      const id = websheetUtilities.getWebsheetSheetTabId(index);
      return (
        <BrandButton
          type="tertiary"
          disabled={currentSheetName === sheet}
          onClick={onClick(sheet, index)}
          key={sheet}
          label={sheet}
          className={
            currentSheetName === sheet ? "websheet-tab--active" : "websheet-tab"
          }
          id={id}
        />
      );
    });
  }, [currentSheetName, onClick, sheetNames]);

  return (
    <div className="sheets-container">
      <div className="list">
        <div className="sheets-label">
          {t("common:ui.websheet.sheetsLabel")}
        </div>
        <>{getSheetTabs}</>
      </div>
    </div>
  );
};

export default WebsheetTabsContent;
