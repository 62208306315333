export const manageClientUsersConstants = {
  GET_CLIENT_USERS_REQUEST: "GET_CLIENT_USERS_REQUEST",
  GET_CLIENT_USERS_SUCCESS: "GET_CLIENT_USERS_SUCCESS",
  GET_CLIENT_USERS_FAILURE: "GET_CLIENT_USERS_FAILURE",

  GET_CLIENT_GROUPS_REQUEST: "GET_CLIENT_GROUPS_REQUEST",
  GET_CLIENT_GROUPS_SUCCESS: "GET_CLIENT_GROUPS_SUCCESS",
  GET_CLIENT_GROUPS_FAILURE: "GET_CLIENT_GROUPS_FAILURE",

  GET_CLIENT_ROLES_REQUEST: "GET_CLIENT_ROLES_REQUEST",
  GET_CLIENT_ROLES_SUCCESS: "GET_CLIENT_ROLES_SUCCESS",
  GET_CLIENT_ROLES_FAILURE: "GET_CLIENT_ROLES_FAILURE",

  ADD_CLIENT_USER_REQUEST: "ADD_CLIENT_USER_REQUEST",
  ADD_CLIENT_USER_SUCCESS: "ADD_CLIENT_USER_SUCCESS",
  ADD_CLIENT_USER_FAILURE: "ADD_CLIENT_USER_FAILURE",

  UPDATE_CLIENT_USER_REQUEST: "UPDATE_CLIENT_USER_REQUEST",
  UPDATE_CLIENT_USER_SUCCESS: "UPDATE_CLIENT_USER_SUCCESS",
  UPDATE_CLIENT_USER_FAILURE: "UPDATE_CLIENT_USER_FAILURE",

  GOOGLE_AUTH_FEATURE_REQUEST: "GOOGLE_AUTH_FEATURE_REQUEST",
  GOOGLE_AUTH_FEATURE_SUCCESS: "GOOGLE_AUTH_FEATURE_SUCCESS",
  GOOGLE_AUTH_FEATURE_FAILURE: "GOOGLE_AUTH_FEATURE_FAILURE",

  RESET_GOOGLE_AUTH_FEATURE: "RESET_GOOGLE_AUTH_FEATURE",
  CLEAR_ERROR: "CLEAR_ERROR_CLIENT_USERS",
  RESET: "RESET_CLIENT_USERS",
  CLEAR_ADD_CLIENT_USER_FLAG: "CLEAR_ADD_CLIENT_USER_FLAG",
  CLEAR_UPDATE_CLIENT_USER_FLAG: "CLEAR_UPDATE_CLIENT_USER_FLAG"
};
