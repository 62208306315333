import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { routeConstants } from "@constants/routeConstants";

import { systemConstants } from "@shared/constants/systemConstants";
import { useFeatures } from "@shared/hooks";
import { useGetRolesQuery } from "@shared/services/roles";

import RolesTable from "@components/molecules/RolesTable/RolesTable";
import ManageUserRolesTemplate from "@components/templates/ManageUserRolesTemplate";

const ManageUserRoles = () => {
  const navigate = useNavigate();
  const { isEnabled, isLoading: isFeaturesLoading } = useFeatures();
  const { data: roles, isLoading } = useGetRolesQuery();
  const handleClickAddRole = () => {
    navigate(routeConstants.addRole);
  };

  useEffect(() => {
    if (!isFeaturesLoading && !isEnabled(systemConstants.features.roles)) {
      navigate(routeConstants.notFound);
    }
  }, [isEnabled, isFeaturesLoading, navigate]);

  return (
    <ManageUserRolesTemplate
      rolesTable={<RolesTable data={roles} />}
      handleClickAddRole={handleClickAddRole}
      isLoading={isLoading}
    />
  );
};

export default ManageUserRoles;
