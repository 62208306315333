import { searchConstants } from "../constants";

const initialState = {
  loading: false,
  searchResults: [],
  searchString: "",
  searchTags: [],
  loadingTags: false,
  requestAssignedToUsers: [],
  loaderToGetRequestAssignedToUsers: false,
  error: ""
};

export function search(state = initialState, action = {}) {
  switch (action.type) {
    case searchConstants.GET_REQUEST_ASSIGNED_TO_USERS_REQUEST:
      return {
        ...state,
        loaderToGetRequestAssignedToUsers: true
      };
    case searchConstants.GET_REQUEST_ASSIGNED_TO_USERS_SUCCESS:
      return {
        ...state,
        loaderToGetRequestAssignedToUsers: false,
        error: "",
        requestAssignedToUsers: action.usersList || []
      };
    case searchConstants.GET_REQUEST_ASSIGNED_TO_USERS_FAILURE:
      return {
        ...state,
        loaderToGetRequestAssignedToUsers: false,
        error: action.error.key ?? action.error.message
      };
    case searchConstants.GET_SEARCH_TAGS_REQUEST:
      return {
        ...state,
        loadingTags: true
      };
    case searchConstants.GET_SEARCH_TAGS_SUCCESS:
      return {
        ...state,
        loadingTags: false,
        error: "",
        searchTags: action.tags || []
      };
    case searchConstants.GET_SEARCH_TAGS_FAILURE:
      return {
        ...state,
        loadingTags: false,
        error: action.error.key ?? action.error.message
      };
    case searchConstants.GET_SEARCH_RESULTS_REQUEST:
      return {
        ...state,
        searchString: action.searchString,
        loading: true
      };
    case searchConstants.GET_SEARCH_RESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        searchResults: action.searchResults || []
      };
    case searchConstants.SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.searchString
      };
    case searchConstants.GET_SEARCH_RESULTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.key ?? action.error.message
      };
    case searchConstants.RESET_SEARCH_STRING: {
      return { ...state, searchString: "", error: "" };
    }
    case searchConstants.RESET_REQUEST_ASSIGNED_TO_USERS: {
      return {
        ...state,
        loaderToGetRequestAssignedToUsers: false,
        requestAssignedToUsers: []
      };
    }
    case searchConstants.RESET_SEARCH_RESULTS:
      return {
        ...state,
        error: "",
        loading: false,
        searchString: "",
        searchResults: []
      };
    default:
      return state;
  }
}
