import { manageHostUsersConstants } from "../constants";
import { manageHostUsersService } from "../services";
import { manageHostGroupsService } from "../services";

export const manageHostUsersActions = {
  getHostUsers,
  getHostGroups,
  getHostRoles,
  clearError,
  clearAddHostUserFlag,
  clearUpdateHostUserFlag,
  downloadBulkUploadUsersTemplate,
  uploadBulkUsers,
  getEngagementTypesWithTags,
  getEngagementTypes,
  createTag,
  updateTag,
  reset,
  deleteGlobalTag
};

function getHostUsers(user) {
  return dispatch => {
    dispatch(request());

    manageHostUsersService.getHostUsers(user).then(
      users => {
        dispatch(success(users));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: manageHostUsersConstants.GET_HOST_USERS_REQUEST };
  }
  function success(users) {
    return { type: manageHostUsersConstants.GET_HOST_USERS_SUCCESS, users };
  }
  function failure(error) {
    return { type: manageHostUsersConstants.GET_HOST_USERS_FAILURE, error };
  }
}

function getHostGroups(user) {
  return dispatch => {
    dispatch(request());

    manageHostGroupsService.getHostGroups(user).then(
      groups => {
        dispatch(success(groups));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: manageHostUsersConstants.GET_HOST_GROUPS_REQUEST };
  }
  function success(groups) {
    return {
      type: manageHostUsersConstants.GET_HOST_GROUPS_SUCCESS,
      groups
    };
  }
  function failure(error) {
    return {
      type: manageHostUsersConstants.GET_HOST_GROUPS_FAILURE,
      error
    };
  }
}

function getHostRoles(user) {
  return dispatch => {
    dispatch(request());

    manageHostUsersService.getHostRoles(user).then(
      roles => {
        dispatch(success(roles));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: manageHostUsersConstants.GET_HOST_ROLES_REQUEST };
  }
  function success(roles) {
    return {
      type: manageHostUsersConstants.GET_HOST_ROLES_SUCCESS,
      roles
    };
  }
  function failure(error) {
    return {
      type: manageHostUsersConstants.GET_HOST_ROLES_FAILURE,
      error
    };
  }
}

function clearError() {
  return { type: manageHostUsersConstants.CLEAR_ERROR };
}

function clearAddHostUserFlag() {
  return { type: manageHostUsersConstants.CLEAR_ADD_HOST_USER_FLAG };
}

function clearUpdateHostUserFlag() {
  return { type: manageHostUsersConstants.CLEAR_UPDATE_HOST_USER_FLAG };
}

function reset() {
  return { type: manageHostUsersConstants.RESET };
}

function downloadBulkUploadUsersTemplate() {
  return dispatch => {
    dispatch(request());

    manageHostUsersService.downloadBulkUploadUsersTemplate().then(
      blob => {
        dispatch(success(blob));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageHostUsersConstants.DOWNLOAD_BULK_USERS_UPLOAD_TEMPLATE_REQUEST
    };
  }
  function success(blob) {
    return {
      type: manageHostUsersConstants.DOWNLOAD_BULK_USERS_UPLOAD_TEMPLATE_SUCCESS,
      blob
    };
  }
  function failure(error) {
    return {
      type: manageHostUsersConstants.DOWNLOAD_BULK_USERS_UPLOAD_TEMPLATE_FAILURE,
      error
    };
  }
}
function uploadBulkUsers(users, clientId) {
  return dispatch => {
    dispatch(request());

    manageHostUsersService.uploadBulkUsers(users, clientId).then(
      blob => {
        dispatch(success(blob));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: manageHostUsersConstants.UPLOAD_BULK_USERS_REQUEST };
  }
  function success(userObjects) {
    return {
      type: manageHostUsersConstants.UPLOAD_BULK_USERS_SUCCESS,
      users: userObjects
    };
  }
  function failure(error) {
    return { type: manageHostUsersConstants.UPLOAD_BULK_USERS_FAILURE, error };
  }
}

function deleteGlobalTag(engagementTypeId, tagToBeDeleted) {
  return dispatch => {
    dispatch(request());

    manageHostUsersService
      .deleteGlobalTag(engagementTypeId, tagToBeDeleted)
      .then(
        tagObject => {
          dispatch(success(tagObject));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };
  function request() {
    return { type: manageHostUsersConstants.DELETE_TAG_REQUEST };
  }
  function success(tagObject) {
    return {
      type: manageHostUsersConstants.DELETE_TAG_SUCCESS,
      tag: tagObject
    };
  }
  function failure(error) {
    return { type: manageHostUsersConstants.DELETE_TAG_FAILURE, error };
  }
}

function getEngagementTypesWithTags() {
  return dispatch => {
    dispatch(request());

    manageHostUsersService.getEngagementTypesWithTags().then(
      engagementTypes => {
        dispatch(success(engagementTypes));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageHostUsersConstants.GET_ENGAGEMENT_TYPES_WITH_TAGS_REQUEST
    };
  }
  function success(engagementTypes) {
    return {
      type: manageHostUsersConstants.GET_ENGAGEMENT_TYPES_WITH_TAGS_SUCCESS,
      engagementTypes
    };
  }
  function failure(error) {
    return {
      type: manageHostUsersConstants.GET_ENGAGEMENT_TYPES_WITH_TAGS_FAILURE,
      error
    };
  }
}

function getEngagementTypes() {
  return dispatch => {
    dispatch(request());

    manageHostUsersService.getEngagementTypes().then(
      engagementTypes => {
        dispatch(success(engagementTypes));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: manageHostUsersConstants.GET_ENGAGEMENT_TYPES_REQUEST };
  }
  function success(engagementTypes) {
    return {
      type: manageHostUsersConstants.GET_ENGAGEMENT_TYPES_SUCCESS,
      engagementTypes
    };
  }
  function failure(error) {
    return {
      type: manageHostUsersConstants.GET_ENGAGEMENT_TYPES_FAILURE,
      error
    };
  }
}

function updateTag(tag) {
  return dispatch => {
    dispatch(request());

    manageHostUsersService.updateTag(tag).then(
      tagObject => {
        dispatch(success(tagObject));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: manageHostUsersConstants.UPDATE_TAG_REQUEST };
  }
  function success(tagObject) {
    return {
      type: manageHostUsersConstants.UPDATE_TAG_SUCCESS,
      tag: tagObject
    };
  }
  function failure(error) {
    return { type: manageHostUsersConstants.UPDATE_TAG_FAILURE, error };
  }
}

function createTag(tag) {
  return dispatch => {
    dispatch(request());

    manageHostUsersService.createTag(tag).then(
      tagObject => {
        dispatch(success(tagObject));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: manageHostUsersConstants.CREATE_TAG_REQUEST };
  }
  function success(tagObject) {
    return {
      type: manageHostUsersConstants.CREATE_TAG_SUCCESS,
      tag: tagObject
    };
  }
  function failure(error) {
    return { type: manageHostUsersConstants.CREATE_TAG_FAILURE, error };
  }
}
