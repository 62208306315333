import React, { useState } from "react";

import moment from "moment";
import { useTranslation } from "react-i18next";

import { utilities } from "@shared/helpers";

import Icon from "@components/atoms/Icon/Icon";

import "./auditRecords.scss";

const dateFields = ["date", "startDate", "plannedCompletionDate"];
const exemptedColumnsForTables = [
  {
    table: "milestone",
    columns: ["properties"]
  }
];

const AuditRecords = props => {
  const [collapsed, setCollapsed] = useState(false);
  const { i18n } = useTranslation();

  const getValue = (record, field) => {
    if (record.tableName === "milestone" && field === "name") {
      return utilities.getProjectMilestoneName(record.value, i18n);
    }
    else if (record.tableName === "queryResponses" && field === "properties") {
      return JSON.stringify(record.value[field]);
    }
    return `${record.value[field]}`;
  };

  const getDate = value => {
    if (value) {
      return moment(value).format("D MMM YYYY H:mm");
    } else {
      return `${value}`;
    }
  };

  const getFields = record => {
    if (!record?.value) {
      return [];
    }

    //searches for the exempted columns for the passed in record's table and filters them out
    const exemptedColumns =
      exemptedColumnsForTables.find(val => val.table === record.tableName)
        ?.columns ?? [];
    return Object.keys(record.value).filter(
      field => !exemptedColumns.some(c => c === field)
    );
  };

  const getEachUpdateOrDeleteTransaction = (record, prevRecord) => {
    const fields = getFields(record);
    if (
      !prevRecord ||
      record.tableId !== prevRecord.tableId ||
      !record.tableId
    ) {
      return (
        <>
          <div key={"action"}>{record.action}</div>
          {fields.map((field, index) => (
            <React.Fragment key={index}>
              {dateFields.some(item => item === field) ? (
                <div>{getDate(record.value[field])}</div>
              ) : (
                <div>{getValue(record, field)}</div>
              )}
            </React.Fragment>
          ))}
          <div key={"createdAt"}>{getDate(record.createdAt)}</div>
        </>
      );
    } else {
      return (
        <>
          <div key={"action"}>{record.action}</div>
          {fields.map((field, index) => (
            <React.Fragment key={index}>
              {dateFields.some(item => item === field) ? (
                <div>
                  <div>{getDate(prevRecord.value[field])}</div>
                  <div>{getDate(record.value[field])}</div>
                </div>
              ) : (
                <div>
                  <div>{getValue(record, field)}</div>
                  <div>{getValue(record, field)}</div>
                </div>
              )}
            </React.Fragment>
          ))}
          <div key={"createdAt"}>{getDate(record.createdAt)}</div>
        </>
      );
    }
  };

  const getEachCreateTransaction = record => {
    const fields = getFields(record);
    return (
      <>
        <div key={"action"}>{record.action}</div>
        {fields.map((field, index) => (
          <React.Fragment key={index}>
            {dateFields.some(item => item === field) ? (
              <div>{getDate(record.value[field])}</div>
            ) : (
              <div>{getValue(record, field)}</div>
            )}
          </React.Fragment>
        ))}
        <div key={"createdAt"}>{getDate(record.createdAt)}</div>
      </>
    );
  };

  const getHeaderForTransaction = header => {
    const fields = getFields(header);
    return fields.map((field, index) => <div key={index}>{field}</div>);
  };

  const getRecordTransactions = (reportFor, recordSets) => {
    const sortedRecordSets = recordSets.map((recordSet, index) => {
      if (recordSet.action === "CREATE") {
        return {
          action: recordSet.action,
          row: recordSet,
          createdAt: recordSet.createdAt
        };
      } else {
        return {
          action: recordSet.action,
          row: recordSet,
          prevRow: index !== 0 && recordSets[index - 1],
          createdAt: recordSet.createdAt
        };
      }
    });
    sortedRecordSets.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
    return (
      <div className="audit-records">
        <div
          className="audit-records__container--transaction--header"
          style={{
            display: "grid",
            gridAutoColumns: "20rem",
            gridAutoFlow: "column"
          }}
        >
          <div key={"action-header"}>{"action"}</div>
          {getHeaderForTransaction(recordSets[0])}
          <div key={"created-at-header"}>{"createdAt"}</div>
        </div>

        {sortedRecordSets.map((recordSet, index) => {
          return (
            recordSet.row?.user?.email === reportFor && (
              <div
                className="audit-records__container--transaction--items"
                style={{
                  display: "grid",
                  gridAutoColumns: "20rem",
                  gridAutoFlow: "column"
                }}
                key={index}
              >
                {recordSet.action === "CREATE" &&
                  getEachCreateTransaction(recordSet.row)}

                {recordSet.action !== "CREATE" &&
                  getEachUpdateOrDeleteTransaction(
                    recordSet.row,
                    recordSet.prevRow
                  )}
              </div>
            )
          );
        })}
      </div>
    );
  };
  return (
    <div className="audit-records__container">
      <div className="audit-records__title">
        <h2 className="audit-records__title__text">
          {props.data && props.data.table}
        </h2>
        <Icon
          className="audit-records__title__expand-icon"
          name={collapsed ? "expand_more" : "expand_less"}
          onClick={() => setCollapsed(!collapsed)}
        />
      </div>
      {!collapsed &&
        getRecordTransactions(
          props.reportFor,
          props.data && props.data.recordSets
        )}
    </div>
  );
};

export default AuditRecords;
