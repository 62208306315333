import React from "react";

import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import App from "@app/App";

import "./i18n";
import "./index.scss";
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <Router>
    <React.Suspense fallback={<div></div>}>
      <App />
    </React.Suspense>
  </Router>,
  document.getElementById("root")
);

serviceWorker.unregister();
