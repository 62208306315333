import React from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { useAuthUser } from "@shared/hooks/useAuthUser";

import DashboardBoxTemplate from "@components/templates/DashboardBoxTemplate";

import "./EntityList.scss";

/**
 * Component for showing List of entities.
 *
 * @param   {Object} props
 * @param   {*}  props.t i18n utils
 * @param   {Object[]}  props.entities the entities
 * @param   {string}  props.entities[].name entity name
 * @param   {number}  props.entities[].externalId entity externalId
 * @param   {{id: number, engagement: Object}}  props.project the project
 * @component
 * @example
 * const project = ...;
 * return (
 *   <EntityList entities={project.entities} projectId={project.id}/>
 * )
 */
const EntityList = ({ entities, project, t }) => {
  const { user: authUser } = useAuthUser();

  const headerAction = () => {
    if (authUser.isHostUser) {
      return (
        <NavLink
          to={`/projects/${project.id}/edit`}
          state={{ engagement: project.engagement }}
        >
          <i className="material-icons">edit</i>
        </NavLink>
      );
    }
    return null;
  };

  return (
    <DashboardBoxTemplate
      title={t("common:ui.projects.entities.label")}
      boxClassName={"entity-list-box"}
      action={headerAction()}
    >
      <ul>
        {entities?.map(({ name, externalId }) => (
          <li key={externalId}>{name}</li>
        ))}
      </ul>
    </DashboardBoxTemplate>
  );
};

EntityList.propTypes = {
  entities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      externalId: PropTypes.number.isRequired
    })
  ).isRequired,
  project: PropTypes.object.isRequired
};

export default withTranslation()(EntityList);
