import React, { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import ErrorBox from "@shared/components/errorBox/ErrorBox";
import { useAuthUser } from "@shared/hooks";
import {
  useAddProjectMutation,
  useGetProjectManagersQuery
} from "@shared/services/projectService";

import { getErrorMessage } from "@app/helpers/error";
import {
  getAddProjectFormFieldsSchema,
  getProjectObjectFromProjectFormData
} from "@app/helpers/projectForm";
import { useGoBack } from "@app/hooks";
import { useProjectFormConfig } from "@app/hooks/useProjectFormConfig";

import Form from "@components/atoms/Form/Form";
import ProjectForm from "@components/molecules/ProjectForm/ProjectForm";
import SelectClientDropdown from "@components/organisms/SelectClientDropdown/SelectClientDropdown";
import SelectEngagementTypeDropdown from "@components/organisms/SelectEngagementTypeDropdown";

import "./AddClientProjectForm.scss";

const AddClientProjectForm = () => {
  const { t } = useTranslation();
  const { user } = useAuthUser();
  const navigate = useNavigate();
  const [selectedEngagementType, setSelectedEngagementType] = useState(null);
  const {
    entitiesEnabled,
    projectManagerEnabled,
    projectYearEnabled,
    startDateLabel,
    endDateLabel
  } = useProjectFormConfig({
    engagementTypeConfig: selectedEngagementType?.configuration,
    t
  });
  const { data: projectManagers } = useGetProjectManagersQuery(
    { hostId: user?.hostId },
    { skip: !projectManagerEnabled || !user?.hostId }
  );
  const goBack = useGoBack();

  const [
    addProject,
    { data: addedProject, error, isError, isLoading: isSubmitting, isSuccess }
  ] = useAddProjectMutation();

  const yupSchema = yup.object({
    engagementType: yup.object().required(
      t("common:ui.forms.required.message", {
        label: t("stringFormat.propercase", {
          key: "common:ui.engagementType.displayName"
        })
      })
    ),
    client: yup.object().required(
      t("common:ui.forms.required.message", {
        label: t("stringFormat.propercase", {
          key: "common:ui.engagementType.displayName"
        })
      })
    ),
    ...getAddProjectFormFieldsSchema(t)
  });

  const handleEngagementTypeChange = useCallback(engagementType => {
    setSelectedEngagementType(engagementType);
  }, []);

  const handleFormSubmit = useCallback(
    data => {
      const project = {
        clientId: data.client.value.id,
        engagementTypeId: data.engagementType.value.id,
        ...getProjectObjectFromProjectFormData(
          data,
          {
            entitiesEnabled,
            projectManagerEnabled,
            projectYearEnabled
          },
          t
        )
      };
      addProject(project);
    },
    [addProject, entitiesEnabled, projectManagerEnabled, projectYearEnabled, t]
  );

  const handleCancel = useCallback(() => {
    goBack();
  }, [goBack]);

  useEffect(() => {
    if (isSuccess) {
      navigate(`/projects/${addedProject?.id}`);
    }
  }, [addedProject, isSuccess, navigate]);

  return (
    <Form
      yupSchema={yupSchema}
      handleSubmit={handleFormSubmit}
      handleCancel={handleCancel}
      disabled={isSubmitting || isSuccess}
    >
      {isError && <ErrorBox message={getErrorMessage(error, t)} />}
      <SelectClientDropdown name="client" isFormField required />
      <SelectEngagementTypeDropdown
        onChange={handleEngagementTypeChange}
        isFormField
        required
        hideIfOne
        disablePreselectDefault
      />
      {selectedEngagementType ? (
        <ProjectForm
          enableEntities={entitiesEnabled}
          enableProjectManager={projectManagerEnabled}
          enableProjectYear={projectYearEnabled}
          startDateLabel={startDateLabel}
          endDateLabel={endDateLabel}
          projectManagers={projectManagers}
        />
      ) : (
        <></>
      )}
    </Form>
  );
};

export default AddClientProjectForm;
