import { useCallback, useEffect, useRef, useState } from "react";

import { handleErrorResponse } from "@shared/services/handleErrorResponse";
import { handleFileDownload } from "@shared/services/handleFileDownload";

import { systemConstants } from "../constants";
import { authHeader } from "../helpers";

const baseURL = systemConstants.serverURL;

// Complementary hook for components/organisms/FileDownloader/FileDownloader.jsx
// adapted from https://www.techprescient.com/react-custom-hook-typescript-to-download-a-file-through-api/
export function useDownloadFile({ onError, onDownloading }) {
  const anchorRef = useRef(null);
  const [url, setFileUrl] = useState();
  const [filename, setFilename] = useState();

  const downloadViaPost = useCallback(
    ({ apiUrl, data }) => {
      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data)
      };

      const f = fetch(`${baseURL}/api/${apiUrl}`, requestOptions)
        .then(handleFileDownload)
        .then(({ blobUrl: objectURL, filename }) => {
          if (anchorRef.current) {
            setFileUrl(objectURL);
            setFilename(filename);
            anchorRef.current.click();
            onDownloading?.();
          }
        })
        .catch(e => onError?.(e.message));
    },
    [onError, onDownloading]
  );

  const download = useCallback(
    ({ apiUrl, method = "POST", data }) => {
      if (method === "POST") {
        downloadViaPost({ apiUrl, data });
      } else {
        onError("Unsupported method");
      }
    },
    [downloadViaPost, onError]
  );

  return {
    anchorRef,
    url,
    filename,
    download
  };
}
