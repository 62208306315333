import { useCallback, useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageClientEngagementProjectsActions } from "@shared/actions";

import deepCompare from "../helpers/deepCompare";

export function useGetMyProjects() {
  const manageClientEngagementProjects = useSelector(
    state => state.manageClientEngagementProjects
  );
  const [myProjects, setMyProjects] = useState([]);
  const [isMyProjectsLoading, setIsMyProjectsLoading] = useState(false);
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const isInitial = useRef(false);

  useEffect(() => {
    if (!deepCompare(manageClientEngagementProjects.myProjects, myProjects)) {
      setMyProjects(structuredClone(manageClientEngagementProjects.myProjects));
    }
  }, [myProjects, manageClientEngagementProjects.myProjects]);

  useEffect(() => {
    setIsMyProjectsLoading(manageClientEngagementProjects.loadingMyProjects);
  }, [manageClientEngagementProjects.loadingMyProjects]);

  useEffect(() => {
    setError(manageClientEngagementProjects.error);
  }, [manageClientEngagementProjects.error]);

  const fetchMyProjects = useCallback(
    ({ withDetails }) => {
      if (!isInitial.current) {
        dispatch(
          manageClientEngagementProjectsActions.getMyProjects(withDetails)
        );
        isInitial.current = true;
        return true;
      }
      return false;
    },
    [dispatch]
  );

  const refreshMyProjects = useCallback(() => {
    isInitial.current = false;
    fetchMyProjects({ withDetails: true });
  }, [fetchMyProjects]);

  const resetMyProjects = useCallback(() => {
    dispatch(manageClientEngagementProjectsActions.reset());
    setMyProjects([]);
  }, [dispatch]);

  return {
    myProjects,
    isMyProjectsLoading,
    error,
    fetchMyProjects,
    refreshMyProjects,
    resetMyProjects
  };
}
