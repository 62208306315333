import React, { useCallback, useMemo, useRef } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  smartFormActions,
  smartFormHelper,
  smartFormProgressStates,
  smartFormResponseType
} from "@app/helpers/smartForm";
import { useDataTable } from "@app/hooks/useDataTable";

import BrandButton from "@components/atoms/Button/BrandButton";
import CommentButton from "@components/atoms/CommentButton";
import DatePicker from "@components/atoms/DatePicker";
import Icon from "@components/atoms/Icon";
import DataTable from "@components/molecules/DataTable";
import SmartFormEntityProgress from "@components/molecules/SmartFormEntityProgress";
import SmartFormRadioButtons from "@components/molecules/SmartFormRadioButtons";
import SmartFormTextArea from "@components/molecules/SmartFormTextArea";

import SmartFormSingleChoice from "../SmartFormSingleChoice";
import "./SmartFormTable.scss";

/**
 * @param {Object} props
 * @param {Array} props.questionWithComments List of questions which comments
 * @param {Array} props.questions List of questions
 * @param {Object} props.handlers Handlers for various events
 * @param {Object} props.relevantEntitiesById List of all relevant entities for the SmartForm (for lookup)
 * @param {Boolean} props.disableAnswerSubmissions Whether answer submissions is disabled or not
 * @param {Boolean} props.alwaysShowEntitiesIcon Whether to always show the entities icon or fallback to default behaviour
 * @returns
 */
const SmartFormTable = ({
  questionWithComments,
  questions,
  handlers,
  relevantEntitiesById,
  disableAnswerSubmissions,
  alwaysShowEntitiesIcon
}) => {
  const { t } = useTranslation();
  const { createColumn } = useDataTable(questions);

  const relevantEntitiesTotal = useMemo(() => {
    return Object.values(relevantEntitiesById)?.length || 0;
  }, [relevantEntitiesById]);

  const tableRef = useRef();

  const questionsById = useMemo(() => {
    return questions.reduce((acc, q) => {
      acc[q.questionId] = q;
      return acc;
    }, {});
  }, [questions]);

  const getQuestionById = useCallback(
    questionId => {
      return questionsById[questionId];
    },
    [questionsById]
  );

  const verticalProgressIndicator = useCallback(
    ({ status = smartFormProgressStates.NOT_STARTED }) => {
      return (
        <div
          className={`smart-form-table__indicator smart-form-table__indicator--${status}`}
          data-testid={`testSmartFormProgress-${status}`}
        ></div>
      );
    },
    []
  );

  const questionText = useCallback(
    ({ questionId, text, additionalInfo, isMandatory }) => {
      const mandatoryClassName = isMandatory
        ? "smart-form-table__questionText__mandatory"
        : "";
      if (!additionalInfo) {
        return (
          <div
            id={`smart-form-question-${questionId}`}
            className="smart-form-table__questionText"
          >
            <span className={mandatoryClassName}>{text}</span>
          </div>
        );
      }

      return (
        <details className="smart-form-table__disclosure">
          <summary
            id={`smart-form-question-${questionId}`}
            className="smart-form-table__questionText"
          >
            <span className={mandatoryClassName}>{text}</span>
            <Icon
              className="smart-form-table__info-icon smart-form-table__disclosure__toggle"
              name="info"
              size="medium"
            ></Icon>
          </summary>
          <div>
            {additionalInfo.split("\n").map((string, i) => {
              return <p key={i}>{string}</p>;
            })}
          </div>
        </details>
      );
    },
    []
  );

  const getHoverElement = useCallback(
    question => {
      let relevantEntities = [];
      const maxEntities = 10;

      if (
        !question.relevantEntities ||
        question.relevantEntities.length === relevantEntitiesTotal
      ) {
        relevantEntities = [`${t("requests:requests.ui.smartForm.all.label")}`];
      } else {
        relevantEntities = Object.entries(relevantEntitiesById)
          .filter(([id]) => question?.relevantEntities?.includes(+id))
          .map(([, name]) => name);
      }

      const numEntitiesOverMax = relevantEntities.splice(maxEntities)?.length;

      const hoverElement =
        relevantEntities?.length > 0 ? (
          <span className="smart-form-table__dependent-question__entities__hover">
            {`${t("requests:requests.ui.smartForm.relevantEntities.label")}:`}
            <span className="smart-form-table__dependent-question__entities__hover__names">
              {relevantEntities.join(", ")}
              {numEntitiesOverMax
                ? t("requests:requests.ui.smartForm.numOfOtherEntities", {
                    count: numEntitiesOverMax
                  })
                : ""}
            </span>
          </span>
        ) : null;

      return hoverElement;
    },
    [relevantEntitiesById, relevantEntitiesTotal, t]
  );

  /**
   *
   * @param {*} responseType see SmartFormResponseType
   * @param {Number} numberOfAnswers Number of answer provided
   * @param {Boolean} isAnswerSubmissionsDisabled Whether answer submissions is disabled or not
   * @returns {?Object} { label, icon }
   */
  const labelAndIconForOpenModalButton = useCallback(
    (responseType, numberOfAnswers, isAnswerSubmissionsDisabled) => {
      if (responseType === smartFormResponseType.DOCUMENT) {
        if (numberOfAnswers) {
          return {
            icon: "description",
            label: t("requests:requests.ui.smartForm.modal.viewDocuments.label")
          };
        }

        if (isAnswerSubmissionsDisabled) {
          return {
            icon: "description",
            label: t("requests:requests.ui.smartForm.modal.noDocuments.label")
          };
        }

        return {
          icon: "description",
          label: t("requests:requests.ui.smartForm.modal.uploadDocuments.label")
        };
      }

      if (responseType === smartFormResponseType.WEBSHEET) {
        if (numberOfAnswers) {
          return {
            icon: "view_module",
            label: t("requests:requests.ui.smartForm.modal.viewWebsheets.label")
          };
        }

        if (isAnswerSubmissionsDisabled) {
          return {
            icon: "view_module",
            label: t("requests:requests.ui.smartForm.modal.noWebsheets.label")
          };
        }

        return {
          icon: "view_module",
          label: t("requests:requests.ui.smartForm.modal.fillWebsheet.label")
        };
      }
    },
    [t]
  );

  const renderCommentCell = useCallback(
    ({ cell }) => {
      const question = getQuestionById(cell.row.original.questionId);
      const hasComments = questionWithComments?.some(
        qId => qId === question?.questionId
      );
      return (
        <div
          className={`data-table__data--icon-cell ${
            hasComments ? "" : "data-table__data--on-hover-cell"
          }`}
          role="cell-comment-icon"
        >
          <CommentButton
            id={question?.questionId}
            onClick={() => {
              handlers[smartFormActions.OPEN_COMMENT_MODAL](question);
            }}
            icon="contact_support"
            state={hasComments ? "active" : "outlined"}
            title={t(`requests:requests.ui.smartForm.addComment.label`)}
          />
        </div>
      );
    },
    [questionWithComments, handlers, getQuestionById, t]
  );

  const renderEntityIcon = useCallback(
    question => {
      if (relevantEntitiesTotal <= 1) {
        return <></>;
      }
      return (
        <BrandButton
          className="smart-form-table__dependent-question__entities"
          type="text-accent"
          iconOutlined={true}
          iconName="lan"
          iconSize="medium"
          label=""
          textTransform="none"
          hoverElement={getHoverElement(question)}
        />
      );
    },
    [getHoverElement, relevantEntitiesTotal]
  );

  const renderDependentQuestion = useCallback(
    question => {
      return (
        <div className="smart-form-table__dependent-question">
          <span className="smart-form-table__dependent-question__list-icon">
            ∟
          </span>
          {renderEntityIcon(question)}
          {questionText(question)}
        </div>
      );
    },
    [questionText, renderEntityIcon]
  );

  const renderResponseTypeIcon = useCallback(
    ({ cell }) => (
      <Icon
        className="smart-form-table__response-type-icon data-table__data--on-hover-cell"
        name={smartFormHelper.getResponseTypeIcon(
          cell.row.original.responseType
        )}
        size="medium"
      />
    ),
    []
  );

  const renderResponseType = useCallback(
    ({ cell }) => {
      const question = getQuestionById(cell.row.original.questionId);
      const answers = question.answers;
      const globalAnswer = question?.isAnsweredGlobally
        ? answers?.[0]?.value ?? null
        : null;
      if (question.assignedEntities.length > 0 && relevantEntitiesTotal > 1) {
        return (
          <div className="smart-form-table__action-button">
            <SmartFormEntityProgress
              assignedEntities={question.assignedEntities}
              answeredEntities={question.answeredEntities}
              relevantEntitiesById={relevantEntitiesById}
              questionRelevantEntities={question.relevantEntities}
              onClick={() =>
                handlers[smartFormActions.OPEN_ENTITIES_MODAL](question)
              }
              responseType={cell.value}
              text={t(
                "requests:requests.ui.smartForm.incompleteEntities.label"
              )}
            />
          </div>
        );
      }

      if (
        cell.value === smartFormResponseType.TEXT ||
        cell.value === smartFormResponseType.NUMBER
      ) {
        return (
          <SmartFormTextArea
            maxLength={question.maxLength}
            isLineBreaksAllowed={question.lineBreaks}
            onAnswerSupplied={handlers[smartFormActions.SUPPLY_ANSWER]}
            value={globalAnswer}
            questionId={question.questionId}
            disabled={!question?.isAnsweredGlobally || disableAnswerSubmissions}
            responseType={cell.value}
            min={question.min}
            max={question.max}
          />
        );
      } else if (cell.value === smartFormResponseType.SINGLE_CHOICE) {
        return (
          <SmartFormSingleChoice
            options={question.options}
            layout={"vertical"}
            value={globalAnswer}
            handleOnChange={value => {
              handlers[smartFormActions.SUPPLY_ANSWER]?.(
                question.questionId,
                value
              );
            }}
            questionId={question.questionId}
            disabled={!question?.isAnsweredGlobally || disableAnswerSubmissions}
          />
        );
      } else if (cell.value === smartFormResponseType.BOOLEAN) {
        const booleanOptions = [
          {
            name: `${t(
              "requests:requests.ui.smartForm.responseType.boolean.yes.label"
            )}`,
            value: true
          },
          {
            name: `${t(
              "requests:requests.ui.smartForm.responseType.boolean.no.label"
            )}`,
            value: false
          }
        ];
        return (
          <SmartFormRadioButtons
            onChange={handlers[smartFormActions.SUPPLY_ANSWER]}
            value={globalAnswer}
            allowUnselect={false}
            options={booleanOptions}
            questionId={question.questionId}
            disabled={!question?.isAnsweredGlobally || disableAnswerSubmissions}
          />
        );
      } else if (cell.value === smartFormResponseType.DATE) {
        return (
          <DatePicker
            value={globalAnswer ? new Date(globalAnswer) : null}
            showPlaceholder={true}
            // Setting min/max dates to the earliest/latest possible dates to prevent the user from selecting a date outside of the range
            minDate={new Date("1000-01-01")}
            maxDate={new Date("9999-12-31")}
            // Debouncing prevents the onChange from triggering whilst the user is typing as doing so triggers a re-render causing the input to lose focus whilst typing
            useDebouncedOnChange={true}
            onChange={value => {
              handlers[smartFormActions.SUPPLY_ANSWER]?.(
                question.questionId,
                value
              );
            }}
            disabled={!question?.isAnsweredGlobally || disableAnswerSubmissions}
          />
        );
      } else if (
        cell.value === smartFormResponseType.DOCUMENT ||
        cell.value === smartFormResponseType.WEBSHEET
      ) {
        const { label, icon } = labelAndIconForOpenModalButton(
          cell.value,
          answers?.length,
          disableAnswerSubmissions
        );
        return (
          <div className="smart-form-table__action-button">
            <BrandButton
              iconName={icon}
              iconOutlined={true}
              type="text-inherit"
              size="small"
              textTransform="none"
              label={label}
              onClick={() =>
                handlers[smartFormActions.OPEN_ENTITIES_MODAL](question)
              }
              disabled={answers?.length === 0 && disableAnswerSubmissions}
            />
          </div>
        );
      }
      return <div className="smart-form-table__comingsoon">{cell.value}</div>;
    },
    [
      disableAnswerSubmissions,
      getQuestionById,
      handlers,
      labelAndIconForOpenModalButton,
      relevantEntitiesById,
      relevantEntitiesTotal,
      t
    ]
  );

  const renderResponseTypeOther = useCallback(
    ({ cell }) => {
      if (
        [
          smartFormResponseType.WEBSHEET,
          smartFormResponseType.DOCUMENT
        ].includes(cell.value)
      ) {
        return <></>;
      }

      const question = getQuestionById(cell.row.original.questionId);
      if (question.assignedEntities.length === 0 && disableAnswerSubmissions) {
        return <></>;
      }
      if (
        question.assignedEntities.length === 0 &&
        relevantEntitiesTotal > 1 &&
        (!question.relevantEntities || question?.relevantEntities?.length > 1)
      ) {
        return (
          <div className="smart-form-table__action-button">
            <BrandButton
              label={t("requests:requests.ui.smartForm.answerPerEntity.label")}
              onClick={() =>
                handlers[smartFormActions.OPEN_ENTITIES_MODAL](question)
              }
              iconName="lan"
              iconOutlined={true}
              size="small"
              type="text-inherit"
              textTransform="none"
            />
          </div>
        );
      }

      return <></>;
    },
    [
      disableAnswerSubmissions,
      getQuestionById,
      handlers,
      relevantEntitiesTotal,
      t
    ]
  );

  const renderQuestion = useCallback(
    ({ cell }) => {
      const question = getQuestionById(cell.row.original.questionId);
      if (question?.parentQuestionId) {
        return renderDependentQuestion(question);
      }
      if (alwaysShowEntitiesIcon) {
        return (
          <div className="smart-form-table__dependent-question">
            {renderEntityIcon(question)}
            {questionText(question)}
          </div>
        );
      }
      return questionText(question);
    },
    [
      alwaysShowEntitiesIcon,
      getQuestionById,
      questionText,
      renderDependentQuestion,
      renderEntityIcon
    ]
  );

  const columns = useMemo(() => {
    return [
      createColumn({
        Header: "",
        accessor: "progressIndicator",
        width: 10,
        fixedWidth: true,
        Cell: ({ cell }) => {
          return verticalProgressIndicator({ status: cell.value });
        }
      }),
      createColumn({
        Header: "",
        accessor: "text",
        width: 300,
        handleSortChange: "",
        Cell: renderQuestion
      }),
      createColumn({
        Header: "",
        id: "responseTypeIcon",
        accessor: "responseType",
        className: "responseTypeIcon",
        width: 20,
        handleSortChange: "",
        Cell: renderResponseTypeIcon
      }),
      createColumn({
        Header: "",
        accessor: "responseType",
        width: 100,
        Cell: renderResponseType
      }),
      createColumn({
        Header: "",
        accessor: "responseType",
        id: "response",
        width: 100,
        className: "responseTypeOther",
        Cell: renderResponseTypeOther
      }),
      createColumn({
        Header: "",
        accessor: "comment",
        width: 50,
        fixedWidth: true,
        Cell: renderCommentCell
      })
    ];
  }, [
    createColumn,
    renderQuestion,
    renderResponseTypeIcon,
    renderResponseType,
    renderResponseTypeOther,
    renderCommentCell,
    verticalProgressIndicator
  ]);

  return (
    <DataTable
      ref={tableRef}
      className="smart-form-table"
      columns={columns}
      data={questions}
      hideHeaders={true}
    ></DataTable>
  );
};

export const processedQuestionPropType = PropTypes.shape({
  questionId: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  responseType: PropTypes.oneOf([
    "boolean",
    "text",
    "singleChoice",
    "date",
    "number",
    "document",
    "websheet"
  ]).isRequired,
  additionalInfo: PropTypes.string,
  maxLength: PropTypes.number,
  lineBreaks: PropTypes.bool,
  options: PropTypes.array,
  progressIndicator: PropTypes.string.isRequired,
  isAnsweredGlobally: PropTypes.bool,
  answeredEntities: PropTypes.arrayOf(PropTypes.number),
  assignedEntities: PropTypes.arrayOf(PropTypes.number),
  answers: PropTypes.arrayOf(PropTypes.object)
});

SmartFormTable.defaultProps = {};

SmartFormTable.propTypes = {
  questions: PropTypes.arrayOf(processedQuestionPropType),
  handlers: PropTypes.shape({
    openCommentModal: PropTypes.func,
    openEntitiesModal: PropTypes.func,
    saveAnswers: PropTypes.func,
    supplyAnswer: PropTypes.func
  }),
  relevantEntitiesById: PropTypes.any,
  disableAnswerSubmissions: PropTypes.bool,
  highlightedQuestionId: PropTypes.number,
  alwaysShowEntitiesIcon: PropTypes.bool
};

export default SmartFormTable;
