import { systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { handleErrorResponse, handleResponse } from "./";

const baseURL = systemConstants.serverURL;

export const clientDocumentService = {
  getClientDocuments,
  getClientDocumentsBySearchString,
  updateClientDocumentProperties,
  getClientDocumentRevisions,
  updateClientDocumentCurrentRevision,
  uploadClientDocument,
  getArchivedClientDocuments,
  deleteClientDocument,
  restoreClientDocument
};

function getClientDocuments(clientId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`${baseURL}/api/clients/${clientId}/documents`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getClientDocumentsBySearchString(client, search) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/clients/${client.id}/documents/search?string=${search}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getArchivedClientDocuments(clientId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    `${baseURL}/api/clients/${clientId}/documents/archived`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function updateClientDocumentProperties(clientId, document) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(document)
  };
  return fetch(
    `${baseURL}/api/clients/${clientId}/documents/${document.id}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getClientDocumentRevisions(clientId, documentId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/clients/${clientId}/documents/${documentId}/documentRevisions`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function updateClientDocumentCurrentRevision(clientId, revision) {
  revision.clientId = clientId;
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(revision)
  };
  return fetch(
    `${baseURL}/api/clients/${clientId}/documents/${revision.documentId}/documentRevisions/${revision.id}/current`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

// upload a new ClientDocument
function uploadClientDocument(clientId, document) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(document)
  };

  return fetch(`${baseURL}/api/clients/${clientId}/documents`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function restoreClientDocument(clientId, document) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(document)
  };
  return fetch(
    `${baseURL}/api/clients/${clientId}/documents/${document.id}/restore`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function deleteClientDocument(clientId, document) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/clients/${clientId}/documents/${document.id}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}
