import React from "react";

import PropTypes from "prop-types";

import ModalContentTemplate from "@components/templates/ModalContentTemplate/ModalContentTemplate";

import "./SmartFormCommentsModalTemplate.scss";

function SmartFormCommentsModalTemplate(props) {
  const {
    title,
    commentComposer,
    commentsThread,
    loadingOrNoContentMessage,
    onClose,
    leftSidePanel,
    conversationTitle,
    isNewComment
  } = props;
  const getBodyContent = () => {
    return (
      <div className="smart-form-comments-modal-template__comments-thread">
        {commentsThread}
      </div>
    );
  };

  const getFooterContent = () => {
    return (
      <div className="smart-form-comments-modal-template__composer">
        {commentComposer}
      </div>
    );
  };

  const getLeftSidePanelContent = () => {
    return (
      <div className="smart-form-comments-modal-template__left-side-panel">
        {leftSidePanel}
      </div>
    )
  }

  return (
    <ModalContentTemplate
      onClose={onClose}
      modalClassName="smart-form-comments-modal-template"
      header={{ title, colouredBg: true, expandable: true }}
      body={{ 
        content: getBodyContent(), 
        loadingOrNoContentMessage,
        conversationTitle,
        isNewComment
      }}
      footer={{
        content: getFooterContent(),
        hideCancel: true,
        handleCancel: onClose,
        buttonOverride: <></>,
        seperator: true
      }}
      leftSidePanel={getLeftSidePanelContent()}
    />
  );
}

SmartFormCommentsModalTemplate.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  commentComposer: PropTypes.node.isRequired,
  commentsThread: PropTypes.node.isRequired,
  loadingOrNoContentMessage: PropTypes.string,
  leftSidePanel: PropTypes.node,
  conversationTitle: PropTypes.string,
  isNewComment: PropTypes.bool
};

export default SmartFormCommentsModalTemplate;
