import React, { useCallback, useRef } from "react";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useRequestPageNavigator } from "@shared/hooks/useRequestPageNavigator";

import Button from "@components/atoms/Button";
import ToastMessage from "@components/molecules/ToastMessage";

export function useToasts() {
  const { t } = useTranslation();
  const { navigateToRequestPage } = useRequestPageNavigator();
  const toastId = useRef(null);

  const showActionItemCreatedToast = useCallback(
    ({ project, request, actionItemType }) => {
      if (!request?.id || !project?.id) {
        return;
      }

      const notify = () =>
        (toastId.current = toast(
          <ToastMessage
            type={"success"}
            title={t("requests:requests.ui.toastMessages.requestCreatedTitle")}
            actions={
              <Button
                label={t(
                  "requests:requests.ui.toastMessages.viewRequestActionLabel"
                )}
                type="text"
                size="minimal"
                onClick={openRequest}
              />
            }
          />
        ));

      const dismiss = () => toast.dismiss(toastId.current);
      const openRequest = () => {
        navigateToRequestPage(request.id, project.id, actionItemType, {
          websheetOpensNewWindow: true
        });
        dismiss();
      };
      notify();
    },
    [navigateToRequestPage, t]
  );

  const showActionItemsCopiedToast = useCallback(() => {
    toast(
      <ToastMessage
        type="success"
        title={t("requests:requests.ui.toastMessages.copyRequestsCreatedTitle")}
      />
    );
  }, [t]);

  const showApplyNewCleanToast = useCallback(() => {
    toast(
      <ToastMessage
        type="success"
        title={t(
          "common:ui.websheet.actions.cleaningWizard.notification.applyNew"
        )}
      />
    );
  }, [t]);

  const showInsightsBoardEditedToast = useCallback(() => {
    toast(
      <ToastMessage
        type="warning"
        title={t(
          "common:insights.board.page.toastMessages.modifiedByAnotherUser"
        )}
      />
    );
  }, [t]);

  const showSaveCleanToast = useCallback(
    ({ fileName, templateName }) => {
      toast(
        <ToastMessage
          type="success"
          title={t(
            "common:ui.websheet.actions.cleaningWizard.notification.saveTemplate",
            {
              fileName,
              templateName
            }
          )}
        />
      );
    },
    [t]
  );

  const showSuccess = useCallback(
    ({ key }) => {
      toast(<ToastMessage type="success" title={t(key)} />);
    },
    [t]
  );

  const showError = useCallback(
    ({ key }) => {
      toast(<ToastMessage type="error" title={t(key)} />);
    },
    [t]
  );

  const showSendToHostSuccess = useCallback(() => {
    toast(
      <ToastMessage
        type="success"
        title={t(
          "requests:requests.ui.toastMessages.sendRequest.success.title"
        )}
      />
    );
  }, [t]);

  const showSendToHostErrorToast = useCallback(
    ({ host }) => {
      toast(
        <ToastMessage
          type="error"
          title={t(
            "requests:requests.ui.toastMessages.sendRequest.error.title"
          )}
          description={t(
            "requests:requests.ui.toastMessages.sendRequest.error.description",
            { host }
          )}
          actions={
            <Button
              label={t("requests:requests.ui.toastActions.refresh")}
              type="text"
              size="minimal"
              iconName="refresh"
              iconSize="medium"
              onClick={() => {
                window.location.reload();
              }}
            />
          }
        />
      );
    },
    [t]
  );

  return {
    showActionItemCreatedToast,
    showActionItemsCopiedToast,
    showInsightsBoardEditedToast,
    showApplyNewCleanToast,
    showSaveCleanToast,
    showSuccess,
    showError,
    showSendToHostSuccess,
    showSendToHostErrorToast
  };
}
