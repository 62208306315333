import React from "react";

import DropdownSelect from "@components/atoms/DataTableDropdownSelect";

const DrilldownFilter = props => {
  const {
    filterLabel,
    onChange,
    selectedFilterOption,
    currentDrilldownFilter,
    filterItems
  } = props;

  const checkFilterOptions = filterItems.some(
    item => item.label === currentDrilldownFilter?.drilldownFilter.label
  );

  return (
    <div>
      <DropdownSelect
        optionalLabel={`Filter by ${filterLabel}: `}
        label={filterLabel}
        items={filterItems}
        value={checkFilterOptions ? selectedFilterOption : filterItems[0].value}
        width="100%"
        onChange={onChange}
        selectedItemHeader
      />
    </div>
  );
};

export default DrilldownFilter;
