import React, { useMemo } from "react";

import PropTypes from "prop-types";

import Avatar from "@components/atoms/Avatar";
import ContactDetails from "@components/atoms/ContactDetails";
import DropdownDotMenu from "@components/atoms/DropdownDotMenu/DropdownDotMenu";

import "./Contact.scss";

const Contact = props => {
  const { user, handleUserActionClick, isDragging, enableDrag, menuItems } =
    props;

  const userAction = useMemo(() => {
    if (!menuItems.length) {
      return <></>;
    }
    return (
      <DropdownDotMenu
        positionRelative
        menuItems={menuItems}
        onMenuItemClick={handleUserActionClick}
      />
    );
  }, [handleUserActionClick, menuItems]);

  return (
    <>
      <div
        className={`contact-container ${
          enableDrag ? "contact-container--draggable" : ""
        }${isDragging ? "contact-container--dragging" : ""}`}
        key={user.id}
      >
        <div className="contact-details">
          <Avatar size={"large"} user={user} />
          <ContactDetails user={user} />
        </div>
        {userAction}
      </div>
    </>
  );
};

Contact.defaultProps = {
  isShowIcon: false,
  enableDrag: false
};

Contact.propTypes = {
  user: PropTypes.object.isRequired,
  handleUserActionClick: PropTypes.func,
  isShowIcon: PropTypes.bool,
  enableDrag: PropTypes.bool,
  showMenuItems: PropTypes.bool
};

export default Contact;
