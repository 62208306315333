import React, { useCallback, useMemo } from "react";

import PropTypes from "prop-types";
import * as yup from "yup";

import {
  getOperatorsForNumberTypes,
  getOperatorsForOtherTypes
} from "@shared/helpers/interactiveReportOperators";

import ModalForm from "@components/molecules/ModalForm";

import InteractiveReportFilterForm from "./InteractiveReportFilterForm";
import "./InteractiveReportFilterModal.scss";

const operatorsForColumnTypes = {
  number: getOperatorsForNumberTypes(),
  other: getOperatorsForOtherTypes()
};
const InteractiveReportFilterModal = ({
  onCancelModal,
  onApplyFilters,
  tableColumns,
  filterConditions
}) => {
  const saveFilter = formData => {
    onApplyFilters({
      conditions: formData.conditions.map(fc => ({
        field: fc.field?.value?.name,
        operator: fc.operator?.value,
        value: fc.value
      }))
    });
  };

  const columnsForFilter = useMemo(
    () =>
      tableColumns.map(c => ({
        name: c.name,
        value: c,
        lookup: c.lookup
      })),
    [tableColumns]
  );

  const getDefaultValueForColumnField = useCallback(
    fieldName => columnsForFilter.find(c => c.name === fieldName),
    [columnsForFilter]
  );

  const getDefaultValueForOperator = useCallback(operatorCode => {
    return Object.values(operatorsForColumnTypes)
      .flat()
      .find(c => c.value === operatorCode);
  }, []);

  const initialState = useMemo(() => {
    let conditions = [];
    if (filterConditions.length) {
      conditions = filterConditions?.map(({ field, operator, value }) => ({
        field: getDefaultValueForColumnField(field),
        operator: getDefaultValueForOperator(operator),
        value
      }));
    } else {
      // special case to initialise the form with one entry ready to go for better UX
      conditions = [
        {
          field: null,
          operator: null,
          value: null
        }
      ];
    }
    return {
      conditions
    };
  }, [
    filterConditions,
    getDefaultValueForColumnField,
    getDefaultValueForOperator
  ]);

  const yupSchema = useMemo(
    () =>
      yup.object({
        conditions: yup.array().of(
          yup
            .object()
            .shape({
              field: yup.object().nullable().required("Please select a field"),
              operator: yup
                .object()
                .nullable()
                .required("Please select an option"),
              value: yup
                .mixed()
                .transform(hasValue => (!hasValue ? undefined : hasValue))
                .required("Please enter a value")
            })
            .test(
              "value-matches-fieldType",
              "${path}.value not correct type",
              function (item) {
                if (item.field?.value?.type === "number") {
                  const value = item.value;
                  try {
                    yup.number().validateSync(value);
                    return true;
                  } catch (err) {
                    return this.createError({
                      path: `${this.path}.value`,
                      message: "Please enter a numerical value"
                    });
                  }
                } else if (!item.field?.value) {
                  return this.createError({
                    path: `${this.path}.value`,
                    message: "Please enter a value"
                  });
                }
                return true;
              }
            )
        )
      }),
    []
  );

  const modalTitle = useMemo(() => {
    return filterConditions?.length ? "Edit Filter" : "Create Filter";
  }, [filterConditions]);

  return (
    <ModalForm
      boxClassName="filter-modal"
      handleSubmit={saveFilter}
      handleCancel={onCancelModal}
      submitLabel={"Apply"}
      cancelLabel={"Cancel"}
      yupSchema={yupSchema}
      title={modalTitle}
      defaultValues={initialState}
    >
      <InteractiveReportFilterForm
        columnsForFilter={columnsForFilter}
        operatorsForColumnTypes={operatorsForColumnTypes}
        parentId="modal-form"
      ></InteractiveReportFilterForm>
    </ModalForm>
  );
};

InteractiveReportFilterModal.propTypes = {
  onCancelModal: PropTypes.func.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  tableColumns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string
    })
  ),
  filterConditions: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      operator: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  )
};

export default InteractiveReportFilterModal;
