import React from "react";

import PropTypes from "prop-types";

import BrandButton from "./BrandButton";
import "./Button.scss";
import Logout from "./Logout";

const Button = props => {
  const { type, onClick, disabled, label, size } = props;

  const handleClick = e => {
    e.preventDefault();
    onClick(e);
  };

  const isBrandButton = () => {
    return [
      "primary",
      "primary-dark",
      "secondary",
      "tertiary",
      "text",
      "text-dark",
      "danger"
    ].includes(type);
  };

  return (
    <>
      {type == "button" && (
        <button onClick={e => handleClick(e)}>{props.children}</button>
      )}
      {type == "logout" && <Logout handleLogout={e => handleClick(e)} />}
      {isBrandButton() && (
        <BrandButton
          {...props}
          onClick={e => handleClick(e)}
          type={type}
          disabled={disabled}
          label={label}
          size={size}
        ></BrandButton>
      )}
    </>
  );
};

Button.defaultProps = {
  type: "button"
};

Button.propTypes = {
  ...BrandButton.propTypes,
  type: PropTypes.oneOf([
    "button",
    "logout",
    "primary",
    "primary-dark",
    "secondary",
    "tertiary",
    "text",
    "text-dark",
    "danger"
  ]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string
};

export default Button;
