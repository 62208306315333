import React, { useContext, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useStaticAssets } from "@shared/hooks";

import UIConfigContext from "@app/helpers/UIConfigContext";
import { classNames } from "@app/helpers/componentHelpers";

import HeaderMenu from "@components/molecules/HeaderMenu";
import LanguageToggle from "@components/molecules/LanguageToggle";
import LogoNav from "@components/molecules/LogoNav";

import "./HeadingTemplate.scss";

const HeadingTemplate = props => {
  const {
    searchBar,
    landingPageLink,
    showGroupLabel,
    groupLabels,
    menuItems,
    user,
    showSideBar,
    adminNavItems,
    isolationMode
  } = props;
  const { t } = useTranslation();
  const uiConfig = useContext(UIConfigContext);

  const [hostLanguages, setHostLanguages] = useState(null);
  const { authentication } = useSelector(_state => _state);
  const [logoLink, setLogoLink] = useState(landingPageLink);
  const { getUrl, setFavIcon } = useStaticAssets();

  useEffect(() => {
    if (authentication.host) {
      setHostLanguages(authentication.host?.host?.properties?.i18n);
      document.title = authentication.host.name;
      setFavIcon();
    }
  }, [authentication.host, setFavIcon]);

  useEffect(() => {
    setLogoLink(landingPageLink || "");
  }, [landingPageLink]);

  const renderGroupLabels = useMemo(
    () => (
      <div className="heading__groupLabel">
        {showGroupLabel ? <>{groupLabels}</> : ""}
      </div>
    ),
    [showGroupLabel, groupLabels]
  );

  return (
    <nav className={classNames(["heading", uiConfig.theme.navigationStyle])}>
      <div
        className={classNames(["heading__bar", uiConfig.theme.navigationStyle])}
      >
        <div className="heading__bar__left">
          <div className="heading__logo">
            <LogoNav logo={getUrl("headerLogo.png")} link={logoLink} />
          </div>
          <div className="heading__name">{t("common:ui.headingName")}</div>
          {!uiConfig?.sideNavigation?.enabled && renderGroupLabels}
        </div>
        {!isolationMode && (
          <div className="heading__bar__right">
            {searchBar && (
              <div className="heading__search-bar">{searchBar}</div>
            )}
            {uiConfig?.sideNavigation?.enabled && renderGroupLabels}
            {hostLanguages && (
              <LanguageToggle
                languages={hostLanguages?.languages}
                defaultLng={hostLanguages?.default}
              />
            )}

            {showSideBar && adminNavItems?.length > 0 && (
              <HeaderMenu
                menuItems={adminNavItems}
                icon={"admin_panel_settings"}
              />
            )}
            {menuItems && <HeaderMenu menuItems={menuItems} user={user} />}
          </div>
        )}
      </div>
      {props.children}
    </nav>
  );
};

HeadingTemplate.defaultProps = {
  searchBar: null
};

HeadingTemplate.propTypes = {
  searchBar: PropTypes.element,
  showSideBar: PropTypes.bool,
  landingPageLink: PropTypes.string,
  showGroupLabel: PropTypes.bool,
  groupLabels: PropTypes.element,
  menuItems: PropTypes.arrayOf(PropTypes.object),
  adminNavItems: PropTypes.arrayOf(PropTypes.object),
  user: PropTypes.object,
  isolationMode: PropTypes.bool,
  children: PropTypes.element.isRequired
};

export default HeadingTemplate;
