import React from "react";

import PropTypes from "prop-types";

import Loading from "@components/molecules/Loading/Loading";

import PageTemplate from "../PageTemplate";
import "./FormPageTemplate.scss";

function FormPageTemplate(props) {
  const { title, formElement, breadcrumbs, isLoading, other } = props;

  const getForm = () => (
    <div className="ot-form-page-template">{formElement}</div>
  );

  return (
    <PageTemplate
      header={{
        title,
        breadcrumbs
      }}
      other={other}
      body={{ header: null, primary: isLoading ? <Loading /> : getForm() }}
    />
  );
}

FormPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      linkTo: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          pathname: PropTypes.string.isRequired,
          search: PropTypes.string,
          hash: PropTypes.string,
          state: PropTypes.object
        })
      ]),
      label: PropTypes.string.isRequired
    })
  ),
  formElement: PropTypes.element.isRequired,
  isLoading: PropTypes.bool,
  other: PageTemplate.propTypes.other
};

export default FormPageTemplate;
