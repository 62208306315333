export const menuConstants = {
  menuItemNames: {
    dashboard: "Dashboard",
    projects: "Projects",
    project: "Project",
    home: "Home",
    insights: "Insights",
    teams: "Teams",
    clientData: "Your Data Repository",
    permanentFiles: "Permanent Files",
    engagements: "Engagements",
    myProfile: "My Profile",
    admin: "Admin"
  },
  menuItemTypes: {
    NAV_LINK: "NAV_LINK",
    NAV_DROPDOWN: "NAV_DROPDOWN",
    NAV_SEARCHABLE_DROPDOWN: "NAV_SEARCHABLE_DROPDOWN",
    NAV_MULTILEVEL_DROPDOWN: "NAV_MULTILEVEL_DROPDOWN",
    NAV_SUBMENU: "NAV_SUBMENU",
    NAV_BOOKMARK: "NAV_BOOKMARK",
    NAV_SECTION_TITLE: "NAV_SECTION_TITLE"
  },
  sideNavContext: {
    ALL_PROJECTS: "ALL_PROJECTS",
    PROJECT: "PROJECT",
    CLIENT: "CLIENT",
    ADMIN: "ADMIN"
  }
};
