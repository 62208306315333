import React, { useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { useGetClientsQuery } from "@shared/hooks";

import DropdownInput from "@components/atoms/DropdownInput";
import Form from "@components/atoms/Form";

function SelectClientDropdown(props) {
  const { t } = useTranslation();
  const { name, onChange, hideIfOne, isFormField, defaultValue, required } =
    props;
  const { data: clients } = useGetClientsQuery();
  const [selectedClient, setSelectedClient] = useState(defaultValue);

  useEffect(() => {
    if (selectedClient?.value) {
      onChange?.(selectedClient.value);
    }
  }, [onChange, selectedClient]);

  const dropdownItems = useMemo(
    () =>
      clients?.map(c => ({
        name: c.name,
        value: { id: c.id, name: c.name }
      })),
    [clients]
  );

  const clientDropdown = useMemo(() => {
    if (isFormField) {
      return (
        <Form.Dropdown
          name={name ?? "client"}
          label={t("stringFormat.propercase", {
            key: "common:clients.displayName_one"
          })}
          items={dropdownItems}
          defaultValue={selectedClient}
          onChange={setSelectedClient}
          required={required}
          showSearchFilter
          placeholder={t("common:ui.forms.placeholder.selectionOption", {
            indefiniteArticle: "a",
            label: t("stringFormat.propercase", {
              key: "common:clients.displayName_one"
            })
          })}
        />
      );
    }

    return (
      <DropdownInput
        label={t("stringFormat.propercase", {
          key: "common:clients.displayName_one"
        })}
        items={dropdownItems}
        value={selectedClient}
        showSearchFilter
        onChange={setSelectedClient}
        required={required}
        placeholder={t("common:ui.forms.placeholder.selectionOption", {
          indefiniteArticle: "a",
          label: t("stringFormat.propercase", {
            key: "common:clients.displayName_one"
          })
        })}
      />
    );
  }, [dropdownItems, isFormField, name, required, selectedClient, t]);

  if (hideIfOne && clients?.length === 1) {
    return isFormField ? (
      <Form.HiddenInput
        name={name ?? "client"}
        value={{ name: clients[0].name, value: clients[0] }}
      />
    ) : (
      <></>
    );
  }

  return <div>{clientDropdown}</div>;
}

SelectClientDropdown.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  hideIfOne: PropTypes.bool,
  isFormField: PropTypes.bool,
  defaultValue: PropTypes.object,
  required: PropTypes.bool
};

export default SelectClientDropdown;
