import React, { useCallback, useEffect, useState } from "react";

import * as EmailValidator from "email-validator";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { manageAuditActions } from "@shared/actions";

import AuditRecords from "@shared-components/pages/host/manageAudits/AuditRecords";

import Button from "@components/atoms/Button/BrandButton";
import BrandButton from "@components/atoms/Button/BrandButton";
import TextInput from "@components/atoms/TextInput/TextInput";
import PageTemplate from "@components/templates/PageTemplate/PageTemplate";

import "./ManageAudits.scss";

const ManageAudits = props => {
  const { t } = props;
  const dispatch = useDispatch();
  const manageAudit = useSelector(state => state.manageAudit);
  const [user, setUser] = useState({ email: "" });
  const [getReportSubmitted, setGetReportSubmitted] = useState(false);
  const inputFocusRef = React.createRef();
  const [error, setError] = useState("");
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noRecordsFound, setNoRecordsFound] = useState(false);
  const [email, setEmail] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(manageAuditActions.reset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (inputFocusRef.current) {
      inputFocusRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFocusRef.current]);

  const handleInputFocus = () => {
    setInputFocus(true);
  };
  const handleInputFocusOut = () => {
    setInputFocus(false);
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.activeElement, inputFocus, user]);

  const handleKeyPress = event => {
    if (event.key === "Enter" && inputFocus) {
      event.preventDefault();
      handleGetAuditReport();
    }
  };

  useEffect(() => {
    setError(manageAudit.error);
  }, [manageAudit.error]);

  useEffect(() => {
    setRecords(manageAudit.records);
  }, [manageAudit.records]);

  useEffect(() => {
    if (!manageAudit.loading && getReportSubmitted) {
      if (!manageAudit.records.length) {
        setNoRecordsFound(true);
      }
      setRecords(manageAudit.records);
      setLoading(false);
      setGetReportSubmitted(false);
    } else if (manageAudit.loading) {
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageAudit.loading]);

  const handleEmailChange = useCallback(
    event => {
      dispatch(manageAuditActions.setError(""));
      setGetReportSubmitted(false);
      const emailChanged = event.target.value;
      setUser({ ...user, email: emailChanged });
    },
    [dispatch, user]
  );

  const handleGetAuditReport = useCallback(() => {
    setGetReportSubmitted(true);
    setNoRecordsFound(false);
    if (user && user.email && EmailValidator.validate(user.email)) {
      setEmail(user.email);
      dispatch(manageAuditActions.getAuditRecordsByUser(user));
    } else {
      dispatch(manageAuditActions.setError("Please enter valid email address"));
      setGetReportSubmitted(false);
    }
  }, [dispatch, user]);

  const getLoading = useCallback(() => {
    if (!email) {
      return "No report generated.";
    }
    if (loading) {
      return "Loading...";
    } else if (noRecordsFound) {
      return "No records found.";
    }
    return "";
  }, [email, loading, noRecordsFound]);

  const getBodyContent = useCallback(
    () =>
      manageAudit.records.length ? (
        <div className="manage-audits__container--body-records">
          <div className="manage-audits__container--title">{`Report for ${email}`}</div>
          {records.map((record, index) => (
            <AuditRecords key={index} data={record} reportFor={email} />
          ))}
        </div>
      ) : (
        <></>
      ),
    [email, manageAudit.records.length, records]
  );

  return (
    <PageTemplate
      header={{
        title: t("ui.sideNavigation.admin.auditReport"),
        content: (
          <div className="manage-audits__container--body-input-fields">
            <div className="manage-audits__container--body-input-fields-input">
              <TextInput
                type="text"
                label="Please enter user's email address"
                error={!!error}
                onChange={handleEmailChange}
                value={user.email || ""}
                ref={inputFocusRef}
                maxLength={255}
                onFocus={handleInputFocus}
                onBlur={handleInputFocusOut}
              />
            </div>
            <div className="manage-audits__container--body-input-fields-button">
              <BrandButton
                disabled={!user.email}
                label={"Get Audit Report"}
                onClick={handleGetAuditReport}
              />
            </div>
          </div>
        )
      }}
      body={{
        primary: getBodyContent()
      }}
      other={{
        error,
        loading: getLoading()
      }}
    />
  );
};

export default withTranslation()(ManageAudits);
