import { manageHostUsersConstants } from "../constants";

const initialState = {
  loading: false,
  users: [],
  groups: [],
  roles: [],
  error: "",
  addedUser: false,
  updatedUser: false,
  bulkUploadUsersTemplate: null,
  bulkUploadedUsers: [],
  engagementTypesWithTags: [],
  engagementTypes: [],
  addedTag: null,
  deletedTag: null,
  updatedTag: null,
  uploadingUserLoading: null
};

export function manageHostUsers(state = initialState, action = {}) {
  switch (action.type) {
    case manageHostUsersConstants.UPLOAD_BULK_USERS_REQUEST:
      return {
        ...state,
        uploadingUserLoading: true
      };
    case manageHostUsersConstants.UPLOAD_BULK_USERS_FAILURE:
      return {
        ...state,
        error: action.error.key ?? action.error.message,
        uploadingUserLoading: null
      };
    case manageHostUsersConstants.UPLOAD_BULK_USERS_SUCCESS:
      return {
        ...state,
        bulkUploadedUsers: action.users,
        uploadingUserLoading: null
      };
    case manageHostUsersConstants.GET_HOST_USERS_REQUEST:
    case manageHostUsersConstants.DOWNLOAD_BULK_USERS_UPLOAD_TEMPLATE_REQUEST:
    case manageHostUsersConstants.GET_HOST_GROUPS_REQUEST:
    case manageHostUsersConstants.GET_HOST_ROLES_REQUEST:
    case manageHostUsersConstants.ADD_HOST_USER_REQUEST:
    case manageHostUsersConstants.UPDATE_HOST_USER_REQUEST:
    case manageHostUsersConstants.GET_ENGAGEMENT_TYPES_WITH_TAGS_REQUEST:
    case manageHostUsersConstants.GET_ENGAGEMENT_TYPES_REQUEST:
    case manageHostUsersConstants.CREATE_TAG_REQUEST:
    case manageHostUsersConstants.UPDATE_TAG_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageHostUsersConstants.GET_HOST_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.users,
        error: ""
      };
    case manageHostUsersConstants.GET_HOST_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        users: [],
        error: action.error.key ?? action.error.message
      };

    case manageHostUsersConstants.GET_HOST_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        groups: action.groups,
        error: ""
      };
    case manageHostUsersConstants.GET_HOST_GROUPS_FAILURE:
      return {
        ...state,
        loading: false,
        groups: [],
        error: action.error.key ?? action.error.message
      };

    case manageHostUsersConstants.GET_HOST_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: action.roles,
        error: ""
      };
    case manageHostUsersConstants.GET_HOST_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        roles: [],
        error: action.error.key ?? action.error.message
      };

    case manageHostUsersConstants.ADD_HOST_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        addedUser: true,
        error: ""
      };
    case manageHostUsersConstants.ADD_HOST_USER_FAILURE:
      return {
        ...state,
        loading: false,
        addedUser: false,
        error: action.error.key ?? action.error.message
      };

    case manageHostUsersConstants.UPDATE_HOST_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        updatedUser: true,
        error: ""
      };
    case manageHostUsersConstants.UPDATE_HOST_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.key ?? action.error.message
      };
    case manageHostUsersConstants.CLEAR_ERROR:
      return {
        ...state,
        error: ""
      };
    case manageHostUsersConstants.CLEAR_ADD_HOST_USER_FLAG:
      return {
        ...state,
        error: "",
        addedUser: false,
        groups: [],
        roles: []
      };
    case manageHostUsersConstants.CLEAR_UPDATE_HOST_USER_FLAG:
      return {
        ...state,
        error: "",
        updatedUser: false,
        groups: [],
        roles: []
      };
    case manageHostUsersConstants.RESET:
      return initialState;

    case manageHostUsersConstants.DOWNLOAD_BULK_USERS_UPLOAD_TEMPLATE_SUCCESS:
      return {
        ...state,
        error: "",
        bulkUploadUsersTemplate: action.blob
      };
    case manageHostUsersConstants.DOWNLOAD_BULK_USERS_UPLOAD_TEMPLATE_FAILURE:
    case manageHostUsersConstants.GET_ENGAGEMENT_TYPES_WITH_TAGS_FAILURE:
    case manageHostUsersConstants.GET_ENGAGEMENT_TYPES_FAILURE:
    case manageHostUsersConstants.CREATE_TAG_FAILURE:
    case manageHostUsersConstants.UPDATE_TAG_FAILURE:
    case manageHostUsersConstants.DELETE_TAG_FAILURE:
      return {
        ...state,
        error: action.error.key ?? action.error.message
      };

    case manageHostUsersConstants.GET_ENGAGEMENT_TYPES_WITH_TAGS_SUCCESS:
      return {
        ...state,
        engagementTypesWithTags: action.engagementTypes
      };
    case manageHostUsersConstants.GET_ENGAGEMENT_TYPES_SUCCESS:
      return {
        ...state,
        engagementTypes: action.engagementTypes
      };
    case manageHostUsersConstants.CREATE_TAG_SUCCESS:
      return {
        ...state,
        addedTag: action.tag
      };
    case manageHostUsersConstants.DELETE_TAG_SUCCESS:
      return {
        ...state,
        deletedTag: action.tag
      };
    case manageHostUsersConstants.UPDATE_TAG_SUCCESS:
      return {
        ...state,
        updatedTag: action.tag
      };
    default:
      return state;
  }
}
