import React, { useCallback, useMemo } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { menuItemAccess } from "@app/helpers/menuItemAccess";

import BrandButton from "@components/atoms/Button/BrandButton";
import Button from "@components/atoms/Button/Button";
import HoverText from "@components/atoms/HoverText/HoverText";
import Toggle from "@components/atoms/Toggle/Toggle";

import "./WebSheetActions.scss";

export const ACTION = {
  reassign: "REASSIGN",
  transition: "TRANSITION",
  review: "REVIEW",
  approve: "APPROVE",
  reject: "REJECT",
  complete: "COMPLETE",
  cleaningWizard: "CLEANING_WIZARD",
  incomplete: "INCOMPLETE"
};

export const WebSheetActions = props => {
  const {
    type,
    requestHandlers,
    disabledStates,
    customStates = {},
    isReadOnly,
    user,
    activeStates,
    isComplete,
    isSingleEntity,
    queryActionsConfig
  } = props;

  const { t } = useTranslation();

  const rejectButton = useCallback(
    () => (
      <div
        key="reject"
        className="edit-document__button edit-document__button--reject"
      >
        <Button
          type="danger"
          disabled={disabledStates[ACTION.reject]}
          onClick={requestHandlers[ACTION.reject]}
          label={t("common:ui.websheet.actions.reject")}
        />
      </div>
    ),
    [disabledStates, requestHandlers, t]
  );

  const reassignButton = useCallback(
    () => (
      <div
        key="reassign"
        className="edit-document__button edit-document__button--reassign"
      >
        <Button
          type="secondary"
          onClick={requestHandlers[ACTION.reassign]}
          label={t("common:ui.websheet.actions.reassign")}
          disabled={disabledStates[ACTION.reassign]}
        />
      </div>
    ),
    [disabledStates, requestHandlers, t]
  );

  const approveButton = useCallback(
    () => (
      <div
        key="approve"
        className="edit-document__button edit-document__button--approve"
      >
        <Button
          type="primary"
          onClick={requestHandlers[ACTION.approve]}
          label={t("common:ui.websheet.actions.approve")}
          disabled={disabledStates[ACTION.approve]}
        />
      </div>
    ),
    [disabledStates, requestHandlers, t]
  );

  const readyForReviewButton = useCallback(
    () => (
      <div
        key="readyForReview"
        className="edit-document__button edit-document__button--markComplete"
      >
        <Button
          disabled={disabledStates[ACTION.review]}
          type="secondary"
          onClick={requestHandlers[ACTION.review]}
          label={t("common:ui.websheet.actions.readyForReview")}
        />
      </div>
    ),
    [disabledStates, requestHandlers, t]
  );

  const disabled = useMemo(() => {
    if (isSingleEntity || isComplete) {
      return false;
    }
    return disabledStates[ACTION.complete];
  }, [isComplete, isSingleEntity, disabledStates]);

  const completeToggleAction = useCallback(
    isComplete => {
      const { isVisible } = customStates[ACTION.complete];
      if (!isVisible) {
        return <></>;
      }

      const onChange = requestHandlers[ACTION.complete];
      const hoverElement =
        !isComplete && disabled
          ? t("common:ui.websheet.actions.complete.hover.entities")
          : null;

      return (
        <div
          key="complete"
          className="edit-document__action edit-document__action--complete"
        >
          <Toggle
            label={t("common:ui.websheet.actions.complete.label")}
            onChange={onChange}
            disabled={disabled}
            value={isComplete}
            showIndicatorLabels={false}
            labelPosition="left"
          />
          {hoverElement && (
            <HoverText
              classNameTarget="edit-document__action--complete__hover-text"
              type="long"
              name={hoverElement}
            />
          )}
        </div>
      );
    },
    [customStates, disabled, requestHandlers, t]
  );

  const cleaningWizardButton = useCallback(() => {
    const {
      isVisible,
      label,
      type,
      hoverElement = null,
      hoverPosition
    } = customStates[ACTION.cleaningWizard];

    const disabled = disabledStates[ACTION.cleaningWizard];
    return (
      isVisible && (
        <div key="cleaningWizard">
          <BrandButton
            disabled={disabled}
            type={type}
            onClick={requestHandlers[ACTION.cleaningWizard]}
            label={label}
            hoverElement={disabled && hoverElement}
            hoverPosition={hoverPosition}
          />
        </div>
      )
    );
  }, [customStates, disabledStates, requestHandlers]);

  const renderTransition = useCallback(
    action => {
      switch (action) {
        case ACTION.review:
          return readyForReviewButton();
        case ACTION.approve:
          return approveButton();
        case ACTION.reject:
          return rejectButton();
      }
    },
    [approveButton, readyForReviewButton, rejectButton]
  );

  const getQueryActions = useCallback(() => {
    if (!queryActionsConfig) {
      return "";
    }

    return queryActionsConfig.map(
      ({ action, transition = { action: "" }, availableTo }) => {
        if (availableTo && !menuItemAccess(availableTo, user)) {
          return <React.Fragment key={action} />;
        }
        if (activeStates && !activeStates.includes(action)) {
          return <React.Fragment key={action} />;
        }

        switch (action) {
          case ACTION.reassign:
            return isReadOnly ? (
              reassignButton()
            ) : (
              <React.Fragment key={action} />
            );
          case ACTION.transition:
            return renderTransition(transition.action);
          case ACTION.complete:
            return completeToggleAction(isComplete);
          case ACTION.cleaningWizard:
            return cleaningWizardButton();
          default:
            return <React.Fragment key={action} />;
        }
      }
    );
  }, [
    activeStates,
    cleaningWizardButton,
    completeToggleAction,
    isComplete,
    isReadOnly,
    queryActionsConfig,
    reassignButton,
    renderTransition,
    user
  ]);

  const getActions = useCallback(() => {
    switch (type) {
      case "query":
      case "smartForm":
        return getQueryActions();
      default:
        return <></>;
    }
  }, [getQueryActions, type]);

  return getActions();
};

WebSheetActions.propTypes = {
  type: PropTypes.oneOf(["document", "query", "smartForm"]).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isComplete: PropTypes.bool.isRequired,
  isSingleEntity: PropTypes.bool.isRequired,
  requestHandlers: PropTypes.any.isRequired, // Key lookup with button as key,
  queryActionsConfig: PropTypes.any.isRequired,
  disabledStates: PropTypes.any.isRequired, // Key lookup with button as key for disable states
  customStates: PropTypes.any, //Alter visibility, type, label, etc
  user: PropTypes.object,
  activeStates: PropTypes.arrayOf(PropTypes.string) //Give visibility to only particular states
};

export default WebSheetActions;
