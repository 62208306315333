import { manageProfileConstants } from "../constants";
import { manageProfileService } from "../services";

export const manageProfileActions = {
  getUserDetails,
  getSupportedMimesForImage,
  updateUser
};

function getSupportedMimesForImage() {
  return dispatch => {
    dispatch(request());

    manageProfileService.getSupportedMimesForImage().then(
      mimesList => {
        dispatch(success(mimesList));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return {
      type: manageProfileConstants.GET_SUPPORTED_MIMES_FOR_IMAGE_REQUEST
    };
  }
  function success(mimesList) {
    return {
      type: manageProfileConstants.GET_SUPPORTED_MIMES_FOR_IMAGE_SUCCESS,
      mimesList
    };
  }
  function failure(error) {
    return {
      type: manageProfileConstants.GET_SUPPORTED_MIMES_FOR_IMAGE_FAILURE,
      error
    };
  }
}

function getUserDetails({ id: userId }) {
  return dispatch => {
    dispatch(request());

    manageProfileService.getUserDetails({ id: userId }).then(
      userObject => {
        dispatch(success(userObject));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: manageProfileConstants.GET_USER_DETAILS_REQUEST };
  }
  function success(userObject) {
    return {
      type: manageProfileConstants.GET_USER_DETAILS_SUCCESS,
      user: userObject
    };
  }
  function failure(error) {
    return { type: manageProfileConstants.GET_USER_DETAILS_FAILURE, error };
  }
}

function updateUser(user) {
  return dispatch => {
    dispatch(request());

    manageProfileService.updateUser(user).then(
      ({ requireLogout, properties }) => {
        dispatch(success({ requireLogout, properties }));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: manageProfileConstants.UPDATE_USER_REQUEST };
  }
  function success({ requireLogout, properties }) {
    return {
      type: manageProfileConstants.UPDATE_USER_SUCCESS,
      requireLogout,
      properties
    };
  }
  function failure(error) {
    return { type: manageProfileConstants.UPDATE_USER_FAILURE, error };
  }
}
