import { useCallback, useEffect, useMemo, useState } from "react";

function cleanHashString(hash) {
  return hash?.replace("#", "") || "";
}

const removeLocationHash = () =>
  window.history.replaceState({}, "", window.location.toString().split("#")[0]);

const invalidHashValues = ["", null, undefined];

export function useUrlHash() {
  const [urlHash, setUrlHash] = useState(cleanHashString(window.location.hash));

  const urlHashDetail = useMemo(() => new URLSearchParams(urlHash), [urlHash]);

  useEffect(() => {
    function handleHashUpdate() {
      setUrlHash(cleanHashString(window.location.hash));
    }

    window.addEventListener("hashchange", handleHashUpdate);
    return () => window.removeEventListener("hashchange", handleHashUpdate);
  }, []);

  const updateUrlHash = useCallback((key, value) => {
    const updatedHashDetail = new URLSearchParams(
      cleanHashString(window.location.hash)
    );
    updatedHashDetail.delete(key);
    if (!invalidHashValues.includes(value)) {
      updatedHashDetail.append(key, String(value));
    }
    const updatedHashString = updatedHashDetail.toString();
    if (!updatedHashString.length) {
      removeLocationHash();
    } else {
      window.location.hash = updatedHashString;
    }
  }, []);

  return { urlHashDetail, updateUrlHash, clearUrlHash: removeLocationHash };
}
