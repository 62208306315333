import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { finalPackageActions } from "@shared/actions";

export function useAddFinalPackageProcess(project) {
  const dispatch = useDispatch();
  const finalPackage = useSelector(state => state.finalPackage);
  const [createError, setCreateError] = useState();
  const [isCreating, setIsCreating] = useState();
  const [createdEntry, setCreatedEntry] = useState();

  const resetCreateProcessEntry = useCallback(() => {
    dispatch(finalPackageActions.resetCreateFinalPackageProcessEntry());
  }, [dispatch]);

  const createProcessEntry = useCallback(
    entry => {
      dispatch(finalPackageActions.createFinalPackageProcessEntry(entry));

      return () => {
        dispatch(finalPackageActions.resetCreateFinalPackageProcessEntry());
      };
    },
    [dispatch]
  );

  useEffect(() => {
    setCreateError(finalPackage.finalPackageProcessCreateError);
  }, [finalPackage.finalPackageProcessCreateError]);

  useEffect(() => {
    setIsCreating(finalPackage.isFinalPackageProcessCreating);
  }, [finalPackage.isFinalPackageProcessCreating]);

  useEffect(() => {
    setCreatedEntry(finalPackage.finalPackageProcessCreated);
  }, [finalPackage.finalPackageProcessCreated]);

  return {
    resetCreateProcessEntry,
    createProcessEntry,
    createError,
    isCreating,
    createdEntry
  };
}
