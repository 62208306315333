import React, { useEffect, useState } from "react";

import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { routeConstants } from "@constants";

import { manageHostUsersActions } from "@shared/actions";
import { systemConstants } from "@shared/constants";
import { loggedUser } from "@shared/helpers";

import Button from "@components/atoms/Button/BrandButton";
import DropdownPagination from "@components/molecules/DropdownPagination";
import TagsDataTable from "@components/organisms/GlobalTagsDataTable";
import MainPageTemplate from "@components/templates/MainPageTemplate";
import PageBodyTemplate from "@components/templates/PageBodyTemplate";

import "./GlobalTags.scss";

const GlobalTags = ({ t }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const manageHostUsers = useSelector(state => state.manageHostUsers);
  const [tags, setTags] = useState([]);
  const authentication = useSelector(state => state.authentication);
  const [pagination, setPagination] = useState({
    countPerPage: systemConstants.pagination.itemCountPerPage,
    pageCount: 1,
    currentPage: "Page 1",
    currentPageIndex: 0,
    pages: []
  });
  useEffect(() => {
    dispatch(manageHostUsersActions.getEngagementTypesWithTags());
    return () => {
      dispatch(manageHostUsersActions.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (manageHostUsers.deletedTag) {
      dispatch(manageHostUsersActions.getEngagementTypesWithTags());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageHostUsers.deletedTag]);

  useEffect(() => {
    const tagsArray = [];
    if (
      manageHostUsers.engagementTypesWithTags &&
      manageHostUsers.engagementTypesWithTags.length
    ) {
      manageHostUsers.engagementTypesWithTags.forEach(engagegementType => {
        if (
          engagegementType &&
          engagegementType.tags &&
          engagegementType.tags.length
        ) {
          engagegementType.tags.sort((a, b) => {
            const enagemenetTypeNameA =
              a.engagementType && a.engagementType.name.toUpperCase();
            const enagemenetTypeNameB =
              b.engagementType && b.engagementType.name.toUpperCase();
            if (enagemenetTypeNameA < enagemenetTypeNameB) {
              return -1;
            } else if (enagemenetTypeNameA > enagemenetTypeNameB) {
              return 1;
            } else {
              const tagNameA = a.name && a.name.toUpperCase();
              const tagNameB = b.name && b.name.toUpperCase();
              return tagNameA < tagNameB ? -1 : tagNameA > tagNameB ? 1 : 0;
            }
          });

          engagegementType.tags.forEach((tag, index) => {
            const tagObject = {
              tagId: tag.id,
              name: tag.name,
              engagementTypeName: engagegementType.name,
              engagementType: {
                id: engagegementType.id,
                name: index === 0 ? engagegementType.name : "",
                updateDisplayName: engagegementType.name
              },
              documentsCount: tag.documents && tag.documents.length,
              queriesCount: tag.queries && tag.queries.length,
              isVirtual: false
            };
            tagsArray.push(tagObject);
          });
        } else if (
          engagegementType &&
          engagegementType.tags &&
          !engagegementType.tags.length
        ) {
          // No tags under the engagementType but we want to show the
          // engagementType's name, so create a 'virtual/fake' tag
          const tagObject = {
            isVirtual: true,
            name: "",
            engagementTypeName: engagegementType.name,
            engagementType: {
              id: engagegementType.id,
              name: engagegementType.name
            },
            documentsCount: "",
            queriesCount: ""
          };
          tagsArray.push(tagObject);
        }
      });
    }

    // tagsArray.sort((a, b) => a.name.localeCompare(b.name));
    setTags(tagsArray);
    createPagination(tagsArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageHostUsers.engagementTypesWithTags]);

  useEffect(() => {
    if (pagination.pages.length) {
      setTags(pagination.pages[pagination.currentPageIndex].data);
    } else {
      setTags([]);
    }
  }, [pagination]);

  const handlePageSelection = pageName => {
    const index = pagination.pages.findIndex(page => page.name === pageName);
    setPagination({
      ...pagination,
      currentPage: pageName,
      currentPageIndex: index
    });
  };

  const handlePageCountIncrement = () => {
    const index = pagination.currentPageIndex;
    if (index < pagination.pages.length - 1) {
      setPagination({
        ...pagination,
        currentPage: `Page ${index + 2}`,
        currentPageIndex: index + 1
      });
    }
  };

  const handlePageCountDecrement = () => {
    const index = pagination.currentPageIndex;

    if (index > 0) {
      setPagination({
        ...pagination,
        currentPage: `Page ${index}`,
        currentPageIndex: index - 1
      });
    }
  };

  const createPagination = allTags => {
    const count = Math.ceil(allTags.length / pagination.countPerPage);
    const items = [];
    for (let number = 0; number < count; number++) {
      const data = allTags.slice(
        number * pagination.countPerPage,
        number * pagination.countPerPage + pagination.countPerPage
      );
      items.push({ number: number + 1, name: `Page ${number + 1}`, data });
    }

    setPagination({
      ...pagination,
      currentPage: "Page 1",
      currentPageIndex: 0,
      pageCount: count,
      pages: items
    });
  };

  const handleAddTag = event => {
    navigate(routeConstants.addTag);
  };

  const headerActions = () =>
    loggedUser.doesBelongToHost(authentication.user) && (
      <Button
        type="primary"
        label={t("common:ui.globalTags.addTag")}
        iconName="add"
        onClick={handleAddTag}
      />
    );

  return (
    <MainPageTemplate>
      <PageBodyTemplate
        title={t("common:ui.globalTags.title")}
        actions={headerActions()}
      >
        <div className="global-tags">
          <div className="global-tags__body">
            {tags.length ? (
              <>
                <div className="global-tags__table">
                  <TagsDataTable data={tags} />
                </div>
                {pagination && pagination.pages.length > 1 ? (
                  <DropdownPagination
                    handlePageSelection={handlePageSelection}
                    handlePageCountIncrement={handlePageCountIncrement}
                    handlePageCountDecrement={handlePageCountDecrement}
                    pages={pagination.pages.map(page => ({ name: page.name }))}
                    currentPage={pagination.currentPage}
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </PageBodyTemplate>
    </MainPageTemplate>
  );
};

export default withTranslation()(GlobalTags);
