import React, { useCallback, useEffect, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  useCheckProjLevelSettingsUploadDocument,
  useCurrentProject,
  useGetProjectMembers,
  useInteractiveReport,
  useInteractiveReportFilters
} from "@shared/hooks";
import { useInteractiveReportJSONEditor } from "@shared/hooks/useInteractiveReportJsonEditor";
import { useToasts } from "@shared/hooks/useToasts";

import Button from "@components/atoms/Button/Button";
import PopupOverlay from "@components/atoms/PopupOverlay/PopupOverlay";
import ProjectAccessModal from "@components/molecules/ProjectAccessModal/ProjectAccessModal";
import UploadInteractiveBox from "@components/molecules/UploadInteractiveBox";
import ModalTemplate from "@components/templates/ModalTemplate/ModalTemplate";
import PageTemplate from "@components/templates/PageTemplate/PageTemplate";

import "./UploadInteractiveReport.scss";

const UploadInteractiveReport = () => {
  const { currentProject: project, onChangeCurrentProject } =
    useCurrentProject();
  const { projectId } = useParams();
  const { isProjectMember } = useGetProjectMembers(project);
  const { resetAllFilters } = useInteractiveReportFilters();
  const { uploadInteractiveFile, uploaded, uploading, error } =
    useInteractiveReport();

  const { saveError, setSaveError, jsonData, isJsonDataLoading } =
    useInteractiveReportJSONEditor(projectId, uploaded);

  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const [contentKey, setContentKey] = useState("");

  const { showSuccess } = useToasts();
  const showSuccessfullyUploadedToast = useCallback(() => {
    showSuccess({ key: "interactiveReport:interactiveReport.uploadSuccess" });
  }, [showSuccess]);

  /**
   * Display success message upon successfully uploaded file
   */
  useEffect(() => {
    if (uploaded) {
      showSuccessfullyUploadedToast();
    }
  }, [uploaded, showSuccessfullyUploadedToast]);

  useCheckProjLevelSettingsUploadDocument(
    project,
    onChangeCurrentProject,
    projectId
  );

  const handleDrop = useCallback(
    acceptedFile => {
      if (acceptedFile.length && acceptedFile[0]) {
        uploadInteractiveFile({
          projectId: project.id,
          file: acceptedFile[0]
        });
      }
    },
    [project?.id, uploadInteractiveFile]
  );

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setContentKey("");
    setSaveError(null);
  }, [setModalOpen, setContentKey, setSaveError]);

  const handleOpenModal = useCallback(
    contentKey => {
      setModalOpen(true);
      setContentKey(contentKey);
    },
    [setModalOpen, setContentKey]
  );

  const getFailureModal = useCallback(() => {
    if (!saveError) {
      return;
    }
    return (
      <PopupOverlay
        isVisible={true}
        isModal={true}
        onClose={handleCloseModal}
        width={"60rem"}
      >
        <ModalTemplate
          boxClassName="tag-editor"
          title={
            saveError.title ||
            t(
              "interactiveReport:interactiveReport.ui.navigation.uploadErrorTitle"
            )
          }
          onClose={handleCloseModal}
          content={
            <span>
              <Trans
                i18nKey={saveError?.message}
                values={{ path: saveError?.path }}
                shouldUnescape={true}
              />
            </span>
          }
          footer={
            <Button
              type={"primary"}
              disabled={false}
              label="OK"
              onClick={handleCloseModal}
            />
          }
        />
      </PopupOverlay>
    );
  }, [saveError, handleCloseModal, t]);

  const getProjectAccessModal = useCallback(
    () => (
      <ProjectAccessModal
        visibility={true}
        message={saveError?.message}
        handleClose={handleCloseModal}
        handleOutsideClick={false}
      />
    ),
    [saveError?.message, handleCloseModal]
  );

  useEffect(() => {
    if (error) {
      setSaveError({ ...error });
    }
  }, [error, setSaveError]);

  const getModalContent = useCallback(() => {
    switch (contentKey) {
      case "uploadFailure":
        return getFailureModal();
      case "projectAccess":
        return getProjectAccessModal();
      default:
        return <></>;
    }
  }, [contentKey, getFailureModal, getProjectAccessModal]);

  useEffect(() => {
    if (uploaded) {
      resetAllFilters();
    }
  }, [resetAllFilters, uploaded]);

  useEffect(() => {
    if (saveError) {
      if (saveError?.membershipError) {
        handleOpenModal("projectAccess");
      } else {
        handleOpenModal("uploadFailure");
      }
    }
  }, [saveError, handleOpenModal]);

  return (
    <PageTemplate
      header={{
        title: t(
          "interactiveReport:interactiveReport.ui.navigation.uploadTitle"
        )
      }}
      body={{
        primary: (
          <div className="upload-interactive-report">
            <div className={`upload-interactive-box ${isJsonDataLoading}`}>
              <UploadInteractiveBox
                isProjectMember={isProjectMember}
                handleDrop={handleDrop}
                setError={setSaveError}
                projectId={projectId}
              />
            </div>
          </div>
        )
      }}
      other={{
        loading: uploading ? "Uploading..." : "",
        project,
        fullScreenContent: jsonData
      }}
      modal={{
        open: modalOpen,
        content: getModalContent()
      }}
    />
  );
};

export default UploadInteractiveReport;
