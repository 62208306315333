import React from "react";

import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useLogout, useUserProfile } from "@shared/hooks";

import ErrorBox from "@shared-components/errorBox/ErrorBox";
import Popup from "@shared-components/popup/Popup";
import SuccessBox from "@shared-components/successBox/successBox";

import { routeConstants } from "@app/constants";

import Button from "@components/atoms/Button";
import UserForm from "@components/organisms/UserForm/UserForm";
import MainPageTemplate from "@components/templates/MainPageTemplate";
import ModalTemplate from "@components/templates/ModalTemplate/ModalTemplate";
import PageBodyTemplate from "@components/templates/PageBodyTemplate";

const Profile = ({ t }) => {
  const navigate = useNavigate();
  const {
    userProfile,
    userFormConfig,
    requireLogout,
    updateUserProfile,
    error,
    submitted
  } = useUserProfile();
  const { logout } = useLogout();

  const handleSubmit = updatedUser => {
    //eslint-disable-next-line
    updateUserProfile(updatedUser);
  };

  const doForceLogout = () => {
    logout();
    navigate(routeConstants.logout, {
      state: {}
    });
  };

  const addConfigKey = key =>
    userFormConfig?.find(field => field.key === key) ?? {};

  const formConfig = [
    {
      key: "firstname",
      type: "text",
      required: true
    },
    {
      key: "lastname",
      type: "text",
      required: true
    },
    {
      key: "email",
      type: "text",
      required: true,
      disabled: true
    },
    addConfigKey("jobTitle"),
    {
      key: "role",
      type: "dropdown",
      required: true,
      disabled: true,
      items: []
    },
    addConfigKey("MOBILE_NUMBER"),
    addConfigKey("BUSINESS_NUMBER"),
    {
      key: "NOTIFICATION",
      type: "radio",
      defaultValue: "digest",
      items: ["digest", "each"],
      path: "properties.notification.email"
    }
  ];
  return (
    <MainPageTemplate>
      <PageBodyTemplate title={t("common:ui.manageProfile.updateTitle")}>
        {error && <ErrorBox message={error} />}
        {submitted && <SuccessBox message={"Update Successful"} />}
        <UserForm
          formConfig={formConfig}
          user={userProfile}
          handleSubmit={handleSubmit}
          submitLabel={t("common:ui.manageProfile.updateSubmit")}
        />
        <Popup visibility={requireLogout} width="50rem">
          <ModalTemplate
            boxClassName="tag-editor"
            title={"Profile update successful"}
            content={
              <>
                <p>Your changes were successful.</p>
                <p>
                  As a security precaution, you need to log out and log in again
                  for these changes to take effect.
                </p>
              </>
            }
            footer={
              <>
                <Button
                  type={"primary"}
                  label="Log out"
                  onClick={doForceLogout}
                />
              </>
            }
          />
        </Popup>
      </PageBodyTemplate>
    </MainPageTemplate>
  );
};
export default withTranslation()(Profile);
