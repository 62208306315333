import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./Loading.scss";

const Loading = props => {
  const { t } = useTranslation();
  const { message, type, minHeight } = props;
  const inlineStyle = {};
  if (minHeight) {
    inlineStyle.style = {
      minHeight: minHeight
    };
  }
  return (
    <div className="loading" {...inlineStyle}>
      <h2 className={`loading__text loading__text--${type}`}>
        {message ?? t("common:loading")}
      </h2>
    </div>
  );
};

Loading.defaultProps = {
  type: "primary"
};

Loading.propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf(["primary", "secondary"]),
  minHeight: PropTypes.string
};

export default Loading;
