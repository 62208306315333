import { manageClientsConstants } from "../constants";

const initialState = {
  loading: false,
  clients: [],
  error: "",
  client: null,
  fetchedClient: false,
  addedClient: false,
  updatedClient: false,
  clientsFromCRM: [],
  clientsWithEngagementsAndProjects: []
};

export function manageClients(state = initialState, action = {}) {
  switch (action.type) {
    case manageClientsConstants.GET_CLIENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageClientsConstants.GET_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        client: action.client,
        fetchedClient: true,
        error: ""
      };
    case manageClientsConstants.GET_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        client: null,
        fetchedClient: false,
        error: action.error.key ?? action.error.message
      };
    case manageClientsConstants.GET_CLIENTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageClientsConstants.GET_CLIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        clients: action.clients,
        error: ""
      };
    case manageClientsConstants.GET_CLIENTS_FAILURE:
      return {
        ...state,
        loading: false,
        clients: [],
        error: action.error.key ?? action.error.message
      };
    case manageClientsConstants.GET_CLIENTS_WITH_ENGAGEMENTS_AND_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
        clientsWithEngagementsAndProjects: []
      };
    case manageClientsConstants.GET_CLIENTS_WITH_ENGAGEMENTS_AND_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        clientsWithEngagementsAndProjects: action.clients,
        error: ""
      };
    case manageClientsConstants.GET_CLIENTS_WITH_ENGAGEMENTS_AND_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        clientsWithEngagementsAndProjects: [],
        error: action.error.key ?? action.error.message
      };
    case manageClientsConstants.GET_CLIENTS_FROM_CRM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageClientsConstants.GET_CLIENTS_FROM_CRM_SUCCESS:
      return {
        ...state,
        loading: false,
        clientsFromCRM: action.clients,
        error: ""
      };
    case manageClientsConstants.GET_CLIENTS_FROM_CRM_FAILURE:
      return {
        ...state,
        loading: false,
        clientsFromCRM: [],
        error: action.error.key ?? action.error.message
      };
    case manageClientsConstants.ADD_CLIENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageClientsConstants.ADD_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        addedClient: true,
        error: ""
      };
    case manageClientsConstants.ADD_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        addedClient: false,
        error: action.error.key ?? action.error.message
      };
    case manageClientsConstants.UPDATE_CLIENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageClientsConstants.UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        updatedClient: true,
        error: ""
      };
    case manageClientsConstants.UPDATE_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.key ?? action.error.message
      };
    case manageClientsConstants.CLEAR_ERROR:
      return {
        ...state,
        error: ""
      };
    case manageClientsConstants.CLEAR_GET_CLIENT_FLAGS:
      return {
        ...state,
        error: "",
        fetchedClient: false,
        client: null
      };
    case manageClientsConstants.CLEAR_ADD_CLIENT_FLAGS:
      return {
        ...state,
        error: "",
        addedClient: false,
        clientsFromCRM: []
      };
    case manageClientsConstants.CLEAR_UPDATE_CLIENT_FLAGS:
      return {
        ...state,
        error: "",
        updatedClient: false
      };
    case manageClientsConstants.RESET_CLIENTS:
      return {
        ...state,
        clients: [],
        clientsWithEngagementsAndProjects: []
      };
    default:
      return state;
  }
}
