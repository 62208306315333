import React from "react";

import PropTypes from "prop-types";

import Checkbox from "@components/atoms/CheckBox";

import "./Checklist.scss";

const Checklist = props => {
  const { items, toggleItem } = props;
  return (
    <div className="checklist">
      {items?.map((item, index) => {
        return (
          <div
            key={index}
            className="checklist__item--checkbox"
            data-testid={`testMultiselect__menu-item-${index}`}
          >
            <Checkbox
              label={item.name}
              value={item.isChecked || false}
              onChange={() => toggleItem(item)}
              styleVariant="primary"
              textWeight="normal"
            />
          </div>
        );
      })}
    </div>
  );
};

Checklist.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      isChecked: PropTypes.bool,
      name: PropTypes.string.isRequired
    })
  ),
  toggleItem: PropTypes.func.isRequired
};

export default Checklist;
