import React, { useRef } from "react";

import PropTypes from "prop-types";
import useOnClickOutside from "use-onclickoutside";

import "./HamburgerMenu.scss";
import HamburgerMenuItem from "./HamburgerMenuItem";

const HamburgerMenu = props => {
  const { isShowMenu, menuItems, onClose, direction, showClose, type } = props;
  const hamburgerMenuRef = useRef(null);
  const contentClassName = `hamburger-menu__content ${
    isShowMenu ? "hamburger-menu__content-show" : ""
  } hamburger-menu__content--${direction}`;

  useOnClickOutside(hamburgerMenuRef, () => {
    onClose();
  });

  const onClickClose = event => {
    event.stopPropagation();
    onClose();
  };

  return (
    <div
      className={`hamburger-menu ${isShowMenu ? "hamburger-menu--long" : ""}`}
    >
      {isShowMenu && (
        <div className={contentClassName} ref={hamburgerMenuRef}>
          <>
            {showClose && (
              <div className="content__header">
                <i className="material-icons menu-icon" onClick={onClickClose}>
                  close
                </i>
              </div>
            )}
            <div className="content__body">
              {menuItems.length &&
                menuItems.map(item => (
                  <HamburgerMenuItem
                    itemIcon={item.icon}
                    itemName={item.name}
                    itemPath={item.path}
                    itemState={item.state}
                    onClickItem={item.onClick}
                    key={item.name}
                    type={type}
                    itemKey={item.key}
                    onClose={onClose}
                  />
                ))}
            </div>
          </>
        </div>
      )}
    </div>
  );
};

HamburgerMenu.defaultProps = {
  direction: "bot",
  showClose: true
};

HamburgerMenu.propTypes = {
  direction: PropTypes.oneOf(["bot", "right"]),
  showClose: PropTypes.bool
};

export default HamburgerMenu;
