import React, { useState } from "react";

import { useDispatch } from "react-redux";

import { manageProjectDocumentsActions } from "../../actions";
import Button from "../button/Button";
import Input from "../input/Input";
import "./createFolder.scss";

const CreateFolder = props => {
  const [folder, setFolder] = useState({
    name: "",
    projectId: props.project.id,
    parentFolderId: props.parentFolderId
  });
  const [createSubmitted, setCreateSubmitted] = useState(false);
  const dispatch = useDispatch();

  const handleChange = event => {
    event.stopPropagation();
    setFolder({ ...folder, name: event.target.value });
  };

  const handleCreate = () => {
    setCreateSubmitted(true);
    if (folder.name) {
      dispatch(manageProjectDocumentsActions.createProjectFolder(folder));
      props.onCreate();
    }
  };

  return (
    <>
      <div className="create-folder__container">
        <div className="create-folder__container-header">
          <h2>Create a folder</h2>
          <i
            onClick={props.onCancel}
            className="material-icons create-folder__container-header-icon"
          >
            close
          </i>
        </div>
        <div className="create-folder__container-body">
          <span className="create-folder__container-body-text">Name</span>
          <Input
            error={!folder.name && createSubmitted}
            value={folder.name}
            placeholder="Please enter the folder name"
            handleChange={handleChange}
            size={"small"}
          />
        </div>
      </div>
      <div className="create-folder__button">
        <div className="create-folder__button--cancel">
          <Button
            type="plain-primary"
            name="Cancel"
            handleClick={props.onCancel}
          />
        </div>
        <div className="create-folder__button--save">
          <Button type="primary" name="Create" handleClick={handleCreate} />
        </div>
      </div>
    </>
  );
};

export default CreateFolder;
