import React from "react";

import PropTypes from "prop-types";

import "./HoverText.scss";

const HoverText = props => {
  const { name, isActive, position, maxWidth, type, classNameTarget } = props;

  return (
    <>
      {name && (
        <span
          className={`hover-text hover-text--${position} ${
            isActive && "hover-text--active"
          } hover-text--${type} ${classNameTarget}`}
          data-testid="test-hover-text"
          style={{ maxWidth: `${maxWidth ? `${maxWidth}px` : "300px"}` }}
        >
          {name}
        </span>
      )}
    </>
  );
};

HoverText.defaultProps = {
  isActive: false,
  position: "bottom",
  type: "short",
  classNameTarget: ""
};

HoverText.propTypes = {
  isActive: PropTypes.bool,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  position: PropTypes.oneOf([
    "right",
    "top",
    "bottom",
    "bottom-left",
    "top-mid",
    "top-left"
  ]),
  type: PropTypes.oneOf(["short", "long"]),
  classNameTarget: PropTypes.string,
  maxWidth: PropTypes.number
};

export default HoverText;
