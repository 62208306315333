import { manageDashboardConstants } from "../constants";

const initialState = {
  todosLoading: false,
  queriesLoading: false,
  contactsLoading: false,
  recentDocumentsLoading: false,
  projectsLoading: false,
  updatedTodoLoading: false,
  queries: [],
  contacts: [],
  recentDocuments: [],
  projects: [],
  updatedTodo: null,
  error: ""
};

export function manageDashboard(state = initialState, action = {}) {
  switch (action.type) {
    case manageDashboardConstants.GET_OPEN_QUERIES_REQUEST:
      return {
        ...state,
        queriesLoading: true
      };
    case manageDashboardConstants.GET_OPEN_QUERIES_SUCCESS:
      return {
        ...state,
        queriesLoading: false,
        queries: action.queries,
        error: ""
      };
    case manageDashboardConstants.GET_OPEN_QUERIES_FAILURE:
      return {
        ...state,
        queriesLoading: false,
        queries: [],
        error: action.error.key ?? action.error.message
      };
    case manageDashboardConstants.GET_PROJECTS_REQUEST:
      return {
        ...state,
        projectsLoading: true
      };
    case manageDashboardConstants.GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projectsLoading: false,
        projects: action.projects,
        error: ""
      };
    case manageDashboardConstants.GET_PROJECTS_FAILURE:
      return {
        ...state,
        projectsLoading: false,
        projects: [],
        error: action.error.key ?? action.error.message
      };
    case manageDashboardConstants.GET_RECENT_DOCUMENTS_REQUEST:
      return {
        ...state,
        recentDocumentsLoading: true
      };
    case manageDashboardConstants.GET_RECENT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        recentDocumentsLoading: false,
        recentDocuments: action.documents,
        error: ""
      };
    case manageDashboardConstants.GET_RECENT_DOCUMENTS_FAILURE:
      return {
        ...state,
        recentDocumentsLoading: false,
        recentDocuments: [],
        error: action.error.key ?? action.error.message
      };
    case manageDashboardConstants.GET_CONTACTS_REQUEST:
      return {
        ...state,
        contactsLoading: true
      };
    case manageDashboardConstants.GET_CONTACTS_SUCCESS:
      return {
        ...state,
        contactsLoading: false,
        contacts: action.contacts,
        error: ""
      };
    case manageDashboardConstants.GET_CONTACTS_FAILURE:
      return {
        ...state,
        contactsLoading: false,
        contacts: [],
        error: action.error.key ?? action.error.message
      };
    case manageDashboardConstants.UPDATE_TODO_STATUS_REQUEST:
      return {
        ...state,
        updatedTodoLoading: true
      };
    case manageDashboardConstants.UPDATE_TODO_STATUS_SUCCESS:
      const updatedTodos = state.todos.map(todo => {
        if (todo.id === action.updatedTodo.todoId) {
          todo.completed = action.updatedTodo.completed;
        }
        return todo;
      });

      return {
        ...state,
        updatedTodoLoading: false,
        updatedTodo: action.updatedTodo,
        todos: updatedTodos,
        error: ""
      };
    case manageDashboardConstants.UPDATE_TODO_STATUS_FAILURE:
      return {
        ...state,
        updatedTodoLoading: false,
        updatedTodo: null,
        error: action.error.key ?? action.error.message
      };
    case manageDashboardConstants.CLEAR_ERROR:
      return {
        ...state,
        error: ""
      };
    case manageDashboardConstants.RESET:
      return initialState;
    default:
      return state;
  }
}
