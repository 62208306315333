import React from "react";

import PropTypes from "prop-types";

import "./InteractiveReportCommentIcon.scss";

const InteractiveReportCommentIcon = ({ type, state }) => {
  return (
    <div className="ot-interactive-report-comment-icon">
      <i
        className={`material-symbols-${
          state === "default" ? "outlined" : "filled"
        }
        ot-interactive-report-comment-icon--${state ?? "default"}`}
      >
        {type}
      </i>
    </div>
  );
};

InteractiveReportCommentIcon.defaultProps = {
  type: "chat_bubble",
  state: "default"
};

InteractiveReportCommentIcon.propTypes = {
  type: PropTypes.string,
  state: PropTypes.oneOf(["active", "inactive"])
};

export default InteractiveReportCommentIcon;
