import React, { useState } from "react";

import PropTypes from "prop-types";

import HoverText from "../HoverText/HoverText";
import "./Avatar.scss";

const Avatar = props => {
  const { user, size, hoverPosition } = props;
  const [showHoverName, setShowHoverName] = useState(false);
  const getAvatarColorCode = name => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    return Math.abs(hash % 5) + 1;
  };

  const renderDefaultAvatar = () => {
    return (
      <div
        className={`default-avatar default-avatar--${size} default-avatar--variant-${getAvatarColorCode(
          user.name
        )}`}
        alt={`Avatar of ${props.user.firstname} ${props.user.lastname}`}
        onMouseEnter={() => setShowHoverName(true)}
        onMouseLeave={() => setShowHoverName(false)}
      >
        {`${user.firstname.charAt(0)}${user.lastname.charAt(0)}`}
      </div>
    );
  };

  const mouseHoverHandler = () => {
    return props.showHover
      ? {
          onMouseEnter: () => setShowHoverName(true),
          onMouseLeave: () => setShowHoverName(false)
        }
      : {};
  };

  return (
    <div className="ot-avatar">
      {props.user.avatar?.image ? (
        <img
          src={props.user.avatar.image}
          alt={`Avatar of ${props.user.firstname} ${props.user.lastname}`}
          className={`ot-avatar__image ot-avatar__image--${size}`}
          {...mouseHoverHandler()}
        />
      ) : (
        renderDefaultAvatar()
      )}
      {props.showHover && (
        <HoverText
          isActive={props.showHover && showHoverName}
          name={`${props.user.firstname} ${props.user.lastname}`}
          position={hoverPosition}
        />
      )}
    </div>
  );
};

Avatar.defaultProps = {
  size: "small",
  showHover: false,
  hoverPosition: "top"
};

Avatar.propTypes = {
  size: PropTypes.oneOf(["x-small", "small", "medium", "large"]),
  user: PropTypes.shape({
    name: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    avatar: PropTypes.shape({
      image: PropTypes.string
    })
  }),
  showHover: PropTypes.bool,
  hoverPosition: PropTypes.oneOf(["top", "bottom"])
};

export default Avatar;
