import React, { useCallback, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { routeConstants } from "@constants";

import { loggedUser } from "@shared/helpers";
import { useGetClientByIdQuery } from "@shared/hooks";
import { useClientUserProfile } from "@shared/hooks/useClientUserProfile";
import {
  useGetUserByIdQuery,
  useUpdateClientUserMutation
} from "@shared/services";

import ErrorBox from "@shared-components/errorBox/ErrorBox";

import UserForm from "@components/organisms/UserForm/UserForm";
import MainPageTemplate from "@components/templates/MainPageTemplate";
import PageBodyTemplate from "@components/templates/PageBodyTemplate";

const UpdateClientUser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { clientId, userId } = useParams();
  const { data: client, isError: clientError } =
    useGetClientByIdQuery(clientId);
  const { data: clientUser, isError: userError } = useGetUserByIdQuery(userId);

  const authentication = useSelector(state => state.authentication);

  const isClientAdmin = loggedUser.isClientAdmin(authentication.user);
  const { roles, projectsList, clientUserFields, error } = useClientUserProfile(
    {
      client,
      updateUser: clientUser
    }
  );

  const [updateClientUser, { isSuccess: isClientUserUpdated }] =
    useUpdateClientUserMutation();

  const updateUser = useCallback(
    profile => {
      updateClientUser({ client, user: profile });
    },
    [client, updateClientUser]
  );

  useEffect(() => {
    if (!clientId || userError || clientError) {
      navigate(routeConstants.notFound);
    }
  }, [clientError, clientId, navigate, userError]);

  useEffect(() => {
    if (isClientUserUpdated) {
      navigate(`/admin/clients/${client.id}/users`);
    }
  }, [client.id, isClientUserUpdated, navigate]);

  const breadcrumbs = () => {
    const result = [];
    if (!isClientAdmin) {
      result.push({
        linkTo: routeConstants.manageClients,
        label: t("common:ui.manageClients.title")
      });
    }
    result.push({
      linkTo: `/admin/clients/${client.id}/users`,
      label: isClientAdmin ? t("common:ui.manageUsers.title") : `${client.name}`
    });

    return result;
  };

  const addConfigKey = key =>
    clientUserFields?.find(field => field.key === key) ?? {};

  const addRole = () => {
    if (roles?.length > 1) {
      return {
        key: "role",
        type: "dropdown",
        required: true,
        items: roles
      };
    }
    return {};
  };

  const formConfig = [
    {
      key: "firstname",
      type: "text",
      required: true,
      editable: true
    },
    {
      key: "lastname",
      type: "text",
      required: true,
      editable: true
    },
    {
      key: "email",
      type: "text",
      required: true,
      editable: true
    },
    addConfigKey("jobTitle"),
    addRole(),
    {
      key: "projects",
      type: "multiselect",
      required: false,
      editable: true,
      items: projectsList,
      defaultValue: projectsList?.filter(p => p.added),
      placeholder: t("common:user.configured.fields.projects.recommended")
    },
    addConfigKey("MOBILE_NUMBER"),
    addConfigKey("BUSINESS_NUMBER"),
    {
      key: "NOTIFICATION",
      type: "radio",
      defaultValue: "digest",
      items: ["digest", "each"],
      path: "properties.notification.email"
    }
  ];
  return (
    <MainPageTemplate>
      <PageBodyTemplate
        breadcrumbs={breadcrumbs()}
        title={
          isClientAdmin
            ? t("common:ui.manageUsers.updateTitle_team")
            : t("common:ui.manageUsers.updateTitle_client")
        }
      >
        {error && <ErrorBox message={error} />}
        <UserForm
          handleSubmit={updateUser}
          user={clientUser}
          formConfig={formConfig}
          submitLabel={t("common:ui.manageUsers.updateSubmit")}
        />
      </PageBodyTemplate>
    </MainPageTemplate>
  );
};

export default UpdateClientUser;
