import React, { useEffect, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { routeConstants } from "@constants";

import { authActions } from "@shared/actions";
import { systemConstants } from "@shared/constants";
import { useStaticAssets } from "@shared/hooks";

import Footer from "@components/organisms/Footer/Footer";

import "./LogoutPage.scss";

const { serverURL } = systemConstants;

const LogoutPage = _props => {
  const location = useLocation();
  const [hostObject, setHostObject] = useState(null);
  const { authentication } = useSelector(id => id);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getUrl } = useStaticAssets();

  useEffect(() => {
    if (authentication.host) {
      setHostObject(authentication.host);
    }
  }, [authentication.host]);

  useEffect(() => {
    if (!authentication.host) {
      dispatch(authActions.getHostWithBrandDetails());
    }
  }, [location.state, dispatch, authentication.host]);

  const handleClick = () => {
    navigate(routeConstants.login);
  };

  return (
    <div className="logged-out">
      <div className="logged-out--container">
        {hostObject && (
          <div className="logged-out--logo-container">
            <img
              src={getUrl("authLogo.png")}
              alt="Logo"
              className="logged-out--logo"
            />
          </div>
        )}
        <div className="logged-out--content">
          <div className="logged-out--host-header">
            {t("common:authentication.logoutPage.message")}
          </div>
          <div className="logged-out--content-message">
            <Trans i18nKey={"common:authentication.logoutPage.redirectMessage"}>
              To login again, please{" "}
              <a href="#" onClick={handleClick}>
                click here
              </a>
            </Trans>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LogoutPage;
