import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { cmsActions } from "@shared/actions";

export function useContent() {
  const cms = useSelector(state => state.cms);
  const [content, setContent] = useState(null);

  const dispatch = useDispatch();

  const fetchContent = useCallback(() => {
    if (cms?.content === null && !cms.contentLoaded) {
      dispatch(cmsActions.getContent());
    }
  }, [cms?.content, cms.contentLoaded, dispatch]);

  useEffect(() => {
    setContent(cms.content);
  }, [cms.content]);

  return { content, fetchContent };
}
