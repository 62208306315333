import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Icon from "@components/atoms/Icon";

import "./SearchBar.scss";

/**
 * @type {React.FC<Object>}
 */
const SearchBar = React.forwardRef((props, fwdRef) => {
  const {
    value,
    handleChange,
    handleClear,
    disabled,
    label,
    className,
    placeholder
  } = props;
  const { t } = useTranslation();
  return (
    <div className="search-bar">
      {label && <label className="search-bar__label">{label}</label>}
      <div className={`search-bar__container ${className}`}>
        <Icon name="search" className="search-bar__icon" />
        <input
          className="search-bar__input"
          disabled={disabled}
          placeholder={placeholder ?? t("common:ui.forms.search.label")}
          value={value}
          onChange={e => handleChange(e.target.value)}
          ref={fwdRef}
        />
        {value && (
          <Icon
            name="close"
            className={"search-bar__clear"}
            onClick={handleClear}
            size="medium"
          />
        )}
      </div>
    </div>
  );
});

SearchBar.propTypes = {
  value: PropTypes.any,
  handleChange: PropTypes.func,
  handleClear: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string
};
export default SearchBar;
