import React from "react";

import PropTypes from "prop-types";

import Icon from "@components/atoms/Icon/Icon";

import "./InfoMessageBox.scss";

const InfoMessageBox = props => {
  const { color, iconProps, message } = props;
  return (
    <div className={`infoMessageBox infoMessageBox--color-${color}`}>
      <span className="infoMessageBox--icon">
        <Icon {...iconProps} />
      </span>
      <span className="infoMessageBox--text">{message}</span>
    </div>
  );
};

InfoMessageBox.defaultProps = {
  color: "default"
};

InfoMessageBox.propTypes = {
  iconProps: PropTypes.shape({
    ...Icon.propTypes
  }).isRequired,
  color: PropTypes.oneOf(["default", "smartformcompleted"]),
  message: PropTypes.string
};

export default InfoMessageBox;
