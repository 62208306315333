import React, { useCallback } from "react";
import { useMemo } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import dateFormatter from "@shared/helpers/dateHelper";
import { useLocaleDate } from "@shared/hooks";

import { useDataTable } from "@app/hooks";

import DataTable from "@components/molecules/DataTable";

const actions = {
  selectDocumentRevision: "Select document revision",
  downloadDocument: "Download document"
};

const DocumentRevisionDataTable = props => {
  const {
    documentRevisions,
    activeRevisionId,
    handleRevisionSelected,
    handleDownloadDocument,
    canSelectActive
  } = props;
  const { t } = useTranslation();

  const {
    locale,
    options: { shortFormat }
  } = useLocaleDate();
  const { findItem, createColumn, createColumnForSingleActionIcon } =
    useDataTable(documentRevisions);

  const handleAction = useCallback(
    (action, documentRevisionId) => {
      const item = findItem(documentRevisionId);
      switch (action) {
        case actions.selectDocumentRevision: {
          handleRevisionSelected?.(item);
          return;
        }
        case actions.downloadDocument: {
          handleDownloadDocument?.(item);
          return;
        }
        default:
      }
    },
    [findItem, handleDownloadDocument, handleRevisionSelected]
  );

  const singleActionHandler = useCallback(
    action =>
      ({ cell }) => {
        const documentOrFolderId = cell.row.original.id;
        handleAction(action, documentOrFolderId);
      },
    [handleAction]
  );

  const handleRowClick = useCallback(
    revisionId => {
      if (canSelectActive) {
        handleAction(actions.selectDocumentRevision, revisionId);
      }
    },
    [canSelectActive, handleAction]
  );

  const renderSelectActiveCell = useCallback(
    ({ cell }) => {
      if (canSelectActive) {
        return (
          <span className="radio-box" data-testid="active-cell__radio-button">
            <i className="material-icons radio-btn">
              {cell.value ? "radio_button_checked" : "radio_button_unchecked"}
            </i>
            {t("common:documents.revision.active")}
          </span>
        );
      }

      if (cell.value) {
        return t("common:documents.revision.active");
      }
      return t("common:documents.revision.inactive");
    },
    [canSelectActive, t]
  );

  const columns = useMemo(() => {
    return [
      createColumn({
        Header: t("common:documents.revision.name"),
        accessor: "displayName"
      }),
      createColumn({
        Header: t("common:documents.revision.comment"),
        accessor: "comment"
      }),
      createColumn({
        Header: t("common:documents.uploadedBy"),
        accessor: "displayUploadedBy"
      }),
      createColumn({
        Header: t("common:documents.uploadDate"),
        accessor: "uploadDate"
      }),
      createColumn({
        Header: "",
        accessor: "isRevisionActive",
        Cell: renderSelectActiveCell
      }),
      createColumnForSingleActionIcon({
        accessor: "downloadActionIcon",
        onClickHandler: singleActionHandler(actions.downloadDocument),
        fixedWidth: true,
        width: 55
      })
    ];
  }, [
    createColumn,
    createColumnForSingleActionIcon,
    renderSelectActiveCell,
    singleActionHandler,
    t
  ]);

  const data = useMemo(() => {
    return documentRevisions.map(item => ({
      ...item,
      displayName: t("common:documents.revision.displayName", {
        revisionNumber: item.revision,
        fileName: item.name
      }),
      comment: item.properties?.overrideMessage ?? "",
      displayUploadedBy: item.uploadedBy?.name,
      uploadDate: dateFormatter(item.date, locale, shortFormat),
      isRevisionActive: activeRevisionId === item.id,
      downloadActionIcon: "download"
    }));
  }, [activeRevisionId, documentRevisions, locale, shortFormat, t]);

  return (
    <>
      <DataTable
        className="document-revisions-table"
        columns={columns}
        data={data}
        onRowClick={handleRowClick}
      ></DataTable>
    </>
  );
};

DocumentRevisionDataTable.defaultProps = {};

DocumentRevisionDataTable.propTypes = {
  activeRevisionId: PropTypes.number,
  documentRevisions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      revision: PropTypes.number,
      name: PropTypes.string,
      date: PropTypes.string,
      properties: PropTypes.shape({
        overrideMessage: PropTypes.string
      }),
      uploadedBy: PropTypes.shape({
        name: PropTypes.string
      })
    })
  ).isRequired,
  handleRevisionSelected: PropTypes.func.isRequired,
  handleDownloadDocument: PropTypes.func.isRequired,
  canSelectActive: PropTypes.bool
};

export default DocumentRevisionDataTable;
