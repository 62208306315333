import React, { useEffect, useMemo } from "react";

import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Form from "@components/atoms/Form";
import SelectProjectYearField from "@components/molecules/SelectProjectYearField";
import UploadProjectEntitiesFormField from "@components/molecules/UploadProjectEntitiesFormField";

function ProjectForm(props) {
  const { t } = useTranslation();
  const {
    enableEntities,
    enableProjectManager,
    enableProjectYear,
    enableCopyEngagementUsers,
    startDateLabel,
    endDateLabel,
    projectManagers,
    project,
    showUpdateFieldsOnly
  } = props;
  const { watch, reset } = useFormContext();

  const watchStartDate = watch("startDate");
  const watchEndDate = watch("plannedCompletionDate");
  const defaultProjectManager = useMemo(
    () =>
      project?.projectManager
        ? {
            name: project?.projectManager?.name,
            value: project?.projectManager
          }
        : null,
    [project]
  );

  const projectManagerItems = useMemo(
    () =>
      projectManagers
        ?.map(projectManager => ({
          name: projectManager?.name,
          value: projectManager
        }))
        ?.sort((a, b) => a?.name?.localeCompare(b?.name)) ?? [],
    [projectManagers]
  );

  //Reset form fields when config changes (this clears validation checks applied by schema validation linked to previous config)
  useEffect(() => {
    reset(fieldValues => ({ ...fieldValues }), { keepValues: true });
  }, [
    enableEntities,
    enableProjectManager,
    enableCopyEngagementUsers,
    enableProjectYear,
    reset
  ]);

  return (
    <>
      <div className="ot-project-form__field">
        <Form.HiddenInput
          key="projectManagerEnabled"
          name="projectManagerEnabled"
          value={enableProjectManager}
        />

        <Form.HiddenInput
          key="projectYearEnabled"
          name="projectYearEnabled"
          value={enableProjectYear}
        />

        <Form.HiddenInput
          key="entitiesEnabled"
          name="entitiesEnabled"
          value={enableEntities}
        />

        <Form.TextField
          name="projectName"
          label={t("common:ui.projects.name.label")}
          required
          defaultValue={project?.name}
        />
      </div>
      {enableProjectYear && (
        <SelectProjectYearField
          name="year"
          label={t("common:ui.projects.year.label")}
          required
          defaultValue={project?.year ?? new Date().getFullYear().toString()}
        />
      )}
      {enableProjectManager && (
        <Form.Dropdown
          name="projectManager"
          label={t("common:ui.projects.manager")}
          items={projectManagerItems}
          defaultValue={defaultProjectManager}
          required
        />
      )}
      {!showUpdateFieldsOnly && (
        <>
          <div className="ot-project-form__field">
            <Form.DateField
              name="startDate"
              label={startDateLabel ?? ""}
              maxDate={watchEndDate}
              required
            />
          </div>
          <div className="ot-project-form__field">
            <Form.DateField
              name="plannedCompletionDate"
              label={endDateLabel ?? ""}
              required
              minDate={watchStartDate}
            />
          </div>
        </>
      )}
      {enableEntities && (
        <div className="ot-project-form__field ot-project-form__field--entities">
          <UploadProjectEntitiesFormField
            name="entities"
            defaultValue={project?.entities}
            required
          />
        </div>
      )}
      {!showUpdateFieldsOnly && enableCopyEngagementUsers && (
        <div className="ot-project-form__field">
          <Form.Checkbox
            name="copyEngagementUsers"
            label={t("common:ui.projects.copyUsers")}
            defaultValue={true}
          />
        </div>
      )}
    </>
  );
}

ProjectForm.defaultProps = {
  enableEntities: false,
  enableProjectManager: false,
  enableProjectYear: false,
  showUpdateFieldsOnly: false
};

ProjectForm.propTypes = {
  project: PropTypes.object,
  enableEntities: PropTypes.bool,
  enableProjectManager: PropTypes.bool,
  enableProjectYear: PropTypes.bool,
  enableCopyEngagementUsers: PropTypes.bool,
  startDateLabel: PropTypes.string,
  endDateLabel: PropTypes.string,
  projectManagers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  showUpdateFieldsOnly: PropTypes.bool
};

export default ProjectForm;
