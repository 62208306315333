import React from "react";

import Icon from "@components/atoms/Icon";
import InteractiveReportCommentIcon from "@components/atoms/InteractiveReportCommentIcon";

import "./CommentsFilterItem.scss";

export default function CommentsFilterItem({ name, icons, checked }) {
  return (
    <div className="ot-comments-filter-item">
      <span className="ot-comments-filter-item__check">
        {checked && <Icon name="check" size="medium" />}
      </span>
      <div className="ot-comments-filter-item--container">
        <span className="ot-comments-filter-item__name">{name}</span>
        <span className="ot-comments-filter__icons">
          {icons?.map(({ icon, state }, index) => (
            <span
              className="material-icons ot-comments-filter-item__icon"
              key={index}
            >
              <InteractiveReportCommentIcon type={icon} state={state} />
            </span>
          ))}
        </span>
      </div>
    </div>
  );
}
