import React, { useCallback, useEffect, useState } from "react";

import PropTypes from "prop-types";

import { utilities } from "@shared/helpers/utilities";

import DropdownInput from "@components/atoms/DropdownInput";

function ProjectYearField(props) {
  const { onChange } = props;
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 21 }, (_, i) => ({
    name: (currentYear - 10 + i).toString()
  }));
  const [selectedYear, setSelectedYear] = useState(null);

  useEffect(() => {
    setSelectedYear(
      utilities.isValidYearString(props.defaultValue)
        ? { name: props.defaultValue }
        : null
    );
  }, [currentYear, props.defaultValue]);

  const handleYearChange = useCallback(
    item => {
      if (item === null) {
        return;
      }

      setSelectedYear(item);
      onChange?.(item?.name);
    },
    [onChange]
  );

  return (
    <div>
      <DropdownInput
        label={props.label}
        value={selectedYear}
        onChange={handleYearChange}
        items={years}
        showSearchFilter={false}
      ></DropdownInput>
    </div>
  );
}

ProjectYearField.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
};

export default ProjectYearField;
