import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";
import prepareHeaders from "./rtkUtilities";

export const clientService = createApi({
  reducerPath: "clientServiceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  tagTypes: ["Client"],
  endpoints: builder => ({
    getClientById: builder.query({
      query: clientId => `/api/clients/${clientId}`,
      providesTags: ["Client"]
    }),
    getClients: builder.query({
      query: () => "/api/clients",
      providesTags: ["Client"]
    })
  })
});

export const { useGetClientByIdQuery, useGetClientsQuery } = clientService;
