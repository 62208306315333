import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import TextAreaInput from "@components/atoms/TextAreaInput";
import BoxTemplate from "@components/templates/BoxTemplate/BoxTemplate";
import { useAiReporting } from "./useAiReporting";
import LoaderSpinner from "@components/atoms/LoaderSpinner";
import "./AiReporting.scss";

import {
  useCheckProjLevelSettingsUploadDocument,
  useCurrentProject,
} from "@shared/hooks";

import Button from "@components/atoms/Button/Button";
import PopupOverlay from "@components/atoms/PopupOverlay/PopupOverlay";
import JsonEditorInteractiveBox from "@components/molecules/JsonEditorInteractiveBox"
import ModalTemplate from "@components/templates/ModalTemplate/ModalTemplate";
import PageTemplate from "@components/templates/PageTemplate/PageTemplate";
import BrandButton from "@components/atoms/Button/BrandButton";
import { useInteractiveReportJSONEditor } from "@shared/hooks/useInteractiveReportJsonEditor";

const AiReporting = () => {
  const { currentProject: project, onChangeCurrentProject } = useCurrentProject();
  const { projectId } = useParams();
  const { t } = useTranslation();

  const {
    schema,
    saveError,
    setSaveError,
    onChangeJSONEditor,
    onSaveJSONEditor,
    onEdit,
    jsonData,
    localJSONContent,
    isSuccessOnSaveJsonEditor,
    isLoadingOnSaveJsonEditor,
    errorOnSaveJsonEditor,
  } = useInteractiveReportJSONEditor(projectId, true);

  const {
    onCancel,
    onGenerated,
    generateReport,
    onApply,
    setError: setPromptError,
    error: promptError,
    prompt,
    setPrompt,
    isGenerateReportLoading,
  } = useAiReporting(projectId, onSaveJSONEditor);

  const [modalOpen, setModalOpen] = useState(false);

  useCheckProjLevelSettingsUploadDocument(project, onChangeCurrentProject, projectId);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setSaveError(null);
  }, [setModalOpen, setSaveError]);

  useEffect(() => {

    if (saveError?.display) {
      setModalOpen(true);
    }

  }, [saveError, setSaveError]);

  const getFailureModal = useCallback(
    () => {

      if (!saveError) {
        return;
      }
      return <PopupOverlay
        isVisible={true}
        isModal={true}
        onClose={handleCloseModal}
        width={"60rem"}
      >
        <ModalTemplate
          boxClassName="tag-editor"
          title={saveError.title}
          onClose={handleCloseModal}
          content={
            <span>
              <Trans
                i18nKey={saveError.message}
                values={{ path: saveError.path }}
                shouldUnescape={true}
              />
            </span>
          }
          footer={
            <Button
              type={"primary"}
              disabled={false}
              label="OK"
              onClick={handleCloseModal}
            />
          }
        />
      </PopupOverlay>
    },
    [saveError, handleCloseModal]
  );

  const onPromptChange = (text) => {
    setPromptError(null);
    setPrompt(text);
  }

  const isLoadingPage = useMemo(() => {
    return isGenerateReportLoading || isLoadingOnSaveJsonEditor;
  }, [isGenerateReportLoading, isLoadingOnSaveJsonEditor]);

  return (
    <PageTemplate
      header={{
        title: t(
          "interactiveReport:interactiveReport.ui.navigation.aiReporting"
        )
      }}
      body={{
        primary: (
          <div className="ai-reporting">

            <BoxTemplate boxClassName='box-template'>

              <div className="box-template-content-prompt">
                <TextAreaInput
                  label={t("interactiveReport:interactiveReport.aiReporting.inputText.label")}
                  value={prompt}
                  placeholder={t("interactiveReport:interactiveReport.aiReporting.inputText.placeholder")}
                  onChange={v => onPromptChange(v.target.value)}
                  maxLength={255}
                  className="text-area"
                  error={t(promptError?.message)}
                />

                <div className="button-panel">

                  <BrandButton
                    type="primary"
                    disabled={!prompt || isLoadingPage}
                    onClick={() => generateReport({ projectId, prompt, jsonContent: JSON.parse(localJSONContent) })}
                    label={onGenerated ? t('interactiveReport:interactiveReport.aiReporting.reGenerateReportButton.label') : t(
                      "interactiveReport:interactiveReport.aiReporting.generateReportButton.label"
                    )}
                    size="small"
                  />
                </div>
              </div>
            </BoxTemplate>

            <BoxTemplate>
              <div className="box-template-content-editor">

                <div className="json-editor-interactive-box">
                  <JsonEditorInteractiveBox
                    schema={schema}
                    localJSONContent={localJSONContent}
                    isSuccessOnSaveJsonEditor={isSuccessOnSaveJsonEditor}
                    errorOnSaveJsonEditor={errorOnSaveJsonEditor}
                    onChangeJSONEditor={onChangeJSONEditor}
                    disabled={isLoadingPage}
                  />
                </div>

                <div className="button-panel">

                  <BrandButton
                    type="primary"
                    disabled={!onEdit || isLoadingPage}
                    onClick={onApply}
                    label={t(
                      "interactiveReport:interactiveReport.aiReporting.applyReportButton.label"
                    )}
                    size="small"
                  />

                  <BrandButton
                    type="secondary"
                    disabled={!onEdit || isLoadingPage}
                    onClick={onCancel}
                    label={t(
                      "interactiveReport:interactiveReport.aiReporting.cancelReportButton.label"
                    )}
                    size="small"
                  />
                </div>
              </div>
            </BoxTemplate>

            {
              isLoadingPage &&
              <>
                <LoaderSpinner diameter={21} colorStyle="accent" />
                <div className="disabled-reporting"></div>
              </>
            }
          </div>
        )
      }}
      other={{
        loading: "",
        project,
        fullScreenContent: jsonData
      }}
      modal={{
        open: modalOpen,
        content: getFailureModal()
      }}
    />
  );
};

export default AiReporting;
