import React from "react";

import PropTypes from "prop-types";

import "./toCard.scss";

const ToCard = props => {
  const handleClose = () => {
    props.onRemove(props.user);
  };

  return (
    <div className="to-card__container">
      <span className="to-card__name">{props.user.name}</span>
      {!props.disableRemove ? (
        <i onClick={handleClose} className="material-icons to-card__icon">
          close
        </i>
      ) : (
        ""
      )}
    </div>
  );
};
ToCard.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  onRemove: PropTypes.func.isRequired,
  disableRemove: PropTypes.bool
};

export default ToCard;
