import React, { useCallback, useMemo } from "react";

import PropTypes from "prop-types";

import { systemConstants } from "@shared/constants/systemConstants";

import TextTruncate from "@shared-components/UI/textTruncate/TextTruncate";

import { useDataTable } from "@app/hooks";

import DataTable from "@components/molecules/DataTable";

const statusesToIndicatorColorScheme = {
  [systemConstants.user.status.active]: "success",
  [systemConstants.user.status.inactive]: "dark"
};

const roles = {
  [systemConstants.roles.engagementManager]:
    systemConstants.roles.engagementManager,
  [systemConstants.roles.teamMember]: systemConstants.roles.teamMember,
  [systemConstants.roles.clientAdmin]: "Admin",
  [systemConstants.roles.clientUser]: "User"
};

const actions = {
  deactivateUser: "Deactivate User",
  activateUser: "Activate User",
  updateUser: "Update User"
};

const tableConstants = {
  active: "Active?",
  email: "Email",
  name: "Name",
  role: "Role",
  userGroups: "User Groups"
};

const UserDataTable = props => {
  const {
    users,
    onActivateUser,
    onDeactivateUser,
    onUpdateUser,
    exposedUpdateAction
  } = props;

  const {
    findItem,
    createColumnForStatus,
    createColumn,
    createColumnForSingleAction,
    createColumnForDropdownMenu
  } = useDataTable(users);

  const handleAction = useCallback(
    (action, clientUserId) => {
      const clientUser = findItem(clientUserId);
      if (action === actions.deactivateUser) {
        onDeactivateUser(clientUser);
      } else if (action === actions.activateUser) {
        onActivateUser(clientUser);
      } else if (action === actions.updateUser) {
        onUpdateUser(clientUser);
      }
    },
    [findItem, onActivateUser, onDeactivateUser, onUpdateUser]
  );

  const singleActionHandler = useCallback(
    action =>
      ({ cell }) => {
        const clientId = cell.row.original.id;
        handleAction(action, clientId);
      },
    [handleAction]
  );

  const dropdownMenuActionHandler = useCallback(
    ({ menuItem, cell }) => {
      const clientUserId = cell.row.original.id;
      const action = menuItem.name;
      handleAction(action, clientUserId);
    },
    [handleAction]
  );

  const columns = useMemo(() => {
    const result = [
      createColumnForStatus({
        Header: tableConstants.active,
        accessor: "statusIndicatorColorScheme",
        width: 75,
        fixedWidth: true
      }),
      createColumn({
        Header: tableConstants.name,
        accessor: "name",
        width: 250,
        Cell: ({ value }) => <TextTruncate text={value} inline={true} />
      }),
      createColumn({
        Header: tableConstants.email,
        accessor: "email",
        width: 250
      }),
      createColumn({
        Header: tableConstants.role,
        accessor: "role",
        width: 150
      }),
      createColumn({
        Header: tableConstants.userGroups,
        accessor: "groups",
        width: 200
      })
    ];
    if (exposedUpdateAction) {
      result.push(
        createColumnForSingleAction({
          accessor: "action",
          onClickHandler: singleActionHandler(actions.updateUser),
          width: 150,
          fixedWidth: true
        })
      );
    }
    result.push(
      createColumnForDropdownMenu({
        accessor: "actionsMenu",
        onClickHandler: dropdownMenuActionHandler,
        className: "visible-on-hover"
      })
    );
    return result;
  }, [
    exposedUpdateAction,
    singleActionHandler,
    dropdownMenuActionHandler,
    createColumnForStatus,
    createColumn,
    createColumnForSingleAction,
    createColumnForDropdownMenu
  ]);

  const data = React.useMemo(
    () =>
      users.map(item => {
        const statusIndicatorColorScheme =
          statusesToIndicatorColorScheme[item.status] || "dark";

        const displayRoleName = roles[item.role.name];

        const getActionsMenu = () => {
          const menuItems = [];

          if (!exposedUpdateAction) {
            menuItems.push({ name: actions.updateUser });
          }

          if (item.status === systemConstants.user.status.active) {
            menuItems.push({ name: actions.deactivateUser });
          } else {
            menuItems.push({ name: actions.activateUser });
          }

          return menuItems;
        };

        return {
          id: item.id,
          statusIndicatorColorScheme,
          name: item.name,
          email: item.email,
          role: displayRoleName,
          groups: item.groups.map(group => group.name).join(", "),
          action: actions.updateUser,
          actionsMenu: getActionsMenu()
        };
      }),
    [users, exposedUpdateAction]
  );

  return <DataTable columns={columns} data={data} />;
};

UserDataTable.defaultProps = {
  exposedUpdateAction: false
};

UserDataTable.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      role: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired,
      groups: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.number
        })
      )
    })
  ),
  exposedUpdateAction: PropTypes.bool,
  onUpdateUser: PropTypes.func.isRequired,
  onActivateUser: PropTypes.func.isRequired,
  onDeactivateUser: PropTypes.func.isRequired
};

export default UserDataTable;
