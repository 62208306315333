import { systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { handleErrorResponse, handleResponse } from "./";

const baseURL = systemConstants.serverURL;

export const manageHostGroupsService = {
  getHostGroups
};

function getHostGroups(user) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`${baseURL}/api/hosts/${user.hostId}/groups`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}
