import React, { useCallback, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import * as yup from "yup";

import { systemConstants } from "@shared/constants";
import { utilities } from "@shared/helpers/utilities";
import {
  useAddFinalPackageProcess,
  useGetFinalPackageSignedDocuments,
  useSupportedDocumentMimes
} from "@shared/hooks";

import ErrorBox from "@shared-components/errorBox/ErrorBox";

import { handleFreezeScrollPosition } from "@app/helpers/componentHelpers";

import Form from "@components/atoms/Form";
import ModalForm from "@components/molecules/ModalForm";

import "./AddFinalPackageProcessForm.scss";

const addFilesState = systemConstants.addFiles.state;

const noFileItem = {
  name: "-- no file --",
  value: -1
};

const defaultMessageStrings = Object.freeze({
  lodgementReceipt: Object.freeze({
    requiredError: "You must enter a lodgement date."
  })
});

function AddFinalPackageProcessForm(props) {
  const { project, config, onCancel, onSubmitted } = props;
  const [showForm, setShowForm] = useState(false);
  const {
    createProcessEntry,
    createError,
    isCreating,
    createdEntry,
    resetCreateProcessEntry
  } = useAddFinalPackageProcess(project);
  const [documentsForDropdown, setDocumentsForDropdown] = useState([
    noFileItem
  ]);
  const { supportedDocumentMimes } = useSupportedDocumentMimes();
  const { signedDocuments, fetchSignedDocuments } =
    useGetFinalPackageSignedDocuments();
  const [error, setError] = useState("");
  const [fields, setFields] = useState([]);
  const [uploadState, setUploadState] = useState(addFilesState.add);
  const [newEntry, setNewEntry] = useState();

  const messageStrings = useMemo(() => {
    if (!fields?.length) {
      return defaultMessageStrings;
    }
    const lodgementReceipt = fields.find(f => f.key === "lodgementReceipt");
    const entity = fields.find(f => f.key === "name");
    if (!lodgementReceipt || !entity) {
      return defaultMessageStrings;
    }
    return {
      lodgementReceipt: {
        requiredError: `A ${lodgementReceipt.label} has been uploaded for this ${entity.label}. You must enter a lodgement date.`
      }
    };
  }, [fields]);

  const yupSchema = yup.object({
    name: yup.string().required("Please enter a value"),
    signOffDate: yup.string().nullable(true),
    signOffDocument: yup.object(),
    lodgementDate: yup.string().when("lodgementReceipt", {
      is: lodgementReceipt => {
        return Object.values(lodgementReceipt).length;
      },
      then: () =>
        yup
          .string()
          .nullable(true)
          .required(messageStrings.lodgementReceipt.requiredError),
      otherwise: () => yup.string().nullable()
    }),
    lodgementReceipt: yup.object()
  });

  useEffect(() => {
    if (project) {
      fetchSignedDocuments(project.id);
    }
  }, [fetchSignedDocuments, project]);

  useEffect(() => {
    setShowForm(true);

    const configFields = config.fieldNames.map(f => ({
      key: f.key,
      label: f.formLabel ?? f.label,
      type: f.type,
      required: f.required
    }));
    setFields(configFields);
    resetCreateProcessEntry();
  }, [config, resetCreateProcessEntry]);

  useEffect(() => {
    if (!isCreating && createdEntry) {
      setShowForm(false);
      onSubmitted();
    }
  }, [isCreating, createdEntry, onSubmitted]);

  useEffect(() => {
    setError(createError);
  }, [createError]);

  const handleCancel = useCallback(() => {
    handleFreezeScrollPosition(false);
    setError("");
    setShowForm(false);
    onCancel();
  }, [onCancel]);

  const onUploadsComplete = useCallback(
    files => {
      setUploadState(addFilesState.finished);
      const file =
        files &&
        Object.values(files)?.map(file => {
          return {
            filePathId: file.filePathId,
            name: file.name,
            projectId: project?.id,
            isDeleted: file.isDeleted || false,
            isNew: file.isNew || false
          };
        })?.[0];
      createProcessEntry({ ...newEntry, file });
    },
    [newEntry, createProcessEntry, project?.id]
  );

  const onUploadsFailed = useCallback(error => {
    setError(error);
    setUploadState(addFilesState.finished);
  }, []);

  const handleSubmit = useCallback(
    data => {
      handleFreezeScrollPosition(false);
      setError("");
      const newEntry = {
        name: data.name,
        signOffDate: utilities.safeUtcDate(data.signOffDate),
        lodgementDate: utilities.safeUtcDate(data.lodgementDate),
        signOffDocumentId:
          data.signOffDocument?.value === -1
            ? null
            : data.signOffDocument?.value,
        projectId: project.id
      };
      setNewEntry(newEntry);
      setUploadState(addFilesState.upload);
    },
    [project.id]
  );

  useEffect(() => {
    setDocumentsForDropdown([
      noFileItem,
      ...(signedDocuments ?? []).map(d => ({
        name: d.name,
        value: d.id
      }))
    ]);
  }, [signedDocuments]);

  return (
    <ModalForm
      title={"Create Entry"}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      slidingForm={true}
      show={showForm}
      yupSchema={yupSchema}
    >
      {error && <ErrorBox message={error} />}
      <>
        {fields.map(f => {
          switch (f.type) {
            case "document":
              return (
                <Form.Dropdown
                  key={f.key}
                  name={f.key}
                  label={f.label}
                  required={f.required}
                  items={documentsForDropdown}
                />
              );
            case "text":
              return (
                <Form.TextField
                  key={f.key}
                  name={f.key}
                  label={f.label}
                  required={f.required}
                />
              );
            case "date":
              return (
                <Form.DateField key={f.key} name={f.key} label={f.label} />
              );
            case "file":
              return (
                <div className="file-upload" key={f.key}>
                  <div>{f.label}</div>
                  <Form.UploadDocuments
                    name={f.key}
                    projectId={project?.id}
                    state={uploadState}
                    supportedDocumentMimes={supportedDocumentMimes}
                    maxNumberOfFiles={1}
                    maxNumberOfFilesError="Lodgement receipt needs one file only. Make sure there is exactly one file attached and try again."
                    onUploadsComplete={onUploadsComplete}
                    onUploadsFailed={onUploadsFailed}
                  />
                </div>
              );
          }
        })}
      </>
    </ModalForm>
  );
}

AddFinalPackageProcessForm.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  config: PropTypes.shape({
    title: PropTypes.string,
    fieldNames: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
        type: PropTypes.oneOf(["text", "date", "document", "file"])
      })
    )
  })
};

export default AddFinalPackageProcessForm;
