import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import LoaderSpinner from "@components/atoms/LoaderSpinner/LoaderSpinner";
import DropdownSearchBar from "@components/molecules/DropdownSearchBar/DropdownSearchBar";

import DropdownItem from "../../atoms/DropdownItem/DropdownItem";
import { useSearchableDropdown } from "../../atoms/DropdownSearchable/useSearchableDropdown";
import "./DropdownItemList.scss";

const DropdownItemList = props => {
  const {
    search,
    dropdownItems,
    style,
    isExpandable,
    onScroll,
    isLoading,
    setExpandedItemId
  } = props;
  const { t } = useTranslation();
  const {
    inputRef,
    focusInput,
    searchItems,
    setSearchItems,
    handleClearFilter,
    getDropdownItems
  } = useSearchableDropdown({
    dropdownItems
  });
  const handleDropdownChange = event => {
    setSearchItems(event.target.value ?? "");
  };
  const handleSearch = () => {
    setExpandedItemId(null);
  };

  useEffect(() => {
    search ? focusInput() : setSearchItems("");
  }, [focusInput, setSearchItems, search]);

  return (
    <div className="dropdown-item-list" style={style}>
      {search && (
        <div
          className="dropdown-item-list__search-bar"
          onKeyUp={handleSearch}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <DropdownSearchBar
            value={searchItems}
            onClear={handleClearFilter}
            onChange={handleDropdownChange}
            ref={inputRef}
            forMenu
            placeholder={props.placeholder ?? t("common:ui.forms.search.label")}
          />
        </div>
      )}
      <div
        className="dropdown-item-list__container-scrollable"
        onScroll={onScroll}
      >
        {isLoading ? (
          <div className="dropdown-item-list__item-container">
            <LoaderSpinner diameter="30px" />
          </div>
        ) : (
          getDropdownItems().map(item => (
            <div key={item.id} className="dropdown-item-list__item-container">
              <DropdownItem
                id={item.id}
                item={item}
                onClickSelection={item.onClick}
                isExpandable={isExpandable}
                isExpanded={item.isExpanded}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

DropdownItemList.defaultProps = {
  search: false,
  isExpandable: false
};

DropdownItemList.propTypes = {
  search: PropTypes.bool,
  dropdownItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ),
  isLoading: PropTypes.bool,
  style: PropTypes.object,
  isExpandable: PropTypes.bool,
  onScroll: PropTypes.func,
  setExpandedItemId: PropTypes.func,
  placeholder: PropTypes.string
};

export default DropdownItemList;
