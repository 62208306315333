import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";

export const documentService = createApi({
  reducerPath: "documentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders: (headers, { getState }) => {
      const token =
        getState().authentication?.token || localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: [
    "documentProperties",
    "archivedClientDocuments",
    "clientDocuments"
  ],
  endpoints: builder => ({
    getDocumentProperties: builder.query({
      query: ({ documentId }) => `/api/documents/${documentId}/properties`,
      providesTags: ["documentProperties"]
    }),
    getClientDocuments: builder.query({
      query: ({ clientId }) => `/api/clients/${clientId}/documents`,
      provideTags: ["clientDocuments"]
    }),
    getArchivedClientDocuments: builder.query({
      query: ({ clientId }) => `/api/clients/${clientId}/documents/archived`,
      provideTags: ["archivedClientDocuments"]
    }),
    deleteClientDocument: builder.mutation({
      query: ({ clientId, documentId }) => ({
        url: `/api/clients/${clientId}/documents/${documentId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["clientDocuments", "archivedClientDocuments"]
    }),
    restoreClientDocument: builder.mutation({
      query: ({ clientId, document }) => ({
        url: `/api/clients/${clientId}/documents/${document.id}/restore`,
        method: "POST",
        body: document
      })
    }),
    updateProjectDocumentProperties: builder.mutation({
      query: ({ document }) => ({
        url: `/api/documents/${document.id}`,
        method: "PUT",
        body: document
      }),
      invalidatesTags: ["documentProperties"]
    }),
    updateClientDocumentProperties: builder.mutation({
      query: ({ clientId, document }) => ({
        url: `/api/clients/${clientId}/documents/${document.id}`,
        method: "PUT",
        body: document
      }),
      invalidatesTags: ["documentProperties", "clientDocuments"]
    })
  })
});

export const {
  useGetClientDocumentsQuery,
  useGetArchivedClientDocumentsQuery,
  useGetDocumentPropertiesQuery,
  useUpdateProjectDocumentPropertiesMutation,
  useUpdateClientDocumentPropertiesMutation,
  useDeleteClientDocumentMutation,
  useRestoreClientDocumentMutation
} = documentService;
