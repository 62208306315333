import React, { useEffect, useState } from "react";

import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { routeConstants } from "@constants";

import { manageClientTagsActions } from "@shared/actions";
import { systemConstants } from "@shared/constants";
import { loggedUser } from "@shared/helpers";

import Button from "@components/atoms/Button/BrandButton";
import DropdownPagination from "@components/molecules/DropdownPagination";
import ClientTagsDataTable from "@components/organisms/ClientTagsDataTable";
import MainPageTemplate from "@components/templates/MainPageTemplate";
import PageBodyTemplate from "@components/templates/PageBodyTemplate";

import "./ManageClientTags.scss";

const ManageClientTags = ({ t }) => {
  const authentication = useSelector(state => state.authentication);
  const manageClientTags = useSelector(state => state.manageClientTags);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [client] = useState(location.state);
  const [tags, setTags] = useState([]);
  const [pagination, setPagination] = useState({
    countPerPage: systemConstants.pagination.itemCountPerPage,
    pageCount: 1,
    currentPage: "Page 1",
    currentPageIndex: 0,
    pages: []
  });

  useEffect(() => {
    if (client || manageClientTags.deletedClientTag) {
      dispatch(manageClientTagsActions.getClientTags(client));
    }
    return () => {
      dispatch(manageClientTagsActions.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, manageClientTags.deletedClientTag]);

  useEffect(() => {
    if (manageClientTags.clientTags) {
      setTags(
        manageClientTags.clientTags.sort((a, b) => {
          const textA = a.name.toUpperCase();
          const textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
      );
    }
  }, [manageClientTags.clientTags]);

  const handleAddTag = () => {
    navigate(routeConstants.addClientTag, {
      state: {
        client
      }
    });
  };

  const handlePageSelection = pageName => {
    const index = pagination.pages.findIndex(page => page.name === pageName);
    setPagination({
      ...pagination,
      currentPage: pageName,
      currentPageIndex: index
    });
  };

  const handlePageCountIncrement = () => {
    const index = pagination.currentPageIndex;
    if (index < pagination.pages.length - 1) {
      setPagination({
        ...pagination,
        currentPage: `Page ${index + 2}`,
        currentPageIndex: index + 1
      });
    }
  };

  const handlePageCountDecrement = () => {
    const index = pagination.currentPageIndex;

    if (index > 0) {
      setPagination({
        ...pagination,
        currentPage: `Page ${index}`,
        currentPageIndex: index - 1
      });
    }
  };

  const headerActions = () =>
    loggedUser.doesBelongToHost(authentication.user) && (
      <Button
        type="primary"
        label={t("common:ui.manageClientTags.addTag")}
        iconName="add"
        onClick={handleAddTag}
      />
    );

  return (
    <MainPageTemplate>
      <PageBodyTemplate
        breadcrumbs={[
          {
            linkTo: routeConstants.manageClients,
            label: t("common:ui.manageClients.title")
          }
        ]}
        title={
          client &&
          t("common:ui.manageClientTags.titleWithClientName", {
            clientName: client.name
          })
        }
        actions={headerActions()}
      >
        <div className="manage-client-tags">
          <div className="manage-client-tags__body">
            {tags.length ? (
              <>
                <div className="manage-client-tags__table">
                  <ClientTagsDataTable client={client} data={tags} />
                </div>
                {pagination && pagination.pages.length > 1 ? (
                  <DropdownPagination
                    handlePageSelection={handlePageSelection}
                    handlePageCountIncrement={handlePageCountIncrement}
                    handlePageCountDecrement={handlePageCountDecrement}
                    pages={pagination.pages.map(page => ({ name: page.name }))}
                    currentPage={pagination.currentPage}
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </PageBodyTemplate>
    </MainPageTemplate>
  );
};

export default withTranslation()(ManageClientTags);
