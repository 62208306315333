import React, { useCallback, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { routeConstants } from "@constants";

import { useHostUserProfile } from "@shared/hooks/useHostUserProfile";
import {
  useGetUserByIdQuery,
  useUpdateHostUserMutation
} from "@shared/services";

import ErrorBox from "@shared-components/errorBox/ErrorBox";

import UserForm from "@components/organisms/UserForm/UserForm";
import MainPageTemplate from "@components/templates/MainPageTemplate";
import PageBodyTemplate from "@components/templates/PageBodyTemplate";

const UpdateHostUser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId } = useParams();
  const { data: user, isError } = useGetUserByIdQuery(userId);

  const [updateHostUser, { isSuccess: isHostUserUpdated }] =
    useUpdateHostUserMutation();

  const { roles, teamUserFields, projectsList, error } =
    useHostUserProfile(user);

  useEffect(() => {
    if (!userId || isError) {
      navigate(routeConstants.notFound);
    }
  }, [isError, navigate, userId]);

  useEffect(() => {
    if (isHostUserUpdated) {
      navigate(routeConstants.manageUsers);
    }
  }, [isHostUserUpdated, navigate]);

  const updateUser = useCallback(
    userProfile => {
      updateHostUser({ user, userProfile });
    },
    [updateHostUser, user]
  );

  const addConfigKey = key =>
    teamUserFields?.find(field => field.key === key) ?? {};

  const addRole = () => {
    if (roles?.length > 1) {
      return {
        key: "role",
        type: "dropdown",
        required: true,
        items: roles
      };
    }
    return {};
  };

  const formConfig = [
    {
      key: "firstname",
      type: "text",
      required: true
    },
    {
      key: "lastname",
      type: "text",
      required: true
    },
    {
      key: "email",
      type: "text",
      required: true
    },
    addConfigKey("jobTitle"),
    addRole(),
    {
      key: "projects",
      type: "multiselect",
      required: false,
      items: projectsList,
      defaultValue: projectsList?.filter(p => p.added)
    },
    addConfigKey("MOBILE_NUMBER"),
    addConfigKey("BUSINESS_NUMBER"),
    {
      key: "NOTIFICATION",
      type: "radio",
      defaultValue: "digest",
      items: ["digest", "each"],
      path: "properties.notification.email"
    }
  ];

  return (
    <MainPageTemplate>
      <PageBodyTemplate
        breadcrumbs={[
          {
            linkTo: routeConstants.manageUsers,
            label: t("common:ui.manageUsers.title")
          }
        ]}
        title={t("common:ui.manageUsers.updateTitle_team")}
      >
        {error && <ErrorBox message={error} />}
        <UserForm
          user={user}
          formConfig={formConfig}
          handleSubmit={updateUser}
          submitLabel={t("common:ui.manageUsers.updateSubmit")}
        />
      </PageBodyTemplate>
    </MainPageTemplate>
  );
};

export default UpdateHostUser;
