import { manageAuditConstants } from "../constants";
import { manageAuditService } from "../services";

export const manageAuditActions = {
  getAuditRecordsByUser,
  resetAuditRecords,
  setError,
  reset
};

function getAuditRecordsByUser(user) {
  return dispatch => {
    dispatch(request());

    manageAuditService.getAuditRecordsByUser(user).then(
      records => {
        dispatch(success(records));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageAuditConstants.GET_AUDIT_RECORDS_BY_USER_REQUEST
    };
  }
  function success(records) {
    return {
      type: manageAuditConstants.GET_AUDIT_RECORDS_BY_USER_SUCCESS,
      records
    };
  }
  function failure(error) {
    return {
      type: manageAuditConstants.GET_AUDIT_RECORDS_BY_USER_FAILURE,
      error
    };
  }
}

function resetAuditRecords() {
  return { type: manageAuditConstants.RESET_AUDIT_RECORDS };
}

function setError(error) {
  return { type: manageAuditConstants.SET_ERROR, error };
}

function reset() {
  return { type: manageAuditConstants.RESET };
}
