import React, { useCallback } from "react";

import PropTypes from "prop-types";

import SearchableDropdown from "@components/atoms/DropdownSearchable/SearchableDropdown";

import "./GroupLabels.scss";

const GroupLabels = props => {
  const {
    groupLabel,
    dropdownItems,
    handleSelectFilter,
    styleVariant,
    icon,
    disabled = false,
    position,
    navigationStyle
  } = props;

  const handleClickSelection = useCallback(
    selectedLabel => {
      handleSelectFilter?.(selectedLabel);
    },
    [handleSelectFilter]
  );

  return (
    <div
      className={`groupLabels ${
        navigationStyle === "OT1" ? `groupLabels--style-${styleVariant}` : ""
      }`}
    >
      <SearchableDropdown
        name={groupLabel}
        dropdownItems={dropdownItems}
        handleClickSelection={handleClickSelection}
        leftIcon={styleVariant === "iconBox" ? icon : null}
        disabled={disabled}
        position={position}
        navigationStyle={navigationStyle}
      />
    </div>
  );
};

GroupLabels.defaultProps = {
  styleVariant: "standard"
};

GroupLabels.propTypes = {
  groupLabel: PropTypes.string,
  dropdownItems: PropTypes.array,
  handleSelectFilter: PropTypes.func,
  styleVariant: PropTypes.oneOf(["standard", "iconBox"]),
  position: PropTypes.oneOf(["left", "right"]),
  navigationStyle: PropTypes.oneOf(["OT1", "OT2"]),
  disabled: PropTypes.bool,
  icon: PropTypes.node, // NB: icon is only visible when styleVariant=iconBox
  isActive: PropTypes.bool
};

export default GroupLabels;
