import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { useGetEngagementTypesQuery } from "@shared/hooks";

import DropdownInput from "@components/atoms/DropdownInput";
import Form from "@components/atoms/Form";

import "./SelectEngagementTypeDropdown.scss";

function SelectEngagementTypeDropdown(props) {
  const { t } = useTranslation();
  const {
    name,
    onChange,
    hideIfOne,
    isFormField,
    defaultValue,
    required,
    disablePreselectDefault
  } = props;
  const { data: engagementTypes } = useGetEngagementTypesQuery();
  const [selectedEngagementType, setSelectedEngagementType] =
    useState(defaultValue);

  useEffect(() => {
    if (!defaultValue && !disablePreselectDefault && engagementTypes?.length) {
      setSelectedEngagementType(prevState => {
        if (!prevState) {
          const engagementType = {
            name: engagementTypes[0].name,
            value: engagementTypes[0]
          };

          return engagementType;
        }
        return prevState;
      });
    }
  }, [defaultValue, disablePreselectDefault, engagementTypes]);

  useEffect(() => {
    if (engagementTypes?.length === 1) {
      setSelectedEngagementType({
        name: engagementTypes[0].name,
        value: engagementTypes[0]
      });
    }
  }, [engagementTypes]);

  useEffect(() => {
    if (selectedEngagementType?.value) {
      onChange?.(selectedEngagementType.value);
    }
  }, [onChange, selectedEngagementType]);

  const getEngagementTypesDropdown = () => {
    if (!engagementTypes?.length) {
      return <></>;
    }

    if (hideIfOne && engagementTypes.length === 1) {
      return isFormField ? (
        <Form.HiddenInput
          name={name ?? "engagementType"}
          value={{
            name: engagementTypes[0].name,
            value: engagementTypes[0]
          }}
        />
      ) : (
        <></>
      );
    }

    const dropdownItems = engagementTypes.map(e => ({
      name: e.name,
      value: e
    }));

    return (
      <div className="ot-select-engagement-type-dropdown">
        {isFormField ? (
          <Form.Dropdown
            name={name ?? "engagementType"}
            label={t("stringFormat.propercase", {
              key: "common:ui.engagementType.displayName"
            })}
            items={dropdownItems}
            defaultValue={selectedEngagementType}
            onChange={setSelectedEngagementType}
            required={required}
            placeholder={t("common:ui.forms.placeholder.selectionOption", {
              indefiniteArticle: "an",
              label: t("stringFormat.propercase", {
                key: "common:ui.engagementType.displayName"
              })
            })}
          />
        ) : (
          <DropdownInput
            label={t("stringFormat.propercase", {
              key: "common:ui.engagementType.displayName"
            })}
            items={dropdownItems}
            value={selectedEngagementType}
            showSearchFilter={false}
            onChange={setSelectedEngagementType}
            required={required}
            placeholder={t("common:ui.forms.placeholder.selectionOption", {
              indefiniteArticle: "an",
              label: t("stringFormat.propercase", {
                key: "common:ui.engagementType.displayName"
              })
            })}
          />
        )}
      </div>
    );
  };

  return <div>{getEngagementTypesDropdown()}</div>;
}

SelectEngagementTypeDropdown.defaultProps = {
  hideIfOne: false,
  disablePreselectDefault: false,
  isFormField: false
};

SelectEngagementTypeDropdown.propTypes = {
  onChange: PropTypes.func,
  hideIfOne: PropTypes.bool,
  isFormField: PropTypes.bool,
  defaultValue: PropTypes.object,
  required: PropTypes.bool,
  disablePreselectDefault: PropTypes.bool
};

export default SelectEngagementTypeDropdown;
