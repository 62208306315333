import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  manageDocumentUploadsActions,
  manageProjectDocumentsActions
} from "@shared/actions";
import { systemConstants } from "@shared/constants";
import { useDocumentCheck } from "@shared/hooks";

import { convertEntitiesToIds } from "@app/helpers/entity";

import Button from "@components/atoms/Button/BrandButton";
import InlineAlert from "@components/atoms/InlineAlert/InlineAlert";
import MultiSelect from "@components/atoms/Multiselect";
import TextAreaInput from "@components/atoms/TextAreaInput";
import UploadFileAttachmentList from "@components/molecules/UploadFileAttachmentList";
import ModalTemplate from "@components/templates/ModalTemplate/ModalTemplate";

import "./AddClientProjectDocument.scss";

const attachedFileStates = systemConstants.addFiles.attachedFile.state;

const AddClientProjectDocument = props => {
  const { t, project } = props;
  const { entities } = project;
  const entityEnabled = project.configuration.entities?.enabled;
  const dispatch = useDispatch();
  const manageDocumentUploads = useSelector(
    state => state.manageDocumentUploads
  );
  const manageProjectDocuments = useSelector(
    state => state.manageProjectDocuments
  );
  const manageProject = useSelector(state => state.manageProject);
  const [documents, setDocuments] = useState([...props.droppedFiles]);
  const [attachmentsObject, setAttachmentsObject] = useState({});
  const [files, setFiles] = useState([...props.droppedFiles]);
  const [uploadSubmitted, setUploadSubmitted] = useState(false);
  const [selectedEntities, setSelectedEntities] = useState(
    entities?.length > 1 ? [] : entities
  );
  const inputRef = useRef(null);
  const [error, setError] = useState(props.error);
  const [uploadedDocuments, setUploadedDocuments] = useState(false);
  const uploadAbortError = "As per user request aborting document(s) upload.";
  const [supportedDocumentMimes, setSupportedDocumentMimes] = useState(
    systemConstants.mimes.document
  );
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagItems] = useState(
    props.tags.map(tag => ({ name: tag.name, value: tag.id }))
  );
  const { check, fetchCheck, isActionTypeSmartForm } = useDocumentCheck(
    props.project.id
  );
  const [requiresOverrideMessage, setRequiresOverrideMessage] = useState(false);
  const [overrideMessage, setOverrideMessage] = useState(null);

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  useEffect(() => {
    setSupportedDocumentMimes(manageProject.supportedDocumentMimes);
  }, [manageProject.supportedDocumentMimes]);

  useEffect(() => {
    if (documents?.length > 0) {
      const formattedDocuments = {};
      documents.forEach(doc => {
        if (doc.name) {
          if (!uploadSubmitted && doc.error) {
            doc.state = attachedFileStates.uploadFailed;
          }
          if (doc.pathId) {
            doc.state = attachedFileStates.uploaded;
          }
          if (!doc.state) {
            doc.state = attachedFileStates.selected;
          }
          formattedDocuments[doc.name] = doc;
        }
      });
      setAttachmentsObject(formattedDocuments);
    } else {
      setAttachmentsObject({});
    }
  }, [documents, uploadSubmitted]);

  useEffect(() => {
    if (props.droppedFiles && props.droppedFiles.length) {
      const documentList = props.droppedFiles.map(documentObject => {
        return {
          id: documentObject.id || null,
          name: documentObject.name,
          state: attachedFileStates.selected,
          projectId: props.project.id,
          projectFolderId: props.parentFolderId,
          revisionName: ""
        };
      });
      setFiles([...props.droppedFiles]);
      setDocuments([...documentList]);
    }
  }, [props.droppedFiles, props.parentFolderId, props.project.id]);

  useEffect(() => {
    documents.forEach(documentObject => {
      if (documentObject.pathId) {
        if (!documentObject.metadataUpdateStated) {
          const appendOverrideMessage = check.some(
            c => c.present && c.name === documentObject.name
          );
          const revisionProperties = appendOverrideMessage
            ? { overrideMessage }
            : null;
          const documentProperties = entityEnabled
            ? { entities: convertEntitiesToIds(selectedEntities) }
            : null;
          dispatch(
            manageProjectDocumentsActions.uploadNewProjectDocument({
              ...documentObject,
              documentPathId: documentObject.pathId,
              tags: selectedTags,
              properties: revisionProperties,
              documentProperties
            })
          );
          documentObject.metadataUpdateStated = true;
        }
      }

      if (!documentObject.pathId && documentObject.error === uploadAbortError) {
        documentObject.aborted = true;
      }

      return documentObject;
    });
  }, [
    documents,
    dispatch,
    selectedTags,
    check,
    overrideMessage,
    selectedEntities,
    entityEnabled
  ]);

  useEffect(() => {
    if (documents.some(documentObject => !documentObject.updatedMetaData)) {
      setUploadedDocuments(false);
    } else {
      setUploadedDocuments(true);
    }
  }, [documents]);

  useEffect(() => {
    if (manageProjectDocuments.error) {
      setError(manageProjectDocuments.error);
    }
  }, [manageProjectDocuments.error]);

  useEffect(() => {
    if (
      manageProjectDocuments.newUploadedDocuments &&
      manageProjectDocuments.newUploadedDocuments.length
    ) {
      manageProjectDocuments.newUploadedDocuments.forEach(
        newUploadedDocumentObject => {
          const updatedDocuments = documents.map(documentObject => {
            if (documentObject.name === newUploadedDocumentObject.name) {
              documentObject.updatedMetaData = true;
            }
            return documentObject;
          });
          setDocuments(updatedDocuments);
        }
      );
    }
    // eslint-disable-next-line
  }, [manageProjectDocuments.newUploadedDocuments]);

  useEffect(() => {
    if (
      manageProjectDocuments.uploadedDocuments &&
      manageProjectDocuments.uploadedDocuments.length
    ) {
      manageProjectDocuments.uploadedDocuments.forEach(
        uploadedDocumentObject => {
          const updatedDocuments = documents.map(documentObject => {
            if (documentObject.name === uploadedDocumentObject.name) {
              documentObject.updatedMetaData = true;
            }
            return documentObject;
          });
          setDocuments(updatedDocuments);
        }
      );
    }
    // eslint-disable-next-line
  }, [manageProjectDocuments.uploadedDocuments]);

  useEffect(() => {
    if (
      manageDocumentUploads.uploadingDocuments &&
      manageDocumentUploads.uploadingDocuments.length
    ) {
      documents.forEach(document => {
        const documentUploaded = manageDocumentUploads.uploadingDocuments.find(
          uploadingDocument =>
            uploadingDocument.projectId === document.projectId &&
            uploadingDocument.name === document.name
        );

        if (documentUploaded && documentUploaded.documentPathId) {
          const updatedDocuments = documents.map(documentObject => {
            if (documentObject.name === document.name) {
              documentObject.pathId = documentUploaded.documentPathId;
              documentObject.state = attachedFileStates.attached;
            }
            return documentObject;
          });
          setDocuments(updatedDocuments);
        }

        if (documentUploaded && documentUploaded.error) {
          setError(documentUploaded.error);
          const updatedDocuments = documents.map(documentObject => {
            if (documentObject.name === document.name) {
              documentObject.error = documentUploaded.error;
              documentObject.state = attachedFileStates.selected;
            }
            return documentObject;
          });
          setDocuments(updatedDocuments);
        }

        if (documentUploaded && documentUploaded.uploadProgress) {
          const updatedDocuments = documents.map(documentObject => {
            if (documentObject.name === document.name) {
              documentObject.uploadProgress = documentUploaded.uploadProgress;
              documentObject.state = attachedFileStates.uploading;
            }
            return documentObject;
          });
          setDocuments(updatedDocuments);
        }

        if (documentUploaded && documentUploaded.reqSource) {
          const updatedDocuments = documents.map(documentObject => {
            if (documentObject.name === document.name) {
              documentObject.reqSource = documentUploaded.reqSource;
            }
            return documentObject;
          });
          setDocuments(updatedDocuments);
        }
      });
    }
    // eslint-disable-next-line
  }, [manageDocumentUploads.uploadingDocuments]);

  const handleChange = event => {
    event.stopPropagation();
    const addedDocuments = [...event.target.files];
    event.target.value = null;
    if (
      addedDocuments.length &&
      addedDocuments.some(
        document => document.size > systemConstants.project.document.maxSize
      )
    ) {
      setError("The maximum supported file size is 50 GB for documents.");
    } else {
      const newDocuments = addedDocuments.filter(documentObject => {
        let documentNotAdded = true;
        files.forEach(file => {
          if (documentObject.name === file.name) {
            documentNotAdded = false;
          }
        });
        return documentNotAdded;
      });

      setFiles(files.concat(newDocuments));
      setDocuments(
        documents.concat(
          newDocuments.map(file => {
            return {
              id: file.id || null,
              name: file.name,
              projectId: props.project.id,
              projectFolderId: props.parentFolderId,
              revisionName: ""
            };
          })
        )
      );
      setError(false);
    }
  };

  const handleSelectEntities = e => {
    setSelectedEntities(
      e?.map(({ name, externalId }) => ({
        name,
        externalId
      }))
    );
  };

  const handleCancel = useCallback(() => {
    if (!uploadSubmitted || error) {
      dispatch(manageProjectDocumentsActions.resetNewUploadedDocument());
      dispatch(manageProjectDocumentsActions.resetUploadedDocument());
      dispatch(manageDocumentUploadsActions.resetUploadingDocuments());
      dispatch(manageProjectDocumentsActions.clearError());
      setOverrideMessage(null);
      setRequiresOverrideMessage(false);
      props.onUpload();
    }
  }, [dispatch, error, props, uploadSubmitted]);

  const handleUpload = () => {
    if (files.length) {
      fetchCheck(files.map(f => f.name));
    }
    props.onFileUpload();
  };

  const handleConfirmOverride = useCallback(() => {
    if (requiresOverrideMessage && !overrideMessage) {
      return;
    }

    setUploadSubmitted(true);
    files.forEach(file => {
      dispatch(
        manageDocumentUploadsActions.uploadDocument({
          file,
          projectId: props.project.id
        })
      );
    });
  }, [
    requiresOverrideMessage,
    overrideMessage,
    files,
    props.project.id,
    dispatch
  ]);

  useEffect(() => {
    if (!check?.length) {
      return;
    }
    const hasExistingFilenamesPresent = check.some(c => c.present);
    if (hasExistingFilenamesPresent) {
      setRequiresOverrideMessage(true);
    } else {
      setRequiresOverrideMessage(false);
      handleConfirmOverride();
    }
  }, [check, handleConfirmOverride]);

  const handleReqCancel = useCallback(() => {
    let isReqCancelled = false;
    documents.forEach(docObject => {
      if (docObject && docObject.reqSource && !docObject.pathId) {
        isReqCancelled = true;
        docObject.reqSource.cancel(uploadAbortError);
      }
    });
    if (!isReqCancelled) {
      dispatch(manageProjectDocumentsActions.resetNewUploadedDocument());
      dispatch(manageProjectDocumentsActions.resetUploadedDocument());
      dispatch(manageDocumentUploadsActions.resetUploadingDocuments());
      setOverrideMessage(null);
      props.onUpload();
    }
  }, [dispatch, documents, props]);

  const handleDone = useCallback(() => {
    if (uploadedDocuments) {
      dispatch(manageProjectDocumentsActions.resetNewUploadedDocument());
      dispatch(manageProjectDocumentsActions.resetUploadedDocument());
      dispatch(manageDocumentUploadsActions.resetUploadingDocuments());
      setOverrideMessage(null);
      props.onUpload();
    }
  }, [dispatch, props, uploadedDocuments]);

  const handleAddFile = () => {
    inputRef.current.click();
  };

  const handleRemove = removedDocumentName => {
    const updatedFiles = files.filter(
      fileObject => fileObject.name !== removedDocumentName
    );
    const updatedDocuments = documents.filter(
      documentObject => documentObject.name !== removedDocumentName
    );
    setFiles(updatedFiles);
    setDocuments(updatedDocuments);
    setError("");
    setUploadSubmitted(false);
  };

  const getUploadCancellationError = () => {
    const uploadCancelledDocuments = documents.filter(
      docObject => docObject.error === uploadAbortError
    );

    const uploadedDocumentsCount =
      documents.length - uploadCancelledDocuments.length;
    return `${uploadedDocumentsCount} document(s) uploaded and ${uploadCancelledDocuments.length} document(s) were cancelled.`;
  };

  const getSupportedMimes = () => {
    const supportedMimesList = supportedDocumentMimes.map(mime => `.${mime}`);
    return supportedMimesList.join();
  };

  const onChangeTagSelection = selection => {
    setSelectedTags(selection.map(item => ({ id: item.value })));
  };

  const handleClose = useMemo(() => {
    if (uploadedDocuments) {
      return handleDone;
    }

    if (!uploadSubmitted || error) {
      return handleCancel;
    }

    return handleReqCancel;
  }, [
    uploadedDocuments,
    uploadSubmitted,
    error,
    handleReqCancel,
    handleDone,
    handleCancel
  ]);

  const getLabelsField = () => {
    if (documents.length > 0 && props.tags?.length > 0) {
      return (
        <MultiSelect
          key="tags"
          onChange={onChangeTagSelection}
          disabled={uploadSubmitted}
          label={t("common:ui.documents.fileUpload.selectLabels")}
          items={tagItems}
        />
      );
    }
  };

  const getEntitiesField = () => {
    if (
      entityEnabled &&
      entities?.length > 1 &&
      (!check.length || check.some(({ present }) => !present))
    ) {
      return (
        <MultiSelect
          key="entities"
          required={false}
          onChange={handleSelectEntities}
          disabled={uploadSubmitted}
          label={t("requests:requests.configured.fields.entities.label")}
          items={entities}
        />
      );
    }
  };

  const content = () => {
    return (
      <>
        {error && (
          <InlineAlert
            type="error"
            message={
              error === uploadAbortError
                ? getUploadCancellationError()
                : t(error)
            }
          />
        )}
        <div className="hidden">
          <input
            ref={inputRef}
            onChange={handleChange}
            type="file"
            multiple
            accept={getSupportedMimes()}
          />
        </div>
        <div>
          {documents.length ? (
            <>
              {error ? (
                ""
              ) : (
                <p>
                  {t(
                    "common:ui.documents.fileUpload.uploadAttachmentFileList",
                    {
                      count: documents.length
                    }
                  )}
                </p>
              )}
              <div className="add-file__container--file-box">
                {documents && (
                  <UploadFileAttachmentList
                    attachments={attachmentsObject}
                    onDelete={handleRemove}
                    disabled={!uploadSubmitted && requiresOverrideMessage}
                  />
                )}
              </div>
            </>
          ) : (
            <p>{t("common:documents.fileUpload.addDocumentsHintText")}</p>
          )}
          {!uploadSubmitted && !requiresOverrideMessage ? (
            <>
              <Button
                type="text-dark"
                label={t("common:documents.fileUpload.addDocuments")}
                iconName="add_circle"
                onClick={handleAddFile}
              />
            </>
          ) : (
            ""
          )}
        </div>
        {getLabelsField()}
        {getEntitiesField()}
        {!uploadSubmitted && requiresOverrideMessage && (
          <div>
            {isActionTypeSmartForm ? (
              <InlineAlert
                type="warning"
                message={t("common:ui.upload.error.fileName.cannotOverwrite")}
              ></InlineAlert>
            ) : (
              <>
                <InlineAlert
                  type="warning"
                  message={t("common:ui.upload.error.fileName.existed")}
                ></InlineAlert>
                <TextAreaInput
                  label={t("common:ui.upload.fileUpload.labelOverrideMessage")}
                  value={overrideMessage}
                  required={true}
                  onChange={v => setOverrideMessage(v.target.value)}
                  maxLength={255}
                />
              </>
            )}
          </div>
        )}
        {props.subtext && (
          <div className="add-file__container--subtext">{props.subtext}</div>
        )}
      </>
    );
  };

  const footers = () => {
    if ((!uploadSubmitted && !requiresOverrideMessage) || error) {
      return (
        <>
          <Button
            type={error ? "primary" : "secondary"}
            disabled={uploadSubmitted && !error}
            label={t("common:documents.fileUpload.buttons", {
              context:
                error === uploadAbortError ? "CLOSE" : error ? "DONE" : "CANCEL"
            })}
            onClick={handleCancel}
          />
          {error ? (
            ""
          ) : (
            <Button
              type="primary"
              disabled={!files.length || uploadSubmitted || !!error}
              label={t("common:documents.fileUpload.buttons", {
                context: "UPLOAD"
              })}
              onClick={handleUpload}
            />
          )}
        </>
      );
    } else if (!uploadSubmitted && requiresOverrideMessage) {
      return (
        <>
          <Button
            type={"secondary"}
            disabled={!requiresOverrideMessage}
            label={t("common:documents.fileUpload.buttons", {
              context: "CANCEL"
            })}
            onClick={handleCancel}
          />
          <Button
            type="primary"
            disabled={!requiresOverrideMessage || !overrideMessage}
            label={t("common:ui.forms.upload.label")}
            onClick={handleConfirmOverride}
          />
        </>
      );
    } else {
      return (
        <>
          {uploadedDocuments ? (
            <Button
              type="primary"
              disabled={!uploadedDocuments}
              label={t("common:documents.fileUpload.buttons", {
                context: "DONE"
              })}
              onClick={handleDone}
            />
          ) : (
            <Button
              type="secondary"
              label={t("common:documents.fileUpload.buttons", {
                context: "CANCEL"
              })}
              onClick={handleReqCancel}
            />
          )}
        </>
      );
    }
  };

  return (
    <ModalTemplate
      boxClassName="upload-documents"
      title={props.title || "Upload document(s)"}
      onClose={handleClose}
      content={content()}
      footer={footers()}
    />
  );
};

export default withTranslation()(AddClientProjectDocument);
