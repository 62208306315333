import { systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { handleErrorResponse, handleResponse } from "./";

const baseURL = systemConstants.serverURL;

export const manageHostUsersService = {
  getHostUsers,
  getHostRoles,
  downloadBulkUploadUsersTemplate,
  uploadBulkUsers,
  getEngagementTypesWithTags,
  getEngagementTypes,
  createTag,
  updateTag,
  deleteGlobalTag
};
function getHostUsers(user) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(`${baseURL}/api/hosts/${user.hostId}/users`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getHostRoles(user) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`${baseURL}/api/hosts/${user.hostId}/roles`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function downloadBulkUploadUsersTemplate() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(`${baseURL}/api/users/bulkUploadTemplate`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function uploadBulkUsers(users, clientId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(users)
  };

  return fetch(
    `${baseURL}/api/clients/${clientId}/users/bulk-add`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getEngagementTypesWithTags() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`${baseURL}/api/engagementTypes/tags`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function deleteGlobalTag(engagementTypeId, tagToBeDeleted) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    body: JSON.stringify(tagToBeDeleted)
  };
  const tagId = tagToBeDeleted.tagId;
  return fetch(
    `${baseURL}/api/engagementTypes/${engagementTypeId}/tags/${tagId}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getEngagementTypes() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`${baseURL}/api/engagementTypes`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function createTag(tag) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(tag)
  };

  return fetch(
    `${baseURL}/api/engagementTypes/${tag.engagementTypeId}/tags`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function updateTag(tag) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(tag)
  };

  return fetch(
    `${baseURL}/api/engagementTypes/${tag.engagementTypeId}/tags/${tag.id}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}
