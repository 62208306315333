import { manageClientUsersConstants } from "../constants";

const initialState = {
  googleAuthLoading: false,
  getClientUsersLoading: false,
  getClientGroupsLoading: false,
  getClientRolesLoading: false,
  addClientUserLoading: false,
  updateClientUserLoading: false,
  users: [],
  groups: [],
  roles: [],
  error: "",
  addedUser: false,
  updatedUser: false,
  googleAuthenticationFeature: false
};

export function manageClientUsers(state = initialState, action = {}) {
  switch (action.type) {
    case manageClientUsersConstants.GOOGLE_AUTH_FEATURE_REQUEST:
      return {
        ...state,
        googleAuthLoading: true
      };
    case manageClientUsersConstants.GOOGLE_AUTH_FEATURE_SUCCESS:
      return {
        ...state,
        googleAuthLoading: false,
        googleAuthenticationFeature: action.resObject.enabled,
        error: ""
      };
    case manageClientUsersConstants.GOOGLE_AUTH_FEATURE_FAILURE:
      return {
        ...state,
        googleAuthLoading: false,
        googleAuthenticationFeature: false
      };
    case manageClientUsersConstants.GET_CLIENT_USERS_REQUEST:
      return {
        ...state,
        getClientUsersLoading: true
      };
    case manageClientUsersConstants.GET_CLIENT_USERS_SUCCESS:
      return {
        ...state,
        getClientUsersLoading: false,
        users: action.users,
        error: ""
      };
    case manageClientUsersConstants.GET_CLIENT_USERS_FAILURE:
      return {
        ...state,
        getClientUsersLoading: false,
        users: [],
        error: action.error.key ?? action.error.message
      };
    case manageClientUsersConstants.GET_CLIENT_GROUPS_REQUEST:
      return {
        ...state,
        getClientGroupsLoading: true
      };
    case manageClientUsersConstants.GET_CLIENT_GROUPS_SUCCESS:
      return {
        ...state,
        getClientGroupsLoading: false,
        groups: action.groups,
        error: ""
      };
    case manageClientUsersConstants.GET_CLIENT_GROUPS_FAILURE:
      return {
        ...state,
        getClientGroupsLoading: false,
        groups: [],
        error: action.error.key ?? action.error.message
      };
    case manageClientUsersConstants.GET_CLIENT_ROLES_REQUEST:
      return {
        ...state,
        getClientRolesLoading: true
      };
    case manageClientUsersConstants.GET_CLIENT_ROLES_SUCCESS:
      return {
        ...state,
        getClientRolesLoading: false,
        roles: action.roles,
        error: ""
      };
    case manageClientUsersConstants.GET_CLIENT_ROLES_FAILURE:
      return {
        ...state,
        getClientRolesLoading: false,
        roles: [],
        error: action.error.key ?? action.error.message
      };
    case manageClientUsersConstants.ADD_CLIENT_USER_REQUEST:
      return {
        ...state,
        addClientUserLoading: true
      };
    case manageClientUsersConstants.ADD_CLIENT_USER_SUCCESS:
      return {
        ...state,
        addClientUserLoading: false,
        addedUser: true,
        error: ""
      };
    case manageClientUsersConstants.ADD_CLIENT_USER_FAILURE:
      return {
        ...state,
        addClientUserLoading: false,
        addedUser: false,
        error: action.error.key ?? action.error.message
      };
    case manageClientUsersConstants.UPDATE_CLIENT_USER_REQUEST:
      return {
        ...state,
        updateClientUserLoading: true
      };
    case manageClientUsersConstants.UPDATE_CLIENT_USER_SUCCESS:
      return {
        ...state,
        updateClientUserLoading: false,
        updatedUser: true,
        error: ""
      };
    case manageClientUsersConstants.UPDATE_CLIENT_USER_FAILURE:
      return {
        ...state,
        updateClientUserLoading: false,
        updatedUser: false,
        error: action.error.key ?? action.error.message
      };
    case manageClientUsersConstants.CLEAR_ERROR:
      return {
        ...state,
        error: ""
      };
    case manageClientUsersConstants.RESET_GOOGLE_AUTH_FEATURE:
      return {
        ...state,
        googleAuthenticationFeature: false
      };
    case manageClientUsersConstants.CLEAR_ADD_CLIENT_USER_FLAG:
      return {
        ...state,
        error: "",
        addedUser: false,
        groups: [],
        roles: []
      };
    case manageClientUsersConstants.CLEAR_UPDATE_CLIENT_USER_FLAG:
      return {
        ...state,
        error: "",
        updatedUser: false,
        groups: [],
        roles: []
      };
    case manageClientUsersConstants.RESET:
      return initialState;
    default:
      return state;
  }
}
