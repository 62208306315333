import { systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { handleErrorResponse, handleResponse } from "./";

const baseURL = systemConstants.serverURL;

export const projectListService = {
  getProjectsByUser
};

function getProjectsByUser() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`${baseURL}/api/projects/getByUser`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}
