import React from "react";

import PropTypes from "prop-types";

import "./ShapeIndicator.scss";

const ShapeIndicator = props => {
  const { shape, color, borderColor, enableBorder } = props;

  const boderColorClassName = enableBorder
    ? `shape-indicator--${shape}--border--${borderColor}`
    : "";

  return (
    <div className="shape-indicator" data-testid="test-shapeIndicator">
      {enableBorder && shape == "triangle" ? (
        <div className="shape-indicator--triangle--outer">
          <div
            className={`shape-indicator--triangle--inner shape-indicator--${shape}--${color}`}
          ></div>
        </div>
      ) : (
        <div
          className={`shape-indicator--${shape} shape-indicator--${shape}--${color} ${boderColorClassName}`}
        ></div>
      )}
    </div>
  );
};

ShapeIndicator.defaultProps = {
  borderColor: "default",
  enableBorder: false
};

ShapeIndicator.propTypes = {
  shape: PropTypes.oneOf(["square", "triangle", "round"]),
  color: PropTypes.oneOf([
    "open",
    "closed",
    "upcoming",
    "overdue",
    "responded",
    "note",
    "attachment"
  ]),
  borderColor: PropTypes.oneOf(["default", "primary"]),
  enableBorder: PropTypes.bool
};

export default ShapeIndicator;
