import React, { useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import BrandButton from "@components/atoms/Button/BrandButton";
import DataTableDropdownSelect from "@components/atoms/DataTableDropdownSelect";

import CommentsFilterItem from "./CommentsFilterItem/CommentsFilterItem";
import "./InteractiveReportFilterControls.scss";
import InteractiveReportFilterDropdown from "./InteractiveReportFilterDropdown";

const InteractiveReportFilterControls = ({
  showCommentsFilter,
  selectedCommentsOption,
  commentsFilterOptions,
  filterConditions,
  onCommentsFilterSelected,
  onTriggerOpenFilterModal,
  onResetFilters,
  columnDropdownItems,
  onChangeColumnFilter,
  t
}) => {
  const [commentOptions, setCommentOptions] = useState([]);
  const filtersAppliedText = useMemo(() => {
    if (!filterConditions?.length) {
      return null;
    }

    const visibleFiltersText = filterConditions
      .slice(0, 3)
      .map(c => c.field)
      .filter(f => f)
      .join(", ");

    if (filterConditions.length <= 3) {
      return `Filters applied: ${visibleFiltersText}`;
    }

    const remainder = filterConditions.slice(3).length;
    return `Filters applied: ${visibleFiltersText} and ${remainder} other filter(s)`;
  }, [filterConditions]);

  useEffect(() => {
    setCommentOptions([
      { name: "All", label: "-", value: undefined },
      ...(commentsFilterOptions?.map(c => {
        const label = c.requestKeys ? t(c.label) : c.label;
        return {
          ...c,
          name: label,
          label,
          value: c
        };
      }) ?? [{ name: "-", value: undefined }])
    ]);
  }, [commentsFilterOptions, t]);

  return (
    <div className="ot-interactive-report-filter-controls">
      <div className="ot-interactive-report-filter-controls__top">
        <div className="ot-interactive-report-filter-controls__settings">
          {showCommentsFilter && (
            <div className="ot-interactive-report-filter-controls__comments">
              <DataTableDropdownSelect
                label="Comments:"
                items={commentOptions}
                value={selectedCommentsOption}
                width="100%"
                itemRenderer={({ item, checked }) => {
                  return (
                    <CommentsFilterItem
                      name={item.label}
                      icons={item.icons}
                      checked={checked}
                    />
                  );
                }}
                onChange={onCommentsFilterSelected}
              />
            </div>
          )}
          {filterConditions?.length ? (
            <div
              className="ot-interactive-report-filter-controls__filters-applied"
              onClick={onTriggerOpenFilterModal}
            >
              <BrandButton
                type="text"
                label={filtersAppliedText}
                iconName="edit"
                iconSide="right"
              ></BrandButton>
            </div>
          ) : (
            <>
              <BrandButton
                type="text"
                onClick={onTriggerOpenFilterModal}
                label="Create Filter"
                iconName="add"
              ></BrandButton>
            </>
          )}
        </div>
        <InteractiveReportFilterDropdown
          items={columnDropdownItems}
          onChange={onChangeColumnFilter}
        />
        <div className="divide-bar" />
        <BrandButton
          type="text-accent"
          onClick={onResetFilters}
          label="RESET FILTERS"
        ></BrandButton>
      </div>
    </div>
  );
};

InteractiveReportFilterControls.propTypes = {
  showCommentsFilter: PropTypes.bool,
  onResetFilters: PropTypes.func.isRequired,
  onTriggerOpenFilterModal: PropTypes.func.isRequired,
  onCommentsFilterSelected: PropTypes.func.isRequired,
  filterConditions: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      operator: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  )
};

export default withTranslation()(InteractiveReportFilterControls);
