export const manageClientEntitiesConstants = {
  GET_CLIENT_ENTITIES_REQUEST: "GET_CLIENT_ENTITIES_REQUEST",
  GET_CLIENT_ENTITIES_SUCCESS: "GET_CLIENT_ENTITIES_SUCCESS",
  GET_CLIENT_ENTITIES_FAILURE: "GET_CLIENT_ENTITIES_FAILURE",

  GET_CLIENT_NON_ENTITIES_REQUEST: "GET_CLIENT_NON_ENTITIES_REQUEST",
  GET_CLIENT_NON_ENTITIES_SUCCESS: "GET_CLIENT_NON_ENTITIES_SUCCESS",
  GET_CLIENT_NON_ENTITIES_FAILURE: "GET_CLIENT_NON_ENTITIES_FAILURE",

  ADD_CLIENT_ENTITY_REQUEST: "ADD_CLIENT_ENTITY_REQUEST",
  ADD_CLIENT_ENTITY_SUCCESS: "ADD_CLIENT_ENTITY_SUCCESS",
  ADD_CLIENT_ENTITY_FAILURE: "ADD_CLIENT_ENTITY_FAILURE",

  REMOVE_CLIENT_ENTITY_REQUEST: "REMOVE_CLIENT_ENTITY_REQUEST",
  REMOVE_CLIENT_ENTITY_SUCCESS: "REMOVE_CLIENT_ENTITY_SUCCESS",
  REMOVE_CLIENT_ENTITY_FAILURE: "REMOVE_CLIENT_ENTITY_FAILURE",

  RESET_CLIENT_ENTITIES: "RESET_CLIENT_ENTITIES",
  RESET_CLIENT_NON_ENTITIES: "RESET_CLIENT_NON_ENTITIES",
  RESET_ADDED_ENTITY: "RESET_ADDED_ENTITY",
  RESET_REMOVED_ENTITY: "RESET_REMOVED_ENTITY",
  RESET_ERROR_WHILE_ADDING_CLIENT_ENTITY:
    "RESET_ERROR_WHILE_ADDING_CLIENT_ENTITY",

  CLEAR_ERROR: "CLEAR_CLEINT_ENITITIES_ERROR",
  RESET: "RESET_CLIENT_ENTITIES_MANAGEMENT"
};
