import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "@shared/constants";

import prepareHeaders from "./rtkUtilities";

export const clientsMenuService = createApi({
  reducerPath: "clientsMenuService",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders: prepareHeaders
  }),
  tagTypes: ["ClientForMenu"],
  endpoints: builder => ({
    getClientsForMenu: builder.query({
      query: () => `/api/clients?for=menu`,
      providesTags: ["ClientForMenu"]
    })
  })
});

export const { useGetClientsForMenuQuery, util: clientsMenuUtil } =
  clientsMenuService;
