import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

import { useAuthUser, useEngagementMembers } from "@shared/hooks";

import DropdownInput from "@components/atoms/DropdownInput";
import AddEngagementUserTemplate from "@components/templates/AddEngagementUserTemplate";

const AddEngagementUser = props => {
  const [engagement, setEngagement] = useState(props.engagement);
  const [memberOfOptions, setMemberOfOptions] = useState([]);
  const [memberOptions, setMemberOptions] = useState([]);
  const [added, setAdded] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    name: ""
  });
  const { user } = useAuthUser();
  const {
    engagementMembers,
    membersToAdd,
    isMemberLoading,
    membersError,
    addedMember,
    getMembers,
    addMember,
    resetAddedMember
  } = useEngagementMembers();
  const [memberOfOption, setMemberOfOption] = useState({ user: [] });
  const { onAdd } = props;

  useEffect(() => {
    setEngagement(props.engagement);
  }, [props.engagement]);

  useEffect(() => {
    setMemberOfOptions(
      Object.keys(membersToAdd)
        .filter(option => membersToAdd[option])
        .map(option => {
          const optionObject = membersToAdd[option];
          // To keep the key unique
          optionObject.id = `${optionObject.id} ${optionObject.name}`;
          return optionObject;
        })
    );
  }, [membersToAdd]);

  useEffect(() => {
    if (membersToAdd.client) {
      setMemberOfOption(membersToAdd.client);
    }
  }, [membersToAdd.client]);

  useEffect(() => {
    if (addedMember) {
      getMembers(engagement);
      resetAddedMember();
      onAdd();
    }
  }, [addedMember, engagement, onAdd, getMembers, resetAddedMember]);

  useEffect(() => {
    if (membersError && added) {
      onAdd();
    }
  }, [membersError, onAdd, added]);

  useEffect(() => {
    setMemberOptions(memberOfOption.users);
  }, [memberOfOption.users]);

  const handleMemberOfOptionSelection = option => {
    setSelectedUser({ name: "" });
    option.users =
      option.users && option.users.length
        ? option.users.sort((a, b) => a.firstname.localeCompare(b.firstname))
        : [];
    setMemberOfOption(option);
  };

  const handleMemberSelection = member => {
    setSelectedUser(member);
  };

  const handleCancel = () => {
    props.onCancel();
  };

  const handleAdd = () => {
    setAdded(true);
    if (
      !engagementMembers.some(
        engagementMember =>
          engagementMember.id === selectedUser.id &&
          engagementMember.name === selectedUser.name
      )
    ) {
      if (selectedUser.name && selectedUser.id) {
        addMember(engagement, selectedUser);
      }
    } else {
      onAdd();
    }
  };

  const renderClientDropdown = () => {
    return (
      <DropdownInput
        label={"Member of"}
        items={memberOfOptions}
        onChange={handleMemberOfOptionSelection}
        value={{ name: memberOfOption.name } || ""}
      />
    );
  };

  const renderSelectedUserDropdown = () => {
    return (
      <DropdownInput
        label={"Member"}
        items={memberOptions}
        onChange={handleMemberSelection}
        value={{ id: selectedUser.id, name: selectedUser.name }}
      />
    );
  };

  return (
    <AddEngagementUserTemplate
      user={user}
      selectedUser={selectedUser}
      isLoading={isMemberLoading}
      engagement={engagement}
      memberOptions={memberOptions}
      handleCancel={handleCancel}
      handleAdd={handleAdd}
      renderClientDropdown={renderClientDropdown}
      renderSelectedUserDropdown={renderSelectedUserDropdown}
    />
  );
};

AddEngagementUser.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  engagement: PropTypes.shape({
    id: PropTypes.number
  })
};

export default AddEngagementUser;
