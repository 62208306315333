import React, { forwardRef, useEffect, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { classNames } from "@app/helpers/componentHelpers";

import "./FloatingMenu.scss";

const FloatingMenu = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [position, setPosition] = useState({});
  const {
    align,
    menuItemClickHandler,
    parentPos,
    menuItems,
    disableFixedPositionCalculation
  } = props;
  const contentClassNames = () => {
    return classNames([
      "ot-dropdown-dot-menu-floating-menu__content",
      `ot-dropdown-dot-menu-floating-menu__content--${align}`
    ]);
  };

  useEffect(() => {
    if (ref?.current && parentPos && !disableFixedPositionCalculation) {
      const contentBox = ref.current.getElementsByClassName(
        "ot-dropdown-dot-menu-floating-menu__content"
      )[0];
      const triangleBoxStyles = window.getComputedStyle(contentBox, ":before");
      const triangleHeight = triangleBoxStyles.getPropertyValue("height");
      const contentWidth = contentBox.getBoundingClientRect().width;
      const offsetY = Number.parseInt(triangleHeight) * 0.7;
      const { top: parentTop, left: parentLeft } = parentPos;

      let offsetX = 0;
      if (align === "left") {
        offsetX = -contentWidth + Number.parseInt(triangleHeight) * 1.29;
      } else if (align === "right") {
        offsetX = -Number.parseInt(triangleHeight) * 1.29;
      }
      setPosition({
        top: parentTop + offsetY,
        left: parentLeft + offsetX
      });
    }
  }, [align, disableFixedPositionCalculation, parentPos, ref]);

  return (
    <div className="ot-dropdown-dot-menu-floating-menu" ref={ref}>
      <div className="ot-dropdown-dot-menu-floating-menu__content-wrapper">
        <div className={contentClassNames()} style={position}>
          <div className="ot-dropdown-dot-menu-floating-menu__content-list">
            {menuItems.map((item, index) => (
              <button
                data-testid={`test__menu-item-${index}`}
                key={`${item.key ?? item.name}_${index}`}
                className="ot-dropdown-dot-menu-floating-menu__menu-item"
                onClick={menuItemClickHandler(item)}
              >
                {t(item.label ?? item.name)}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

FloatingMenu.defaultProps = {
  align: "left",
  menuItems: [],
  disableFixedPositionCalculation: false
};

FloatingMenu.propTypes = {
  align: PropTypes.oneOf(["left", "right"]),
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string, // preferable to use key + label
      key: PropTypes.string,
      label: PropTypes.string
    })
  ),
  menuItemClickHandler: PropTypes.func,
  parentPos: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number
  }),
  disableFixedPositionCalculation: PropTypes.bool
};

export default FloatingMenu;
