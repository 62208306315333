import jwtDecoder from "jwt-decode";

import { systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { handleErrorResponse, handleResponse } from "./";

const baseURL = systemConstants.serverURL;

export const authService = {
  login,
  loginWithExternalAuth,
  logout,
  verify,
  forgotPassword,
  verifyResetPasswordToken,
  resetPassword,
  getQRCode,
  activateMFA,
  disableMFA,
  verifyMFAToken,
  emailMFAVerificationCode,
  getHostWithBrandDetails,
  getClientAccessToken
};

function getClientAccessToken() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`${baseURL}/api/auth/clientAccessToken`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getHostWithBrandDetails() {
  const requestOptions = {
    method: "POST",
    headers: authHeader()
  };

  return fetch(`${baseURL}/api/auth/hostBrand`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getQRCode(user) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`${baseURL}/api/users/${user.id}/mfa/qrcode`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function emailMFAVerificationCode(user, mfaObject) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(mfaObject)
  };

  return fetch(
    `${baseURL}/api/users/${user.id}/mfa/email-verification-code`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function disableMFA(user, mfaObject) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(mfaObject)
  };

  return fetch(`${baseURL}/api/users/${user.id}/mfa/disable`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function activateMFA(user, mfaObject) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(mfaObject)
  };

  return fetch(`${baseURL}/api/users/${user.id}/mfa/activate`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function verifyMFAToken(user, token) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ token })
  };

  return fetch(`${baseURL}/api/users/${user.id}/mfa/verify`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: authHeader({ beforeLogin: true }),
    body: JSON.stringify({ email, password })
  };

  return fetch(`${baseURL}/api/auth`, requestOptions)
    .then(handleResponse)
    .then(({ token }) => {
      // store jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("token", token);
      return { user: jwtDecoder(token), token };
    })
    .catch(handleErrorResponse);
}

function loginWithExternalAuth({ authObject }) {
  const requestOptions = {
    method: "POST",
    headers: authHeader({ beforeLogin: true }),
    body: JSON.stringify(authObject)
  };

  return fetch(`${baseURL}/api/auth/external-auth`, requestOptions)
    .then(handleResponse)
    .then(({ token }) => {
      // store jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("token", token);
      return { user: jwtDecoder(token), token };
    })
    .catch(handleErrorResponse);
}

function forgotPassword(email) {
  const requestOptions = {
    method: "POST",
    headers: authHeader({ beforeLogin: true }),
    body: JSON.stringify({ email })
  };

  return fetch(`${baseURL}/api/auth/forgot`, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    })
    .catch(handleErrorResponse);
}

function verifyResetPasswordToken({ id, passwordToken }) {
  const requestOptions = {
    method: "POST",
    headers: authHeader({ beforeLogin: true }),
    body: JSON.stringify({ id, passwordToken })
  };
  return fetch(`${baseURL}/api/auth/reset/verify`, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    })
    .catch(handleErrorResponse);
}

function resetPassword({ id, password, passwordToken }) {
  const requestOptions = {
    method: "POST",
    headers: authHeader({ beforeLogin: true }),
    body: JSON.stringify({ id, password, passwordToken })
  };

  return fetch(`${baseURL}/api/auth/reset`, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    })
    .catch(handleErrorResponse);
}

function logout() {
  // remove user from local storage to log user out
  localStorage.clear();
  sessionStorage.clear();
}

function verify() {
  const requestOptions = {
    method: "POST",
    headers: authHeader()
  };

  return fetch(`${baseURL}/api/auth/verify`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}
