import React from "react";

import PropTypes from "prop-types";

import { getTopPositionOfInnerContentWithinBodyPx } from "@app/helpers/componentHelpers";

import "./BoxTemplate.scss";

const BoxTemplate = props => {
  const {
    title,
    subtext,
    action,
    boxClassName,
    size,
    dataTestId,
    stickyTitle
  } = props;
  const colorSchemeClass = () => `box-template--${props.colorScheme}`;
  const sizeClass = () => {
    switch (size) {
      case "medium":
        return "box-template--medium";
      case "large":
        return "box-template--large";
      default:
        return "";
    }
  };

  return (
    <div
      className={`box-template ${colorSchemeClass()} ${sizeClass()} ${
        boxClassName ? boxClassName : ""
      }`}
      data-testid={dataTestId ?? null}
    >
      {(title || subtext || action) && (
        <div
          className={`box-template__header${stickyTitle ? "--sticky" : ""}`}
          style={
            stickyTitle
              ? {
                  top: `${getTopPositionOfInnerContentWithinBodyPx() - 1}px`
                }
              : {}
          }
        >
          <div className="box-template__heading">
            <h4 className="box-template__title">{title}</h4>
            <div className="box-template__subtext">{subtext}</div>
          </div>
          <div className="box-template__action">{action}</div>
        </div>
      )}
      <div className={`box-template__content ${boxClassName}__content`}>
        {props.children}
      </div>
    </div>
  );
};

BoxTemplate.defaultProps = {
  colorScheme: "white"
};

BoxTemplate.propTypes = {
  boxClassName: PropTypes.string,
  title: PropTypes.string,
  subtext: PropTypes.string,
  action: PropTypes.node,
  children: PropTypes.node,
  colorScheme: PropTypes.oneOf(["white", "transparent"]),
  size: PropTypes.oneOf(["large", "medium"]),
  dataTestId: PropTypes.string,
  stickyTitle: PropTypes.bool
};

export default BoxTemplate;
