import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { routeConstants } from "@constants";

import { addOneYear } from "@shared/helpers/dateHelper";
import { milestoneUtilities } from "@shared/helpers/milestoneUtilities";
import { utilities } from "@shared/helpers/utilities";
import { useCopyProjectMutation } from "@shared/services/projectService";

import { parseEntities, validateEntities } from "@app/helpers/entity";
import { getErrorMessage } from "@app/helpers/error";
import { useGoBack } from "@app/hooks";

import Form from "@components/atoms/Form";
import SelectProjectYearField from "@components/molecules/SelectProjectYearField";
import UploadProjectEntitiesFormField from "@components/molecules/UploadProjectEntitiesFormField";
import PageFormTemplate from "@components/templates/PageFormTemplate";

const CopyProjectForm = ({ project, engagement }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goBack = useGoBack();
  const currentYear = new Date().getFullYear();
  const { startDate, plannedCompletionDate, configuration } = project ?? {};
  const entitiesEnabled = configuration?.entities?.enabled ?? false;
  const projectYearEnabled =
    configuration?.project?.yearField?.enabled ?? false;
  const [copyProject, { data: copiedProject, error, isSuccess }] =
    useCopyProjectMutation();
  const [copiedStartDate, setCopiedStartDate] = useState();
  const [copiedEndDate, setCopiedEndDate] = useState();

  const getCopyItems = () => {
    const copyItems = [
      {
        value: "milestones",
        name: t("stringFormat.capitalize", {
          key: "common:milestone_other"
        })
      },
      {
        value: "tags",
        name: t("stringFormat.capitalize", {
          key: "common:tags_other"
        })
      },
      {
        value: "users",
        name: t("stringFormat.capitalize", {
          key: "common:users"
        })
      }
    ];
    if (entitiesEnabled) {
      copyItems.push({
        value: "entities",
        name: t("stringFormat.capitalize", {
          key: "common:entities_other"
        })
      });
    }
    return copyItems;
  };

  useEffect(() => {
    if (isSuccess && copiedProject?.id) {
      navigate(`${routeConstants.projects}/${copiedProject?.id}`);
    }
  }, [copiedProject?.id, isSuccess, navigate]);

  const handleSubmit = e => {
    const {
      name,
      entities,
      year,
      plannedCompletionDate,
      startDate,
      copyEngagementUsers,
      copyItems
    } = e;
    const newProject = {
      name,
      plannedCompletionDate: utilities.safeUtcDate(plannedCompletionDate),
      startDate: utilities.safeUtcDate(startDate),
      engagementId: project.engagementId,
      year: year?.value
    };
    if (entities) {
      const parsedEntities = parseEntities(entities, t);
      newProject.entities = parsedEntities;
    }

    const formattedCopyFields = copyItems.reduce((acc, { value }) => {
      acc[value] = true;
      return acc;
    }, {});
    const payload = {
      project: newProject,
      copyFields: formattedCopyFields,
      prevProjectId: project.id,
      fillUsers: copyEngagementUsers
    };
    copyProject({ payload });
  };

  const buildComparerForCopyItems = accessor => {
    return fieldValues => {
      if (!fieldValues) {
        return false;
      }
      return !fieldValues.some(v => v.value === accessor);
    };
  };

  const renderStartDate = () => {
    if (!startDate) {
      return;
    }
    const copyDate = addOneYear(startDate);
    return (
      <Form.DateField
        name={"startDate"}
        required={true}
        label={milestoneUtilities.renderStartLabel(configuration, t)}
        defaultValue={copyDate}
        maxDate={copiedEndDate}
        onChangeDate={setCopiedStartDate}
      />
    );
  };

  const renderEndDate = () => {
    if (!plannedCompletionDate) {
      return;
    }
    const copyDate = addOneYear(plannedCompletionDate);
    return (
      <Form.DateField
        name={"plannedCompletionDate"}
        required={true}
        label={milestoneUtilities.renderEndLabel(configuration, t)}
        defaultValue={copyDate}
        minDate={
          copiedStartDate ? new Date(copiedStartDate) : addOneYear(startDate)
        }
        onChangeDate={setCopiedEndDate}
      />
    );
  };

  const yupSchema = useMemo(() => {
    const yupShape = {
      name: yup.string().required(t("common:ui.projects.name.error")),
      startDate: yup
        .string()
        .nullable()
        .required(t("common:ui.projects.startDate.error")),
      plannedCompletionDate: yup
        .string()
        .nullable()
        .required(t("common:ui.projects.endDate.error.defined"))
    };

    if (entitiesEnabled) {
      yup.addMethod(yup.string, "entitiesValidation", function () {
        return this.test(`test-card-type`, "", function (value) {
          const validation = validateEntities(parseEntities(value, t), t);
          const { path, createError } = this;
          if (validation?.error) {
            return createError({ path, message: validation.error.message });
          }
          return true;
        });
      });
      yupShape.entities = yup.string().when("copyItems", (data, field) => {
        if (data?.[0]?.find(d => d.value === "entities")) {
          return field;
        }
        return field.entitiesValidation();
      });
    }

    if (projectYearEnabled) {
      yupShape.year = yup
        .object()
        .shape({
          name: yup.string().required(),
          value: yup.string().required()
        })
        .required(t("common:ui.projects.year.error"));
    }

    return yup.object().shape(yupShape);
  }, [entitiesEnabled, projectYearEnabled, t]);

  return (
    <PageFormTemplate errorMessage={getErrorMessage(error, t)}>
      <Form
        handleSubmit={handleSubmit}
        handleCancel={goBack}
        submitLabel={t("common:ui.project.title_copy")}
        cancelLabel={t("common:ui.forms.cancel.label")}
        yupSchema={yupSchema}
      >
        <Form.TextField
          required={true}
          label={t("common:ui.projects.name.label")}
          name={"name"}
        ></Form.TextField>
        {projectYearEnabled && (
          <SelectProjectYearField
            name="year"
            label={t("common:ui.projects.year.label")}
            required={true}
            defaultValue={project?.year ?? currentYear}
          />
        )}
        {renderStartDate()}
        {renderEndDate()}
        {configuration !== undefined && (
          <Form.CheckBoxGroup
            name="copyItems"
            label={t("common:ui.project.copyFields.copyItems")}
            items={getCopyItems()}
            defaultValue={getCopyItems()}
          />
        )}
        <Form.ConditionalField
          fieldName={"copyItems"}
          valueComparer={buildComparerForCopyItems("entities")}
          resetFieldAccessor={"entities"}
        >
          {entitiesEnabled && <UploadProjectEntitiesFormField />}
        </Form.ConditionalField>
        <Form.ConditionalField
          fieldName={"copyItems"}
          valueComparer={buildComparerForCopyItems("users")}
          resetFieldAccessor={"copyEngagementUsers"}
        >
          <Form.CheckListItem
            name="copyEngagementUsers"
            label={t("common:ui.projects.copyUsers")}
            defaultValue={true}
            shouldUnregister={true}
            labelPosition="left"
          />
        </Form.ConditionalField>
      </Form>
    </PageFormTemplate>
  );
};

export default CopyProjectForm;
