import moment from "moment/moment";

import * as websheetStyling from "@shared/helpers/websheet/styling";
import * as websheetRules from "@shared/helpers/websheet/websheetRules";
import websheetUtilities from "@shared/helpers/websheetUtilities";

const formatHelper = (date, format) =>
  date?.[date?.length - 1] === "Z" ? moment(date).format(format) : date;

const addAutocompleteToCell = (
  cell,
  { dataValidation, sourceMemoization, sheetData }
) => {
  if (!dataValidation) {
    return cell;
  }

  const sourceFormula = dataValidation.formulae?.[0];
  let source = sourceMemoization?.[sourceFormula];
  if (!source && sourceMemoization) {
    source = websheetUtilities.getSource(sourceFormula, sheetData);
    sourceMemoization[sourceFormula] = source;
  }
  if (source.length) {
    cell.type = "autocomplete";
    cell.source = source;
    cell.strict = true;
  }

  return cell;
};

const getCellMetadataForNumeric = ({
  cellFormatPattern,
  shouldDisplayComments,
  t
}) => {
  const formatNumeric = websheetUtilities.formatNumeric(cellFormatPattern);
  if (!formatNumeric) {
    return null;
  }

  const metadata = {};
  metadata.type = "numeric";
  metadata.numericFormat = formatNumeric;

  const validationMessage = t(
    "requests:requests.websheet.cell.validation.numberRequired"
  );

  if (shouldDisplayComments) {
    metadata.comment = {
      value: validationMessage
    };
  }

  metadata.format = formatNumeric;
  metadata.messages = validationMessage;

  return metadata;
};

const normaliseStyleFormats = (styleFormats, formatsLookup) => {
  const formats = styleFormats?.reduce((acc, styleFormat) => {
    return {
      ...acc,
      ...formatsLookup[styleFormat]
    };
  }, {});

  return formats ?? {};
};

export const applyConditionalFormatting = (
  rowIdx,
  colIdx,
  cellMeta,
  value,
  TD
) => {
  if (!cellMeta.conditionals?.length) {
    return null;
  }

  // Rules are tested in priority order
  const orderedRules = cellMeta.conditionals
    .reduce((acc, conditional) => {
      return [
        ...acc,
        ...conditional.rules.map(rule => ({
          ...rule,
          ref: conditional.ref
        }))
      ];
    }, [])
    .sort((a, b) => a.priority - b.priority);

  // Find the first rule that matches the cell value
  const matchingRule = orderedRules.find(rule =>
    websheetRules.isRuleConditionSatisfied({
      ref: rule.ref,
      rule,
      cellValue: value,
      cellMeta,
      rowIdx,
      colIdx
    })
  );

  if (matchingRule) {
    const { style } = matchingRule;
    const { fill, font, border } = style ?? {};
    fill && websheetStyling.formatFill(fill, TD);
    font && websheetStyling.formatFont(font, TD);
    border && websheetStyling.formatBorder(border, TD);
  }
};

/**
 *
 * @param {Object} param
 * @param {Boolean} param.isHostUser
 * @param {Array} param.formats
 * @param {Array} param.sheetData
 * @param {Boolean} param.isReadOnly
 * @param {Object} param.currentSheet
 * @param {Array} [param.dataValidations]
 * @param {Object} [param.sources]
 * @param {any} param.t
 * @param {any} [param.additionalClassName]
 * @param {Array} [param.invalidCells]
 * @param {Object} [param.sourceRef]
 * @param {Number} [param.rowIdx]
 * @param {Number} [param.colIdx]
 * @param {Number} [param.rowOffset]
 * @param {Object} [param.formatsLookup]
 * @param {Object} [param.conditionalFormattings]
 * @param {Object} [param.hyperFormulaRef]
 * @returns
 */
export const webSheetCell = ({
  isHostUser,
  formats,
  sheetData,
  isReadOnly,
  currentSheet,
  dataValidations,
  t,
  invalidCells,
  additionalClassName,
  sourceRef,
  rowIdx,
  colIdx,
  rowOffset,
  formatsLookup,
  conditionalFormattings,
  hyperFormulaRef
}) => {
  let result = websheetUtilities.getResult(rowIdx, colIdx);
  result.hfSheetId = hyperFormulaRef?.current?.getSheetId(currentSheet.sheet);

  const getFormatForCell = (r, c) => {
    return normaliseStyleFormats(
      formats?.[r]?.[c]?.styleFormats,
      formatsLookup
    );
  };
  const format = getFormatForCell(rowIdx, colIdx);

  const pattern = format?.numFmt;
  const dataType = format?.type;
  const shouldDisplayComments =
    sheetData[rowIdx]?.[colIdx] &&
    (dataType === "whole" || dataType === "decimal" || dataType === "date");
  result.className += additionalClassName?.(rowIdx, colIdx) ?? "";

  result.readOnly = !(
    !isReadOnly &&
    (isHostUser ||
      !currentSheet.sheetProtection?.sheet ||
      format?.protection?.locked === false)
  );

  const metadataForNumeric = getCellMetadataForNumeric({
    cellFormatPattern: pattern,
    shouldDisplayComments,
    t
  });
  if (metadataForNumeric) {
    Object.assign(result, metadataForNumeric);
  }

  const horizontal = format?.alignment?.horizontal;
  const vertical = format?.alignment?.vertical;
  const wrapText = format?.alignment?.wrapText;
  result.className += wrapText ? " wrapText" : " htNoWrap";
  result.className += websheetUtilities.getHorizontalAlignment(horizontal);
  result.className += websheetUtilities.getVerticalAlignment(vertical);
  let isValidCell = true;
  if (invalidCells) {
    if (invalidCells.some(cell => cell === `${rowIdx}-${colIdx}`)) {
      result.className += " htInvalid";
      isValidCell = false;
    } else {
      result.className = result.className.replace(/\bhtInvalid\b/g, "");
    }
  }

  const formatDate = websheetUtilities.formatDate(pattern);
  if (formatDate) {
    if (isValidCell) {
      result.type = "date";
    }
    result.allowInvalid = false;
    result.dateFormat = formatDate; //Friday, 21 January 2022
    result.comments = true;
    if (shouldDisplayComments) {
      result.comment = {
        value: t("requests:requests.websheet.cell.validation.comment", {
          comment: formatDate,
          interpolation: { escapeValue: false }
        })
      };
    }
    if (sheetData[rowIdx] && isValidCell) {
      sheetData[rowIdx][colIdx] = formatHelper(
        sheetData[rowIdx][colIdx],
        formatDate
      );
      result.format = formatDate;
    }
  }

  const refCell = websheetUtilities.getLastCellForMerge({
    merges: currentSheet.merges,
    row: rowIdx,
    col: colIdx,
    rowOffset
  });

  result.font = format?.font;
  result.fill = format?.fill;
  result.hyperlink = format?.hyperlink;
  result.pattern = pattern;
  result.border = format?.border;

  const refBottom = getFormatForCell(refCell.row, refCell.col)?.border?.bottom;
  const refRight = getFormatForCell(refCell.row, refCell.col)?.border?.right;

  const borderTopFromBottomCell = getFormatForCell(rowIdx + 1, colIdx)?.border
    ?.top;

  const bottomBorderStyle = (
    result.border?.bottom ??
    borderTopFromBottomCell ??
    refBottom
  )?.style;

  if (bottomBorderStyle) {
    result.className += ` border-bottom border-bottom-${bottomBorderStyle}`;
  }

  const borderLeftFromRightCell = getFormatForCell(rowIdx, colIdx + 1)?.border
    ?.left;
  const rightBorderStyle = (
    result.border?.right ??
    borderLeftFromRightCell ??
    refRight
  )?.style;

  if (rightBorderStyle) {
    result.className += ` border-right border-right-${rightBorderStyle}`;
  }

  if (dataValidations?.[rowIdx]?.[colIdx]) {
    addAutocompleteToCell(result, {
      dataValidation: dataValidations?.[rowIdx]?.[colIdx],
      sourceMemoization: sourceRef,
      sheetData
    });
  }

  // list of conditionals that could apply to this cell
  if (hyperFormulaRef?.current) {
    result.conditionals = conditionalFormattings?.filter(cf => {
      const rowCol = {
        row: rowIdx,
        col: colIdx
      };

      const isCovered = websheetRules.isCellCoveredByRange(
        rowCol,
        cf.ref,
        hyperFormulaRef,
        result.hfSheetId
      );
      return isCovered;
    });
    result.hyperFormulaRef = hyperFormulaRef;
  }

  return result;
};
