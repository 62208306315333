import { useEffect, useRef, useState } from "react";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { manageProjectQueriesActions } from "@shared/actions";
import { systemConstants } from "@shared/constants";
import usePubSub from "@shared/hooks/usePubSub";

const queryStatus = systemConstants.project.queries.status;

export function useProjectQueries(project, queryTypesRequired) {
  const dispatch = useDispatch();
  const manageProjectQueries = useSelector(state => state.manageProjectQueries);
  const [projectQueries] = useState(manageProjectQueries);
  const [queries, setQueries] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [error, setError] = useState();
  const didMount = useRef(false);
  const refreshQueriesState = usePubSub();

  useEffect(() => {
    if (!didMount.current) {
      refreshQueriesState.subscribe(
        systemConstants.project.events.projects.refreshProjectQueries
      );
      didMount.current = true;
    }
  }, [refreshQueriesState]);

  useEffect(() => {
    return () => {
      dispatch(manageProjectQueriesActions.reset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!project || refreshQueriesState.value?.projectId !== project.id) {
      return;
    }
    if (
      refreshQueriesState.value?.query &&
      refreshQueriesState.value?.queries?.length
    ) {
      return;
    }

    const incomingQueries = [];
    if (refreshQueriesState.value?.query) {
      incomingQueries.push(refreshQueriesState.value?.query);
    } else if (refreshQueriesState.value?.queries) {
      incomingQueries.push(...refreshQueriesState.value.queries);
    }

    dispatch(
      manageProjectQueriesActions.updateProjectQueries({
        queries,
        incomingQueries,
        updateType: refreshQueriesState.value.message
      })
    );
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, project, refreshQueriesState.value]); //do not add queries into the dependency

  useEffect(() => {
    if (project) {
      dispatch(manageProjectQueriesActions.getProjectQueries(project));
    }
    return () => {
      if (project?.queryPage) {
        project.queryPage = null;
      }
    };
  }, [dispatch, project]);

  useEffect(() => {
    if (!manageProjectQueries.queries) {
      return;
    }
    const filteredQueries = structuredClone(
      manageProjectQueries.queries
    ).filter(query => queryTypesRequired.includes(query.queryType));

    // order by non-closed first, then due-date asc (OEI-82)
    const sortedQueries = filteredQueries.sort((a, b) => {
      if (a.status !== queryStatus.closed && b.status === queryStatus.closed) {
        return -1;
      }
      if (b.status !== queryStatus.closed && a.status === queryStatus.closed) {
        return 1;
      }
      if (a.requiredBy && !b.requiredBy) {
        return -1;
      } else if (b.requiredBy && !a.requiredBy) {
        return 1;
      }
      if (a.requiredBy && b.requiredBy) {
        if (moment(a.requiredBy).isBefore(moment(b.requiredBy))) {
          return -1;
        } else if (moment(b.requiredBy).isBefore(moment(a.requiredBy))) {
          return 1;
        }
      }
      return 0;
    });
    setQueries(sortedQueries);
  }, [manageProjectQueries.queries, queryTypesRequired]);

  useEffect(() => {
    setIsLoading(manageProjectQueries.loading);
  }, [manageProjectQueries.loading]);

  useEffect(() => {
    setError(manageProjectQueries.error);
  }, [manageProjectQueries.error]);

  function fetchQueries(project) {
    dispatch(manageProjectQueriesActions.getProjectQueries(project));
  }

  return { queries, projectQueries, isLoading, error, fetchQueries };
}
