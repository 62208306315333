import React, { useContext, useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import _DatePicker from "react-date-picker";

import { getDateFormatString } from "@shared/helpers/dateHelper";
import { useLocaleDate } from "@shared/hooks/useLocaleDate";

import UIConfigContext from "@app/helpers/UIConfigContext";
import { classNames } from "@app/helpers/componentHelpers";
import { formatDayOfWeek } from "@app/helpers/date";

import "./DatePicker.scss";
import "./OTDatePicker.scss";
import "./TDSDatePicker.scss";

const DatePicker = React.forwardRef((props, fwdRef) => {
  const uiConfig = useContext(UIConfigContext);
  const [error, setError] = useState("");
  const [focus, setFocus] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const { locale } = useLocaleDate();
  const debounceDateValue = useRef(null);
  const debounceTimer = useRef(null);

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  useEffect(() => {
    if (props.disabled && isCalendarOpen) {
      setIsCalendarOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.disabled]);

  const clearDebouncedOnChangeTimer = () => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
  };

  const ONCHANGE_DEBOUNCE_DELAY_MS = 500;
  const queueDebouncedOnChange = (dateValue, isImmediate = true) => {
    clearDebouncedOnChangeTimer();
    if (isImmediate) {
      props.onChange(dateValue);
    } else {
      debounceTimer.current = setTimeout(() => {
        props.onChange(dateValue);
      }, ONCHANGE_DEBOUNCE_DELAY_MS);
    }
  };

  const getErrorClass = () => {
    return error?.length > 0 ? "error" : "";
  };

  const getError = () => {
    if (error && error.length > 0) {
      return (
        <div className="ot-date-picker__errmsg">
          <i className="ot-date-picker__errmsg-icon material-icons">error</i>
          {error}
        </div>
      );
    }
    return <></>;
  };

  const getCalendarIcon = () => {
    return <i className="material-icons">date_range</i>;
  };
  const getFocusClass = () => {
    return props.value || focus || isCalendarOpen || props.forceLabelFocus
      ? "focus"
      : "";
  };
  const getRequiredClass = () => {
    return props.required ? "asterisk" : "";
  };

  const getDisabledClass = () => (props.disabled ? "disabled" : "");

  const getNextLabel = () => (
    <i className="material-icons ot-date-picker__next-label">navigate_next</i>
  );
  const getPrevLabel = () => (
    <i className="material-icons ot-date-picker__prev-label">navigate_before</i>
  );

  const getHideInputClass = () =>
    !focus && !isCalendarOpen && !props.value
      ? "react-date-picker--hide-input"
      : "";

  const getPlaceholderClass = () =>
    props.showPlaceholder ? "react-date-picker__placeholder--show" : "";

  const clearIcon = () => {
    return <i className="material-icons">close</i>;
  };

  const getThemeClass = () => {
    return `ot-date-picker--theme-${uiConfig?.theme?.designSystem ?? "tds"}`;
  };

  const onChangeHandler = date => {
    if (date && props.useDebouncedOnChange) {
      debounceDateValue.current = date;
      return;
    }
    debounceDateValue.current = date;
    props.onChange(date);
  };

  const onBlurHandler = ({ isImmediate }) => {
    if (debounceDateValue.current && props.useDebouncedOnChange) {
      queueDebouncedOnChange(debounceDateValue.current, isImmediate);
    }
  };

  return (
    <div
      className={classNames([
        "ot-date-picker",
        getErrorClass(),
        getDisabledClass(),
        getThemeClass()
      ])}
      onClick={() => {
        if (props.disabled) return;
        if (!isCalendarOpen) setIsCalendarOpen(true);
      }}
      role="datepicker"
    >
      {props.label && (
        <label
          className={classNames([
            "ot-date-picker__label",
            getFocusClass(),
            getRequiredClass()
          ])}
        >
          {props.label}
        </label>
      )}
      <_DatePicker
        value={props.value}
        inputRef={fwdRef}
        onChange={onChangeHandler}
        format={getDateFormatString(locale)}
        formatShortWeekday={(_, date) => formatDayOfWeek(date)}
        isOpen={isCalendarOpen}
        clearIcon={props.value ? clearIcon() : null}
        dayPlaceholder={"DD"}
        monthPlaceholder={"MM"}
        yearPlaceholder={"YYYY"}
        maxDetail={"month"}
        minDate={props.minDate}
        maxDate={props.maxDate}
        nextLabel={getNextLabel()}
        prevLabel={getPrevLabel()}
        next2Label={null}
        prev2Label={null}
        disabled={props.disabled}
        calendarIcon={getCalendarIcon()}
        className={[getHideInputClass(), getPlaceholderClass()]}
        onCalendarOpen={() => {
          setIsCalendarOpen(true);
        }}
        onCalendarClose={() => {
          setIsCalendarOpen(false);
          onBlurHandler({ isImmediate: true });
        }}
        onFocus={event => {
          setFocus(true);
          props.onFocus?.(event);
          clearDebouncedOnChangeTimer();
        }}
        onBlur={event => {
          setFocus(false);
          props.onBlur?.(event);
          onBlurHandler({ isImmediate: false });
        }}
      />
      {getError()}
    </div>
  );
});

DatePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.string,
  showPlaceholder: PropTypes.bool,
  useDebouncedOnChange: PropTypes.bool
};
DatePicker.displayName = "DatePicker";

export default DatePicker;
