import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import useOnClickOutside from "use-onclickoutside";

import ToCard from "../toCard/ToCard";
import "./dropdownWithInput.scss";

const DropdownWithInput = React.forwardRef((props, ref) => {
  const multiValues = props.multiValues;
  const [list, setList] = useState([]);
  const [error, setError] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const dropdownRef = useRef(null);
  const [cursor, setCursor] = useState(null);
  const [lastSelection, setLastSelection] = useState(null);
  const [resetState, setResetState] = useState(false);
  useEffect(() => {
    setList(props.list);
  }, [props.list]);

  useEffect(() => {
    if (props.reset) {
      setList(props.list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reset]);

  useEffect(() => {
    if (props.resetLastSelection != resetState) {
      setLastSelection(null);
      setResetState(!resetState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.resetLastSelection]);

  useOnClickOutside(dropdownRef, () => {
    if (!props.disabled) {
      setShowSuggestions(false);
      if (
        lastSelection &&
        (props.value !== "" || props.defaultSelection === null)
      ) {
        if (lastSelection && lastSelection.name != props.value) {
          props.handleSelection(lastSelection);
        }
      }
      if (
        lastSelection &&
        props.defaultSelection &&
        props.defaultSelection !== null &&
        props.value === ""
      ) {
        setLastSelection(list[props.defaultSelection]);
        props.handleSelection(list[props.defaultSelection]);
      }
    }
  });

  const handleSelection = (event, item) => {
    event.stopPropagation();
    setLastSelection(item);
    props.handleSelection(item);
    if (!props.multiSelect) {
      setShowSuggestions(false);
      if (multiValues) {
        setList(props.list);
      } else {
        setList([item]);
      }
    }
  };

  const handleKeyDown = event => {
    if (event.key === "ArrowDown") {
      if (cursor === null && list.length > 0) {
        setCursor(0);
        setShowSuggestions(true);
      } else if (cursor + 1 < list.length) {
        setCursor(cursor + 1);
      }
    }
    if (event.key === "ArrowUp" && cursor !== null) {
      if (cursor === 0) {
        setCursor(null);
        setShowSuggestions(false);
      } else {
        setCursor(cursor - 1);
      }
    }
    if (
      (event.key === " " || event.key === "Enter") &&
      cursor !== null &&
      cursor < list.length
    ) {
      handleSelection(event, list[cursor]);
    }
  };

  const handleClick = () => {
    setShowSuggestions(true);
  };

  const handleClear = event => {
    const defaultItem = props.list[0];
    handleSelection(event, defaultItem);
    handleChange({ target: { value: "" } });
  };

  const handleBlur = event => {
    event.stopPropagation();
    if (event.relatedTarget) {
      setShowSuggestions(false);
    }
  };

  const handleChange = event => {
    props.handleChange(event.target.value);
    if (event.target.value) {
      const newList = props.list.filter(item =>
        item.name.toUpperCase().includes(event.target.value.toUpperCase())
      );
      if (!newList.length) {
        setError(true);
      } else {
        setError(false);
      }
      setList(newList);
    } else {
      setError(false);
      setList(props.list);
    }
  };

  const handleFocus = event => {
    event.target.select();
  };

  const getOptions = (item, index) => {
    return (
      <div
        key={
          !!props?.listItemKeyFunction
            ? props.listItemKeyFunction(item)
            : item?.id
        }
        className={
          "dropdown-with-input-container__list" +
          (cursor === index
            ? " dropdown-with-input-container__list-active"
            : "")
        }
        onClick={event => handleSelection(event, item)}
      >
        <span className="dropdown-with-input-container__list--text">
          {item.name}
        </span>
      </div>
    );
  };

  const renderMultiSelection = () => {
    return (
      props.multiSelect &&
      props.values &&
      props.values.map((item, index) => (
        <div
          key={`${index}-${item}`}
          className="dropdown-with-input-container__multiSelectionItem"
        >
          <ToCard user={item} onRemove={props.handleRemoveFromMultiSelection} />
        </div>
      ))
    );
  };

  return (
    <div
      className="dropdown-with-input-container"
      ref={dropdownRef}
      onClick={props.disabled ? null : handleClick}
      onBlur={props.disabled ? null : handleBlur}
    >
      <div
        className={`form-control dropdown-with-input-container__search${
          props.error || error
            ? " dropdown-with-input-container__search-error"
            : ""
        }${
          showSuggestions
            ? " dropdown-with-input-container__search-focused"
            : ""
        }`}
      >
        <div className="dropdown-with-input-container__inputAndMultiSelections">
          {renderMultiSelection()}
          <input
            type={props.type}
            className={
              "form-control" +
              (props.customClass ? ` ${props.customClass}` : "")
            }
            value={props.value}
            autoFocus={false}
            onFocus={handleFocus}
            onChange={handleChange}
            maxLength={props.maxLength}
            placeholder={
              !props.multiSelect || !props.values || !props.values.length
                ? props.placeholder
                : ""
            }
            disabled={props.disabled}
            onKeyDown={handleKeyDown}
            ref={ref}
          />
        </div>
        {props.value && (
          <i
            className="material-icons dropdown-with-input-container__icon-clear"
            onClick={event => handleClear(event)}
          >
            {"clear"}
          </i>
        )}
        <i className="material-icons dropdown-with-input-container__icon">
          {!showSuggestions ? "keyboard_arrow_down" : "keyboard_arrow_up"}
        </i>
      </div>

      {!props.disabled && (
        <div
          className={
            "dropdown-with-input-container__suggestion" +
            (!showSuggestions
              ? " dropdown-with-input-container__suggestion--hide"
              : "")
          }
        >
          {list.map(getOptions)}
        </div>
      )}
    </div>
  );
});
DropdownWithInput.propTypes = {
  type: PropTypes.string,
  error: PropTypes.bool,
  handleChange: PropTypes.func,
  handleSelection: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })
  ).isRequired,
  placeholder: PropTypes.string,
  multiValues: PropTypes.bool,
  multiSelect: PropTypes.bool,
  value: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ),
  listItemKeyFunction: PropTypes.func,
  maxLength: PropTypes.number,
  reset: PropTypes.bool
};

export default DropdownWithInput;
