import { useCallback, useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageProjectActions } from "@shared/actions";

export function useGetProjectById() {
  const dispatch = useDispatch();
  const manageProject = useSelector(state => state.manageProject);
  const [isFetchingProject, setIsFetchingProject] = useState(false);
  const [errorFetchingProject, setErrorFetchingProject] = useState();
  const didFetch = useRef(false);

  useEffect(() => {
    setIsFetchingProject(manageProject.projectLoading);
  }, [manageProject.projectLoading]);

  useEffect(() => {
    setErrorFetchingProject(manageProject.error);
  }, [manageProject.error]);

  const fetchProjectById = useCallback(
    projectId => {
      if (!didFetch.current || projectId != manageProject.currentProject?.id) {
        dispatch(manageProjectActions.getProjectById(projectId));
        didFetch.current = true;
      }
    },
    [dispatch, manageProject.currentProject?.id]
  );

  const reloadProjectById = useCallback(
    projectId => {
      if (!manageProject.projectLoading) {
        didFetch.current = false;
        fetchProjectById(projectId);
      }
    },
    [fetchProjectById, manageProject.projectLoading]
  );

  const clearProjectError = useCallback(
    () => dispatch(manageProjectActions.clearError()),
    [dispatch]
  );

  return {
    fetchProjectById,
    reloadProjectById,
    isFetchingProject,
    clearProjectError,
    errorFetchingProject
  };
}
