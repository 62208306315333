import { systemConstants } from "@shared/constants";

import { menuConstants, routeConstants } from "@app/constants";

const menuItemTypes = menuConstants.menuItemTypes;
const sideNavContext = menuConstants.sideNavContext;
const engagementManager = systemConstants.roles.engagementManager;
const teamMember = systemConstants.roles.teamMember;
const clientAdmin = systemConstants.roles.clientAdmin;
const clientUser = systemConstants.roles.clientUser;
const allUsers = [engagementManager, teamMember, clientAdmin, clientUser];

const isEngagementSubPages = () => {
  const pathname = window.location.pathname;
  return Object.values(routeConstants.engagement).some(
    engagementPath => engagementPath === pathname
  );
};

const isManageClientsSubPage = () => {
  const pathname = window.location.pathname;
  return ["/admin/clients", "manage-clients", "admin/add-client"].some(path =>
    pathname.includes(path)
  );
};

const isManageUsersSubPage = () => {
  const pathname = window.location.pathname;
  return ["/admin/manage-users", "/admin/add-user", "/admin/users"].some(path =>
    pathname.includes(path)
  );
};

const safeLinkToInteractiveReportPages = (projectId, pageId) =>
  projectId && pageId ? `/projects/${projectId}/pages/${pageId}` : "";

const subSectionIcon = {
  name: "stop",
  fillStyle: "filled",
  designStyle: "material-icons",
  colorStyle: "sideNav-IR",
  size: "normal"
};

const mapIRMenus = project => (menu, id) => ({
  name: menu.name,
  id: `IR-${id}`,
  type:
    menu.pages?.length === 1
      ? menuItemTypes.NAV_LINK
      : menuItemTypes.NAV_SUBMENU,
  permission: allUsers,
  link:
    menu.pages?.length === 1
      ? safeLinkToInteractiveReportPages(project?.id, menu.pages[0]?.id)
      : "",
  items:
    menu.pages?.length > 1
      ? menu.pages?.map(page => ({
          name: page?.pageName,
          link: safeLinkToInteractiveReportPages(project?.id, page?.id),
          isActive:
            safeLinkToInteractiveReportPages(project?.id, page?.id) ===
            window.location.pathname,
          context: sideNavContext.PROJECT,
          state: { project },
          icon: {
            name: "stop",
            fillStyle: "filled",
            designStyle: "material-icons",
            colorStyle: "sideNav-IR",
            size: "small"
          }
        }))
      : [],
  state: { project },
  context: sideNavContext.PROJECT,
  icon: subSectionIcon
});

export const getRelevantItems = (items, user, currentSideNavContext = null) => {
  return (
    items?.filter(menuItem => {
      if (
        menuItem.permission &&
        !menuItem.permission.includes(user.role.name)
      ) {
        return false;
      }
      return (
        !menuItem.context || menuItem.context.includes(currentSideNavContext)
      );
    }) ?? []
  ).map(i => {
    if (typeof i.isActive === "undefined") {
      i.isActive = isMenuActive(i);
    }
    return i;
  });
};

const sideNavContextMapping = {
  [sideNavContext.PROJECT]: [
    routeConstants.project,
    routeConstants.request,
    routeConstants.projects,
    routeConstants.uploadInteractiveReport,
    routeConstants.aiReporting
  ],
  [sideNavContext.ALL_PROJECTS]: [
    routeConstants.clientDashboard,
    routeConstants.clientUserLandingPage
  ],
  // Client context is used when we want to display client name in the side nav
  // but the side nav is not related to any specific project
  [sideNavContext.CLIENT]: [
    routeConstants.permanentFiles,
    routeConstants.engagements,
    "/clients"
  ],
  [sideNavContext.ADMIN]: [`/admin`]
};

const getCurrentSideNavContext = currentPath => {
  let context;
  Object.entries(sideNavContextMapping).forEach(([key, routes]) => {
    routes.forEach(route => {
      if (
        (typeof route === "string" && currentPath?.includes(route)) ||
        (typeof route === "object" &&
          Object.values(route)?.some(requestPath =>
            currentPath?.includes(requestPath)
          ))
      ) {
        context = key;
      }
    });
  });
  return context;
};

const isMenuActive = ({ link }) => window.location.pathname === link;
const getClientMenuItems = ({ user, clientId, i18nText }) => {
  if (!user.isLoggedIn || !clientId) {
    return [];
  }
  const clientMenuItems = [
    {
      name: i18nText("common:ui.sideNavigation.permanentFile"),
      type: menuItemTypes.NAV_LINK,
      icon: {
        name: "description",
        fillStyle: "outlined",
        designStyle: "material-icons",
        colorStyle: "inherit",
        size: "normal"
      },
      link: `/clients/${clientId}/permanent-files`,
      context: [sideNavContext.PROJECT, sideNavContext.CLIENT],
      position: "bottom"
    }
  ];
  return clientMenuItems;
};

const getAdminMenuItems = ({ clientId, i18nText }) => [
  {
    name: i18nText("admin:ui.navigation.heading.userManagement.label"),
    type: menuItemTypes.NAV_SECTION_TITLE,
    icon: {
      name: "supervised_user_circle"
    },
    permission: [engagementManager],
    context: sideNavContext.ADMIN
  },
  {
    name: i18nText("admin:ui.navigation.manageUsers.label"),
    type: menuItemTypes.NAV_LINK,
    context: sideNavContext.ADMIN,
    link: routeConstants.manageUsers,
    isActive: isManageUsersSubPage(),
    permission: [engagementManager],
    icon: subSectionIcon
  },
  {
    name: i18nText("admin:ui.navigation.manageClientUsers.label"),
    type: menuItemTypes.NAV_LINK,
    context: sideNavContext.ADMIN,
    link: `/admin/clients/${clientId}/users`,
    isActive: isManageClientsSubPage(),
    permission: [clientAdmin],
    icon: subSectionIcon
  },
  {
    name: i18nText("admin:ui.navigation.manageRoles.label"),
    type: menuItemTypes.NAV_LINK,
    context: sideNavContext.ADMIN,
    icon: subSectionIcon,
    link: routeConstants.admin.manageUserRoles,
    enabledFeatures: [systemConstants.features.roles]
  },
  {
    name: i18nText("admin:ui.navigation.heading.clientManagement.label"),
    type: menuItemTypes.NAV_SECTION_TITLE,
    icon: {
      name: "apartment"
    },
    context: sideNavContext.ADMIN,
    permission: [engagementManager, teamMember]
  },
  {
    name: i18nText("admin:ui.navigation.manageClients.label"),
    type: menuItemTypes.NAV_LINK,
    context: sideNavContext.ADMIN,
    link: routeConstants.manageClients,
    permission: [engagementManager, teamMember],
    isActive: isManageClientsSubPage(),
    icon: subSectionIcon
  }
];

/**
 *
 * @param {Object} options
 * @param {Object} options.user
 * @param {number} options.clientId
 * @param {Object} options.project
 * @param {Object} options.interactiveReportMenusData
 * @param {*} options.i18nText
 * @param {String} [options.currentSideNavContext]
 * @param {Object} [options.pathname]
 * @returns
 */

const getSideMenuItems = ({
  user,
  clientId,
  project,
  interactiveReportMenusData,
  i18nText,
  currentSideNavContext
}) => {
  if (!user.isLoggedIn) {
    return [];
  }
  const interactiveReportMenuItems =
    interactiveReportMenusData?.map(mapIRMenus(project)) ?? [];
  const sideNav = [
    {
      name: i18nText("common:ui.dashboard.navigation.title"),
      type: menuItemTypes.NAV_LINK,
      permission: allUsers,
      icon: {
        custom: "dashboard"
      },
      link: `${routeConstants.projects}/${project?.id}`,
      context: sideNavContext.PROJECT
    },
    {
      name: i18nText("requests:requests.ui.navigation.title"),
      type: menuItemTypes.NAV_LINK,
      permission: allUsers,
      icon: {
        name: "pending_actions",
        fillStyle: "filled",
        designStyle: "material-icons",
        colorStyle: "inherit"
      },
      link: `/projects/${project?.id}/queries`,
      context: sideNavContext.PROJECT
    },
    {
      name: i18nText("risks:risks.ui.navigation.title"),
      type: menuItemTypes.NAV_LINK,
      permission: allUsers,
      icon: {
        name: "bubble_chart",
        fillStyle: "filled",
        designStyle: "material-icons",
        colorStyle: "inherit"
      },
      enabledFeatures: [systemConstants.features.projectRisks],
      link: `/projects/${project?.id}/risks`,
      context: sideNavContext.PROJECT
    },
    {
      name: i18nText("common:ui.documents.navigation.title"),
      type: menuItemTypes.NAV_LINK,
      permission: allUsers,
      icon: {
        name: "draft",
        designStyle: "material-symbols",
        colorStyle: "inherit",
        fillStyle: "outlined"
      },
      link: `/projects/${project?.id}/data-repository`,
      state: { project },
      context: sideNavContext.PROJECT
    },
    {
      name: i18nText("interactiveReport:interactiveReport.ui.navigation.title"),
      type: menuItemTypes.NAV_SECTION_TITLE,
      icon: {
        name: "bar_chart",
        designStyle: "material-icons",
        colorStyle: "inherit",
        fillStyle: "filled"
      },
      enabledFeatures: [systemConstants.features.interactiveReport],
      context: sideNavContext.PROJECT
    },
    ...(project ? interactiveReportMenuItems : []),
    {
      name: i18nText(
        "interactiveReport:interactiveReport.ui.navigation.uploadTitle"
      ),
      type: menuItemTypes.NAV_LINK,
      permission: [engagementManager, teamMember],
      icon: {
        name: "upload",
        designStyle: "material-icons",
        colorStyle: "inherit"
      },
      link: `/projects/${project?.id}/upload-interactive-report`,
      enabledFeatures: [systemConstants.features.interactiveReport],
      context: sideNavContext.PROJECT
    },
    ...(interactiveReportMenuItems?.length > 0
      ? [
          {
            name: i18nText(
              "interactiveReport:interactiveReport.ui.navigation.aiReporting"
            ),
            type: menuItemTypes.NAV_LINK,
            permission: [engagementManager, teamMember],
            icon: {
              name: "psychology",
              designStyle: "material-icons",
              colorStyle: "inherit"
            },
            link: `/projects/${project?.id}/ai-reporting`,
            enabledFeatures: [
              systemConstants.features.interactiveReport,
              systemConstants.features.aiReporting
            ],
            context: sideNavContext.PROJECT
          }
        ]
      : []),
    {
      name: i18nText("common:ui.projectSummary.header.projectSettings.label"),
      type: menuItemTypes.NAV_LINK,
      permission: [engagementManager, teamMember],
      icon: {
        name: "settings",
        designStyle: "material-icons",
        colorStyle: "inherit",
        fillStyle: "filled"
      },
      link: `/projects/${project?.id}/edit`,
      context: sideNavContext.PROJECT,
      position: "middle"
    },
    ...getAdminMenuItems({ clientId, i18nText }),
    ...getClientMenuItems({ user, clientId, i18nText })
  ];

  const navigationItems = getRelevantItems(
    sideNav,
    user,
    currentSideNavContext
  );
  return navigationItems;
};
const getTopMenuItems = ({
  user,
  i18nText,
  clientsForMenu,
  projectsForMenu
}) => {
  if (!user.isLoggedIn) {
    return [];
  }
  const topItems = [
    {
      name: i18nText("common:ui.home.navigation.title"),
      type: menuItemTypes.NAV_LINK,
      permission: allUsers,
      link: routeConstants.clientDashboard
    },
    {
      name: i18nText("common:ui.engagement.navigation.title"),
      type: menuItemTypes.NAV_LINK,
      permission: [engagementManager, teamMember],
      link: routeConstants.manageClientEngagements,
      enabledFeatures: [systemConstants.features.engagements],
      isActive: isEngagementSubPages()
    },
    {
      name: i18nText("common:ui.projects.navigation.title"),
      type: menuItemTypes.NAV_MULTILEVEL_DROPDOWN,
      permission: [engagementManager, teamMember],
      isActive: window.location.pathname.includes(routeConstants.projects),
      placeholder: i18nText("common:ui.forms.searchByClient.label"),
      items:
        clientsForMenu?.map(client => ({
          name: client.name,
          id: client.id,
          type: menuItemTypes.NAV_LINK
        })) ?? []
    },
    {
      name: i18nText("common:ui.projects.navigation.title"),
      type: menuItemTypes.NAV_SEARCHABLE_DROPDOWN,
      permission: [clientAdmin, clientUser],
      isActive: window.location.pathname.includes(routeConstants.projects),
      placeholder: i18nText("common:ui.forms.searchProjects.label"),
      items:
        projectsForMenu?.map(project => ({
          name: project.name,
          id: project.id,
          type: menuItemTypes.NAV_LINK
        })) ?? []
    },
    {
      name: i18nText("common:insights.navigation.title"),
      type: menuItemTypes.NAV_LINK,
      permission: [engagementManager],
      enabledFeatures: [systemConstants.features.insights],
      link: routeConstants.insightsBoard
    }
  ];
  const topNav = getRelevantItems(topItems, user);
  return topNav;
};

const getProfileMenuItems = ({ handleLogout, i18nText }) => [
  {
    name: i18nText("common:ui.sideNavigation.manageProfile"),
    path: routeConstants.profile,
    icon: "account_circle"
  },
  {
    name: i18nText("common:ui.sideNavigation.logout"),
    path: routeConstants.logout,
    icon: "exit_to_app",
    onClick: handleLogout
  }
];

export const menuItems = {
  getTopMenuItems,
  getProfileMenuItems,
  getCurrentSideNavContext,
  getSideMenuItems
};
