export const manageProjectConstants = {
  GET_SUPPORTED_MIMES_FOR_DOCUMENT_REQUEST:
    "GET_SUPPORTED_MIMES_FOR_DOCUMENT_REQUEST",
  GET_SUPPORTED_MIMES_FOR_DOCUMENT_SUCCESS:
    "GET_SUPPORTED_MIMES_FOR_DOCUMENT_SUCCESS",
  GET_SUPPORTED_MIMES_FOR_DOCUMENT_FAILURE:
    "GET_SUPPORTED_MIMES_FOR_DOCUMENT_FAILURE",

  GET_PROJECT_MESSAGES_REQUEST: "GET_PROJECT_MESSAGES_REQUEST",
  GET_PROJECT_MESSAGES_SUCCESS: "GET_PROJECT_MESSAGES_SUCCESS",
  GET_PROJECT_MESSAGES_FAILURE: "GET_PROJECT_MESSAGES_FAILURE",

  GET_PROJECT_TODOS_REQUEST: "GET_PROJECT_TODOS_REQUEST",
  GET_PROJECT_TODOS_SUCCESS: "GET_PROJECT_TODOS_SUCCESS",
  GET_PROJECT_TODOS_FAILURE: "GET_PROJECT_TODOS_FAILURE",

  UPDATE_PROJECT_TODO_STATUS_REQUEST: "UPDATE_PROJECT_TODO_STATUS_REQUEST",
  UPDATE_PROJECT_TODO_STATUS_SUCCESS: "UPDATE_PROJECT_TODO_STATUS_SUCCESS",
  UPDATE_PROJECT_TODO_STATUS_FAILURE: "UPDATE_PROJECT_TODO_STATUS_FAILURE",

  UPDATE_PROJECT_TODO_REQUEST: "UPDATE_PROJECT_TODO_REQUEST",
  UPDATE_PROJECT_TODO_SUCCESS: "UPDATE_PROJECT_TODO_SUCCESS",
  UPDATE_PROJECT_TODO_FAILURE: "UPDATE_PROJECT_TODO_FAILURE",

  GET_PROJECT_NOTES_REQUEST: "GET_PROJECT_NOTES_REQUEST",
  GET_PROJECT_NOTES_SUCCESS: "GET_PROJECT_NOTES_SUCCESS",
  GET_PROJECT_NOTES_FAILURE: "GET_PROJECT_NOTES_FAILURE",

  GET_PROJECT_MILESTONES_REQUEST: "GET_PROJECT_MILESTONES_REQUEST",
  GET_PROJECT_MILESTONES_SUCCESS: "GET_PROJECT_MILESTONES_SUCCESS",
  GET_PROJECT_MILESTONES_FAILURE: "GET_PROJECT_MILESTONES_FAILURE",

  GET_PROJECT_DOCUMENTS_REQUEST: "GET_PROJECT_DOCUMENTS_REQUEST",
  GET_PROJECT_DOCUMENTS_SUCCESS: "GET_PROJECT_DOCUMENTS_SUCCESS",
  GET_PROJECT_DOCUMENTS_FAILURE: "GET_PROJECT_DOCUMENTS_FAILURE",

  GET_PROJECT_DOCUMENT_REQUEST: "GET_PROJECT_DOCUMENT_REQUEST",
  GET_PROJECT_DOCUMENT_SUCCESS: "GET_PROJECT_DOCUMENT_SUCCESS",
  GET_PROJECT_DOCUMENT_FAILURE: "GET_PROJECT_DOCUMENT_FAILURE",

  GET_PROJECT_FILE_REQUEST: "GET_PROJECT_FILE_REQUEST",
  GET_PROJECT_FILE_SUCCESS: "GET_PROJECT_FILE_SUCCESS",
  GET_PROJECT_FILE_FAILURE: "GET_PROJECT_FILE_FAILURE",

  UPDATE_PROJECT_MESSAGE_REQUEST: "UPDATE_PROJECT_MESSAGE_REQUEST",
  UPDATE_PROJECT_MESSAGE_SUCCESS: "UPDATE_PROJECT_MESSAGE_SUCCESS",
  UPDATE_PROJECT_MESSAGE_FAILURE: "UPDATE_PROJECT_MESSAGE_FAILURE",

  SEND_PROJECT_MESSAGE_REQUEST: "SEND_PROJECT_MESSAGE_REQUEST",
  SEND_PROJECT_MESSAGE_SUCCESS: "SEND_PROJECT_MESSAGE_SUCCESS",
  SEND_PROJECT_MESSAGE_FAILURE: "SEND_PROJECT_MESSAGE_FAILURE",

  ASSIGN_PROJECT_TODO_REQUEST: "ASSIGN_PROJECT_TODO_REQUEST",
  ASSIGN_PROJECT_TODO_SUCCESS: "ASSIGN_PROJECT_TODO_SUCCESS",
  ASSIGN_PROJECT_TODO_FAILURE: "ASSIGN_PROJECT_TODO_FAILURE",

  REORDER_PROJECT_MEMBER_REQUEST: "REORDER_PROJECT_MEMBER_REQUEST",
  REORDER_PROJECT_MEMBER_SUCCESS: "REORDER_PROJECT_MEMBER_SUCCESS",
  REORDER_PROJECT_MEMBER_FAILURE: "REORDER_PROJECT_MEMBER_FAILURE",

  CREATE_PROJECT_NOTE_REQUEST: "CREATE_PROJECT_NOTE_REQUEST",
  CREATE_PROJECT_NOTE_SUCCESS: "CREATE_PROJECT_NOTE_SUCCESS",
  CREATE_PROJECT_NOTE_FAILURE: "CREATE_PROJECT_NOTE_FAILURE",

  UPDATE_PROJECT_NOTE_REQUEST: "UPDATE_PROJECT_NOTE_REQUEST",
  UPDATE_PROJECT_NOTE_SUCCESS: "UPDATE_PROJECT_NOTE_SUCCESS",
  UPDATE_PROJECT_NOTE_FAILURE: "UPDATE_PROJECT_NOTE_FAILURE",

  ADD_OR_UPDATE_PROJECT_MILESTONES_REQUEST:
    "ADD_OR_UPDATE_PROJECT_MILESTONES_REQUEST",
  ADD_OR_UPDATE_PROJECT_MILESTONES_SUCCESS:
    "ADD_OR_UPDATE_PROJECT_MILESTONES_SUCCESS",
  ADD_OR_UPDATE_PROJECT_MILESTONES_FAILURE:
    "ADD_OR_UPDATE_PROJECT_MILESTONES_FAILURE",

  RESET_UPDATE_PROJECT_TODO_STATUS_FLAG:
    "RESET_UPDATE_PROJECT_TODO_STATUS_FLAG",

  RESET_UPDATE_PROJECT_TODO_FLAG: "RESET_UPDATE_PROJECT_TODO_FLAG",

  CLEAR_ERROR: "CLEAR_ERROR_PROJECT",
  RESET_UPDATE_PROJECT_NOTE: "UPDATE_PROJECT_NOTE",
  RESET_CREATE_PROJECT_NOTE: "UPDATE_PROJECT_NOTE",
  RESET_DOCUMENT: "RESET_DOCUMENT",
  RESET_ASSIGN_PROJECT_TODO: "RESET_ASSIGN_PROJECT_TODO",
  RESET: "RESET_PROJECT",

  SET_CURRENT_PROJECT: "SET_CURRENT_PROJECT",
  RESET_CURRENT_PROJECT: "RESET_CURRENT_PROJECT",

  GET_PROJECT_SUCCESS: "GET_PROJECT_SUCCESS",
  GET_PROJECT_FAILURE: "GET_PROJECT_FAILURE",
  GET_PROJECT_REQUEST: "GET_PROJECT_REQUEST"
};
