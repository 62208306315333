import React, { useCallback, useEffect, useMemo, useState } from "react";

import lodash from "lodash";
import PropTypes from "prop-types";
import * as yup from "yup";

import { systemConstants } from "@shared/constants/systemConstants";

import DropdownDotMenu from "@components/atoms/DropdownDotMenu/DropdownDotMenu";
import Form from "@components/atoms/Form/Form";
import InlineAlert from "@components/atoms/InlineAlert/InlineAlert";
import PopupOverlay from "@components/atoms/PopupOverlay/PopupOverlay";
import DangerModal from "@components/molecules/DangerModal/DangerModal";
import ModalForm from "@components/molecules/ModalForm";
import UploadFile from "@components/molecules/UploadFile/UploadFile";
import NewsMastheadImage from "@components/organisms/News/NewsMastheadImage";
import BoxTemplate from "@components/templates/BoxTemplate/BoxTemplate";

import "./EditableNewsMasthead.scss";

const menuItemsNames = Object.freeze({
  replaceImage: "Update",
  deleteImage: "Delete"
});

// NB: these defaults are fallbacks if configuration don't provide them
const defaultMessageStrings = Object.freeze({
  title: "Edit News Image",
  imageUploadControl: {
    label: "Upload News Image",
    promptToDropFiles: "Drag and drop a PNG file or",
    imageRequiredError: "Please select a replacement image",
    tooManyFilesError:
      "Only one file can be uploaded. Make sure there is exactly one file attached and try again",
    invalidFileTypeError: "Files must be in .png format to upload"
  },
  deleteConfirmation: {
    title: "Delete News Image",
    text: "Are you sure you want to delete this News Image? This will affect what is visible on all project pages and cannot be undone."
  },
  replaceConfirmation: {
    title: "Replace News Image",
    text: "Are you sure you want to replace this News Image? This will affect what is visible on all project pages and cannot be undone."
  }
});

const addFilesState = systemConstants.addFiles.state;
const baseURL = systemConstants.serverURL;

const EditableNewsMasthead = props => {
  const {
    engagementTypeId,
    newsConfiguration,
    deleteImage,
    newsMasthead,
    uploadHooks
  } = props;
  const [mastheadImage, setMastheadImage] = useState(null);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showReplaceConfirmation, setShowReplaceConfirmation] = useState(false);
  const [showUploadImage, setShowUploadImage] = useState(false);

  const [dropErrorMessage, setDropErrorMessage] = useState(null);
  const [recentlyDroppedFiles, setRecentlyDroppedFiles] = useState([]);
  const [uploadState, setUploadState] = useState(addFilesState.add);

  const messageStrings = useMemo(() => {
    const mastheadConfig = newsConfiguration?.masthead ?? {};
    return lodash.defaultsDeep({}, mastheadConfig, defaultMessageStrings);
  }, [newsConfiguration]);

  useEffect(() => {
    if (!newsMasthead?.assetName) {
      setMastheadImage(null);
    } else {
      const imageUrl = `${baseURL}/api/hostBrands/assets/${window.location.host}/${newsMasthead?.assetName}?v=${newsMasthead.lastUpdated}`;
      setMastheadImage(imageUrl);
    }
  }, [newsMasthead]);

  const handleMenuItemClicked = menuItem => {
    switch (menuItem.name) {
      case menuItemsNames.deleteImage: {
        setShowDeleteConfirmation(true);
        break;
      }
      case menuItemsNames.replaceImage: {
        setShowReplaceConfirmation(true);
        setRecentlyDroppedFiles([]);
        setUploadState(addFilesState.add);
        uploadHooks.clearUploadsCompleted();
      }
    }
  };

  const startUploadWorkflow = useCallback(() => {
    setShowUploadImage(true);
  }, []);

  const handleDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
    setMastheadImage(null);
    deleteImage({ engagementTypeId });
  };
  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
  };

  const handleReplaceConfirmation = () => {
    setShowReplaceConfirmation(false);
    startUploadWorkflow();
  };
  const handleCancelReplace = () => {
    setShowReplaceConfirmation(false);
  };

  const handleSubmitImage = _fields => {
    setUploadState(addFilesState.upload);
  };
  const handleUploadCompletion = useCallback(
    _uploadedFiles => {
      setUploadState(addFilesState.finished);
      uploadHooks.clearUploadsCompleted();
      setShowUploadImage(false);
      setDropErrorMessage(null);
    },
    [uploadHooks]
  );
  const handleUploadFailure = () => {
    setUploadState(addFilesState.add);
  };
  const handleUploadCancel = () => {
    setShowUploadImage(false);
  };

  const handleDropRejection = useCallback(
    filesRejected => {
      const rejectReasons = new Set();
      filesRejected.forEach(f => {
        f.errors.forEach(e => {
          rejectReasons.add(e.code);
        });
      });
      if (rejectReasons.has("too-many-files")) {
        setDropErrorMessage(
          messageStrings.imageUploadControl.tooManyFilesError
        );
      } else if (rejectReasons.has("file-invalid-type")) {
        setDropErrorMessage(
          messageStrings.imageUploadControl.invalidFileTypeError
        );
      }
    },
    [messageStrings]
  );
  const handleDropSuccess = useCallback(
    filesAccepted => {
      if (filesAccepted.length !== 1) {
        return;
      }
      setRecentlyDroppedFiles([filesAccepted[0]]);
      startUploadWorkflow();
    },
    [startUploadWorkflow]
  );

  return (
    <BoxTemplate
      boxClassName="editable-news-image"
      title={messageStrings.title}
      size="medium"
      action={
        mastheadImage && (
          <DropdownDotMenu
            positionRelative={true}
            menuItems={[
              { name: menuItemsNames.replaceImage },
              { name: menuItemsNames.deleteImage }
            ]}
            onMenuItemClick={handleMenuItemClicked}
          ></DropdownDotMenu>
        )
      }
    >
      {mastheadImage ? (
        <NewsMastheadImage
          imageAlt="masthead image"
          imageSrc={mastheadImage}
        ></NewsMastheadImage>
      ) : (
        <>
          <UploadFile
            handleDrop={handleDropSuccess}
            maxNumberOfFiles={1}
            supportedDocumentMimes={["png"]}
            dropMessage={messageStrings.imageUploadControl.promptToDropFiles}
            handleRejection={handleDropRejection}
          ></UploadFile>
          {dropErrorMessage && (
            <InlineAlert type="error" message={dropErrorMessage}></InlineAlert>
          )}
        </>
      )}

      <PopupOverlay
        isVisible={showDeleteConfirmation}
        isModal={true}
        showClose={false}
        width={"60rem"}
      >
        <DangerModal
          title={messageStrings.deleteConfirmation.title}
          content={messageStrings.deleteConfirmation.text}
          handleConfirmation={handleDeleteConfirmation}
          onCancel={handleCancelDelete}
        ></DangerModal>
      </PopupOverlay>

      <PopupOverlay
        isVisible={showReplaceConfirmation}
        isModal={true}
        showClose={false}
        width={"60rem"}
      >
        <DangerModal
          title={messageStrings.replaceConfirmation.title}
          content={messageStrings.replaceConfirmation.text}
          handleConfirmation={handleReplaceConfirmation}
          onCancel={handleCancelReplace}
        ></DangerModal>
      </PopupOverlay>

      <PopupOverlay
        isVisible={showUploadImage}
        isModal={true}
        showClose={true}
        width={"60rem"}
      >
        <ModalForm
          boxClassName="replace-image"
          title="Upload Replacement Image"
          submitLabel="Submit"
          cancelLabel="Cancel"
          handleSubmit={handleSubmitImage}
          handleCancel={handleUploadCancel}
          yupSchema={yup.object({
            newsImage: yup
              .object()
              .test(
                "newsImage",
                messageStrings.imageUploadControl.imageRequiredError,
                val => (val ? Object.values(val).length > 0 : false)
              )
          })}
        >
          <Form.UploadImages
            key="newsImage"
            name="newsImage"
            label={messageStrings.imageUploadControl.label}
            supportedDocumentMimesMessage={
              messageStrings.imageUploadControl.invalidFileTypeError
            }
            dropMessage={messageStrings.imageUploadControl.promptToDropFiles}
            maxNumberOfFilesError={
              messageStrings.imageUploadControl.tooManyFilesError
            }
            maxNumberOfFiles={1}
            initialAddFiles={recentlyDroppedFiles}
            uploadHooks={uploadHooks}
            engagementTypeId={engagementTypeId}
            state={uploadState}
            onUploadsComplete={handleUploadCompletion}
            onUploadsFailed={handleUploadFailure}
          ></Form.UploadImages>
        </ModalForm>
      </PopupOverlay>
    </BoxTemplate>
  );
};

EditableNewsMasthead.defaultProps = {};

EditableNewsMasthead.propTypes = {
  engagementTypeId: PropTypes.number.isRequired,
  newsConfiguration: PropTypes.shape({
    masthead: PropTypes.object
  }),
  deleteImage: PropTypes.func,
  newsMasthead: PropTypes.shape({
    assetName: PropTypes.string,
    lastUpdated: PropTypes.number
  }).isRequired,
  uploadHooks: PropTypes.shape({
    uploadImageFiles: PropTypes.func,
    isUploading: PropTypes.bool,
    uploadsFailed: PropTypes.bool,
    uploadsCompleted: PropTypes.bool,
    uploadError: PropTypes.any,
    getUploadFileState: PropTypes.func,
    clearUploadsCompleted: PropTypes.func
  }).isRequired
};

export default EditableNewsMasthead;
