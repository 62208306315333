import React, { useCallback, useMemo, useState } from "react";

import { paginationHelper } from "@app/helpers/pagination";

import HoverText from "@components/atoms/HoverText/HoverText";
import Icon from "@components/atoms/Icon";

import "./Pagination.scss";

const PaginationNumber = ({
  t,
  page,
  isActive,
  information,
  handlePageUpdate,
  type
}) => {
  const [showHover, setShowHover] = useState(false);

  const iconName = useMemo(
    () => paginationHelper.getPageIconName(information, type),
    [information, type]
  );

  const hoverText = useMemo(() => {
    const lines = paginationHelper.getPageHoverText(t, information, type);
    return lines?.length ? (
      <div className="pagination-number__hover" key="pagination-number-hover">
        {lines.map((text, index) => (
          <span key={index}>{text}</span>
        ))}
      </div>
    ) : null;
  }, [information, t, type]);

  const onClick = useCallback(
    () => handlePageUpdate(page),
    [handlePageUpdate, page]
  );

  const onMouseEnter = useCallback(() => {
    setShowHover(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setShowHover(false);
  }, []);

  return (
    <div
      key={`pagination-number-page-${page}`}
      className={`pagination-number${isActive ? "--current" : ""}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span className="pagination-number__text">{page}</span>
      {iconName && (
        <Icon
          name={iconName}
          className="pagination-number__icon"
          fillStyle="filled"
        />
      )}
      {hoverText && (
        <HoverText
          isActive={showHover}
          name={hoverText}
          position="top-mid"
          maxWidth={900}
          type="long"
        />
      )}
    </div>
  );
};

export default PaginationNumber;
