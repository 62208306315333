import React from "react";

import PropTypes from "prop-types";

import Icon from "@components/atoms/Icon";

import "./DataViewSwitcher.scss";

function DataViewSwitcher(props) {
  const { selectedView, onChangeView } = props;

  const onGridClick = () => onChangeView("grid");
  const onTableClick = () => onChangeView("table");

  return (
    <div className="ot-data-view-switcher">
      <div className="ot-data-view-switcher__icon ot-data-view-switcher__icon--grid">
        <Icon
          name="apps"
          onClick={onGridClick}
          colorStyle={selectedView === "grid" ? "accent" : "sideNav"}
          size={"large"}
        />
      </div>

      <div className="ot-data-view-switcher__icon ot-data-view-switcher__icon--table">
        <Icon
          name="menu"
          onClick={onTableClick}
          colorStyle={selectedView === "table" ? "accent" : "sideNav"}
          size={"large"}
        />
      </div>
    </div>
  );
}

DataViewSwitcher.propTypes = {
  selectedView: PropTypes.oneOf(["table", "grid"]).isRequired,
  onChangeView: PropTypes.func.isRequired
};

export default DataViewSwitcher;
