import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

import { utilities } from "@shared/helpers/utilities";

import Form from "@components/atoms/Form";

function SelectProjectYearField(props) {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 21 }, (_, i) => ({
    name: (currentYear - 10 + i).toString()
  }));

  const [selectedYear, setSelectedYear] = useState(null);

  useEffect(() => {
    setSelectedYear(
      utilities.isValidYearString(props.defaultValue)
        ? { name: props.defaultValue, value: props.defaultValue }
        : null
    );
  }, [props.defaultValue]);

  return (
    <div>
      <Form.Dropdown
        name={props.name}
        label={props.label}
        items={years}
        showSearchFilter={false}
        defaultValue={selectedYear}
        required={props.required}
      />
    </div>
  );
}

SelectProjectYearField.propTypes = {
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool
};

export default SelectProjectYearField;
