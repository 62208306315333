import React, { useCallback, useEffect, useRef, useState } from "react";

import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  manageDocumentDownloadsActions,
  manageProjectQueriesActions,
  manageQueryResponsesActions
} from "@shared/actions";
import { systemConstants } from "@shared/constants/systemConstants";
import {
  useAuthUser,
  useCurrentProject,
  useGetProjectById,
  useGetProjectMembers,
  useGetProjectQuery,
  useRequestPageNavigator,
  useUIConfig,
  useUpdateQuery
} from "@shared/hooks";
import { useGetActionItemTypesQuery } from "@shared/services/actionItemTypesService";

import Popup from "@shared-components/popup/Popup";

import { routeConstants } from "@app/constants/routeConstants";

import Icon from "@components/atoms/Icon";
import ProjectAccessModal from "@components/molecules/ProjectAccessModal";
import RequestAttachmentSummaryBox from "@components/molecules/RequestAttachmentSummaryBox";
import RequestConversationBox from "@components/molecules/RequestConversationBox";
import RequestDetailsBox from "@components/molecules/RequestDetailsBox";
import RequestEntitiesBox from "@components/molecules/RequestEntitiesBox";
import EditActionTags from "@components/organisms/EditActionTags";
import QueryActions from "@components/organisms/QueryActions";
import AddQueryResponse from "@components/organisms/QueryActions/AddQueryResponse";
import { buttonActionConstants } from "@components/organisms/QueryActions/QueryActions";
import ReassignQuery from "@components/organisms/QueryActions/ReassignQuery";
import UpdateCopiedTo from "@components/organisms/QueryActions/UpdateCopiedTo";
import PageTemplate from "@components/templates/PageTemplate/PageTemplate";

import "./RequestDetails.scss";

const statusTypes = systemConstants.project.queries.status;
const conversationActionItemType = systemConstants.actionItemTypes.conversation;
const responseTypes = systemConstants.project.queries.responses.responseTypes;

const RequestDetails = props => {
  const { t } = props;
  const { updatedQuery, updateQuery } = useUpdateQuery();
  const {
    query: fetchedQuery,
    errorFetchingQuery,
    refetchQuery
  } = useGetProjectQuery();
  const location = useLocation();
  const [query, setQuery] = useState(location.state?.query);
  const [queryResponses, setResponses] = useState([]);
  const { user: authUser } = useAuthUser();
  const [showProjectAccessModal, setShowProjectAccessModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [reassignQueryVisibility, setReassignQueryVisibility] = useState(false);
  const [addResponseVisibility, setAddResponseVisibility] = useState(false);
  const [responseActionType, setResponseActionType] = useState("");
  const [showEditTags, setShowEditTags] = useState(false);

  const [conversationConfig, setConversationConfig] = useState(null);
  const [showReminderDate, setShowReminderDate] = useState(false);
  const [pillConfig, setPillConfig] = useState();
  const [showUpdateCopiedTo, setShowUpdateCopiedTo] = useState(false);

  const dispatch = useDispatch();
  const manageQueryResponses = useSelector(state => state.manageQueryResponses);
  const { navigateToRequestPage } = useRequestPageNavigator();
  const navigate = useNavigate();
  const { projectId, queryId } = useParams();
  const { uiConfig } = useUIConfig();
  const { reloadProjectById, errorFetchingProject } = useGetProjectById();
  const isProjectFetched = useRef(false);
  const { currentProject } = useCurrentProject();
  const [currentProjectId, setCurrentProjectId] = useState();
  const { data: actionItemTypes } = useGetActionItemTypesQuery(
    {
      engagementTypeId: currentProject?.engagement.engagementTypeId,
      projectId: currentProject?.id
    },
    {
      skip: currentProject?.engagement.engagementTypeId === undefined
    }
  );
  const { members } = useGetProjectMembers({ id: projectId });
  const [isLoadingPageFirstTime, setIsLoadingPageFirstTime] = useState(true);

  useEffect(() => {
    if (!projectId && currentProject && !queryId && query) {
      navigate(`/projects/${currentProject.id}/queries/${query.id}`, {
        replace: true
      });
    }
  }, [navigate, currentProject, projectId, query, queryId]);

  useEffect(() => {
    if (errorFetchingProject) {
      navigate("not-found", { replace: true });
    }
  }, [errorFetchingProject, navigate]);

  useEffect(() => {
    if (errorFetchingQuery) {
      navigate("not-found", { replace: true });
    }
  }, [errorFetchingQuery, navigate, projectId, currentProject]);

  useEffect(
    () => () => dispatch(manageDocumentDownloadsActions.reset()),
    [dispatch]
  );

  useEffect(() => {
    const currProjectId = projectId || location?.state?.project?.id;
    if (currProjectId) {
      setCurrentProjectId(currProjectId);
      isProjectFetched.current = false;
    }
  }, [projectId, location?.state?.project]);

  useEffect(() => {
    if (
      currentProjectId &&
      !errorFetchingProject &&
      (!currentProject || currentProject.id != currentProjectId) &&
      !isProjectFetched.current
    ) {
      reloadProjectById(currentProjectId);
      isProjectFetched.current = true;
    }
  }, [
    currentProjectId,
    currentProject,
    errorFetchingProject,
    reloadProjectById
  ]);

  useEffect(() => {
    return () => {
      dispatch(manageDocumentDownloadsActions.reset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (uiConfig?.pills) {
      setPillConfig(uiConfig.pills);
    }
  }, [uiConfig?.pills]);

  useEffect(() => {
    if (fetchedQuery && projectId) {
      if (fetchedQuery.projectId != projectId) {
        navigate(routeConstants.login);
      } else {
        setQuery(fetchedQuery);
        setIsLoadingPageFirstTime(false);
      }
    } else if (isLoadingPageFirstTime && queryId) {
      refetchQuery(queryId);
    }
  }, [
    fetchedQuery,
    isLoadingPageFirstTime,
    navigate,
    projectId,
    queryId,
    refetchQuery
  ]);

  useEffect(() => {
    if (
      query?.id &&
      manageQueryResponses.queryResponses &&
      manageQueryResponses.queryResponses[query.id]
    ) {
      setResponses(manageQueryResponses.queryResponses[query.id]);
    }
  }, [query?.id, manageQueryResponses.queryResponses]);

  useEffect(() => {
    if (query?.id) {
      dispatch(manageQueryResponsesActions.getQueryResponses(query.id));
    }
  }, [dispatch, query?.id, manageQueryResponses.isAdded]);

  useEffect(() => {
    if (updatedQuery && query?.id && updatedQuery.id === query.id) {
      setQuery({ ...updatedQuery });
      setResponseActionType("");
      dispatch(manageProjectQueriesActions.getMyQueryCount(currentProject));
    }
  }, [updatedQuery, dispatch, currentProject, query?.id]);

  useEffect(() => {
    if (!query?.queryType) {
      return;
    }
    const filteredActionItemTypes = actionItemTypes
      ?.filter(
        actionItemType => actionItemType.configuration.key === query.queryType
      )
      ?.map(q => ({
        ...q.configuration,
        buttons: currentProject?.configuration.buttons
      }));
    if (filteredActionItemTypes?.length > 0) {
      setConversationConfig(filteredActionItemTypes[0]);
    }
  }, [actionItemTypes, query?.queryType, currentProject]);

  useEffect(() => {
    if (!conversationConfig?.type) {
      return;
    }
    // The actual config type isn't Conversation redirect appropriately
    if (conversationConfig.type !== conversationActionItemType) {
      navigateToRequestPage(
        query.id,
        query.projectId,
        conversationConfig.type,
        { replace: true }
      );
    }
  }, [conversationConfig, query, navigateToRequestPage]);

  function downloadAttachment({ id, name, documentRevisionId }) {
    if (documentRevisionId) {
      dispatch(
        manageDocumentDownloadsActions.downloadDocumentRevision({
          id,
          name,
          documentRevisionId
        })
      );
    } else {
      dispatch(
        manageDocumentDownloadsActions.downloadCurrentDocument({ id, name })
      );
    }
  }

  const isProjectMember = (action, cb) => {
    if (
      authUser?.isHostUser &&
      !members.hostUsers?.some(member => member.id === authUser.id)
    ) {
      setShowProjectAccessModal(true);
      setErrorMessage(t("common:project.accessRequiredToDoAction", { action }));
    } else {
      cb();
    }
  };

  const handleCancelReassignQuery = () => {
    setReassignQueryVisibility(false);
  };

  const handlePopupCancelClick = () => {
    setAddResponseVisibility(false);
  };

  const handleCopiedToUpdated = () => {
    refetchQuery(query.id);
    setShowUpdateCopiedTo(false);
  };
  const handleCopiedToCancelled = () => {
    setShowUpdateCopiedTo(false);
  };

  const handleTagsUpdated = () => {
    refetchQuery(query.id);
    setShowEditTags(false);
  };
  const handleCancelUpdateTags = () => {
    setShowEditTags(false);
  };

  const handlePopupAddClick = () => {
    setAddResponseVisibility(false);
    refetchQuery(query.id);
  };

  const showAddResponsePopup = () => {
    isProjectMember(
      t(
        "requests:requests.ui.requestDetailsPage.accessRequiredActionAddResponse"
      ),
      () => {
        dispatch(manageDocumentDownloadsActions.resetError());
        setResponseActionType(responseTypes.message);
        setAddResponseVisibility(true);
      }
    );
  };

  const showRejectRequestPopup = () => {
    dispatch(manageDocumentDownloadsActions.resetError());
    setResponseActionType(responseTypes.reject);
    setAddResponseVisibility(true);
  };

  const showApproveRequestPopup = () => {
    dispatch(manageDocumentDownloadsActions.resetError());
    setResponseActionType(responseTypes.approve);
    setAddResponseVisibility(true);
  };

  const closeRequest = () => {
    isProjectMember(
      t(
        "requests:requests.ui.requestDetailsPage.accessRequiredActionCloseRequest"
      ),
      () => {
        dispatch(manageDocumentDownloadsActions.resetError());
        setResponseActionType(responseTypes.close);
        updateQuery({ ...query, status: statusTypes.closed });
      }
    );
  };

  const showReassignRequestPopup = () => {
    isProjectMember(
      t(
        "requests:requests.ui.requestDetailsPage.accessRequiredActionReassignRequest"
      ),
      () => {
        setReassignQueryVisibility(true);
      }
    );
  };

  const onQueryReassigned = useCallback(() => {
    if (updatedQuery) {
      setQuery({ ...updatedQuery });
    }
    setReassignQueryVisibility(false);
  }, [updatedQuery]);

  const reopenRequest = () => {
    dispatch(manageDocumentDownloadsActions.resetError());
    setResponseActionType(responseTypes.reopen);
    updateQuery({ ...query, status: statusTypes.open });
  };

  const handleCloseAccessModal = () => {
    setShowProjectAccessModal(false);
    setErrorMessage("");
  };

  useEffect(() => {
    const reminderDateField = conversationConfig?.fields?.find(
      f => f.key === "reminderDate"
    );

    setShowReminderDate(
      reminderDateField &&
        !(
          reminderDateField.availableTo &&
          !authUser.isMemberOfUserGroup(reminderDateField.availableTo)
        )
    );
  }, [authUser, conversationConfig?.fields]);

  const conversationContent = () => {
    return (
      authUser.id && (
        <>
          <div className="request-details__interactions">
            <div className="request-details__conversation">
              <RequestConversationBox
                query={query}
                responses={queryResponses}
                viewingUserId={authUser.id}
                handleFileDownload={downloadAttachment}
                title={t("requests:requests.configured.shortName", {
                  context: conversationConfig.key
                })}
              />
            </div>

            <div className="request-details__actions">
              <QueryActions
                queryId={query.id}
                queryType={query.queryType}
                queryConfig={conversationConfig}
                queryStatus={query.status}
                isUserRequestor={query.isUserOnRequestorTeam}
                requestHandlers={{
                  [buttonActionConstants.reassign]: showReassignRequestPopup,
                  [buttonActionConstants.closeRequest]: closeRequest,
                  [buttonActionConstants.reopen]: reopenRequest,
                  [buttonActionConstants.addResponse]: showAddResponsePopup,
                  [buttonActionConstants.approve]: showApproveRequestPopup,
                  [buttonActionConstants.reject]: showRejectRequestPopup,
                  [buttonActionConstants.closePage]: () => navigate(-1)
                }}
              ></QueryActions>
            </div>
          </div>
        </>
      )
    );
  };

  const milestoneContent = () => {
    if (!currentProject?.configuration?.milestones?.labels?.enabled) {
      return "";
    }
    const projectMilestones = currentProject.milestones;
    return projectMilestones?.find(m => m.id === query?.milestone)?.name ?? "";
  };

  const detailsContent = () => {
    return (
      <>
        <RequestDetailsBox
          requestQuery={query}
          queryConfig={conversationConfig}
          showReminderDate={showReminderDate}
          hideRequiredByField={
            currentProject?.configuration?.requests?.hideRequiredByField ??
            false
          }
          canEditLabels={authUser?.isHostUser}
          onClickEditLabels={() => {
            setShowEditTags(true);
          }}
          onClickEditCopiedTo={() => {
            setShowUpdateCopiedTo(true);
          }}
          title={t("requests:requests.configured.shortName", {
            context: conversationConfig.key
          })}
          pillConfig={pillConfig}
          milestoneContent={milestoneContent()}
        />
        <RequestAttachmentSummaryBox
          responses={queryResponses}
          attachments={query.attachments}
          onFileDownloadClicked={downloadAttachment}
        />

        {currentProject?.entities &&
          currentProject?.configuration.entities?.enabled && (
            <RequestEntitiesBox
              title={t(`requests:requests.configured.fields.entities.label`, {
                context: query.queryType
              })}
              boxClassName="request-details__entities"
              projectEntities={currentProject.entities ?? []}
              entityIdsToDisplay={query?.entities ?? []}
              action={<Icon name="lan" />}
            />
          )}
      </>
    );
  };

  return conversationConfig ? (
    <>
      <PageTemplate
        header={{
          title: t("requests:requests.configured.name", {
            context: conversationConfig.key
          })
        }}
        body={{
          primary: conversationContent(),
          secondary: detailsContent()
        }}
        other={{
          project: currentProject
        }}
      />
      <Popup
        visibility={reassignQueryVisibility}
        handleOutsideClick={handleCancelReassignQuery}
        width="50rem"
      >
        <ReassignQuery
          project={currentProject}
          query={query}
          onQueryReassigned={onQueryReassigned}
          onCancel={handleCancelReassignQuery}
        />
      </Popup>
      <Popup
        visibility={showEditTags}
        handleOutsideClick={handleCancelReassignQuery}
        width="50rem"
      >
        <EditActionTags
          project={currentProject}
          query={query}
          onUpdate={handleTagsUpdated}
          onCancel={handleCancelUpdateTags}
        />
      </Popup>
      <Popup
        visibility={showUpdateCopiedTo}
        handleOutsideClick={handleCancelReassignQuery}
        width="50rem"
      >
        <UpdateCopiedTo
          project={currentProject}
          query={query}
          queryConfig={conversationConfig}
          onUpdate={handleCopiedToUpdated}
          onCancel={handleCopiedToCancelled}
        />
      </Popup>
      <Popup
        visibility={addResponseVisibility}
        handleOutsideClick={false}
        width="60rem"
      >
        <AddQueryResponse
          query={query}
          queryConfig={conversationConfig}
          project={currentProject}
          queryType={query.queryType}
          onSuccess={handlePopupAddClick}
          onCancel={handlePopupCancelClick}
          responseType={responseActionType}
        />
      </Popup>
      <ProjectAccessModal
        visibility={showProjectAccessModal}
        handleClose={handleCloseAccessModal}
        message={errorMessage}
      />
    </>
  ) : (
    <></>
  );
};

export default withTranslation()(RequestDetails);
