import { isNil } from "lodash";

export function formatDocumentAnswersPayload(previousAnswers, data) {
  const getPreviousAnswerStateForFile = documentId =>
    Object.entries(previousAnswers).reduce((acc, [entityId, documentIds]) => {
      const docId = documentIds?.find(dId => dId === documentId);
      if (!docId) {
        return acc;
      }

      return {
        ...acc,
        [entityId]: docId
      };
    }, {});

  return data.map(d => {
    const fileAndAnswerInfo = d.value;

    const previousAnswerStateForFile = getPreviousAnswerStateForFile(
      fileAndAnswerInfo.documentId
    );

    const finalAnswerStateForFile =
      fileAndAnswerInfo.entities?.reduce(
        (acc, e) => ({
          ...acc,
          [e]: fileAndAnswerInfo.documentId
        }),
        {}
      ) ?? {};

    return {
      value: {
        ...fileAndAnswerInfo,
        entityChanges: formatSmartFormPayload(
          previousAnswerStateForFile,
          finalAnswerStateForFile
        )
      }
    };
  });
}

/**
 * Compare previous answer and final answer
 * @param {Object} prev {1:"test1",2:"test2",3:"test3"}
 * @param {Object} final {1: "test1a"}
 * @returns {Object[]} payload [{entities:[1],value: "test1a"},{entities:[2,3],isDeleted: true}]
 */
export function formatSmartFormPayload(prev, final) {
  const toUpdatedAnswers = [];
  const toDeleteAnswers = [];

  //find toDelete and toUpdate Answers
  Object.keys(prev).forEach(entityId => {
    if (isNil(final[entityId])) {
      toDeleteAnswers.push({
        value: prev[entityId],
        entities: [+entityId],
        isDeleted: true
      });
    } else if (final[entityId] !== prev[entityId]) {
      toUpdatedAnswers.push({
        value: final[entityId],
        entities: [+entityId]
      });
    }
  });

  //find new answers
  Object.keys(final).forEach(entityId => {
    if (isNil(prev[entityId])) {
      toUpdatedAnswers.push({
        entities: [+entityId],
        value: final[entityId],
        isAdded: true
      });
    }
  });

  toUpdatedAnswers.push(...toDeleteAnswers);
  const formattedPayload = toUpdatedAnswers;
  return formattedPayload;
}
