import React from "react";

import PropTypes from "prop-types";

import "./CheckBox.scss";

const CheckBox = React.forwardRef((props, fwdRef) => {
  const {
    label,
    value,
    onChange,
    onBlur,
    error,
    disabled,
    styleVariant,
    textWeight,
    labelPosition,
    className,
    indeterminate,
    formProps
  } = props;
  const getErrorClass = () => (error ? " checkbox--error" : "");
  const getTypeClass = () => `checkbox--type-${styleVariant}`;
  const getWeightClass = () => `checkbox--weight-${textWeight}`;
  const renderLabel = label && <p className="checkbox__label">{label}</p>;
  const getIndeterminateClass = () =>
    indeterminate ? "checkbox__input--indeterminate" : "";

  return (
    <label
      className={[
        "checkbox",
        getErrorClass(),
        getTypeClass(),
        getWeightClass(),
        className
      ]
        .filter(c => c)
        .join(" ")}
    >
      {labelPosition === "left" && renderLabel}
      <input
        ref={fwdRef}
        className={["checkbox__input", getIndeterminateClass()].join(" ")}
        onChange={onChange}
        onBlur={onBlur}
        type="checkbox"
        disabled={disabled}
        // when passing in form props (onChange, onBlur, ref, etc), if the input contains checked value, it overrides react-hook-form behavior
        {...(formProps ?? { checked: !!value })}
      />
      <span className="checkbox__checkmark"></span>
      {labelPosition === "right" && renderLabel}
    </label>
  );
});

CheckBox.displayName = "CheckBox";

CheckBox.defaultProps = {
  styleVariant: "standard",
  textWeight: "bold",
  labelPosition: "right",
  formProps: null
};

CheckBox.propTypes = {
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(["left", "right"]),
  onChange: PropTypes.func,
  value: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  styleVariant: PropTypes.oneOf([
    "accent",
    "standard",
    "primary",
    "primary-light"
  ]),
  textWeight: PropTypes.oneOf(["normal", "bold"]),
  className: PropTypes.string,
  indeterminate: PropTypes.bool,
  formProps: PropTypes.object,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool
};

export default CheckBox;
