import { useEffect, useState } from "react";

import {
  useAddDeletedAnswerMutation,
  useGetQuestionDeletedAnswersQuery,
  useRemoveDeletedAnswerMutation,
  useRestoreDeletedAnswerMutation
} from "@shared/hooks";

export function useUpdateDeleteAnswers(
  queryId,
  questionId,
  isDocumentQuestion
) {
  const [error, setError] = useState("");
  const [unsavedDeletedAnswers, setUnsavedDeletedAnswers] = useState([]);
  const [addDeletedAnswer, { error: addDeleteAnswerError }] =
    useAddDeletedAnswerMutation();
  const [restoreDeletedAnswer, { error: restoreDeletedAnswerError }] =
    useRestoreDeletedAnswerMutation();
  const [removeDeletedAnswer, { error: removeDeletedAnswerError }] =
    useRemoveDeletedAnswerMutation();

  const { data: deletedAnswers } = useGetQuestionDeletedAnswersQuery(
    {
      queryId,
      questionId
    },
    { skip: (!queryId && !questionId) || !isDocumentQuestion }
  );

  useEffect(() => {
    const err =
      addDeleteAnswerError ||
      restoreDeletedAnswerError ||
      removeDeletedAnswerError;
    setError(err?.data?.key ?? err?.data?.message ?? err?.error ?? "");
  }, [
    addDeleteAnswerError,
    restoreDeletedAnswerError,
    removeDeletedAnswerError
  ]);

  const addToDeleted = answer => {
    if (answer.unsaved) {
      return;
    }
    const entities = answer.entities?.map(e => +e.value);
    const responseType = answer.responseType;
    const complete = answer.complete;
    const formattedAnswer = {
      value: answer?.value?.id,
      ...(entities ? { entities } : {}),
      ...(responseType ? { responseType } : {}),
      ...(complete ? { complete } : {})
    };
    setUnsavedDeletedAnswers(prev => [...prev, formattedAnswer]);
  };

  const removeDeleted = documentId => {
    removeDeletedAnswer({ documentId, queryId, questionId });
  };

  const restoreDeleted = documentId => {
    restoreDeletedAnswer({ documentId, queryId, questionId });
  };

  const saveDeleted = () => {
    if (unsavedDeletedAnswers.length) {
      addDeletedAnswer({ answers: unsavedDeletedAnswers, queryId, questionId });
    }
    setUnsavedDeletedAnswers([]);
  };

  return {
    error,
    addToDeleted,
    saveDeleted,
    removeDeleted,
    restoreDeleted,
    deletedAnswers
  };
}
