import React from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./PageBodyTemplate.scss";

const PageBodyTemplate = props => {
  return (
    <div className="page-body-template">
      <div className="page-body-template__header">
        <div
          className={`page-body-template__header-container ${
            props.extraSpace
              ? "page-body-template__header-container--extra"
              : ""
          }`}
        >
          <div className="page-body-template__header-heading">
            {props.breadcrumbs && (
              <div className="page-body-template__header-breadcrumbs">
                {props.breadcrumbs.map((crumb, index) => (
                  <>
                    {index > 0 && <span>{">"}</span>}
                    <span key={index}>
                      <Link
                        className={
                          "page-body-template__header-breadcrumbs-item"
                        }
                        to={crumb.linkTo}
                        state={crumb.linkTo?.state}
                      >
                        {crumb.label}
                      </Link>
                    </span>
                  </>
                ))}
              </div>
            )}
            {props.tagline && (
              <div
                className="page-body-template__header-tagline"
                role="tagline"
              >
                {props.tagline}
              </div>
            )}
            {props.title && (
              <h1 className="page-body-template__header-text">{props.title}</h1>
            )}
            {props.shortDescription && (
              <div className="page-body-template__header-short-description">
                {props.shortDescription}
              </div>
            )}
            {props.subheading && (
              <h1 className="page-body-template__header-subhead">
                {props.subheading}
              </h1>
            )}
          </div>
          <div className="page-body-template__header-actions">
            {props.actions}
          </div>
          <div className="page-body-template__header-horizontal-bar"></div>
        </div>
      </div>

      <div className="page-body-template__main">{props.children}</div>
    </div>
  );
};

PageBodyTemplate.defaultProps = {};

PageBodyTemplate.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      linkTo: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  tagline: PropTypes.any,
  title: PropTypes.string,
  subheading: PropTypes.string,
  shortDescription: PropTypes.string,
  actions: PropTypes.node,
  children: PropTypes.node
};

export default PageBodyTemplate;
