import React, { useCallback } from "react";

import CircleIndicator from "@components/atoms/CircleIndicator";
import CheckBox from "@components/atoms/DataTableCheckbox";
import DataTableDropdownMultiselect from "@components/atoms/DataTableDropdownMultiselect/DataTableDropdownMultiselect";
import DataTableDropdownSelect from "@components/atoms/DataTableDropdownSelect/DataTableDropdownSelect";
import DropdownDotMenu from "@components/atoms/DropdownDotMenu";
import Icon from "@components/atoms/Icon/Icon";
import LoaderSpinner from "@components/atoms/LoaderSpinner/LoaderSpinner";

// Collection of common utilities to be used in conjunction with DataTable
export function useDataTable(listOfItems) {
  // accessor(item) => returns the property to compare against 'id'
  const findItem = useCallback(
    (id, accessor) =>
      listOfItems.find(item =>
        accessor ? accessor(item) === id : id === item.id
      ),
    [listOfItems]
  );

  const createColumn = useCallback(
    ({ Header = "", accessor, className, handleSortChange, ...others }) => {
      return {
        Header,
        accessor,
        className: className || accessor,
        onSortedChange: handleSortChange ?? (() => { }),
        ...others
      };
    },
    []
  );

  const createColumnForStatus = useCallback(
    ({ Header = "", accessor, ...others }) => {
      return {
        Header,
        accessor,
        className: "status",
        width: 50,
        fixedWidth: true,
        Cell: ({ cell }) => {
          return (
            <>
              {cell.value ? (
                <CircleIndicator colorScheme={cell.value} />
              ) : (
                <></>
              )}
            </>
          );
        },
        ...others
      };
    },
    []
  );

  const createColumnForCheckBox = useCallback(
    ({ accessor, onClickHandler, ...others }) => {
      return {
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <CheckBox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        accessor,
        className: "check-box",
        width: 50,
        Cell: ({ row }) => (
          <div>
            <CheckBox {...row.getToggleRowSelectedProps()} />
          </div>
        ),
        ...others
      };
    },
    []
  );

  // onClickHandler: ({ menuItem, cell })
  /**
   * @param {string} accessor - accessor to the actions menu
   * @param {function} onClickHandler - function to handle the click event on the menu item - receives object with menuItem and cell
   * @param {function} displayCell - function to determine whether to display the cell
   * @param {object} others - other props to be passed to the column
   * @returns {object} - returns the column object
   * @description - creates a column for the kebab menu with actions - data should be an array of objects with the following shape:
   * {
   *  label: string
   *  name: string
   *  key: string
   * }
   */
  const createColumnForDropdownMenu = useCallback(
    ({
      accessor: accessorToActionsMenu,
      onClickHandler: menuItemClickHandler,
      displayCell = _ => true,
      ...others
    }) => {
      return {
        accessor: accessorToActionsMenu,
        className: "actions",
        width: 50,
        fixedWidth: true,
        Cell: ({ cell }) => {
          return (
            <>
              {cell.value?.length && displayCell(cell.row.id) ? (
                <DropdownDotMenu
                  data-testid="test__moreActionBtn"
                  menuItems={cell.value}
                  onMenuItemClick={menuItem =>
                    menuItemClickHandler({ menuItem, cell })
                  }
                />
              ) : (
                <></>
              )}
            </>
          );
        },
        ...others
      };
    },
    []
  );

  // onClickHandler: ({ cell })
  const createColumnForSingleAction = useCallback(
    ({ accessor: accessorToAction, onClickHandler, ...others }) => {
      return {
        accessor: accessorToAction || "action",
        className: "single-action",
        clickableCell: onClickHandler,
        ...others
      };
    },
    []
  );

  const createColumnForSingleActionIcon = useCallback(
    ({
      accessor: accessorToIconName,
      onClickHandler,
      displayCell = _ => true,
      title,
      value,
      ...others
    }) => {
      const extraProps = {
        title
      };
      return {
        accessor: accessorToIconName || "action",
        className: "single-action",
        clickableCell: onClickHandler,
        width: 50,
        fixedWidth: true,
        Cell: ({ cell }) =>
          displayCell(cell.row.id) && (
            <i {...extraProps} className="material-icons">
              {cell.value ?? value}
            </i>
          ),
        ...others
      };
    },
    []
  );

  // This is a custom filter UI for selecting a unique option from a list
  const SelectColumnFilter = useCallback(
    (label, allOptionLabel, filterExtras = [], onChangeHandler) => {
      return ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
        // Calculate the options for filtering using the preFilteredRows
        const optionsSet = new Set([...filterExtras]);
        preFilteredRows.forEach(row => {
          optionsSet.add(row.values[id]);
        });
        const options = [...optionsSet.values()];

        return (
          <>
            <DataTableDropdownSelect
              label={label}
              items={[
                { label: allOptionLabel, name: "All", value: undefined },
                ...options.map(o => ({ name: o, value: o }))
              ]}
              onChange={onChangeHandler ?? setFilter}
              value={filterValue}
            />
          </>
        );
      };
    },
    []
  );

  const SelectMultiselectColumnFilter = useCallback(
    (label, allOptionLabel, filterExtras = [], onChangeHandler = undefined) => {
      return ({
        column: { filterValue, setFilter, preFilteredRows, id, accessValue }
      }) => {
        // Calculate the options for filtering using the preFilteredRows
        const optionsSet = new Set([...filterExtras]);
        preFilteredRows.forEach(row => {
          if (accessValue) {
            optionsSet.add(accessValue(row.values[id]));
          } else {
            optionsSet.add(row.values[id]);
          }
        });
        const options = [...optionsSet.values()].filter(o => o);
        return (
          <>
            <DataTableDropdownMultiselect
              label={label}
              items={[
                {
                  label: allOptionLabel,
                  name: "All",
                  value: undefined,
                  isChecked: true
                },
                ...options.map(o => ({
                  name: o,
                  value: o,
                  isChecked: o.isChecked
                }))
              ]}
              value={filterValue}
              onChange={onChangeHandler ?? setFilter}
            />
          </>
        );
      };
    },
    []
  );

  const createColumnForFilter = useCallback(
    ({
      accessor,
      className,
      filterLabel,
      allOptionLabel,
      filterExtras = [],
      onChangeHandler,
      ...others
    }) => {
      return {
        Header: "",
        accessor,
        className,
        Filter: SelectColumnFilter(
          filterLabel,
          allOptionLabel,
          filterExtras,
          onChangeHandler
        ),
        filter: "includes",
        disableFilters: false,
        ...others
      };
    },
    [SelectColumnFilter]
  );

  const createColumnForMultiselectFilter = useCallback(
    ({
      accessor,
      className,
      filterLabel,
      allOptionLabel,
      filterExtras = [],
      onChangeHandler,
      ...others
    }) => {
      return {
        Header: "",
        accessor,
        className,
        Filter: SelectMultiselectColumnFilter(
          filterLabel,
          allOptionLabel,
          filterExtras,
          onChangeHandler
        ),
        filter: "includesSome",
        disableFilters: false,
        ...others
      };
    },
    [SelectMultiselectColumnFilter]
  );

  const createColumnForDownloading = useCallback(
    ({ accessor, className, onClickHandler, downloadingRowIds, ...others }) => {
      return {
        accessor,
        clickableCell: onClickHandler,
        className,
        Cell: ({ cell }) => {
          if (downloadingRowIds.find(id => id === cell.row.original.id)) {
            return <LoaderSpinner diameter={21} colorStyle="accent" />;
          }
          return (
            <Icon
              name="download"
              designStyle="material-icons"
              fillStyle="filled"
            />
          );
        },
        ...others
      };
    },
    []
  );

  const caseInsensitiveSortType = useCallback((rowA, rowB, columnId) => {
    const safeCol = row => row.values[columnId] || "";
    return safeCol(rowA).localeCompare(safeCol(rowB), { sensitivity: "base" });
  }, []);

  const caseInsensitiveEmptyLastSortType = useCallback(
    (rowA, rowB, columnId) => {
      if (!rowA.values[columnId]) {
        return 1;
      }
      if (!rowB.values[columnId]) {
        return -1;
      }
      return caseInsensitiveSortType(rowA, rowB, columnId);
    },
    [caseInsensitiveSortType]
  );

  return {
    findItem,
    createColumn,
    createColumnForDropdownMenu,
    createColumnForDownloading,
    createColumnForSingleAction,
    createColumnForSingleActionIcon,
    createColumnForStatus,
    createColumnForFilter,
    createColumnForCheckBox,
    createColumnForMultiselectFilter,
    caseInsensitiveSortType,
    caseInsensitiveEmptyLastSortType
  };
}
