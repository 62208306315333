import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import DataViewSwitcher from "@components/molecules/DataViewSwitcher";
import PageTemplate from "@components/templates/PageTemplate";

import AddProjectActionButton from "../AddProjectActionButton/AddProjectActionButton";
import "./ProjectsDashboardTemplate.scss";

function ProjectsDashboardTemplate(props) {
  const { t } = useTranslation();
  const {
    projectsPanelView,
    viewType,
    projectsTableView,
    filtersView,
    onChangeViewType,
    showNoResultsFoundMessage,
    enableAddProject
  } = props;

  const addProjectButton = enableAddProject ? <AddProjectActionButton /> : null;

  return (
    <PageTemplate
      header={{
        title: t("common:ui.dashboard.title"),
        actions: addProjectButton,
        content: (
          <div className="ot-projects-dashboard-template">
            <div className="ot-projects-dashboard-template__top-bar">
              <div className="ot-projects-dashboard-template__top-bar--left">
                <DataViewSwitcher
                  onChangeView={onChangeViewType}
                  selectedView={viewType}
                />
              </div>
              <div className="ot-projects-dashboard-template__top-bar--right">
                {filtersView}
              </div>
            </div>
            {showNoResultsFoundMessage && (
              <div className="ot-projects-dashboard-template__no-results-msg">
                {t("common:ui.dashboard.noProjects.label")}
              </div>
            )}
            {!showNoResultsFoundMessage && (
              <div>
                {viewType === "grid" ? projectsPanelView : projectsTableView}
              </div>
            )}
          </div>
        )
      }}
      other={{ secondaryNavDisabled: true }}
    />
  );
}

ProjectsDashboardTemplate.propTypes = {
  filtersView: PropTypes.element,
  projectsPanelView: PropTypes.element,
  projectsTableView: PropTypes.element,
  viewType: DataViewSwitcher.propTypes.selectedView,
  showNoResultsFoundMessage: PropTypes.bool,
  onChangeViewType: DataViewSwitcher.propTypes.onChangeView,
  enableAddProject: PropTypes.bool
};

export default ProjectsDashboardTemplate;
