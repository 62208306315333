import React, { useCallback, useEffect, useRef, useState } from "react";

import Loading from "@components/molecules/Loading/Loading";
import ProjectAccessModal from "@components/molecules/ProjectAccessModal/ProjectAccessModal";
import VisualisationImage from "@components/molecules/VisualisationImage";
import VisualisationWorkbook from "@components/molecules/VisualisationWorkbook/VisualisationWorkbook";

import { getHiddenClass } from "../../../pages/FinalPackage/FinalPackage";
import "./VisualisationTemplate.scss";

const VisualisationTemplate = props => {
  const {
    project,
    config,
    isUploadDisabled,
    user,
    documents,
    workbook,
    onDrop,
    onEditTitle,
    onDelete,
    loading,
    uploading,
    showProjectAccessModal,
    membershipErrorMessage,
    handleUploadPermission,
    handleCloseModal
  } = props;

  return (
    <div className="visualisation">
      {loading || uploading ? (
        <Loading
          type={"secondary"}
          message={`${loading ? "Loading" : ""} ${
            uploading ? "Uploading" : ""
          } Visualisation`}
        />
      ) : (
        <>
          <div
            className={`visualisation__container visualisation${getHiddenClass(
              documents?.left,
              user
            )}`}
          >
            <VisualisationImage
              config={config?.image}
              user={user}
              onDrop={onDrop}
              onDelete={onDelete}
              onEditTitle={onEditTitle}
              document={documents?.left}
              positionNum={"left"}
              isUploadDisabled={isUploadDisabled.current}
              handleUploadPermission={handleUploadPermission}
            />
          </div>
          <div
            className={`visualisation__container visualisation${getHiddenClass(
              documents?.mid,
              user
            )}`}
          >
            <VisualisationImage
              config={config?.image}
              user={user}
              document={documents?.mid}
              onDrop={onDrop}
              onDelete={onDelete}
              onEditTitle={onEditTitle}
              positionNum={"mid"}
              isUploadDisabled={isUploadDisabled.current}
              handleUploadPermission={handleUploadPermission}
            />
          </div>
          <div
            className={`visualisation__container visualisation${getHiddenClass(
              documents?.right,
              user
            )}`}
          >
            <VisualisationImage
              config={config?.image}
              user={user}
              document={documents?.right}
              onDrop={onDrop}
              onDelete={onDelete}
              onEditTitle={onEditTitle}
              positionNum={"right"}
              isUploadDisabled={isUploadDisabled.current}
              handleUploadPermission={handleUploadPermission}
            />
          </div>
          {user.isHostUser && (
            <div className="workbook">
              <VisualisationWorkbook
                config={config?.workbook}
                user={user}
                document={workbook}
                onDrop={onDrop}
                onDelete={onDelete}
                positionNum={"workbook"}
                isUploadDisabled={isUploadDisabled.current}
                handleUploadPermission={handleUploadPermission}
              />
            </div>
          )}
          <ProjectAccessModal
            visibility={showProjectAccessModal}
            message={membershipErrorMessage}
            handleClose={handleCloseModal}
            handleOutsideClick={handleCloseModal}
          />
        </>
      )}
    </div>
  );
};

export default VisualisationTemplate;
