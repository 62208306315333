export const manageHostUsersConstants = {
  GET_HOST_USERS_REQUEST: "GET_HOST_USERS_REQUEST",
  GET_HOST_USERS_SUCCESS: "GET_HOST_USERS_SUCCESS",
  GET_HOST_USERS_FAILURE: "GET_HOST_USERS_FAILURE",

  GET_HOST_GROUPS_REQUEST: "GET_HOST_GROUPS_REQUEST",
  GET_HOST_GROUPS_SUCCESS: "GET_HOST_GROUPS_SUCCESS",
  GET_HOST_GROUPS_FAILURE: "GET_HOST_GROUPS_FAILURE",

  GET_HOST_ROLES_REQUEST: "GET_HOST_ROLES_REQUEST",
  GET_HOST_ROLES_SUCCESS: "GET_HOST_ROLES_SUCCESS",
  GET_HOST_ROLES_FAILURE: "GET_HOST_ROLES_FAILURE",

  ADD_HOST_USER_REQUEST: "ADD_HOST_USER_REQUEST",
  ADD_HOST_USER_SUCCESS: "ADD_HOST_USER_SUCCESS",
  ADD_HOST_USER_FAILURE: "ADD_HOST_USER_FAILURE",

  UPDATE_HOST_USER_REQUEST: "UPDATE_HOST_USER_REQUEST",
  UPDATE_HOST_USER_SUCCESS: "UPDATE_HOST_USER_SUCCESS",
  UPDATE_HOST_USER_FAILURE: "UPDATE_HOST_USER_FAILURE",

  CLEAR_ERROR: "CLEAR_ERROR_HOST_USERS",
  RESET: "RESET_HOST_USERS",
  CLEAR_ADD_HOST_USER_FLAG: "CLEAR_ADD_HOST_USER_FLAG",
  CLEAR_UPDATE_HOST_USER_FLAG: "CLEAR_UPDATE_HOST_USER_FLAG",

  DOWNLOAD_BULK_USERS_UPLOAD_TEMPLATE_REQUEST:
    "DOWNLOAD_BULK_USERS_UPLOAD_TEMPLATE_REQUEST",
  DOWNLOAD_BULK_USERS_UPLOAD_TEMPLATE_SUCCESS:
    "DOWNLOAD_BULK_USERS_UPLOAD_TEMPLATE_SUCCESS",
  DOWNLOAD_BULK_USERS_UPLOAD_TEMPLATE_FAILURE:
    "DOWNLOAD_BULK_USERS_UPLOAD_TEMPLATE_FAILURE",

  UPLOAD_BULK_USERS_REQUEST: "UPLOAD_BULK_USERS_REQUEST",
  UPLOAD_BULK_USERS_SUCCESS: "UPLOAD_BULK_USERS_SUCCESS",
  UPLOAD_BULK_USERS_FAILURE: "UPLOAD_BULK_USERS_FAILURE",

  GET_ENGAGEMENT_TYPES_WITH_TAGS_REQUEST:
    "GET_ENGAGEMENT_TYPES_WITH_TAGS_REQUEST",
  GET_ENGAGEMENT_TYPES_WITH_TAGS_SUCCESS:
    "GET_ENGAGEMENT_TYPES_WITH_TAGS_SUCCESS",
  GET_ENGAGEMENT_TYPES_WITH_TAGS_FAILURE:
    "GET_ENGAGEMENT_TYPES_WITH_TAGS_FAILURE",

  GET_ENGAGEMENT_TYPES_REQUEST: "GET_ENGAGEMENT_TYPES_REQUEST",
  GET_ENGAGEMENT_TYPES_SUCCESS: "GET_ENGAGEMENT_TYPES_SUCCESS",
  GET_ENGAGEMENT_TYPES_FAILURE: "GET_ENGAGEMENT_TYPES_FAILURE",

  CREATE_TAG_REQUEST: "CREATE_TAG_REQUEST",
  CREATE_TAG_SUCCESS: "CREATE_TAG_SUCCESS",
  CREATE_TAG_FAILURE: "CREATE_TAG_FAILURE",

  UPDATE_TAG_REQUEST: "UPDATE_TAG_REQUEST",
  UPDATE_TAG_SUCCESS: "UPDATE_TAG_SUCCESS",
  UPDATE_TAG_FAILURE: "UPDATE_TAG_FAILURE",

  DELETE_TAG_REQUEST: "DELETE_TAG_REQUEST",
  DELETE_TAG_SUCCESS: "DELETE_TAG_SUCCESS",
  DELETE_TAG_FAILURE: "DELETE_TAG_FAILURE"
};
