import React, { useEffect, useMemo, useRef, useState } from "react";

import PropTypes from "prop-types";
import useOnClickOutside from "use-onclickoutside";

import Icon from "@components/atoms/Icon";

import "./Dropdown.scss";
import DropdownItem from "./DropdownItem";

const ITEM_HEIGHT_PX = 40;

const Dropdown = props => {
  const {
    name,
    dropdownItems,
    handleClickSelection,
    showDropdowns,
    getItemId,
    leftIcon,
    disabled = false,
    position = "left",
    capacity = 8
  } = props;
  const dropdownRef = useRef(null);
  const [showItems, setShowItems] = useState(false);
  const [menuHeight, setMenuHeight] = useState(0);

  useEffect(() => {
    setShowItems(showDropdowns);
  }, [showDropdowns]);

  const toggleDropdown = () => {
    setShowItems(prevState => !prevState);
  };

  const onClickSelection = (value, index) => () => {
    setShowItems(false);
    handleClickSelection(value, index);
  };
  useOnClickOutside(dropdownRef, () => {
    setShowItems(false);
  });

  const renderClassName = useMemo(
    () =>
      `dropdown__items ${
        showItems ? "dropdown__items--show" : "dropdown__items--hide"
      } dropdown__items--${position}`,
    [showItems, position]
  );

  const renderStyle = useMemo(() => {
    if (position === "top") {
      const totalHeight = ITEM_HEIGHT_PX * capacity;
      setMenuHeight(totalHeight);
      return {
        top: `-${menuHeight}px`,
        height: `${menuHeight}px`
      };
    }
  }, [capacity, menuHeight, position]);

  return (
    <div className="dropdown" data-testid="test__dropdown" ref={dropdownRef}>
      <button
        className="dropdown__label button-default"
        onClick={toggleDropdown}
        disabled={disabled}
      >
        <span className="dropdown__label-iconLeft">
          <Icon name={leftIcon} />
        </span>
        {name}
        {dropdownItems.length > 1 && (
          <i className="material-icons">arrow_drop_down</i>
        )}
      </button>
      <div
        className={renderClassName}
        onBlur={toggleDropdown}
        style={renderStyle}
      >
        {dropdownItems.map((item, index) => {
          const id = getItemId?.(index) ?? item.id;
          if (item.name !== name) {
            return (
              <span key={item.id}>
                <DropdownItem
                  id={id}
                  key={item.id}
                  item={item}
                  icon={item.icon?.iconName}
                  active={item.active}
                  onClickSelection={onClickSelection(item.name, index)}
                />
              </span>
            );
          }
        })}
      </div>
    </div>
  );
};

Dropdown.defaultProps = {
  showDropdowns: false,
  leftIcon: ""
};

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  showDropdowns: PropTypes.bool,
  handleClickSelection: PropTypes.func.isRequired,
  dropdownItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ),
  getItemId: PropTypes.func,
  leftIcon: PropTypes.string,
  capacity: PropTypes.number,
  position: PropTypes.oneOf(["left", "right", "top"])
};

export default Dropdown;
