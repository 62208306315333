import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageProjectActions } from "@shared/actions";

export function useUpdateMilestones() {
  const dispatch = useDispatch();
  const manageProject = useSelector(state => state.manageProject);
  const [isUpdatingProjectMilestones, setIsUpdatingProjectMilestones] =
    useState(false);

  useEffect(() => {
    setIsUpdatingProjectMilestones(manageProject.loading);
  }, [manageProject.loading]);

  const addOrUpdateProjectMilestones = useCallback(
    (updatedProject, updatedMilestones) => {
      dispatch(
        manageProjectActions.addOrUpdateProjectMilestones(
          updatedProject,
          updatedMilestones
        )
      );
    },
    [dispatch]
  );

  return { addOrUpdateProjectMilestones, isUpdatingProjectMilestones };
}
