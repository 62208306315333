import React, { useEffect } from "react";

import PropTypes from "prop-types";

import { handleFreezeScrollPosition } from "@app/helpers/componentHelpers";

import LoaderSpinner from "@components/atoms/LoaderSpinner";

import "./PageOverlay.scss";

const PageOverlay = props => {
  const { isVisible, spinnerSize, text } = props;

  useEffect(() => {
    handleFreezeScrollPosition(isVisible);
  }, [isVisible]);

  return (
    <>
      {isVisible && (
        <div className="page-overlay">
          <LoaderSpinner diameter={spinnerSize} />
          <p>{text}</p>
        </div>
      )}
    </>
  );
};

PageOverlay.defaultProps = {
  isVisible: false,
  spinnerSize: "100",
  text: ""
};

PageOverlay.propTypes = {
  isVisible: PropTypes.bool,
  spinnerSize: PropTypes.string,
  text: PropTypes.string
};

export default PageOverlay;
