import { ToDoType, manageProjectConstants } from "../constants";
import { manageProjectService } from "../services";

export const manageProjectActions = {
  getSupportedMimesForDocuments,
  getProjectMessages,
  getProjectTodos,
  updateProjectTodoStatus,
  updateProjectTodo,
  getProjectNotes,
  getProjectMilestones,
  getProjectDocuments,
  getProjectDocument,
  getProjectFoldersAndDocuments,
  addOrUpdateProjectMilestones,
  resetUpdateProjectTodoStatusFlag,
  resetUpdateProjectTodoFlag,
  updateProjectMessageStatus,
  sendProjectMessage,
  assignProjectTodo,
  createProjectNote,
  updateProjectNote,
  reorderProjectMembers,
  clearError,
  resetDocument,
  resetUpdateNote,
  resetCreateNote,
  resetAssignProjectTodo,
  reset,
  setCurrentProject,
  resetCurrentProject,
  getProjectById
};

function getSupportedMimesForDocuments() {
  return dispatch => {
    dispatch(request());

    manageProjectService.getSupportedMimesForDocuments().then(
      mimesList => {
        dispatch(success(mimesList));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectConstants.GET_SUPPORTED_MIMES_FOR_DOCUMENT_REQUEST
    };
  }
  function success(mimesList) {
    return {
      type: manageProjectConstants.GET_SUPPORTED_MIMES_FOR_DOCUMENT_SUCCESS,
      mimesList
    };
  }
  function failure(error) {
    return {
      type: manageProjectConstants.GET_SUPPORTED_MIMES_FOR_DOCUMENT_FAILURE,
      error
    };
  }
}

function getProjectFoldersAndDocuments(project) {
  return dispatch => {
    dispatch(request());

    manageProjectService.getProjectFoldersAndDocuments(project).then(
      projectFoldersAndDocuments => {
        dispatch(success(projectFoldersAndDocuments));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectConstants.GET_PROJECT_FOLDERS_AND_DOCUMENTS_REQUEST
    };
  }
  function success(projectFoldersAndDocuments) {
    return {
      type: manageProjectConstants.GET_PROJECT_FOLDERS_AND_DOCUMENTS_SUCCESS,
      projectFoldersAndDocuments
    };
  }
  function failure(error) {
    return {
      type: manageProjectConstants.GET_PROJECT_FOLDERS_AND_DOCUMENTS_FAILURE,
      error
    };
  }
}

function reorderProjectMembers(project, members) {
  return dispatch => {
    dispatch(request());

    manageProjectService.reorderProjectMembers(project, members).then(
      members => {
        dispatch(success(members));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectConstants.REORDER_PROJECT_MEMBER_REQUEST
    };
  }
  function success(members) {
    return {
      type: manageProjectConstants.REORDER_PROJECT_MEMBER_SUCCESS,
      members
    };
  }
  function failure(error) {
    return {
      type: manageProjectConstants.REORDER_PROJECT_MEMBER_FAILURE,
      error
    };
  }
}

function sendProjectMessage(message) {
  return dispatch => {
    dispatch(request());

    manageProjectService.sendProjectMessage(message).then(
      message => {
        dispatch(success(message));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectConstants.SEND_PROJECT_MESSAGE_REQUEST
    };
  }
  function success(message) {
    return {
      type: manageProjectConstants.SEND_PROJECT_MESSAGE_SUCCESS,
      message
    };
  }
  function failure(error) {
    return {
      type: manageProjectConstants.SEND_PROJECT_MESSAGE_FAILURE,
      error
    };
  }
}

function createProjectNote(note) {
  return dispatch => {
    dispatch(request());

    manageProjectService.createProjectNote(note).then(
      note => {
        dispatch(success(note));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectConstants.CREATE_PROJECT_NOTE_REQUEST
    };
  }
  function success(note) {
    return {
      type: manageProjectConstants.CREATE_PROJECT_NOTE_SUCCESS,
      note
    };
  }
  function failure(error) {
    return {
      type: manageProjectConstants.CREATE_PROJECT_NOTE_FAILURE,
      error
    };
  }
}

function updateProjectNote(note) {
  return dispatch => {
    dispatch(request());

    manageProjectService.updateProjectNote(note).then(
      note => {
        dispatch(success(note));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectConstants.UPDATE_PROJECT_NOTE_REQUEST
    };
  }
  function success(note) {
    return {
      type: manageProjectConstants.UPDATE_PROJECT_NOTE_SUCCESS,
      note
    };
  }
  function failure(error) {
    return {
      type: manageProjectConstants.UPDATE_PROJECT_NOTE_FAILURE,
      error
    };
  }
}

function assignProjectTodo(todo) {
  return dispatch => {
    dispatch(request());

    manageProjectService.assignProjectTodo(todo).then(
      todo => {
        dispatch(success(todo));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectConstants.ASSIGN_PROJECT_TODO_REQUEST
    };
  }
  function success(todo) {
    return {
      type: manageProjectConstants.ASSIGN_PROJECT_TODO_SUCCESS,
      todo
    };
  }
  function failure(error) {
    return {
      type: manageProjectConstants.ASSIGN_PROJECT_TODO_FAILURE,
      error
    };
  }
}

function getProjectDocument(document) {
  return dispatch => {
    dispatch(request());

    manageProjectService.getProjectDocument(document).then(
      document => {
        dispatch(success(document));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectConstants.GET_PROJECT_DOCUMENT_REQUEST
    };
  }
  function success(document) {
    return {
      type: manageProjectConstants.GET_PROJECT_DOCUMENT_SUCCESS,
      document
    };
  }
  function failure(error) {
    return {
      type: manageProjectConstants.GET_PROJECT_DOCUMENT_FAILURE,
      error
    };
  }
}

function getProjectDocuments({ id: projectId }) {
  return dispatch => {
    dispatch(request());

    manageProjectService.getProjectDocuments({ id: projectId }).then(
      documents => {
        dispatch(success(documents));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectConstants.GET_PROJECT_DOCUMENTS_REQUEST
    };
  }
  function success(documents) {
    return {
      type: manageProjectConstants.GET_PROJECT_DOCUMENTS_SUCCESS,
      documents
    };
  }
  function failure(error) {
    return {
      type: manageProjectConstants.GET_PROJECT_DOCUMENTS_FAILURE,
      error
    };
  }
}

function getProjectMessages(project) {
  return dispatch => {
    dispatch(request());

    manageProjectService.getProjectMessages(project).then(
      messages => {
        dispatch(success(messages));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectConstants.GET_PROJECT_MESSAGES_REQUEST
    };
  }
  function success(messages) {
    return {
      type: manageProjectConstants.GET_PROJECT_MESSAGES_SUCCESS,
      messages
    };
  }
  function failure(error) {
    return {
      type: manageProjectConstants.GET_PROJECT_MESSAGES_FAILURE,
      error
    };
  }
}

function getProjectTodos(project, type = ToDoType.ASSIGNED_TO_ME) {
  return dispatch => {
    dispatch(request());

    manageProjectService.getProjectTodos(project, type).then(
      todos => {
        dispatch(success(todos));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectConstants.GET_PROJECT_TODOS_REQUEST
    };
  }
  function success(todos) {
    return {
      type: manageProjectConstants.GET_PROJECT_TODOS_SUCCESS,
      todos
    };
  }
  function failure(error) {
    return {
      type: manageProjectConstants.GET_PROJECT_TODOS_FAILURE,
      error
    };
  }
}

function getProjectNotes(project) {
  return dispatch => {
    dispatch(request());

    manageProjectService.getProjectNotes(project).then(
      notes => {
        dispatch(success(notes));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectConstants.GET_PROJECT_NOTES_REQUEST
    };
  }
  function success(notes) {
    return {
      type: manageProjectConstants.GET_PROJECT_NOTES_SUCCESS,
      notes
    };
  }
  function failure(error) {
    return {
      type: manageProjectConstants.GET_PROJECT_NOTES_FAILURE,
      error
    };
  }
}

function getProjectMilestones(project) {
  return dispatch => {
    dispatch(request());

    manageProjectService.getProjectMilestones(project).then(
      milestones => {
        dispatch(success(milestones));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectConstants.GET_PROJECT_MILESTONES_REQUEST
    };
  }
  function success(milestones) {
    return {
      type: manageProjectConstants.GET_PROJECT_MILESTONES_SUCCESS,
      milestones
    };
  }
  function failure(error) {
    return {
      type: manageProjectConstants.GET_PROJECT_MILESTONES_FAILURE,
      error
    };
  }
}

function addOrUpdateProjectMilestones(project, milestones) {
  return dispatch => {
    dispatch(request());

    manageProjectService.addOrUpdateProjectMilestones(project, milestones).then(
      milestones => {
        dispatch(success(milestones));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectConstants.ADD_OR_UPDATE_PROJECT_MILESTONES_REQUEST
    };
  }
  function success(milestones) {
    return {
      type: manageProjectConstants.ADD_OR_UPDATE_PROJECT_MILESTONES_SUCCESS,
      milestones
    };
  }
  function failure(error) {
    return {
      type: manageProjectConstants.ADD_OR_UPDATE_PROJECT_MILESTONES_FAILURE,
      error
    };
  }
}

function updateProjectTodoStatus(todo) {
  return dispatch => {
    dispatch(request());

    manageProjectService.updateProjectTodoStatus(todo).then(
      todo => {
        dispatch(success(todo));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectConstants.UPDATE_PROJECT_TODO_STATUS_REQUEST
    };
  }
  function success(todo) {
    return {
      type: manageProjectConstants.UPDATE_PROJECT_TODO_STATUS_SUCCESS,
      todo
    };
  }
  function failure(error) {
    return {
      type: manageProjectConstants.UPDATE_PROJECT_TODO_STATUS_FAILURE,
      error
    };
  }
}

function updateProjectTodo(todo) {
  return dispatch => {
    dispatch(request());

    manageProjectService.updateProjectTodo(todo).then(
      updatedTodo => {
        dispatch(success(updatedTodo));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectConstants.UPDATE_PROJECT_TODO_REQUEST
    };
  }
  function success(todo) {
    return {
      type: manageProjectConstants.UPDATE_PROJECT_TODO_SUCCESS,
      todo
    };
  }
  function failure(error) {
    return {
      type: manageProjectConstants.UPDATE_PROJECT_TODO_FAILURE,
      error
    };
  }
}

function updateProjectMessageStatus(message) {
  return dispatch => {
    dispatch(request());

    manageProjectService.updateProjectMessageStatus(message).then(
      message => {
        dispatch(success(message));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectConstants.UPDATE_PROJECT_MESSAGE_REQUEST
    };
  }
  function success(message) {
    return {
      type: manageProjectConstants.UPDATE_PROJECT_MESSAGE_SUCCESS,
      message
    };
  }
  function failure(error) {
    return {
      type: manageProjectConstants.UPDATE_PROJECT_MESSAGE_FAILURE,
      error
    };
  }
}

function resetUpdateProjectTodoFlag() {
  return { type: manageProjectConstants.RESET_UPDATE_PROJECT_TODO_FLAG };
}

function resetUpdateProjectTodoStatusFlag() {
  return { type: manageProjectConstants.RESET_UPDATE_PROJECT_TODO_STATUS_FLAG };
}

function clearError() {
  return { type: manageProjectConstants.CLEAR_ERROR };
}

function resetDocument() {
  return { type: manageProjectConstants.RESET_DOCUMENT };
}

function resetUpdateNote() {
  return { type: manageProjectConstants.RESET_UPDATE_PROJECT_NOTE };
}

function resetCreateNote() {
  return { type: manageProjectConstants.RESET_CREATE_PROJECT_NOTE };
}

function resetAssignProjectTodo() {
  return { type: manageProjectConstants.RESET_ASSIGN_PROJECT_TODO };
}

function reset() {
  return { type: manageProjectConstants.RESET };
}

function setCurrentProject(project) {
  return {
    type: manageProjectConstants.SET_CURRENT_PROJECT,
    currentProject: project
  };
}

function resetCurrentProject() {
  return { type: manageProjectConstants.RESET_CURRENT_PROJECT };
}

function getProjectById(projectId) {
  return dispatch => {
    dispatch(request());

    manageProjectService.getProjectById(projectId).then(
      project => {
        dispatch(success(project));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: manageProjectConstants.GET_PROJECT_REQUEST
    };
  }
  function success(updatedProject) {
    return {
      type: manageProjectConstants.GET_PROJECT_SUCCESS,
      updatedProject
    };
  }
  function failure(error) {
    return {
      type: manageProjectConstants.GET_PROJECT_FAILURE,
      error
    };
  }
}
