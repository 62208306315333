import React, { useCallback, useMemo, useRef, useState } from "react";

import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import SearchBar from "@components/molecules/SearchBar";

const SearchTextInput = props => {
  const {
    shouldDebounce,
    handleChange: onChange,
    disabled,
    defaultValue,
    placeholder
  } = props;
  const { t } = useTranslation();
  const [value, setValue] = useState(defaultValue ?? "");
  const { label, className } = props;
  const inputRef = useRef(null);

  const debounceCalculate = useMemo(() => debounce(onChange, 300), [onChange]);

  const handleChange = useCallback(
    value => {
      setValue(value);
      if (!onChange) return;

      if (shouldDebounce) {
        debounceCalculate(value);
      } else {
        onChange(value);
      }
    },
    [debounceCalculate, onChange, shouldDebounce]
  );

  const handleClick = () => {
    inputRef.current.focus();
  };

  const handleClear = () => {
    setValue("");
    onChange?.("");
  };

  return (
    <div onClick={handleClick}>
      <SearchBar
        label={label}
        value={value}
        handleChange={handleChange}
        handleClear={handleClear}
        disabled={disabled}
        className={className}
        ref={inputRef}
        placeholder={placeholder ?? t("common:ui.forms.search.label")}
      />
    </div>
  );
};

SearchTextInput.defaultProps = {
  shouldDebounce: false
};

SearchTextInput.propTypes = {
  handleChange: PropTypes.func,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  shouldDebounce: PropTypes.bool,
  placeholder: PropTypes.string
};

export default SearchTextInput;
