import { systemConstants } from "../constants";
import { authHeader } from "../helpers";
import { handleErrorResponse, handleResponse } from "./";

const baseURL = systemConstants.serverURL;

export const manageClientsService = {
  getClientsFromCRM,
  getClient,
  getAllClientsWithEngagementsAndProjects,
  getClients,
  addClient,
  updateClient
};

function getClientsFromCRM() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`${baseURL}/api/tools/crm/clients`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getClient(user) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`${baseURL}/api/clients/${user.clientId}`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getClients() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`${baseURL}/api/clients`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function getAllClientsWithEngagementsAndProjects({
  userId,
  includeAllStatuses
}) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${baseURL}/api/clients/${
      userId ?? "me"
    }/AllProjectsWithClientAndEngagment${
      includeAllStatuses ? "?allStatuses=true" : ""
    }`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function addClient(client) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(client)
  };

  return fetch(`${baseURL}/api/clients`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}

function updateClient(client) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(client)
  };
  return fetch(`${baseURL}/api/clients/${client.id}`, requestOptions)
    .then(handleResponse)
    .catch(handleErrorResponse);
}
