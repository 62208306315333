import React, { useCallback, useEffect } from "react";

import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";

import { systemConstants } from "../../constants";
import "./dragNDrop.scss";

const DocumentsDragNDrop = props => {
  const { onDropRejected, openFileSelection, handleDrop } = props;
  const appInsights = useAppInsightsContext();
  const onDrop = useCallback(acceptedFiles => {
    handleDrop(acceptedFiles);
  }, [handleDrop]);

  const getSupportedMimes = () => {
    if (props.supportedMimes) {
      const supportedMimeList = props.supportedMimes.map(mime => `.${mime}`);
      return supportedMimeList.join();
    } else {
      const supportedMimeList = systemConstants.mimes.document.map(
        mime => `.${mime}`
      );
      return supportedMimeList.join();
    }
  };

  const onRejected = useCallback(
    fileRejections => {
      if (onDropRejected) {
        appInsights?.trackTrace({
          message: JSON.stringify({
            errorMessage: "Unsupported file type blocked.",
            filesProperty: fileRejections.map(({ file }) => ({
              name: file.name,
              type: file.type
            }))
          })
        });
        onDropRejected(fileRejections);
      }
    },
    [appInsights, onDropRejected]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    onDropRejected: onRejected,
    accept: getSupportedMimes(),
    maxFiles: props.maxFiles || 0
  });

  useEffect(() => {
    if (openFileSelection) {
      open();
    }
  }, [openFileSelection, open]);

  return (
    <div
      className={
        "document-drag-n-drop " +
        (props.className ? props.className : "") +
        (props.error ? " drag-n-drop-error" : "")
      }
      {...getRootProps()}
    >
      <input className="document-drag-n-drop-text-input" {...getInputProps()} />
      <>
        <div className="document-drag-n-drop-text-box">
          <i className="material-icons">cloud_upload</i>
          <div className="document-drag-n-drop-text-main">
            {props.message ? (
              <>
                <span className="document-drag-n-drop-text-message">
                  {props.message}
                </span>
                <span className="document-drag-n-drop-text-link">
                  {props.linkName}
                </span>
              </>
            ) : (
              <>
                Drag and drop attachments or{" "}
                <span className="document-drag-n-drop-text-link">
                  browse for files to upload
                </span>
              </>
            )}
          </div>
        </div>
      </>
    </div>
  );
};

DocumentsDragNDrop.propTypes = {
  handleDrop: PropTypes.func.isRequired,
  onDropRejected: PropTypes.func,
  maxFiles: PropTypes.number,

  // NB: 'openFileSelection' property is only reacted to on the rising edge (i.e. FALSY to TRUE)
  // Employ a timeout or reset it to false immediately one cycle after triggering such that when you
  //  get the expected behaviour of opening the dialog when programmatically set to TRUE.
  //  Generally you'd only need this property when opening from a button which is placed elsewhere
  //  For more info: https://react-dropzone.js.org/#file-dialog-cancel-callback
  openFileSelection: PropTypes.bool
};

export default DocumentsDragNDrop;
