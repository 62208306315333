export const searchConstants = {
  GET_SEARCH_RESULTS_REQUEST: "GET_SEARCH_RESULTS_REQUEST",
  GET_SEARCH_RESULTS_SUCCESS: "GET_SEARCH_RESULTS_SUCCESS",
  GET_SEARCH_RESULTS_FAILURE: "GET_SEARCH_RESULTS_FAILURE",

  GET_REQUEST_ASSIGNED_TO_USERS_REQUEST:
    "GET_REQUEST_ASSIGNED_TO_USERS_REQUEST",
  GET_REQUEST_ASSIGNED_TO_USERS_SUCCESS:
    "GET_REQUEST_ASSIGNED_TO_USERS_SUCCESS",
  GET_REQUEST_ASSIGNED_TO_USERS_FAILURE:
    "GET_REQUEST_ASSIGNED_TO_USERS_FAILURE",

  GET_SEARCH_TAGS_REQUEST: "GET_SEARCH_TAGS_REQUEST",
  GET_SEARCH_TAGS_SUCCESS: "GET_SEARCH_TAGS_SUCCESS",
  GET_SEARCH_TAGS_FAILURE: "GET_SEARCH_TAGS_FAILURE",

  SET_SEARCH_STRING: "SET_SEARCH_STRING",

  RESET_REQUEST_ASSIGNED_TO_USERS: "RESET_REQUEST_ASSIGNED_TO_USERS",
  RESET_SEARCH_STRING: "RESET_SEARCH_STRING",
  RESET_SEARCH_RESULTS: "RESET_SEARCH_RESULTS"
};
