import { isEqual, pick } from "lodash";

import { systemConstants } from "@shared/constants/systemConstants";

import { utilities } from "./utilities";

/**
 * Checks if newValue is equal to the initial value that was translatable,
 * which updates the milestone object accordingly
 * @param {Object} param
 * @param {Object} param.milestone //the milestone object associated with the name that changed
 * @param {String|Object} param.newValue //the name the changed
 * @param {Object} param.i18n
 * @param {String} param.contextKey
 * @returns {Object} newValue
 */
function formattedNameAndPropertiesForMilestone({
  milestone,
  newValue,
  i18n,
  contextKey
}) {
  const newProperties = structuredClone(milestone?.properties) ?? {};
  //this checks if its coming from dropdown
  if (typeof newValue === "object") {
    //assigns key if it exists
    if (newValue?.value) {
      if (!newProperties.i18nOptions) {
        newProperties.i18nOptions = {
          context: contextKey
        };
      }
      return {
        name: newValue?.value,
        properties: newProperties
      };
    }
    return {
      name: newValue?.name
    };
  }

  const milestoneType = systemConstants.project.milestone.type;
  let updatedMilestoneName = newValue;
  if (
    milestone &&
    [milestoneType.start, milestoneType.end].includes(milestone.type) &&
    newProperties.i18nOptions
  ) {
    const milestoneDict = {
      [milestoneType.start]: "ui.milestone.start",
      [milestoneType.end]: "ui.milestone.end"
    };

    //attempts to translate milestone name
    const milestoneName = utilities.getProjectMilestoneName(milestone, i18n);

    //if translated version === new name value, assign back the key so it can translated
    if (newValue === milestoneName) {
      updatedMilestoneName = milestoneDict[milestone.type];
    } else {
      //shouldn't be translated anymore
      delete newProperties.i18nOptions;
    }
  }

  return {
    name: updatedMilestoneName,
    properties: newProperties
  };
}

const updateStatus = {
  added: "ADDED",
  updated: "UPDATED",
  remove: "REMOVE"
};

function buildMilestoneDifferences(oldMilestones, newMilestones) {
  const addedMilestones = newMilestones
    .filter(m => !m.id)
    .map(m => ({
      ...m,
      update: updateStatus.added
    }));

  const removedMilestones = oldMilestones
    .filter(milestone => {
      return !newMilestones.some(fm => fm.id === milestone.id);
    })
    .map(m => ({
      ...m,
      update: updateStatus.remove
    }));

  const modifiedMilestones = newMilestones
    .filter(milestone => {
      const oldMilestone = oldMilestones.find(m => m.id === milestone.id);
      if (!oldMilestone) {
        return false;
      }
      oldMilestone.date = utilities.endOfDay(oldMilestone.date).toISOString();
      milestone.date = utilities.endOfDay(milestone.date).toISOString();
      if (
        oldMilestone.status ===
        systemConstants.project.milestone.status.inProgress
      ) {
        oldMilestone.status = systemConstants.project.milestone.status.open;
      }
      const fieldsToCompare = ["date", "name", "status"];
      return !isEqual(
        pick(milestone, fieldsToCompare),
        pick(oldMilestone, fieldsToCompare)
      );
    })
    .map(m => ({
      ...m,
      update: updateStatus.updated
    }));

  return [...addedMilestones, ...removedMilestones, ...modifiedMilestones];
}

function formatMilestone({ oldMilestone, milestone, i18n, contextKey }) {
  return {
    ...milestone,
    status: milestone.completed
      ? systemConstants.project.milestone.status.completed
      : systemConstants.project.milestone.status.open,
    date: utilities.endOfDay(milestone.date).toISOString(),
    ...formattedNameAndPropertiesForMilestone({
      milestone: oldMilestone,
      newValue: milestone.name,
      contextKey,
      i18n
    })
  };
}

function updateProjectStatusAndDates(project, milestones) {
  const startMilestone = milestones.find(
    m => m.type === systemConstants.project.milestone.type.start
  );
  const endMilestone = milestones.find(
    m => m.type === systemConstants.project.milestone.type.end
  );

  if (
    milestones.every(
      m => m.status === systemConstants.project.milestone.status.completed
    )
  ) {
    project.status = systemConstants.project.milestone.status.completed;
  } else {
    project.status = systemConstants.project.milestone.status.open;
  }
  project.plannedCompletionDate = endMilestone.date;
  project.startDate = startMilestone.date;
}

const renderStartLabel = (configuration, t) => {
  const context = configuration?.i18nContextKey;
  const milestoneConfig = configuration?.milestones?.labels;

  if (milestoneConfig?.enabled) {
    return t(milestoneConfig.names?.[0]?.key, { context });
  }
  return t("common:ui.milestone.start", { context });
};

const renderEndLabel = (configuration, t) => {
  const context = configuration?.i18nContextKey;
  const milestoneConfig = configuration?.milestones?.labels;
  if (milestoneConfig?.enabled) {
    return t(milestoneConfig.names?.[milestoneConfig.names?.length - 1]?.key, {
      context
    });
  }
  return t("common:ui.milestone.end", { context });
};

function formatMilestoneDropdownItem(milestone, date, t) {
  return {
    name: `${t(milestone?.name)} | ${date} | ${
      milestone.status === systemConstants.project.milestone.status.completed
        ? systemConstants.project.milestone.status.completed
        : systemConstants.project.milestone.status.inComplete
    }`,
    value: milestone
  };
}

export const milestoneUtilities = {
  buildMilestoneDifferences,
  formatMilestone,
  updateProjectStatusAndDates,
  renderStartLabel,
  renderEndLabel,
  formatMilestoneDropdownItem,
  forTest: {
    formattedNameAndPropertiesForMilestone
  }
};
