import React from "react";

import CheckBox from "../CheckBox/CheckBox";
import "./DataTableCheckbox.scss";

const DataTableCheckbox = React.forwardRef(
  ({ indeterminate, checked, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <CheckBox
          ref={resolvedRef}
          {...rest}
          value={checked}
          styleVariant="primary"
          textWeight="normal"
          indeterminate={indeterminate}
        />
      </>
    );
  }
);

export default DataTableCheckbox;
