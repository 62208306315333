import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react";

import PropTypes from "prop-types";

import websheetWizardUtilities from "@shared/helpers/websheetWizard/websheetWizardUtilities";
import { useCleaningTemplate } from "@shared/hooks/useCleaningTemplate";

import WizardWebsheet from "../../WizardWebsheet";

export const ROW_SELECTION_TYPE = {
  SINGLE: "single",
  MULTIPLE: "multiple"
};

const RowSelectionWizard = React.forwardRef((props, fwdRef) => {
  const {
    data: cleaningDataObj,
    rowSelectionType,
    templateHeaders,
    onSheetLoading
  } = props;
  const data = useMemo(
    () => cleaningDataObj?.websheet ?? [],
    [cleaningDataObj?.websheet]
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const hotTableComponent = useRef(null);
  const {
    getRowSelectionTemplate,
    setFirstPredictedRow,
    setUnnecessaryPredictedRows
  } = useCleaningTemplate(cleaningDataObj.savedState);

  useEffect(() => {
    if (rowSelectionType === ROW_SELECTION_TYPE.SINGLE) {
      const rowToSelect = websheetWizardUtilities.suggestRowHeader({
        sheetData: data[0],
        templateHeaders,
        rowOffset: 1,
        maxRowsToSearch: websheetWizardUtilities.MAX_ROWS_TO_SEARCH
      });
      const selectedRows = websheetWizardUtilities.createRange(
        rowToSelect.index + 1
      );
      setSelectedRows(selectedRows);
      setFirstPredictedRow(selectedRows.length);
    }
  }, [
    data,
    rowSelectionType,
    templateHeaders,
    setSelectedRows,
    setFirstPredictedRow
  ]);

  useEffect(() => {
    if (rowSelectionType === ROW_SELECTION_TYPE.MULTIPLE) {
      const rowsToSelect = websheetWizardUtilities
        .suggestUnnecessaryRows({ sheetData: data[0] })
        .map(({ rowIndex }) => rowIndex);
      const uniqueRowsToSelect = [...new Set(rowsToSelect)].sort(
        (a, b) => a - b
      );
      setSelectedRows(uniqueRowsToSelect);
      setUnnecessaryPredictedRows(uniqueRowsToSelect);
    }
  }, [data, rowSelectionType, setSelectedRows, setUnnecessaryPredictedRows]);

  const setHotTableComponent = useCallback(node => {
    hotTableComponent.current = node;
  }, []);

  const inputClickHandler = useCallback(
    (row, input) => {
      if (rowSelectionType === ROW_SELECTION_TYPE.SINGLE && input.checked) {
        // set the selectedRows to contain indexes from 0 to row
        setSelectedRows(websheetWizardUtilities.createRange(row + 1));
      } else {
        const currentRow = row + 1;
        if (input.checked) {
          setSelectedRows(prev => [...prev, currentRow]);
        } else {
          setSelectedRows(prev => prev.filter(r => r !== currentRow));
        }
      }
    },
    [rowSelectionType]
  );

  const isChecked = useCallback(
    row => {
      if (rowSelectionType === ROW_SELECTION_TYPE.SINGLE) {
        // return true only if the last element in selectedRows is the same as the row
        return selectedRows[selectedRows.length - 1] === row;
      } else {
        return selectedRows.includes(row + 1);
      }
    },
    [selectedRows, rowSelectionType]
  );

  // create the custom radio button for the row header
  // and append classNames to row header and col header
  const afterGetRowHeader = useCallback(
    (hotRowIdx, TH) => {
      TH.classList.add("wizard-websheet__row__header");
      TH.innerHTML = "";

      if (hotRowIdx > data[0].data.length - 2) {
        return;
      }

      const rowHeader = websheetWizardUtilities.makeSelectableRowHeader({
        type:
          rowSelectionType === ROW_SELECTION_TYPE.SINGLE ? "radio" : "checkbox",
        name: `row-${hotRowIdx}`,
        value: hotRowIdx,
        isChecked: isChecked(hotRowIdx)
      });
      TH.appendChild(rowHeader);

      const handler = e => inputClickHandler(hotRowIdx, e.target);
      rowHeader.addEventListener("click", handler);
      // NB: we don't need to removeEventListener because HOT removes it from the DOM when not needed
    },
    [data, isChecked, inputClickHandler, rowSelectionType]
  );

  const afterGetColHeader = (_, TH) => {
    TH.className += "wizard-websheet__col__header";
  };

  const highlightSelectedRow = useCallback(
    (row, _) => {
      return isChecked(row) ? "selected-rows" : "";
    },
    [isChecked]
  );

  const colWidths = useMemo(() => {
    return websheetWizardUtilities.getColWidthsForWizard(data[0]);
  }, [data]);

  const canSubmit = useCallback(() => {
    return (
      rowSelectionType === ROW_SELECTION_TYPE.MULTIPLE ||
      (rowSelectionType === ROW_SELECTION_TYPE.SINGLE &&
        selectedRows.length > 0)
    );
  }, [rowSelectionType, selectedRows]);

  const getDataForSubmission = useCallback(() => {
    if (!hotTableComponent?.current?.hotInstance || !canSubmit()) {
      return {};
    }

    const getRowsToDelete = selectedRows => {
      if (rowSelectionType === ROW_SELECTION_TYPE.SINGLE) {
        return selectedRows.slice(1);
      }
      return selectedRows;
    };

    const { websheet } = websheetWizardUtilities.removeRowsFromSheet(data, {
      sheetName: data[0].sheet,
      rowsToDelete: getRowsToDelete(selectedRows)
    });
    const result = {
      websheet,
      savedState: {
        ...cleaningDataObj.savedState,
        ...getRowSelectionTemplate({
          firstRow: data[0].data[selectedRows[selectedRows.length - 1]],
          data,
          selectedRows
        })
      }
    };

    return result;
  }, [
    canSubmit,
    data,
    selectedRows,
    cleaningDataObj.savedState,
    getRowSelectionTemplate,
    rowSelectionType
  ]);

  useImperativeHandle(
    fwdRef,
    () => ({
      getDataForSubmission,
      canSubmit
    }),
    [getDataForSubmission, canSubmit]
  );

  return (
    <WizardWebsheet
      currentSheetName={data?.[0]?.sheet}
      data={data}
      firstRowHeader={true}
      rowHeaders={true}
      additionalClassName={highlightSelectedRow}
      hotTableComponent={setHotTableComponent}
      afterGetRowHeader={afterGetRowHeader}
      afterGetColHeader={afterGetColHeader}
      colWidths={colWidths}
      onSheetLoading={onSheetLoading}
    />
  );
});

RowSelectionWizard.propTypes = {
  data: PropTypes.array.isRequired,
  rowSelectionType: PropTypes.oneOf([
    ROW_SELECTION_TYPE.MULTIPLE,
    ROW_SELECTION_TYPE.SINGLE
  ]).isRequired,
  onSheetLoading: PropTypes.func
};

export default RowSelectionWizard;
