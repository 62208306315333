import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { manageClientEngagementsActions } from "@shared/actions";

export function useGetEngagementManagers(user) {
  const dispatch = useDispatch();
  const [engagementManagers, setEngagementManagers] = useState([]);

  const manageClientEngagements = useSelector(
    state => state.manageClientEngagements
  );

  useEffect(() => {
    setEngagementManagers(manageClientEngagements.engagementManagers);
  }, [manageClientEngagements.engagementManagers]);

  useEffect(() => {
    dispatch(manageClientEngagementsActions.getEngagementManagers(user));
  }, [dispatch, user]);

  return { engagementManagers };
}
