import React from "react";

import { useTranslation } from "react-i18next";

import DropdownMenu from "@shared-components/dropdown/DropdownMenu";

import "./DropdownPagination.scss";

const DropdownPagination = props => {
  const { t } = useTranslation();

  return (
    <div className="data-pagination">
      <div className="data-pagination__dropdown">
        {props.currentPageIndex >= 0
          ? t("common:ui.pagination.pageNumber", {
              pageNumber: props.currentPageIndex + 1
            })
          : props.currentPage}
        <DropdownMenu
          boxWidth="small"
          type="text"
          label={
            <i className="material-icons data-pagination__dropdown data-pagination__dropdownicon">
              arrow_drop_down
            </i>
          }
          menuItems={props.pages}
          handleMenuItemClick={props.handlePageSelection}
        />
      </div>
      <i
        onClick={props.handlePageCountDecrement}
        className="material-icons data-pagination--nav"
      >
        chevron_left
      </i>
      <i
        onClick={props.handlePageCountIncrement}
        className="material-icons data-pagination--nav"
      >
        chevron_right
      </i>
    </div>
  );
};

export default DropdownPagination;
