import React, { useCallback } from "react";

import InsightsCard from "@components/molecules/InsightsCard";

const formatInsightLayout = (insight, layoutType) => {
  const layout = insight?.layouts?.[layoutType] ?? insight?.layout;
  return {
    i: "" + insight.id,
    x: layout?.x ?? 0,
    y: layout?.y ?? 0,
    w: layout?.w ?? 2,
    h: layout?.h ?? 1,
    static: true
  };
};

export function useGetInsightsBoardsCards() {
  const getBoardInsightsCards = useCallback(({ insightsData }) => {
    if (insightsData?.length > 0) {
      const cards = insightsData?.map(insight => (
        <div key={insight.id} data-insights-card={insight.id}>
          <InsightsCard
            id={insight.id}
            title={insight.name}
            table={insight.table}
            graph={insight.graph}
            columns={insight.columns}
            displayFormats={insight.displayFormats}
          />
        </div>
      ));

      const layouts = {
        sm: insightsData.map(insight => formatInsightLayout(insight, "sm")),
        lg: insightsData.map(insight => formatInsightLayout(insight, "lg"))
      };
      return { cards, layouts };
    }
  }, []);

  return { getBoardInsightsCards };
}
