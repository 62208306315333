import React, { useCallback } from "react";

import PropTypes from "prop-types";
import _DatePicker from "react-date-picker";
import { useTranslation } from "react-i18next";

import { removeDuplicateAndArchivedDocuments } from "@app/helpers/documents";

import FileAttachmentList from "@components/molecules/FileAttachmentList";
import BoxTemplate from "@components/templates/BoxTemplate";

import "./RequestAttachmentSummaryBox.scss";

const RequestAttachmentSummaryBox = props => {
  const { t } = useTranslation();
  const renderAttachments = useCallback(() => {
    const displayAttachments =
      props.responses?.flatMap(({ attachments }) => attachments) ?? [];

    const combineAttachments = removeDuplicateAndArchivedDocuments([
      ...displayAttachments,
      ...(props.attachments ?? [])
    ]);

    if (combineAttachments?.length) {
      return (
        <BoxTemplate
          boxClassName="request-details-box"
          title={t("requests:requests.ui.requestAttachmentSummary")}
        >
          <FileAttachmentList
            attachments={combineAttachments}
            onFileDownloadClicked={props.onFileDownloadClicked}
          />
        </BoxTemplate>
      );
    }
  }, [props.attachments, props.onFileDownloadClicked, props.responses, t]);

  return <>{renderAttachments()}</>;
};

RequestAttachmentSummaryBox.defaultProps = {};

RequestAttachmentSummaryBox.propTypes = {
  responses: PropTypes.array,
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      status: PropTypes.string,
      name: PropTypes.string
    })
  ),
  onFileDownloadClicked: PropTypes.func
};
export default RequestAttachmentSummaryBox;
