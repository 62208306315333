import React from "react";

import PropTypes from "prop-types";

import "./PillIndicator.scss";

const PillIndicator = props => {
  const { label, shape, color } = props;
  return (
    <div
      className={`pill-indicator pill-indicator--${color} pill-indicator--${shape}`}
    >
      {label}
    </div>
  );
};
PillIndicator.propTypes = {
  label: PropTypes.string,
  shape: PropTypes.oneOf(["round"])
};
export default PillIndicator;
