import React, { useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import * as yup from "yup";

import { systemConstants } from "@shared/constants/systemConstants";
import { useGetProjectMembers, useUpdateQuery } from "@shared/hooks";

import Form from "@components/atoms/Form";
import ModalForm from "@components/molecules/ModalForm";

import "./ReassignQuery.scss";

const statusTypes = systemConstants.project.queries.status;

const ReassignQuery = props => {
  const { onQueryReassigned, query, document, t } = props;
  const { members } = useGetProjectMembers(props.project);
  const { updatedQuery, updateQueryError, updateQuery } = useUpdateQuery();
  const [assignedUser, setAssignedUser] = useState(null);
  const [updatedQueryUserId, setUpdatedQueryUserId] = useState(null);
  const files = useMemo(
    () => [
      {
        isDeleted: false,
        isNew: false,
        name: document?.name,
        projectId: query?.projectId
      }
    ],
    [document?.name, query]
  );

  useEffect(() => {
    if (updatedQuery?.userId !== updatedQueryUserId && assignedUser) {
      setUpdatedQueryUserId(updatedQuery.userId);
      onQueryReassigned(assignedUser);
    }
  }, [updatedQuery, updatedQueryUserId, assignedUser, onQueryReassigned]);

  const handleSubmit = data => {
    const reassignedUser = data.assignedTo.value;
    updateQuery({
      ...props.query,
      reassign: true,
      assignedTo: reassignedUser,
      status: statusTypes.open,
      files
    });
    setAssignedUser(reassignedUser);
  };

  const membersForDropdown = React.useMemo(() => {
    const clientUsers =
      members.clientUsers.map(user => ({
        name: user.name,
        value: user
      })) || [];
    const hostUsers =
      members.hostUsers.map(user => ({
        name: user.name,
        value: user
      })) || [];
    return [...clientUsers, ...hostUsers];
  }, [members.clientUsers, members.hostUsers]);

  const defaultAssignedTo = React.useMemo(
    () => query.assignedTo,
    [query.assignedTo]
  );

  return (
    <>
      <ModalForm
        boxClassName="ot-reassign-query"
        title={t("requests:requests.ui.reassignRequest.modalTitle")}
        handleCancel={props.onCancel}
        handleSubmit={handleSubmit}
        submitLabel={t(
          "requests:requests.ui.reassignRequest.actions.submitLabel"
        )}
        cancelLabel={t(
          "requests:requests.ui.reassignRequest.actions.cancelLabel"
        )}
        yupSchema={yup
          .object({
            assignedTo: yup
              .object()
              .required(
                t(
                  "requests:requests.ui.reassignRequest.validation.assignedToRequired.errorMessage"
                )
              )
          })
          .required()}
        defaultValues={{
          assignedTo: {
            name: defaultAssignedTo?.name,
            value: defaultAssignedTo
          }
        }}
        errorMessage={updateQueryError || null}
      >
        <Form.Dropdown
          label={t(
            "requests:requests.ui.reassignRequest.fields.assignTo.label"
          )}
          name="assignedTo"
          items={membersForDropdown}
        />
      </ModalForm>
    </>
  );
};

ReassignQuery.defaultProps = {};

ReassignQuery.propTypes = {
  query: PropTypes.any.isRequired,
  project: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onQueryReassigned: PropTypes.func.isRequired
};

export default withTranslation()(ReassignQuery);
