import React from "react";

import PropTypes from "prop-types";

import Nav from "@components/atoms/Nav";

import "./NavBar.scss";

const NavBar = props => {
  const {
    navItems,
    subNavLeftPosition,
    setSubNavActive,
    handleNavigate,
    currentPath,
    navigationStyle
  } = props;

  return (
    <div className="navbar" data-testid="testNavBar">
      <Nav
        navItems={navItems}
        subNavLeftPosition={subNavLeftPosition}
        setSubNavActive={setSubNavActive}
        handleNavigate={handleNavigate}
        currentPath={currentPath}
        navigationStyle={navigationStyle}
      />
    </div>
  );
};

NavBar.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      isActive: PropTypes.bool,
      link: PropTypes.string,
      state: PropTypes.object
    })
  ),
  subNavLeftPosition: PropTypes.string,
  setSubNavActive: PropTypes.func,
  handleNavigate: PropTypes.func,
  currentPath: PropTypes.string,
  navigationStyle: PropTypes.string
};

export default NavBar;
