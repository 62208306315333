import React from "react";

import moment from "moment";

import { systemConstants } from "@shared/constants";

const milestoneStatus = systemConstants.project.milestone.status;
const milestoneType = systemConstants.project.milestone.type;

function getBase64OfFile(file, setFunction) {
  const readFile = new FileReader();
  readFile.readAsDataURL(file);

  readFile.onload = e => {
    setFunction(e.target.result);
  };
}

function getDefaultClientLogo() {
  return null;
}

/**
 * @param {Object[]} array1
 * @param {Object[]} array2
 * @returns {Object[]}
 * @description - This function returns a merged array of objects from array1 and array2 by id.
 * If an object in array1 has the same id as an object in array2, the object in array2 will be used.
 */
function getUniqueArrayOfObjectsById(array1, array2) {
  const idMap = new Map();
  const mergedArray = [];

  for (const obj of array2) {
    idMap.set(obj.id, obj);
  }

  for (const obj of array1) {
    const newObj = idMap.get(obj.id);
    if (newObj) {
      mergedArray.push(newObj);
      idMap.delete(obj.id);
    } else {
      mergedArray.push(obj);
    }
  }

  const remainingObjects = [...idMap.values()];
  mergedArray.push(...remainingObjects);

  return mergedArray;
}

/**
 * @param {String} date1 - Format: YYYY-MM-DD
 * @param {String} date2 - Format: YYYY-MM-DD
 * @returns {Number} - Number of days between date1 and date2
 * @description - This function returns the number of days between date1 and date2.
 * If date1 is greater than date2, it returns a negative number.
 * If date1 is less than date2, it returns a positive number.
 */
function getDateDifference(date1, date2) {
  const msInADay = 24 * 60 * 60 * 1000;
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  const timeDifference = d2 - d1;
  if (timeDifference < 0) {
    return Math.floor(timeDifference / msInADay);
  }
  return Math.ceil(timeDifference / msInADay);
}

const getProjectMilestoneProgressBarStatus = project => {
  const currentMilestone = project.currentMilestone;
  const currentDate = new Date();
  const dayDifference = getDateDifference(currentDate, currentMilestone?.date);
  if (project.status === systemConstants.project.status.completed) {
    return `complete`;
  }
  if (dayDifference < 0) {
    return "behind";
  }
  return "inprogress";
};

function createPagination({ list, setPagination }) {
  const count = Math.ceil(
    list.length / systemConstants.pagination.itemCountPerPage
  );
  const items = [];
  for (let number = 0; number < count; number++) {
    const data = list.slice(
      number * systemConstants.pagination.itemCountPerPage,
      number * systemConstants.pagination.itemCountPerPage +
        systemConstants.pagination.itemCountPerPage
    );
    items.push({ number: number + 1, name: `Page ${number + 1}`, data });
  }

  setPagination(prev => ({
    ...prev,
    currentPage: "Page 1",
    currentPageIndex: 0,
    pageCount: count,
    pages: items
  }));
}

function handlePageSelection(pageName, pagination, setFunction) {
  const index = pagination.pages.findIndex(page => page.name === pageName);
  setFunction({
    ...pagination,
    currentPage: pageName,
    currentPageIndex: index
  });
}

function handlePageCountIncrement(pagination, setFunction) {
  const index = pagination.currentPageIndex;
  if (index < pagination.pages.length - 1) {
    setFunction({
      ...pagination,
      currentPage: `Page ${index + 2}`,
      currentPageIndex: index + 1
    });
  }
}

function handlePageCountDecrement(pagination, setFunction) {
  const index = pagination.currentPageIndex;

  if (index > 0) {
    setFunction({
      ...pagination,
      currentPage: `Page ${index}`,
      currentPageIndex: index - 1
    });
  }
}

function handleResetPagination(pagination, setFunction) {
  setFunction({
    ...pagination,
    currentPage: "Page 1",
    currentPageIndex: 0
  });
}

function clearExternalLinkObjectFromSessionLastKnownPath() {
  const getLastKnownPath = JSON.parse(sessionStorage.getItem("lastKnownPath"));
  if (
    getLastKnownPath &&
    getLastKnownPath.state &&
    getLastKnownPath.state.externalLinkObject
  ) {
    const updatedPath = {
      ...getLastKnownPath,
      state: { ...getLastKnownPath.state, externalLinkObject: null }
    };
    sessionStorage.setItem("lastKnownPath", JSON.stringify(updatedPath));
  }
}

function convertRemToPx(rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

function convertPxToVW(px) {
  return px * (100 / document.documentElement.clientWidth);
}

function convertRemToVW(rem) {
  const inPixels = convertRemToPx(rem);
  return convertPxToVW(inPixels);
}

function calmpViewWidth(viewWidth, minViewWidth, maxViewWidth) {
  return maxViewWidth < viewWidth
    ? maxViewWidth + "vw"
    : viewWidth < minViewWidth
    ? minViewWidth + "vw"
    : viewWidth + "vw";
}

function getFormattedErrorMessage(message) {
  return `${message}- ${formatDate(Date.now())}`;
}

// https://www.freecodecamp.org/news/javascript-debounce-example/
function debounce(callback, timeoutMs = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback.apply(this, args);
    }, timeoutMs);
  };
}

// https://stackoverflow.com/a/27078401
function throttle(callback, limit) {
  var waiting = false;
  return (...args) => {
    if (!waiting) {
      callback.apply(this, args);
      waiting = true;
      setTimeout(() => {
        waiting = false;
      }, limit);
    }
  };
}

function orderProjectsByStartDate(projects) {
  projects.sort(function (a, b) {
    return new Date(b.startDate) - new Date(a.startDate);
  });
  return projects;
}

function getProjectMilestoneData(fromProject) {
  const project = structuredClone(fromProject);
  project.percentage = 0;
  project.isCompleted = false;
  if (project.status == systemConstants.project.status.completed) {
    project.isCompleted = true;
    project.percentage = 100;
  }
  if (project.milestones?.length) {
    project.daysForNextMilestone = moment(
      project.upcomingMilestone
        ? project.upcomingMilestone.date
        : project.plannedCompletionDate
    )
      .startOf("day")
      .diff(moment().startOf("day"), "days");
    if (project.daysForNextMilestone >= 0) {
      project.milestoneStatus = systemConstants.project.status.onTrack;
    } else {
      project.milestoneStatus = systemConstants.project.status.behind;
    }

    project.in =
      Math.abs(project.daysForNextMilestone) === 1 || 0
        ? `${Math.abs(project.daysForNextMilestone)} DAY`
        : `${Math.abs(project.daysForNextMilestone)} DAYS`;
    let completedMilestones = 0;
    project.milestones.forEach(milestone => {
      if (
        milestone.status == systemConstants.project.milestone.status.completed
      ) {
        completedMilestones += 1;
      }
    });
    if (project.status != systemConstants.project.status.completed) {
      project.percentage =
        (completedMilestones / project.milestones.length) * 100;
    }
  }
  return project;
}

function getProjectMilestoneName(milestone, i18n) {
  if (!milestone?.properties?.i18nOptions) {
    return milestone?.name;
  }
  const {
    name,
    properties: { i18nOptions }
  } = milestone;

  return i18n?.t(name, i18nOptions) ?? milestone?.name;
}

function getLastMilestoneDate(milestones) {
  const lastMilestoneDate =
    milestones?.length > 0 ? milestones[milestones.length - 1].date : undefined;
  if (lastMilestoneDate) {
    return lastMilestoneDate;
  }
}

function getFirstMilestoneDate(milestones) {
  const firstMilestoneDate =
    milestones?.length > 0 ? milestones[0].date : undefined;
  if (firstMilestoneDate) {
    return firstMilestoneDate;
  }
}

function filterExcessiveMilestones(milestones) {
  let filteredMilestones = [];
  if (milestones.length <= 20) {
    return milestones;
  } else {
    let lastCompletedMilestone;
    let inprogressMilestoneIndex;
    let maximumMilestones = 19;
    milestones.forEach((m, i) => {
      if (
        m?.status === milestoneStatus.inProgress ||
        m?.type === milestoneType.start ||
        m?.type === milestoneType.end
      ) {
        inprogressMilestoneIndex = milestoneStatus.inProgress ? i : null;
        filteredMilestones.push(m);
      } else if (m?.status === milestoneStatus.completed) {
        if (!inprogressMilestoneIndex) {
          lastCompletedMilestone = m;
          maximumMilestones = 18;
        } else if (
          i > inprogressMilestoneIndex &&
          filteredMilestones.length < maximumMilestones
        ) {
          filteredMilestones.push(m);
        }
      } else if (
        m?.status === milestoneStatus.open &&
        filteredMilestones.length < maximumMilestones
      ) {
        filteredMilestones.push(m);
      }
    });
    if (lastCompletedMilestone) {
      filteredMilestones.splice(1, 0, lastCompletedMilestone);
    }
  }
  return filteredMilestones;
}

function bytesToFileSizeString(size) {
  if (size === 0) return "0kB";
  var i = Math.floor(Math.log(size) / Math.log(1024));
  return (
    (size / Math.pow(1024, i)).toFixed(2) * 1 +
    " " +
    ["B", "kB", "MB", "GB", "TB"][i]
  );
}

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

function formatDateOnly(date) {
  if (!date) return null;

  const outDate = new Date(safeUtcDate(date));

  outDate.setHours(0, 0, 0, 0);
  return [
    outDate.getFullYear(),
    padTo2Digits(outDate.getMonth() + 1),
    padTo2Digits(outDate.getDate())
  ].join("-");
}

function safeUtcDate(date) {
  return date ? moment(date).endOf("day").utc() : null;
}

function endOfDay(date) {
  if (!date) {
    return null;
  }

  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999); //sets to end of day
  return endOfDay;
}

function safeDate(date) {
  return date ? new Date(date) : null;
}

function isStandardMilestone(milestone) {
  return milestone.type === systemConstants.project.milestone.type.standard;
}

function isStartMilestone(milestone) {
  return milestone.type === systemConstants.project.milestone.type.start;
}

function isEndMilestone(milestone) {
  return milestone.type === systemConstants.project.milestone.type.end;
}

function subtractDays(date, numberOfDays) {
  return new Date(date - numberOfDays * 24 * 60 * 60 * 1000);
}

function convertObjectValuesToLowerCase(obj) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, value.toLowerCase()])
  );
}

function formatDate(date) {
  if (!date) {
    return;
  }
  return new Date(date)
    .toLocaleString("en-AU", {
      dateStyle: "long",
      timeStyle: "short",
      hour12: false
    })
    .split(" at ")
    .join(" ");
}

function formatDrilldownSubtitle(string) {
  if (!string) {
    return;
  }
  return string.toString().replace(/[()]/g, "");
}

function sortBy(props) {
  return data =>
    (data ?? []).sort((a, b) =>
      a[props].localeCompare(b[props], { sensitivity: "base" })
    );
}

function isKeywordInclude(origin, keyword) {
  return origin?.toUpperCase().includes(keyword?.toUpperCase());
}

function translatableError(i18nKey) {
  const e = new Error(i18nKey);
  e.i18nKey = i18nKey;
  e.isTranslatable = true;
  return e;
}

function isValidYearString(year) {
  const parsedYear = parseInt(year, 10);
  return 1900 <= parsedYear && parsedYear <= 4000;
}

export const utilities = {
  getBase64OfFile,
  getDefaultClientLogo,
  createPagination,
  handlePageSelection,
  handlePageCountIncrement,
  handlePageCountDecrement,
  handleResetPagination,
  clearExternalLinkObjectFromSessionLastKnownPath,
  convertRemToPx,
  convertPxToVW,
  convertRemToVW,
  calmpViewWidth,
  getFormattedErrorMessage,
  debounce,
  formatDate,
  formatDateOnly,
  throttle,
  orderProjectsByStartDate,
  getProjectMilestoneData,
  getProjectMilestoneName,
  getUniqueArrayOfObjectsById,
  getDateDifference,
  getProjectMilestoneProgressBarStatus,
  getLastMilestoneDate,
  getFirstMilestoneDate,
  filterExcessiveMilestones,
  bytesToFileSizeString,
  safeUtcDate,
  endOfDay,
  safeDate,
  isStandardMilestone,
  isStartMilestone,
  isEndMilestone,
  subtractDays,
  convertObjectValuesToLowerCase,
  formatDrilldownSubtitle,
  sortBy,
  isKeywordInclude,
  translatableError,
  isValidYearString
};
