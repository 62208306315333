import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import PropTypes from "prop-types";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { SizeMe } from "react-sizeme";

import { utilities } from "@shared/helpers";
import { breakpoints, colWidth } from "@shared/helpers/gridLayoutHelper";
import { useAuthUser } from "@shared/hooks";

import InfiniteScrollFooter from "@components/molecules/InfiniteScrollFooter";

import ProjectsPanel from "./ProjectPanel/ProjectPanel";
import "./ProjectPanelList.scss";
import { generateLayout } from "./projectPanelListHelper";

const ProjectPanelList = props => {
  const { projects, onScrollHandler, isFetching } = props;
  const { user } = useAuthUser();
  const [width, setWidth] = useState();
  const projectPanelRef = useRef();

  useEffect(() => {
    // set width on initial load
    if (projectPanelRef.current) {
      setWidth(projectPanelRef.current.clientWidth);
    }
  }, []);

  const projectPanels = useMemo(
    () =>
      projects?.map(project => (
        <ProjectsPanel
          key={project.id}
          id={project.id}
          projectName={project.name}
          clientName={project.clientName}
          engagementType={project.engagementType}
          year={project.year}
          yearEnabled={project.yearEnabled}
          colorStatus={utilities.getProjectMilestoneProgressBarStatus(project)}
          totalMilestones={project.totalMilestones}
          completedMilestone={project.completedMilestoneCount}
          currentMilestone={project.currentMilestone}
          lastMilestone={project.lastMilestone}
          user={user}
        />
      )),
    [projects, user]
  );

  const onBreakpointChange = useCallback(size => {
    setWidth(size.width);
  }, []);

  const rows = useMemo(
    () => generateLayout(projectPanels?.length, width),
    [projectPanels?.length, width]
  );

  return (
    <section
      className="project-panel-list"
      onScroll={onScrollHandler}
      ref={projectPanelRef}
    >
      <SizeMe>
        {({ size }) => (
          <>
            <ResponsiveGridLayout
              className="layout"
              layouts={{
                xl: rows
              }}
              breakpoints={breakpoints}
              cols={colWidth}
              maxRows={2}
              width={size.width}
              rowHeight={230}
              margin={[40, 40]}
              containerPadding={[0, 0]}
              allowOverlap={false}
              isDraggable={false}
              onBreakpointChange={onBreakpointChange(size)}
            >
              {rows.map((row, i) => (
                <div key={row.i}>{projectPanels[i]}</div>
              ))}
            </ResponsiveGridLayout>
            {isFetching && <InfiniteScrollFooter state="loading" />}
          </>
        )}
      </SizeMe>
    </section>
  );
};

ProjectPanelList.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      clientName: PropTypes.string.isRequired,
      engagementType: PropTypes.string.isRequired,
      year: PropTypes.string,
      lastMilestone: PropTypes.shape({
        date: PropTypes.string,
        name: PropTypes.string
      }).isRequired,
      totalMilestones: PropTypes.number.isRequired,
      completedMilestoneCount: PropTypes.number.isRequired
    })
  ).isRequired,
  onScrollHandler: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired
};

export default ProjectPanelList;
