import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import useOnClickOutside from "use-onclickoutside";

import { classNames } from "@app/helpers/componentHelpers";

import Button from "@components/atoms/Button";
import LoaderSpinner from "@components/atoms/LoaderSpinner";
import AskHelp from "@components/molecules/AskHelp";
import Contact from "@components/molecules/Contact";

import "./ContactList.scss";

const ContactList = props => {
  const { t } = useTranslation();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [contacts, setContacts] = useState(props.contacts);
  const contactCardRef = useRef(null);
  const contactListRef = useRef(null);

  useOnClickOutside(contactCardRef, () => {
    setSelectedUserId(null);
  });

  useEffect(() => {
    setSelectedUserId(null);
    setContacts(props.contacts);
  }, [props.contacts]);

  useEffect(() => {
    if (selectedUserId) {
      contactCardRef.current.style.marginTop = `${-contactListRef.current
        .parentElement.scrollTop}px`;
    }
  }, [selectedUserId]);

  const handleOnDragEnd = contact => {
    props.handleReorderContacts(contact);
  };

  const noContentTemplate = callToActionMessage => {
    return <AskHelp message={callToActionMessage} />;
  };

  const contactListClassNames = () => {
    const classes = ["contact-list__members"];
    if (contacts?.length === 0) {
      classes.push("contact-list__no-content");
    }
    return classNames(classes);
  };

  const getListStyle = () => ({
    cursor: "ns-resize"
  });
  const contactListBodyTemplate = () => {
    if (!(contacts?.length > 0)) {
      return noContentTemplate("No members present");
    }
    return (
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="contacts">
          {provided => (
            <ul
              className="contacts"
              {...provided.droppableProps}
              style={getListStyle()}
              ref={provided.innerRef}
            >
              {contacts.map((user, index) => (
                <Draggable
                  key={user.email}
                  draggableId={user.email}
                  index={index}
                  isDragDisabled={!props.enableDrag}
                >
                  {(provided, snapshot) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Contact
                        drag={provided}
                        enableDrag={props.enableDrag}
                        isDragging={snapshot.isDragging}
                        user={user}
                        handleUserActionClick={data =>
                          props.handleUserActionClick({
                            action: data.key,
                            user
                          })
                        }
                        menuItems={props.getMenuItems(user)}
                      />
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  const loadingTemplate = () => (
    <div className="contact-list__spinner">
      <LoaderSpinner diameter="30px" />
    </div>
  );

  return (
    <div className="contact-list">
      <div className="contact-list__container">
        <div className={contactListClassNames()}>
          {props.isContactsLoading
            ? loadingTemplate()
            : contactListBodyTemplate()}
        </div>
      </div>
      <div className="action-items-btn">
        <Button
          type="primary"
          label={t(
            "requests:requests.ui.contactList.actions.sendRequestButtonLabel"
          )}
          onClick={props.onClick}
        />
      </div>
    </div>
  );
};

ContactList.propTypes = {
  contacts: PropTypes.array.isRequired,
  isContactsLoading: PropTypes.bool.isRequired,
  handleSendMessage: PropTypes.func,
  handleReorderContacts: PropTypes.func,
  handleUserActionClick: PropTypes.func,
  onClick: PropTypes.func,
  getMenuItems: PropTypes.func,
  enableDrag: PropTypes.bool
};

export default ContactList;
