import React, { useCallback } from "react";

import { useDropzone } from "react-dropzone";

import "./dragNDrop.scss";

const DragNDrop = props => {
  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];
    const readFile = new FileReader();
    readFile.readAsDataURL(file);

    readFile.onload = event => {
      props.handleDrop(event.target.result, file);
    };
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png",
    multiple: false
  });

  return (
    <div
      className={"drag-n-drop" + (props.error ? "drag-n-drop-error" : "")}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <>
        <div className="drag-n-drop-text-box">
          <i className="material-icons">cloud_upload</i>
          <span className="drag-n-drop-text-main">Drag and drop an image</span>
        </div>

        <span className="drag-n-drop-text-sub">{props.message}</span>
      </>
    </div>
  );
};

export default DragNDrop;
