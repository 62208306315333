import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { systemConstants } from "../constants";
import prepareHeaders from "./rtkUtilities";

export const projectMemberService = createApi({
  reducerPath: "projectMemberApi",
  baseQuery: fetchBaseQuery({
    baseUrl: systemConstants.serverURL,
    prepareHeaders
  }),
  tagTypes: ["ProjectMembers", "ProjectMembersToAdd"],
  endpoints: builder => ({
    getProjectMembers: builder.query({
      query: projectId => `/api/projects/${projectId}/members`,
      providesTags: ["ProjectMembers"]
    }),
    getProjectMembersToAdd: builder.query({
      query: ({ projectId }) => `/api/projects/${projectId}/users`,
      providesTags: ["ProjectMembersToAdd"]
    }),
    addProjectMember: builder.mutation({
      query: ({ projectId, projectMember }) => ({
        url: `/api/projects/${projectId}/members`,
        method: "POST",
        body: projectMember
      })
    }),
    updateProjectMember: builder.mutation({
      query: ({ projectId, memberId, projectMember }) => ({
        url: `/api/projects/${projectId}/members/${memberId}`,
        method: "PUT",
        body: projectMember
      })
    }),
    deleteProjectMember: builder.mutation({
      query: ({ projectId, userId }) => ({
        url: `/api/projects/${projectId}/members`,
        method: "DELETE",
        body: {
          id: userId
        }
      })
    })
  })
});

export const {
  useGetProjectMembersQuery,
  useGetProjectMembersToAddQuery,
  useAddProjectMemberMutation,
  useUpdateProjectMemberMutation,
  useDeleteProjectMemberMutation,
  util: projectMemberUtil
} = projectMemberService;
