import React, { isValidElement } from "react";

import PropTypes from "prop-types";

import Text from "@components/atoms/Text";
import ToastIcon from "@components/atoms/ToastIcon";

import "./ToastMessage.scss";

function ToastMessage(props) {
  const { source, title, description, actions, type, closeToast } = props;

  const getDescription = () => {
    if (isValidElement(description)) {
      return description;
    } else if (typeof description === "string") {
      return <Text value={description} colorStyle="inherit" fontSize="4" />;
    }
  };

  const getTitle = () => {
    if (isValidElement(title)) {
      return title;
    } else if (typeof title === "string") {
      return (
        <Text value={title} colorStyle="primary" fontSize="4" fontType="bold" />
      );
    }
    return <></>;
  };

  return (
    <div className="ot-toast-message">
      <div className="ot-toast-message__close" onClick={closeToast}>
        <i className="material-icons">{"close"}</i>
      </div>
      <div className="ot-toast-message__layout">
        <div className="ot-toast-message__icon">
          <ToastIcon type={type} />
        </div>
        <div className="ot-toast-message__content">
          {source && (
            <div className="ot-toast-message__source">
              {<Text value={source} colorStyle="inherit" fontSize="2" />}
            </div>
          )}
          <div className="ot-toast-message__title">{getTitle()}</div>
          {description && (
            <div className="ot-toast-message__description">
              {getDescription()}
            </div>
          )}
          {actions && (
            <div className="ot-toast-message__actions">{actions}</div>
          )}
        </div>
      </div>
    </div>
  );
}

ToastMessage.propTypes = {
  source: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.oneOf(["success", "error", "warning", "info"]),
  actions: PropTypes.element
};

export default ToastMessage;
