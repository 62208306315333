import React, { useCallback, useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { authActions } from "@shared/actions";
import { systemConstants } from "@shared/constants";
import { cryptography } from "@shared/helpers/cryptography";

import Button from "@components/atoms/Button";

import "./AzureAD.scss";

const AzureAD = ({ tenantId, clientId, uri, azureOnly, code }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const azureAuth = useCallback(() => {
    cryptography
      .generateCodeChallengeVerifier()
      .then(({ codeChallenge, codeVerifier }) => {
        window.localStorage.setItem("codeVerifier", codeVerifier);
        const params = Object.entries({
          client_id: clientId,
          redirect_uri: uri || `${window.location.origin}/login`,
          response_type: "code",
          scope:
            "https://graph.microsoft.com/User.Read openid profile offline_access",
          response_mode: "query",
          code_challenge: codeChallenge,
          code_challenge_method: "S256"
        })
          .map(([k, v]) => `${k}=${encodeURI(v)}`)
          .join("&");
        window.location.href = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?${params}`;
      });
  }, [tenantId, clientId, uri]);

  useEffect(() => {
    if (location) {
      const codeVerifier = window.localStorage.getItem("codeVerifier");
      if (code) {
        setIsLoading(true);
        dispatch(
          authActions.loginWithExternalAuth({
            authObject: {
              externalAuthProvider:
                systemConstants.externalAuthProviders.azure.key,
              externalAuthObject: {
                code,
                codeVerifier,
                redirectURI: `${window.location.origin}/login`
              }
            }
          })
        );
      } else if (azureOnly) {
        azureAuth();
      } else {
        setIsLoading(false);
      }
    }
  }, [location, dispatch, azureOnly, azureAuth, code]);

  const onClick = event => {
    event.preventDefault();
    azureAuth();
  };

  return (
    !isLoading && (
      <div className="azuread__container">
        <Button
          type="primary"
          label={"Continue with Azure"}
          onClick={onClick}
        />
      </div>
    )
  );
};

export default AzureAD;
