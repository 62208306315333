import React, { useCallback, useEffect, useState } from "react";

import {
  useAppInsightsContext,
  useTrackEvent
} from "@microsoft/applicationinsights-react-js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as EmailValidator from "email-validator";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { authActions, externalLinkValidationActions } from "@shared/actions";
import { systemConstants } from "@shared/constants";
import { useAuthUser, useStaticAssets, useUIConfig } from "@shared/hooks";
import { externalLinkValidationService } from "@shared/services";

import Button from "@shared-components/button/Button";
import ErrorBox from "@shared-components/errorBox/ErrorBox";
import GoogleAuth from "@shared-components/externalAuth/googleAuth/GoogleAuth";
import Input from "@shared-components/input/Input";
import AuthContainer from "@shared-components/pages/authContainer/AuthContainer";

import { routeConstants } from "@app/constants";
import { useDefaultLandingPageLink } from "@app/hooks/useDefaultLandingPageLink";

import AzureAD from "@components/organisms/AzureAD";

import "./LoginPage.scss";

const TYPES = systemConstants.externalLinkObjectType;

const initialState = {
  loggedIn: false,
  user: { email: "", password: "", submitted: false }
};

const LoginPage = ({ t, i18n }) => {
  const appInsights = useAppInsightsContext();
  const [state, setState] = useState(initialState);
  const [emailError, setEmailError] = useState({
    show: false,
    message: "Please enter a valid email."
  });
  const [isButtonStartState, setIsButtonStartState] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const authentication = useSelector(_state => _state.authentication);
  const externalLinkValidation = useSelector(
    _state => _state.externalLinkValidation
  );
  const { uiConfig } = useUIConfig();
  const { getDefaultLandingPageLink } = useDefaultLandingPageLink();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { getUrl, setFavIcon } = useStaticAssets();

  const externalEncodedObject = sessionStorage.getItem("externalObject");
  const loginToken = localStorage.getItem("token");
  const [externalLinkObject, setExternalLinkObject] = useState(null);
  const [hostObject, setHostObject] = useState(null);
  const [defaultLandingPage, setDefaultLandingPage] = useState(null);
  const { user } = useAuthUser();
  const trackAuth = useTrackEvent(appInsights, "Auth", authentication);
  const trackUser = useTrackEvent(appInsights, "User", user);
  //Bottom 3 variables help check if Azure SSO is being redirected
  const codeVerifierExists = !!window.localStorage.getItem("codeVerifier");
  const query = new URLSearchParams(location.search);
  const code = query.get("code");

  useEffect(() => {
    if (user?.id) {
      trackUser(user);
    }
  }, [user, trackUser]);

  useEffect(() => {
    if (uiConfig && user) {
      setDefaultLandingPage(getDefaultLandingPageLink(user));
    }
  }, [getDefaultLandingPageLink, uiConfig, user]);

  useEffect(() => {
    dispatch(authActions.getHostWithBrandDetails());
  }, [dispatch]);

  useEffect(() => {
    if (authentication.loggedIn && !authentication.verified) {
      dispatch(authActions.verify());
    }
  }, [authentication.loggedIn, authentication.verified, dispatch]);

  useEffect(() => {
    if (authentication.externalLoginFailed) {
      navigate(routeConstants.accountNotFound, {
        state: {
          prevRoute: routeConstants.login
        }
      });
    }
  }, [authentication.externalLoginFailed, dispatch, navigate]);

  useEffect(() => {
    if (authentication.host) {
      setHostObject(authentication.host);
      const hostLanguage =
        authentication?.host?.host?.properties?.i18n?.default;
      if (hostLanguage && i18n.language != hostLanguage) {
        i18n.changeLanguage(hostLanguage);
      }
      document.title = authentication.host.name;
      setFavIcon();
    }
  }, [authentication.host, i18n, setFavIcon]);

  useEffect(() => {
    if (
      !authentication.loggedIn ||
      !authentication.verified ||
      !(authentication.user.hostId || authentication.user.clientId) ||
      !loginToken
    ) {
      return;
    }
    if (
      defaultLandingPage &&
      !(externalEncodedObject || externalLinkValidation.externalObjectPresent)
    ) {
      trackAuth(authentication);
      const lastKnownPath = authentication.lastKnownPath;

      // special case for reloading the same page
      if (lastKnownPath && lastKnownPath.pathname === location.state?.target) {
        dispatch(authActions.resetLastKnownPath());
        return navigate(lastKnownPath.pathname, {
          state: lastKnownPath.state,
          replace: true
        });
      }
      if (location.state?.target) {
        return navigate(location.state.target, {
          state: location.state
        });
      }
      if (authentication.documentEditInfo) {
        const {
          item: docItem,
          projectFolder: docProjectFolder,
          project: docProject,
          query,
          title
        } = authentication.documentEditInfo;
        sessionStorage.removeItem("documentEditInfo");
        return navigate(routeConstants.editDocument, {
          state: {
            item: docItem,
            projectFolder: docProjectFolder,
            project: docProject,
            query,
            title
          }
        });
      }
      if (lastKnownPath) {
        dispatch(authActions.resetLastKnownPath());
        return navigate(lastKnownPath.pathname, {
          state: lastKnownPath.state
        });
      }
      return navigate(defaultLandingPage);
    } else if (externalEncodedObject) {
      const externalObject = JSON.parse(
        Buffer.from(externalEncodedObject, "base64").toString()
      );
      sessionStorage.removeItem("externalObject");
      if (
        externalObject.user.id === authentication.user.id &&
        externalObject.user.email === authentication.user.email &&
        externalObject.type
      ) {
        setExternalLinkObject({ ...externalObject });
        dispatch(
          externalLinkValidationActions.validateExternalLinkObject({
            externalObject
          })
        );
      } else {
        return navigate(defaultLandingPage, {
          state: {
            externalLinkObject: {
              success: false,
              message: externalLinkValidationService.getErrorMessage({
                externalObject
              })
            }
          }
        });
      }
    }
  }, [
    authentication,
    navigate,
    defaultLandingPage,
    dispatch,
    externalEncodedObject,
    externalLinkValidation.externalObjectPresent,
    loginToken,
    trackAuth,
    location.state
  ]);

  useEffect(() => {
    if (!externalLinkObject) {
      return;
    }
    if (externalLinkValidation.error) {
      dispatch(externalLinkValidationActions.reset());
      return navigate(defaultLandingPage, {
        state: {
          externalLinkObject: {
            success: false,
            message: externalLinkValidationService.getErrorMessage({
              externalObject: externalLinkObject
            })
          }
        }
      });
    }

    if (!externalLinkValidation.valid) {
      return;
    }

    dispatch(externalLinkValidationActions.reset());
    switch (externalLinkObject.type) {
      case TYPES.projectNotification:
        return navigate(routeConstants.project.dashboard, {
          state: {
            project: externalLinkValidation.project,
            externalLinkObject: externalLinkObject
          }
        });
      case TYPES.projectRequestResponse:
      case TYPES.projectRequest:
      case systemConstants.actionItemTypes.conversation:
        return navigate(routeConstants.request.queryDetails, {
          state: {
            project: externalLinkValidation.project,
            query: externalLinkValidation.externalObject.query,
            backToDashboard: true
          }
        });
      case TYPES.projectQueryList: {
        // NB: QueryList types are deprecated and we've kept it to maintain backward compatibility
        // as they may be in use. We can remove these after 2022-SEP-30 (or earlier if decided)
        const project = externalLinkValidation.externalObject.project;
        const item = externalLinkValidation.externalObject.query.document;
        const query = externalLinkValidation.externalObject.query;
        const state = {
          item,
          project,
          query,
          title: `Query List - ${query.description}`
        };
        sessionStorage.setItem("documentEditInfo", JSON.stringify(state));
        return navigate(routeConstants.editDocument, { state });
      }
      case systemConstants.actionItemTypes.websheet: {
        const project = externalLinkValidation.externalObject.project;
        const item = externalLinkValidation.externalObject.query.document;
        const query = externalLinkValidation.externalObject.query;
        const state = {
          item,
          project,
          query,
          title: query.description
        };
        sessionStorage.setItem("documentEditInfo", JSON.stringify(state));
        return navigate(routeConstants.editDocument, { state });
      }
      case TYPES.projectDataRepository:
        return navigate(routeConstants.dataRepository, {
          state: { project: externalLinkValidation.externalObject.project }
        });
      case TYPES.clientDataRepository:
        return navigate(routeConstants.clientDataRepository, {
          state: { client: externalLinkValidation.externalObject.client }
        });
      default:
        return navigate(defaultLandingPage);
    }
  }, [
    externalLinkValidation,
    externalLinkObject,
    navigate,
    defaultLandingPage,
    dispatch
  ]);

  const loginButtonEnabled = useCallback(() => {
    return state.user.password && EmailValidator.validate(state.user.email);
  }, [state.user.email, state.user.password]);

  const handleLogin = useCallback(
    event => {
      if (loginButtonEnabled()) {
        if (EmailValidator.validate(state.user.email)) {
          setState({ ...state, user: { ...state.user, submitted: true } });
          dispatch(authActions.login(state.user.email, state.user.password));
        } else {
          setEmailError({ ...emailError, show: true });
        }
      }
    },
    [dispatch, emailError, loginButtonEnabled, state]
  );

  const handleEmailChange = useCallback(
    event => {
      event.stopPropagation();
      setEmailError({ ...emailError, show: false });
      setIsButtonStartState(false);
      dispatch(authActions.clearError());
      setState({
        ...state,
        user: { ...state.user, email: event.target.value, submitted: false }
      });
    },
    [dispatch, emailError, state]
  );

  const handlePasswordChange = useCallback(
    event => {
      event.stopPropagation();
      setIsButtonStartState(false);
      dispatch(authActions.clearError());
      setState({
        ...state,
        user: { ...state.user, password: event.target.value, submitted: false }
      });
    },
    [dispatch, state]
  );

  const handlePasswordVisibility = useCallback(() => {
    setPasswordVisible(!passwordVisible);
  }, [passwordVisible]);

  const handleKeyDown = useCallback(
    event => {
      event.stopPropagation();
      if (event.key === "Enter") {
        handleLogin(event);
      }
    },
    [handleLogin]
  );

  const [strategies, setStrategies] = useState({
    passwordEnabled: false,
    googleEnabled: false,
    azureEnabled: false
  });

  useEffect(() => {
    const auth = authentication.host?.host?.properties?.auth;
    if (auth) {
      const googleEnabled = auth.google?.enabled && auth.google?.clientId;
      const azureEnabled = auth.azure?.enabled && auth.azure?.clientId;
      const passwordEnabled = auth.password
        ? auth.password.enabled
        : !googleEnabled && !azureEnabled;
      setStrategies({
        passwordEnabled,
        googleEnabled,
        azureEnabled,
        google: auth.google,
        azure: auth.azure,
        azureOnly: azureEnabled && !passwordEnabled && !googleEnabled
      });
    }
  }, [authentication.host]);

  if (!hostObject) {
    return <></>;
  }
  if (authentication.lastKnownPath) {
    return <> </>;
  }
  if (strategies.azureOnly || (codeVerifierExists && code)) {
    return (
      <AzureAD
        azureOnly={strategies.azureOnly}
        code={code}
        {...strategies.azure}
      />
    );
  }

  return (
    <AuthContainer>
      <div data-testid="login-container">
        <div className="auth-container__host-header">
          <span className="auth-container__host-header-name">
            {t("common:authentication.loginPage.title")}
          </span>

          <img
            src={getUrl("authLogo.png")}
            alt="Logo"
            className="auth-container__host-header-logo"
          />
        </div>

        {authentication.error && (
          <ErrorBox
            type="component"
            message={authentication.error.message}
            data-testid="login-component-error"
          ></ErrorBox>
        )}

        {strategies.passwordEnabled && (
          <>
            <div className="form-group">
              <label className="form-label">
                {t("common:authentication.loginPage.email")}
              </label>
              <Input
                className={
                  "form-control" +
                  ((!state.user.email && state.user.submitted) ||
                  emailError.show
                    ? " is-invalid"
                    : "")
                }
                error={emailError.show}
                type={"email"}
                placeholder={t("common:authentication.loginPage.emailPrompt")}
                handleChange={handleEmailChange}
                value={state.user.email}
                handleKeyDown={handleKeyDown}
                data-testid="login-email"
                autoComplete="new-password"
              />
              <div
                className="field-error-message"
                data-testid="login-email-error"
              >
                {emailError.show ? emailError.message : ""}
              </div>
            </div>

            <div className="form-group">
              <label className="form-label">
                {t("common:authentication.loginPage.password")}
              </label>
              <div className="login-form-password">
                <Input
                  className={
                    "form-control" +
                    (!state.user.password && state.user.submitted
                      ? " is-invalid"
                      : "")
                  }
                  type={passwordVisible ? "text" : "password"}
                  placeholder={t(
                    "common:authentication.loginPage.passwordPrompt"
                  )}
                  maxLength={1024}
                  handleChange={handlePasswordChange}
                  handleKeyDown={handleKeyDown}
                  data-testid="login-password"
                  error={false}
                  value={state.user.password}
                />

                <i
                  onClick={handlePasswordVisibility}
                  data-testid="login-password-toggle"
                  className="material-icons login-form-password-toggle"
                >
                  {passwordVisible ? "visibility_off" : "visibility"}
                </i>
              </div>
            </div>

            <div className="login-container__actions">
              <div className="login-container__actions--button">
                <Button
                  type={
                    !isButtonStartState && !loginButtonEnabled()
                      ? "disabled"
                      : "primary"
                  }
                  name={t("common:authentication.loginPage.loginButton")}
                  handleClick={handleLogin}
                />
              </div>
            </div>
            <div className="login-container__actions--link">
              <Link
                to={routeConstants.forgotPassword}
                state={{ email: state.user?.email }}
                className="auth-container__link"
              >
                {t("common:authentication.loginPage.forgotPassword")}
              </Link>
            </div>
          </>
        )}

        {strategies.passwordEnabled && strategies.googleEnabled && (
          <div className="login-container__actions--or">OR</div>
        )}
        {strategies.googleEnabled && (
          <GoogleOAuthProvider
            clientId={
              authentication.host?.host?.properties?.auth?.google?.clientId
            }
          >
            <GoogleAuth />
            <br />
          </GoogleOAuthProvider>
        )}
        {strategies.passwordEnabled && strategies.azureEnabled && (
          <div className="login-container__actions--or">OR</div>
        )}
        {strategies.azureEnabled && (
          <AzureAD azureOnly={strategies.azureOnly} {...strategies.azure} />
        )}
      </div>
    </AuthContainer>
  );
};

export default withTranslation()(LoginPage);
