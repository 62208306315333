import React, { Suspense, useContext, useMemo } from "react";

import UIConfigContext from "@app/helpers/UIConfigContext";

import { defaultProps, propTypes } from "./multiselectHelper";

const Multiselect = React.forwardRef((props, fwdRef) => {
  const uiConfig = useContext(UIConfigContext);

  const ThemedMultiselect = useMemo(() => {
    switch (uiConfig?.theme?.designSystem) {
      case "ot":
        return React.lazy(() => import("./OTMultiselect"));
      case "tds":
      default:
        return React.lazy(() => import("./TDSMultiselect"));
    }
  }, [uiConfig?.theme?.designSystem]);

  return (
    <>
      <Suspense fallback={<div></div>}>
        <ThemedMultiselect
          {...props}
          ref={fwdRef}
          theme={uiConfig?.theme?.designSystem}
        ></ThemedMultiselect>
      </Suspense>
    </>
  );
});

Multiselect.defaultProps = defaultProps;
Multiselect.propTypes = propTypes;

export default Multiselect;
