import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { routeConstants } from "@constants";

import { useHostUserProfile } from "@shared/hooks/useHostUserProfile";
import { useAddHostUserMutation } from "@shared/services";

import ErrorBox from "@shared-components/errorBox/ErrorBox";

import UserForm from "@components/organisms/UserForm/UserForm";
import MainPageTemplate from "@components/templates/MainPageTemplate";
import PageBodyTemplate from "@components/templates/PageBodyTemplate";

const AddHostUser = () => {
  const { t } = useTranslation();
  const { teamUserFields, roles, error, projectsList } = useHostUserProfile();
  const authentication = useSelector(state => state.authentication);
  const [addHostUser, { isSuccess: isHostUserAdded }] =
    useAddHostUserMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isHostUserAdded) {
      navigate(routeConstants.manageUsers);
    }
  }, [isHostUserAdded, navigate]);

  const getConfig = key =>
    teamUserFields?.find(field => field.key === key) ?? {};

  const addRole = () => {
    if (roles?.length > 1) {
      return {
        key: "role",
        type: "dropdown",
        required: true,
        items: roles
      };
    }
    return {};
  };

  const handleSubmit = user => {
    if (!user.roleId) {
      user.roleId = roles?.[0]?.id;
    }
    user.hostId = authentication.user.hostId;
    addHostUser({ user });
  };

  const formConfig = [
    {
      key: "firstname",
      type: "text",
      required: true
    },
    {
      key: "lastname",
      type: "text",
      required: true
    },
    {
      key: "email",
      type: "text",
      required: true
    },
    getConfig("jobTitle"),
    addRole(),
    {
      key: "projects",
      type: "multiselect",
      required: false,
      items: projectsList,
      defaultValue: [],
      placeholder: t("common:user.configured.fields.projects.recommended")
    },
    getConfig("MOBILE_NUMBER"),
    getConfig("BUSINESS_NUMBER"),
    {
      key: "NOTIFICATION",
      type: "radio",
      defaultValue: "digest",
      items: ["digest", "each"],
      path: "properties.notification.email"
    },
    getConfig("LICENSE")
  ];

  return (
    <MainPageTemplate>
      <PageBodyTemplate
        breadcrumbs={[
          {
            linkTo: routeConstants.manageUsers,
            label: t("common:ui.manageUsers.title")
          }
        ]}
        title={t("ui.manageUsers.addTitle_team")}
      >
        {error && <ErrorBox message={error} />}
        <UserForm
          formConfig={formConfig}
          handleSubmit={handleSubmit}
          submitLabel={t("common:ui.manageUsers.addSubmit")}
        />
      </PageBodyTemplate>
    </MainPageTemplate>
  );
};

export default AddHostUser;
