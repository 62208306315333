import { FunctionPlugin, FunctionArgumentType } from 'hyperformula';
import { ProcedureAst } from 'hyperformula/typings/parser/Ast';
import { InterpreterState} from 'hyperformula/typings/interpreter/InterpreterState';
import { InterpreterValue} from 'hyperformula/typings/interpreter/InterpreterValue';

export class FunctionValuePlugin extends FunctionPlugin {
  value(ast:ProcedureAst, state: InterpreterState):InterpreterValue {
    return this.runFunction(
      ast.args,
      state,
      this.metadata("VALUE"),
      (text) => text
    );    
  }
}

FunctionValuePlugin.implementedFunctions = {
  VALUE: {
    method: "value",
    parameters: [
      { argumentType: FunctionArgumentType.STRING, passSubtype: true }
    ],
  },
};

export const FunctionValuePluginTranslations = {
  enGB: {
    VALUE: "VALUE"
  }
};
