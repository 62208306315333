import React, { useEffect } from "react";

import { useGetFinalPackageSignedDocuments } from "@shared/hooks";

import BrandButton from "@components/atoms/Button/BrandButton";
import InlineAlert from "@components/atoms/InlineAlert";
import ModalTemplate from "@components/templates/ModalTemplate";

const EditSignedLabelConfirmationModal = ({
  project,
  handleDismiss,
  onSubmit
}) => {
  const { error, fetched, signedDocuments, fetchSignedDocuments } =
    useGetFinalPackageSignedDocuments();
  const getSignedTagConfirmationMessage = () => {
    const format = (count, word) => {
      if (count === 0) {
        return;
      }
      if (count === 1) {
        return `by 1 ${word}`;
      }
      return `by ${count} ${word}s`;
    };

    return `This label is currently in use ${format(
      signedDocuments.length,
      "document"
    )}. Editing this label will mean these documents can no longer be accessed from the Final Package Page`;
  };

  useEffect(() => {
    if (project?.id) {
      fetchSignedDocuments(project.id);
    }
  }, [fetchSignedDocuments, project?.id]);

  useEffect(() => {
    if (fetched && signedDocuments?.length === 0) {
      onSubmit();
    }
  }, [fetched, onSubmit, signedDocuments]);

  useEffect(() => {
    if (error) {
      onSubmit();
    }
  }, [error, onSubmit]);

  return (
    fetched &&
    signedDocuments?.length > 0 && (
      <>
        <ModalTemplate
          boxClassName="tag-editor"
          title={"Confirm Edit Label"}
          onClose={handleDismiss}
          content={
            <InlineAlert
              type="error"
              message={getSignedTagConfirmationMessage()}
            />
          }
          footer={
            <>
              <BrandButton
                type="secondary"
                label="Cancel"
                onClick={handleDismiss}
              />
              <BrandButton label="Edit" type="primary" onClick={onSubmit} />
            </>
          }
        />
      </>
    )
  );
};

export default EditSignedLabelConfirmationModal;
