export const smartFormConstants = {
  contentKeys: {
    REASSIGN: "REASSIGN",
    FINALISE: "FINALISE",
    COMMENT: "COMMENT",
    EDIT_TAGS: "EDIT_TAGS",
    COPIED_TO: "COPIED_TO",
    PROJECT_ACCESS: "PROJECT_ACCESS",
    UPLOAD_DOCUMENT: "UPLOAD_DOCUMENT",
    ANSWER_PER_ENTITY: "ANSWER_PER_ENTITY",
    ANSWER_PER_ENTITY_IN_TEXT: "ANSWER_PER_ENTITY_IN_TEXT",
    ANSWER_PER_ENTITY_IN_BOOLEAN: "ANSWER_PER_ENTITY_IN_BOOLEAN"
  }
};
